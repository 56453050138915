import React, { useMemo } from 'react';
import _ from 'src/libs/util';
import PropTypes from 'prop-types';

import ProductCard from './ProductCard';
import { Product } from 'src/types/context/Product';
import { Meta } from 'src/types/context/Meta';
import MainViewLink from '../../../MainViewLink';

type PPVPlanProps = {
  checkModal: () => void;
  meta?: Meta;
  event?: Event;
  products?: Product[];
  isProductPage: boolean;
  isDisabled?: boolean;
  spMode: boolean;
  doAction: (option: { selected: { type: string; id: number | string }; returnTo: string }) => void;
};

const PPVPlan = (props: PPVPlanProps, context) => {
  const authContext = context.getModelData('authContext');
  const browserInfo = context.getModelData('browserInfo');

  const signupLink = useMemo(() => {
    // ログインしていれば不要
    if (authContext) {
      return null;
    }
    const returnTo = encodeURIComponent(context.routeHandler.url);
    const href = `/signup_wol?return_to=${returnTo}&from=tvod`;
    if (browserInfo.isAndroid || browserInfo.isIOS) {
      return (
        <MainViewLink href={href} className={'plan-info__notice btn'}>
          WEBアカウント登録（無料）はこちら
        </MainViewLink>
      );
    } else {
      return (
        <div className="plan-info__notice">
          ※WEBアカウント登録（無料）は
          <MainViewLink href={href} className={'accent-color'}>
            こちら
          </MainViewLink>
        </div>
      );
    }
  }, [authContext, browserInfo.isAndroid, browserInfo.isIOS, context.routeHandler.url]);

  return (
    <>
      {!props.isProductPage ? (
        <div className="plan-info__title">
          対象番組のみ視聴<div className="card-badge tvod ppv">PPV</div>
        </div>
      ) : null}
      {_.map(props.products, product => (
        <ProductCard
          /* @ts-ignore TS2339 */
          product={product}
          doAction={props.doAction}
          meta={props.meta}
          isDisabled={props.isDisabled}
          isProductPage={props.isProductPage}
          spMode={props.spMode}
        />
      ))}
      {signupLink}
    </>
  );
};

PPVPlan.contextTypes = {
  getModelData: PropTypes.func,
  routeHandler: PropTypes.object,
};
export default React.memo<PPVPlanProps>(PPVPlan);
