import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import routes from '../../../common/routes';
import * as ERROR from '../../../../constants/error';
import MainViewLink from '../../../common/components/MainViewLink';
import Axios from '../../../../common/Axios';
import ViewingConfirmation from '../../../common/components/howto_play/ViewingConfirmation';

//
// ログイン前にTVOD/SVODの価格一覧を表示する
//
class PriceList extends React.PureComponent {
  static getPaths = function(models, options, props) {
    let path = this.getRootPath(models, options, props);
    return [path.concat([['products', 'courses']])];
  };

  static getRootPath = function(models, options, props) {
    if (props.metaId) {
      return ['meta', props.metaId];
    }
    return [];
  };

  static get propTypes() {
    return {
      metaId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      model: PropTypes.object.isRequired,
    };
  }

  static get contextTypes() {
    return {
      routeHandler: PropTypes.object,
      models: PropTypes.object,
      history: PropTypes.object,
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    const meta = props.model.getSync(rootPath);

    // @ts-ignore TS2339
    this.products = _.get(meta, 'products');
    // @ts-ignore TS2339
    this.courses = _.get(meta, 'courses');

    this.handleClick = this.handleClick.bind(this);

    this.state = {
      showType: null,
      dispose: null,
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;

    // URLに変化があったときにforceUpdateする
    // @ts-ignore TS2339
    this.unlisten = this.context.history.listen(location => {
      // @ts-ignore TS2339
      if (!this._isMounted) {
        return;
      }
      this.forceUpdate();
    });

    // @ts-ignore TS2339
    if (!this.props.metaId) {
      this.setState({ showType: 'nav' });
    } else {
      this.fetchData(this.props);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;

    // @ts-ignore TS2339
    if (typeof this.unlisten === 'function') {
      // @ts-ignore TS2339
      this.unlisten();
    }
  }

  fetchData(props) {
    if (!props.metaId) {
      // @ts-ignore TS2339
      if (!this._isMounted) {
        return;
      }
      this.setState({ showType: 'nav' });
      return;
    }

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.products = _.values(
          _.omitBy(_.get(res, ['json'].concat(rootPath).concat('products')), val => {
            return !_.get(val, 'id') || !val.onSale || _.isEmpty(val.activePricing);
          }),
        );
        // @ts-ignore TS2339
        this.courses = _.values(
          _.omitBy(_.get(res, ['json'].concat(rootPath).concat('courses')), val => {
            return !_.get(val, 'id');
          }),
        );

        delete this.state[JSON.stringify(paths)];
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };

        const appConfig = this.context.getModelData('config');
        // @ts-ignore TS2339
        if (this.products.length == 0 && this.courses.length == 0) {
          throw 'products and courses is empty';
          // ベーシックプラン一つだけの場合は通常のログイン/会員登録リンクを表示する
        } else if (
          // @ts-ignore TS2339
          this.products.length == 0 &&
          // @ts-ignore TS2339
          this.courses.length == 1 &&
          // @ts-ignore TS2339
          _.get(this.courses, '[0].id') == _.get(appConfig, 'course.basic_id')
        ) {
          // @ts-ignore TS2339
          newState.showType = 'nav';
          // それ以外の場合はproducts, coursesを表示する
        } else {
          // @ts-ignore TS2339
          newState.showType = 'howToAcq';
        }

        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        // 何も操作できなくなる事態を防ぐために
        // エラーは握りつぶして、ログイン/会員登録リンクを表示させる
        const newState = {
          showType: 'nav',
          fetchDataError: e,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    // GET値で選択済みのproduct_id,course_idがあれば
    // ログイン・会員登録リンクを出す
    if (_.get(this.context, 'routeHandler.query.course_id') || _.get(this.context, 'routeHandler.query.product_id')) {
      return this.props.children ? this.props.children : null;
    }

    // @ts-ignore TS2339
    if (!this.state.showType) {
      return null;
    }

    // @ts-ignore TS2339
    switch (this.state.showType) {
      case 'loading':
        return null;
      case 'howToAcq':
        let productNodes = [];
        // @ts-ignore TS2339
        if (this.products) {
          // @ts-ignore TS2339
          productNodes = this.products.map((product, i) => {
            return (
              <div key={`product_${i}`}>
                <a
                  href="javascript:void(0)"
                  onClick={this.handleClick('product_id', product.id)}
                  className={classnames('btn', {
                    // @ts-ignore TS2339
                    'btn-line-overlay': this.props.isOverlay,
                    // @ts-ignore TS2339
                    'btn-gray btn-wide': !this.props.isOverlay,
                  })}
                  // @ts-ignore TS2322
                  tabIndex="0"
                  role="button"
                >
                  <div className="price-record">
                    <span className="price-name">{product.name}</span>
                    <span className="price-value">{product.activePricing.displayPaymentAmount}(税込)</span>
                  </div>
                </a>
              </div>
            );
          });
        }

        let courseNodes = [];
        // @ts-ignore TS2339
        if (this.courses) {
          // @ts-ignore TS2339
          courseNodes = this.courses.map((course, i) => {
            return (
              <div key={`course_${i}`}>
                <a
                  href="javascript:void(0)"
                  onClick={this.handleClick('course_id', course.id)}
                  className={classnames('btn', {
                    // @ts-ignore TS2339
                    'btn-line-overlay': this.props.isOverlay,
                    // @ts-ignore TS2339
                    'btn-gray btn-wide': !this.props.isOverlay,
                  })}
                  // @ts-ignore TS2322
                  tabIndex="0"
                  role="button"
                >
                  <div className="price-record">
                    <span className="price-name">{course.name}</span>
                    <span className="price-value">{course.activePricing.displayPaymentAmount}(税込) / 月</span>
                  </div>
                </a>
              </div>
            );
          });
        }

        let descText = 'ご視聴いただくには別途料金が必要です。';
        let actionType;
        if (productNodes.length > 0 && courseNodes.length == 0) {
          descText = 'この作品はレンタル作品です。' + descText;
          actionType = 'purchase';
        } else if (productNodes.length == 0 && courseNodes.length > 0) {
          descText = 'この動画を視聴するには下記プランの契約が必要です。';
          actionType = 'subscribe';
        }

        return (
          <div className="price-list">
            <div className="price-list-title">{descText}</div>
            <ViewingConfirmation actionType={actionType} />
            {productNodes}
            {courseNodes}
          </div>
        );

      default:
        // nav
        return this.props.children ? this.props.children : null;
    }
  }

  handleClick(key, id) {
    return e => {
      // @ts-ignore TS2339
      if (!this._isMounted) {
        return;
      }

      // @ts-ignore TS2339
      if (this.props.onClick) {
        // @ts-ignore TS2339
        this.props.onClick(e, key, id);
        return;
      }

      let searchValue = this.context.history.location.search || '';
      if (!searchValue) {
        searchValue += '?';
      }
      searchValue += `${key}=${id}`;

      const { routeHandler, history } = this.context;
      // @ts-ignore TS2554
      const to = routes.watchNow.makePath({ id: this.props.metaId });

      history.push({
        pathname: to,
        search: searchValue,
      });
      return;
    };
  }
}

export default PriceList;
