import React, { useCallback, useMemo } from 'react';
import _ from 'src/libs/util';
import PropTypes from 'prop-types';

import useShowSidelistButton from './hooks/useShowSidelistButton';
import useSidelistPalette from './hooks/useSidelistPalette';

const PlayerSideListButton = (props, context) => {
  const config = useMemo(() => context.getModelData('config'), [context]);
  // @ts-ignore TS2345
  const { palette, paletteObjectsLength } = useSidelistPalette({
    model: props.model,
    config,
    meta: props.meta,
    propKeys: ['id', 'title'],
    withObjectsLength: true,
  });
  const active = useShowSidelistButton(config, props.meta, props.isActiveControls);

  const onClickButton = useCallback(() => {
    if (props.handlePlayerSideListModeChange) {
      props.handlePlayerSideListModeChange();
    }
    if (context.gtmApp) context.gtmApp.pushDataLayerOnPaltteInPlayerClick(palette);
  }, [context.gtmApp, palette, props]);

  return active && palette && paletteObjectsLength ? (
    <div className="list-btn isActiveControls" onClick={onClickButton}>
      {palette.title}
      <i className="fa fa-angle_right"></i>
    </div>
  ) : null;
};

PlayerSideListButton.contextTypes = {
  getModelData: PropTypes.func,
  gtmApp: PropTypes.object,
};

export default React.memo(PlayerSideListButton);
