import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import * as DOMUtils from '../../../../sketch-platform/utils/DOMUtils';

const SpSliderItem = props => {
  const className = { 'sp-slider-item': true };
  className['size-' + props.size] = !!props.size;
  return <div className={classnames(className, props.additionalClasses)}>{props.children}</div>;
};

SpSliderItem.propTypes = {
  size: PropTypes.string,
};

SpSliderItem.defaultProps = {
  size: '1x1',
};

class SpNextLoader extends React.Component {
  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.nextRef = React.createRef();
    this.handleEvent = _.throttle(this.handleEvent.bind(this), 300, { leading: true, trailing: true });
    this.handleNextLoad = this.handleNextLoad.bind(this);
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    this.timer = window.requestAnimationFrame(this.handleEvent);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.timer) window.cancelAnimationFrame(this.timer);
    // @ts-ignore TS2339
    delete this.timer;
  }

  handleEvent(e) {
    // @ts-ignore TS2339
    const el = this.nextRef.current;
    if (el) {
      const { width, height } = DOMUtils.getWindowRect();
      const { top, left } = DOMUtils.getRect(el);
      if (width + width / 2 > left && height + height / 2 > top) {
        // @ts-ignore TS2339
        if (this.timer) window.cancelAnimationFrame(this.timer);
        // @ts-ignore TS2339
        delete this.timer;
        this.handleNextLoad(e);
      }
    }
    // @ts-ignore TS2339
    this.timer = window.requestAnimationFrame(this.handleEvent);
  }

  handleNextLoad(e) {
    // @ts-ignore TS2339
    if (this.props.onNextLoad) this.props.onNextLoad(e);
  }

  render() {
    return (
      // @ts-ignore TS2339
      <div ref={this.nextRef} className="sp-slider-more" onClick={this.handleNextLoad}>
        <i className="fa fa-angle_right"></i>
      </div>
    );
  }
}

const SpSlider = props => {
  const handleNextLoad = e => {
    if (props.onNextClick) props.onNextClick(e);
  };

  const renderChildren = () => {
    const children = props.children.map(child => {
      let clone = React.cloneElement(child, {});
      let itemProps = _.assign(
        {
          key: `SpSliderItem-${child.key}`,
          additionalClasses: {},
        },
        props,
      );
      for (var key in clone.props) {
        let snake = key.replace(/([A-Z])/g, function(s) {
          return '-' + s.charAt(0).toLowerCase();
        });
        if (snake == 'size') {
          itemProps.size = clone.props[key];
        } else if (snake.indexOf('is-') == 0) {
          itemProps.additionalClasses[snake.replace(/is-/, '')] = clone.props[key];
        }
      }
      return <SpSliderItem {...itemProps}>{clone}</SpSliderItem>;
    });

    if (props.loading) {
      children.push(
        <div key="loading" className="gallery-spin-loader sp-slider-spin-loader">
          <div className="loader-cont"></div>
        </div>,
      );
    } else if (props.totalItems > props.children.length) {
      // @ts-ignore TS2322
      children.push(<SpNextLoader key="next" onNextLoad={handleNextLoad} />);
    }
    return children;
  };

  const style = {};
  if (props.scrollable === false) {
    // @ts-ignore TS2339
    style.overflowX = 'hidden';
  }

  return (
    <div className="sp-slider-container">
      <div className="sp-slider-content" style={style}>
        <div className="sliderMask showPeek">{renderChildren()}</div>
      </div>
    </div>
  );
};

SpSlider.propTypes = {
  loading: PropTypes.bool,
  totalItems: PropTypes.number,
  onNextClick: PropTypes.func,
};

SpSlider.defaultProps = {
  loading: false,
  totalItems: 0,
};

export default SpSlider;
