import _ from 'src/libs/util';

import activeProfile, { isFree, showNotTrial } from '../../../utils/activeProfile';
import routes from '../../common/routes';
import { getMetaDeliveryStatus, META_DELIVERY_STATUS } from '../../common/utils/metaUtil';
import datetime from 'src/libs/datetime';

/*
新着/新着ありともうすぐ終了はbadge_statusのみで判断
LIVEは（まもなく開始、ライブ中）はフロントで判断
無料/無料ありのバッジはbadge_statusもみつつ、フロントでも判断

{ "label": "未設定", "value": 1, "default_flag": true },
{ "label": "新着", "value": 2, "default_flag": false },
{ "label": "新着あり", "value": 3, "default_flag": false },
{ "label": "もうすぐ終了", "value": 4, "default_flag": false },
{ "label": "無料", "value": 5, "default_flag": false },
{ "label": "無料あり", "value": 6, "default_flag": false }
{ "label": "配信終了", "value": 7, "default_flag": false }
{ "label": "無料トライアル 対象外", "value": 10, "default_flag": false }
{ "label": "無料トライアル 対象外を含む", "value": 11, "default_flag": false }

{0: 'free', 1: 'trial', 2: 'stream', 3: 'broadcast', 4: 'trial_used'}
*/

export const TAG_TEXTS = {
  DELIVERY_SOON: 'まもなく配信',
  LIVE_ON_AIR: 'ライブ中',
  NEW: '新着',
  INCLUDE_NEW: '新着あり',
  END_DELIVERY_SOON: 'もうすぐ終了',
  FREE: '無料',
  INCLUDE_FREE: '無料あり',
  END_DELIVERY: '配信終了',
  NON_TARGET_TRIAL: '無料トライアル 対象外',
  INCLUDE_NON_TARGET_TRIAL: '無料トライアル 対象外を含む',
};

const STATUS_TO_TEXT = {
  2: TAG_TEXTS.NEW,
  3: TAG_TEXTS.INCLUDE_NEW,
  4: TAG_TEXTS.END_DELIVERY_SOON,
  5: TAG_TEXTS.FREE,
  6: TAG_TEXTS.INCLUDE_FREE,
  7: TAG_TEXTS.END_DELIVERY,
  10: TAG_TEXTS.NON_TARGET_TRIAL,
  11: TAG_TEXTS.INCLUDE_NON_TARGET_TRIAL,
};
export default function activeTags(item, context, options = {}) {
  const cardInfo = item.cardInfo || {};
  if (cardInfo.badgeText && (cardInfo.badgeText + '').length > 0) {
    return [cardInfo.badgeText];
  }
  // @ts-ignore TS2554
  const profile = activeProfile(context.models) || {};
  const tags = [];
  let pushTags = text => {
    if (!tags.includes(text)) {
      tags.push(text);
    }
  };
  const routeHandler = context.routeHandler || {};
  // ライブ
  if (item.schemaId === 4 && !item.advertisingId) {
    const isLiveCanvas = routes.liveSchedule.match(routeHandler.path);
    const liveDeliveryStatus = getMetaDeliveryStatus(item, { liveComingSoonHour: isLiveCanvas ? 12 : 24 });
    // ライブスケジュールキャンバスの場合
    if (isLiveCanvas) {
      if (liveDeliveryStatus === META_DELIVERY_STATUS.LIVE_COMING_SOON) {
        return [TAG_TEXTS.DELIVERY_SOON];
      }
    // その他
    } else {
      // @ts-ignore TS2339
      if (options.hideDeliveryStartText) {
        return [];
      }
      pushTags = (text) => {
        const liveEnd = ![
          META_DELIVERY_STATUS.LIVE_COMING_SOON,
          META_DELIVERY_STATUS.BEFORE_LIVE,
          META_DELIVERY_STATUS.LIVE_ON_AIR,
        ].includes(liveDeliveryStatus);
        // ライブが終了していない場合は「'もうすぐ終了」を出さない
        if (!tags.includes(text) && (text !== TAG_TEXTS.END_DELIVERY_SOON || liveEnd)) {
          tags.push(text);
        }
      };
      const s = datetime();
      const broadcastStartAt = datetime(cardInfo.broadcastStartAt);
      // 基本アートの上のため、明示的にfalseを渡すようにしている
      // @ts-ignore TS2339
      const text = options.isOnArt === false ? 'ライブ配信' : 'ライブ';
      // LIVE配信開始前24h以内の時
      if (liveDeliveryStatus === META_DELIVERY_STATUS.LIVE_COMING_SOON) {
        // 翌日かつ12時以降なら日付を出す
        // 日付が違いかつ開始時間が12より大きいので日付表示(24時間より前と同じ)とする
        if (broadcastStartAt.hour() >= 12 && broadcastStartAt.date() != s.date()) {
          pushTags(`${broadcastStartAt.formatMDdd()}${text}`);
        } else {
          const a = broadcastStartAt.format('a');
          let h = broadcastStartAt.format('h');
          // @ts-ignore TS2367
          if (h == 12) h = 0;
          const m = broadcastStartAt.format('mm');
          pushTags(`${a}${h}:${m}${text}`);
        }
        // LIVE配信開始前24hより前
      } else if (liveDeliveryStatus === META_DELIVERY_STATUS.BEFORE_LIVE) {
        pushTags(`${broadcastStartAt.formatMDdd()}${text}`);
      }
    }
    if (liveDeliveryStatus === META_DELIVERY_STATUS.LIVE_ON_AIR) {
      pushTags(TAG_TEXTS.LIVE_ON_AIR);
    }
  }

  const deliveryStart = datetime(cardInfo.deliveryStartAt);
  const deliveryEnd = datetime(cardInfo.deliveryEndAt, 'YYYY/MM/DD HH:mm:ss');
  const timeDiff = deliveryEnd.diff(deliveryStart, 'hours');
  const deliveryStatus = !isNaN(timeDiff)
    ? getMetaDeliveryStatus(item, { endDeliverySoonHour: timeDiff > 72 ? 72 : timeDiff / 2 })
    : null;

  if (deliveryStatus === META_DELIVERY_STATUS.END_DELIVERY_SOON) {
    pushTags(TAG_TEXTS.END_DELIVERY_SOON);
  }

  const badgeStatuses = cardInfo.badgeStatuses || [];

  for (const badgeStatus of badgeStatuses) {
    let text = STATUS_TO_TEXT[badgeStatus];
    if (badgeStatus == 2) {
    } else if (badgeStatus == 3) {
    } else if (badgeStatus == 4) {
    } else if (badgeStatus == 5 || badgeStatus == 6) {
      // 未ログインor無料会員以外には表示しない
      if (!isFree(profile)) text = '';
    } else if (badgeStatus == 7) {
    } else if (badgeStatus == 10 || badgeStatus == 11) {
      // 未ログイン、無料、トライアル以外には表示しない
      if (!showNotTrial(profile, context.models)) text = '';
    }
    if (text) pushTags(text);
  }
  return tags;
}
