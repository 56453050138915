import cookieDough from 'cookie-dough';

import { TOKEN_KEY } from '../constants/cookie';
import datetime from 'src/libs/datetime';
import { AuthContext } from 'src/types/context/AuthContext';

const cookie = cookieDough();
const setTokenData = function(tokenData) {
  if (typeof window !== 'undefined') {
    if (window.localStorage) {
      window.localStorage.setItem(TOKEN_KEY, JSON.stringify(tokenData));
    } else if (window.navigator.cookieEnabled) {
      cookie.set(TOKEN_KEY, JSON.stringify(tokenData), { path: '/' });
    }
  }
};
const getTokenData = function() {
  try {
    if (typeof window !== 'undefined') {
      if (window.localStorage) {
        return JSON.parse(window.localStorage.getItem(TOKEN_KEY));
      } else if (window.navigator.cookieEnabled) {
        return JSON.parse(cookie.get(TOKEN_KEY));
      }
    }
  } catch (e) {
    return null;
  }
};
const setAuthContextData = function(authContext: AuthContext) {
  if (!authContext) return;
  let tokenData = getTokenData();
  if (!tokenData || !tokenData.time || (tokenData && tokenData.time < authContext.time)) {
    if (!tokenData) tokenData = {};
    tokenData.id = authContext.id;
    tokenData.token = authContext.token;
    tokenData.idpToken = authContext.idpToken;
    tokenData.time = authContext.time;
    tokenData.refreshTime = datetime().format();
    setTokenData(tokenData);
  }
};

export default {
  set: setTokenData,
  get: getTokenData,
  setAuthContextData: setAuthContextData,
};
