import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import GalleryContent from './GalleryContent';
import MyListDeleteApp from '../../../../common/MyListDeleteApp';
import MyListGalleryHeader from './MyListGallaryHeader';
import GalleryDeleteContent from './GalleryDeleteContent';
import SortGallery from './SortGallery';

export default class MyList extends React.Component {
  static getPrefetchPaths = function(models, options, props) {
    const so = _.get(props, 'routeHandler.query.so', 'cd');
    return GalleryContent.getPaths(
      models,
      options,
      Object.assign({}, GalleryContent.defaultProps, props, { keyPrefix: 'mylist', sortOrder: so }),
    );
  };

  static get contextTypes() {
    return {
      models: PropTypes.object,
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    // @ts-ignore TS2339
    this._deleteApp = new MyListDeleteApp(context.getModelData('services', 'cms'), this.context.gtmApp, this.model);
    this.handleDeleted = this.handleDeleted.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleFetchedCount = this.handleFetchedCount.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);
    this.state = {
      showDeleteContent: false,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    this._deleteApp.on('onDeleted', this.handleDeleted);
    this.sendToGtm();
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    this._deleteApp.off('onDeleted', this.handleDeleted);
  }

  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    this.context.gtmApp.pageView('マイリスト');
  }

  handleDeleted() {
    // キャッシュされているマイリスト一覧を削除する
    // @ts-ignore TS2339
    this.model.invalidate(['mylist']);
  }

  handleChangeQuery(query) {
    // @ts-ignore TS2339
    this._deleteApp.flush();
    this.setState({ query });
  }

  handleFetchedCount(count) {
    this.setState({ showDeleteContent: count > 0 });
  }

  render() {
    // @ts-ignore TS2339
    const deleteContent = this.state.showDeleteContent ? <GalleryDeleteContent deleteApp={this._deleteApp} /> : null;
    const sortOrder = this.context.routeHandler.query['so'] || 'cd';
    // @ts-ignore TS2339
    const sortComponent = this.state.showDeleteContent ? (
      <SortGallery
        // @ts-ignore TS2322
        sortOrder={sortOrder}
        sortOrders={[
          { so: 'cd', name: '追加順（降順）' },
          { so: 'ca', name: '追加順（昇順）' },
        ]}
        onChangeQuery={this.handleChangeQuery}
        positionRight={true}
      />
    ) : null;

    return (
      <GalleryContent
        // @ts-ignore TS2322
        header={<MyListGalleryHeader deleteContent={deleteContent} sortComponent={sortComponent} />}
        // @ts-ignore TS2322
        deleteApp={this._deleteApp}
        onFetchedCount={this.handleFetchedCount}
        // @ts-ignore TS2339
        model={this.model}
        keyPrefix={'mylist'}
        sortOrder={sortOrder}
        showEpisodeNumber={true}
        showPopCardMylistButton={false}
      />
    );
  }
}
