import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import GalleryContent from './GalleryContent';
import routes from '../../../common/routes';
import { NotFoundError } from '../../../common/components/ErrorBoundary';

class RecentlyAdded extends React.PureComponent {
  static getPrefetchPaths = function(models, options, props) {
    return GalleryContent.getPaths(
      models,
      options,
      Object.assign({}, GalleryContent.defaultProps, props, { keyPrefix: 'recentlyadded' }),
    );
  };

  static get contextTypes() {
    return {
      getModelData: PropTypes.func.isRequired,
    };
  }

  constructor(props, context) {
    super(props, context);
    const recentlyAddedKey = _.get(context.getModelData('config'), 'recently_added.palette_key');
    if (recentlyAddedKey) {
      throw new NotFoundError({
        // @ts-ignore TS2554
        redirect_link: routes.editorial.makePath({ id: recentlyAddedKey }),
      });
    }
  }

  render() {
    return (
      <GalleryContent
        header={
          <div className="gallery-header">
            <div className="title">
              <span className="gallery-title">新着</span>
              <span className="gallery-description">1ヶ月以内に公開された作品</span>
            </div>
          </div>
        }
        // @ts-ignore TS2339
        model={this.props.pathEvaluator || this.props.model.pathEvaluator}
        keyPrefix={'recentlyadded'}
      />
    );
  }
}

export default RecentlyAdded;
