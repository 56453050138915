import _ from 'src/libs/util';
import url from 'url';

import DeleteApp from './DeleteApp';

class InterestApp extends DeleteApp {
  constructor(service) {
    super();
    service = Object.assign({}, service);
    service.pathname = _.join(_.concat(service.path, 'users/favorites/bulk_add'), '/');
    // @ts-ignore TS2339
    this._url = url.format(service);
    service.pathname = _.join(_.concat(service.path, 'profiles/update'), '/');
    // @ts-ignore TS2339
    this._updateUrl = url.format(service);
  }

  send(authContext, userId) {
    const params = {
      user_id: userId || authContext.id,
      favorites: JSON.stringify(
        _.map(this.deleteIds(), id => {
          return { model_type: 'meta', model_id: id };
        }),
      ),
    };
    return super.send(params, authContext);
  }

  _checkResponse(response) {
    return true;
    if (response.result) return true;
    const code = _.get(response, 'error.code');
    // 既に削除済み
    if (code == 8004) {
      return true;
    }
    return false;
  }

  registered(authContext, userId) {
    const params = {
      profile_id: userId,
      values: JSON.stringify({ interest_list_selected_flag: true }),
    };

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        let response = xhr.response;
        if (typeof response === 'string') {
          response = JSON.parse(response);
        }
        // console.log(response);
      }
    };

    const query = Object.keys(params)
      .map(function(key) {
        return key + '=' + encodeURIComponent(params[key]);
      })
      .join('&');

    // @ts-ignore TS2339
    xhr.open('POST', this._updateUrl, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    if (authContext) {
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
    }
    xhr.send(query);
  }
}

export default InterestApp;
