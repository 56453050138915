import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';

import ChannelCard from 'src/apps/bluerose/components/browse/Epg/ChannelCard';

export default class LinearChannelList extends Component {
  static getPaths = function(models, options, props = {}) {
    let paths = [['linearChannel', { from: 0, to: 5 }, ['id', 'name', 'refId']]];
    return paths;
  };

  static getRootPath = function(models, options, props = {}) {
    return ['linearChannel'];
  };

  static get propTypes() {
    return {
      model: PropTypes.object.isRequired,
    };
  }

  static get contextTypes() {
    return {
      routeHandler: PropTypes.object,
      models: PropTypes.object,
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    this.items = props.model.getSync(rootPath);
    this.state = {
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
    };
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  render() {
    // @ts-ignore TS2339
    if (!this.items) return null;

    // console.log(this.items)
    const simulcasts = this.context.getModelData('simulcast');
    // @ts-ignore TS2339
    const card = _.map(this.items, item => {
      const targetChannel = _.find(simulcasts, cast => cast.refId == item.refId);
      const channelName = targetChannel ? _.lowerCase(targetChannel.name) : '';
      return (
        <div className={classnames('linear-card', channelName)}>
          <div className={classnames('linear-card__name', channelName)}>
            <div></div>
          </div>
          {/*
           // @ts-ignore TS2339 */}
          <ChannelCard titleId={item.id} model={this.props.model} forceWatchLink={true} itemTabbable={true} />
        </div>
      );
    });
    return card;
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        const items = _.get(res.json, rootPath, {});
        // @ts-ignore TS2339
        this.items = _.toArray(_.omit(items, '$__path'));
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }
}
