import React from 'react';
import _ from 'src/libs/util';

class FallbackModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {};
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  handleClick() {
    // @ts-ignore TS2339
    if (this.props.closeModal) this.props.closeModal();
  }

  render() {
    return (
      <div className="modal-text">
        <p className="title">ご利用の環境では、特定の画質でのみ再生が可能です。</p>
        <p className="text">
          詳しい内容は
          <a href="https://support.wowow.co.jp/s/category?prmcat=category3" target="_blank" className="accent-color">
            お客さまサポート・よくあるご質問
          </a>
          でご確認ください。
        </p>
        <div className="btn-block">
          <button className="btn btn-fill" name="fallbackModalClose" onClick={this.handleClick}>
            閉じる
          </button>
        </div>
      </div>
    );
  }
}

export default FallbackModal;
