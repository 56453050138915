import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import _ from 'src/libs/util';

import CardContext, { withCardContext } from '../../../common/context/CardContext';
import MainViewLink from '../../../common/components/MainViewLink';
import BackgroundImage from '../../../common/components/BackgroundImage';
import routes from '../../../common/routes';
import CardBadge from './CardBadge';
import PresTrackedElement from '../../../common/components/PresTrackedElement';
import PopCard from './PopCard';
import PopEventCard from './PopEventCard';
import TitleCard from './TitleCard';
import EventCard from './Epg/EventCard';

class AdvertisingCard extends Component<any> {
  static getPaths = function(models, options, props = {}) {
    let paths = [];
    const rootPath = AdvertisingCard.getRootPath(models, options, props);
    const metaRootPath = AdvertisingCard.getMetaRootPath(models, options, props);
    paths = paths.concat([
      rootPath.concat([
        ['id', 'advertisingId', 'name', 'schemaId', 'creatives', 'url', 'description', 'cardInfo', 'oap'],
      ]),
    ]);
    if (metaRootPath.length > 0) {
      // @ts-ignore TS2339
      if (props.metaType === 'event') {
        paths = paths.concat(
          // @ts-ignore TS2554
          PopEventCard.getPaths()
            .map(path => {
              return metaRootPath.concat(path);
            })
            .concat(
              // @ts-ignore TS2339
              EventCard.getPaths().map(path => {
                return metaRootPath.concat(path);
              }),
            ),
        );
        // @ts-ignore TS2339
      } else if (props.metaType === 'linearChannel') {
        paths = paths.concat([
          metaRootPath.concat([
            { from: 0, to: 5 },
            ['id', 'name', 'shortName', 'refId', 'type', 'cardInfo', 'schemaId'],
          ]),
        ]);
      } else {
        paths = paths.concat(
          // @ts-ignore TS2554
          PopCard.getPaths()
            .map(path => {
              return metaRootPath.concat(path);
            })
            .concat(
              // @ts-ignore TS2339
              TitleCard.getPaths().map(path => {
                return metaRootPath.concat(path);
              }),
            ),
        );
      }
    }
    return paths;
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (props.advertisngId) {
      // @ts-ignore TS2339
      return ['advertising', props.advertisngId];
    }
    return [];
  };

  // 広告の遷移先のメタの情報
  static getMetaRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (props.metaType && props.metaId) {
      // @ts-ignore TS2339
      return [props.metaType, props.metaId];
      // @ts-ignore TS2339
    } else if (props.metaType) {
      // @ts-ignore TS2339
      return [props.metaType];
    }
    return [];
  };

  static getLinkProps(item = {}) {
    let linkProps = {
      // @ts-ignore TS2339
      href: item.url,
      // @ts-ignore TS2339
      params: { id: item.id },
    };
    // @ts-ignore TS2339
    if (item.palette) {
      linkProps = {
        // @ts-ignore TS2322
        to: routes.editorial,
        // @ts-ignore TS2339
        params: { id: item.palette.id },
      };
    }
    return linkProps;
  }

  static get proptTypes() {
    return {
      size: PropTypes.oneOf(['1x1', '2x1', '1x2', '2x2', '3x2']).isRequired,
      rowNum: PropTypes.number,
      onPopClose: PropTypes.func,
      onPopLeave: PropTypes.func,
      onPopOpen: PropTypes.func,
      popClasses: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    };
  }

  static get defaultProps() {
    return {
      size: '1x1',
      ellipsized: true,
      rowNum: 0,
      showScore: false,
    };
  }

  static contextType = CardContext;

  constructor(props, context) {
    super(props, context);
    this.getRelativePath = this.getRelativePath.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.onPopOpen = this.onPopOpen.bind(this);
    this.onPopWillClose = this.onPopWillClose.bind(this);
    this.onPopClose = this.onPopClose.bind(this);
    // @ts-ignore TS2339
    this.openedPop = false;
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.popType = this.popType.bind(this);
    this.fetchData = this.fetchData.bind(this);
    // @ts-ignore TS2339
    this.titleCardRef = React.createRef();

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    if (props.itemData) {
      // @ts-ignore TS2339
      this.item = props.itemData;
    } else if (rootPath) {
      // @ts-ignore TS2339
      this.item = props.model.getSync(rootPath);
    }
    this.state = {
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
    };
  }

  componentDidMount() {
    this.fetchData(this.props, this.context);
  }

  getRelativePath(url) {
    if (!url) return null;
    const host = _.get(this.context.getModelData('hosts'), 'host');
    let relativePath = _.replace(url, 'wod://', '').replace(host, '');
    if (relativePath && !relativePath.startsWith('/')) relativePath = `/${relativePath}`;
    return relativePath;
  }

  popType() {
    // @ts-ignore TS2339
    if (!this.item) return 'none';
    if (this.props.popType === 'none') return 'none';

    // タッチデバイスはpopさせない
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isTouchDevice || browserInfo.isRequestDesktopWebsite) return 'none';

    // oapとタイトルがある場合はpopCardさせる
    // @ts-ignore TS2339
    if (this.item.oap && this.item.name) {
      return 'bounce';
    }

    // @ts-ignore TS2339
    if (this.item.url) {
      // @ts-ignore TS2339
      const relativePath = this.getRelativePath(this.item.url);
      const routeList = [
        routes.program,
        routes.title,
        routes.content,
        routes.pv,
        routes.watchNow,
        routes.simulcast,
        routes.simulcastDetail,
      ];
      const shouldPop = _.some(routeList, route => route.match(relativePath));
      if (shouldPop) {
        return 'bounce';
      }
      return 'none';
    } else {
      return 'none';
    }
  }
  handleMouseOver(e) {
    if (this.popType() === 'none') {
      return;
      // @ts-ignore TS2339
    } else if (this.state.deleteMode) {
      return;
    }

    // @ts-ignore TS2339
    if (this.state.hasPop && this.mouseOutTimeoutId && this.props.onPopOpen) {
      this.props.onPopOpen(this);
    }

    // @ts-ignore TS2339
    if (this.displayTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.displayTimeoutId);
      // @ts-ignore TS2339
      delete this.displayTimeoutId;
    }

    // @ts-ignore TS2339
    if (this.mouseOutTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOutTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOutTimeoutId;
      return;
    }

    let timeout = 300;
    if (this.props.getRowHasPopOpen) {
      if (this.props.getRowHasPopOpen()) {
        timeout = 100;
      }
    }
    // @ts-ignore TS2339
    if (this.popUnmounting === true) {
      timeout = 300;
    }

    // @ts-ignore TS2339
    this.alreadyPopOpened = false;
    // @ts-ignore TS2339
    this.mouseOverTimeoutId = setTimeout(() => {
      // @ts-ignore TS2339
      delete this.mouseOverTimeoutId;
      this.setState({ hasPop: true, displayTitle: false });
    }, timeout);
  }

  handleMouseOut(e) {
    if (this.popType() === 'none') {
      return;
    }

    // まだ拡大が始まっていない時は拡大しない
    // @ts-ignore TS2339
    if (this.mouseOverTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOverTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOverTimeoutId;
    }

    // @ts-ignore TS2339
    if (!this.state.hasPop) return;

    // @ts-ignore TS2339
    const delay = this.alreadyPopOpened ? 150 : 1;
    if (this.props.onPopLeave) {
      this.props.onPopLeave(this, delay);
    }

    // @ts-ignore TS2339
    this.mouseOutTimeoutId = setTimeout(() => {
      // @ts-ignore TS2339
      delete this.mouseOutTimeoutId;
      // @ts-ignore TS2339
      this.popUnmounting = true;
      this.setState({ hasPop: false });
    }, delay);
    // @ts-ignore TS2339
    this.displayTimeoutId = setTimeout(() => {
      // @ts-ignore TS2339
      delete this.displayTimeoutId;
      this.setState({ displayTitle: true });
    }, 500);
  }

  onPopOpen() {
    if (this.popType() === 'none') {
      return;
    }

    // @ts-ignore TS2339
    this.alreadyPopOpened = true;
    // @ts-ignore TS2339
    if (this.props.onPopOpen && this.state.hasPop === true) {
      this.props.onPopOpen(this);
    }

    // @ts-ignore TS2339
    this.openedPop = true;
  }

  onPopWillClose() {
    // @ts-ignore TS2339
    this.openedPop = false;
  }

  onPopClose() {
    if (this.popType() === 'none') {
      return;
    }

    // @ts-ignore TS2339
    delete this.popUnmounting;
    if (this.props.onPopClose) {
      this.props.onPopClose(this);
    }

    this.forceUpdate();
  }

  onCardClick() {
    // @ts-ignore TS2339
    if (this.props.onCardClick) this.props.onCardClick(this.item);
  }

  fetchData(props = this.props, context = this.context) {
    const pathProps = { ...this.props };
    // @ts-ignore TS2339
    if (this.item && this.item.url) {
      // 広告の遷移先からメタ情報を引くための処理
      // @ts-ignore TS2339
      const relativePath = this.getRelativePath(this.item.url);
      // @ts-ignore TS2339
      if (this.item.palette) {
      } else if (routes.program.match(relativePath)) {
        // @ts-ignore TS2339
        pathProps.metaType = 'program';
      } else if ([routes.content, routes.pv].some(route => route.match(relativePath))) {
        // @ts-ignore TS2339
        pathProps.metaType = 'content';
      } else if ([routes.watchNow, routes.title].some(route => route.match(relativePath))) {
        // @ts-ignore TS2339
        pathProps.metaType = 'meta';
      } else if (routes.simulcastDetail.match(relativePath)) {
        // @ts-ignore TS2339
        pathProps.metaType = 'event';
      } else if (routes.simulcast.match(relativePath)) {
        // @ts-ignore TS2339
        pathProps.metaType = 'linearChannel';
        // @ts-ignore TS2339
        pathProps.channelName = relativePath.split('/')[2];
      }
      // @ts-ignore TS2339
      if (pathProps.metaType && pathProps.metaType !== 'linearChannel') {
        // @ts-ignore TS2339
        if (pathProps.metaType === 'event') {
          // @ts-ignore TS2339
          pathProps.metaId = relativePath.split('/')[3];
        } else {
          // @ts-ignore TS2339
          pathProps.metaId = relativePath.split('/')[2];
        }
      }
    }
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, pathProps);
    // @ts-ignore TS2339
    const metaRootPath = this.constructor.getMetaRootPath(context.models, {}, pathProps);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(context.models, {}, pathProps);
    // @ts-ignore TS2339
    const evaluator = props.model.fetch(paths);
    this.setState({ dispose: evaluator.dispose });
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        if (!props.itemData) {
          // @ts-ignore TS2339
          this.item = _.get(res.json, rootPath, {});
        }
        if (metaRootPath.length > 0) {
          let result = _.get(res.json, metaRootPath, {});
          // @ts-ignore TS2339
          if (pathProps.metaType === 'linearChannel') {
            const items = _.omit(result, ['$__path']);
            // @ts-ignore TS2339
            const simulcast = _.find(context.getModelData('simulcast'), item => item.name === pathProps.channelName);
            const linearChannel = _.find(items, item => item.refId == simulcast.refId) || {};
            result = linearChannel;
          }
          // @ts-ignore TS2339
          this.titleModel = result;
        }
        const newState = {
          fetchDataError: null,
          dispose: null,
        };
        this.setState(newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        this.setState(newState);
      });
  }

  render() {
    // @ts-ignore TS2339
    if (!this.item) return null;

    const key = `advertisng-card-${this.props.rowNum}-${this.props.rankNum}-${this.props.advertisngId}`;
    const tabIndex = this.props.itemTabbable ? 0 : -1;
    let creative = null;
    if (this.context.artType === 'keyart') {
      // @ts-ignore TS2339
      creative = _.find(this.item.creatives, { display_type: 'masthead' });
    } else if (this.context.artType === 'poster') {
      // @ts-ignore TS2339
      creative = _.find(this.item.creatives, { display_type: 'poster' });
    } else if (this.context.artType === 'square') {
      // @ts-ignore TS2339
      creative = _.find(this.item.creatives, { display_type: 'square' });
    }
    // @ts-ignore TS2339
    if (!creative) creative = _.find(this.item.creatives, { display_type: 'thumbnail' });
    // @ts-ignore TS2339
    if (!creative) creative = _.find(this.item.creatives, { display_type: 'square' });
    // @ts-ignore TS2339
    if (!creative) creative = _.find(this.item.creatives, { display_type: 'default' });
    if (!creative) return null;
    // @ts-ignore TS2339
    let titleModel = this.item;
    let titleId;
    // @ts-ignore TS2339
    if (this.titleModel && (this.titleModel.id || this.titleModel.uniqueId)) {
      titleModel = Object.assign(
        {},
        // @ts-ignore TS2339
        { ...this.titleModel },
        // @ts-ignore TS2339
        { oap: this.item.oap ? this.item.oap : this.titleModel.oap },
      );
      // @ts-ignore TS2339
      titleId = this.titleModel.id;
    }
    let detailNode = null;
    // @ts-ignore TS2339
    if (!_.isEmpty(this.item) && this.state.hasPop && this.popType() !== 'none') {
      // @ts-ignore TS2339
      let popThumbnail = _.find(this.item.creatives, { display_type: 'thumbnail' });
      if (titleModel && titleModel.uniqueId) {
        detailNode = (
          <PopEventCard
            model={this.props.model}
            // @ts-ignore TS2339
            key={`item_${this.item.id}`}
            classes={classnames(this.props.popClasses)}
            popType={this.popType()}
            titleCardImage={popThumbnail && popThumbnail.url ? popThumbnail.url : null}
            titleModel={titleModel}
            artKind={this.context.artKind}
            // myListButton={myListButton}
            // @ts-ignore TS2322
            deliveryStartVisible={this.props.deliveryStartVisible}
            keyPrefix={this.props.keyPrefix}
            listContext={this.props.listContext}
            listType={this.props.listType}
            ellipsized={true}
            useTitleCardImage={true}
          />
        );
      } else {
        detailNode = (
          <PopCard
            model={this.props.model}
            // @ts-ignore TS2339
            key={`item_${this.item.id}`}
            classes={classnames(this.props.popClasses)}
            popType={this.popType()}
            titleCardImage={popThumbnail && popThumbnail.url ? popThumbnail.url : null}
            titleModel={titleModel}
            // @ts-ignore TS2322
            titleId={titleId}
            artKind={this.context.artKind}
            // myListButton={myListButton}
            showEpisodeNumber={this.props.showEpisodeNumber}
            deliveryStartVisible={this.props.deliveryStartVisible}
            keyPrefix={this.props.keyPrefix}
            listContext={this.props.listContext}
            listType={this.props.listType}
            useTitleCardImage={true}
            useViewingModel={['linear_channel', 'media'].includes(titleModel.type)}
          />
        );
      }
    }
    let cardBadge = (
      <CardBadge
        // @ts-ignore TS2339
        item={this.item}
        mbListLayout={this.props.mbListLayout}
        // @ts-ignore TS2339
        deleteMode={this.state.deleteMode}
        keyPrefix={this.props.keyPrefix}
        listContext={this.props.listContext}
        listType={this.props.listType}
        isOnArt={!this.props.listCard}
      />
    );

    // @ts-ignore TS2339
    const linkProps = AdvertisingCard.getLinkProps(this.item);
    let titleDisplaySetting = this.props.titleDisplaySetting;
    if (titleDisplaySetting === 'default') {
      titleDisplaySetting = 'under_card';
      // @ts-ignore TS2339
    } else if (this.item && titleDisplaySetting === 'media_only' && this.item.type !== 'media') {
      titleDisplaySetting = 'none';
    }
    const onCard = titleDisplaySetting === 'on_card' || titleDisplaySetting === 'media_only';

    // mbListLayoutがgridの場合はpalette.titleTypeを見る
    // table,boxはタイトルは必須
    let titleContent = (
      <div className="text-wrapper">
        {!this.props.listCard ? null : cardBadge}
        <div className={classnames('title-card-title', { ellipsized: this.props.ellipsized })}>
          {/*
           // @ts-ignore TS2339 */}
          {this.props.ellipsized ? <p>{this.item.name}</p> : this.item.name}
        </div>
      </div>
    );
    let titleShow = true;
    // @ts-ignore TS2339
    if (this.item.name && !onCard) {
      if (this.props.mbListLayout == 'grid') {
        if (this.props.titleType == 'hidden' || this.context.titleType == 'hidden' || this.props.titleHidden === true) {
          titleShow = false;
        } else {
          titleShow = true;
        }
      } else {
        if (this.props.mbListLayout == 'table') {
          titleShow = true;
        } else if (
          this.props.titleType !== 'hidden' ||
          this.context.titleType !== 'hidden' ||
          this.props.titleHidden === false
        ) {
          titleShow = true;
        } else if (this.props.viewType == 'list') {
          titleShow = true;
        } else {
          titleShow = false;
        }
      }
      if (titleDisplaySetting == 'none') {
        titleShow = false;
      }
      // @ts-ignore TS2339
    } else if (this.item.name && onCard) {
      if (this.props.mbListLayout == 'table') {
        titleShow = true;
      } else if (this.props.titleType !== 'hidden' || this.context.titleType !== 'hidden') {
        titleShow = true;
      } else if (this.props.viewType == 'list') {
        titleShow = true;
      } else {
        titleShow = false;
      }
    }
    return (
      <MainViewLink
        {...linkProps}
        // @ts-ignore TS2322
        onClick={this.onCardClick}
        tabIndex={tabIndex}
        className={classnames('card', 'title-card', `size-${this.props.size}`, {
          'on-card':
            onCard &&
            !(
              (this.context.titleType === 'hidden' || this.props.titleType === 'hidden') &&
              (this.context.textArea === 'hidden' || this.props.textArea === 'hidden')
            ),
          list: this.context.viewType === 'list',
          keyart: this.context.artType === 'keyart',
          poster: this.context.artType === 'poster',
          square: this.context.artType === 'square',
        })}
      >
        <div
          className={classnames('sliderRefocus', {
            // @ts-ignore TS2339
            hasPop: this.state.hasPop === true,
            lockup: true,
            'title-card-tall-artwork': this.context.artType === 'poster',
          })}
          // @ts-ignore TS2339
          aria-label={this.item.name}
          // @ts-ignore TS2339
          ref={this.titleCardRef}
          role={'link'}
          key={key}
          id={key}
          onMouseEnter={this.handleMouseOver}
          onMouseLeave={this.handleMouseOut}
        >
          {/*
           // @ts-ignore TS2322 */}
          <PresTrackedElement idDoubleWide={this.props.isDoubleWide}>
            {this.props.listCard ? null : cardBadge}
            <BackgroundImage
              // @ts-ignore TS2322
              className={classnames('artwork', { 'artwork-tall': this.context.artType === 'poster' })}
              url={creative.url}
            />
            <div className="video-preload-title">
              {/*
               // @ts-ignore TS2339 */}
              <div className="video-preload-title-label">{this.item.name}</div>
            </div>
          </PresTrackedElement>
          <TransitionGroup component={null}>
            {detailNode ? (
              <CSSTransition
                classNames="pop-bounce"
                onEnter={this.onPopOpen}
                onExit={this.onPopWillClose}
                onExited={this.onPopClose}
                timeout={{ exit: 400, enter: 400 }}
              >
                {detailNode}
              </CSSTransition>
            ) : null}
          </TransitionGroup>
          {/*
           // @ts-ignore TS2339 */}
          {onCard && titleShow ? null : onCard && this.item.name ? (
            <div className="title-card-title">
              {/*
               // @ts-ignore TS2339 */}
              <p>{this.item.name}</p>
            </div>
          ) : null}
        </div>
        {titleShow ? titleContent : null}
      </MainViewLink>
    );
  }

  getZoomScale() {
    return this.props.isTallRow === true ? 1.2 : this.popType() === 'bounce' ? 1.55 : 1.95;
  }
}

const root = withCardContext(AdvertisingCard);
// @ts-ignore TS2339
root.getPaths = AdvertisingCard.getPaths;
// @ts-ignore TS2339
root.getLinkProps = AdvertisingCard.getLinkProps;
export default root;
