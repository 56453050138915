import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';
import Falcor from 'falcor';

import Row from '../Row';
import EpisodeCard from '../EpisodeCard';
import LoadingSlider from '../../loader/LoadingSlider';
import LoadingTitle from '../../loader/LoadingTitle';
import MainViewLink from '../../../../common/components/MainViewLink';
import routes from '../../../../common/routes';
import GalleryContent from '../GalleryContent';
import SwitchGallery from '../SwitchGallery';
import * as browserEvents from '../../../../../sketch-platform/utils/browserEvents';
import * as DOMUtils from '../../../../../sketch-platform/utils/DOMUtils';
import { CardContextProvider } from '../../../../common/context/CardContext';
import { CLICK_AREA, CONTENT_EVENTS } from '../../../../../common/GtmApp';
import { GroupMeta } from 'src/types/context/Meta';

type EpisodesRowProps = {
  model: Falcor.Model;
  [key: string]: any;
};

type EpisodesRowState = {
  sortOrder?: string;
  [key: string]: any;
};

export default class EpisodesRow extends Component<EpisodesRowProps, EpisodesRowState> {
  static pathKeys = ['defaultSort', 'supportSorts'];

  static getPaths = function(models, options, props) {
    const rootPath = this.getRootPath(models, options, props);
    let paths = [];
    if (props.sortOrder) {
      let pathSet = [props.type];
      if (props.hierarchyType && props.hierarchyType.key) {
        pathSet.push(props.hierarchyType.key);
        pathSet.push('f');
      }
      pathSet.push(props.sortOrder);
      // @ts-ignore TS2339
      paths = EpisodeCard.getPaths().map(path => {
        return rootPath.concat(
          pathSet.concat(
            [
              {
                from: (props && props.fromNum) || 0,
                to:
                  props && props.toNum ? props.toNum : (props && props.fromNum ? props.fromNum : 0) + props.perPage - 1,
              },
            ].concat(path),
          ),
        );
      });
      paths = paths.concat([rootPath.concat(pathSet.concat(['length']))]);
    }
    return paths.concat([rootPath.concat([this.pathKeys])]);
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (props.id) {
      // @ts-ignore TS2339
      return ['meta', props.id];
    }
    return [];
  };

  static get propTypes() {
    return {
      isLoading: PropTypes.bool,
      onSliderMove: PropTypes.func,
      showEpisodeDuration: PropTypes.bool,
      showEpisodeNumber: PropTypes.bool,
      showEpisodeSummary: PropTypes.bool,
      trackId: PropTypes.number,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      model: PropTypes.object.isRequired,
      itemsInRow: PropTypes.number,
      perPage: PropTypes.number,
      hierarchyType: PropTypes.object,
    };
  }

  static get defaultProps() {
    return {
      type: 'episodes',
      perPage: 50,
    };
  }

  static get contextTypes() {
    return {
      currentPage: PropTypes.string,
      routeHandler: PropTypes.object,
      models: PropTypes.object,
      getI18nString: PropTypes.func,
      cookies: PropTypes.object,
      columnsInRow: PropTypes.number,
      getModelData: PropTypes.func,
      gtmApp: PropTypes.object,
    };
  }

  static get childContextTypes() {
    return {
      showSeasonTitle: PropTypes.bool,
      columnsInRow: PropTypes.number,
    };
  }

  _isMounted?: boolean;
  item?: GroupMeta;
  isFirstSetOfResults: boolean;

  constructor(props, context) {
    super(props, context);

    this.isFirstSetOfResults = true;
    // @ts-ignore TS2339
    this.numTitlesToFetch = props.perPage;
    this.handleSliderMove = this.handleSliderMove.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleSortOrderChange = this.handleSortOrderChange.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.onPopOpen = this.onPopOpen.bind(this);
    this.onPopLeave = this.onPopLeave.bind(this);
    this.onPopClose = this.onPopClose.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);
    // @ts-ignore TS2339
    this.sliderRef = React.createRef();
    // @ts-ignore TS2339
    this.rowRef = React.createRef();
    this.handleOpenGallery = this.handleOpenGallery.bind(this);
    // @ts-ignore TS2339
    this.episodesRef = React.createRef();

    const browserInfo = context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      this.onScroll = _.throttle(this.onScroll.bind(this), 100);
    }

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, null, props);
    // @ts-ignore TS2339
    this.item = this.props.model.getSync(rootPath) || {};

    this.state = {
      dispose: null,
      sortOrder: this.item?.defaultSort,
      fetchDataError: null,
      isPopOpen: false,
      openGallery: false,
    };
    // @ts-ignore TS2339
    this.state.supportSorts = [
      { so: 'ena', name: '新しい順' },
      { so: 'end', name: '古い順' },
    ];
    // @ts-ignore TS2339
    this.episodes = [];
  }

  getChildContext() {
    const browserInfo = this.context.getModelData('browserInfo');
    const columnsInRow = browserInfo.isIOS || browserInfo.isAndroid ? 1 : this.context.columnsInRow;
    return {
      // @ts-ignore TS2339
      showSeasonTitle: this.props.type === 'seriesEpisodes',
      columnsInRow,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('scroll', this.onScroll);
    }
    this.fetchData(this.props);
    if (this.context.focusManager) {
      // @ts-ignore TS2339
      const el = ReactDOM.findDOMNode(this.rowRef.current);
      if (el) {
        this.context.focusManager.collectFocasableElements(el);
      }
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // @ts-ignore TS2339
    if (nextProps.id !== this.props.id) {
      // @ts-ignore TS2339
      this.isFirstSetOfResults = true;
      // @ts-ignore TS2339
      this.episodes = [];
      // @ts-ignore TS2339
      if (this.state.dispose) this.state.dispose();
      this.setState({ fetchDataError: null, dispose: null, fromNum: 0 }, () => {
        this.fetchData(nextProps);
      });
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('scroll', this.onScroll);
    }
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  onScroll(e) {
    // console.log('Episodes.js: onScroll')
    // @ts-ignore TS2339
    if (!this.state.openGallery) return;
    if (this.isWithinDistanceBuffer(300)) {
      // @ts-ignore TS2554
      this.fetchMoreRows(true);
    }
  }

  isWithinDistanceBuffer(distanceBuffer) {
    // @ts-ignore TS2339
    if (!this.episodesRef) return;
    // @ts-ignore TS2339
    const el = this.episodesRef.current;
    if (el) {
      return DOMUtils.getDistanceToBottomOfElement(el) < distanceBuffer;
    }
    return false;
  }

  fetchMoreRows() {
    // console.log('Episodes.js: fetchMoreRows')
    // @ts-ignore TS2339
    if (this.episodes && this.numTitlesToFetch > this.episodes.length && !this.state.fetchingMoreRows) {
      // @ts-ignore TS2339
      this.state.fetchingMoreRows = true;
      this.fetchData(this.props);
    }
  }

  handleSliderMove(nextState, actionType) {
    if (
      nextState != undefined &&
      // @ts-ignore TS2339
      this.numTitlesToFetch > this.episodes.length &&
      // @ts-ignore TS2339
      nextState.lowestVisibleItemIndex >= this.episodes.length - 10 &&
      // @ts-ignore TS2339
      !this.state.fetchingMoreRows
    ) {
      // @ts-ignore TS2339
      this.state.fetchingMoreRows = true;
      this.fetchData(this.props);
    }
    // @ts-ignore TS2339
    if (this.props.onSliderMove) {
      // @ts-ignore TS2339
      this.props.onSliderMove();
    }
  }

  handleNextClick() {
    // @ts-ignore TS2339
    if (this.numTitlesToFetch > this.episodes.length && !this.state.fetchingMoreRows) {
      // @ts-ignore TS2339
      this.state.fetchingMoreRows = true;
      this.fetchData(this.props);
    }
  }

  handleSortOrderChange(so) {
    if (so.so) so = so.so;
    // @ts-ignore TS2339
    if (so !== this.state.sortOrder) {
      // @ts-ignore TS2339
      this.isFirstSetOfResults = true;
      // @ts-ignore TS2339
      this.episodes = [];
      // @ts-ignore TS2339
      if (this.state.dispose) this.state.dispose();
      this.setState({ sortOrder: so, dispose: null, generation: -1, fetchDataError: null, fromNum: 0 }, () => {
        this.fetchData(this.props);
      });
    }
  }

  handleOpenGallery() {
    // @ts-ignore TS2339
    if (this.state.openGallery) this.scrollToEpisodeTop();
    // @ts-ignore TS2339
    const openGallery = !this.state.openGallery;
    // @ts-ignore TS2339
    if (this.props.onChangeQuery) {
      // @ts-ignore TS2339
      this.props.onChangeQuery({ gallery: openGallery ? 'open' : 'close' });
    }
    this.setState({ openGallery });
  }

  handleCardClick(item) {
    this.sendToGtm(item);
  }

  scrollToEpisodeTop() {
    // @ts-ignore TS2339
    const el = this.episodesRef.current;
    // el.offsetTopが0になる
    if (el) browserEvents.scrollTo(0, el.parentNode.offsetTop);
  }

  sendToGtm(item) {
    if (!_.get(this.context, 'gtmApp')) return;
    const hasRelationProgram = _.get(item, 'seriesMeta') && _.get(item, 'seasonMeta');
    const relationProgram = hasRelationProgram
      ? {
          refId: _.get(item, 'seriesMeta.refId'),
          name: _.get(item, 'seriesMeta.name'),
        }
      : null;
    const program = {
      refId: _.get(item, 'seasonMeta.refId') || _.get(item, 'seriesMeta.refId'),
      name: _.get(item, 'seasonMeta.name') || _.get(item, 'seriesMeta.name'),
    };
    const content = {
      refId: _.get(item, 'refId'),
      name: _.get(item, 'name'),
      rental: _.get(item, 'rental'),
      subscription: _.get(item, 'subscription'),
    };
    const attributes = _.get(item, 'attributes');
    const genres = _.get(item, 'genres');
    const middleGenres = _.get(item, 'middleGenres');
    const schemaId = _.get(item, 'schemaId');
    this.context.gtmApp.pushDataLayerOnContentPageClick(
      CONTENT_EVENTS.CONTENT_CLICK,
      { relationProgram, program, content, attributes, genres, middleGenres, schemaId },
      { clickArea: CLICK_AREA.THUMBNAIL },
    );
  }

  render() {
    // 想定外な親子関係の場合に0になりヘッダーだけ表示される状態になるので消し込む
    // @ts-ignore TS2339
    if (this.totalCount == 0) return null;
    const browserInfo = this.context.getModelData('browserInfo');

    let spinLoader;
    // @ts-ignore TS2339
    if ((browserInfo.isIOS || browserInfo.isAndroid) && this.state.fetchingMoreRows === true) {
      spinLoader = (
        <div className="gallery-spin-loader">
          <div className="loader-cont"></div>
        </div>
      );
    }

    // @ts-ignore TS2339
    let itemsInRow = this.props.itemsInRow;
    if (!itemsInRow) {
      itemsInRow = this.context.columnsInRow > 2 ? this.context.columnsInRow - 1 : 2;
    }

    const episodesLinkProps = {
      to: routes.titleEpisodes,
      // @ts-ignore TS2339
      params: { id: this.props.id },
      query: {},
    };
    // @ts-ignore TS2339
    if (this.props.selectedSeasonId !== 'all') {
      // @ts-ignore TS2339
      episodesLinkProps.query.season_id = this.props.id;
    }
    // @ts-ignore TS2339
    if (this.state.supportSorts.length > 1) {
      // @ts-ignore TS2339
      episodesLinkProps.query.so = this.state.sortOrder;
    }
    // @ts-ignore TS2339
    if (this.props.hierarchyType.key) {
      // @ts-ignore TS2339
      episodesLinkProps.query.ht = this.props.hierarchyType.key;
    }

    const rowProps = {};
    // @ts-ignore TS2339
    rowProps.enableLooping = false;
    // @ts-ignore TS2339
    const headerTitle = this.props.hierarchyType.name ? this.props.hierarchyType.name : 'エピソード';
    const titles = this.renderChildren();
    // @ts-ignore TS2339
    const titlesLenght = this.totalCount > 0 ? ' (' + this.totalCount + ')' : null;

    const sortGalleryProps = {
      // @ts-ignore TS2339
      model: this.props.model,
      // @ts-ignore TS2339
      sortOrder: this.state.sortOrder,
      onChangeQuery: this.handleSortOrderChange,
    };
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      const showOpen = 4 < titles.length;
      return (
        <div
          // @ts-ignore TS2339
          ref={this.episodesRef}
          // @ts-ignore TS2339
          className={classnames('episodes-wrapper', { close: showOpen && !this.state.openGallery })}
        >
          <GalleryContent
            header={
              <div className="gallery-header">
                <div className="title">
                  <span className="gallery-title">
                    <MainViewLink {...episodesLinkProps}>{headerTitle}</MainViewLink>
                  </span>
                  <div className="row-arrow" data-tracking-click="more">
                    <div className="see-more-link">すべて見る</div>
                    <div className="row-chevron icon-caret-right fa fa-angle_right"></div>
                  </div>
                </div>
                {titles.length > 1 ? <SwitchGallery {...sortGalleryProps} /> : null}
              </div>
            }
            // @ts-ignore TS2322
            listType={true}
          >
            {titles}
          </GalleryContent>
          {spinLoader}
          {showOpen ? (
            <div className="open-gallery">
              <div className="btn btn-very-small" onClick={this.handleOpenGallery}>
                {/*
                 // @ts-ignore TS2339 */}
                {this.state.openGallery ? (
                  <React.Fragment>
                    閉じる<i className="fa-angle_up"></i>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    もっと見る<i className="fa-angle_down"></i>
                  </React.Fragment>
                )}
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <div className="episodes-wrapper">
        <div className="section-header">
          <h2>
            <div className="section-title">
              {/*
               // @ts-ignore TS2322 */}
              <MainViewLink {...episodesLinkProps} data-tracking-click="more" className="title">
                <div className="section-header-title">
                  {headerTitle}
                  <span>{titlesLenght}</span>
                </div>
                <div className="row-arrow" data-tracking-click="more">
                  <div className="see-more-link">すべて見る</div>
                  <div className="row-chevron icon-caret-right fa fa-angle_right"></div>
                </div>
              </MainViewLink>
              {/*
               // @ts-ignore TS2339 */}
              {this.state.supportSorts.length > 1 ? <SwitchGallery {...sortGalleryProps} /> : null}
            </div>
          </h2>
        </div>
        {/*
         // @ts-ignore TS2339 */}
        {this.isFirstSetOfResults && this.state.dispose ? (
          <div className="episode-row-content">
            <LoadingSlider
              // @ts-ignore TS2322
              itemsInRow={
                // @ts-ignore TS2339
                this.props.itemsInRow
                  ? // @ts-ignore TS2339
                    this.props.itemsInRow
                  : this.context.columnsInRow > 2
                  ? this.context.columnsInRow - 1
                  : 2
              }
              pulsate={true}
              displayWhenNotPulsing={true}
              lockupClassName={'card episode-card'}
              titleCardLoading={true}
            />
          </div>
        ) : (
          <Row
            {...rowProps}
            // @ts-ignore TS2339
            totalItems={this.totalCount}
            // @ts-ignore TS2339
            rowNum={this.props.rowNum}
            numOfItemRows={1}
            // @ts-ignore TS2339
            loading={this.state.loading}
            handleSliderMove={this.handleSliderMove.bind(this)}
            // @ts-ignore TS2322
            handleItemClick={this.handleCardClick}
            // @ts-ignore TS2339
            listContext={this.props.listContext}
            // @ts-ignore TS2322
            loadingComponent={<LoadingTitle titleCardLoading={true} />}
            enablePaginationIndicator={false}
            enablePeek={true}
            enablePushOut={false}
            //episodeCardSize：エピソードの時はcolumInRow（スマホサイズ）の時強制的に50%サイズにする
            episodeCardSize={true}
          >
            {titles}
          </Row>
        )}
      </div>
    );
  }

  renderChildren() {
    const browserInfo = this.context.getModelData('browserInfo');
    return _.compact(
      // @ts-ignore TS2339
      this.episodes.map((episode, index) => {
        if (!episode.id) return;
        return (
          <CardContextProvider
            // @ts-ignore TS2339
            key={`provider_episode_${this.props.id}_${index}_${episode.episodeNumebr}`}
            value={this.getChildContext()}
          >
            <EpisodeCard
              // @ts-ignore TS2322
              model={this.props.model}
              rankNum={episode.episodeNumber}
              episodeModel={episode}
              episodeId={episode.id}
              popType="bounce"
              listType={browserInfo.isIOS || browserInfo.isAndroid ? true : false}
              // @ts-ignore TS2339
              key={`episode_${this.props.id}_${index}_${episode.episodeNumebr}`}
              enableCardClick={browserInfo.isIOS || browserInfo.isAndroid ? true : null}
              sliderItemId={`item_${index}`}
              // @ts-ignore TS2339
              paletteName={this.props.hierarchyType.name ? this.props.hierarchyType.name : 'エピソード'}
              paletteNum={index}
              // @ts-ignore TS2339
              numb={this.props.numb}
              // @ts-ignore TS2339
              selected={episode.id == this.props.selectedEpisodeId}
              // @ts-ignore TS2339
              onChangeEpisode={this.props.onChangeEpisode}
              onCardClick={this.handleCardClick}
              // @ts-ignore TS2339
              keyPrefix={this.props.keyPrefix}
            />
          </CardContextProvider>
        );
      }),
    );
  }

  onPopOpen(targetCard) {
    // @ts-ignore TS2339
    if (this.popAnimationId && this.activeCard === targetCard) {
      // @ts-ignore TS2339
      clearTimeout(this.popAnimationId);
      // @ts-ignore TS2339
      delete this.popAnimationId;
    }

    // @ts-ignore TS2339
    this.activeCard = targetCard;

    this.setState({ isPopOpen: true });

    // @ts-ignore TS2339
    if (!this.sliderRef.current) {
      return;
    }

    // @ts-ignore TS2339
    const targetSliderItem = this.sliderRef.current.itemRefs[targetCard.props.sliderItemId];
    const targetViewportPosition = targetSliderItem.props.viewportPosition;

    const zoomSize = targetCard.getZoomScale();
    const getWidth = function(elem) {
      if (!elem) {
        return 0;
      }
      return elem.getBoundingClientRect().width || elem.offsetWidth || 0;
    };
    const width = Math.ceil((getWidth(targetCard.titleCardRef.current) * (zoomSize - 1.0)) / 2.0);
    const slide = ReactDOM.findDOMNode(targetSliderItem);
    if (!slide) return;

    const defaultStyle =
      'transition-duration: 400ms; transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1); transition-delay: 0ms;';

    let offset = 0;
    if (targetViewportPosition === 'leftEdge') {
      offset = width;
    } else if (targetViewportPosition === 'rightEdge') {
      offset = -width;
    }
    // @ts-ignore TS2339
    if (this.props.enablePushOut !== true) {
      // @ts-ignore TS2339
      ReactDOM.findDOMNode(targetCard).setAttribute(
        'style',
        `z-index: 99999; transform: translate3d(${offset}px, 0px, 0px); ${defaultStyle}`,
      );
      // @ts-ignore TS2339
      slide.style.zIndex = 99999;
      // @ts-ignore TS2339
      slide.style.transform = 'translate3d(0px, 0px, 0px)';
    } else {
      // @ts-ignore TS2339
      slide.setAttribute('style', `z-index: 99999; transform: translate3d(${offset}px, 0px, 0px); ${defaultStyle}`);
    }

    // @ts-ignore TS2339
    if (this.props.enablePushOut !== true) {
      return;
    }

    // 両サイドのSliderItemの位置を重ならないように移動する

    let elem = slide;
    // 左側のSliderItemの処理
    offset = -1 * width;
    if (targetViewportPosition === 'rightEdge') {
      offset = offset * 2;
    } else if (targetViewportPosition === 'leftEdge') {
      offset = 0;
    }
    while ((elem = elem.previousElementSibling)) {
      if (elem.classList.contains('slider-item') && !elem.classList.contains('slider-item-')) {
        elem.setAttribute('style', `transform: translate3d(${offset}px, 0px, 0px); ${defaultStyle}`);
      }
    }

    // 右側のSliderItemの処理
    elem = slide;
    offset = width;
    if (targetViewportPosition === 'leftEdge') {
      offset = offset * 2;
    } else if (targetViewportPosition === 'rightEdge') {
      offset = 0;
    }
    while ((elem = elem.nextElementSibling)) {
      if (elem.classList.contains('slider-item') && !elem.classList.contains('slider-item-')) {
        elem.setAttribute('style', `transform: translate3d(${offset}px, 0px, 0px); ${defaultStyle}`);
      }
    }
  }

  onPopLeave(targetCard, delay = 0) {
    // @ts-ignore TS2339
    this.popAnimationId = setTimeout(() => {
      // @ts-ignore TS2339
      delete this.popAnimationId;
      // @ts-ignore TS2339
      if (this.activeCard !== targetCard && this.activeCard !== undefined) {
        // @ts-ignore TS2339
        if (this.props.enablePushOut !== true) {
          // @ts-ignore TS2339
          if (this.sliderRef.current) {
            // @ts-ignore TS2339
            const targetSliderItem = this.sliderRef.current.itemRefs[targetCard.props.sliderItemId];
            // 同じスライドItemの場合は処理を行わない
            // @ts-ignore TS2339
            if (targetCard.props.sliderItemId !== this.activeCard.props.sliderItemId) {
              const slide = ReactDOM.findDOMNode(targetSliderItem);
              if (slide) {
                // @ts-ignore TS2339
                slide.style.zIndex = 2;
                // @ts-ignore TS2339
                slide.style.transform = 'translate3d(0px, 0px, 0px)';
              }
            }
          }

          const container = ReactDOM.findDOMNode(targetCard);
          if (container) {
            // @ts-ignore TS2339
            container.style.zIndex = 2;
            // @ts-ignore TS2339
            container.style.transform = 'translate3d(0px, 0px, 0px)';
          }
        }
        return;
      }

      const container = ReactDOM.findDOMNode(targetCard);
      if (container) {
        // @ts-ignore TS2339
        if (parseInt(container.style.zIndex, 10) === 4) {
          // @ts-ignore TS2339
          container.style.zIndex = 2;
        }
        // @ts-ignore TS2339
        container.style.transform = 'translate3d(0px, 0px, 0px)';
      }

      // @ts-ignore TS2339
      const sliderDOM = ReactDOM.findDOMNode(this.sliderRef.current);
      if (sliderDOM) {
        // @ts-ignore TS2339
        Array.prototype.forEach.call(sliderDOM.querySelectorAll('.slider-item:not(.slider-item-)'), slide => {
          if (parseInt(slide.style.zIndex, 10) === 4) {
            slide.style.zIndex = 2;
          }
          // @ts-ignore TS2339
          if (this.props.enablePushOut === true) {
            slide.style.transform = 'translate3d(0px, 0px, 0px)';
          }
        });
      }
    }, delay);
  }

  onPopClose(targetCard) {
    // @ts-ignore TS2339
    if (this.activeCard !== targetCard) {
      // @ts-ignore TS2339
      if (this.props.enablePushOut !== true) {
        // @ts-ignore TS2339
        if (this.sliderRef.current) {
          // @ts-ignore TS2339
          const targetSliderItem = this.sliderRef.current.itemRefs[targetCard.props.sliderItemId];
          const slide = ReactDOM.findDOMNode(targetSliderItem);
          if (slide) {
            // @ts-ignore TS2339
            slide.removeAttribute('style');
          }
        }
        const card = ReactDOM.findDOMNode(targetCard);
        if (card) {
          // @ts-ignore TS2339
          card.removeAttribute('style');
        }
      }
      return;
    }

    // @ts-ignore TS2339
    delete this.activeCard;
    this.setState({ isPopOpen: false });
    // @ts-ignore TS2339
    const sliderDOM = ReactDOM.findDOMNode(this.sliderRef.current);
    if (sliderDOM) {
      // @ts-ignore TS2339
      Array.prototype.forEach.call(sliderDOM.querySelectorAll('.slider-item:not(.slider-item-)'), slide => {
        slide.removeAttribute('style');
      });
      Array.prototype.forEach.call(
        // @ts-ignore TS2339
        sliderDOM.querySelectorAll('.slider-item:not(.slider-item-) .episode-card'),
        container => {
          container.removeAttribute('style');
        },
      );
    }
  }

  async fetchSortData(props: EpisodesRowProps) {
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    if (this.state[JSON.stringify(paths)]) return;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
    // @ts-ignore TS2339
    this.state[JSON.stringify(paths)] = paths;
    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    if (!this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    await new Promise(resolve => {
      let newState = {};
      evaluator
        .then(res => {
          newState = {
            fetchDataError: null,
            dispose: null,
            fetchingMoreRows: undefined,
          };

          if (res) {
            // @ts-ignore TS2339
            this.item = _.get(res, `json.meta.${props.id}`);
            // @ts-ignore TS2339
            newState.sortOrder = this.item.defaultSort || 'df';
          }
        })
        .catch(e => {
          const newState = {
            fetchDataError: e,
            fetchingMoreRows: undefined,
            dispose: null,
          };
        })
        .finally(() => {
          // @ts-ignore TS2339
          delete this.state[JSON.stringify(paths)];
          if (this._isMounted) {
            this.setState(newState, () => {
              resolve(null);
            });
          } else {
            this.state = newState;
            resolve(null);
          }
        });
    });
  }

  async fetchData(props) {
    if (!props.id) return;

    // ソート情報がない場合は取得する
    if (!this.state.sortOrder) {
      await this.fetchSortData(props);
    }

    const pathProps = Object.assign(
      {},
      props,
      // @ts-ignore TS2339
      { toNum: this.numTitlesToFetch - 1 },
      // @ts-ignore TS2339
      { sortOrder: this.state.sortOrder },
    );
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    // @ts-ignore TS2339
    this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (!this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    // @ts-ignore TS2339
    const beforeItemLength = this.episodes ? this.episodes.length : 0;
    evaluator
      .then(res => {
        const newState = {
          fetchDataError: null,
          dispose: null,
          fetchingMoreRows: undefined,
        };

        if (res) {
          // @ts-ignore TS2339
          this.item = _.get(res, `json.meta.${props.id}`);
          const dataPath = props.hierarchyType.key
            ? // @ts-ignore TS2339
              `json.meta.${props.id}.${props.type}.${props.hierarchyType.key}.f.${this.state.sortOrder}`
            : // @ts-ignore TS2339
              `json.meta.${props.id}.${props.type}.${this.state.sortOrder}`;
          const data = _.get(res, dataPath);
          // @ts-ignore TS2339
          this.episodes = _.clone(_.values(_.omitBy(data, (val, key) => key.indexOf('$') === 0 || key === 'length')));
          if (data.hasOwnProperty('length')) {
            // @ts-ignore TS2339
            this.totalCount = data.length;
          }
        } else {
          // @ts-ignore TS2339
          this.totalCount = this.episodes.length;
        }

        // @ts-ignore TS2339
        this.numTitlesToFetch += props.perPage;
        // @ts-ignore TS2339
        if (this.numTitlesToFetch > this.totalCount) this.numTitlesToFetch = this.totalCount;
        // @ts-ignore TS2339
        delete this.state[JSON.stringify(paths)];
        this.isFirstSetOfResults = false;

        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState(newState, () => {
            // @ts-ignore TS2339
            if (beforeItemLength >= this.episodes.length) {
              // @ts-ignore TS2339
              this.numTitlesToFetch = this.episodes.length;
            }
          });
        } else {
          Object.assign(this.state, newState);
        }
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        // @ts-ignore TS2339
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = newState;
      });
  }
}
