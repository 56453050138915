import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';

import TargetTitle from './TargetTitle';
import Input from './Input';

import volumeDataStore from '../../../../../utils/volumeDataStore';

export default class RoomJoin extends Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }

  static get propTypes() {
    return {
      player: PropTypes.object,
      handleRoomClosed: PropTypes.func,
      handleNoVacancy: PropTypes.func,
      handleDuplicateEntry: PropTypes.func,
      watchPartyMode: PropTypes.bool,
      handleWatchPartyModeChange: PropTypes.func,
      closeModal: PropTypes.func,
      titleModel: PropTypes.object,
      seriesModel: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.nameRef = React.createRef();
    // @ts-ignore TS2339
    this.passRef = React.createRef();
    this._handleNameChange = this._handleNameChange.bind(this);
    this._handlePassChange = this._handlePassChange.bind(this);
    this._handleEnter = this._handleEnter.bind(this);

    const profole = context.watchPartyApp.getProfile();
    this.state = {
      name: profole.name,
      pass: '',
      errorMessageNickname: null,
      errorMessageRoomKey: null,
      isSubmittable: true,
      showDescription: false,
    };

    this._handleWatchPartyStart = this._handleWatchPartyStart.bind(this);
    this._handleWatchPartyCancel = this._handleWatchPartyCancel.bind(this);
  }

  componentDidMount() {
    this.setState({ showDescription: true });
  }

  _handleNameChange(e) {
    this.setState({ name: e.target.value, errorMessageNickname: null });
  }

  _handlePassChange(e) {
    this.setState({ pass: e.target.value, errorMessageRoomKey: null });
  }

  _handleEnter(e) {
    if (e.keyCode == 13) {
      this._handleWatchPartyStart();
    }
  }

  _handleWatchPartyStart() {
    // console.log('_handleWatchPartyStart');
    // @ts-ignore TS2339
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      this.joinRoom();
    });
  }

  _handleWatchPartyCancel() {
    // ボリュームの状態を復元
    const volumeData = volumeDataStore.get() || {};
    const muted = volumeData.muted || false;
    // @ts-ignore TS2339
    this.props.player.muted(muted);

    this.context.watchPartyApp.dispose();
    // @ts-ignore TS2339
    this.props.closeModal();
  }

  joinRoom() {
    // ボリュームの状態を復元
    const volumeData = volumeDataStore.get() || {};
    const muted = volumeData.muted || false;
    // @ts-ignore TS2339
    this.props.player.muted(muted);

    // @ts-ignore TS2339
    if (this.context.watchPartyApp.checkPass(this.state.pass)) {
      this.context.watchPartyApp
        .initClient()
        .then(() => {
          // @ts-ignore TS2339
          this.context.watchPartyApp.joinWithName(this.state.name, { joinRoom: 1 }, (error, body) => {
            this.setState({ loading: false });
            // console.log(error, body);

            // NGワード
            if (_.has(error, 'message') && -1 !== error.message.indexOf('includes ng word')) {
              this.setState({ errorMessageNickname: '不適切な表現が含まれています' });
              return;
            }

            // バックエンド側のアクセス人数制限
            if (_.has(error, 'message.0')) {
              // Error: reached to room user limit. [limit=20]"
              if (-1 !== error.message[0].indexOf('room user limit')) {
                // @ts-ignore TS2339
                this.props.handleNoVacancy();
                return;
              }
            }

            if (error) {
              // TODO: error処理

              // 出席人数制限
              if (error.message === 'room user limit') {
                // @ts-ignore TS2339
                this.props.handleNoVacancy();
                return;
              }

              // すでに入室した時
              if (error.message === 'user is joined') {
                // @ts-ignore TS2339
                this.props.handleDuplicateEntry();
                return;
              }

              console.log(error);
              return;
            }

            // @ts-ignore TS2339
            this.props.closeModal();
            // @ts-ignore TS2339
            this.props.handleWatchPartyModeChange();
          });
        })
        .catch(e => {
          console.log(e);
          this.setState({ loading: false });

          // ルームが終了した時
          if (_.has(e, 'message.0')) {
            if (-1 !== e.message[0].indexOf('Not Found')) {
              // @ts-ignore TS2339
              this.props.handleRoomClosed();
            }
          }
        });
    } else {
      // Error パスワードが不正
      this.setState({ loading: false });
      this.setState({ errorMessageRoomKey: 'パスワードが不正です。' });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="modal-title">ウォッチパーティに参加</div>
        <div className="text-left">
          <div className="modal-block">
            <TargetTitle
              // @ts-ignore TS2339
              titleModel={this.props.titleModel}
              // @ts-ignore TS2339
              seriesModel={this.props.seriesModel}
              // @ts-ignore TS2339
              linearModel={this.props.linearModel}
            />
          </div>
          <div className="modal-block">
            <Input
              // @ts-ignore TS2339
              ref={this.nameRef}
              label={'ニックネーム'}
              type="text"
              placeholder={'ニックネームを入力してください'}
              maxLength={8}
              required={false}
              // @ts-ignore TS2322
              onChange={this._handleNameChange}
              onKeyDown={this._handleEnter}
              // @ts-ignore TS2339
              value={this.state.name}
              // @ts-ignore TS2339
              errorMessage={this.state.errorMessageNickname}
            />
            <Input
              // @ts-ignore TS2339
              ref={this.passRef}
              label={'パスワード'}
              type="text"
              placeholder={'パスワードを入力してください'}
              maxLength={6}
              required={true}
              // @ts-ignore TS2322
              onChange={this._handlePassChange}
              onKeyDown={this._handleEnter}
              // @ts-ignore TS2339
              value={this.state.pass}
              // @ts-ignore TS2339
              errorMessage={this.state.errorMessageRoomKey}
            />
            <div className="input-block">
              {/*
               // @ts-ignore TS2339 */}
              {this.state.showDescription && (
                <p className="small-text">
                  再生/一時停止などは、ルーム主催者のみが操作でき、ルーム参加者は操作できません。
                  {/* <br />ウォッチパーティに参加するには、<a href='https://www.hjholdings.jp/chat_guideline' target='_blank' class='text-accent'>チャットガイドライン</a> への同意が必要です。 */}
                </p>
              )}
            </div>
          </div>
          <div className="form-btn-block text-center">
            <a className="btn btn-gray btn-small" href="javascript:void(0);" onClick={this._handleWatchPartyCancel}>
              キャンセル
            </a>
            <a
              className={classnames('btn btn-fill btn-small', {
                // @ts-ignore TS2339
                disabled: _.isEmpty(this.state.name) || _.isEmpty(this.state.pass),
              })}
              href="javascript:void(0);"
              onClick={this._handleWatchPartyStart}
            >
              同意して入室
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
