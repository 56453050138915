import React from 'react';
import _ from 'src/libs/util';

//
// props
// - PaymentInstrument
//
export default props => {
  let content;

  if (!props.paymentInstrument) {
    return null;
  }

  if (_.get(props.paymentInstrument, 'paymentMethod.payMethod') == 'card') {
    content = (
      <React.Fragment>
        <i className={`fa ${_.get(props.paymentInstrument, 'paymentDetail.cardTypeClassName')}`}></i>
        •••• •••• •••• {_.get(props.paymentInstrument, 'paymentDetail.cardNumber')}
      </React.Fragment>
    );
  } else {
    content = _.get(props.paymentInstrument, 'paymentMethod.displayName');
  }

  return content;
};
