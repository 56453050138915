import React, { useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import _ from 'src/libs/util';
import routes from 'src/apps/common/routes';
import { CUSTOM_EVENTS } from 'src/common/GtmApp';

export const RETURN_ROUTES = [
  routes.watchNow,
  routes.content,
  routes.pv,
  routes.program,
  routes.title,
  routes.simulcast,
  routes.simulcastDetail,
];

type LoginButtonProps = {
  className?: string;
};

const LoginButton = (props: LoginButtonProps, context) => {
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      const query = {};
      const pathname = _.get(window, 'location.pathname', '');
      // watch・titleページの場合はログインのリンクにreturn_toをつけて遷移させる
      if (RETURN_ROUTES.some(route => route.match(pathname))) {
        query['return_to'] = pathname;
      }
      context.gtmApp.pushDataLayerOnCustomClick(CUSTOM_EVENTS.LOGIN_CLICK);
      window.location.href = routes.login.makePath({}, query);
      return false;
    },
    [context],
  );

  return (
    <button className={classnames('btn btn-fill grey-color btn-very-small', props.className)} onClick={onClick}>
      ログイン
    </button>
  );
};

LoginButton.contextTypes = {
  gtmApp: PropTypes.object,
};

export default LoginButton;
