import { useMemo } from 'react';
import _ from 'src/libs/util';

import * as ERROR from 'src/constants/error';
import { getIsDeliveryEnded } from 'src/apps/common/utils/metaUtil';
import useEnableProducts from 'src/apps/common/components/UpSellFlow/ViewingPlan/hooks/useEnableProducts';
import useShowSubscriptionPlan from 'src/apps/common/components/UpSellFlow/ViewingPlan/hooks/useShowSubscriptionPlan';

import { Course } from 'src/types/context/Course';
import { Product } from 'src/types/context/Product';
import { Meta } from 'src/types/context/Meta';
import { Event } from 'src/types/context/Event';
import { Profile } from 'src/types/context/Profile';

type UseShowPlanInfoArgs = {
  courses?: Course[];
  products?: Product[];
  meta?: Meta;
  event?: Event;
  error?: any;
  profile: Profile;
};
const useShowPlanInfo = ({ courses = [], products = [], meta, event, error = {}, profile }: UseShowPlanInfoArgs) => {
  const enableProducts = useEnableProducts({ products, isProductPage: false });
  const showSubscriptionPlan = useShowSubscriptionPlan({ courses, meta, event, profile });
  const showPPVPlan = useMemo(() => enableProducts.length > 0, [enableProducts.length]);

  const showPlanInfo = useMemo(() => {
    // 未ログイン・権利なしエラー以外は出さない
    const isShowErrorType = [ERROR.UNAUTHORIZED, ERROR.LICENSE_INVALID].includes(error.type);
    // 配信終了していれば出さない
    const isDeliveryEnded = getIsDeliveryEnded(_.get(meta, 'cardInfo.deliveryEndAt'));
    // 同時配信対象外の場合は出さない
    const isNoSimulcast = event && event.isMask;

    return isShowErrorType && !isDeliveryEnded && !isNoSimulcast && (showSubscriptionPlan || showPPVPlan);
  }, [error.type, event, meta, showPPVPlan, showSubscriptionPlan]);

  return showPlanInfo;
};

export default useShowPlanInfo;
