import _ from 'src/libs/util';

export default function getOrientation() {
  if (typeof window === 'undefined') return;

  let orientation;
  /**正面設定が縦で縦向き・正面設定が横で縦向き */
  const verticalTypes = ['portrait-primary', 'portrait-secondary'];
  /**正面設定が横で横向き・正面設定が縦で横向き */
  const horizontalTypes = ['landscape-primary', 'landscape-secondary'];
  // @ts-ignore TS2551
  const type = window.screen.msOrientation || window.screen.mozOrientation || (window.screen.orientation || {}).type;
  if (type) {
    if (_.includes(verticalTypes, type)) orientation = 'vertical';
    else if (_.includes(horizontalTypes, type)) orientation = 'horizontal';
  }
  // safariなど上に対応していないものもあるため
  if (!orientation) {
    // 縦
    if (window.orientation % 180 === 0) orientation = 'vertical';
    // 横
    else orientation = 'horizontal';
  }
  return orientation;
}
