import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';

import SearchInput from '../../../SearchInput';
import SearchFilter from '../../../../../bluerose/components/browse/search/SearchFilter';
import keywordDataStore from '../../../../../../utils/keywordDataStore';
import routes from '../../../../routes';
import activeProfile from '../../../../../../utils/activeProfile';

type SearchBoxProps = {
  hideLabel?: boolean;
  handleSearchBoxFocus?: (searchBoxFocused) => void;
  term?: string;
  handleMenuClick?: (openMenu, target) => void;
  showHeaderSearchInput?: boolean;
  enableIncrementalSearch?: boolean;
  handleSearchInputFocus?: (focused) => void;
  showFilter?: boolean;
};

type SearchBoxState = {
  isClosing: boolean;
  isUserFocusing: boolean;
  stringDirection: string;
  term?: string;
  showSearchMenu: boolean;
  showFilterMenu: boolean;
  url?: string;
  direction?: string;
};
class SearchBox extends React.PureComponent<SearchBoxProps, SearchBoxState> {
  searchInputRef: React.RefObject<SearchInput>;
  static get defaultProps() {
    return {
      term: '',
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      routeHandler: PropTypes.object,
      routeHandlers: PropTypes.array,
      history: PropTypes.object,
      isRtl: PropTypes.bool,
      authApp: PropTypes.object,
      i18n: PropTypes.object,
      i18nContext: PropTypes.string,
      service: PropTypes.string,
    };
  }
  private _canDynamicSearch: boolean;

  constructor(props, context) {
    super(props, context);
    const { routeHandlers } = context;
    this._canDynamicSearch = !!_.find(routeHandlers, function(routeHandler) {
      return routeHandler.route._regex === routes.search._regex;
    });
    this.searchInputRef = React.createRef();
    this.changeHistory = this.changeHistory.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearchBoxFocus = this.handleSearchBoxFocus.bind(this);
    this.handleSearchInputFocus = this.handleSearchInputFocus.bind(this);
    this.handleSearchInputBlur = this.handleSearchInputBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearchButton = this.handleSearchButton.bind(this);
    this.toggleSearchClick = this.toggleSearchClick.bind(this);

    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.toggleFilterClick = this.toggleFilterClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.state = {
      isClosing: false,
      isUserFocusing: true,
      stringDirection: 'ltr',
      term: props.term,
      showSearchMenu: false,
      showFilterMenu: false,
    };
  }

  componentDidMount() {
    if (this.state.isUserFocusing || this.state.term.length > 0) {
      // そのままフォーカスすると、先頭にカーソルがきてしまうので
      // 一度空にした状態でフォーカスし、そのあと文字を設定することで
      // カーソルを文字の末尾に移動させる

      let dom = ReactDOM.findDOMNode(this);
      if (dom instanceof Element) {
        let elm = dom.querySelector('input');
        let val = elm.value;
        elm.value = '';
        if (!this.props.showHeaderSearchInput) {
          elm.focus();
        }
        elm.value = val;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this._canDynamicSearch && !_.isEqual(this.props, nextProps)) {
      this.setState({ term: nextProps.term });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.searchBoxFocused &&
      !this.state.isUserFocusing &&
      this.state.term.length === 0 &&
      !this.state.isClosing
    ) {
      if (this.props.handleSearchBoxFocus) {
        this.props.handleSearchBoxFocus(false);
      }
    } else if (!prevProps.searchBoxFocused && (this.state.isUserFocusing || this.state.term.length > 0)) {
      if (this.props.handleSearchBoxFocus) {
        this.props.handleSearchBoxFocus(true);
      }
    }
  }

  componentWillUnmount() {}

  changeHistory(term) {
    const { routeHandler, history } = this.context;
    if (term.length > 0) {
      const query = Object.assign({}, routeHandler.query, { q: term });
      // @ts-ignore TS2554
      const to = routes.search.makePath({ service: this.context.service }, query);
      if (routeHandler.route._regex === routes.search._regex) {
        const canGoBack = !!history.location.state?.canGoBack;
        history.replace(to, { canGoBack: canGoBack });
      } else {
        this.setState({ url: routeHandler.url });
        history.push(to, { canGoBack: !!this.state.url });
      }
    } else {
      if (history.length > 1 && history.location.state?.canGoBack) {
        history.goBack();
      } else {
        history.push('/');
      }
    }
  }

  handleChange(term) {
    this.setState({ term });
    if (this._canDynamicSearch && this.props.enableIncrementalSearch !== false) {
      this.changeHistory(term);
    }
  }

  handleSearchInputFocus() {
    this.setState({
      isUserFocusing: true,
    });
    if (this.props.handleSearchInputFocus) {
      this.props.handleSearchInputFocus(true);
    }
  }

  handleSearchInputBlur() {
    this.setState({
      isClosing: this.searchInputRef.current && this.state.term.length === 0,
      isUserFocusing: false,
    });
    if (this.props.handleSearchBoxFocus) {
      this.props.handleSearchBoxFocus(this.state.term.length > 0);
    }
    if (this.props.handleSearchInputFocus) {
      this.props.handleSearchInputFocus(false);
    }

    if (this.searchInputRef.current && this.state.term.length === 0) {
      this.searchInputRef.current.close(() => {
        this.setState({
          isClosing: false,
        });
      });
    }
  }

  handleSearchBoxFocus() {
    this.setState(
      {
        isUserFocusing: true,
      },
      () => {
        ReactDOM.findDOMNode(this)
          // @ts-ignore TS2339
          .querySelector('input')
          .focus();
      },
    );
    if (this.props.handleSearchBoxFocus) {
      this.props.handleSearchBoxFocus(true);
    }
  }

  handleKeyDown(e) {
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isAndroid || browserInfo.isIOS) {
      if (e.which === 13) {
        this.toggleSearchClick();
      }
    }
    if (e.which === 13) {
      this.toggleSearchClick();
    }
    const { history } = this.context;
    if (e.which === 13) {
      // 空の場合は処理しない
      if (this.state.term.length === 0) return;

      const profile = activeProfile(this.context.models);
      keywordDataStore.add(_.get(profile, 'id'), this.state.term);

      if (this._canDynamicSearch && this.props.enableIncrementalSearch !== false) {
        return;
      }
      if (this._canDynamicSearch && this.props.enableIncrementalSearch === false) {
        this.changeHistory(this.state.term);
        return;
      }
      e.preventDefault();
      // @ts-ignore TS2554
      window.location.href = routes.search.makePath({ service: this.context.service }, { q: this.state.term });
      return;
    }
  }

  handleSearchButton(e) {
    // 空の場合は処理しない
    if (this.state.term.length === 0) return;
    if (this._canDynamicSearch && this.props.enableIncrementalSearch !== false) {
      return;
    }
    if (this._canDynamicSearch && this.props.enableIncrementalSearch === false) {
      this.changeHistory(this.state.term);
      return;
    }
    e.preventDefault();
    // @ts-ignore TS2554
    window.location.href = routes.search.makePath({ service: this.context.service }, { q: this.state.term });
  }

  toggleSearchClick() {
    if (this.props.handleMenuClick) this.props.handleMenuClick('searchKeyword', document.getElementById('searchTab'));
  }

  handleChangeQuery(query) {
    // @ts-ignore TS2339
    if (this._isMounted) this.setState({ query: query });
  }

  toggleFilterClick() {
    this.setState({ showFilterMenu: !this.state.showFilterMenu });
  }

  handleSearch() {
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isAndroid || browserInfo.isIOS) {
      this.toggleFilterClick();
    }
  }

  render() {
    // @ts-ignore TS2339
    const model = this.props.pathEvaluator || this.props.model.pathEvaluator;

    return (
      <div className={classnames('search-element', { open: this.state.showSearchMenu })}>
        <div className="search-element-box">
          <SearchInput
            ref={this.searchInputRef}
            direction={this.state.direction}
            // @ts-ignore TS2322
            onSearchBoxClick={this.onSearchBoxClick}
            onClickSearchButton={this.handleSearchButton}
            onChange={this.handleChange}
            term={this.state.term}
            onKeyDown={this.handleKeyDown}
            onFocus={this.handleSearchInputFocus}
            onBlur={this.handleSearchInputBlur}
          />
        </div>
        {this.props.showFilter ? (
          <div className="search-element-box">
            <div className={classnames('search-filter-menu-cont', { open: this.state.showFilterMenu })}>
              <SearchFilter
                // @ts-ignore TS2322
                model={model}
                searchFilter={this.context.routeHandler ? this.context.routeHandler.query['sf'] || '' : ''}
                onChangeQuery={this.handleChangeQuery}
                enableIncrementalSearch={this.props.enableIncrementalSearch}
                onSearch={this.handleSearch}
                key="searchFilter"
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default SearchBox;
