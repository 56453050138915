import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

export default class RoomEntried extends Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }
  static get propTypes() {
    return {
      handleWatchPartyModeChange: PropTypes.func,
      closeModal: PropTypes.func,
      userState: PropTypes.string,
    };
  }
  constructor(props, context) {
    super(props, context);

    this._handleClose = this._handleClose.bind(this);
  }

  _handleClose() {
    this.context.watchPartyApp.dispose();
    // @ts-ignore TS2339
    this.props.closeModal();
  }

  render() {
    return (
      <React.Fragment>
        <div className="modal-title">ウォッチパーティ参加の確認</div>
        <div className="text-left">
          <div className="modal-block">
            <p>
              {/*
               // @ts-ignore TS2322 */}
              すでに<span class="text-accent">{this.props.room.name}</span>に参加しています。
            </p>
          </div>
          <div className="form-btn-block text-center">
            <a className="btn btn-gray btn-small" href="javascript:void(0);" onClick={this._handleClose}>
              閉じる
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
