import React, { useCallback } from 'react';
import _ from 'src/libs/util';

// @ts-ignore TS2306
import nl2br from '../../../../common/nl2br';

const ProductDetailModal = (props, context) => {
  const onClickClose = useCallback(() => {
    if (props.onClickClose) props.onClickClose();
  }, [props]);

  return (
    <div className="product-detail-modal">
      <div className="modal-text">
        <div className="title">
          {props.product.name}
          <span>の商品詳細</span>
        </div>
        <p className="text text-left" dangerouslySetInnerHTML={{ __html: nl2br(props.product.productDescription) }}></p>
      </div>
      <div className="btn-block">
        <button className="btn btn-fill grey-color" onClick={onClickClose}>
          閉じる
        </button>
      </div>
    </div>
  );
};

export default ProductDetailModal;
