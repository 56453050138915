'use strict';
import _ from 'src/libs/util';

export default function(models, ratingCode: string) {
  const ratingCodesJson = _.get(models, 'config.data.rating_codes');
  if (!ratingCodesJson) {
    return;
  }
  if (_.isArray(ratingCode)) return ratingCode.map(code => ratingCodesJson[code]);
  return ratingCodesJson[ratingCode];
}
