import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';

class SearchSuggestions extends React.PureComponent {
  static getPaths = function(models, options, props) {
    let paths = [];
    const indexes = function(models, options, props) {
      return {
        from: (props && props.fromNum) || 0,
        to:
          props && props.toNum
            ? props.toNum - (props.fromNum || 0)
            : options && options.numItems
            ? options.numItems
            : props.perPage - 1,
      };
    };
    const rootPath = this.getRootPath(models, options, props);
    return paths
      .concat([
        rootPath
          .concat([props.types.join(',')])
          .concat(indexes(models, options, props))
          .concat([['id', 'name', 'type']]),
      ])
      .concat([rootPath.concat([props.types.join(',')]).concat(['length'])]);
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    return ['attributes', 'byTerm', `|${props.term}`];
  };

  static get propTypes() {
    return {
      model: PropTypes.object,
      perPage: PropTypes.number,
      types: PropTypes.array,
    };
  }

  static get defaultProps() {
    return {
      perPage: 20,
      types: ['person', 'league', 'team'], //['genre', 'person', 'studio'],
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      routeHandler: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      dispose: null,
      fetchDataError: null,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // @ts-ignore TS2339
    if (nextProps.term !== this.props.term) {
      // @ts-ignore TS2339
      this.items = [];
      this.setState({ fetchDataError: null, generation: -1 }, () => {
        this.fetchData(nextProps);
      });
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        const data = _.get(res.json, rootPath.concat(props.types.join(',')), {});
        // @ts-ignore TS2339
        this.items = _.clone(_.values(_.omitBy(data, (val, key) => key.indexOf('$') === 0 || key === 'length')));
        if (data.hasOwnProperty('length')) {
          // @ts-ignore TS2339
          this.totalCount = data.length;
        }
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }

  render() {
    // @ts-ignore TS2339
    if (_.isEmpty(this.items)) return null;

    const getLink = item => {
      const props = {
        params: { id: item.id },
      };
      switch (item.type) {
        case 'genre':
          // @ts-ignore TS2339
          props.to = routes.genre;
          break;
        case 'person':
          // @ts-ignore TS2339
          props.to = routes.person;
          break;
        case 'studio':
          // @ts-ignore TS2339
          props.to = routes.studio;
          break;
        case 'league':
          // @ts-ignore TS2339
          props.to = routes.league;
          break;
        case 'team':
          // @ts-ignore TS2339
          props.to = routes.team;
          break;
        default:
          return item.name;
      }
      return <MainViewLink {...props}>{item.name}</MainViewLink>;
    };
    const typeJp = {
      person: '人物',
      league: 'リーグ・大会',
      team: 'チーム',
    };
    // @ts-ignore TS2339
    const suggestions = _.map(this.props.types, type => {
      // @ts-ignore TS2339
      const targets = _.filter(this.items, item => item.type === type);
      if (_.isEmpty(targets)) return null;
      return (
        <React.Fragment>
          <span className="label">{`${typeJp[type]}の検索結果`}</span>
          <ul>
            {targets.map(item => {
              return <li key={`attribute-link-${item.id}`}>{getLink(item)}</li>;
            })}
          </ul>
        </React.Fragment>
      );
    });

    return (
      <div className="rail">
        <div className="suggestion-container">{suggestions}</div>
      </div>
    );
  }
}

export default SearchSuggestions;
