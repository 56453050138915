import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import _ from 'src/libs/util';
import qs from 'query-string';
import http from 'http';
import https from 'https';

class Axios {
  api: AxiosInstance;
  constructor(options = {}) {
    if (!_.has(options, 'httpAgent')) options = _.assign({ httpAgent: new http.Agent({ keepAlive: true }) }, options);
    if (!_.has(options, 'httpsAgent'))
      options = _.assign({ httpsAgent: new https.Agent({ keepAlive: true }) }, options);
    // @ts-ignore TS2339
    this.options = options;

    this.api = axios.create(_.assign({ responseType: 'json' }, options));

    // タイムアウトしていないのにタイムアウトエラーがでる対応ととして
    // retryオプションを使えるようにする
    // https://github.com/axios/axios/issues/164#issuecomment-327837467
    // @ts-ignore TS2339
    if (options.retry) {
      const _axios = this.api;

      // retry時はconfigのfilterが困難だったので、
      // urlとmethodだけ出力する
      const paramsFilter = function(config) {
        return JSON.stringify({
          url: _.get(config, 'url'),
          method: _.get(config, 'method'),
        });
      };

      // _axios.interceptors.request.use(config => {
      //   console.log('LLLLL _axios.interceptors LLLLL');
      //   console.log(config);
      //   return config;
      // });

      _axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
        var config = err.config;
        // If config does not exist or the retry option is not set, reject
        if (!config || !config.retry) return Promise.reject(err);

        // Set the variable for keeping track of the retry count
        config.__retryCount = config.__retryCount || 0;

        // Check if we've maxed out the total number of retries
        if (config.__retryCount >= config.retry) {
          // Reject with the error
          return Promise.reject(err);
        }

        // Increase the retry count
        config.__retryCount += 1;

        // axios v0.17ではデフォルトのオプションでagentを指定し、リクエスト時にもagentを指定すると、
        // 循環参照しているマージ処理の際にMaximum call stack size exceededが起きる
        // https://github.com/axios/axios/issues/370
        config = _.omit(config, ['httpAgent', 'httpsAgent']);

        // Create new promise to handle exponential backoff
        var backoff = new Promise(function(resolve) {
          setTimeout(function() {
            // @ts-ignore TS2794
            resolve();
          }, config.retryDelay || 1);
        });

        // Return the promise in which recalls axios to retry the request
        return backoff.then(function() {
          return _axios.request(config);
        });
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetch<T = any>(config) {
    // console.log(config)

    // nodejsでreq.xhr == true で判断するためにはヘッダを付与する必要があるため
    // optionsでxhr:trueを渡すと自動でヘッダが付与されるようにしている
    // @ts-ignore TS2339
    if (this.options.xhr) {
      _.set(config, 'headers["X-Requested-With"]', 'XMLHttpRequest');
    }
    return new Promise<AxiosResponse<T>>((resolve, reject) => {
      this.api
        .request(config)
        .then(function() {
          resolve.apply(this, arguments);
        })
        .catch((e: AxiosError) => {
          // エラー時はリクエスト内容をログ出力する
          const statusCode = _.get(e, 'response.status');
          if (!statusCode || 500 <= statusCode) {
            const params = config;
            this.logger.error('AXIOS_REQUEST_ERROR', params);
          }

          reject(e);
        });
    });
  }

  get<T = any>(url: string, params = {}, options = {}) {
    const queryString = qs.stringify(params);
    if (queryString && queryString.length > 0) {
      if (url.indexOf('?') >= 0) {
        url += '&' + queryString;
      } else {
        url += '?' + queryString;
      }
    }
    return this.fetch<T>(Object.assign({}, { method: 'get', url: url }, options)).then(res => {
      return { data: res.data };
    });
  }

  post<T = any>(url: string, data?: any, options = {}) {
    return this.fetch<T>(Object.assign({}, { method: 'post', url: url, data: data }, options)).then(res => {
      return { data: res.data };
    });
  }

  patch<T = any>(url: string, data?: any, options = {}) {
    return this.fetch<T>(Object.assign({}, { method: 'patch', url: url, data: data }, options)).then(res => {
      return { data: res.data };
    });
  }

  del(url: string, options = {}) {
    return this.fetch(Object.assign({}, { method: 'delete', url: url }, options)).then(res => {
      return { data: res.data };
    });
  }

  put<T = any>(url: string, data?: any, options = {}) {
    return this.fetch<T>(Object.assign({}, { method: 'put', url: url, data: data }, options)).then(res => {
      return { data: res.data };
    });
  }

  formPost<T = any>(url: string, data: any, options = {}) {
    return this.post<T>(url, data, options);
  }

  multi(requests) {
    return axios.all(requests).then(
      axios.spread((...response) => {
        return response;
      }),
    );
  }

  get logger() {
    if (_.get(this, 'options.logger')) {
      // @ts-ignore TS2339
      return this.options.logger;
    }
    return console;
  }
}

export default Axios;
