import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

import { getShowChasingPlayBack } from '../../../common/utils/liveUtil';
import routes from '../../../common/routes';
import MainViewLink from '../../../common/components/MainViewLink';
import getRatingText from 'src/utils/getRatingText';

const Duration = props => {
  let text;
  if (!!props.seasonCount) {
    text = `${props.seasonCount}シーズン`;
  } else if (!!props.episodeCount) {
    text = `${props.episodeCount}エピソード`;
  } else if (!!props.episodeRuntime) {
    // 時間に合わせる
    let runtime = parseInt(props.episodeRuntime, 10);
    let seconds = runtime % 60;
    let minutes = ((runtime - seconds) / 60) % 60;
    let hour = Math.floor(runtime / 3600);
    if (hour > 0) {
      if (minutes > 0) {
        text = `${hour}時間${minutes}分`;
      } else {
        text = `${hour}時間`;
      }
    } else if (minutes > 0) {
      text = `${minutes}分`;
    } else {
      text = `${seconds}秒`;
    }
  }
  if (!text) return null;

  return <span className="duration">{text}</span>;
};

const isMetaEmpty = value => {
  if (value === 0) {
    return true;
  }
  if (typeof value === 'number') {
    return false;
  }
  if (_.isEmpty(value)) {
    return true;
  }
  return false;
};

const Rating = (props, context) => {
  const ratingText = getRatingText(context.models, props.rating);
  if (!ratingText) return null;
  if (_.isArray(ratingText)) {
    return _.map(ratingText, (rating, i) => {
      return (
        <span className="rating" key={`rating-${i}`}>
          {rating}
        </span>
      );
    });
  }
  return (
    <span className="rating" key="rating">
      {ratingText}
    </span>
  );
};

Rating.contextTypes = {
  models: PropTypes.object,
};

const getLinkProps = (genre, to, toRef, uniqueId) => {
  let linkProps = {
    to: to,
    params: { id: genre.id },
  };
  if (genre.refId && toRef) {
    linkProps = {
      to: toRef,
      params: { id: genre.refId },
    };
  }
  if (uniqueId) {
    // @ts-ignore TS2339
    linkProps.query = { type: 'ev' };
  }
  // スマホでタグをタップした時に遷移させるようにする
  // @ts-ignore TS2339
  linkProps.onClick = function(e) {
    e.stopPropagation();
  };
  return linkProps;
};
const Meta = (props, context) => {
  const metadata = props.metadata;
  const cardInfo = metadata.cardInfo || {};
  if (
    isMetaEmpty(cardInfo) &&
    isMetaEmpty(cardInfo.productionYear) &&
    isMetaEmpty(cardInfo.rating) &&
    isMetaEmpty(cardInfo.seasonCount) &&
    isMetaEmpty(cardInfo.episodeCount) &&
    isMetaEmpty(cardInfo.episodeRuntime) &&
    isMetaEmpty(cardInfo.episodeNumberTitle) &&
    isMetaEmpty(cardInfo.broadcastEndAt) &&
    isMetaEmpty(cardInfo.deliveryEndAt) &&
    isMetaEmpty(metadata.chasingPlayback) &&
    isMetaEmpty(metadata.genres) &&
    isMetaEmpty(metadata.systemGenres) &&
    isMetaEmpty(metadata.tags)
  ) {
    return null;
  }

  // 追っかけ再生表示
  const chasingPlayback = (
    <span className="chasingPlayback" key="chasingPlayback">
      追っかけ再生あり
    </span>
  );
  const showChaseingPlayBack = getShowChasingPlayBack(metadata);

  const hideGenreIds = _.get(context, 'hideGenreIds', []);
  const genres = metadata.genres ? _.filter(metadata.genres, genre => !_.find(hideGenreIds, id => id == genre.id)) : [];

  const systemGenres = metadata.systemGenres
    ? _.filter(metadata.systemGenres, genre => !_.find(hideGenreIds, id => id == genre.id))
    : [];
  return (
    <div className="top-metadata">
      {showChaseingPlayBack ? chasingPlayback : null}
      {!!cardInfo.episodeNumberTitle ? <span className="episode-number">{cardInfo.episodeNumberTitle}</span> : null}
      {!props.hiddenYear && !!cardInfo.productionYear ? (
        <span className="year" key="year">
          {cardInfo.productionYear}
        </span>
      ) : null}
      {!!props.metadata.uniqueId && !!props.metadata.ritem ? (
        <span className="rating" key="rating">
          {props.metadata.ritem}
        </span>
      ) : null}
      {/*
       // @ts-ignore TS2786 */}
      {!!cardInfo.rating ? <Rating rating={cardInfo.rating} /> : null}
      <Duration
        seasonCount={cardInfo.seasonCount}
        episodeCount={cardInfo.episodeCount}
        episodeRuntime={cardInfo.episodeRuntime}
      />
      {!props.hiddenGenre ? (
        <React.Fragment>
          {!_.isEmpty(genres)
            ? genres.map((genre, e) => {
                const linkProps = getLinkProps(genre, routes.genre, routes.genreRef, metadata.uniqueId);
                return (
                  // @ts-ignore TS2322
                  <MainViewLink className="hashtag" key={`taglist-genres-${genre.id}`} {...linkProps}>
                    #{genre.name}
                  </MainViewLink>
                );
              })
            : null}
          {!_.isEmpty(systemGenres)
            ? systemGenres.map((attr, e) => {
                // @ts-ignore TS2554
                const linkProps = getLinkProps(attr, routes.attr);
                return (
                  // @ts-ignore TS2322
                  <MainViewLink className="hashtag" key={`taglist-genres-${attr.id}`} {...linkProps}>
                    #{attr.name}
                  </MainViewLink>
                );
              })
            : null}
          {!!metadata.tags || !_.isEmpty(metadata.tags)
            ? metadata.tags.map((tag, i) => {
                return (
                  <span className="hashtag" key={`tag-${i}`}>
                    #{tag}
                  </span>
                );
              })
            : null}
        </React.Fragment>
      ) : null}
    </div>
  );
};

Meta.getPaths = function(models, options, props) {
  return [[['cardInfo']]];
};

Meta.propTypes = {
  metadata: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hiddenGenre: PropTypes.bool,
};

Meta.contextTypes = {
  hideGenreIds: PropTypes.array,
};

export default Meta;
