import React from 'react';
import _ from 'src/libs/util';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MainViewLink from '../../../common/components/MainViewLink';
import GalleryTab from './GalleryTab';
import routes from '../../../common/routes';

export default class MyListGalleryHeader extends React.Component {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.links = [
      // @ts-ignore TS2322
      <MainViewLink className="active" to={routes.mylist}>
        お気に入り
      </MainViewLink>,
        // @ts-ignore TS2322
      <MainViewLink className="active" to={routes.continueWatching}>
        視聴中
      </MainViewLink>,
      // @ts-ignore TS2322
      <MainViewLink className="active" to={routes.onRental}>
        購入
      </MainViewLink>,
    ];

    const routeHandler = context.routeHandler;
    // @ts-ignore TS2339
    const activeTabIndex = _.findIndex(this.links, linkComponent => {
      // @ts-ignore TS2339
      return routeHandler.path.match(linkComponent.props.to._regex) !== null;
    });
    this.handleChangeDeleteMode = this.handleChangeDeleteMode.bind(this);
    this.state = {
      activeTabIndex: activeTabIndex,
      deleteMode: '',
    };
  }

  handleChangeDeleteMode(deleteMode) {
    this.setState({ deleteMode });
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');
    const userInfo = this.context.getModelData('userInfo');
    // @ts-ignore TS2339
    const currentLink = this.links[this.state.activeTabIndex];
    const isCurrentContinueWatching = currentLink && routes.continueWatching._regex === currentLink.props.to._regex;

    let deleteContent = null;
    // @ts-ignore TS2339
    if (this.props.deleteContent) {
      // @ts-ignore TS2339
      deleteContent = React.cloneElement(this.props.deleteContent, { onChangeDeleteMode: this.handleChangeDeleteMode });
    }
    return (
      <React.Fragment>
        <div className="gallery-header">
          <div className="title">
            <span className="gallery-title">マイリスト</span>
          </div>
          {/*
           // @ts-ignore TS2339 */}
          {!(browserInfo.isIOS || browserInfo.isAndroid) && this.props.sortComponent}
        </div>
        {/*
         // @ts-ignore TS2339 */}
        <GalleryTab links={this.links} activeTabIndex={this.state.activeTabIndex} />
        {/*
         // @ts-ignore TS2339 */}
        {deleteContent || isCurrentContinueWatching || this.props.sortComponent ? (
          <div className="gallery-content-header">
            <div className="button-box clearfix">{deleteContent}</div>
            {isCurrentContinueWatching ? (
              <MainViewLink
                // @ts-ignore TS2322
                className={classnames('btn btn-border viewing-history btn-very-small', this.state.deleteMode)}
                to={routes.viewingHistory}
                params={{ id: userInfo.guid }}
              >
                <span className="short">視聴履歴はこちら</span>
                <span className="long">すべての視聴履歴はこちらからご確認いただけます</span>
              </MainViewLink>
            ) : null}
            {/* @ts-ignore TS2339  */}
            {(browserInfo.isIOS || browserInfo.isAndroid) && this.props.sortComponent}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
