import { useMemo } from 'react';
import _ from 'src/libs/util';

import * as COURSE from 'src/constants/course';
import usePlaybackRestrictionType from 'src/apps/common/hooks/usePlaybackRestrictionType';
import { canPlayBroadcast as getIsCasRegistered } from 'src/utils/activeProfile';
import { isFree } from 'src/utils/activeProfile';

const useShowSubscriptionPlan = ({ meta, event, profile, courses }) => {
  const playbackRestrictionType = usePlaybackRestrictionType({ meta, event });

  const showSubscriptionView = useMemo(() => {
    const course = courses
      ? courses.find(course => course.id === COURSE.DEFAULT) ||
        courses.find(course => course.id === COURSE.STREAMING_DIRECT_WITH_CAS_PAY_AT_SUBSCRIBE)
      : null;

    if (!_.isEmpty(course)) {
      let _showSubscriptionView = true;
      // 有料会員で放送視聴登録のみの場合は上に導線出すので非表示
      const needBcas = playbackRestrictionType == 100;
      const noBcas = !getIsCasRegistered(profile) && !isFree(profile);
      // 契約済みBCAS未登録者
      if (needBcas && noBcas) {
        _showSubscriptionView = false;
      }
      return _showSubscriptionView;
    }
    return false;
  }, [courses, playbackRestrictionType, profile]);

  return showSubscriptionView;
};

export default useShowSubscriptionPlan;
