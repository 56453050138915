import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import DisableRateModal from '../modal/DisableRateModal';
import DisableRenditionModal from '../modal/DisableRenditionModal';

class Settings extends React.PureComponent {
  static get propTypes() {
    return {
      player: PropTypes.object.isRequired,
      startHover: PropTypes.func,
      stopHover: PropTypes.func,
      quality: PropTypes.number,
      enableQualityChange: PropTypes.bool,
      playbackRate: PropTypes.number,
      enablePlaybackRateChange: PropTypes.bool,
      enableAutoplay: PropTypes.bool,
      onToggleAutoplay: PropTypes.func,
      onChangeQuality: PropTypes.func,
    };
  }

  static get defaultProps() {
    return {};
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.toggleAutoplayRef = React.createRef();
    // @ts-ignore TS2339
    this.rateMenuRef = React.createRef();
    // @ts-ignore TS2339
    this.qualityMenuRef = React.createRef();
    // @ts-ignore TS2339
    this.playbackRateRefs = {};
    // @ts-ignore TS2339
    this.renditionRefs = {};

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.handleToggleAutoplay = this.handleToggleAutoplay.bind(this);
    this.handleQualityMenu = this.handleQualityMenu.bind(this);
    this.handleQualityBack = this.handleQualityBack.bind(this);
    this.handleRateMenu = this.handleRateMenu.bind(this);
    this.handleRateBack = this.handleRateBack.bind(this);

    this.handleToggleAutoplayKeyDown = this.handleToggleAutoplayKeyDown.bind(this);
    this.handleRateMenuKeyDown = this.handleRateMenuKeyDown.bind(this);
    this.handleQualityMenuKeyDown = this.handleQualityMenuKeyDown.bind(this);
    this.handlePlaybackRateKeyDown = this.handlePlaybackRateKeyDown.bind(this);
    this.handleRenditionKeyDown = this.handleRenditionKeyDown.bind(this);

    this.onClickQuality = this.onClickQuality.bind(this);
    this.onClickRate = this.onClickRate.bind(this);
    this.onChangeQuality = this.onChangeQuality.bind(this);
    this.onChangeRate = this.onChangeRate.bind(this);

    this.mainMenukeyDown = this.mainMenukeyDown.bind(this);
    this.subMenukeyDown = this.subMenukeyDown.bind(this);
    this.getPlaybackRateNode = this.getPlaybackRateNode.bind(this);
    this.getRenditionsNode = this.getRenditionsNode.bind(this);

    this.state = {
      toggle: props.enableAutoplay,
      showMainMenu: false,
      showQualityMenu: false,
      showRateMenu: false,
      quality: props.quality,
      playbackRate: props.playbackRate,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.setState({ showMainMenu: true });

    // @ts-ignore TS2339
    const focusElement = this.toggleAutoplayRef.current || this.rateMenuRef.current || this.qualityMenuRef.current;
    // setTimeoutしないとフォーカスが移動しない
    // @ts-ignore TS2339
    if (focusElement && this.props.shouldFocus) setTimeout(() => focusElement.focus(), 0);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ toggle: nextProps.enableAutoplay });
  }

  componentDidUpdate(beforeProps, beforeState) {
    // @ts-ignore TS2339
    if (this.props.drowTooltip) this.props.drowTooltip();
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  onMouseEnter(e) {
    // @ts-ignore TS2339
    if (this.props.startHover) {
      // @ts-ignore TS2339
      this.props.startHover();
    }
  }

  onMouseLeave(e) {
    // @ts-ignore TS2339
    if (this.props.stopHover) {
      // @ts-ignore TS2339
      this.props.stopHover();
    }
  }

  handleToggleAutoplay(e) {
    // @ts-ignore TS2339
    if (this.props.onToggleAutoplay) this.props.onToggleAutoplay(!this.state.toggle);
  }

  handleQualityMenu(e) {
    this.setState({ showQualityMenu: true });
    const renditionsNode = this.getRenditionsNode();
    const index = _.findIndex(renditionsNode, node => {
      return node.props['aria-checked'];
    });
    // @ts-ignore TS2339
    setTimeout(() => this.renditionRefs[renditionsNode[index].key].focus(), 0);
  }

  handleQualityBack(e) {
    this.setState({ showQualityMenu: false });
    // @ts-ignore TS2339
    setTimeout(() => this.qualityMenuRef.current.focus(), 0);
  }

  handleRateMenu(e) {
    // @ts-ignore TS2339
    if (!this.props.enablePlaybackRateChange) {
      // @ts-ignore TS2339
      this.props.showModal(<DisableRateModal closeModal={this.props.closeModal} />);
      return;
    }
    this.setState({ showRateMenu: true });
    const playbackRateNode = this.getPlaybackRateNode();
    const index = _.findIndex(playbackRateNode, node => {
      return node.props['aria-checked'];
    });
    // @ts-ignore TS2339
    setTimeout(() => this.playbackRateRefs[playbackRateNode[index].key].focus(), 0);
  }

  handleRateBack(e) {
    this.setState({ showRateMenu: false });
    // @ts-ignore TS2339
    setTimeout(() => this.rateMenuRef.current.focus(), 0);
  }

  onClickQuality(e, quality) {
    // @ts-ignore TS2339
    if (this.props.onChangeQuality) this.props.onChangeQuality(quality);
    this.handleQualityBack(e);
  }

  onClickRate(e, rate) {
    // @ts-ignore TS2339
    if (this.props.player.playbackRate) this.props.player.playbackRate(rate);
    this.handleRateBack(e);
  }

  onChangeQuality(e) {
    this.setState({ quality: e.target.value });
    // @ts-ignore TS2339
    if (this.props.onChangeQuality) this.props.onChangeQuality(e.target.value);
  }

  onChangeRate(e) {
    this.setState({ playbackRate: e.target.value });
    // @ts-ignore TS2339
    if (this.props.player.playbackRate) this.props.player.playbackRate(e.target.value);
  }

  handleToggleAutoplayKeyDown(event) {
    this.mainMenukeyDown(
      event,
      this.handleToggleAutoplay,
      null,
      // @ts-ignore TS2339
      this.rateMenuRef.current || this.qualityMenuRef.current,
    );
  }

  handleRateMenuKeyDown(event) {
    // @ts-ignore TS2339
    this.mainMenukeyDown(event, this.handleRateMenu, null, this.qualityMenuRef.current, true);
  }

  handleQualityMenuKeyDown(event) {
    this.mainMenukeyDown(
      event,
      this.handleQualityMenu,
      // @ts-ignore TS2339
      this.rateMenuRef.current || this.toggleAutoplayRef.current,
      null,
      true,
    );
  }

  handlePlaybackRateKeyDown(event, keyName) {
    const playbackRateNode = this.getPlaybackRateNode();
    const index = _.findIndex(playbackRateNode, node => {
      return node.key == keyName;
    });
    // @ts-ignore TS2339
    this.subMenukeyDown(event, index, playbackRateNode, this.playbackRateRefs, this.handleRateBack);
  }

  handleRenditionKeyDown(event, keyName) {
    const renditionsNode = this.getRenditionsNode();
    const index = _.findIndex(renditionsNode, node => {
      return node.key == keyName;
    });
    // @ts-ignore TS2339
    this.subMenukeyDown(event, index, renditionsNode, this.renditionRefs, this.handleQualityBack);
  }

  mainMenukeyDown(event, enterFunc, prevElement, nextElement, enableRight = false) {
    // Right Arrows and Enter and Space
    if ((enableRight && event.which === 39) || event.which === 13 || event.which === 32) {
      event.preventDefault();
      enterFunc(event);

      // Down Arrows
    } else if (event.which === 40) {
      event.preventDefault();
      if (nextElement) {
        setTimeout(() => nextElement.focus(), 0);
      }

      // Up Arrows
    } else if (event.which === 38) {
      event.preventDefault();
      if (prevElement) {
        setTimeout(() => prevElement.focus(), 0);
      }

      // Tab
    } else if (event.which === 9 && !event.shiftKey) {
      // @ts-ignore TS2339
      if (!nextElement && this.props.onEscape) {
        event.preventDefault();
        // @ts-ignore TS2339
        this.props.onEscape();
      }

      // Tab with Shift
    } else if (event.which === 9 && event.shiftKey) {
      // @ts-ignore TS2339
      if (!prevElement && this.props.onEscape) {
        event.preventDefault();
        // @ts-ignore TS2339
        this.props.onEscape();
      }

      // Esc and BackSpace
    } else if (event.which === 27 || event.which === 8) {
      // @ts-ignore TS2339
      if (this.props.onEscape) {
        event.preventDefault();
        // @ts-ignore TS2339
        this.props.onEscape();
      }
    }

    // @ts-ignore TS2339
    if (this.props.onMouseMove) this.props.onMouseMove();
  }

  subMenukeyDown(event, index, nodes, refs, backFunc) {
    // Down Arrows
    if (event.which === 40) {
      index++;
      if (nodes[index]) {
        event.preventDefault();
        setTimeout(() => refs[nodes[index].key].focus(), 0);
      }

      // Up Arrows
    } else if (event.which === 38) {
      index--;
      if (nodes[index]) {
        event.preventDefault();
        setTimeout(() => refs[nodes[index].key].focus(), 0);
      }

      // Tab
    } else if (event.which === 9 && !event.shiftKey) {
      index++;
      // @ts-ignore TS2339
      if (!nodes[index] && this.props.onEscape) {
        event.preventDefault();
        // @ts-ignore TS2339
        this.props.onEscape();
      }

      // Tab with Shift
    } else if (event.which === 9 && event.shiftKey) {
      index--;
      // @ts-ignore TS2339
      if (!nodes[index] && this.props.onEscape) {
        event.preventDefault();
        // @ts-ignore TS2339
        this.props.onEscape();
      }

      // Left Arrows and BackSpace
    } else if (event.which === 37 || event.which === 8) {
      event.preventDefault();
      backFunc(event);

      // Enter and Space
    } else if (event.which === 13 || event.which === 32) {
      event.preventDefault();
      refs[nodes[index].key].click();

      // Esc
    } else if (event.which === 27) {
      // @ts-ignore TS2339
      if (this.props.onEscape) {
        event.preventDefault();
        // @ts-ignore TS2339
        this.props.onEscape();
      }
    }

    // @ts-ignore TS2339
    if (this.props.onMouseMove) this.props.onMouseMove();
  }

  getPlaybackRateNode() {
    const playbackRates = _.get(this.props, 'config.playback_rates', []);
    const playbackRateIndex = _.findIndex(playbackRates, playbackRate => {
      // @ts-ignore TS2339
      return playbackRate.rate == this.props.playbackRate;
    });
    return _.map(playbackRates, playbackRate => {
      const keyName = 'playbackRate_' + playbackRate.rate;
      return (
        <div
          // @ts-ignore TS2339
          ref={el => (this.playbackRateRefs[keyName] = el)}
          className="wod-menuitem"
          // @ts-ignore TS2322
          tabIndex="0"
          role="menuitemradio"
          onClick={e => this.onClickRate(e, playbackRate.rate)}
          key={keyName}
          // @ts-ignore TS2339
          aria-checked={this.props.playbackRate == playbackRate.rate}
          onKeyDown={e => this.handlePlaybackRateKeyDown(e, keyName)}
        >
          <div className="wod-menuitem-label">
            <i className="fa fa-ok" />
            {playbackRate.label}
          </div>
        </div>
      );
    });
  }

  getRenditionsNode() {
    const browserInfo = this.context.getModelData('browserInfo');
    const renditions = _.get(this.props, 'renditions', []);
    const renditionsNode = [];
    _.forEach(renditions, (rendition, index) => {
      const keyName = 'quality_' + index;
      const props = {
        onClick: e => this.onClickQuality(e, index),
      };
      let disable = false;

      // MacかつSafari以外の場合、最高画質・高画質はダイアログ表示
      if (browserInfo.isMac && !browserInfo.isSafari) {
        if (index == 1 || index == 2) {
          props.onClick = e => {
            // @ts-ignore TS2339
            this.props.showModal(<DisableRenditionModal closeModal={this.props.closeModal} />);
          };
          disable = true;
        }
      }

      renditionsNode.push(
        <div
          // @ts-ignore TS2339
          ref={el => (this.renditionRefs[keyName] = el)}
          {...props}
          className={classnames('wod-menuitem', { disable })}
          // @ts-ignore TS2322
          tabIndex="0"
          role="menuitemradio"
          key={keyName}
          // @ts-ignore TS2339
          aria-checked={this.props.quality == index}
          onKeyDown={e => this.handleRenditionKeyDown(e, keyName)}
        >
          <div className="wod-menuitem-label">
            <i className="fa fa-ok" />
            <div>
              <span>{rendition.label}</span>
            </div>
          </div>
        </div>,
      );
    });
    return renditionsNode;
  }

  render() {
    const playbackRates = _.get(this.props, 'config.playback_rates', []);
    const playbackRateIndex = _.findIndex(playbackRates, playbackRate => {
      // @ts-ignore TS2339
      return playbackRate.rate == this.props.playbackRate;
    });
    const playbackRateNode = this.getPlaybackRateNode();
    const playbackRateOptionNode = _.map(playbackRates, playbackRate => {
      return (
        <option key={`playbackRate-${playbackRate.rate}`} value={playbackRate.rate}>
          {playbackRate.label}
        </option>
      );
    });

    const renditions = _.get(this.props, 'renditions', []);
    const renditionsNode = this.getRenditionsNode();
    const renditionsOptionNode = [];
    _.forEach(renditions, (rendition, index) => {
      renditionsOptionNode.push(
        <option key={`rendition-${index}`} value={index}>
          {rendition.label}
        </option>,
      );
    });

    const browserInfo = this.context.getModelData('browserInfo');

    return (
      <div
        className={classnames('settings-wapper', {
          // @ts-ignore TS2339
          'show-main-menu': this.state.showMainMenu,
          // @ts-ignore TS2339
          'show-quality-menu': this.state.showQualityMenu,
          // @ts-ignore TS2339
          'show-rate-menu': this.state.showRateMenu,
        })}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className="wod-popup wod-settings-menu">
          <div className="wod-panel main-menu">
            <div className="wod-panel-menu" role="menu">
              {/*
               // @ts-ignore TS2339 */}
              {this.props.onToggleAutoplay ? (
                <div
                  // @ts-ignore TS2339
                  ref={this.toggleAutoplayRef}
                  className="wod-menuitem"
                  role="menuitemcheckbox"
                  // @ts-ignore TS2339
                  aria-checked={this.state.toggle}
                  // @ts-ignore TS2322
                  tabIndex="0"
                  onClick={this.handleToggleAutoplay}
                  onKeyDown={this.handleToggleAutoplayKeyDown}
                >
                  <div className="wod-menuitem-label">連続再生</div>
                  <div className="wod-menuitem-content">
                    <div className="wod-menuitem-toggle-checkbox"></div>
                  </div>
                </div>
              ) : null}

              {/*
               // @ts-ignore TS2339 */}
              {this.props.showPlaybackRateChange ? (
                <div
                  // @ts-ignore TS2339
                  ref={this.rateMenuRef}
                  className="wod-menuitem"
                  aria-haspopup="true"
                  role="menuitem"
                  // @ts-ignore TS2322
                  tabIndex="0"
                  onClick={browserInfo.isIOS || browserInfo.isAndroid ? null : this.handleRateMenu}
                  onKeyDown={this.handleRateMenuKeyDown}
                >
                  <div className="wod-menuitem-label">速度</div>
                  {browserInfo.isIOS || browserInfo.isAndroid ? (
                    <div className="wod-menuitem-content select">
                      <label className="select-group">
                        {/*
                         // @ts-ignore TS2339 */}
                        <select value={this.state.playbackRate} onChange={this.onChangeRate}>
                          {playbackRateOptionNode}
                        </select>
                        <i className="fa fa-angle_right" />
                      </label>
                    </div>
                  ) : (
                    <div className="wod-menuitem-content">
                      {playbackRates[playbackRateIndex].label}
                      <i className="fa fa-angle_right" />
                    </div>
                  )}
                </div>
              ) : null}

              {/*
               // @ts-ignore TS2339 */}
              {this.props.enableQualityChange ? (
                <div
                  // @ts-ignore TS2339
                  ref={this.qualityMenuRef}
                  className="wod-menuitem"
                  aria-haspopup="true"
                  role="menuitem"
                  // @ts-ignore TS2322
                  tabIndex="0"
                  onClick={browserInfo.isIOS || browserInfo.isAndroid ? null : this.handleQualityMenu}
                  onKeyDown={this.handleQualityMenuKeyDown}
                >
                  <div className="wod-menuitem-label">画質</div>
                  {browserInfo.isIOS || browserInfo.isAndroid ? (
                    <div className="wod-menuitem-content select">
                      <label className="select-group">
                        {/*
                         // @ts-ignore TS2339 */}
                        <select value={this.state.quality} onChange={this.onChangeQuality}>
                          {renditionsOptionNode}
                        </select>
                        <i className="fa fa-angle_right" />
                      </label>
                    </div>
                  ) : (
                    <div className="wod-menuitem-content">
                      <div>
                        {/*
                         // @ts-ignore TS2339 */}
                        <span>{renditions[this.props.quality].label}</span>
                        <span className="wod-menu-label-secondary"></span>
                      </div>
                      <i className="fa fa-angle_right" />
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          <div className="wod-panel wod-quality-menu quality-menu">
            <div className="wod-panel-header">
              <i className="fa fa-angle_left" />
              <button type="button" className="wod-button wod-panel-title" onClick={this.handleQualityBack}>
                画質
              </button>
            </div>
            <div className="wod-panel-menu" role="menu">
              {renditionsNode}
            </div>
          </div>

          <div className="wod-panel rate-menu">
            <div className="wod-panel-header">
              <i className="fa fa-angle_left" />
              <button type="button" className="wod-button wod-panel-title" onClick={this.handleRateBack}>
                速度
              </button>
            </div>
            <div className="wod-panel-menu" role="menu">
              {playbackRateNode}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
