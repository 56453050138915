import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import * as ERROR from '../../../../constants/error';
import MainViewLink from '../MainViewLink';
import AppView from './AppView';
import routes from '../../../common/routes';
// @ts-ignore TS1192
import reactNl2br from '../../../../common/reactNl2br';
import { LINKS } from '../../../../constants/links';

import AgreementRequiredView from './ErrorView/AgreementRequiredView';
import SetDate from '../../../bluerose/components/browse/SetDate';

const ErrorView = (props, context) => {
  const errorType = _.get(props, 'error.type');
  const errorCode = _.get(props, 'error.code');

  // iOSでエラーコード3がでて再生できないというケースが発生したため
  // 原因不明。
  // エラー回避策としてiosで3が出たときはアプリ遷移画面を出す。
  const browserInfo = context.getModelData('browserInfo');
  if (browserInfo.isIOS && parseInt(_.get(props, 'error.code')) == 3) {
    const metaId = _.get(props, 'activeEpisode.id');
    return <AppView {...props} metaId={metaId} />;
  }

  // スマホ用コンテンツ保護メッセージ
  if (errorType === ERROR.CONTENTS_PROTECTION) {
    return <AppView {...props} />;
  }

  // 規約同意が必要な場合
  if (errorType === ERROR.AGREEMENT_REQUIRED) {
    return <AgreementRequiredView {...props} metaId={_.get(props, 'error.metaId')} />;
  }

  // 同時視聴制限
  if (errorCode === 'L-2050') {
    return (
      <div className="error-view">
        <div className="error-view-content">
          <div className="error-view-body">
            <div className="error-view-detail">
              <p className="error-view-title">同時に視聴できる端末の上限数を超えて再生しようとしています。</p>
              <p className="error-view-text">他の端末で再生していないか、ご確認ください。</p>
              {browserInfo.isAndroid || browserInfo.isIOS ? (
                <a
                  href="https://support.wowow.co.jp/answer/628d75ffc5aeea7a71e9eb35"
                  target="_blank"
                  className="watch-main-visual__metadata__message-notice btn"
                >
                  詳しくはこちら
                </a>
              ) : null}
              {!(browserInfo.isAndroid || browserInfo.isIOS) ? (
                <div className="watch-main-visual__metadata__message-notice">
                  詳しくは
                  <a
                    href="https://support.wowow.co.jp/answer/628d75ffc5aeea7a71e9eb35"
                    target="_blank"
                    className="accent-color text-underline"
                  >
                    こちら
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
  // 配信前
  if (errorType === ERROR.META_NOT_DELIVERY_STARTED) {
    return (
      <div className="error-view">
        <div className="error-view-content">
          {_.get(props, 'activeEpisode.cardInfo.deliveryStartAt') ? (
            <div className="error-view-heading">
              <p className="whoops text-center">
                {/*
                 // @ts-ignore TS2322 */}
                <SetDate date={props.activeEpisode.cardInfo.deliveryStartAt} format={'fullja'} /> から配信予定
              </p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  // 映倫
  if (errorType === ERROR.EIRIN) {
    const mention = _.find(props.activeEpisode.mentions, mention => mention.type === 'eirin');
    const clickCancel = function() {
      props.doAction({ button: 'cancel' });
    };
    const clickSubmit = function() {
      props.doAction({ button: 'submit' });
    };
    return (
      <div className="error-view">
        <div className="error-view-content">
          <div className="error-view-content__dialog">
            <div className="error-view-body">
              <div className="error-view-detail">
                <p className="error-view-title">{reactNl2br(mention.message)}</p>
                {mention.cancel_button_label && (
                  <a href="javascript:void(0);" onClick={clickCancel} className="btn btn-border">
                    {mention.cancel_button_label}
                  </a>
                )}
                {mention.submit_button_label && (
                  <a href="javascript:void(0);" onClick={clickSubmit} className="btn btn-fill">
                    {mention.submit_button_label}
                  </a>
                )}
                {!mention.cancel_button_label && !mention.submit_button_label && (
                  <a href="javascript:void(0);" onClick={clickSubmit} className="btn btn-fill">
                    OK
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // 再生開始チェック
  if (errorType === ERROR.START_OVER) {
    const clickCancel = function() {
      props.doAction({ button: 'cancel' });
    };
    const clickSubmit = function() {
      props.doAction({ button: 'submit' });
    };
    return (
      <div className="error-view">
        <div className="error-view-content">
          <div className="error-view-content__dialog">
            <div className="error-view-body">
              <div className="error-view-detail">
                <p className="error-view-title">
                  この動画は追っかけ再生に対応しています。
                  <br />
                  どこから再生しますか？
                </p>
                <a href="javascript:void(0);" onClick={clickSubmit} className="btn btn-border">
                  <i className="fa-undo-alt"></i>最初から再生
                </a>
                <a href="javascript:void(0);" onClick={clickCancel} className="btn btn-fill">
                  <i className="fa-play-circle"></i>ライブを再生
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  let header = '申し訳ありません。問題が発生しました。';
  let title, text, code, link, href;

  if (errorType === ERROR.BROWSER_NOT_SUPPORT) {
    const browserInfo = context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      return (
        <AppView
          {...props}
          message="ご利用いただいている環境はサポートされていません。専用アプリからご視聴ください。"
        />
      );
    } else {
      header = '';
      title = 'エラー';
      text =
        'ご利用いただいている環境は推奨環境ではないため、動画の再生をすることはできません。推奨環境をご確認ください。';
      link = (
        // @ts-ignore TS2322
        <a className="btn btn-border" tabIndex="0" role="button" href={LINKS.SUPPORT.ENABLE_DEVICES} target="_blank">
          推奨環境はこちら
        </a>
      );
    }
  } else if (props.error.type === ERROR.AVAILABLE_ENTITLEMENT) {
    header = '';
    title = _.get(props, 'error.display.title');
    text = _.get(props, 'error.display.text');
    link = _.get(props, 'error.display.link');
  } else if (props.error.type === ERROR.RATING_UNMATCH) {
    header = '';
    title =
      'ペアレンタルコントロールで設定している年齢制限を超えているため、この作品を再生することができません。ペアレンタルコントロールの詳細はヘルプセンターをご覧ください。';
    link = (
      <a
        className="btn btn-border"
        // @ts-ignore TS2322
        tabIndex="0"
        role="button"
        href="https://support.wowow.co.jp/s/category?prmcat=category3"
        target="_blank"
      >
        お客サポート・よくあるご質問はこちら
      </a>
    );
  } else {
    title = _.get(props, 'error.display.title', 'ストリーミングエラー');
    text = _.get(props, 'error.display.text');
    if (_.get(props, 'error.code') && parseInt(props.error.code) != 0) {
      code = props.error.code;
    }

    if (
      !text &&
      (errorType == 'MEDIA_ERR_ENCRYPTED' ||
        errorType === 'PLAYER_ERR_TIMEOUT' ||
        errorType === 'RESTRICTIONS_CANNOT_BE_MET' ||
        errorType === 'VIDEO_ERROR' ||
        errorType === 'MEDIA_ERR_UNKNOWN' ||
        code === 'S-4012' ||
        code === 'S-3' ||
        code === 'S-4' ||
        code == 'S-5')
    ) {
      header = '問題が発生しました。';
      text = (
        <p className="error-text">
          通信環境、推奨環境をご確認ください。
          <br />
          解決しない場合は、他のブラウザ、端末でもお試しください。
          <br />
          外部モニタなどに出力している場合は、ヘルプセンターをご確認ください。
        </p>
      );
      link = (
        <a
          className="btn btn-border"
          // @ts-ignore TS2322
          tabIndex="0"
          role="button"
          href="https://support.wowow.co.jp/s/category?prmcat=category3"
          target="_blank"
        >
          お客サポート・よくあるご質問はこちら
        </a>
      );
    }

    // 利用登録出来る端末数が上限に達している -> 視聴デバイス管理リンクを出す
    if (errorType === ERROR.VIEWING_DEVICE_LIMIT) {
      let returnTo;
      const metaId = _.get(props, 'activeEpisode.id');
      if (metaId) {
        // @ts-ignore TS2554
        returnTo = '?return_to=' + routes.watchNow.makePath({ id: metaId });
      }
      link = (
        <button
          className="btn btn-border"
          // @ts-ignore TS2322
          tabIndex="0"
          role="button"
          onClick={e => {
            if (context.isIframe) {
              const host = _.get(context.getModelData('hosts'), 'host');
              window.parent.location.href = `${host}/device/management?return_to=${encodeURIComponent(
                window.parent.location.href,
              )}`;
            } else {
              window.location.href = `/device/management${returnTo}`;
            }
          }}
        >
          視聴デバイス管理へ
        </button>
      );
    }

    switch (code) {
      case 'S-1':
        href = 'https://support.wowow.co.jp/answer/62bd36c09c65d8daa8a34ec9';
        break;
      case 'S-2':
        href = 'https://support.wowow.co.jp/answer/62bd36c09c65d8daa8a34ec9';
        break;
      case 'S-3016':
        href = 'https://support.wowow.co.jp/answer/62bd36c09c65d8daa8a34ec9';
        break;
      case 'S-4012':
        href = 'https://support.wowow.co.jp/answer/62bd36c09c65d8daa8a34ec9';
        break;
      case 'L-2000':
        href = 'https://support.wowow.co.jp/answer/62bd38021e5eea9d5b3b4192';
        break;
      case 'L-2003':
        href = 'https://support.wowow.co.jp/answer/62bd38021e5eea9d5b3b4192';
        break;
      case 'L-2020':
        href = 'https://support.wowow.co.jp/answer/62bd38021e5eea9d5b3b4192';
        break;
      // case 'L-2041': 未ログインの場合はそのままにする
      //   href = 'https://support.wowow.co.jp/answer/62bd38811f5f90ddf5738635';
      //   break;
      case 'L-2050':
        href = 'https://support.wowow.co.jp/answer/62bd38c4621f9f429c0d33d7';
        break;
      case 'L-2051':
        href = 'https://support.wowow.co.jp/answer/62bd39031e5eea9d5b3b4f14';
        break;
      case 'L-2054':
        href = 'https://support.wowow.co.jp/answer/62bd39571e5eea9d5b3b5719';
        break;
      case 'L-2055':
        href = 'https://support.wowow.co.jp/answer/62bd39571e5eea9d5b3b5719';
        break;

      case 'L-29083':
        href = 'https://support.wowow.co.jp/answer/62bd3bcb0a971c9dcde67a41';
        break;
    }

    if (href) {
      link = (
        // @ts-ignore TS2322
        <a className="btn btn-border" tabIndex="0" role="button" href={href} target="_blank">
          お客サポート・よくあるご質問はこちら
        </a>
      );
    }

    // code: 2055
    // message: "サポート外ネットワークからの接続です。日本国外からの接続、VPN・プロキシ経由の接続等ではご利用いただけません。"
    // if (code === 'L-2055') {
    //   link = (
    //     <a className="btn btn-border" tabIndex="0" role="button" href="https://support.wowow.co.jp/answer/61f112833bc2e6001a685262?search=true" target="_blank">お客サポート・よくあるご質問はこちら</a>
    //   );
    // }
  }

  return (
    <div className="error-view">
      {props.exitButtonElement}
      <div className="error-view-content">
        {header ? (
          <div className="error-view-heading">
            <p className="whoops">{header}</p>
          </div>
        ) : null}
        <div className="error-view-body">
          <div className="error-view-detail">
            <p className="error-view-title">{title}</p>
            {typeof text === 'string' ? <p className="error-view-text">{text}</p> : text}
            {link ? <p>{link}</p> : null}
            {code ? <p>コード：{code}</p> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

ErrorView.propTypes = {
  model: PropTypes.object,
};

ErrorView.contextTypes = {
  models: PropTypes.object,
  getModelData: PropTypes.func,
  isIframe: PropTypes.bool,
};

export default ErrorView;
