import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';
import MetaTags from './MetaTags';
import Meta from '../browse/Meta';
import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
import * as DOMUtil from '../../../../sketch-platform/utils/DOMUtils';

class UpnextView extends React.PureComponent {
  static get propTypes() {
    return {
      player: PropTypes.object,
      metadata: PropTypes.object,
      onPlay: PropTypes.func,
      autoplay: PropTypes.bool,
      durationTime: PropTypes.number,
    };
  }

  static get defaultProps() {
    return {
      durationTime: 10000,
    };
  }

  static get contextTypes() {
    return {
      models: PropTypes.object,
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.rootRef = React.createRef();
    // @ts-ignore TS2339
    this.autoplayRingRef = React.createRef();
    this.handleReloadClick = this.handleReloadClick.bind(this);
    this.handlePlayClick = this.handlePlayClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleFullScreenChange = this.handleFullScreenChange.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.drowRing = this.drowRing.bind(this);
    this.startAutoplayRing = this.startAutoplayRing.bind(this);
    this.checkMediaQuery = this.checkMediaQuery.bind(this);

    this.state = { autoplay: props.autoplay, cancel: false };

    // @ts-ignore TS2339
    this._player = props.player;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.checkMediaQuery);
    } else {
      browserEvents.addEventListener('resize', this.checkMediaQuery);
    }
    // @ts-ignore TS2339
    if (this._player) {
      // @ts-ignore TS2339
      this._player.on('onFullScreenChange', this.handleFullScreenChange);
    }
    this.checkMediaQuery();

    // リング描画開始
    this.startAutoplayRing();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ autoplay: nextProps.autoplay });
  }

  componentDidUpdate(beforeProps, beforeState) {
    // @ts-ignore TS2339
    if (beforeState.autoplay != this.state.autoplay) {
      // @ts-ignore TS2339
      if (this.state.autoplay) {
        this.startAutoplayRing();
      } else {
        this.stopAutoplayRing();
      }
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.drowTimmer) clearTimeout(this.drowTimmer);
    // @ts-ignore TS2339
    delete this.drowTimmer;

    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('orientationchange', this.checkMediaQuery);
    } else {
      browserEvents.removeEventListener('resize', this.checkMediaQuery);
    }
    // @ts-ignore TS2339
    if (this._player) {
      // @ts-ignore TS2339
      this._player.off('onFullScreenChange', this.handleFullScreenChange);
    }
  }

  handleFullScreenChange() {
    // イベントが呼び出されたあとにMediaQueryが変化するため無理やり非同期にしている
    setTimeout(() => {
      this.checkMediaQuery();
    }, 10);
  }

  checkMediaQuery() {
    const orientation = this.getOrientation();
    this.setState({ orientation });
  }

  getOrientation() {
    // @ts-ignore TS2339
    const { width, height } = DOMUtil.getRect(this.rootRef.current);
    return width > height ? 'landscape' : 'portrait';
  }

  handleReloadClick(e) {
    e.preventDefault();
    e.stopPropagation();
    // @ts-ignore TS2339
    this.props.onClickReload();
  }
  handlePlayClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.onPlay();
  }

  handleCancelClick(e) {
    e.preventDefault();
    e.stopPropagation();

    this.stopAutoplayRing();
  }

  onPlay() {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    // @ts-ignore TS2339
    if (this.props.onPlay) this.props.onPlay({ sc: 1 });
  }

  startAutoplayRing() {
    // @ts-ignore TS2339
    if (!this.state.autoplay) return;

    // @ts-ignore TS2339
    if (this.drowTimmer) clearTimeout(this.drowTimmer);
    // @ts-ignore TS2339
    delete this.drowTimmer;
    this.setState({ cancel: false });

    // @ts-ignore TS2339
    this.drowRing(this.props.durationTime);
  }

  stopAutoplayRing() {
    // @ts-ignore TS2339
    if (this.drowTimmer) clearTimeout(this.drowTimmer);
    // @ts-ignore TS2339
    delete this.drowTimmer;
    this.setState({ cancel: true });

    this.drowRing(1000, true);
  }

  drowRing(duration, reverse = false) {
    // @ts-ignore TS2339
    if (!this._isMounted) return;

    // @ts-ignore TS2339
    const autoplayRing = this.autoplayRingRef.current;
    if (!autoplayRing) return;

    let from = -211;
    let to = -422;
    let strokeDashoffset = parseInt(autoplayRing.getAttribute('stroke-dashoffset'), 10);
    if (reverse) {
      if (strokeDashoffset >= from) {
      } else {
        autoplayRing.setAttribute('stroke-dashoffset', strokeDashoffset + 1);
        // @ts-ignore TS2339
        this.drowTimmer = setTimeout(() => {
          this.drowRing(duration, reverse);
        }, duration / (from - to));
      }
    } else {
      if (strokeDashoffset <= to) {
        this.onPlay();
      } else {
        autoplayRing.setAttribute('stroke-dashoffset', strokeDashoffset - 1);
        // @ts-ignore TS2339
        this.drowTimmer = setTimeout(() => {
          this.drowRing(duration, reverse);
        }, duration / (from - to));
      }
    }
  }

  render() {
    // @ts-ignore TS2339
    const title = this.props.title;
    // @ts-ignore TS2339
    const playableTitle = this.props.metadata.shortName || this.props.metadata.name;
    const description =
      // @ts-ignore TS2339
      this.props.metadata.cardInfo && this.props.metadata.cardInfo.description
        // @ts-ignore TS2339
        ? this.props.metadata.cardInfo.description
        : null;
    const { isAndroid, isIOS, isRequestDesktopWebsite } = this.context.getModelData('browserInfo') || {};
    const isSp = isAndroid || isIOS || isRequestDesktopWebsite;
    const overlayStyle = {};
    // @ts-ignore TS2339
    if (this.props.metadata && this.props.metadata.thumbnailUrl) {
      // @ts-ignore TS2339
      overlayStyle.backgroundImage = `url(${this.props.metadata.thumbnailUrl})`;
    }

    // @ts-ignore TS2339
    const isPortrait = this.state.orientation === 'portrait';

    return (
      // @ts-ignore TS2339
      <div className="wod-upnext wod-suggestion-set" data-layer="4" aria-label={title} ref={this.rootRef}>
        <div
          className={classnames('wod-cued-thumbnail-overlay-image', { portrait: isPortrait })}
          style={overlayStyle}
        ></div>
        <div className={classnames('wod-upnext__text', { isSp: isSp })}>
          {!isSp && (
            <div className="wod-upnext-top">
              {title !== playableTitle ? <div className="wod-upnext-title ellipsized">{title}</div> : null}
              <div className="wod-upnext-author ellipsized">{playableTitle}</div>
              {description ? <div className="wod-upnext-description">{description}</div> : null}
              {/*
               // @ts-ignore TS2339 */}
              <Meta metadata={this.props.metadata} />
              {/*
               // @ts-ignore TS2322 */}
              <MetaTags metadata={this.props.metadata} />
            </div>
          )}
          <div className="wod-upnext-bottom">
            <a
              className="btn btn-border btn-very-small"
              id="next-episode-button"
              role="button"
              aria-label="次の動画を再生"
              href=""
              onClick={this.handlePlayClick}
            >
              <i className="fa fa-play-circle"></i>
              <span className="text-next-episode">{_.get(this.props, 'nextButtonText') || '次のエピソード'}</span>
              <span className="text-play">を再生</span>
            </a>
          </div>
          {!isSp && (
            <div className="wod-upnext-bottom replay">
              <a
                href=""
                className="wod-upnext-autoplay-icon"
                role="button"
                aria-label="もう一度再生"
                onClick={this.handleReloadClick}
              >
                <i className="fa fa-undo-alt"></i>
                もう一度再生
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default UpnextView;
