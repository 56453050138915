import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import Canvas from './Canvas';
import Palette from './Palette';
import HtmlSnippetRow from './HtmlSnippetRow';
import GalleryTab from './GalleryTab';
import presentationHOC from '../../../common/hocs/presentationHOC';
import * as DOMUtils from '../../../../sketch-platform/utils/DOMUtils';
import routes from '../../../common/routes';
import datetime from 'src/libs/datetime';

const PERPAGE = 25;

class LiveScheduleCanvas extends Component {
  static getPaths = function(models, options, props) {
    const rootPath = this.getRootPath(models, options, props);
    const indexes = {
      from: (props && props.fromNum) || 0,
      to:
        props && props.toNum
          ? props.toNum - (props.fromNum || 0)
          : options && options.numVideos
          ? options.numVideos
          : PERPAGE - 1,
    };

    const rowPaths = HtmlSnippetRow.getPaths(models, options, props).concat(Palette.getPaths(models, options, props));
    const canvasRowPaths = rowPaths.map(path => {
      return rootPath.concat([indexes]).concat(path);
    });

    return canvasRowPaths.concat([
      rootPath.concat([['length', 'emptyMessage']]),
      rootPath.concat([
        indexes,
        ['hasObjects', 'idKey', 'type', 'id', 'listContext', 'date', 'year', 'month', 'day', 'weekday'],
      ]),
      rootPath.concat(['additional', indexes]),
    ]);
  };

  static getRootPath = function(models, options, props) {
    if (props.canvasId) {
      return ['canvas', props.canvasId];
    }
    return [];
  };

  static get propTypes() {
    return {
      canvasId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      numRows: PropTypes.number,
      enablePeek: PropTypes.bool,
      model: PropTypes.object.isRequired,
      header: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    };
  }

  static get contextTypes() {
    return {
      routeHandler: PropTypes.object,
      history: PropTypes.object,
      models: PropTypes.object,
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.canvasRef = React.createRef();

    this.onScroll = this.onScroll.bind(this);
    this.onScrollEnd = this.onScrollEnd.bind(this);
    // @ts-ignore TS2339
    this.numRowsToFetch = PERPAGE;
    // @ts-ignore TS2339
    this.noContentsCount = 0;
    // @ts-ignore TS2339
    this.pathEvaluators = [];
    this.handleNoContents = this.handleNoContents.bind(this);
    // @ts-ignore TS2339
    this.fallbackList = [];

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    this.canvas = props.model.getSync(rootPath);
    // @ts-ignore TS2339
    this.additional = props.model.getSync(rootPath.concat('additional'));
    // @ts-ignore TS2339
    if (this.canvas) {
      // @ts-ignore TS2339
      this.canvas = _.compact(
        // @ts-ignore TS2339
        _.dropRightWhile(_.values(_.omit(this.canvas, ['$__path'])), function(v) {
          return _.isNumber(v);
        }),
      );
      // @ts-ignore TS2339
      this.numRowsToFetch = this.canvas.length;
    }

    let idKey = null;
    const date = _.get(context.routeHandler, 'query.d');
    if (date) {
      // @ts-ignore TS2339
      const palette = _.find(this.canvas, structure => structure.date == date);
      if (palette) idKey = palette.idKey;
    }
    // デフォルトは当日
    if (!idKey) {
      idKey = _.get(
        // @ts-ignore TS2339
        _.find(this.canvas, structure => structure.date == datetime().format('YYYY-MM-DD')),
        'idKey',
      );
    }

    this.state = {
      idKey: idKey,
      bouncing: false,
      dispose: null,
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
      maxRowToLoadImages: 6,
      maxRowToRender: 3,
      scrollHandlersDisabled: true,
      showEmptyMessage: false,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);

    // browserEvents.addEventListener('scroll', this.onScroll);
    // browserEvents.addEventListener('scrollEnd', this.onScrollEnd)
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();

    // browserEvents.removeEventListener('scroll', this.onScroll);
    // browserEvents.removeEventListener('scrollEnd', this.onScrollEnd);
  }

  fetchMoreRows() {
    // if (!this.canvas) return;
    // if (this.numRowsToFetch > this.canvas.length && !this.state.fetchingMoreRows) {
    //   this.state.fetchingMoreRows = true;
    //   this.fetchData(this.props)
    // }
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const pathProps = Object.assign({}, props, { toNum: this.numRowsToFetch - 1 });
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.canvas = _.get(res, ['json'].concat(rootPath));
        // @ts-ignore TS2339
        this.additional = _.get(this.canvas, 'additional');
        // @ts-ignore TS2339
        this.totalRowCount = props.model.getSync(`canvas.${props.canvasId}.length`, 0);
        // @ts-ignore TS2339
        this.canvas = _.compact(
          // @ts-ignore TS2339
          _.dropRightWhile(_.values(_.omit(this.canvas, ['$__path', 'additional'])), function(v) {
            return _.isNumber(v);
          }),
        );
        // @ts-ignore TS2339
        this.numRowsToFetch += PERPAGE;
        // @ts-ignore TS2339
        if (this.numRowsToFetch > this.totalRowCount) this.numRowsToFetch = this.totalRowCount;
        delete this.state[JSON.stringify(paths)];
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };

        // @ts-ignore TS2339
        delete this.state.fetchingMoreRows;
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);

        const browserInfo = this.context.getModelData('browserInfo');
        if (browserInfo.isIOS || browserInfo.isAndroid) {
          this.fetchCanvasData(props);
        }
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  fetchCanvasData(props) {
    // @ts-ignore TS2339
    const pathProps = Object.assign({}, props, { canvasId: _.map(this.canvas, structure => structure.idKey) });
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    const paths = Canvas.getPaths(this.context.models, {}, pathProps);

    if (this.state[JSON.stringify(paths)]) return;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
    this.state[JSON.stringify(paths)] = paths;
    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) Object.assign(this.state, { dispose });
    else this.setState({ dispose });
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        _.map(this.canvas, structure => {
          let rows = _.get(
            res,
            ['json'].concat(Canvas.getRootPath(this.context.models, {}, { canvasId: structure.idKey })),
          );
          rows = _.compact(
            _.dropRightWhile(
              _.values(
                _.omit(rows, ['$__path', 'emptyMessage', 'additional', 'titleType', 'name', 'shortName', 'keyartUrl']),
              ),
              function(v) {
                return _.isNumber(v);
              },
            ),
          );
          rows = _.filter(
            _.map(rows, row => _.omit(row, '$__path')),
            row => !_.isEmpty(row),
          );
          if (_.isEmpty(rows)) {
            // @ts-ignore TS2339
            this.fallbackList.push(structure.idKey);
          }
        });
        delete this.state[JSON.stringify(paths)];
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  handleTabClick(e, idKey) {
    this.setState({
      idKey: idKey,
      // fallback: _.includes(this.fallbackList, idKey),
    });
    // @ts-ignore TS2339
    const palette = _.find(this.canvas, structure => structure.idKey == idKey);
    const query = { d: palette.date };
    // @ts-ignore TS2554
    this.context.history.replace(routes.liveSchedule.makePath({}, query), { norender: true });
  }

  handleNoContents() {
    // @ts-ignore TS2339
    this.fallbackList.push(this.state.idKey);
    this.setState({ fallback: true });
  }

  render() {
    let spinLoader;
    // @ts-ignore TS2339
    if (this.state.fetchingMoreRows === true) {
      spinLoader = (
        <div className="gallery-spin-loader">
          <div className="loader-cont"></div>
        </div>
      );
    }

    // @ts-ignore TS2339
    if (!this.canvas && !spinLoader) {
      return null;
    }

    const today = datetime().format('YYYY-MM-DD');
    const weekMap = {
      monday: '月曜',
      tuesday: '火曜',
      wednesday: '水曜',
      thursday: '木曜',
      friday: '金曜',
      saturday: '土曜',
      sunday: '日曜',
    };

    const browserInfo = this.context.getModelData('browserInfo');
    let structureIdKey, month, unit;
    // @ts-ignore TS2339
    let tablinks = _.map(this.canvas, (structure, i) => {
      if (!structure) return null;

      // @ts-ignore TS2339
      if (this.state.idKey == structure.idKey) {
        structureIdKey = i;
        month = structure.month;
        unit = '月';
      }
      let active = structure.hasObjects;
      if (browserInfo.isIOS || browserInfo.isAndroid) {
        // @ts-ignore TS2339
        active = !_.includes(this.fallbackList, structure.idKey);
      }
      return (
        <a
          href="javascript:void(0);"
          className={classnames({ active: active })}
          onClick={e => this.handleTabClick(e, structure.idKey)}
          key={`tablinks-${i}`}
        >
          <span>{structure.day}</span>
          {structure.date == today ? '今日' : weekMap[structure.weekday]}
        </a>
      );
    });
    // @ts-ignore TS2322
    const tab = <GalleryTab links={tablinks} activeTabIndex={structureIdKey} label={month} unit={unit} center={true} />;
    // @ts-ignore TS2339
    const showCanvas = (browserInfo.isIOS || browserInfo.isAndroid) && !this.state.fallback;
    return (
      // @ts-ignore TS2339
      <div ref={this.canvasRef} className={classnames({ canvas: !showCanvas }, 'liveSchedule')}>
        {/*
         // @ts-ignore TS2339 */}
        {this.state.idKey ? (
          showCanvas ? (
            <Canvas
              // @ts-ignore TS2339
              model={this.props.model}
              // @ts-ignore TS2339
              canvasId={this.state.idKey}
              tabHeader={tab}
              // noContents={this.handleNoContents}
              liveSchedule={true}
            />
          ) : (
            <Palette
              // @ts-ignore TS2322
              liveSchedule={true}
              deliveryStartVisible={true}
              tabHeader={tab}
              // @ts-ignore TS2339
              paletteId={this.state.idKey}
              // @ts-ignore TS2339
              model={this.props.model}
              listContext={'editorial'}
              textArea={'delivery_start_at'}
              useRowHeader={false}
              headerVisuable={false}
              isGrid={true}
              shouldSkipGtm={true}
            />
          )
        ) : null}
        {spinLoader}
      </div>
    );
  }

  onScroll(e) {
    if (this.isWithinDistanceBuffer(300)) {
      // @ts-ignore TS2554
      this.fetchMoreRows(true);
    }
  }

  onScrollEnd(e) {}

  isWithinDistanceBuffer(distanceBuffer) {
    // @ts-ignore TS2339
    if (!this.canvasRef) return;
    // @ts-ignore TS2339
    const el = ReactDOM.findDOMNode(this.canvasRef.current);
    if (el) {
      return DOMUtils.getDistanceToBottomOfElement(el) < distanceBuffer;
    }
    return false;
  }
}

export default presentationHOC(LiveScheduleCanvas);
