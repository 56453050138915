import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';

export default class KickUser extends Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }

  static get propTypes() {
    return {
      closeModal: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    this._kickUser = this._kickUser.bind(this);
  }

  _kickUser() {
    // ユーザーを強制退室させる
    this.context.watchPartyApp
      // @ts-ignore TS2339
      .kickUser(this.props.user.id)
      .then(() => {
        // @ts-ignore TS2339
        this.props.closeModal();
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="modal-title">強制退室</div>
        <div className="text-left">
          <div className="modal-block">
            {/*
             // @ts-ignore TS2339 */}
            <div className="kick-user">{this.props.user.name}</div>
            <p>強制退室させたユーザーは再度入室することはできません。よろしいですか？</p>
          </div>
          <div className="form-btn-block text-center">
            {/*
             // @ts-ignore TS2339 */}
            <a className="btn btn-gray btn-small" href="javascript:void(0);" onClick={this.props.closeModal}>
              キャンセル
            </a>
            <a className="btn btn-fill btn-small" href="javascript:void(0);" onClick={this._kickUser}>
              強制退室させる
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
