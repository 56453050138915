import { useMemo } from 'react';
import _ from 'src/libs/util';

import * as ERROR from 'src/constants/error';
import datetime from 'src/libs/datetime';
import { META_DELIVERY_STATUS, getMetaDeliveryStatus } from 'src/apps/common/utils/metaUtil';
import displayDate from 'src/apps/common/utils/displayDate';

const useDateBox = ({ meta, error }) => {
  const dateBox = useMemo(() => {
    const box: { title: string; text: string }[] = [];
    if (meta) {
      const status = getMetaDeliveryStatus(meta);
      const errorType = error?.type;
      const broadcastStartAt = meta.cardInfo.broadcastStartAt;
      const startAt =
        errorType == ERROR.NOT_STARTED_ENTITLEMENT
          ? _.get(error, 'objects.entitlement.start_at')
          : status === META_DELIVERY_STATUS.BEFORE_LIVE
          ? meta.cardInfo.deliveryStartAt
          : null;

      if (meta.schemaId == 4 && broadcastStartAt && startAt) {
        // ライブ開始と
        const duration = datetime(broadcastStartAt).duration(startAt);
        if (duration.days() > 0 || duration.minutes() > 0 || duration.hours() > 0) {
          box.push({
            title: 'アクセス開始',
            text: displayDate('fullja', startAt, { suffix: 'からアクセス開始予定' }),
          });
        }
      }
      if (errorType == ERROR.NOT_STARTED_ENTITLEMENT && _.get(error, 'objects.entitlement.start_at')) {
        box.push({
          title: '視聴可能',
          text: displayDate('fullja', _.get(error, 'objects.entitlement.start_at'), { suffix: 'から視聴可能' }),
        });
      } else if (status === META_DELIVERY_STATUS.BEFORE_LIVE) {
        box.push({
          title: 'ライブ配信開始',
          text: displayDate('fullja', meta.cardInfo.broadcastStartAt, { suffix: 'から視聴可能' }),
        });
      } else if (status === META_DELIVERY_STATUS.BEFORE_DELIVERY) {
        // 視聴権限がない時など、サムネイル表示のエラー表示の場合には表示させる、tvodは無条件
        if (
          _.get(meta, 'rental') ||
          (error &&
            (errorType === ERROR.UNAUTHORIZED ||
              errorType === ERROR.LICENSE_INVALID ||
              errorType === ERROR.NOT_PLAYABLE))
        ) {
          box.push({
            title: '配信開始',
            text: displayDate('fullja', meta.cardInfo.deliveryStartAt, { suffix: 'から配信予定' }),
          });
        }
      } else if (status === META_DELIVERY_STATUS.END_DELIVERY_AFTER_X_DAYS) {
        box.push({
          title: '配信期間',
          text: '90日以内に配信終了の予定はありません',
        });
      } else if (status === META_DELIVERY_STATUS.END_DELIVERY_WITHIN_X_DAYS) {
        box.push({
          title: '配信期間',
          text: displayDate('fullja', meta.cardInfo.deliveryEndAt, { suffix: 'まで配信' }),
        });
      } else if (status === META_DELIVERY_STATUS.DELIVERY_ENDED) {
        box.push({
          title: '配信期間',
          text: displayDate('fullja', meta.cardInfo.deliveryEndAt, { suffix: 'に配信終了しました' }),
        });
      }
    }
    return box;
  }, [error, meta]);

  return dateBox;
};

export default useDateBox;
