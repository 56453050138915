import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import presentationHOC from '../../../common/hocs/presentationHOC';

import routes from '../../../common/routes';
import LoadingRow from '../loader/LoadingRow';
import ExclusiveUiRow from './ExclusiveUiRow';
import ExclusiveUiTab from '../../../common/components/ExclusiveUiTab';
import Canvas from './Canvas';

class ExclusiveUiContent extends Component {
  static getPaths = function(models, options, props) {
    let paths = [];
    const exclusiveUiContentRootPath = this.getExclusiveUiContentRootPath(models, options, props);
    const rowPaths = ExclusiveUiRow.getPaths(models, options, props);
    paths = rowPaths.map(path => {
      return exclusiveUiContentRootPath.concat(path);
    });
    if (props.currentTab) {
      if (props.currentTab.palette) {
        const currentTabRootPath = ['palette', props.currentTab.palette.id];
        paths = rowPaths.map(path => {
          return currentTabRootPath.concat(path);
        });
      } else if (props.currentTab.canvas) {
        paths = paths.concat(Canvas.getPaths(models, options, { canvasId: props.currentTab.canvas.id }));
      }
    }
    return paths;
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static getPrefetchedPaths = function(models, options, props) {
    return data => {
      const item = _.omit(_.get(data.json, this.getExclusiveUiContentRootPath(models, options, props)), ['$__path']);
      const tabs = _.get(item, 's3Data.tabs');
      const currentTab = this.getCurrentTab(models, options, { ...props, tabs });
      return this.getPaths(models, options, { ...props, currentTab });
    };
  };

  static getExclusiveUiContentRootPath = function(models, options, props) {
    if (props.id) {
      return ['palette', props.id];
    }
    return [];
  };

  static getColumnIndex = function(models, options, props) {
    const tabIndex = parseInt(props.routeHandler.query['column'], 10);
    return isNaN(tabIndex) ? 0 : tabIndex;
  };

  static getRowIndex = function(models, options, props) {
    const dropdown = parseInt(props.routeHandler.query['row'], 10);
    return isNaN(dropdown) ? 0 : dropdown;
  };

  static getCurrentTab(models, options, props) {
    const column = this.getColumnIndex(models, options, props);
    const tabs = props.tabs;
    let tab = tabs[column];

    if (tab.type === 'dropdown') {
      const row = this.getRowIndex(models, options, props);
      tab = tab.items[row];
    }
    return tab;
  }

  static get propTypes() {
    return {
      model: PropTypes.object.isRequired,
    };
  }
  static get contextTypes() {
    return {
      routeHandler: PropTypes.object,
      models: PropTypes.object,
      getModelData: PropTypes.func,
      history: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.canvasRef = React.createRef();
    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);

    // @ts-ignore TS2339
    const getExclusiveUiContentRootPath = this.constructor.getExclusiveUiContentRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    this.exclusiveUiContent = this.model.getSync(getExclusiveUiContentRootPath);

    this.state = {
      paletteId: null,
      canvasId: null,
      dispose: null,
      // @ts-ignore TS2339
      column: this.constructor.getColumnIndex(context.models, {}, props),
      // @ts-ignore TS2339
      row: this.constructor.getRowIndex(context.models, {}, props),
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  get tabWithProps() {
    // @ts-ignore TS2339
    const tabs = _.get(this.exclusiveUiContent, 's3Data.tabs') || [];
    return tabs.map((tab, idx) => {
      const _tab = {
        name: tab.name,
        // @ts-ignore TS2339
        active: this.state.column === idx,
        type: tab.type || 'link',
        key: `tab-${idx}`,
      };
      if (_tab.type === 'dropdown') {
        // @ts-ignore TS2339
        _tab.items = tab.items.map((item, dropdownIdx) => {
          const onClick = () => {};
          let linkProps = {};
          if (item.canvas || item.palette) {
            linkProps = {
              to: routes.exclusiveUi,
              // @ts-ignore TS2339
              params: { id: this.exclusiveUiContent.id },
              query: { row: dropdownIdx, column: idx },
              onClick,
            };
          }
          return {
            name: item.name,
            linkProps,
            palette: item.palette,
            canvas: item.canvas,
            key: `dropdown-${idx}-${dropdownIdx}`,
            // @ts-ignore TS2339
            active: this.state.column === idx && this.state.row === dropdownIdx,
          };
        });
      } else {
        const onClick = () => {};
        // @ts-ignore TS2339
        _tab.palette = tab.palette;
        // @ts-ignore TS2339
        _tab.canvas = tab.canvas;
        // @ts-ignore TS2339
        _tab.hasObjects = !!_.get(tab, 'canvas.id') || !!_.get(tab, 'palette.id');
        if (tab.canvas || tab.palette) {
          // @ts-ignore TS2339
          _tab.linkProps = {
            to: routes.exclusiveUi,
            // @ts-ignore TS2339
            params: { id: this.exclusiveUiContent.id },
            query: { column: idx },
            onClick,
          };
        }
      }
      return _tab;
    });
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const exclusiveUiContentRootPath = this.constructor.getExclusiveUiContentRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;
    // @ts-ignore TS2339
    const evaluator = this.model.fetch(paths);
    const dispose = evaluator.dispose;
    this.setState({ dispose });
    const newState = {
      dispose: null,
    };
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.exclusiveUiContent = _.get(res.json, exclusiveUiContentRootPath);

        this.setState(newState);
      })
      .catch(e => {
        // @ts-ignore TS2339
        newState.fetchDataError = e;
        delete this.state[JSON.stringify(paths)];
        this.setState(newState);
      });
  }

  render() {
    const tabs = this.tabWithProps;
    if (_.isEmpty(tabs)) {
      // @ts-ignore TS2339
      if (this.state.dispose) {
        return <LoadingRow />;
      }
    }
    // @ts-ignore TS2339
    const currentTab = this.constructor.getCurrentTab(this.context.models, {}, { ...this.props, tabs });
    let content = null;
    if (_.get(currentTab, 'canvas.id')) {
      return (
        <Canvas
          {...this.props}
          canvasId={currentTab.canvas.id}
          // @ts-ignore TS2339
          model={this.model}
          // @ts-ignore TS2322
          tabHeader={<ExclusiveUiTab tabs={tabs} />}
        />
      );
    } else if (_.get(currentTab, 'palette.id')) {
      content = (
        <ExclusiveUiRow
          // @ts-ignore TS2322
          paletteId={currentTab.palette.id}
          // @ts-ignore TS2339
          model={this.model}
          listContext="exclusiveUi"
          rowNum={1}
          orderNum={1}
          // @ts-ignore TS2339
          noContents={this._noContents}
        />
      );
    }

    return (
      <div className="canvas">
        {content ? (
          <React.Fragment>
            {/*
             // @ts-ignore TS2322 */}
            <ExclusiveUiTab tabs={tabs} />
            {content}
          </React.Fragment>
        ) : (
          // @ts-ignore TS2322
          <div className="pulsate-transition" timeout={{ exit: 550, enter: 250 }}>
            <div>
              <div className="no_img" />
              <div className="gallery-message">登録されている作品がありません。</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default presentationHOC(ExclusiveUiContent);
