import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';

import TargetTitle from './TargetTitle';
import Input from './Input';

export default class UseComment extends Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }
  static get propTypes() {
    return {
      watchPartyMode: PropTypes.bool,
      handleWatchPartyModeChange: PropTypes.func,
      closeModal: PropTypes.func,
      titleModel: PropTypes.object,
      seriesModel: PropTypes.object,
    };
  }
  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.nameRef = React.createRef();
    const profole = context.watchPartyApp.getProfile();
    this.state = {
      stepNum: 1,
      name: profole.name,
      errorMessage: null,
    };
    this._handleWatchPartyStart = this._handleWatchPartyStart.bind(this);
    this._handleClose = this._handleClose.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleEnter = this._handleEnter.bind(this);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    if (this.loadingTimer) {
      // @ts-ignore TS2339
      clearTimeout(this.loadingTimer);
      // @ts-ignore TS2339
      delete this.loadingTimer;
    }
  }

  _handleClose() {
    // @ts-ignore TS2339
    this.props.closeModal();
  }

  _handleWatchPartyStart() {
    // TODO: 入力チェック
    this.setState({ loading: true }, () => {
      // ローディングの描画が追いつかない為
      // @ts-ignore TS2339
      this.loadingTimer = setTimeout(() => {
        this._joinWithName();
      }, 10);
    });
  }

  _joinWithName() {
    // @ts-ignore TS2339
    this.context.watchPartyApp.joinWithName(this.state.name, {}, (error, body) => {
      // console.log(error, body);
      this.setState({ loading: false });

      // NGワード
      if (_.has(error, 'message') && -1 !== error.message.indexOf('includes ng word')) {
        this.setState({ errorMessage: '不適切な表現が含まれています' });
        return;
      }

      if (error) {
        // TODO: error処理
        console.log(error);
        return;
      }

      // @ts-ignore TS2339
      this.props.termsChange();
    });
  }

  _handleChange(e) {
    this.setState({ name: e.target.value, errorMessage: null });
  }

  _handleEnter(e) {
    if (e.keyCode == 13) {
      this._handleWatchPartyStart();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="modal-title">チャットに参加する</div>
        <div className="text-left">
          <div className="modal-block">
            <TargetTitle
              // @ts-ignore TS2339
              titleModel={this.props.titleModel}
              // @ts-ignore TS2339
              seriesModel={this.props.seriesModel}
              // @ts-ignore TS2339
              linearModel={this.props.linearModel}
              roomInfoView={false}
            />
          </div>
          <div className="modal-block">
            <Input
              // @ts-ignore TS2339
              ref={this.nameRef}
              label={'ニックネーム'}
              type="text"
              placeholder={'ニックネームを入力してください'}
              maxLength={8}
              // @ts-ignore TS2322
              onChange={this._handleChange}
              onKeyDown={this._handleEnter}
              // @ts-ignore TS2339
              value={this.state.name}
              required={true}
              // @ts-ignore TS2339
              errorMessage={this.state.errorMessage}
            />
            <div className="input-block">
              <p>
                コメントを投稿するには、
                {/*
                 // @ts-ignore TS2322 */}
                <a href="/chat_guideline" target="_blank" class="text-accent">
                  チャットガイドライン
                </a>
                への同意が必要です。
              </p>
            </div>
          </div>
          <div className="form-btn-block text-center">
            <a className="btn btn-gray btn-small" href="javascript:void(0);" onClick={this._handleClose}>
              キャンセル
            </a>
            <a
              // @ts-ignore TS2339
              className={classnames('btn btn-fill btn-small', { disabled: _.isEmpty(this.state.name) })}
              href="javascript:void(0);"
              onClick={this._handleWatchPartyStart}
            >
              同意して入室
            </a>
          </div>
        </div>
        {/*
         // @ts-ignore TS2339 */}
        {this.state.loading ? (
          <div className="comment-loading">
            <div className="loading-spinner"></div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
