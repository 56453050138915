import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';
import HtmlSnippet from '../../../HtmlSnippet';

export default class PaymentInfoForm extends React.PureComponent {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  static get propTypes() {
    return {
      paymentInstruments: PropTypes.array.isRequired,
      paymentMethods: PropTypes.array.isRequired,
      setNextStep: PropTypes.func.isRequired,
      actionName: PropTypes.string,
      nextStep: PropTypes.bool,
      hidden: PropTypes.bool,
      changeable: PropTypes.bool,
    };
  }

  static get defaultProps() {
    return {
      actionName: '購入',
      changeable: true,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.setPaymentInfo(props);

    // @ts-ignore TS2339
    this.radioChangePaymentRef = React.createRef();
    // @ts-ignore TS2339
    this.paymentMethodIdRef = React.createRef();

    this.handlePaymentChange = this.handlePaymentChange.bind(this);
    this.handleSelectPaymentInstrument = this.handleSelectPaymentInstrument.bind(this);
    this.handleSelectPaymentMethod = this.handleSelectPaymentMethod.bind(this);
    this.handleClickAddPayment = this.handleClickAddPayment.bind(this);

    this.state = {
      // @ts-ignore TS2339
      paymentMethodId: this.defaultPaymentMethod.id,
      // @ts-ignore TS2339
      paymentInstrumentId: _.get(this.defaultPaymentInstrument, 'id'),
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillReceiveProps(newProps) {
    this.setPaymentInfo(newProps);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  //
  // デフォルトの支払情報などの表示に必要な情報を
  // props.paymentInstrumentsとprops.paymentMethodsから取得する
  //
  setPaymentInfo(props) {
    // 利用可能なお支払い情報
    // @ts-ignore TS2339
    const paymentMethodRule = _.get(this.props.product, 'paymentMethodRule');
    // @ts-ignore TS2339
    this.activePaymentInstruments = _.filter(props.paymentInstruments, paymentInstrument => {
      return (
        paymentInstrument.isAvailable &&
        (_.isEmpty(paymentMethodRule) || paymentMethodRule.includes(_.get(paymentInstrument, 'paymentMethod.id')))
      );
    });

    // デフォルトのお支払い情報
    let defaultPaymentInstrumentIndex = 0;
    // @ts-ignore TS2339
    if (this.props.defaultPaymentInstrumentId != null) {
      // @ts-ignore TS2339
      defaultPaymentInstrumentIndex = _.findIndex(this.activePaymentInstruments, paymentInstrument => {
        // @ts-ignore TS2339
        return paymentInstrument.id == this.props.defaultPaymentInstrumentId;
      });
      // tvodは最後に登録された支払い方法をデフォルトとする
      // } else {
      //   defaultPaymentInstrumentIndex = _.findIndex(this.activePaymentInstruments, paymentInstrument => {
      //     return paymentInstrument.defaultFlag;
      //   });
    }
    if (-1 === defaultPaymentInstrumentIndex) {
      defaultPaymentInstrumentIndex = 0;
    }
    // @ts-ignore TS2339
    if (this.activePaymentInstruments[defaultPaymentInstrumentIndex]) {
      // @ts-ignore TS2339
      this.defaultPaymentInstrument = this.activePaymentInstruments[defaultPaymentInstrumentIndex];
    }

    // デフォルトのお支払い方法
    let defaultPaymentMethodIndex = _.findIndex(props.paymentMethods, paymentMethod => {
      return (
        // @ts-ignore TS2339
        _.get(this.defaultPaymentInstrument, 'paymentMethod.mode') == paymentMethod.mode &&
        // @ts-ignore TS2339
        paymentMethod.id != _.get(this.defaultPaymentInstrument, 'paymentMethod.id')
      );
    });
    if (-1 == defaultPaymentMethodIndex) {
      defaultPaymentMethodIndex = _.findIndex(props.paymentMethods, paymentMethod => {
        // @ts-ignore TS2339
        return _.get(this.defaultPaymentInstrument, 'paymentMethod.mode') == paymentMethod.mode;
      });
    }
    if (-1 == defaultPaymentMethodIndex) {
      defaultPaymentMethodIndex = 0;
    }
    // @ts-ignore TS2339
    this.defaultPaymentMethod = props.paymentMethods[defaultPaymentMethodIndex];
  }

  //
  // 支払情報の登録へ進むかどうかの切り替え
  //
  handlePaymentChange(e) {
    if (_.get(this, 'radioChangePaymentRef.current.checked')) {
      // @ts-ignore TS2339
      this.props.setNextStep(true);
    } else {
      // @ts-ignore TS2339
      this.props.setNextStep(false);
    }
  }

  handleSelectPaymentInstrument(e) {
    // @ts-ignore TS2339
    if (this._isMounted) {
      this.setState({ paymentInstrumentId: e.target.value });
    }
  }

  handleSelectPaymentMethod(e) {
    // @ts-ignore TS2339
    if (this._isMounted) {
      this.setState({ paymentMethodId: e.target.value });
    }
  }

  //
  // 選択中のPaymentMethodオブジェクトを取得
  //
  getSelectedPaymentMethodInfo() {
    // @ts-ignore TS2339
    const selectPaymentMethodIndex = _.findIndex(this.props.paymentMethods, paymentMethod => {
      // @ts-ignore TS2339
      return paymentMethod.id == this.state.paymentMethodId;
    });
    // @ts-ignore TS2339
    return this.props.paymentMethods[selectPaymentMethodIndex];
  }

  //
  // 選択中のPaymentInstrumentオブジェクトを取得
  //
  getSelectedPaymentInstrumentInfo() {
    // @ts-ignore TS2339
    const selectPaymentInstrumentIndex = _.findIndex(this.props.paymentInstruments, paymentInstrument => {
      // @ts-ignore TS2339
      return paymentInstrument.id == this.state.paymentInstrumentId;
    });
    // @ts-ignore TS2339
    return this.props.paymentInstruments[selectPaymentInstrumentIndex];
  }

  handleClickAddPayment(e) {
    // @ts-ignore TS2339
    if (this.props.handleAddPayment) this.props.handleAddPayment(e);
  }

  render() {
    // WOWOWオンデマンドチケットを使うときは支払い情報の表示はしない
    // @ts-ignore TS2339
    if (this.props.hidden) {
      // @ts-ignore TS2339
      return <input name="paymentInstrument" value={this.state.paymentInstrumentId} type="hidden" />;
    }

    // 登録済みの支払い方法
    let viewPaymentInstrumentsNode;
    // @ts-ignore TS2339
    if (this.props.changeable) {
      const paymentInstrumentsOptions = _.compact(
        // @ts-ignore TS2339
        this.activePaymentInstruments.map(paymentInstrument => {
          return paymentInstrument;
        }),
      );
      const paymentInstrumentsNode = _.compact(
        paymentInstrumentsOptions.map(paymentInstrument => {
          // @ts-ignore TS2339
          if (paymentInstrument.paymentMethod.isAppleIAP || paymentInstrument.isAmazonIAP) return null;
          // @ts-ignore TS2339
          if (paymentInstrument.paymentMethod.payMethod == 'card') {
            return (
              // @ts-ignore TS2339
              <option key={`payment_instrument_${paymentInstrument.id}`} value={paymentInstrument.id}>
                {/*
                 // @ts-ignore TS2339 */}
                {paymentInstrument.paymentMethod.displayName} **** - **** - **** -{' '}
                {/*
                 // @ts-ignore TS2339 */}
                {paymentInstrument.paymentDetail.cardNumber}
              </option>
            );
          } else {
            return (
              // @ts-ignore TS2339
              <option key={`payment_instrument_${paymentInstrument.id}`} value={paymentInstrument.id}>
                {/*
                 // @ts-ignore TS2339 */}
                {paymentInstrument.paymentMethod.displayName}
              </option>
            );
          }
        }),
      );
      viewPaymentInstrumentsNode = _.isEmpty(paymentInstrumentsOptions) ? null : (
        <div className="select-group">
          <select
            name="paymentInstrument"
            // @ts-ignore TS2339
            value={this.state.paymentInstrumentId}
            onChange={this.handleSelectPaymentInstrument}
          >
            {paymentInstrumentsNode}
          </select>
        </div>
      );
      // changeable=falseの場合は、デフォルトの決済方法を表示するのみ
    } else {
      // @ts-ignore TS2339
      if (this.defaultPaymentInstrument) {
        viewPaymentInstrumentsNode = (
          <React.Fragment>
            {/*
             // @ts-ignore TS2339 */}
            <div>{_.get(this.defaultPaymentInstrument, 'paymentMethod.displayName')}</div>
            {/*
             // @ts-ignore TS2339 */}
            <input name="paymentInstrument" value={this.state.paymentInstrumentId} type="hidden" />
          </React.Fragment>
        );
      }
    }

    // 新規登録する支払い方法
    let viewPaymentMethodsNode;
    // @ts-ignore TS2339
    if (this.props.changeable || !this.defaultPaymentInstrument) {
      const paymentMethodsOptions = _.compact(
        // @ts-ignore TS2339
        this.props.paymentMethods.map(paymentMethod => {
          return paymentMethod;
        }),
      );
      const paymentMethodsNode = _.compact(
        paymentMethodsOptions.map(paymentMethod => {
          // @ts-ignore TS2339
          if (paymentMethod.isAppleIAP || paymentMethod.isAmazonIAP) return null;
          return (
            // @ts-ignore TS2339
            <option key={`payment_method_${paymentMethod.id}`} value={paymentMethod.id}>
              {/*
               // @ts-ignore TS2339 */}
              {paymentMethod.displayName}
            </option>
          );
        }),
      );
      viewPaymentMethodsNode = _.isEmpty(paymentMethodsOptions) ? null : (
        <div className="select-group">
          {/*
           // @ts-ignore TS2339 */}
          <select name="paymentMethod" value={this.state.paymentMethodId} onChange={this.handleSelectPaymentMethod}>
            {paymentMethodsNode}
          </select>
        </div>
      );
    }

    const snippetId = _.get(this.context.getModelData('config'), 'html_snippet_keys.payment_method_notice');
    let snippetNode;
    if (snippetId) {
      // @ts-ignore TS2322
      snippetNode = <HtmlSnippet {...this.props} snippetId={snippetId} model={this.props.model} />;
    }

    let carrierPaymentCaution = null;
    const CARRIER_PAYMENT_METHOD_IDS = [1, 2, 3];
    // 一旦5000円以上
    const PRICE = 5000;
    const selectedPaymentInstrument = _.find(
      // @ts-ignore TS2339
      this.activePaymentInstruments,
      // @ts-ignore TS2339
      paymentInstrument => this.state.paymentInstrumentId == paymentInstrument.id,
    );
    if (
      CARRIER_PAYMENT_METHOD_IDS.some(id => id == _.get(selectedPaymentInstrument, 'paymentMethod.id')) &&
      // @ts-ignore TS2339
      _.get(this.props.product, 'activePricing.paymentAmount') >= PRICE
    ) {
      carrierPaymentCaution = (
        <div className="payment-caution">
          キャリア決済でのお支払いは、限度額が設定されています。
          <br />
          ご自身の限度額をご確認ください。
        </div>
      );
    }

    return (
      <div className="payment-box">
        {viewPaymentInstrumentsNode ? (
          <div className="radio-box payment-form">
            <input
              id="payment"
              type="radio"
              value="payment"
              name="check"
              className="radio-button"
              // @ts-ignore TS2339
              defaultChecked={!this.props.nextStep}
              onChange={this.handlePaymentChange}
            />
            <label htmlFor="payment" className="radio-button-label">
              {/*
               // @ts-ignore TS2339 */}
              登録済みのお支払い情報で{this.props.actionName}する
            </label>
            {/*
             // @ts-ignore TS2339 */}
            {!this.props.nextStep ? viewPaymentInstrumentsNode : null}
          </div>
        ) : null}
        {carrierPaymentCaution}

        {/* {viewPaymentMethodsNode ? (
          <div className="radio-box payment-form">
            <input id="paymentChange" type="radio" value="paymentChange"
              name="check" className="radio-button" defaultChecked={this.props.nextStep}
              ref={this.radioChangePaymentRef} onChange={this.handlePaymentChange} />
            <label htmlFor="paymentChange" className="radio-button-label">
              新しいお支払い情報を登録して{this.props.actionName}する
            </label>
            {this.props.nextStep ? viewPaymentMethodsNode : null}
          </div>
        ) : null} */}
        <div className="payment-add" onClick={this.handleClickAddPayment}>
          <i className="fa-favorite-add"></i>新しいお支払い方法を追加する
        </div>
        {snippetNode}
      </div>
    );
  }
}
