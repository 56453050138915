import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';

import MainViewLink from '../../../../common/components/MainViewLink';
import routes from '../../../../common/routes';
// @ts-ignore TS1192
import reactNl2br from '../../../../../common/reactNl2br';
import getRatingText from 'src/utils/getRatingText';

const LOCALES = {
  casts: '出演',
  staffs: 'スタッフ',
  translators: '翻訳者',
  producers: 'プロデューサー',
  directors: '監督/演出',
  scenario_writers: '脚本',
  musics: '楽曲',
  release_date: '公開日',
  first_broadcast_date: '初回',
  productionYear: '制作年',
  country: '制作国',
  recording_date: '収録日',
  award: '受賞履歴',
  match_summary: '対戦カード',
};

const formatRating = function(rating) {
  if (_.isArray(rating)) return _.join(rating, '・');
  return rating;
};

export default class Details extends Component {
  static getPaths = function(models, options, props = {}) {
    const rootPath = this.getRootPath(models, options, props);
    return [
      rootPath.concat([
        [
          'id',
          'name',
          'casts',
          'staffs',
          'copyrights',
          'cardInfo',
          'original',
          'original_authors',
          'genres',
          'middleGenres',
          'studio',
          'directors',
          'scenario_writers',
          'producers',
          'review_score',
          'musics',
          'music_number',
          'release_date',
          'first_broadcast_date',
          'country',
          'award',
          'language',
          'production',
          'remarks',
          'recording_date',
          'match_summary',
          'homeTeam',
          'awayTeam',
          'jpPlayers',
          'starPlayers',
          'translators',
        ],
      ]),
    ];
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (!props.id) return [];
    // @ts-ignore TS2339
    return ['meta', props.id];
  };

  static get propTypes() {
    return {
      onSliderMove: PropTypes.func,
      watched: PropTypes.bool,
      model: PropTypes.object.isRequired,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };
  }

  static get contextTypes() {
    return {
      models: PropTypes.object,
      gtmApp: PropTypes.object,
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    this.state = {
      fetchDataError: null,
      dispose: null,
      generation: props.model.getVersion(rootPath),
    };
    this.onClickLink = this.onClickLink.bind(this);
    // @ts-ignore TS2339
    this.item = props.model.getSync(rootPath);
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // @ts-ignore TS2339
    if (nextProps.id !== this.props.id) {
      // @ts-ignore TS2339
      const rootPath = this.constructor.getRootPath(nextContext.models, {}, nextProps);
      // @ts-ignore TS2339
      this.item = nextProps.model.getSync(rootPath);
      this.setState({ generation: nextProps.model.getVersion(rootPath), fetchDataError: null }, () => {
        this.fetchData(nextProps);
      });
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  fetchData(props) {
    if (!props.id) return;

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.get(res, ['json'].concat(rootPath));
        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  onClickLink(propertyType, propertyName) {
    return () => {
      if (this.context.gtmApp) {
        this.context.gtmApp.pushDataLayerOnContentProperyClick(propertyType, propertyName);
      }
    };
  }

  render() {
    const metaDetails = [];
    let lists = [];
    let label = [];

    // @ts-ignore TS2339
    if (!this.item.cardInfo) this.item.cardInfo = {};

    ['casts', 'staffs', 'translators'].forEach(key => {
      // @ts-ignore TS2339
      if (!this.item[key] || _.isEmpty(this.item[key])) {
        // @ts-ignore TS2339
        if (!this.item.cardInfo[key] || _.isEmpty(this.item.cardInfo[key])) {
          return;
        }
      }
      // @ts-ignore TS2339
      let values = this.item[key];
      if (!Array.isArray(values)) {
        values = [values];
      }

      const positions = _.uniq(
        _.map(values, val => {
          if (!_.get(val, 'position')) {
            // なければ一旦出演/スタッフで
            _.set(val, 'position', LOCALES[key]);
          }
          return val['position'];
        }),
      );

      _.forEach(positions, position => {
        label.push(
          <div className="listLabel" key={`meta_details_${key}_label`}>
            {position}
          </div>,
        );
        // 同じポジション
        const samePositionPeople = _.filter(values, val => _.get(val, 'position') == position);

        _.forEach(samePositionPeople, (person, idx) => {
          if (typeof person === 'string') {
            lists.push(
              <li key={`meta_details_${metaDetails.length}_${lists.length}_${idx}`} className="wide-list">
                {person}
              </li>,
            );
          } else if (_.get(person, 'id')) {
            let notice = person.notice;
            if (notice && notice.length === 0) notice = null;
            if (notice) notice = `(${notice})`;

            lists.push(
              <li className="list-link" key={`meta_details_${metaDetails.length}_${lists.length}_${person.id}`}>
                <MainViewLink
                  // @ts-ignore TS2322
                  to={routes.person}
                  params={{ id: person.id }}
                  query={{ type: 'od' }}
                  // @ts-ignore TS2339
                  tabIndex={this.props.open ? 0 : -1}
                  onClick={this.onClickLink(position, person.name)}
                >
                  {person.name}
                  {notice ? <span>{notice}</span> : null}
                </MainViewLink>
                {notice ? <span>{notice}</span> : null}
              </li>,
            );
          }
        });
        if (!_.isEmpty(lists)) {
          metaDetails.push(
            <div className="meta-details-item" key={`meta_details_${metaDetails.length}`}>
              {label}
              <ul>{lists}</ul>
            </div>,
          );
        }
        lists = [];
        label = [];
      });
    });

    [
      'scenario_writers',
      'producers',
      'directors',
      'musics',
      'release_date',
      'first_broadcast_date',
      'productionYear',
      'country',
      'recording_date',
      'award',
      'match_summary',
    ].map(key => {
      // @ts-ignore TS2339
      if (!this.item) return;
      // numberだとisEmptyはtrueが返ってきてしまうので
      if (
        // @ts-ignore TS2339
        !this.item ||
        // @ts-ignore TS2339
        this.item[key] == undefined ||
        // @ts-ignore TS2339
        (typeof this.item[key] !== 'number' && _.isEmpty(this.item[key]))
      ) {
        if (
          // @ts-ignore TS2339
          this.item.cardInfo[key] == undefined ||
          // @ts-ignore TS2339
          (typeof this.item.cardInfo[key] !== 'number' && _.isEmpty(this.item.cardInfo[key]))
        ) {
          return;
        }
      }
      // 制作年に0が入ってくることがあるので弾く
      // @ts-ignore TS2339
      if (key === 'productionYear' && this.item.cardInfo[key] == 0) return;

      label.push(
        <div className="listLabel" key={`meta_details_${key}_label`}>
          {LOCALES[key]}
        </div>,
      );
      let values;
      if (key === 'productionYear') {
        // @ts-ignore TS2339
        values = this.item.cardInfo[key] || [];
      } else {
        // @ts-ignore TS2339
        values = this.item[key] || [];
      }
      if (Object.prototype.toString.call(values) !== '[object Array]') {
        values = [values];
      }
      values.map((val, index) => {
        if (typeof val === 'string' || typeof val === 'number') {
          lists.push(
            <li key={`meta_details_${metaDetails.length}_${lists.length}_${index}`} className="wide-list">
              {val}
            </li>,
          );
        } else if (val.id) {
          let notice = val.notice;
          if (notice && notice.length === 0) notice = null;
          if (notice) notice = `(${notice})`;
          const routeKey = val.attribute_type === 'attribute' ? 'attr' : val.attribute_type;
          if (routes[routeKey]) {
            lists.push(
              <li className="list-link" key={`meta_details_${metaDetails.length}_${lists.length}_${val.id}`}>
                <MainViewLink
                  // @ts-ignore TS2322
                  to={routes[routeKey]}
                  params={{ id: val.id }}
                  query={{ type: 'od' }}
                  // @ts-ignore TS2339
                  tabIndex={this.props.open ? 0 : -1}
                  onClick={this.onClickLink(LOCALES[key], val.name)}
                >
                  {val.name}
                  {notice ? <span>{notice}</span> : null}
                </MainViewLink>
                {notice ? <span>{notice}</span> : null}
              </li>,
            );
          } else {
            lists.push(
              <li key={`meta_details_${metaDetails.length}_${lists.length}_${val.id}`} className="list-link">
                {val.name}
                {notice ? <span>{notice}</span> : null}
              </li>,
            );
          }
        }
      });
      if (!_.isEmpty(lists)) {
        metaDetails.push(
          <div
            className={classnames('meta-details-item', { 'col-2': key == 'musics' })}
            key={`meta_details_${metaDetails.length}`}
          >
            {label}
            <ul>{lists}</ul>
          </div>,
        );
      }
      lists = [];
      label = [];
    });
    // if (this.item && this.item.studio) {
    //   label.push((<div key={`meta_details_studio_label`} className="listLabel">スタジオ</div>));
    //   lists.push((<li className="list-link" key={`meta_details_studio`}><MainViewLink to={routes.studio} params={{id: this.item.studio.id}} tabIndex={this.props.open ? 0 : -1}>{this.item.studio.name}</MainViewLink></li>));

    //   if (!_.isEmpty(lists)) {
    //     metaDetails.push(
    //       <div className="meta-details-item" key={`meta_details_${metaDetails.length}`}>
    //         {label}
    //         <ul>{lists}</ul>
    //       </div>,
    //     );
    //   }
    //   lists = [];
    //   label = [];
    // }

    if (
      // @ts-ignore TS2339
      this.item &&
      // @ts-ignore TS2339
      this.item.genres &&
      // @ts-ignore TS2339
      Object.prototype.toString.call(this.item.genres) === '[object Array]' &&
      // @ts-ignore TS2339
      this.item.genres.length > 0
    ) {
      label.push(
        <div key={`meta_details_genres_label`} className="listLabel">
          ジャンル
        </div>,
      );
      // @ts-ignore TS2339
      (this.item.genres || []).map((genre, index) => {
        let props = {
          to: routes.genre,
          params: { id: genre.id },
          query: { so: 'po' },
        };
        if (genre.refId) {
          props = {
            to: routes.genreRef,
            params: { id: genre.refId },
            query: { so: 'po' },
          };
        }
        lists.push(
          <li className="list-link" key={`meta_details_${metaDetails.length}_${lists.length}_${genre.id}`}>
            <MainViewLink
              {...props}
              // @ts-ignore TS2322
              tabIndex={this.props.open ? 0 : -1}
              onClick={this.onClickLink('ジャンル', genre.name)}
            >
              {genre.name}
            </MainViewLink>
          </li>,
        );
      });
      if (!_.isEmpty(lists)) {
        metaDetails.push(
          <div className="meta-details-item" key={`meta_details_${metaDetails.length}`}>
            {label}
            <ul>{lists}</ul>
          </div>,
        );
      }
      lists = [];
      label = [];
    }

    // for debug
    // metaDetails.push(
    //   <RatingBar metaId={5370} />
    // );
    let original_authors = [];
    // @ts-ignore TS2339
    if (this.item && this.item.original_authors) {
      // @ts-ignore TS2339
      let values = this.item.original_authors || [];
      if (Object.prototype.toString.call(values) !== '[object Array]') {
        values = [values];
      }
      values.map((val, index) => {
        let notice = val.notice;
        if (notice && notice.length === 0) notice = null;
        if (notice) notice = `(${notice})`;
        if (typeof val === 'string') {
          original_authors.push(
            // @ts-ignore TS2339
            <li className="list-link" key={`meta_details_${metaDetails.length}_${original_authors.length}_${val.id}`}>
              {val}
              <span>{notice}</span>
            </li>,
          );
        } else if (val.id) {
          original_authors.push(
            <li className="list-link" key={`meta_details_${metaDetails.length}_${original_authors.length}_${val.id}`}>
              <MainViewLink
                // @ts-ignore TS2322
                to={routes.person}
                params={{ id: val.id }}
                query={{ type: 'od' }}
                // @ts-ignore TS2339
                tabIndex={this.props.open ? 0 : -1}
              >
                {val.name}
                {notice ? <span>{notice}</span> : null}
              </MainViewLink>
              {notice ? <span>{notice}</span> : null}
            </li>,
          );
        }
      });
    }
    const basicListLabels = {
      music_number: '楽曲',
      review_score: 'この作品の評価',
      production: '制作著作',
    };
    const basicList = (items, key) => {
      if (!items[key]) return null;
      return (
        <div className="meta-details-item">
          <div className="listLabel">{basicListLabels[key]}</div>
          <ul key={`details_item_${key}`}>
            <li className="wide-list">{reactNl2br(items[key])}</li>
          </ul>
        </div>
      );
    };
    const teamItem = item => {
      if (!item) return null;
      return (
        <MainViewLink
          // @ts-ignore TS2322
          to={routes.team}
          params={{ id: item.id }}
          // @ts-ignore TS2339
          tabIndex={this.props.open ? 0 : -1}
          onClick={this.onClickLink('チーム', item.name)}
        >
          {item.name}
        </MainViewLink>
      );
    };
    // @ts-ignore TS2339
    const homeTeam = _.get(this.item, 'homeTeam.id') ? this.item.homeTeam : null;
    // @ts-ignore TS2339
    const awayTeam = _.get(this.item, 'awayTeam.id') ? this.item.awayTeam : null;
    // @ts-ignore TS2339
    const jpPlayers = _.reject(this.item.jpPlayers, { id: null });
    // @ts-ignore TS2339
    const starPlayers = _.reject(this.item.starPlayers, { id: null });
    // @ts-ignore TS2339
    const ratingText = getRatingText(this.context.models, this.item.cardInfo.rating);

    return (
      <div id="metaDetails">
        <div className="meta-details">
          {metaDetails}
          {/*
           // @ts-ignore TS2339 */}
          {this.item ? (
            <React.Fragment>
              {/*
               // @ts-ignore TS2339 */}
              {ratingText && (
                <div className="meta-details-item">
                  <div className="listLabel">年齢制限</div>
                  <ul key={'details_item_rating'}>
                    <li className="maturity-rating">
                      {// @ts-ignore TS2339
                      formatRating(ratingText)}
                    </li>
                  </ul>
                </div>
              )}
              {/*
               // @ts-ignore TS2339 */}
              {(!!this.item.original || original_authors.length > 0) && (
                <div className="meta-details-item">
                  <div className="listLabel">原作</div>
                  <ul key={'details_item_original'}>
                    {/*
                     // @ts-ignore TS2339 */}
                    {this.item.original && (
                      <li className={classnames('original', { original_after: original_authors.length > 0 })}>
                        {/*
                         // @ts-ignore TS2339 */}
                        {this.item.original}
                      </li>
                    )}
                    {original_authors}
                  </ul>
                </div>
              )}

              {/*
               // @ts-ignore TS2554 */}
              {basicList('music_number')}
              {/*
               // @ts-ignore TS2554 */}
              {basicList('review_score')}
              {/*
               // @ts-ignore TS2554 */}
              {basicList('production')}

              {(!!homeTeam || !!awayTeam) && (
                <div className="meta-details-item">
                  <div className="listLabel">チーム</div>
                  <ul key={`details_item_team`}>
                    {homeTeam && <li className="list-list">{teamItem(homeTeam)}</li>}
                    {awayTeam && <li className="list-list">{teamItem(awayTeam)}</li>}
                  </ul>
                </div>
              )}
              {!_.isEmpty(jpPlayers) && (
                <div className="meta-details-item">
                  <div className="listLabel">日本人注目選手</div>
                  <ul key={`details_item_jp_players`}>
                    {_.map(jpPlayers, item => {
                      return (
                        <li key={`jp_player_${item.id}`} className="list-list">
                          <MainViewLink
                            // @ts-ignore TS2322
                            onClick={this.onClickLink('日本人注目選手', item.name)}
                            to={routes.person}
                            params={{ id: item.id }}
                            query={{ type: 'od' }}
                            // @ts-ignore TS2339
                            tabIndex={this.props.open ? 0 : -1}
                          >
                            {item.name}
                          </MainViewLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              {!_.isEmpty(starPlayers) && (
                <div className="meta-details-item">
                  <div className="listLabel">その他注目選手</div>
                  <ul key={`details_item_star_players`}>
                    {_.map(starPlayers, item => {
                      return (
                        <li key={`star_player_${item.id}`} className="list-list">
                          <MainViewLink
                            // @ts-ignore TS2322
                            onClick={this.onClickLink('その他注目選手', item.name)}
                            to={routes.person}
                            params={{ id: item.id }}
                            query={{ type: 'od' }}
                            // @ts-ignore TS2339
                            tabIndex={this.props.open ? 0 : -1}
                          >
                            {item.name}
                          </MainViewLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              {/*
               // @ts-ignore TS2339 */}
              {!!this.item.remarks && (
                <div className="meta-details-item">
                  <ul key={'details_item_remarks'}>
                    {/*
                     // @ts-ignore TS2339 */}
                    <li className="remarks">{this.item.remarks}</li>
                  </ul>
                </div>
              )}
              {/*
               // @ts-ignore TS2339 */}
              {!!this.item.copyrights && this.item.copyrights.length > 0 && (
                <div className="meta-details-item">
                  <ul key={'details_item_copyright'}>
                    {/*
                     // @ts-ignore TS2339 */}
                    {_.map(this.item.copyrights, copyright => {
                      return <li className="copyright">{copyright}</li>;
                    })}
                  </ul>
                </div>
              )}
            </React.Fragment>
          ) : null}
        </div>
      </div>
    );
  }
}
