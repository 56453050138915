import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

import activeProfile, { canPlayBroadcast } from '../../../../utils/activeProfile';
import activeTags from '../../utils/activeTags';
import { getMetaDeliveryStatus, META_DELIVERY_STATUS } from '../../../common/utils/metaUtil';
import datetime from 'src/libs/datetime';
import { Models } from 'src/types/app/models';
import AppContext from 'src/types/context/App';
import { RouteHandler } from 'src/types/app/Route';

export function getMetaData(item, hiddenGenre, tagList, context) {
  const isMetaEmpty = value => {
    if (value === 0) {
      return true;
    }
    if (typeof value === 'number') {
      return false;
    }
    if (_.isEmpty(value)) {
      return true;
    }
    return false;
  };
  // @ts-ignore TS2554
  const profile = activeProfile(context.models) || {};
  const tvodBadge = _.get(item, 'tvodBadge');
  const playbackRestrictionType = _.get(item, 'playbackRestrictionType');
  const languageCodes = _.get(item, 'languageCodes') || [];
  const isSubtitles = !!_.find(languageCodes, code => code == 1);
  const isDubbed = !!_.find(languageCodes, code => code == 2);
  let langTag;
  if (isSubtitles && isDubbed) {
    langTag = '字幕/吹替';
  } else if (isSubtitles) {
    langTag = '字幕';
  } else if (isDubbed) {
    langTag = '吹替';
  }
  if (isMetaEmpty(tagList) && isMetaEmpty(playbackRestrictionType) && isMetaEmpty(langTag) && isMetaEmpty(tvodBadge)) {
    return null;
  }
  if (hiddenGenre) {
    return {
      profile,
      playbackRestrictionType,
    };
  } else {
    return {
      profile,
      playbackRestrictionType,
      langTag,
      tvodBadge,
    };
  }
}

export const BaseMetaTags = props => {
  return (
    <React.Fragment>
      {props.playbackRestrictionType == '100' && !canPlayBroadcast(props.profile) && (
        <span className="tag grey-tag" key="type">
          放送視聴登録者 限定
        </span>
      )}
      {!!props.langTag && (
        <span className="tag" key="language">
          {props.langTag}
        </span>
      )}
    </React.Fragment>
  );
};

const EndDayBadgeNode = props => {
  return (
    <span className="tag grey-tag" key="end-tag">
      <span>{datetime(props.datetime).formatMD()}</span>まで
    </span>
  );
};
export const getEndDayBadge = (itemData, cardInfo, EndDayBadgeNode, days = 90) => {
  const status = getMetaDeliveryStatus(itemData, { days });
  if (
    (itemData.type == 'media' || itemData.schemaId == 3) &&
    status === META_DELIVERY_STATUS.END_DELIVERY_WITHIN_X_DAYS
  ) {
    return <EndDayBadgeNode datetime={cardInfo.deliveryEndAt} />;
  }
  return null;
};

type MetaTagsProps = {
  isOnArt?: boolean;
  metadata: any;
  enddayBadge?: boolean;
  hiddenGenre?: boolean;
};

type MetaTagsContext = {
  models: Models;
  getModelData: AppContext.GetModelData;
  routeHandler: RouteHandler;
};

const MetaTags = (props: MetaTagsProps, context: MetaTagsContext) => {
  const isOnArt = props.isOnArt != null ? props.isOnArt : false;
  let tags = activeTags(props.metadata, context, { isOnArt: isOnArt, tvodTagText: true });
  const metaData = getMetaData(props.metadata, props.hiddenGenre, tags, context);
  if (!metaData) return null;

  let EndDayBadge = null;

  // 配信終了日バッジ
  // 配信終了日時から90日以内で終了日時表示
  const itemData = props.metadata;
  const cardInfo = itemData.cardInfo || {};
  if (props.enddayBadge) {
    EndDayBadge = getEndDayBadge(itemData, cardInfo, EndDayBadgeNode, 90);
    tags = tags.filter(tag => tag !== 'もうすぐ終了');
  }
  // PPV: 1（ライブのみ/ライブエピソードが混在するシリーズ）
  // レンタル：2（エピソードのみ）
  // 購入商品：3（エピソード・ライブ）
  let tvodTag;
  if (itemData.tvodBadge == 1) {
    tvodTag = 'PPV';
  } else if (itemData.tvodBadge == 2) {
    tvodTag = 'レンタル';
  } else if (itemData.tvodBadge == 3) {
    tvodTag = '購入商品';
  }

  return (
    <div className="tag-list">
      {itemData.tvodBadge && (
        <span className="tag tvod" key="ppv-tag">
          {tvodTag}
        </span>
      )}
      {tags.map((tag, index) => (
        <span className="tag grey-tag" key={`active-tag-${index}`}>
          {tag}
        </span>
      ))}
      {EndDayBadge}
      <BaseMetaTags {...metaData} />
    </div>
  );
};

MetaTags.contextTypes = {
  models: PropTypes.object,
  getModelData: PropTypes.func,
  routeHandler: PropTypes.object,
};

export default React.memo<MetaTagsProps>(MetaTags);
