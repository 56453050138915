import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

import * as browserEvents from 'src/sketch-platform/utils/browserEvents';
import activeProfile from 'src/utils/activeProfile';
import usePrevious from 'src/apps/common/hooks/usePrevious';
import useOmitDescription from 'src/apps/bluerose/components/watch/hooks/useOmitDescription';
import useShowPlanInfo from 'src/apps/bluerose/components/watch/hooks/useShowPlanInfo';

const MainvisualSideDescriptionContent = (props, context) => {
  const descriptionBoxRef = useRef<HTMLParagraphElement>(null);
  const [descriptionBoxWidth, setDescriptionBoxWidth] = useState(0);
  const [fontSize, setFontSize] = useState(0);
  const description = useMemo(
    () => (props.event ? props.event.shortMessage : props.metadata ? props.metadata.description : ''),
    [props.event, props.metadata],
  );
  const omitDescription = useOmitDescription({ description, descriptionBoxWidth, fontSize });
  const profile = activeProfile(context.model);
  const showPlanInfo = useShowPlanInfo({
    meta: props.meta,
    event: props.event,
    products: props.products,
    courses: props.courses,
    error: props.error,
    profile,
  });

  const prevShowPlanInfo = usePrevious(showPlanInfo);

  const onResizeRef = useRef(
    _.throttle(() => {
      let width = 0;
      let size = 0;
      if (descriptionBoxRef.current) {
        width = descriptionBoxRef.current.offsetWidth;
        size = parseInt(getComputedStyle(descriptionBoxRef.current).fontSize, 10);
      }
      setDescriptionBoxWidth(width);
      setFontSize(size);
    }, 300),
  );

  useEffect(() => {
    if (showPlanInfo && showPlanInfo !== prevShowPlanInfo) {
      onResizeRef.current();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPlanInfo]);

  useEffect(() => {
    const browserInfo = context.getModelData('browserInfo');
    const onResize = onResizeRef.current;
    onResize();
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', onResize);
    } else {
      browserEvents.addEventListener('resize', onResize);
    }
    return () => {
      if (browserInfo.isIOS || browserInfo.isAndroid) {
        browserEvents.removeEventListener('orientationchange', onResize);
      } else {
        browserEvents.removeEventListener('resize', onResize);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showPlanInfo ? (
    <div className="descriptionBox">
      <p className="descriptionBox__txt" ref={descriptionBoxRef}>
        {omitDescription}
        {omitDescription ? (
          <span className="descriptionBox__btn accent-color" onClick={props.onClickMoreInfo}>
            もっと見る
          </span>
        ) : null}
      </p>
    </div>
  ) : null;
};

MainvisualSideDescriptionContent.contextTypes = {
  getModelData: PropTypes.func,
  model: PropTypes.object,
};

export default React.memo(MainvisualSideDescriptionContent);
