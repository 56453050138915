import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import PresTrackedElement from '../../../common/components/PresTrackedElement';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import BackgroundImage from '../../../common/components/BackgroundImage';
import { getIsDelivery } from '../../../common/utils/metaUtil';

export default class NextWatchCard extends Component {
  static getPaths = function(models, options, props = {}) {
    let path = [];
    // @ts-ignore TS2339
    if (props.id) {
      // @ts-ignore TS2339
      path = path.concat(['meta', props.id]);
    }
    return [
      path.concat([
        [
          'id',
          'refId',
          'type',
          'schemaId',
          'name',
          'shortName',
          'thumbnailUrl',
          'seriesMeta',
          'seasonMeta',
          'genres',
          'middleGenres',
          'attributes',
          'episodeNumber',
          'bookmarkPosition',
          'cardInfo',
        ],
      ]),
    ];
  };

  static get propTypes() {
    return {
      showEpisodeDuration: PropTypes.bool,
      showEpisodeNumber: PropTypes.bool,
      showEpisodeSummary: PropTypes.bool,
      showEpisodeProgress: PropTypes.bool,
      enableTitleEllipsis: PropTypes.bool,
      rankNum: PropTypes.number,
      itemTabbable: PropTypes.bool,
      enableCardClick: PropTypes.bool,
      model: PropTypes.object,
      watchType: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      onCardClick: PropTypes.func,
    };
  }

  static get defaultProps() {
    return {
      showEpisodeDuration: true,
      showEpisodeNumber: true,
      showEpisodeSummary: true,
      showEpisodeProgress: true,
      enableTitleEllipsis: true,
      watchType: 'first',
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      history: PropTypes.object,
      models: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.state = {};
    // @ts-ignore TS2339
    this.countdownRef = React.createRef();
    this.handleClickPlayButton = this.handleClickPlayButton.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleMouseEnterClose = this.handleMouseEnterClose.bind(this);
    this.handleMouseLeaveClose = this.handleMouseLeaveClose.bind(this);

    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    if (!this.props.itemData) this.fetchData(this.props);

    this.countdownTimer();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // @ts-ignore TS2339
    if (this.props.id != nextProps.id) {
      this.fetchData(nextProps);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  handleClickPlayButton(e) {
    e.preventDefault();
    e.stopPropagation();
    const { routeHandler, history } = this.context;
    const query = {};
    // @ts-ignore TS2339
    query.sc = 1;
    // @ts-ignore TS2339
    query.ec = this.props.eirinConfirmed;

    // @ts-ignore TS2339
    if (this.item.refId) {
      // @ts-ignore TS2554
      const to = routes.content.makePath({ id: this.item.refId }, query);
      if (routeHandler.path.indexOf('/content') === 0) {
        history.replace(to);
      } else {
        history.push(to);
      }
    } else {
      // @ts-ignore TS2554
      const to = routes.watchNow.makePath({ id: this.props.id }, query);
      if (routeHandler.path.indexOf('/watch') === 0) {
        history.replace(to);
      } else {
        history.push(to);
      }
    }
    // @ts-ignore TS2339
    if (this.props.onCardClick) this.props.onCardClick(this.item);
  }
  handleKeyDown(e) {
    if (e.which === 13 || e.keyCode === 13) {
      this.handleClickPlayButton(e);
    }
  }

  handleClickClose(e) {
    e.preventDefault();
    e.stopPropagation();
    // @ts-ignore TS2339
    if (this.props.onClose) {
      // @ts-ignore TS2339
      this.props.onClose();
    }
  }

  handleMouseEnterClose(e) {
    this.setState({ hoverClose: true });
  }

  handleMouseLeaveClose(e) {
    this.setState({ hoverClose: false });
  }

  countdownTimer() {
    const update = () => {
      // const el = this.countdownRef.current;
      // if (el && this.props.player) {
      //   const count = parseInt(this.props.player.duration() - this.props.player.currentTime(), 10);
      //   el.innerText = _.max([count, 0]);
      // }
      // @ts-ignore TS2339
      if (this.props.player) {
        // @ts-ignore TS2345
        const count = parseInt(this.props.player.duration() - this.props.player.currentTime(), 10);
        const time = _.max([count, 0]);
        // @ts-ignore TS2339
        if (time != this.state.time) {
          this.setState({ time });
        }
      }
    };
    update();
    // @ts-ignore TS2339
    this.countdownIntervalId = setInterval(() => {
      update();
    }, 1000);
  }

  render() {
    // @ts-ignore TS2339
    if (!this.item) return null;
    // @ts-ignore TS2339
    if (!this.item.cardInfo) this.item.cardInfo = {};

    let countdown =
      // @ts-ignore TS2339
      this.state.time != null ? (
        <React.Fragment>
          <span className="starts">{_.get(this.props, 'nextEpisodeText') || '次のエピソード'}</span>
          <span className="until">の再生まで</span>
          {/*
           // @ts-ignore TS2339 */}
          <span ref={this.countdownRef} className="countdown">
            {/*
             // @ts-ignore TS2339 */}
            {this.state.time}
          </span>
          <span className="sec">秒</span>
        </React.Fragment>
      ) : null;

    const browserInfo = this.context.getModelData('browserInfo');
    const userInfo = this.context.getModelData('userInfo');

    // @ts-ignore TS2339
    const playableTitle = this.item.shortName || this.item.name;

    let content = (
      // @ts-ignore TS2322
      <PresTrackedElement idDoubleWide={this.props.isDoubleWide}>
        <div className="artwork-container">
          <BackgroundImage
            // @ts-ignore TS2322
            onClick={!(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleClickPlayButton : null}
            className="artwork"
            // @ts-ignore TS2339
            url={this.item.thumbnailUrl}
            useLazyload={false}
          >
            {browserInfo.isIOS ||
            browserInfo.isAndroid ||
            // 配信期間中のもののみ再生アイコンを表示する
            // @ts-ignore TS2339
            !getIsDelivery(this.item.cardInfo.deliveryStartAt, this.item.cardInfo.deliveryEndAt) ? null : (
              <div className="play-link">
                <div className="play-ring">
                  <div className="play icon-play"></div>
                </div>
              </div>
            )}
          </BackgroundImage>
        </div>
        <div className="text-box">
          {/*
           // @ts-ignore TS2339 */}
          {this.props.title !== playableTitle ? <div className="series-name ellipsized">{this.props.title}</div> : null}
          <div
            className="title ellipsized"
            onClick={!(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleClickPlayButton : null}
          >
            {playableTitle}
          </div>
          {/*
           // @ts-ignore TS2339 */}
          {this.item.cardInfo && this.item.cardInfo.episodeNumberTitle ? (
            <div className="top-metadata">
              {/*
               // @ts-ignore TS2339 */}
              <span className="episode-number ellipsized">{this.item.cardInfo.episodeNumberTitle}</span>
            </div>
          ) : null}
        </div>
      </PresTrackedElement>
    );

    // @ts-ignore TS2339
    const WapperLayout = this.props.wapperLayout
      ? // @ts-ignore TS2339
        this.props.wapperLayout
      : props => {
          return <React.Fragment>{props.children}</React.Fragment>;
        };

    return (
      <WapperLayout>
        {content}
        <div className="next-episode-card__btn">
          <div
            className="btn btn-border"
            onClick={this.handleClickClose}
            onMouseEnter={this.handleMouseEnterClose}
            onMouseLeave={this.handleMouseLeaveClose}
          >
            視聴を続ける
          </div>
          <div
            className={classnames('btn btn-border upNextin', {
              // @ts-ignore TS2339
              clickable: this.props.enableCardClick,
              // @ts-ignore TS2339
              nohover: this.state.hoverClose,
            })}
            // @ts-ignore TS2339
            onClick={this.props.enableCardClick ? this.handleClickPlayButton : undefined}
            // @ts-ignore TS2339
            onKeyDown={this.props.enableCardClick ? this.handleKeyDown.bind(this) : undefined}
            tabIndex={0}
          >
            {countdown}
          </div>
        </div>
      </WapperLayout>
    );
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, null, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.get(res.json, ['meta', props.id]);
        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
        };
        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState(newState);
        } else {
          Object.assign(this.state, newState);
        }
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = newState;
      });
  }
}
