import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import BackgroundImage from '../../../common/components/BackgroundImage';
import routes from '../../../common/routes';
import MainViewLink from '../../../common/components/MainViewLink';
// @ts-ignore TS1192
import reactNl2br from '../../../../common/reactNl2br';
import datetime from 'src/libs/datetime';

const FORMAT = 'YYYY/MM/DD HH:mm:ss';
const STATUS_LABELS = {
  1: '中止',
  2: '中断',
  3: 'ライブ前',
  4: 'ライブ中',
  5: '終了',
  6: '未定',
};

const SoccerCard = (props, context) => {
  const itemData = props.itemData;
  const browserInfo = context.getModelData('browserInfo');
  const getOnClick = (meta = {}) => {
    return e => {
      e.preventDefault();
      e.stopPropagation();
      // @ts-ignore TS2339
      if (!meta || !meta.ref_id) return;
      if (props.onClick) props.onClick(meta);
      // @ts-ignore TS2554
      context.history.push(routes.content.makePath({ id: meta.ref_id }));
    };
  };

  //   ◎ 設定したい情報​
  // ・チーム名（属性：チームと紐づけたい）​
  // ・アイコン（属性：チームと紐づけたい）​
  // 　→属性に画像を持たせる？（テニスにも活きる？）​
  // ・試合日程​
  // ・試合の段階/Group​
  // ・試合結果​
  // ⇒ それぞれ、手動での運用を想定​
  // ※ 試合結果の表示・非表示はフラグで設定できるようにしたい
  const row = _.map(itemData.items, (item, idx) => {
    const startAt = item.startAt ? datetime(item.startAt, FORMAT, 'ja') : null;
    const endAt = item.endAt ? datetime(item.endAt, FORMAT, 'ja') : null;
    const now = datetime();

    const card = item.card || [];
    if (_.isEmpty(card) || card.length < 2) return null;
    const team1 = card[0] || {};
    const team2 = card[1] || {};

    let status = 6;
    if (item.calledOffFlag) {
      status = 1;
    } else if (item.suspendedFlag) {
      status = 2;
    } else if (
      // ライブの紐づきなしキャッチアップありは無条件で終了
      _.isEmpty(item.langJapaneseLiveMeta) &&
      _.isEmpty(item.langLocalLiveMeta) &&
      (!_.isEmpty(item.langJapaneseCatchupMeta) || !_.isEmpty(item.langLocalCatchupMeta))
    ) {
      status = 5;
    } else if (startAt && startAt.isAfter(now)) {
      status = 3;
    } else if ((endAt && endAt.isAfter(now)) || (startAt && !endAt && startAt.isBefore(now))) {
      status = 4;
    } else if (endAt && endAt.isBefore(now)) {
      status = 5;
    }

    let timeText = null;
    if (status === 3) {
      const d = startAt.format('M/D(dd)');
      const h = startAt.format('H');
      const m = startAt.format('mm');
      timeText = `${d}\n${h}:${m}`;
    } else if (status === 5) {
      if (
        !_.isEmpty(item.langJapaneseLiveMeta) ||
        !_.isEmpty(item.langLocalLiveMeta) ||
        !_.isEmpty(item.langJapaneseCatchupMeta) ||
        !_.isEmpty(item.langLocalCatchupMeta)
      ) {
        // 遷移先メタが何かしらある場合は「見逃し視聴」
        timeText = '見逃し視聴';
      } else {
        // 遷移先がない場合は「終了」
        timeText = STATUS_LABELS[status];
      }
    } else {
      timeText = STATUS_LABELS[status];
    }

    const active = status === 4;
    const timeEl = timeText ? <div className="time">{reactNl2br(timeText)}</div> : null;
    const scoreEl =
      item.displayScoreFlag && team1.score != null && team2.score != null ? (
        <div className="result">{`${team1.score} - ${team2.score}`}</div>
      ) : null;
    const contentInner = (
      <React.Fragment>
        <div className="soccer-card__inner__match__order-team home">
          {team1.name}
          <div className="emblem">
            {/*
             // @ts-ignore TS2322 */}
            <BackgroundImage url={team1.image} />
          </div>
        </div>
        <div className="soccer-card__inner__match__order-game">
          {timeEl}
          {scoreEl}
        </div>
        <div className="soccer-card__inner__match__order-team away">
          <div className="emblem">
            {/*
             // @ts-ignore TS2322 */}
            <BackgroundImage url={team2.image} />
          </div>
          {team2.name}
        </div>
      </React.Fragment>
    );
    let links = [];
    const beforeLiveEnd = [3, 4, 6].includes(status);
    const isBeforeBroadcastStart = [3, 6].includes(status);
    // メタあり、かつライブ中or前もしくはキャッチアップメタができていない場合
    if (
      _.get(item, 'langJapaneseLiveMeta.ref_id') &&
      (beforeLiveEnd || !_.get(item, 'langJapaneseCatchupMeta.ref_id'))
    ) {
      links.push(
        <MainViewLink
          // @ts-ignore TS2322
          to={routes.content}
          params={{ id: item.langJapaneseLiveMeta.ref_id }}
          onClick={getOnClick(item.langJapaneseLiveMeta)}
          className={classnames('soccer-card__inner__match__type__item', { before_broadcast: isBeforeBroadcastStart })}
        >
          日本語
        </MainViewLink>,
      );
    } else if (!beforeLiveEnd && _.get(item, 'langJapaneseCatchupMeta.ref_id')) {
      links.push(
        <MainViewLink
          // @ts-ignore TS2322
          to={routes.content}
          params={{ id: item.langJapaneseCatchupMeta.ref_id }}
          onClick={getOnClick(item.langJapaneseCatchupMeta)}
          className="soccer-card__inner__match__type__item"
        >
          日本語
        </MainViewLink>,
      );
    }

    if (_.get(item, 'langLocalLiveMeta.ref_id') && (beforeLiveEnd || !_.get(item, 'langLocalCatchupMeta.ref_id'))) {
      links.push(
        <MainViewLink
          // @ts-ignore TS2322
          to={routes.content}
          params={{ id: item.langLocalLiveMeta.ref_id }}
          onClick={getOnClick(item.langLocalLiveMeta)}
          className={classnames('soccer-card__inner__match__type__item', { before_broadcast: isBeforeBroadcastStart })}
        >
          外国語
        </MainViewLink>,
      );
    } else if (!beforeLiveEnd && _.get(item, 'langLocalCatchupMeta.ref_id')) {
      links.push(
        <MainViewLink
          // @ts-ignore TS2322
          to={routes.content}
          params={{ id: item.langLocalCatchupMeta.ref_id }}
          onClick={getOnClick(item.langLocalCatchupMeta)}
          className="soccer-card__inner__match__type__item"
        >
          外国語
        </MainViewLink>,
      );
    }

    return (
      <div className={classnames('soccer-card__inner__match', { active })}>
        <div className="soccer-card__inner__match__order">{contentInner}</div>
        <div className="soccer-card__inner__match__type">{links.map(link => link)}</div>
      </div>
    );
  }).filter(item => !!item);

  if (!row.length) return null;

  const style = {};
  if (props.height) {
    // @ts-ignore TS2339
    style.height = props.height;
  }
  return (
    <div className="card title-card soccer-card" style={style} ref={props.rootRef}>
      <div className="soccer-card__title">
        <div className="soccer-card__title-row">
          {itemData.title}
          {itemData.legText ? <div className="soccer-card__title-leg">{itemData.legText}</div> : null}
        </div>
        {itemData.description ? <div className="soccer-card__title-description">{itemData.description}</div> : null}
      </div>
      <div className="soccer-card__inner">{row}</div>
    </div>
  );
};
SoccerCard.contextTypes = {
  history: PropTypes.object,
  getModelData: PropTypes.func,
};
export default SoccerCard;
