import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

//
// TVODチケットの使用可否、使用有無を取り扱うComponent
//
export default class UserVoucherConfirm extends React.PureComponent {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);

    this._handleChange = this._handleChange.bind(this);
    // @ts-ignore TS2339
    this.checkTicketUseRef = React.createRef();

    const appliedData = _.get(props, 'userVoucherInfo.appliedData');
    // @ts-ignore TS2339
    if (this.props.applyVoucherData && appliedData) {
      // @ts-ignore TS2339
      this.props.applyVoucherData(appliedData);
    }
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  _handleChange(e) {
    // @ts-ignore TS2339
    if (!this.props.applyVoucherData) {
      return;
    }

    let appliedData = null;
    if (_.get(this, 'checkTicketUseRef.current.checked')) {
      appliedData = _.get(this.props, 'userVoucherInfo.appliedData');
    }
    // @ts-ignore TS2339
    this.props.applyVoucherData(appliedData);
  }

  render() {
    const appliedData = _.get(this.props, 'userVoucherInfo.appliedData');
    const voucherName = _.get(this.props, 'userVoucherInfo.appliedData.userVoucher.voucher.displayName') || 'クーポン';
    const totalCount = _.get(this.props, 'userVoucherInfo.totalCount');

    if (_.isEmpty(appliedData)) {
      return null;
    }

    return (
      <div className="recipe-table-row">
        <div className="title">クーポン</div>
        <div className="item">
          <div className="radio-box">
            <input
              id="use_ticket_1"
              type="radio"
              value="1"
              name="use_ticket"
              className="radio-button"
              defaultChecked={true}
              // @ts-ignore TS2339
              ref={this.checkTicketUseRef}
              onChange={this._handleChange}
            />
            <label htmlFor="use_ticket_1" className="radio-button-label">
              {voucherName}を利用する
            </label>
            {/*
             // @ts-ignore TS18048 */}
            {totalCount > 0 ? <div className="ticket-text">現在所持しているチケット数：{totalCount}枚</div> : null}
          </div>
          <div className="radio-box">
            <input
              id="use_ticket_0"
              type="radio"
              value="0"
              name="use_ticket"
              className="radio-button"
              onChange={this._handleChange}
            />
            <label htmlFor="use_ticket_0" className="radio-button-label">
              利用しない
            </label>
          </div>
        </div>
      </div>
    );
  }
}
