import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

import SubscribeFinish from './Finish/SubscribeFinish';
import PurchaseFinish from './Finish/PurchaseFinish';

const Finish = props => {
  let content;

  switch (props.itemType) {
    case 'course':
      content = <SubscribeFinish {...props} course={props.item || props.course} />;
      break;
    case 'product':
      content = <PurchaseFinish {...props} product={props.item || props.product} />;
      break;
    default:
      throw new TypeError('Confirm.props.itemType must be "product" or "course".');
  }
  return content;
};

export default Finish;
