import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import presentationHOC from '../../../common/hocs/presentationHOC';

import Billboard from '../billboard/Billboard';
import Palette from './Palette';
import HtmlSnippetRow from './HtmlSnippetRow';

import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
import * as DOMUtils from '../../../../sketch-platform/utils/DOMUtils';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import LoadingRow from '../loader/LoadingRow';
import BannerRow from './BannerRow';
import ExclusiveUiRow from './ExclusiveUiRow';
import datetime from 'src/libs/datetime';
import PaletteImpression from './PaletteImpression';

const PERPAGE = 10;

class Canvas extends Component {
  static getPaths = function(models, options, props) {
    const rootPath = this.getRootPath(models, options, props);
    const indexes = {
      from: (props && props.fromNum) || 0,
      to:
        props && props.toNum
          ? props.toNum - (props.fromNum || 0)
          : options && options.numVideos
          ? options.numVideos
          : PERPAGE - 1,
    };

    const rowPaths = HtmlSnippetRow.getPaths(models, options, props)
      .concat(Palette.getPaths(models, options, props))
      .concat(Billboard.getPaths(models, options, props))
      .concat(ExclusiveUiRow.getPaths(models, options, props));
    const canvasRowPaths = rowPaths.map(path => {
      return rootPath.concat([indexes]).concat(path);
    });

    return canvasRowPaths.concat([
      rootPath.concat([['length', 'emptyMessage', 'titleType', 'name', 'shortName', 'keyartUrl']]),
      rootPath.concat([indexes, ['id_key', 'type', 'id', 'listContext', 'genre', 'schemaId', 'useObjects']]),
      rootPath.concat(['additional', indexes]),
    ]);
  };

  static getRootPath = function(models, options, props) {
    if (props.canvasId) {
      return ['canvas', props.canvasId];
    }
    return [];
  };

  static get propTypes() {
    return {
      canvasId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      numRows: PropTypes.number,
      enablePeek: PropTypes.bool,
      model: PropTypes.object.isRequired,
      header: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    };
  }

  static get contextTypes() {
    return {
      routeHandler: PropTypes.object,
      models: PropTypes.object,
      getModelData: PropTypes.func,
      gtmApp: PropTypes.object,
    };
  }

  private visiblePaletteIds: boolean[];

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.canvasRef = React.createRef();

    this.onScroll = this.onScroll.bind(this);
    this.onScrollEnd = this.onScrollEnd.bind(this);
    this._noContents = this._noContents.bind(this);
    this.onVisiblePalette = this.onVisiblePalette.bind(this);
    // @ts-ignore TS2339
    this.numRowsToFetch = PERPAGE;
    // @ts-ignore TS2339
    this.noContentsCountIds = [];
    // @ts-ignore TS2339
    this.pathEvaluators = [];

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    this.canvas = props.model.getSync(rootPath);
    // @ts-ignore TS2339
    this.additional = props.model.getSync(rootPath.concat('additional'));
    // @ts-ignore TS2339
    if (this.canvas) {
      // @ts-ignore TS2339
      this.canvasData = {
        // @ts-ignore TS2339
        titleType: this.canvas.titleType,
        // @ts-ignore TS2339
        name: this.canvas.name,
        // @ts-ignore TS2339
        shortName: this.canvas.shortName,
      };
      // @ts-ignore TS2339
      this.keyartUrl = this.canvas.keyartUrl;
      // @ts-ignore TS2339
      this.canvas = _.compact(
        // @ts-ignore TS2339
        _.dropRightWhile(_.values(_.omit(this.canvas, ['$__path'])), function(v) {
          return _.isNumber(v);
        }),
      );
      // paletteのobjectsを表示するものでobjectsがないものは予めセットしておく
      // @ts-ignore TS2339
      _.forEach(this.canvas, structure => {
        if (structure.useObjects && _.isEmpty(structure.objects)) {
          this._noContents(structure.id || structure.id_key);
        }
      });
      // @ts-ignore TS2339
      this.numRowsToFetch = this.canvas.length;
    }
    this.visiblePaletteIds = [];
    this.state = {
      bouncing: false,
      dispose: null,
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
      maxRowToLoadImages: 6,
      maxRowToRender: 3,
      scrollHandlersDisabled: true,
      showEmptyMessage: false,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);

    browserEvents.addEventListener('scroll', this.onScroll);
    browserEvents.addEventListener('scrollEnd', this.onScrollEnd);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // @ts-ignore TS2339
    if (nextProps.canvasId != this.props.canvasId) {
      // @ts-ignore TS2339
      delete this.canvas;
      // @ts-ignore TS2339
      this.numRowsToFetch = PERPAGE;
      // @ts-ignore TS2339
      this.noContentsCountIds = [];
      // @ts-ignore TS2554
      this.fetchData(nextProps, nextContext);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();

    browserEvents.removeEventListener('scroll', this.onScroll);
    browserEvents.removeEventListener('scrollEnd', this.onScrollEnd);
  }

  fetchMoreRows() {
    // @ts-ignore TS2339
    if (!this.canvas) return;
    // @ts-ignore TS2339
    if (this.numRowsToFetch > this.canvas.length && !this.state.fetchingMoreRows) {
      // @ts-ignore TS2339
      this.state.fetchingMoreRows = true;
      this.fetchData(this.props);
    }
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const pathProps = Object.assign({}, props, { toNum: this.numRowsToFetch - 1 });
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.canvas = _.get(res, ['json'].concat(rootPath));
        // @ts-ignore TS2339
        this.additional = _.get(this.canvas, 'additional');
        // @ts-ignore TS2339
        this.totalRowCount = props.model.getSync(`canvas.${props.canvasId}.length`, 0);
        // @ts-ignore TS2339
        this.emptyMessage = props.model.getSync(`canvas.${props.canvasId}.emptyMessage`, '');
        // @ts-ignore TS2339
        this.keyartUrl = props.model.getSync(`canvas.${props.canvasId}.keyartUrl`, '');
        // @ts-ignore TS2339
        this.canvasData = {
          titleType: props.model.getSync(`canvas.${props.canvasId}.titleType`, ''),
          name: props.model.getSync(`canvas.${props.canvasId}.name`, ''),
          shortName: props.model.getSync(`canvas.${props.canvasId}.shortName`, ''),
        };
        // @ts-ignore TS2339
        this.canvas = _.compact(
          _.dropRightWhile(
            _.values(
              // @ts-ignore TS2339
              _.omit(this.canvas, [
                '$__path',
                'emptyMessage',
                'additional',
                'titleType',
                'name',
                'shortName',
                'keyartUrl',
              ]),
            ),
            function(v) {
              return _.isNumber(v);
            },
          ),
        );
        // @ts-ignore TS2339
        this.canvas = _.filter(
          // @ts-ignore TS2339
          _.map(this.canvas, structure => _.omit(structure, '$__path')),
          structure => !_.isEmpty(structure),
        );
        // paletteのobjectsを表示するものでobjectsがないものは予めセットしておく
        // @ts-ignore TS2339
        _.forEach(this.canvas, structure => {
          if (structure.useObjects && _.isEmpty(structure.objects)) {
            this._noContents(structure.id || structure.id_key);
          }
        });
        // @ts-ignore TS2339
        this.numRowsToFetch += PERPAGE;
        // @ts-ignore TS2339
        if (this.numRowsToFetch > this.totalRowCount) this.numRowsToFetch = this.totalRowCount;
        delete this.state[JSON.stringify(paths)];
        const newState = {
          showEmptyMessage: false,
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (_.isEmpty(this.canvas)) {
          // @ts-ignore TS2339
          if (this.props.noContents) this.props.noContents();
          newState.showEmptyMessage = true;
        }

        // @ts-ignore TS2339
        delete this.state.fetchingMoreRows;
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  _noContents(id) {
    // @ts-ignore TS2339
    if (!_.includes(this.noContentsCountIds, id)) this.noContentsCountIds.push(id);
    // @ts-ignore TS2339
    if (this.noContentsCountIds.length == this.canvas.length) {
      // @ts-ignore TS2339
      if (this.props.noContents) this.props.noContents();
      this.setState({ showEmptyMessage: true });
    }
  }

  onVisiblePalette(paletteId, paletteName, orderNum) {
    if (!this.visiblePaletteIds[paletteId]) {
      // 1度計測したものはtrueにする
      this.visiblePaletteIds[paletteId] = true;
      // インプレッション数計測
      this.context.gtmApp.pushDataLayerOnPaletteImp(paletteId, paletteName, orderNum);
    }
  }

  render() {
    let spinLoader,
      loadingRow = null;
    // @ts-ignore TS2339
    if (this.state.fetchingMoreRows === true) {
      spinLoader = (
        <div className="gallery-spin-loader">
          <div className="loader-cont"></div>
        </div>
      );
    }
    // @ts-ignore TS2339
    if (this.props.tabHeader && !this.canvas) {
      loadingRow = <LoadingRow />;
    }
    // @ts-ignore TS2339
    if (!this.canvas && !spinLoader && !loadingRow) {
      return null;
    }

    // @ts-ignore TS2339
    const rows = _.map(this.canvas, (structure, i) => {
      if (!structure) return null;

      if (structure.type === 'palette') {
        // @ts-ignore TS2367
        const isLast = i === this.totalRowCount - 1;

        // @ts-ignore TS2339
        let model = this.props.model;
        // falcorのキャッシュが揮発すると自動でデータを取得しに行くが
        // その際にパスが長くなるケースが発生する場合がある為
        // ページ毎にpathEvaluatorを分けて分散させる
        // @ts-ignore TS2339
        if (this.props.pathEvaluator) {
          // @ts-ignore TS2362
          const idx = Math.floor(i / PERPAGE);
          // @ts-ignore TS2339
          if (!this.pathEvaluators[idx]) this.pathEvaluators[idx] = this.props.pathEvaluator.batch(100);
          // @ts-ignore TS2339
          model = this.pathEvaluators[idx];
        }
        return (
          <Palette
            paletteId={structure.id || structure.id_key}
            model={model}
            key={`canvas_row_${i}`}
            listContext={structure.listContext}
            noContents={this._noContents}
            // @ts-ignore TS2339
            onVisiblePalette={this.onVisiblePalette}
            // @ts-ignore TS2339
            useImpression={true}
            // @ts-ignore TS2339
            channelNameHidden={this.props.channelNameHidden}
            isLast={isLast}
            // @ts-ignore TS2339
            additional={_.get(this.additional, i)}
            rowNum={i}
            // @ts-ignore TS2322
            orderNum={i + 1}
            // @ts-ignore TS2339
            canvasId={this.props.canvasId}
          />
        );
      } else if (structure.type === 'html_snippet') {
        return (
          <HtmlSnippetRow
            snippetId={structure.id || structure.id_key}
            // @ts-ignore TS2339
            model={this.props.model}
            key={`canvas_row_${i}`}
            noContents={this._noContents}
          />
        );
      } else if (structure.type === 'billboard') {
        return (
          <Billboard
            // @ts-ignore TS2339
            orderNum={i + 1}
            // @ts-ignore TS2339
            onVisiblePalette={this.onVisiblePalette}
            // @ts-ignore TS2339
            useImpression={true}
            paletteId={structure.id || structure.id_key}
            // @ts-ignore TS2322
            model={this.props.model}
            key={`canvas_row_${i}`}
            listContext={'billboard'}
            noContents={this._noContents}
            // @ts-ignore TS2339
            isHome={this.props.isHome}
          />
        );
      } else if (structure.type === 'banner') {
        return (
          <BannerRow
            // @ts-ignore TS2322
            onVisiblePalette={this.onVisiblePalette}
            // @ts-ignore TS2322
            useImpression={true}
            // @ts-ignore TS2322
            paletteId={structure.id || structure.id_key}
            // @ts-ignore TS2339
            pathEvaluator={this.props.pathEvaluator}
            // @ts-ignore TS2339
            model={this.props.model}
            key={`canvas_row_${i}`}
            listContext={structure.listContext}
            rowNum={i}
            orderNum={i + 1}
            noContents={this._noContents}
          />
        );
      } else if (structure.type === 'exclusiveUi') {
        return (
          <ExclusiveUiRow
            // @ts-ignore TS2322
            paletteId={structure.id || structure.id_key}
            // @ts-ignore TS2339
            model={this.props.model}
            key={`canvas_row_${i}`}
            listContext={structure.listContext}
            rowNum={i}
            orderNum={i + 1}
            noContents={this._noContents}
            // @ts-ignore TS2339
            onVisiblePalette={this.onVisiblePalette}
            // @ts-ignore TS2339
            useImpression={true}
          />
        );
      }
      return null;
    });

    // お知らせを差し込む
    // @ts-ignore TS2339
    if (this.props.infoData) {
      const browserInfo = this.context.getModelData('browserInfo');
      let showAll = false;
      // @ts-ignore TS2339
      let infoData = this.props.infoData;
      // @ts-ignore TS2339
      if (this.props.infoShowLength) {
        // @ts-ignore TS2339
        showAll = this.props.infoData.length > this.props.infoShowLength;
        // @ts-ignore TS2339
        infoData = _.take(this.props.infoData, this.props.infoShowLength);
      }
      // 位置を特定
      // @ts-ignore TS2339
      const idx = _.findIndex(this.canvas, structure => {
        // @ts-ignore TS2339
        const id = structure.id || structure.id_key;
        // @ts-ignore TS2339
        return !_.includes(this.noContentsCountIds, id);
      });
      rows.splice(
        idx + 1,
        0,
        <div className="top-info" key="top-info">
          <div className="top-info__title">お知らせ</div>
          <div className="top-info__box">
            {_.map(infoData, info => {
              let postAt;
              if (info.postAt) {
                if (browserInfo.isIOS || browserInfo.isAndroid) {
                  postAt = datetime(info.postAt).format('M/DD(dd)');
                } else {
                  postAt = datetime(info.postAt).format('YYYY/MM/DD(dd)');
                }
              }
              return (
                <div className="top-info__box-item" key={`info-${info.id}`}>
                  <span
                    className={classnames('top-info__box-item__date', {
                      mb: browserInfo.isIOS || browserInfo.isAndroid,
                    })}
                  >
                    {!!postAt && postAt}
                  </span>
                  {/*
                   // @ts-ignore TS2322 */}
                  <MainViewLink to={routes.infoDetail} params={{ id: info.id }} className="top-info__box-item__text">
                    {info.title}
                  </MainViewLink>
                </div>
              );
            })}
          </div>
        </div>,
      );
    }

    //ジャンル
    // @ts-ignore TS2339
    if (this.props.genreTab) {
      // @ts-ignore TS2339
      const genreIdx = _.findIndex(this.canvas, structure => {
        // @ts-ignore TS2339
        const genreId = structure.id || structure.id_key;
        // @ts-ignore TS2339
        return !_.includes(this.noContentsCountIds, genreId);
      });
      let count = 1;
      // @ts-ignore TS2339
      if (this.props.infoData) {
        count = 2;
      }
      rows.splice(
        genreIdx + count,
        0,
        <div className="top-genre" id="topGenre" key={`topGenre`}>
          {/*
           // @ts-ignore TS2339 */}
          {this.props.genreTab}
        </div>,
      );
    }

    let name = '';
    // @ts-ignore TS2339
    const titleType = _.get(this.canvasData, 'titleType');
    if (titleType == 'name') {
      // @ts-ignore TS2339
      name = this.canvasData.name;
    } else if (titleType == 'short_name') {
      // @ts-ignore TS2339
      name = this.canvasData.shortName;
    }

    // 配列の途中にheaderを追加する必要がある
    return (
      // @ts-ignore TS2339
      <div ref={this.canvasRef} className="canvas">
        {/*
         // @ts-ignore TS2339 */}
        {this.keyartUrl ? (
          <div className="mainVisual-keyart">
            {/*
             // @ts-ignore TS2339 */}
            <img src={this.keyartUrl} />
          </div>
        ) : null}
        {name && (
          <div className="canvas-header">
            <div className="title">{name}</div>
          </div>
        )}
        {/*
         // @ts-ignore TS2339 */}
        {this.props.tabHeader}
        {loadingRow}
        {/*
         // @ts-ignore TS2339 */}
        {this.state.showEmptyMessage ? (
          // @ts-ignore TS2322
          <div className="pulsate-transition" timeout={{ exit: 550, enter: 250 }}>
            <div>
              <div className="no_img" />
              <div className="gallery-message">登録されている作品がありません。</div>
            </div>
          </div>
        ) : (
          rows
        )}
        {spinLoader}
      </div>
    );
  }

  onScroll(e) {
    if (this.isWithinDistanceBuffer(300)) {
      // @ts-ignore TS2554
      this.fetchMoreRows(true);
    }
  }

  onScrollEnd(e) {}

  isWithinDistanceBuffer(distanceBuffer) {
    // @ts-ignore TS2339
    if (!this.canvasRef) return;
    // @ts-ignore TS2339
    const el = this.canvasRef.current;
    if (el) {
      return DOMUtils.getDistanceToBottomOfElement(el) < distanceBuffer;
    }
    return false;
  }
}

export default presentationHOC(Canvas);
