import React, { Component } from 'react';
import _ from 'src/libs/util';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import window from 'global/window';

import * as DOMUtils from 'src/sketch-platform/utils/DOMUtils';
import * as browserEvents from 'src/sketch-platform/utils/browserEvents';

const FadeLeft = props => {
  const classNames = classnames('fade-left', {
    scrolling: !props.isLeftEdge && props.canScroll,
  });
  return (
    <div onClick={props.onClick} className={classNames} ref={props.rootRef}>
      <i className="fa fa-angle_left"></i>
    </div>
  );
};

const FadeRight = props => {
  const classNames = classnames('fade-right', {
    scrolling: !props.isRightEdge && props.canScroll,
  });
  return (
    <div onClick={props.onClick} className={classNames} ref={props.rootRef}>
      <i className="fa fa-angle_right"></i>
    </div>
  );
};

const SLIDE_SPEED = 1000;
const SLIDE_SPEED_s = 1;

export default class GenreTab extends Component {
  static get propTypes() {
    return {
      genreLinks: PropTypes.arrayOf(PropTypes.element).isRequired,
      navLinks: PropTypes.arrayOf(PropTypes.element),
      showSecondaryNavigationLinks: PropTypes.bool,
      showGenres: PropTypes.bool.isRequired,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.headerTabNaviRef = React.createRef();
    // @ts-ignore TS2339
    this.fadeRightRef = React.createRef();
    // @ts-ignore TS2339
    this.fadeLeftRef = React.createRef();

    this.handleScroll = this.handleScroll.bind(this);
    this.handleOrientationChange = this.handleOrientationChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.getActiveTabIndex = this.getActiveTabIndex.bind(this);
    this.getTabList = this.getTabList.bind(this);
    this.renderTabLinks = this.renderTabLinks.bind(this);

    this.state = {
      isLeftEdge: true,
      isRightEdge: false,
      canScroll: false,
      animating: false,
      activeTabIndex: null,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.handleOrientationChange);
    } else {
      browserEvents.addEventListener('resize', this.handleResize);
    }

    // @ts-ignore TS2554
    this.scrollToActiveTab();
    // @ts-ignore TS2554
    this.setState({ canScroll: this.canScroll() });
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('orientationchange', this.handleOrientationChange);
    } else {
      browserEvents.removeEventListener('resize', this.handleResize);
    }
    // @ts-ignore TS2339
    if (this.animatingEndCallback) clearTimeout(this.animatingEndCallback);
  }

  componentDidUpdate(prevProps, prevState) {
    // ヘッダーでジャンルタブを表示する際に毎度タブが一番左に戻される仕様なので左端判定を true にする
    // @ts-ignore TS2339
    if (this.props.showGenres != prevProps.showGenres) {
      this.setState({ isLeftEdge: true });
      this.setState({ isRightEdge: false });
    }
    // @ts-ignore TS2339
    const { width } = DOMUtils.getRect(this.headerTabNaviRef.current);
    // @ts-ignore TS2339
    this.offsetLeft = width;
    this.getActiveTabIndex();
    // @ts-ignore TS2339
    if (this.state.activeTabIndex != prevState.activeTabIndex) this.scrollToActiveTab();
    this.getTabsWidth();
    // @ts-ignore TS2339
    if (this.state.canScroll !== this.canScroll()) {
      // @ts-ignore TS2554
      this.setState({ canScroll: this.canScroll() });
    }
  }

  handleScroll(e) {
    const targetElem = DOMUtils.getRect(e.target);
    if (targetElem.height <= 0) return;
    // @ts-ignore TS2339
    const maxScrollLeft = (e.target.scrollWidth || 0) - this.offsetLeft;
    this.setState({
      isLeftEdge: 0 > e.target.scrollLeft - 5,
      // ジャンルタブがヘッダーから消える際に maxScrollLeft の e.target.scrollWidth の値が
      // e.target.scrollLeft + 5 の値よりも小さくなり結果右端にスクロールしていなくても右端にいる判定になってしまっていた。
      // ヘッダーにジャンルタブが表示されていない状態で表示位置の判定が変更されるのは想定していないため
      // 右端にいる判定に「ヘッダーにジャンルタブが表示されているかどうか」を追加した。
      // @ts-ignore TS2339
      isRightEdge: this.props.showGenres && maxScrollLeft < e.target.scrollLeft + 5,
    });
  }

  handleOrientationChange(e) {
    // @ts-ignore TS2554
    this.scrollToActiveTab();
    // @ts-ignore TS2554
    this.setState({ canScroll: this.canScroll() });
  }

  handleResize(e) {
    // @ts-ignore TS2554
    this.scrollToActiveTab();
    // @ts-ignore TS2554
    const canScroll = this.canScroll();
    this.setState({ canScroll });
    // @ts-ignore TS2339
    if (!canScroll && this.state.isRightEdge) {
      this.setState({ isRightEdge: false });
    }
  }

  getTabList() {
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2339
    let navLinks = this.props.navLinks;
    // @ts-ignore TS2339
    let genreLinks = this.props.genreLinks;
    let headerTabLinks;

    // @ts-ignore TS2339
    if (navLinks && (!this.props.showSecondaryNavigationLinks || browserInfo.isIOS || browserInfo.isAndroid)) {
      //1024px以下
      //スマホorタブレット時
      headerTabLinks = navLinks.concat(genreLinks);
      // @ts-ignore TS2339
    } else if (this.props.showSecondaryNavigationLinks) {
      headerTabLinks = genreLinks;
    }
    return headerTabLinks;
  }
  getTabsWidth() {
    // @ts-ignore TS2339
    let headerTabNaviChild = this.headerTabNaviRef.current.children;
    let headerGenre = [];
    // @ts-ignore TS2339
    if (this.props.showSecondaryNavigationLinks) {
      _.map(headerTabNaviChild, (NaviChild, j) => {
        if (NaviChild.classList.contains('genremenu-list')) {
          headerGenre.push(NaviChild);
        }
      });
      headerTabNaviChild = null;
      headerTabNaviChild = headerGenre;
    }
    return _.map(headerTabNaviChild, (_, i) => {
      const { width } = DOMUtils.getRect(headerTabNaviChild[i]);
      if (width > 0) {
        return width;
      }
    });
  }

  canScroll(windowWidth) {
    if (!windowWidth) {
      windowWidth = DOMUtils.getWindowRect().width;
    }
    if (document) {
      let scrollbarWidth = windowWidth - document.body.clientWidth;
      windowWidth = windowWidth - scrollbarWidth;

      // @ts-ignore TS2339
      const navRef = getComputedStyle(this.headerTabNaviRef.current);
      const navRefWidth = Number(navRef.width.replace('px', ''));
      const tabsWidth = this.getTabsWidth();
      const tabWidthTotal = this.sumWidth(tabsWidth);

      return navRefWidth < tabWidthTotal;
    }
  }

  sumWidth(widthList) {
    return _.reduce(widthList, (sum, width) => {
      return sum + width;
    });
  }

  getActiveTabIndex() {
    let structureIdKey;
    const browserInfo = this.context.getModelData('browserInfo');
    let tabList = this.getTabList();
    if (tabList) {
      _.map(tabList, (link, i) => {
        const linkChild = link.props;
        if (linkChild.to) {
          if (linkChild.to.makePath(linkChild.params) === this.context.routeHandler.path) {
            structureIdKey = i;
          }
        } else if (linkChild.children) {
          if (linkChild.children.props && linkChild.children.props.href === this.context.routeHandler.path) {
            structureIdKey = i;
          }
        }
      });
    }
    if (!structureIdKey || ((browserInfo.isIOS || browserInfo.isAndroid) && this.context.routeHandler.path === '/')) {
      structureIdKey = -1;
    }
    // @ts-ignore TS2339
    if (structureIdKey !== this.state.activeTabIndex) {
      this.setState({ activeTabIndex: structureIdKey });
    }
    return structureIdKey;
  }

  scrollToActiveTab(windowWidth) {
    if (typeof window === 'undefined') return;

    if (!windowWidth) {
      windowWidth = DOMUtils.getWindowRect().width;
    }
    const tabsWidth = this.getTabsWidth();
    const tabWidthTotal = this.sumWidth(tabsWidth);
    // @ts-ignore TS2339
    let activeTabIndex = this.state.activeTabIndex;
    if (!activeTabIndex) {
      activeTabIndex = this.getActiveTabIndex();
    }
    if (activeTabIndex === -1) {
      // メニューにないページでもスクロール位置は変えない
      return;
    }
    // @ts-ignore TS2339
    if (this.props.center) {
      // @ts-ignore TS2339
      const { width, left } = DOMUtils.getRect(this.headerTabNaviRef.current.children[activeTabIndex]);
      // @ts-ignore TS2339
      this.headerTabNaviRef.current.scrollLeft =
        // @ts-ignore TS2339
        this.headerTabNaviRef.current.scrollLeft + left - windowWidth / 2 + width / 2;
      return;
    }

    if (0 < activeTabIndex && windowWidth < tabWidthTotal) {
      const tabWidthToLeftOfActiveTabs = _.transform(tabsWidth, (result, width, i) => {
        // @ts-ignore TS2339
        result.push(Math.round(width));
        return activeTabIndex == i;
      });
      // @ts-ignore TS2339
      this.headerTabNaviRef.current.scrollLeft = this.sumWidth(tabWidthToLeftOfActiveTabs);
    }
  }

  handleButtonPress(type) {
    // @ts-ignore TS2339
    this.onPress = true;
    this.doScroll(type == 'left' ? -10 : 10);
  }

  handleButtonRelease(type) {
    // @ts-ignore TS2339
    this.onPress = false;
  }

  handleButtonClick(type) {
    // @ts-ignore TS2339
    if (this.state.animating) return;
    let leftEdgeIdx;
    let rightEdgeIdx;
    // @ts-ignore TS2339
    const children = this.headerTabNaviRef.current.children;
    // @ts-ignore TS2339
    const parentRect = this.headerTabNaviRef.current.getBoundingClientRect();
    // @ts-ignore TS2339
    const fadeLeftWidth = this.fadeLeftRef.current ? this.fadeLeftRef.current.getBoundingClientRect().width : 0;
    // @ts-ignore TS2339
    const fadeRightWidth = this.fadeLeftRef.current ? this.fadeLeftRef.current.getBoundingClientRect().width : 0;
    for (let i = 0; i < children.length; i++) {
      if (children[i].getBoundingClientRect().left > parentRect.left + fadeLeftWidth) {
        if (leftEdgeIdx == undefined) leftEdgeIdx = i;
        if (children[i].getBoundingClientRect().right > parentRect.right - fadeRightWidth) {
          rightEdgeIdx = i - 1;
          break;
        }
      }
    }
    if (!rightEdgeIdx) rightEdgeIdx = children.length - 1;
    const tabsWidth = this.getTabsWidth();
    // @ts-ignore TS2339
    const currentScrollLeft = this.headerTabNaviRef.current.scrollLeft;
    // @ts-ignore TS2339
    const maxScrollLeft = (this.headerTabNaviRef.current.scrollWidth || 0) - this.offsetLeft;
    let scrollWidth = tabsWidth.reduce((sum, width, i) =>
      i >= leftEdgeIdx && i <= rightEdgeIdx ? (sum += width) : sum,
    );
    // @ts-ignore TS2339
    if (this.fadeLeftRef.current) {
      // @ts-ignore TS2339
      const { width } = this.fadeLeftRef.current.getBoundingClientRect();
      scrollWidth -= width;
    }
    // @ts-ignore TS2339
    if (this.fadeRightRef.current) {
      // @ts-ignore TS2339
      const { width } = this.fadeRightRef.current.getBoundingClientRect();
      scrollWidth -= width;
    }
    // 絶妙なスクロール位置によってはスクロールされ過ぎてしまう場合があるので
    if (type == 'left') {
      scrollWidth = currentScrollLeft > scrollWidth ? scrollWidth : currentScrollLeft;
    } else {
      scrollWidth = maxScrollLeft - currentScrollLeft > scrollWidth ? scrollWidth : maxScrollLeft - currentScrollLeft;
    }
    // @ts-ignore TS2339
    this.scrollWidth = type == 'left' ? scrollWidth : -scrollWidth;

    let callback;
    this.setState({ animating: true });

    callback = () => {
      // @ts-ignore TS2339
      this.scrollWidth = 0;
      // @ts-ignore TS2339
      this.headerTabNaviRef.current.scrollLeft += type == 'left' ? -scrollWidth : scrollWidth;
      this.setState({ animating: false });
      // @ts-ignore TS2339
      delete this.animatingEndCallback;
    };
    // @ts-ignore TS2339
    if (this.animatingEndCallback) clearTimeout(this.animatingEndCallback);
    // @ts-ignore TS2339
    this.animatingEndCallback = setTimeout(callback, SLIDE_SPEED);
  }

  doScroll(step) {
    const loop = () => {
      // @ts-ignore TS2339
      this.headerTabNaviRef.current.scrollLeft = this.headerTabNaviRef.current.scrollLeft + step;
      // @ts-ignore TS2339
      if (this.onPress) requestAnimationFrame(loop);
    };
    requestAnimationFrame(loop);
  }

  renderTabLinks() {
    const browserInfo = this.context.getModelData('browserInfo');
    let sliderContentStyle = {};
    // @ts-ignore TS2339
    if (this.state.animating) {
      let sliderContentTransform = '';
      // @ts-ignore TS2339
      if (this.scrollWidth !== 0) {
        // @ts-ignore TS2339
        sliderContentTransform = `translate(${this.scrollWidth}px, 0px)`;
      }
      sliderContentStyle = {
        MsTransform: sliderContentTransform,
        WebkitTransform: sliderContentTransform,
        transform: sliderContentTransform,
        WebkitTransition: `-webkit-transform ${SLIDE_SPEED}ms ease 0s`,
        OTransition: `-o-transform ${SLIDE_SPEED}ms ease 0s`,
        MozTransition: `transform ${SLIDE_SPEED}ms ease 0s, -moz-transform ${SLIDE_SPEED_s}s ease 0s`,
        transition: `transform ${SLIDE_SPEED}ms ease 0s, -webkit-transform ${SLIDE_SPEED_s}s ease 0s, -moz-transform ${SLIDE_SPEED_s}s ease 0s, -o-transform ${SLIDE_SPEED_s}s ease 0s`,
        pointerEvents: 'none',
      };
    }
    const tabLinks = [];
    let navActiveIndex, genreActiveIndex;
    // @ts-ignore TS2339
    const navLinks = this.props.navLinks;
    if (!!navLinks) {
      let navLiksLength = navLinks.length;
      // @ts-ignore TS2339
      if (!this.props.showSecondaryNavigationLinks) {
        // @ts-ignore TS2339
        if (navLiksLength > this.state.activeTabIndex) {
          // @ts-ignore TS2339
          navActiveIndex = this.state.activeTabIndex;
        } else {
          // @ts-ignore TS2339
          genreActiveIndex = this.state.activeTabIndex - navLiksLength;
        }
      } else {
        // @ts-ignore TS2339
        genreActiveIndex = this.state.activeTabIndex - navLiksLength;
      }
    }

    if (
      // @ts-ignore TS2339
      this.props.navLinks &&
      // @ts-ignore TS2339
      (!this.props.showSecondaryNavigationLinks || browserInfo.isIOS || browserInfo.isAndroid)
    ) {
      // @ts-ignore TS2339
      _.map(this.props.navLinks, (navLinkComponent, i) => {
        tabLinks.push(
          <li
            key={`gallery-tab_nav-${i}`}
            className={classnames('mainmenu-list', {
              active: navActiveIndex == i,
            })}
            style={sliderContentStyle}
          >
            {navLinkComponent}
          </li>,
        );
      });
    }
    // @ts-ignore TS2339
    _.map(this.props.genreLinks, (linkComponent, i) => {
      tabLinks.push(
        <li
          key={`gallery-tab_ganre-${i}`}
          className={classnames('genremenu-list', {
            active: genreActiveIndex == i,
          })}
          style={sliderContentStyle}
        >
          {linkComponent}
        </li>,
      );
    });
    return tabLinks;
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');

    let tabContent = (
      <React.Fragment>
        {browserInfo.isIOS || browserInfo.isAndroid ? null : (
          <React.Fragment>
            <FadeLeft
              // @ts-ignore TS2339
              isLeftEdge={this.state.isLeftEdge}
              // @ts-ignore TS2339
              canScroll={this.state.canScroll}
              onClick={() => this.handleButtonClick('left')}
              // @ts-ignore TS2339
              rootRef={this.fadeLeftRef}
            />
            <FadeRight
              // @ts-ignore TS2339
              isRightEdge={this.state.isRightEdge}
              // @ts-ignore TS2339
              canScroll={this.state.canScroll}
              onClick={() => this.handleButtonClick('right')}
              // @ts-ignore TS2339
              rootRef={this.fadeRightRef}
            />
          </React.Fragment>
        )}
        <ul
          // @ts-ignore TS2339
          ref={this.headerTabNaviRef}
          // @ts-ignore TS2339
          className={classnames('gallery-tab-navi', { 'on-animating': this.state.animating })}
          onScroll={this.handleScroll}
        >
          {this.renderTabLinks()}
        </ul>
      </React.Fragment>
    );

    return <div className="gallery-tab-wrapper">{tabContent}</div>;
  }
}
