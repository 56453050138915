// 日付操作に関するライブラリのラッパークラス
// このファイル以外での直接import禁止
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import pluginDuration from 'dayjs/plugin/duration';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(pluginDuration);
dayjs.extend(AdvancedFormat);
dayjs.extend(minMax);
dayjs.locale('ja');
dayjs.tz.setDefault('Asia/Tokyo');

type Datetime_ = Datetime;
declare namespace datetime {
  type Datetime = Datetime_;
  type ConfigType = dayjs.ConfigType | Datetime;
  type OptionType = dayjs.OptionType;
}

const duration = (diff: number, unit?: pluginDuration.DurationUnitType) => {
  return dayjs.duration(diff, unit);
};

const min = (...dates: Datetime[]) => {
  const args = dates.map(d => d.getLib());
  return datetime(dayjs.min(args));
};

const max = (...dates: Datetime[]) => {
  const args = dates.map(d => d.getLib());
  return datetime(dayjs.max(args));
};

class Datetime {
  private _lib: dayjs.Dayjs;
  private _defaultFormat: string;
  private _fromZero: boolean;
  constructor(date?: datetime.ConfigType, format?: datetime.OptionType, locale?: string) {
    if (date instanceof Date) {
      format = undefined;
    } else if (!format) {
      format = [
        'YYYY/MM/DD HH:mm:ss.SSS',
        'YYYY/MM/DD HH:mm:ss',
        'YYYY-MM-DD HH:mm:ss',
        'YYYY/MM/DD',
        'YYYY-MM-DD',
        'X',
        'x',
      ];
    }
    if (date instanceof Datetime) {
      date = date.getLib();
    }
    if (!locale) {
      locale = 'ja';
    }

    this._lib = dayjs(date, format, locale, true);
    this._defaultFormat = 'YYYY-MM-DDTHH:mm:ssZ';
    this._fromZero = true;
  }
  set defaultFormat(defaultFormat) {
    this._defaultFormat = defaultFormat;
  }
  get defaultFormat() {
    return this._defaultFormat;
  }
  set fromZero(fromZero) {
    this._fromZero = fromZero;
  }
  get fromZero() {
    return this._fromZero;
  }
  locale(): string;
  locale(preset: string): this;
  locale(preset?: string) {
    if (preset) {
      this._lib = this._lib.locale(preset);
      return this;
    } else {
      return this._lib.locale();
    }
  }
  add(diff: number, unit?: dayjs.ManipulateType) {
    this._lib = this._lib.add(diff, unit);
    return this;
  }
  subtract(diff: number, unit?: dayjs.ManipulateType) {
    this._lib = this._lib.subtract(diff, unit);
    return this;
  }
  startOf(unit: dayjs.OpUnitType) {
    this._lib = this._lib.startOf(unit);
    return this;
  }
  endOf(unit: dayjs.OpUnitType) {
    this._lib = this._lib.endOf(unit);
    return this;
  }
  diff(date: datetime.ConfigType, unit?: dayjs.OpUnitType) {
    if (date instanceof Datetime) {
      date = date.getLib();
    }
    return this._lib.diff(date, unit);
  }
  diffFromNow(unit?: dayjs.OpUnitType) {
    const now = new Date();
    return this._lib.diff(now, unit);
  }
  duration(date: datetime.ConfigType) {
    if (date instanceof Datetime) {
      date = date.getLib();
    }
    const diff = this._lib.diff(date);
    return duration(diff, 'milliseconds');
  }
  toDate() {
    return this._lib.toDate();
  }
  isSame(date: datetime.ConfigType, unit?: dayjs.OpUnitType) {
    if (date instanceof Datetime) {
      date = date._lib;
    }
    return this._lib.isSame(date, unit);
  }
  isBefore(date: datetime.ConfigType, unit?: dayjs.OpUnitType) {
    if (date instanceof Datetime) {
      date = date._lib;
    }
    return this._lib.isBefore(date, unit);
  }
  isBeforeNow(unit?: dayjs.OpUnitType) {
    const now = new Date();
    return this._lib.isBefore(now, unit);
  }
  isAfter(date: datetime.ConfigType, unit?: dayjs.OpUnitType) {
    if (date instanceof Datetime) {
      date = date._lib;
    }
    return this._lib.isAfter(date, unit);
  }
  isAfterNow(unit?: dayjs.OpUnitType) {
    const now = new Date();
    return this._lib.isAfter(now, unit);
  }
  isSameOrAfter(date: datetime.ConfigType, unit?: dayjs.OpUnitType) {
    if (date instanceof Datetime) {
      date = date._lib;
    }
    return this._lib.isSameOrAfter(date, unit);
  }
  isSameOrAfterNow(unit?: dayjs.OpUnitType) {
    const now = new Date();
    return this._lib.isSameOrAfter(now, unit);
  }
  isSameOrBefore(date: datetime.ConfigType, unit?: dayjs.OpUnitType) {
    if (date instanceof Datetime) {
      date = date._lib;
    }
    return this._lib.isSameOrBefore(date, unit);
  }
  isSameOrBeforeNow(unit?: dayjs.OpUnitType) {
    const now = new Date();
    return this._lib.isSameOrBefore(now, unit);
  }
  isBetween(
    dateA: datetime.ConfigType,
    dateB: datetime.ConfigType,
    u?: dayjs.OpUnitType | null,
    i: '()' | '[]' | '[)' | '(]' = '()',
  ) {
    if (dateA instanceof Datetime) {
      dateA = dateA._lib;
    }
    if (dateB instanceof Datetime) {
      dateB = dateB._lib;
    }
    return this._lib.isBetween(dateA, dateB, u, i);
  }
  clone() {
    return datetime(this._lib);
  }
  format(format = this.defaultFormat) {
    return this._lib.format(format);
  }
  formatMD() {
    return this._lib.format('M/D');
  }
  formatMDdd() {
    return this._lib.format('M/D(dd)');
  }
  formatYYYYMMDD() {
    return this._lib.format('YYYY/MM/DD');
  }
  formatYYYYMMDDdd() {
    return this._lib.format('YYYY/MM/DD(dd)');
  }
  formatYYYYMMDDddJa() {
    return this._lib.format('YYYY年M月D日(dd)');
  }
  formatahmm() {
    const a = this._lib.format('a');
    const mm = this._lib.format('mm');
    let h = this._lib.format('h');
    if (this.fromZero && h === '12') {
      h = '0';
    }
    return `${a}${h}:${mm}`;
  }
  formatYYYYMDahmm() {
    const a = this._lib.format('a');
    const mm = this._lib.format('mm');
    let h = this._lib.format('h');
    if (this.fromZero && h === '12') {
      h = '0';
    }
    return `${this._lib.format('YYYY/MM/DD')} ${a}${h}:${mm}`;
  }
  toISOString() {
    return this._lib.toISOString();
  }
  unix() {
    return this._lib.unix();
  }
  valueOf() {
    return this._lib.valueOf();
  }
  date(): number;
  date(value: number): this;
  date(value?: number): any {
    if (value != null) {
      this._lib.date(value);
      return this;
    } else {
      return this._lib.date();
    }
  }
  day(): number;
  day(value: number): this;
  day(value?: number): any {
    if (value != null) {
      this._lib = this._lib.day(value);
      return this;
    } else {
      return this._lib.day();
    }
  }
  hour(): number;
  hour(value: number): this;
  hour(value?: number): any {
    if (value != null) {
      this._lib = this._lib.hour(value);
      return this;
    } else {
      return this._lib.hour();
    }
  }
  minute(): number;
  minute(value: number): this;
  minute(value?: number): any {
    if (value != null) {
      this._lib = this._lib.minute(value);
      return this;
    } else {
      return this._lib.minute();
    }
  }
  second(): number;
  second(value: number): this;
  second(value?: number): any {
    if (value != null) {
      this._lib = this._lib.second(value);
      return this;
    } else {
      return this._lib.second();
    }
  }
  isValid() {
    return this._lib.isValid();
  }
  getLib() {
    return this._lib;
  }
}

const datetime = (date?: datetime.ConfigType, format?: datetime.OptionType, locale?: string) =>
  new Datetime(date, format, locale);
datetime.duration = duration;
datetime.min = min;
datetime.max = max;

export default datetime;
