import cookieDough from 'cookie-dough';

import { PSID_KEY } from '../constants/cookie';
import datetime from 'src/libs/datetime';

const cookie = cookieDough();

const setPsidLocalStorage = function(psid) {
  if (!window.localStorage) return;

  window.localStorage.setItem(PSID_KEY, JSON.stringify({ psid: psid, ca: new Date() }));
};
const getPsidLocalStorage = function() {
  if (!window.localStorage) return null;

  let data = JSON.parse(window.localStorage.getItem(PSID_KEY));
  if (data && data.psid) {
    return data.psid;
  }

  return null;
};

const setPsidCookie = function(psid) {
  if (!window.navigator.cookieEnabled) return;

  cookie.set(PSID_KEY, psid, {
    path: '/',
    expires: datetime()
      .add(1, 'months')
      .toDate(),
  });
};
const getPsidCookie = function(obj) {
  let psid = null;
  if (obj) {
    psid = obj.get(PSID_KEY);
  } else {
    psid = cookie.get(PSID_KEY);
  }
  return psid;
};

const setPsid = function(psid) {
  if (typeof window === 'undefined') return null;

  try {
    setPsidLocalStorage(psid);
    // フォールバック用
    setPsidCookie(psid);
  } catch (e) {
    console.log(e);
  }
};
const getPsid = function() {
  if (typeof window === 'undefined') return null;

  try {
    let psid = getPsidLocalStorage();
    if (psid) {
      // cookieにあるか？
      // @ts-ignore TS2554
      let _psid = getPsidCookie();
      // ない場合、または値が異なる場合はLocalStorageの値で更新する
      if (!_psid || _psid != psid) setPsidCookie(psid);
      return psid;
    }

    // 取れなければcookieから取得する
    // @ts-ignore TS2554
    psid = getPsidCookie();
    if (psid) {
      // localStrageに入れておく
      setPsidLocalStorage(psid);
      return psid;
    }
  } catch (e) {
    console.log(e);
  }

  return null;
};

const removePsid = function() {
  if (window.localStorage) {
    window.localStorage.removeItem(PSID_KEY);
  }
  if (window.navigator.cookieEnabled) {
    cookie.remove(PSID_KEY, { path: '/' });
  }
};

export default {
  set: setPsid,
  get: getPsid,
  remove: removePsid,
};
