import React, { Component } from 'react';
import _ from 'src/libs/util';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import GalleryContent from './GalleryContent';
import TitleCard from './TitleCard';
import GalleryTab from './GalleryTab';
import MainViewLink from '../../../common/components/MainViewLink';
import { NotFoundError } from '../../../common/components/ErrorBoundary';
import routes from '../../../common/routes';
import { CardContextProvider } from '../../../common/context/CardContext';
import JsonLd from '../../../common/components/JsonLd';

const validPeriod = period => {
  return -1 !== _.indexOf(['daily', 'weekly', 'monthly'], period);
};

const canDisplay = (id, data) => {
  if (id === 0) return true;
  if (_.isInteger(id) && data) return true;
  return false;
};

const headerTitleName = props => {
  const { genreName, personName, teamName, leagueName, studioName, attributeName } = props;
  const attributeTitleName =
    genreName || personName || teamName || leagueName || studioName || attributeName
      ? `${personName} ${teamName} ${leagueName} ${studioName} ${genreName} ${attributeName}`.trim()
      : null;

  return attributeTitleName ? `${attributeTitleName}ランキング` : '人気ランキング';
};
class RankingHeader extends React.Component {
  static get propTypes() {
    return {
      genreId: PropTypes.number,
      genreName: PropTypes.string,
      personId: PropTypes.number,
      personName: PropTypes.string,
      teamId: PropTypes.number,
      teamName: PropTypes.string,
      leagueId: PropTypes.number,
      leagueName: PropTypes.string,
      studioId: PropTypes.number,
      studioName: PropTypes.string,
      selectPeriod: PropTypes.oneOf(['daily', 'weekly', 'monthly']),
      handleSelectPeriodChange: PropTypes.func,
      routeHandler: PropTypes.object.isRequired,
    };
  }

  constructor(props, context) {
    super(props, context);

    const period_list = [
      { query: 'daily', linkText: '前日' },
      { query: 'weekly', linkText: '週間' },
      { query: 'monthly', linkText: '月間' },
    ];
    // @ts-ignore TS2339
    this.links = _.map(period_list, period => {
      const query = { period: period.query };
      // @ts-ignore TS2339
      if (props.genreId) query.genre = props.genreId;
      // @ts-ignore TS2339
      if (props.personId) query.person = props.personId;
      // @ts-ignore TS2339
      if (props.teamId) query.team = props.teamId;
      // @ts-ignore TS2339
      if (props.leagueId) query.league = props.leagueId;
      // @ts-ignore TS2339
      if (props.studioId) query.studio = props.studioId;
      // @ts-ignore TS2339
      if (props.attributeId) query.attribute = props.attributeId;
      // @ts-ignore TS2339
      if (props.mbListLayout) query.lo = props.mbListLayout;
      // @ts-ignore TS2339
      if (props.searchFilter) query.sf = props.searchFilter;

      return (
        <MainViewLink
          // @ts-ignore TS2322
          to={routes.ranking}
          query={query}
          onClick={() => props.handleSelectPeriodChange(period.query)}
          className="active"
        >
          {period.linkText}
        </MainViewLink>
      );
    });

    this.state = {
      activeTabIndex: this.findActiveTabIndex(props.selectPeriod),
    };
  }

  findActiveTabIndex(selectPeriod) {
    // @ts-ignore TS2339
    return _.findIndex(this.links, linkComponent => {
      // @ts-ignore TS2339
      return selectPeriod === linkComponent.props.query.period;
    });
  }

  componentWillReceiveProps(nextProps) {
    // @ts-ignore TS2339
    if (nextProps.selectPeriod !== this.props.selectPeriod) {
      this.setState({ activeTabIndex: this.findActiveTabIndex(nextProps.selectPeriod) });
    }
  }

  render() {
    // @ts-ignore TS2339
    if (this.props.genreId && !this.props.genreName) return null;
    // @ts-ignore TS2339
    if (this.props.personId && !this.props.personName) return null;
    // @ts-ignore TS2339
    if (this.props.teamId && !this.props.teamName) return null;
    // @ts-ignore TS2339
    if (this.props.leagueId && !this.props.leagueName) return null;
    // @ts-ignore TS2339
    if (this.props.studioId && !this.props.studioName) return null;
    // @ts-ignore TS2339
    if (this.props.attributeId && !this.props.attributeName) return null;

    return (
      <React.Fragment>
        <div className="gallery-header">
          <div className="title">
            <span className="gallery-title">{headerTitleName(this.props)}</span>
          </div>
        </div>
        {/*
         // @ts-ignore TS2339 */}
        <GalleryTab links={this.links} activeTabIndex={this.state.activeTabIndex} />
      </React.Fragment>
    );
  }
}

class Ranking extends Component {
  static get propTypes() {
    return {
      history: PropTypes.object,
      routeHandler: PropTypes.object.isRequired,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  static getPaths = function(models, options, props) {
    const personId = props.person || 0;
    const teamId = props.team || 0;
    const leagueId = props.league || 0;
    const studioId = props.studio || 0;
    const genreId = props.genre || 0;
    const attributeId = props.attribute || 0;
    if (!props.period) {
      const paths = [];
      if (genreId > 0) {
        paths.push(['genre', genreId, ['id', 'name']]);
      }
      if (personId > 0) {
        paths.push(['person', personId, ['id', 'name']]);
      }
      if (teamId > 0) {
        paths.push(['team', teamId, ['id', 'name']]);
      }
      if (leagueId > 0) {
        paths.push(['league', leagueId, ['id', 'name']]);
      }
      if (studioId > 0) {
        paths.push(['studio', studioId, ['id', 'name']]);
      }
      if (attributeId > 0) {
        paths.push(['attr', attributeId, ['id', 'name']]);
      }
      return paths;
    }

    const indexes = function(models, options, props) {
      return {
        from: (props && props.fromNum) || 0,
        to:
          props && props.toNum
            ? props.toNum - (props.fromNum || 0)
            : options && options.numItems
            ? options.numItems
            : 11,
      };
    };

    // @ts-ignore TS2339
    return TitleCard.getPaths()
      .map(path => {
        return [
          'ranking',
          props.period,
          Ranking.getAttributeFilter(props),
          props.searchFilter,
          indexes(models, options, props),
        ].concat(path);
      })
      .concat([['ranking', props.period, Ranking.getAttributeFilter(props), props.searchFilter, 'length']]);
  };

  static getPrefetchPaths = function(models, options, props) {
    const pathProps = {};
    const routeHandler = props.routeHandler || null;
    if (routeHandler) {
      if (routeHandler.query['genre']) {
        // @ts-ignore TS2339
        pathProps.genre = parseInt(routeHandler.query['genre']);
      }
      if (routeHandler.query['person']) {
        // @ts-ignore TS2339
        pathProps.person = parseInt(routeHandler.query['person']);
      }
      if (routeHandler.query['team']) {
        // @ts-ignore TS2339
        pathProps.team = parseInt(routeHandler.query['team']);
      }
      if (routeHandler.query['league']) {
        // @ts-ignore TS2339
        pathProps.league = parseInt(routeHandler.query['league']);
      }
      if (routeHandler.query['studio']) {
        // @ts-ignore TS2339
        pathProps.studio = parseInt(routeHandler.query['studio']);
      }
      if (routeHandler.query['attribute']) {
        // @ts-ignore TS2339
        pathProps.attribute = parseInt(routeHandler.query['attribute']);
      }
      // @ts-ignore TS2339
      pathProps.searchFilter = routeHandler.query['sf'] || 'p:a';
    }
    const galleryPaths = GalleryContent.getPaths(
      models,
      options,
      Object.assign({}, GalleryContent.defaultProps, props, {
        // @ts-ignore TS2339
        genreId: pathProps.genre,
        // @ts-ignore TS2339
        personId: pathProps.person,
        // @ts-ignore TS2339
        teamId: pathProps.team,
        // @ts-ignore TS2339
        league: pathProps.league,
        // @ts-ignore TS2339
        studioId: pathProps.studio,
        // @ts-ignore TS2339
        attributeId: pathProps.attribute,
        // @ts-ignore TS2339
        searchFilter: pathProps.searchFilter,
        keyPrefix: 'ranking',
        period: _.get(props, 'routeHandler.query.period', 'daily'),
      }),
    );
    return this.getPaths(models, options, pathProps).concat(galleryPaths);
  };

  static getAttributeFilter = function(props) {
    const genreId = props.genreId || props.genre || 0;
    const personId = props.personId || props.person || 0;
    const teamId = props.teamId || props.team || 0;
    const leagueId = props.leagueId || props.league || 0;
    const studioId = props.studioId || props.studio || 0;
    const attributeId = props.attributeId || props.attribute || 0;

    return `g:${genreId}|p:${personId}|t:${teamId}|l:${leagueId}|s:${studioId}|a:${attributeId}`;
  };

  static get childContextTypes() {
    return {
      listContext: PropTypes.string,
    };
  }

  getChildContext() {
    return {
      listContext: 'ranking',
    };
  }

  constructor(props, context) {
    super(props, context);

    this.handleSelectPeriodChange = this.handleSelectPeriodChange.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);

    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);

    // @ts-ignore TS2339
    this.genreId = parseInt(props.routeHandler.query['genre'] || 0);
    // @ts-ignore TS2339
    if (this.genreId > 0) {
      // @ts-ignore TS2339
      this.genre = this.model.getSync(['genre', this.genreId]);
    }
    // @ts-ignore TS2339
    this.personId = parseInt(props.routeHandler.query['person'] || 0);
    // @ts-ignore TS2339
    if (this.personId > 0) {
      // @ts-ignore TS2339
      this.person = this.model.getSync(['person', this.personId]);
    }
    // @ts-ignore TS2339
    this.teamId = parseInt(props.routeHandler.query['team'] || 0);
    // @ts-ignore TS2339
    if (this.teamId > 0) {
      // @ts-ignore TS2339
      this.team = this.model.getSync(['team', this.teamId]);
    }
    // @ts-ignore TS2339
    this.leagueId = parseInt(props.routeHandler.query['league'] || 0);
    // @ts-ignore TS2339
    if (this.leagueId > 0) {
      // @ts-ignore TS2339
      this.league = this.model.getSync(['league', this.leagueId]);
    }
    // @ts-ignore TS2339
    this.studioId = parseInt(props.routeHandler.query['studio'] || 0);
    // @ts-ignore TS2339
    if (this.studioId > 0) {
      // @ts-ignore TS2339
      this.studio = this.model.getSync(['studio', this.studioId]);
    }
    // @ts-ignore TS2339
    this.attributeId = parseInt(props.routeHandler.query['attribute'] || 0);
    // @ts-ignore TS2339
    if (this.attributeId > 0) {
      // @ts-ignore TS2339
      this.attribute = this.model.getSync(['attr', this.attributeId]);
    }

    // @ts-ignore TS2339
    this.searchFilter = props.routeHandler.query['sf'];

    const browserInfo = context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      if (props.routeHandler.query['lo'] === 't') {
        // @ts-ignore TS2339
        this.mbListLayout = 'table';
      } else if (props.routeHandler.query['lo'] === 'b') {
        // @ts-ignore TS2339
        this.mbListLayout = 'box';
      }
    }

    this.state = {
      dispose: null,
      fetchDataError: null,
      generation: -1,
      selectPeriod: props.routeHandler.query['period'] || 'daily',
    };
  }

  componentDidMount() {
    if (
      // @ts-ignore TS2339
      !validPeriod(this.state.selectPeriod) ||
      // @ts-ignore TS2339
      !canDisplay(this.genreId, this.genre) ||
      // @ts-ignore TS2339
      !canDisplay(this.personId, this.person) ||
      // @ts-ignore TS2339
      !canDisplay(this.teamId, this.team) ||
      // @ts-ignore TS2339
      !canDisplay(this.leagueId, this.league) ||
      // @ts-ignore TS2339
      !canDisplay(this.studioId, this.studio) ||
      // @ts-ignore TS2339
      !canDisplay(this.attributeId, this.attribute)
    ) {
      // componentWillMountでスローしたいが、SSRのタイミングでも呼ばれるので不可
      // https://reactjs.org/docs/error-boundaries.html
      // @ts-ignore TS2554
      throw new NotFoundError();
    }

    // @ts-ignore TS2339
    this._isMounted = true;

    // @ts-ignore TS2339
    this.unlisten = this.props.history.listen((location, action) => {
      // @ts-ignore TS2339
      if (!this._isMounted) return;
      if (action !== 'POP') return; // PUSH:プルダウンの変更、POP:ブラウザバック

      const q = queryString.parse(location.search);
      const period = q.period || 'daily';
      this.handleSelectPeriodChange(period);
    });

    this.sendToGtm();
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;

    // @ts-ignore TS2339
    if (typeof this.unlisten === 'function') {
      // @ts-ignore TS2339
      this.unlisten();
    }

    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    this.context.gtmApp.pageView(
      headerTitleName({
        // @ts-ignore TS2339
        genreName: this.genre ? this.genre.name : '',
        // @ts-ignore TS2339
        personName: this.person ? this.person.name : '',
        // @ts-ignore TS2339
        teamName: this.team ? this.team.name : '',
        // @ts-ignore TS2339
        leagueName: this.league ? this.league.name : '',
        // @ts-ignore TS2339
        studioName: this.studio ? this.studio.name : '',
        // @ts-ignore TS2339
        attributeName: this.attribute ? this.attribute.name : '',
      }),
    );
  }

  handleSelectPeriodChange(sp) {
    // @ts-ignore TS2339
    if (sp !== this.state.selectPeriod) {
      // @ts-ignore TS2339
      const { routeHandler } = this.props;

      this.setState({ dispose: null, generation: -1, fetchDataError: null, selectPeriod: sp }, () => {
        const options = {
          path: routeHandler.path,
          query: Object.assign({}, routeHandler.query, {
            period: sp,
          }),
        };
        routeHandler.query = options.query;
        // ブラウザの履歴を追加していないが、Linkコンポーネントで履歴に追加されているので、ここで追加する必要は無い

        this.fetchData(this.props);
      });
    }
  }

  fetchData(props) {
    const pathProps = Object.assign({}, props, {
      // @ts-ignore TS2339
      period: this.state.period,
      // @ts-ignore TS2339
      genre: this.genreId,
      // @ts-ignore TS2339
      person: this.personId,
      // @ts-ignore TS2339
      team: this.teamId,
      // @ts-ignore TS2339
      league: this.leagueId,
      // @ts-ignore TS2339
      studio: this.studioId,
      // @ts-ignore TS2339
      attribute: this.attributeId,
    });
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // 既に通信使ている場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する。これによって通信が止まるわけではなく、
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    // @ts-ignore TS2339
    const evaluator = this.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }

    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.genre = _.get(res, `json.genre.${this.genreId}`);
        // @ts-ignore TS2339
        this.person = _.get(res, `json.person.${this.personId}`);
        // @ts-ignore TS2339
        this.team = _.get(res, `json.team.${this.teamId}`);
        // @ts-ignore TS2339
        this.league = _.get(res, `json.team.${this.leagueId}`);
        // @ts-ignore TS2339
        this.studio = _.get(res, `json.studio.${this.studioId}`);
        // @ts-ignore TS2339
        this.attribute = _.get(res, `json.attr.${this.attributeId}`);

        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
        };

        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    // @ts-ignore TS2339
    if (!validPeriod(this.state.selectPeriod)) {
      return null;
    }
    if (
      // @ts-ignore TS2339
      !canDisplay(this.genreId, this.genre) ||
      // @ts-ignore TS2339
      !canDisplay(this.personId, this.person) ||
      // @ts-ignore TS2339
      !canDisplay(this.teamId, this.team) ||
      // @ts-ignore TS2339
      !canDisplay(this.leagueId, this.league) ||
      // @ts-ignore TS2339
      !canDisplay(this.studioId, this.studio) ||
      // @ts-ignore TS2339
      !canDisplay(this.attributeId, this.attribute)
    ) {
      return null;
    }

    const rankingHeaderProps = {
      // @ts-ignore TS2339
      genreId: this.genreId,
      // @ts-ignore TS2339
      genreName: this.genre ? this.genre.name : '',
      // @ts-ignore TS2339
      personId: this.personId,
      // @ts-ignore TS2339
      personName: this.person ? this.person.name : '',
      // @ts-ignore TS2339
      teamId: this.teamId,
      // @ts-ignore TS2339
      teamName: this.team ? this.team.name : '',
      // @ts-ignore TS2339
      leagueId: this.leagueId,
      // @ts-ignore TS2339
      leagueName: this.league ? this.league.name : '',
      // @ts-ignore TS2339
      studioId: this.studioId,
      // @ts-ignore TS2339
      studioName: this.studio ? this.studio.name : '',
      // @ts-ignore TS2339
      attributeId: this.attributeId,
      // @ts-ignore TS2339
      attributeName: this.attribute ? this.attribute.name : '',
      // @ts-ignore TS2339
      mbListLayout: this.mbListLayout ? this.props.routeHandler.query['lo'] : null,
      // @ts-ignore TS2339
      selectPeriod: this.state.selectPeriod,
      // @ts-ignore TS2339
      searchFilter: this.searchFilter,
      handleSelectPeriodChange: this.handleSelectPeriodChange,
      // @ts-ignore TS2339
      routeHandler: this.props.routeHandler,
    };

    const galleryContentProps = {};
    // @ts-ignore TS2339
    if (this.mbListLayout) {
      // @ts-ignore TS2339
      galleryContentProps.mbListLayout = this.mbListLayout;
      // @ts-ignore TS2339
      if (this.mbListLayout === 'table' || this.mbListLayout === 'box') {
        // @ts-ignore TS2339
        galleryContentProps.itemsInRow = 1;
      }
      // @ts-ignore TS2339
      if (this.mbListLayout === 'table') {
        // @ts-ignore TS2339
        galleryContentProps.listType = true;
        // @ts-ignore TS2339
        galleryContentProps.listCard = true;
      }
    }

    const jsonLdProps = {};
    const host = this.context.getModelData('hosts', 'host');
    const query = {};
    // @ts-ignore TS2339
    if (this.genreId) query['genre'] = this.genreId;
    // @ts-ignore TS2339
    if (this.leagueId) query['league'] = this.leagueId;
    // @ts-ignore TS2339
    if (this.teamId) query['team'] = this.teamId;
    // @ts-ignore TS2339
    if (this.personId) query['person'] = this.personId;
    // @ts-ignore TS2339
    if (this.attributeId) query['attribute'] = this.attributeId;
    // @ts-ignore TS2339
    if (this.searchFilter) query['sf'] = this.searchFilter;
    // @ts-ignore TS2554
    const url = routes.ranking.makePath({}, query);
    // @ts-ignore TS2339
    jsonLdProps.breadcrumbList = {
      itemListElement: [{ name: headerTitleName(rankingHeaderProps), item: host + url }],
    };

    return (
      <React.Fragment>
        <CardContextProvider value={this.getChildContext()}>
          <GalleryContent
            {...galleryContentProps}
            header={<RankingHeader {...rankingHeaderProps} />}
            // @ts-ignore TS2339
            model={this.props.pathEvaluator || this.props.model.pathEvaluator}
            // @ts-ignore TS2339
            period={this.state.selectPeriod}
            // @ts-ignore TS2339
            genreId={this.genreId}
            // @ts-ignore TS2339
            personId={this.personId}
            // @ts-ignore TS2339
            teamId={this.teamId}
            // @ts-ignore TS2339
            leagueId={this.leagueId}
            // @ts-ignore TS2339
            studioId={this.studioId}
            // @ts-ignore TS2322
            attributeId={this.attributeId}
            // @ts-ignore TS2339
            searchFilter={this.searchFilter || 'p:a'}
            keyPrefix={'ranking'}
          />
        </CardContextProvider>
        <JsonLd {...jsonLdProps} />
      </React.Fragment>
    );
  }
}

export default Ranking;
