import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import activeProfile, { showNotTrial, canPlayBroadcast } from '../../../../utils/activeProfile';
import activeTags, { TAG_TEXTS } from '../../utils/activeTags';
import { getMetaDeliveryStatus, META_DELIVERY_STATUS } from '../../../common/utils/metaUtil';
import datetime from 'src/libs/datetime';

// 優先順位順 高↑ 低↓
// * マイリスト以外
//   * バッジテキスト
//   * バッジステータスのうち「もうすぐ終了」以外表示
//   * メディアメタかつ配信開始している
// 	   * ライブスキーマ以外は配信終了日時から7日以内で終了日時表示
//     * ライブスキーマでライブが終わっていたら、配信終了日時から7日以内で終了日時表示
//   * もうすぐ終了
// * マイリスト
//   * バッジテキスト
//   * バッジステータスのうち「もうすぐ終了」「新着」以外表示
//   * メディアメタかつ配信開始している
// 	   * ライブスキーマ以外は配信終了日時から90日以内で終了日時表示
//     * ライブスキーマでライブが終わっていたら、配信終了日時から90日以内で終了日時表示
//   * もうすぐ終了 or 新着
// * テーブル、リスト表示、エピソード一覧（リスト）
//   * バッジテキスト
//   * バッジステータスが「もうすぐ終了」以外表示
//   * メディアメタかつ配信開始している
// 	   * ライブスキーマ以外で配信終了日時から90日以内で終了日時表示
//     * ライブスキーマでライブが終わっていたら、配信終了日時から90日以内で終了日時表示
// 	 * 「もうすぐ終了」は表示させない
//   + 日付ラベルも表示させる
// * マストヘッド
//    * バッジテキスト
//    * バッジステータスのうち「もうすぐ終了」以外表示
//    * メディアメタかつ配信開始している
//  	  * ライブスキーマ以外は配信終了日時から90日以内（isOnArt時は7日）で終了日時表示
//      * ライブスキーマでライブが終わっていたら、配信終了日時から90日以内（isOnArt時は7日）で終了日時表示
//    * もうすぐ終了
//    + 塗りバッジのみ表示

const CardBadgeNode = props => {
  return <div className={classnames('card-badge', { tvod: props.tvod, ppv: props.tag == 'PPV' })}>{props.tag}</div>;
};

const EndDayBadgeNode = props => {
  return (
    <div className="card-badge">
      <span>{datetime(props.datetime).formatMD()}</span>まで
    </div>
  );
};

const getDays = props => {
  if (
    props.keyPrefix == 'episodesList' ||
    props.listContext == 'mylist' ||
    props.keyPrefix == 'mylist' ||
    props.listType ||
    props.mbListLayout == 'table'
  ) {
    return 90;
  }
  return 7;
};

export const getEndDayBadge = (itemData, cardInfo, EndDayBadgeNode, days = 90, isMylist) => {
  const status = getMetaDeliveryStatus(itemData, { days });
  const canGetEndDayBadge =
    (itemData.type == 'media' || isMylist || itemData.schemaId == 3) &&
    status === META_DELIVERY_STATUS.END_DELIVERY_WITHIN_X_DAYS;
  if (!canGetEndDayBadge) {
    return null;
  }
  return <EndDayBadgeNode datetime={cardInfo.deliveryEndAt} />;
};

const tagText = (props, context) => {
  const itemData = props.item;
  const cardInfo = itemData.cardInfo || {};
  const options = {
    isOnArt: props.isOnArt,
    tvodTagText: false,
    hideDeliveryStartText: props.hideDeliveryStartText || false,
  };
  let tags = activeTags(itemData, context, options);
  // @ts-ignore TS2554
  const profile = activeProfile(context.models) || {};

  const isMylist = props.listContext == 'mylist' || props.keyPrefix == 'mylist';

  // 配信終了日バッジ
  //Box、Table、リスト、マイリスト表示、エピソード一覧の時は配信終了日時から90日以内で終了日時表示
  //それ以外は配信終了日時から7日以内で終了日時表示
  const EndDayBadge = getEndDayBadge(itemData, cardInfo, EndDayBadgeNode, getDays(props), isMylist);

  const badges = [];
  if (EndDayBadge) {
    tags = tags.filter(tag => tag !== TAG_TEXTS.END_DELIVERY_SOON);
    tags.forEach((tag, index) => badges.push(<CardBadgeNode key={index} tag={tag} />));
    badges.push(EndDayBadge);
  } else {
    tags.forEach((tag, index) => badges.push(<CardBadgeNode key={index} tag={tag} />));
  }

  let content;
  // アートの上のバッジは一つ(エピソードリストは除く)
  if (props.isOnArt && props.keyPrefix !== 'episodesList') {
    content = _.first(badges);
  } else {
    content = badges;
  }

  if (props.mbListLayout == 'table' || props.listType || props.keyPrefix == 'episodesList') {
    // Table、リスト表示
    if (!_.isEmpty(tags) || EndDayBadge) {
      return <React.Fragment>{content}</React.Fragment>;
    }
    return null;
  } else if (cardInfo.badgeText && (cardInfo.badgeText + '').length > 0) {
    // バッジテキスト
    return <React.Fragment>{content}</React.Fragment>;
  } else if (isMylist && !(tags.includes(TAG_TEXTS.END_DELIVERY_SOON) || tags.includes(TAG_TEXTS.NEW))) {
    // マイリスト：新着、もうすぐ終了以外は表示
    return <React.Fragment>{content}</React.Fragment>;
  } else if (!isMylist && !tags.includes(TAG_TEXTS.END_DELIVERY_SOON)) {
    // マイリスト以外：もうすぐ終了以外は表示
    return <React.Fragment>{content}</React.Fragment>;
  } else if (EndDayBadge) {
    return EndDayBadge;
  } else if (!_.isEmpty(tags)) {
    return <React.Fragment>{content}</React.Fragment>;
  }
  return null;
};

const tvodTagText = (props, context) => {
  const itemData = props.item;
  const cardInfo = itemData.cardInfo || {};
  const options = {
    isOnArt: props.isOnArt,
  };

  // PPV: 1（ライブのみ/ライブエピソードが混在するシリーズ）
  // レンタル：2（エピソードのみ）
  // 購入商品：3（エピソード・ライブ）
  if (itemData.tvodBadge) {
    let tagText;
    if (itemData.tvodBadge == 1) {
      tagText = 'PPV';
    } else if (itemData.tvodBadge == 2) {
      tagText = 'レンタル';
    } else if (itemData.tvodBadge == 3) {
      tagText = '購入商品';
    }
    return <CardBadgeNode tag={tagText} tvod={itemData.rental} />;
  }
  return null;
};

const CardBadge = (props, context) => {
  if (!props.item) return null;
  if (context.showBadge === false || props.deleteMode) return null;

  let tags = tagText(props, context);
  let tvodTag = tvodTagText(props, context);
  if (_.isEmpty(tags) && _.isEmpty(tvodTag)) return null;

  const browserInfo = context.getModelData('browserInfo');
  return (
    <React.Fragment>
      {!props.listType ? tvodTag : null}
      {(props.listType && !_.isEmpty(tvodTag)) || !_.isEmpty(tags) ? (
        <div
          className={classnames('card-badge-wrapper', {
            relative: props.listType || browserInfo.isIOS || browserInfo.isAndroid,
          })}
        >
          {props.listType ? tvodTag : null}
          {tags}
        </div>
      ) : null}
    </React.Fragment>
  );
};
CardBadge.contextTypes = {
  getModelData: PropTypes.func,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  showBadge: PropTypes.bool,
};
CardBadge.defaultProps = {
  isOnArt: true,
};
export default CardBadge;
