import React from 'react';
import PropTypes from 'prop-types';
import panelHOC from '../../../hocs/panelHOC';
import _ from 'src/libs/util';

const RightsPanel = props => {
  return (
    <React.Fragment>
      <div className="rights">
        {(props.rights || []).map((right, i) => {
          return (
            <div className="right" key={`rights-${i}`}>
              <div className="right-name">
                <p>{right.name}</p>
              </div>
              <div className="view-period">
                <p>
                  <span>{right.displayLimitType}</span>
                  {right.displayLimitValue}
                  {right.displayLimitUnit}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

RightsPanel.contextTypes = {
  models: PropTypes.object,
};

RightsPanel.propTypes = {
  rights: PropTypes.array.isRequired,
};

export default panelHOC(RightsPanel);
