import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import _ from 'src/libs/util';

import PresTrackedElement from '../../../common/components/PresTrackedElement';
import PopCard from './PopCard';
import Meta from './Meta';
import routes from '../../../common/routes';
import MainViewLink from '../../../common/components/MainViewLink';
import canAccessBySlug from '../../utils/slug';
import BackgroundImage from '../../../common/components/BackgroundImage';
import MyListButton from './MyListButton';
import CardContext, { withCardContext } from '../../../common/context/CardContext';
import SetDate from './SetDate';
import CardBadge from './CardBadge';
import datetime from 'src/libs/datetime';
import { getIsDelivery } from 'src/apps/common/utils/metaUtil';

// TODO:定義する
type TitleCardProps = {
  titleType?: string;
  [key: string]: any;
};
type TitleCardState = {
  [key: string]: any;
};

const _TextArea = props => {
  const context = useContext(CardContext);
  const cardInfo = _.get(props, 'item.cardInfo', {});
  const mbListLayout = _.get(props, 'mbListLayout', {});
  let tips = null;

  if (mbListLayout == 'default') {
    return null;
  }
  // @ts-ignore TS2339
  if (context.tips === 'all') {
    tips = <Meta metadata={props.item} />;
  }
  let text = cardInfo.synopsis;
  // @ts-ignore TS2339
  switch (context.textArea) {
    case 'hidden':
      text = null;
      break;
    case 'header':
      text = _.get(props, 'item.header', null);
      break;
    case 'description':
      text = cardInfo.description;
      break;
    case 'delivery_start_at':
      const key = _.get(props.item, 'schemaId') == 4 ? 'broadcastStartAt' : 'deliveryStartAt';
      // @ts-ignore TS2322
      text = cardInfo[key] ? <SetDate date={cardInfo[key]} format={'short'} /> : null;
      break;
  }
  // @ts-ignore TS2339
  if ((context.textArea == 'description' || context.textArea == 'header') && mbListLayout == 'table') {
    text = null;
  }
  if (tips || text) {
    return (
      <React.Fragment>
        {tips}
        {text && <div className="synopsis">{text}</div>}
      </React.Fragment>
    );
  }
  return null;
};
const TextArea = withCardContext(_TextArea);

class TitleCard extends Component<TitleCardProps, TitleCardState> {
  static getPaths = function(models, options, props = {}) {
    let rootPath = [];
    // @ts-ignore TS2339
    if (props.titleId) {
      // @ts-ignore TS2339
      rootPath = rootPath.concat(['meta', props.titleId]);
    }
    const paths = [
      rootPath.concat([
        [
          'id',
          'refId',
          'type',
          'name',
          'shortName',
          'schemaId',
          'copyright',
          'reviewCount',
          'reviewScore',
          'leadEpisodeImage',
          'rental',
          'subscription',
          'edgeEpisodeImage',
          'tallArtwork',
          'leadSeasonId',
          'slug',
          'cardInfo',
          'oap',
          'seriesMeta',
          'seasonMeta',
          'genres',
          'attributes',
          'middleGenres',
          'awKeyb',
          'awKeyc',
          'tvodBadge',
        ],
      ]),
    ];
    return paths
      .concat(
        // @ts-ignore TS2554
        PopCard.getPaths().map(path => {
          return rootPath.concat(path);
        }),
      )
      .concat(
        // @ts-ignore TS2554
        Meta.getPaths().map(path => {
          return rootPath.concat(path);
        }),
      );
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (!props.titleId) return;
    // @ts-ignore TS2339
    return ['meta', props.titleId];
  };

  static get propTypes() {
    return {
      size: PropTypes.oneOf(['1x1', '2x1', '1x2', '2x2', '3x2']).isRequired,
      sliderItemId: PropTypes.string,
      getRowHasPopOpen: PropTypes.func,
      isDoubleWide: PropTypes.bool,
      isTallPanel: PropTypes.bool,
      itemTabbable: PropTypes.bool,
      model: PropTypes.object.isRequired,
      onPopClose: PropTypes.func,
      onPopLeave: PropTypes.func,
      onPopOpen: PropTypes.func,
      rowModel: PropTypes.object,
      rankNum: PropTypes.number,
      rowNum: PropTypes.number,
      titleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      popType: PropTypes.string.isRequired,
      popClasses: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
      showScore: PropTypes.bool,
      titleDisplaySetting: PropTypes.oneOf(['default', 'under_card', 'media_only', 'on_card', 'none']),
      showPopCardMylistButton: PropTypes.bool,
      isPosterPanel: PropTypes.bool,
      deliveryStartVisible: PropTypes.bool,
    };
  }

  static get defaultProps() {
    return {
      size: '1x1',
      ellipsized: false,
      rankNum: 0,
      rowNum: 0,
      popType: 'none',
      showScore: false,
      titleDisplaySetting: 'default',
      showPopCardMylistButton: true,
      deliveryStartVisible: false,
    };
  }

  // static get contextTypes() {
  //   return {
  //     columnsInRow: PropTypes.number,
  //     titleType: PropTypes.string,
  //     textArea: PropTypes.string,
  //     viewType: PropTypes.string,
  //     artType: PropTypes.string,
  //     artKind: PropTypes.string,
  //     currentPage: PropTypes.string,
  //     getModelData: PropTypes.func,
  //     history: PropTypes.object,
  //     isInitialRender: PropTypes.bool,
  //     isKidsPage: PropTypes.bool,
  //     isOverlayPage: PropTypes.bool,
  //     isRtl: PropTypes.bool,
  //     isTallRow: PropTypes.bool,
  //     titleRowEpisodeTrackId: PropTypes.number,
  //     titleRowTrackId: PropTypes.number,
  //     titleRowTrailerTrackId: PropTypes.number,
  //     listContext: PropTypes.string,
  //     listType: PropTypes.string,
  //     loggingApp: PropTypes.object,
  //     canvasId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  //     profileManager: PropTypes.string,
  //     requestId: PropTypes.string,
  //     routeHandler: PropTypes.object,
  //     rowNum: PropTypes.number,
  //     trackId: PropTypes.number,
  //     deleteApp: PropTypes.object,
  //   };
  // }

  static contextType = CardContext;

  private item: any;

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    if (props.itemData) {
      this.item = props.itemData;
    } else if (rootPath) {
      this.item = props.model.getSync(rootPath);
    }
    this.state = {
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
      deleteMode: context.deleteApp ? context.deleteApp.isDeleteMode() : false,
      isDelete: !!(_.find(_.get(context.deleteApp, '_targets', []), target => target.id === props.titleId) || {})
        .checked,
      displayTitle: true,
      hasPop: false,
    };
    this.onPopOpen = this.onPopOpen.bind(this);
    this.onPopWillClose = this.onPopWillClose.bind(this);
    this.onPopClose = this.onPopClose.bind(this);
    // @ts-ignore TS2339
    this.titleCardRef = React.createRef();
    // @ts-ignore TS2339
    this.openedPop = false;
    // @ts-ignore TS2339
    this._isMounted = false;

    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleDeleteModeChange = this.handleDeleteModeChange.bind(this);
    this.handleDeleteChecked = this.handleDeleteChecked.bind(this);
    this.handleChangeMylisted = this.handleChangeMylisted.bind(this);
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    if (this.context.deleteApp) {
      // @ts-ignore TS2339
      this.unregister = this.context.deleteApp.register(
        Object.assign({}, this.props.itemData, {
          id: this.props.titleId,
          // @ts-ignore TS2339
          checked: this.state.isDelete,
          onModeChange: this.handleDeleteModeChange,
          onChecked: this.handleDeleteChecked,
        }),
      );
    }
    if (!this.props.itemData) this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.mouseOutTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOutTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOutTimeoutId;
    }
    // @ts-ignore TS2339
    if (this.mouseOverTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOverTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOverTimeoutId;
    }
    // @ts-ignore TS2339
    if (this.displayTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.displayTimeoutId);
      // @ts-ignore TS2339
      delete this.displayTimeoutId;
    }
    // @ts-ignore TS2339
    if (this.unregister) this.unregister();
  }

  handleDeleteModeChange(deleteMode) {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    this.setState({ deleteMode });
  }

  handleDeleteChecked(item) {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    this.setState({ isDelete: item.checked });
  }

  handleChangeMylisted(mylisted) {
    // PopCardを閉じると元に戻る為更新しておく
    this.item.mylisted = mylisted;
  }

  render() {
    if (!this.item) {
      // TODO: 読み込み中
      return null;
    }

    // ポスターの無いエピソード等もtrueになるが、運用で回避することとする。(エピソードはサムネイルが縦に伸ばされる)
    const canViewPoster = this.context.artType === 'poster';
    const canViewKeyart = this.context.artType === 'keyart';
    const canViewSquare = this.context.artType === 'square';
    const isKeyVisualB = this.context.artType === 'key_visual_b';
    const isKeyVisualC = this.context.artType === 'key_visual_c';
    const getImage = () => {
      let image;
      if (isKeyVisualB) {
        image = this.item.awKeyb;
        if (image) image = image.replace('[width]', '800').replace('[height]', '800');
      } else if (isKeyVisualC) {
        image = this.item.awKeyc;
        if (image) image = image.replace('[width]', '800').replace('[height]', '800');
      } else if (canViewKeyart) {
        image = this.item.awLogokey;
        if (image) image = image.replace('[width]', '800').replace('[height]', '800');
      } else if (canViewPoster) {
        image = this.item.awPos;
        if (image) image = image.replace('[width]', '800').replace('[height]', '800');
      } else if (canViewSquare) {
        image = this.item.awSquare;
        if (image) image = image.replace('[width]', '800').replace('[height]', '800');
      } else if (this.context.artKind === 'lead_episode' || this.props.artKind === 'lead_episode') {
        image = this.item.leadEpisodeImage;
      } else if (this.context.artKind === 'edge_episode' || this.props.artKind === 'edge_episode') {
        image = this.item.edgeEpisodeImage;
      }
      if (image) return image;
      return this.item.thumbnailUrl;
    };

    let detailNode = null;
    if (!_.isEmpty(this.item) && this.state.hasPop && this.props.popType !== 'none') {
      let myListButton;
      if (this.props.showPopCardMylistButton) {
        const myListProps = {
          model: this.props.model,
          btnType: 'short',
          classes: { 'btn-very-small': true },
          btnStyle: false,
        };

        // if (this.item.schemaId == 1) {
        //   myListProps.item = this.item;
        // } else if (!this.item.leadSeasonId) {
        // @ts-ignore TS2339
        myListProps.id = this.item.id;
        // @ts-ignore TS2339
        myListProps.item = this.item;
        // } else {
        //   myListProps.id = this.item.leadSeasonId;
        // }
        // @ts-ignore TS2322
        myListButton = <MyListButton {...myListProps} onChange={this.handleChangeMylisted} />;
      }

      detailNode = (
        <PopCard
          model={this.props.model}
          key={`item_${this.item.id}`}
          classes={classnames(this.props.popClasses)}
          popType={this.props.popType}
          titleCardImage={this.props.isTallPanel === true ? this.item.tallThumbnailUrl : getImage()}
          titleModel={this.item}
          // @ts-ignore TS2322
          titleId={this.props.titleId}
          artKind={this.context.artKind}
          artType={this.context.artType}
          myListButton={myListButton}
          showEpisodeNumber={this.props.showEpisodeNumber}
          deliveryStartVisible={this.props.deliveryStartVisible}
          keyPrefix={this.props.keyPrefix}
          listContext={this.props.listContext}
          listType={this.props.listType}
        />
      );
    }

    const key = `title-card-${this.props.rowNum}-${this.props.rankNum}-${this.props.titleId}`;
    const tabIndex = this.props.itemTabbable ? 0 : -1;

    let titleArtwork = (
      <BackgroundImage
        // @ts-ignore TS2322
        className={classnames('artwork', {
          'double-wide': this.props.isDoubleWide === true && this.props.isTallPanel !== true,
          noImage: !getImage(),
        })}
        url={getImage()}
      >
        {this.props.listContext == 'continueWatching' &&
        getIsDelivery(this.item.cardInfo.deliveryStartAt, this.item.cardInfo.deliveryEndAt) ? (
          <div className="play-link">
            <div className="play-ring">
              <div className="play icon-play"></div>
            </div>
          </div>
        ) : null}
      </BackgroundImage>
    );
    // posterかつpopcardがない場合は不要なので描画しない
    if (canViewPoster && this.props.popType === 'none') titleArtwork = null;

    let titleTallArtwork;
    if (this.props.isTallPanel === true) {
      titleTallArtwork = (
        <BackgroundImage
          // @ts-ignore TS2322
          className={classnames('artwork artwork-tall', { noImage: !getImage() })}
          url={this.item.tallThumbnailUrl}
        />
      );
    } else if (canViewPoster) {
      titleTallArtwork = (
        // @ts-ignore TS2322
        <BackgroundImage className={classnames('artwork artwork-tall', { noImage: !getImage() })} url={getImage()} />
      );
    }

    let cardBadge = (
      <CardBadge
        item={this.item}
        mbListLayout={this.props.mbListLayout}
        deleteMode={this.state.deleteMode}
        keyPrefix={this.props.keyPrefix}
        listContext={this.props.listContext}
        listType={this.props.listType}
        isOnArt={this.props.mbListLayout !== 'table' && !this.props.listCard}
        hideDeliveryStartText={this.context.textArea === 'delivery_start_at'}
      />
    );

    let rankingBadge;
    if (this.context.listContext === 'ranking' && this.props.rankNum < 50) {
      rankingBadge = <div className="ranking-badge">{this.props.rankNum + 1}.</div>;
      // @ts-ignore TS2339
    } else if (this.context.viewType !== 'list' && this.state.hasPop && this.props.popType !== 'none') {
      rankingBadge = null;
    }

    let deleteCheckbox;
    // @ts-ignore TS2339
    if (this.state.deleteMode) {
      deleteCheckbox = (
        <div className="checkbox-cont">
          <input
            id={`delete-${this.item.id}`}
            className="checkbox"
            type="checkbox"
            name="delete"
            checked={this.state.isDelete}
            onChange={this.handleClick}
          />
          <label htmlFor={`delete-${this.item.id}`} className="check-box-label no-position"></label>
        </div>
      );
    }

    const browserInfo = this.context.getModelData('browserInfo');

    let itemId;
    if (this.item && this.item.id) {
      itemId = canAccessBySlug(this.item) ? this.item.slug : this.item.id;
    }

    let titleDisplaySetting = this.props.titleDisplaySetting;
    if (titleDisplaySetting === 'default') {
      titleDisplaySetting = 'under_card';
    } else if (this.item && titleDisplaySetting === 'media_only' && this.item.type !== 'media') {
      titleDisplaySetting = 'none';
    }

    const routeHandler = this.context.routeHandler;
    const WatchNow = props => {
      if (routeHandler.route._regex === routes.title._regex || routeHandler.route._regex === routes.watchNow._regex) {
        return <div className={props.classname}>{props.children}</div>;
      } else {
        return <h3 className={props.classname}>{props.children}</h3>;
      }
    };

    // console.log(this.item)
    let time;
    //過去のライブ配信期間を出すかださないか
    //ライブ配信ページ：出す、その他：出さない？
    if (this.props.deliveryStartVisible) {
      if (this.item.cardInfo && this.item.cardInfo.deliveryStartAt) {
        time = <SetDate date={this.item.cardInfo.deliveryStartAt} format={'short'} />;
      }
    } else {
      if (this.item.cardInfo && datetime(this.item.cardInfo.deliveryStartAt).diff(datetime(), 'second') > 0) {
        time = <SetDate date={this.item.cardInfo.deliveryStartAt} format={'short'} />;
      }
    }

    let titleShow = true;
    const onCard = titleDisplaySetting === 'on_card' || titleDisplaySetting === 'media_only';
    const titleClassname = classnames('title-card-title', {
      ellipsized: this.props.ellipsized,
      // @ts-ignore TS2339
      'hidden-on-card': onCard && !this.state.displayTitle,
      ranking: !!rankingBadge,
    });
    let title = (
      <WatchNow {...this.props} classname={titleClassname}>
        {this.props.titleType !== 'hidden' || this.context.titleType !== 'hidden' || this.props.viewType == 'list'
          ? rankingBadge
          : null}
        {itemId && !onCard ? (
          <p onClick={!(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleClick : null} className="link">
            {this.props.keyPrefix == 'mylist' || this.context.listContext == 'mylist'
              ? this.item.name
              : this.context.titleType == 'name' ||
                this.context.titleType === 'full_name' ||
                this.context.titleType === 'hidden' ||
                this.props.titleType == 'name' ||
                this.props.titleType === 'full_name' ||
                this.props.titleType === 'hidden'
              ? this.item.name || this.item.shortName
              : this.item.shortName || this.item.name}
          </p>
        ) : (
          <p>
            {this.props.keyPrefix == 'mylist' || this.context.listContext == 'mylist'
              ? this.item.name
              : this.context.titleType === 'full_name'
              ? this.item.name || this.item.shortName
              : this.item.shortName || this.item.name}
          </p>
        )}
      </WatchNow>
    );
    if (!onCard) {
      if (this.props.mbListLayout == 'grid') {
        if (this.props.titleType == 'hidden' || this.context.titleType == 'hidden') {
          titleShow = false;
        } else {
          titleShow = true;
        }
      } else {
        if (this.props.mbListLayout == 'table') {
          titleShow = true;
        } else if (this.props.titleType !== 'hidden' || this.context.titleType !== 'hidden') {
          titleShow = true;
        } else if (this.props.viewType == 'list') {
          titleShow = true;
        } else {
          titleShow = false;
        }
      }
      if (titleDisplaySetting == 'none') {
        titleShow = false;
      }
    } else {
      if (this.props.mbListLayout == 'table') {
        titleShow = true;
      } else if (this.props.titleType !== 'hidden' || this.context.titleType !== 'hidden') {
        titleShow = true;
      } else if (this.props.viewType == 'list') {
        titleShow = true;
      } else {
        titleShow = false;
      }
    }

    // @ts-ignore TS2322
    let textAreaBox = <TextArea item={this.item} mbListLayout={this.props.mbListLayout} />;
    let cardContent = (
      <React.Fragment>
        <div
          className={classnames('sliderRefocus', {
            // @ts-ignore TS2339
            hasPop: this.state.hasPop === true,
            lockup: true,
            'title-card-tall-panel': this.props.isTallPanel === true,
            'title-card-tall-artwork': canViewPoster,
            rankingGra: rankingBadge && onCard && !titleShow,
            onRankingBadge: rankingBadge && !onCard && !titleShow,
          })}
          aria-label={this.item.name}
          // @ts-ignore TS2339
          ref={this.titleCardRef}
          role={'link'}
          tabIndex={
            !(browserInfo.isIOS || browserInfo.isAndroid || browserInfo.isRequestDesktopWebsite) ? tabIndex : null
          }
          key={key}
          id={key}
          onClick={!(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleClick : null}
          onKeyDown={
            !(browserInfo.isIOS || browserInfo.isAndroid || browserInfo.isRequestDesktopWebsite)
              ? this.handleKeyDown
              : null
          }
          onMouseEnter={this.handleMouseOver}
          onMouseLeave={this.handleMouseOut}
        >
          {/*
           // @ts-ignore TS2322 */}
          <PresTrackedElement idDoubleWide={this.props.isDoubleWide}>
            {deleteCheckbox}
            {this.props.listCard ? null : cardBadge}
            {titleArtwork}
            {titleTallArtwork}
            <div className="video-preload-title">
              <div className="video-preload-title-label">{this.item.name}</div>
            </div>
          </PresTrackedElement>
          <TransitionGroup component={null}>
            {detailNode ? (
              <CSSTransition
                classNames="pop-bounce"
                onEnter={this.onPopOpen}
                onExit={this.onPopWillClose}
                onExited={this.onPopClose}
                timeout={{ exit: 400, enter: 400 }}
              >
                {detailNode}
              </CSSTransition>
            ) : null}
          </TransitionGroup>
          {onCard ? (
            <React.Fragment>
              {titleShow ? title : null}
              {!titleShow ? rankingBadge : null}
            </React.Fragment>
          ) : null}
          {!onCard && !titleShow ? rankingBadge : null}
        </div>
        {this.props.size === '3x2' || this.props.size === '3x1' ? (
          <div className="title-detail-card">
            <div className="title-detail-card-info">
              <div className="title">{this.item.shortName}</div>
              <Meta metadata={this.item} />
              <div className="synopsis">{this.item.cardInfo.synopsis}</div>
            </div>
          </div>
        ) : null}
        <div
          className={classnames('text-wrapper', {
            hasTextArea: this.context.textArea !== 'hidden' || this.props.textArea !== 'hidden',
          })}
        >
          {this.props.mbListLayout == 'table' ? cardBadge : null}
          {onCard ? null : titleShow ? title : null}
          {this.props.mbListLayout == 'table' ? null : this.props.listCard ? cardBadge : null}
          {textAreaBox}
        </div>
        {this.props.mbListLayout == 'table' && this.item.cardInfo.description ? (
          <div className="synopsis">{this.item.cardInfo.description}</div>
        ) : null}
      </React.Fragment>
    );

    if (browserInfo.isIOS || browserInfo.isAndroid) {
      let linkProps = {};
      if (this.props.keyPrefix === 'onrental' || this.props.listContext === 'onrental') {
        linkProps = {
          to: routes.titleEpisodes,
          params: { id: this.props.titleId },
          query: { entitled: 1 },
        };
        // TODO インデント
      } else {
        linkProps = {
          to: this.item.type === 'media' ? routes.watchNow : routes.title,
          params: { id: itemId },
        };
        if (this.item.refId) {
          linkProps = {
            to: this.item.type === 'media' ? routes.content : routes.program,
            params: { id: this.item.refId },
          };
          if (this.item.type === 'linear_channel') {
            const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.item.refId);
            linkProps = {
              to: routes.simulcast,
              params: { channelName: simulcast.name },
            };
          }
        }
      }
      return (
        <MainViewLink
          {...linkProps}
          // @ts-ignore TS2322
          role="link"
          aria-label="card"
          onClick={this.handleClick}
          className={classnames('card', 'title-card', `size-${this.props.size}`, {
            'on-card':
              onCard &&
              // @ts-ignore TS2339
              this.state.displayTitle &&
              !(
                (this.context.titleType === 'hidden' || this.props.titleType === 'hidden') &&
                (this.context.textArea === 'hidden' || this.props.textArea === 'hidden')
              ),
            list: this.context.viewType === 'list',
            keyart: this.context.artType === 'keyart',
            poster: this.context.artType === 'poster',
            square: this.context.artType === 'square',
            rankingCard: rankingBadge,
          })}
        >
          {cardContent}
        </MainViewLink>
      );
    } else {
      return (
        <div
          className={classnames('card', 'title-card', `size-${this.props.size}`, {
            'on-card':
              onCard &&
              // @ts-ignore TS2339
              this.state.displayTitle &&
              !(
                (this.context.titleType === 'hidden' || this.props.titleType === 'hidden') &&
                (this.context.textArea === 'hidden' || this.props.textArea === 'hidden')
              ),
            list: this.context.viewType === 'list',
            keyart: this.context.artType === 'keyart',
            poster: this.context.artType === 'poster',
            square: this.context.artType === 'square',
            rankingCard: browserInfo.isCarMonitor && rankingBadge,
          })}
        >
          {cardContent}
        </div>
      );
    }
  }

  handleClick(e) {
    e.preventDefault();
    if (this.props.titleId) {
      // @ts-ignore TS2339
      if (this.state.deleteMode) {
        // @ts-ignore TS2339
        if (this.state.isDelete) {
          // @ts-ignore TS2339
          this.context.deleteApp.remove(this.props.titleId);
        } else {
          // @ts-ignore TS2339
          this.context.deleteApp.add(this.props.titleId);
        }
        return;
      }

      const { history } = this.context;
      let to;
      let params = { id: this.props.titleId };
      let query = {};
      if (
        (this.props.keyPrefix && this.props.keyPrefix.toLowerCase() === 'onrental') ||
        (this.props.listContext && this.props.listContext.toLowerCase() === 'onrental')
      ) {
        to = routes.titleEpisodes;
        params = { id: this.props.titleId };
        query = { entitled: 1 };
        // TODO インデント
      } else {
        if (_.get(this, 'item.type') === 'media') {
          to = routes.watchNow;
        } else {
          to = routes.title;
          if (this.item && this.item.id) {
            params.id = canAccessBySlug(this.item) ? this.item.slug : this.item.id;
          }
        }
        if (this.item.refId) {
          to = _.get(this, 'item.type') === 'media' ? routes.content : routes.program;
          params.id = this.item.refId;
          if (_.get(this, 'item.type') === 'linear_channel') {
            const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.item.refId);
            to = routes.simulcast;
            // @ts-ignore TS2322
            params = { channelName: simulcast.name };
          }
        }
      }
      const path = to.makePath(params, query);
      const routesMatchFlag =
        !!this.context.routeHandlers &&
        _.some(this.context.routeHandlers, handler => handler.route._regex === to._regex);
      if (routesMatchFlag) {
        history.push(path);
      } else {
        window.location.href = path;
      }
    }
    if (this.props.onCardClick) this.props.onCardClick(this.item);
  }

  handleKeyDown(e) {
    if (e.which === 13 || e.keyCode === 13) {
      this.handleClick(e);
    }
  }

  handleMouseOver(e) {
    // タッチデバイスはpopさせない
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isTouchDevice || browserInfo.isRequestDesktopWebsite) {
      return;
    } else if (this.props.popType === 'none') {
      return;
      // @ts-ignore TS2339
    } else if (this.state.deleteMode) {
      return;
    }

    // @ts-ignore TS2339
    if (this.state.hasPop && this.mouseOutTimeoutId && this.props.onPopOpen) {
      this.props.onPopOpen(this);
    }

    // @ts-ignore TS2339
    if (this.displayTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.displayTimeoutId);
      // @ts-ignore TS2339
      delete this.displayTimeoutId;
    }

    // @ts-ignore TS2339
    if (this.mouseOutTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOutTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOutTimeoutId;
      return;
    }

    let timeout = 300;
    if (this.props.getRowHasPopOpen) {
      if (this.props.getRowHasPopOpen()) {
        timeout = 100;
      }
    }
    // @ts-ignore TS2339
    if (this.popUnmounting === true) {
      timeout = 300;
    }

    // @ts-ignore TS2339
    this.alreadyPopOpened = false;
    // @ts-ignore TS2339
    this.mouseOverTimeoutId = setTimeout(() => {
      // @ts-ignore TS2339
      delete this.mouseOverTimeoutId;
      this.setState({ hasPop: true, displayTitle: false });
    }, timeout);
  }

  handleMouseOut(e) {
    if (this.props.popType === 'none') {
      return;
    }

    // まだ拡大が始まっていない時は拡大しない
    // @ts-ignore TS2339
    if (this.mouseOverTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOverTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOverTimeoutId;
    }

    // @ts-ignore TS2339
    if (!this.state.hasPop) return;

    // @ts-ignore TS2339
    const delay = this.alreadyPopOpened ? 150 : 1;
    if (this.props.onPopLeave) {
      this.props.onPopLeave(this, delay);
    }

    // @ts-ignore TS2339
    this.mouseOutTimeoutId = setTimeout(() => {
      // @ts-ignore TS2339
      delete this.mouseOutTimeoutId;
      // @ts-ignore TS2339
      this.popUnmounting = true;
      this.setState({ hasPop: false });
    }, delay);
    // @ts-ignore TS2339
    this.displayTimeoutId = setTimeout(() => {
      // @ts-ignore TS2339
      delete this.displayTimeoutId;
      this.setState({ displayTitle: true });
    }, 500);
  }

  onPopOpen() {
    if (this.props.popType === 'none') {
      return;
    }

    // @ts-ignore TS2339
    this.alreadyPopOpened = true;
    // @ts-ignore TS2339
    if (this.props.onPopOpen && this.state.hasPop === true) {
      this.props.onPopOpen(this);
    }

    // @ts-ignore TS2339
    this.openedPop = true;
  }

  onPopWillClose() {
    // @ts-ignore TS2339
    this.openedPop = false;
  }

  onPopClose() {
    if (this.props.popType === 'none') {
      return;
    }

    // @ts-ignore TS2339
    delete this.popUnmounting;
    if (this.props.onPopClose) {
      this.props.onPopClose(this);
    }

    this.forceUpdate();
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    if (!rootPath) return;

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        this.item = _.get(res.json, rootPath, {});
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }

  getZoomScale() {
    return this.props.isTallRow === true ? 1.2 : this.props.popType === 'bounce' ? 1.55 : 1.95;
  }
}

const root = withCardContext(TitleCard);
// @ts-ignore TS2339
root.getPaths = TitleCard.getPaths;
export default root;
