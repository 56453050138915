import React from 'react';
import _ from 'src/libs/util';
import PropTypes from 'prop-types';

import SortGallery from './SortGallery';
// import BreadCrumbs from './BreadCrumbs';
// import SubGenres from './SubGenres';

export default class AttributeHeader extends React.PureComponent {
  static getPaths = function(models, options, props) {
    let rootPath = [];
    let subGenrePaths = [];
    let breadCrumbPath = [];
    if (props.id && props.type) {
      rootPath = rootPath.concat(this.getRootPath(models, options, props));
    }

    // @ts-ignore TS2554
    const sortGalleryPaths = SortGallery.getPaths(models, options, {
      filterType: props.filterType || 'a',
      sortOrder: props.sortOrder || 'po',
    }).map(function(paths) {
      return rootPath.concat(paths);
    });

    if (props.type === 'genre') {
      // subGenrePaths = subGenrePaths.concat(
      //   SubGenres.getPaths(models, options, { id: props.id }).map(function(path) {
      //     return rootPath.concat(path);
      //   }),
      // );
      // breadCrumbPath = breadCrumbPath.concat(
      //   BreadCrumbs.getPaths(models, options, {
      //     id: props.id,
      //     breadCrumbs: props.breadCrumbs,
      //   }),
      // );
      return breadCrumbPath.concat(subGenrePaths).concat(sortGalleryPaths);
    }

    return sortGalleryPaths.concat(
      props.pqls.map(pql => {
        return rootPath.concat([pql]);
      }),
    );
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    return [props.type, props.id];
  };

  static get propTypes() {
    return {
      model: PropTypes.object,
      pqls: PropTypes.array,
      type: PropTypes.oneOf(['attr', 'genre', 'person', 'league', 'team']).isRequired,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    this.data = props.model.getSync(rootPath);
    this.state = {
      dispose: null,
      fetchDataError: null,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    if (this.props.type !== 'genre') this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.data = _.get(res.json, rootPath, {});
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');
    let titleContent;
    // if (this.props.type === 'genre') {
    //   const bcPqls = _.map(this.props.breadCrumbs, bc => {
    //     return [this.props.type, bc, 'name'];
    //   });
    //   bcPqls.push([this.props.type, this.props.id, 'name']);
    //   const breadCrumbsProps = {
    //     breadCrumbs: this.props.breadCrumbs,
    //     id: this.props.id,
    //     model: this.props.model,
    //     pqls: bcPqls,
    //   };
    //   const subGenresProps = {
    //     breadcrumbs: this.props.breadcrumbs,
    //     id: this.props.id,
    //     model: this.props.model,
    //   };
    //   titleContent = (
    //     <React.Fragment>
    //       <BreadCrumbs {...breadCrumbsProps} />
    //       {/* <SubGenres {...subGenresProps} /> */}
    //     </React.Fragment>
    //   );
    // } else {
    titleContent =
      // @ts-ignore TS2339
      this.data && this.data.name ? (
        <span className="gallery-title">
          {/*
           // @ts-ignore TS2339 */}
          {this.data.name}
          {/*
           // @ts-ignore TS2339 */}
          {this.data.type !== 'genre' && 'の関連作品'}
        </span>
      ) : null;
    // }
    const sortGalleryProps = {
      // @ts-ignore TS2339
      model: this.props.model,
      onFillterChange: this.setFilter,
      // @ts-ignore TS2339
      onChangeQuery: this.props.onChangeQuery,
      // @ts-ignore TS2339
      filterType: this.props.filterType ? this.props.filterType : 'po',
      // @ts-ignore TS2339
      sortOrder: this.props.sortOrder ? this.props.sortOrder : 'po',
      positionRight: true,
    };
    return (
      <React.Fragment>
        <div className="gallery-header clearfix">
          <div className="title">{titleContent}</div>
          {/*
           // @ts-ignore TS2339 */}
          {!(browserInfo.isIOS || browserInfo.isAndroid) && this.props.showSortContent && (
            <SortGallery {...sortGalleryProps} />
          )}
        </div>
        {/*
         // @ts-ignore TS2339 */}
        {this.props.galleryTab}
        {/*
         // @ts-ignore TS2339 */}
        {(browserInfo.isIOS || browserInfo.isAndroid) && this.props.showSortContent && (
          <SortGallery {...sortGalleryProps} />
        )}
      </React.Fragment>
    );
  }

  setFilter() {
    // TODO
  }
}
