import React, { useMemo } from 'react';
import _ from 'src/libs/util';

import { NotFoundError as ClientNotFoundError } from '../../../common/components/ErrorBoundary';
import Attribute from './Attribute';

const type = 'team';

const tabInfo = [
  { key: 'team', name: 'オンデマンド', sort: true, query: { type: 'od' } },
  { key: 'teamEvent', name: '放送同時配信', sort: false, query: { type: 'ev' } },
];

const Team = props => {
  const model = useMemo(() => (props.pathEvaluator || props.model.pathEvaluator).batch(100), [
    props.model.pathEvaluator,
    props.pathEvaluator,
  ]);
  const item = useMemo(() => {
    const rootPath = Team.getRootPath({}, {}, props);
    return model.getSync(rootPath);
  }, [model, props]);

  if (!item || !item.id) {
    // @ts-ignore TS2554
    throw new ClientNotFoundError();
  }

  return <Attribute {...props} model={model} tabInfo={tabInfo} type={type} />;
};

Team.getPaths = function(models, options, props) {
  const pathProps = Object.assign({ tabInfo: tabInfo, type: type }, props);
  return Attribute.getPaths(models, options, pathProps);
};
Team.getRootPath = function(models, options, props = {}) {
  // @ts-ignore TS2339
  if (props.id) {
    // @ts-ignore TS2339
    return [type, props.id];
  }
  return [];
};
Team.getPrefetchPaths = function(models, options, props) {
  const pathProps = Object.assign({ tabInfo: tabInfo, type: type }, props);
  const paths = Attribute.getPrefetchPaths(models, options, pathProps);
  return paths;
};
Team.getPrefetchedPaths = function(models, options, props) {
  return data => {
    const pathProps = Object.assign({ tabInfo: tabInfo, type: type }, props);
    return Attribute.getPrefetchedPaths(models, options, pathProps)(data);
  };
};
Team.afterPrefetch = function(models, options, props) {
  return data => {
    const pathProps = Object.assign({ tabInfo: tabInfo, type: type }, props);
    return Attribute.afterPrefetch(models, options, pathProps)(data);
  };
};

export default Team;
