import React, { useMemo } from 'react';
import _ from 'src/libs/util';

const CloedCampaignTextContent = props => {
  const isCoedCampaignTextContent = useMemo(
    () =>
      !props.loadingHowtoPlayData &&
      props.meta.rental &&
      (props.meta.memberOnly || !props.meta.free) &&
      _.isEmpty(props.courses) &&
      _.isEmpty(props.products),
    [
      props.courses,
      props.loadingHowtoPlayData,
      props.meta.free,
      props.meta.memberOnly,
      props.meta.rental,
      props.products,
    ],
  );

  return isCoedCampaignTextContent ? (
    <div className="watch-main-visual__metadata__message">
      <div className="watch-main-visual__metadata__message-title">
        本作品は限定されたユーザ向けのコンテンツとなります。商品購入ページよりご購入をお願いいたします。
      </div>
    </div>
  ) : null;
};

export default React.memo(CloedCampaignTextContent);
