import React, { useMemo } from 'react';
import _ from 'src/libs/util';

import Attribute from './Attribute';
import { NotFoundError as ClientNotFoundError } from '../../../common/components/ErrorBoundary';

const type = 'league';

const tabInfo = [
  { key: 'league', name: 'オンデマンド', sort: true, query: { type: 'od' } },
  { key: 'leagueEvent', name: '放送同時配信', sort: false, query: { type: 'ev' } },
];

const League = props => {
  const model = useMemo(() => (props.pathEvaluator || props.model.pathEvaluator).batch(100), [
    props.model.pathEvaluator,
    props.pathEvaluator,
  ]);
  const item = useMemo(() => {
    const rootPath = League.getRootPath({}, {}, props);
    return model.getSync(rootPath);
  }, [model, props]);

  if (!item || !item.id) {
    // @ts-ignore TS2554
    throw new ClientNotFoundError();
  }

  return <Attribute {...props} model={model} tabInfo={tabInfo} type={type} />;
};

League.getPaths = function(models, options, props) {
  const pathProps = Object.assign({ tabInfo: tabInfo, type: type }, props);
  return Attribute.getPaths(models, options, pathProps);
};
League.getRootPath = function(models, options, props = {}) {
  // @ts-ignore TS2339
  if (props.id) {
    // @ts-ignore TS2339
    return [type, props.id];
  }
  return [];
};
League.getPrefetchPaths = function(models, options, props) {
  const pathProps = Object.assign({ tabInfo: tabInfo, type: type }, props);
  const paths = Attribute.getPrefetchPaths(models, options, pathProps);
  return paths;
};
League.getPrefetchedPaths = function(models, options, props) {
  return data => {
    const pathProps = Object.assign({ tabInfo: tabInfo, type: type }, props);
    return Attribute.getPrefetchedPaths(models, options, pathProps)(data);
  };
};

League.afterPrefetch = function(models, options, props) {
  return data => {
    const pathProps = Object.assign({ tabInfo: tabInfo, type: type }, props);
    return Attribute.afterPrefetch(models, options, pathProps)(data);
  };
};

export default League;
