import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import routes, { getHomeRoute } from '../../../../common/routes';

import URLGenerator from '../../../../../sketch-platform/ui/routing/URLGenerator';

export const FilterSearchGenre = (props, context) => {};
FilterSearchGenre.getRootPath = function(models, options, props = {}) {
  let key = '';
  if (_.get(models, 'userInfo.data.status') == 'CURRENT_MEMBER') {
    if (_.get(models, 'userInfo.data.isKids')) {
      key = _.get(models, 'config.data.menu_attribute_sets_key.kids');
    } else {
      key = _.get(models, 'config.data.menu_attribute_sets_key.general');
    }
  } else {
    key = _.get(models, 'config.data.menu_attribute_sets_key.anonymous');
  }
  return ['attribute_sets', key];
};
FilterSearchGenre.getPaths = function(models, options, props = {}) {
  const rootPath = FilterSearchGenre.getRootPath(models, options, props);
  // @ts-ignore TS2769
  return [rootPath.concat([{ from: 0, to: 49 }, ['id', 'name', 'slug']])];
};

class FilterList extends Component {
  static get contextTypes() {
    return {
      i18n: PropTypes.object,
      i18nContext: PropTypes.string,
    };
  }
  constructor(props, context) {
    super(props, context);
    this.handleToggleSearchFilterClose = this.handleToggleSearchFilterClose.bind(this);
  }

  handleToggleSearchFilterClose(e) {
    let value = e.currentTarget.getAttribute('value');
    // @ts-ignore TS2339
    if (this.props.selected == value) {
      value = '';
    }
    // @ts-ignore TS2339
    this.props.handleToggleSearchFilterClose(value);
  }
  render() {
    const node = [];
    // @ts-ignore TS2339
    if (this.props.filter.type === 'renge') {
      // @ts-ignore TS2339
      for (let i = this.props.filter.renge.max; i >= this.props.filter.renge.min; i--) {
        node.push(
          <li
            // @ts-ignore TS2339
            key={`search-filter-${this.props.filter.key}-${i}`}
            // @ts-ignore TS2339
            className={classnames({ selected: _.includes(this.props.selected, '' + i) })}
            value={i}
            onClick={this.handleToggleSearchFilterClose}
            onKeyPress={this.handleToggleSearchFilterClose}
            tabIndex={0}
            data-tracking-click="filter-cbox"
          >
            {i}
          </li>,
        );
      }
    } else {
      // @ts-ignore TS2339
      _.forEach(this.props.filter.values, item => {
        let value = null;
        const key = item.id ? '' + item.id : item.key;
        value = item.id ? item.name : item.name;
        node.push(
          <li
            // @ts-ignore TS2339
            key={`search-filter-${this.props.filter.key}-${key}`}
            // @ts-ignore TS2339
            className={classnames({ selected: _.includes(this.props.selected, key) })}
            value={key}
            onClick={this.handleToggleSearchFilterClose}
            onKeyPress={this.handleToggleSearchFilterClose}
            tabIndex={0}
            data-tracking-click="filter-cbox"
          >
            {value}
          </li>,
        );
      });
    }
    const addRengeType = () => {
      // @ts-ignore TS2339
      if (this.props.filter.type === 'renge') {
        // @ts-ignore TS2339
        if (this.props.rengeType === 'from') return ' From:';
        // @ts-ignore TS2339
        else if (this.props.rengeType === 'to') return ' To:';
      }
      return null;
    };
    return (
      <div className="filter-menu" key={`filter-menu`}>
        <div className="title filter-title">
          <div
            key="search-filter-back"
            onClick={this.handleToggleSearchFilterClose}
            // @ts-ignore TS2322
            value={'close'}
            className="filter-menu_back"
            onKeyPress={this.handleToggleSearchFilterClose}
            tabIndex={0}
          >
            <i className="fa fa-angle_left" />
            戻る
          </div>
          {/*
           // @ts-ignore TS2339 */}
          {this.props.filter.label}
          {addRengeType()}
        </div>
        <ul>{node}</ul>
      </div>
    );
  }
}

class SearchFilter extends Component {
  static getPaths = function(models, options, props = {}) {
    let paths = [];
    paths = paths.concat(FilterSearchGenre.getPaths(models, options, props));
    return paths;
  };

  static get defaultProps() {
    return {
      searchFilters: [],
    };
  }

  static get contextTypes() {
    return {
      history: PropTypes.object,
      routeHandler: PropTypes.object,
      models: PropTypes.object,
      i18n: PropTypes.object,
      i18nContext: PropTypes.string,
      getModelData: PropTypes.func.isRequired,
      service: PropTypes.string,
    };
  }

  static getSelectFilters = (searchFilter, filterType) => {
    const filters = {};
    if (!searchFilter) return filters;
    const searchFilters = searchFilter.split('|');
    _.forEach(searchFilters, filter => {
      const [key, val] = filter.split(':');
      filters[key] = val && -1 !== val.indexOf(',') ? val.split(',') : [val];
    });
    //if (filterType == 's') {
    //  // 視聴方法
    //  if (!filters.r) filters.r = [];
    //  if (!_.includes(filters.r, 's')) filters.r.push('s');
    //}
    //if (filterType == 't') {
    //  // 視聴方法
    //  if (!filters.r) filters.r = [];
    //  if (!_.includes(filters.r, 't')) filters.r.push('t');
    //}
    return filters;
  };

  static getSearchValues = (selectFilters, searchFilters, context) => {
    if (!searchFilters) searchFilters = SearchFilter.defaultProps.searchFilters;
    const searchValues = [];
    _.forEach(searchFilters, filter => {
      if (selectFilters[filter.key]) {
        const values = [];
        if (filter.type === 'renge') {
          const value = selectFilters[filter.key];
          let [from, to] = _.split(value, '_');
          if (!from) from = '指定なし';
          if (!to) to = '指定なし';
          values.push({ item: { key: value }, value: from + '-' + to });
          searchValues.push({ filter, values });
        } else {
          _.forEach(filter.values, item => {
            let value = item.name;
            let key = item.id ? item.id : item.key;
            key = '' + key;
            if (
              (filter.key !== 'p' && _.includes(selectFilters[filter.key], key)) ||
              (filter.key === 'p' && key !== 'a' && _.includes(selectFilters[filter.key], key))
            ) {
              values.push({ item, value });
            }
          });
          if (values.length > 0) {
            searchValues.push({ filter, values });
          }
        }
      }
    });
    return searchValues;
  };

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2551
    this.searchFilterRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleToggleSearchFilter = this.handleToggleSearchFilter.bind(this);
    this.handleToggleSearchFilterClose = this.handleToggleSearchFilterClose.bind(this);
    this.handleClickRemove = this.handleClickRemove.bind(this);
    this.reset = this.reset.bind(this);
    this.search = this.search.bind(this);

    this.state = SearchFilter.getSelectFilters(props.searchFilter, props.filterType);
    // @ts-ignore TS2339
    this.state.open = false;

    // デフォルト設定
    if (!this.state['production']) this.state['production'] = '_2021';

    // @ts-ignore TS2551
    this.searchFilters = [
      {
        label: 'ジャンル',
        multi: false,
        key: 'genre',
        type: 'select',
        values: {},
        // }, {
        //   label: '制作年',
        //   multi: false,
        //   key: 'production',
        //   type: 'renge',
        //   renge: {min: 1945, max: new Date().getFullYear()},
        //   values: []
        // }, {
        //   label: '時間',
        //   multi: false,
        //   key: 'duration',
        //   type: 'select',
        //   values: _.get(context.models, 'config.data.search_filter.durations', [])
      },
    ];
  }
  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const newState = {
      // @ts-ignore TS2339
      active: nextContext.routeHandler.route._regex === routes.filter._regex,
    };
    // @ts-ignore TS2339
    if (nextProps.searchFilter != this.props.searchFilter) {
      // @ts-ignore TS2551
      _.map(this.searchFilters, filter => {
        newState[filter.key] = null;
      });
      _.assign(newState, SearchFilter.getSelectFilters(nextProps.searchFilter, nextProps.filterType));
    }
    // @ts-ignore TS2339
    if (!newState.active && this.state.active) {
      this.reset();
    }
    this.setState(newState);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  handleChange() {
    return;
    // @ts-ignore TS2551
    const el = this.searchFilterRef.current;
    const checkboxEl = el.querySelectorAll('input[type="checkbox"]:checked');
    const radioEl = el.querySelectorAll('input[type="radio"]:checked');
    const sf = [];
    const checkbox = {};

    _.forEach(checkboxEl, el => {
      if (!checkbox[el.name]) checkbox[el.name] = [];
      checkbox[el.name].push(el.value);
    });
    _.forEach(checkbox, (value, key) => {
      // @ts-ignore TS2339
      sf.push(`${key}:${value.join(',')}`);
    });
    _.forEach(radioEl, el => {
      sf.push(`${el.name}:${el.value}`);
    });

    const { routeHandler, history } = this.context;
    if (routeHandler.query.sf == sf.join('|')) {
      return null;
    }
    // 視聴方法
    // @ts-ignore TS2339
    if (this.props.filterType == 's' && !_.includes(checkbox.r, 's')) {
      delete routeHandler.query.ft;
    }
    // @ts-ignore TS2339
    if (this.props.filterType == 't' && !_.includes(checkbox.r, 't')) {
      delete routeHandler.query.ft;
    }
    const options = {
      path: routeHandler.path,
      query: _.assign({}, routeHandler.query, {
        sf: sf.join('|'),
      }),
    };
    const to = URLGenerator.createRelative(options);
    // @ts-ignore TS2339
    if (this.props.onChangeQuery) {
      history.replace(to, { norender: true });
      routeHandler.query = options.query;
      // @ts-ignore TS2339
      this.props.onChangeQuery(options.query);
    } else {
      history.replace(to);
    }
  }

  handleRadioChange(e) {
    const newState = {};
    newState[e.target.name] = [e.target.value];
    this.setState(newState, this.handleChange);
  }

  handleCheckboxChange(e) {
    const newState = {};
    let target = _.assign([], this.state[e.target.name]);
    if (e.target.checked) {
      // 「字・吹」は同時に選択させない
      if (e.target.name === 'language') target = [];
      target.push(e.target.value);
      newState[e.target.name] = target;
    } else {
      newState[e.target.name] = target.filter(n => n !== e.target.value);
    }
    this.setState(newState, this.handleChange);
  }

  handleKeyDown(e) {
    if (e.which === 13 || e.keyCode === 13) {
      const newState = {};
      let target = _.assign([], this.state[e.target.name]);
      if (e.target.checked) {
        newState[e.target.name] = target.filter(n => n !== e.target.value);
      } else {
        // 「字・吹」は同時に選択させない
        if (e.target.name === 'language') target = [];
        target.push(e.target.value);
        newState[e.target.name] = target;
      }
      this.setState(newState, this.handleChange);
    }
  }

  handleToggleSearchFilter(e) {
    // @ts-ignore TS2339
    this.setState({ open: !this.state.open });
    // @ts-ignore TS2339
    this.clickKey = e;
  }

  handleToggleSearchFilterClose(value) {
    const newState = { open: false };
    if (value == 'close') {
      //何も返さない
    // @ts-ignore TS2339
    } else if (_.startsWith(this.clickKey, 'production')) {
      const [from, to] = _.split(this.state['production'], '_');
      let production = '_';
      // @ts-ignore TS2339
      if (this.clickKey === 'production-from') production = value + '_' + (to || '');
      // @ts-ignore TS2339
      if (this.clickKey === 'production-to') production = (from || '') + '_' + value;
      if (production === '_') {
        newState['production'] = null;
      } else {
        newState['production'] = production;
      }
    } else if (!value) {
      // @ts-ignore TS2339
      newState[this.clickKey] = null;
    } else {
      // @ts-ignore TS2339
      newState[this.clickKey] = [value];
    }
    this.setState(newState, this.handleChange);
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2551
        let index = this.searchFilters.findIndex(item => item.key == 'genre');
        // @ts-ignore TS2551
        this.searchFilters[index].values = _.clone(
          _.values(
            _.omitBy(_.get(res.json, FilterSearchGenre.getRootPath(this.context.models, {}, props)), (val, key) => {
              return key.indexOf('$') === 0 || key === 'length' || !_.get(val, 'id');
            }),
          ),
        );

        const filter = {};
        // const filters = props.sf.split('|');
        // for (let i=0; i<filters.length; i++) {
        //   const [ key, val ] = filters[i].split(':');
        //   const index = this.items.findIndex(item => item.key == key);
        //   if (index !== -1) {
        //     filter[key] = this.items[index].items.find(item => item.id ? item.id == val : item.key == val);
        //   }
        // }

        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
          filter: filter,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }

  reset() {
    const newState = {};
    // @ts-ignore TS2551
    _.map(this.searchFilters, filter => {
      newState[filter.key] = null;
    });
    this.setState(newState);
  }

  search() {
    const sfList = _.map(
      // @ts-ignore TS2551
      _.filter(this.searchFilters, filter => !_.isEmpty(this.state[filter.key])),
      filter => {
        return filter.key + ':' + (filter.multi ? this.state[filter.key].join(',') : this.state[filter.key]);
      },
    );
    const { routeHandler, history } = this.context;
    // @ts-ignore TS2339
    const to = routes.filter.makePath({ service: this.context.service }, { sf: sfList.join('|') });
    // @ts-ignore TS2339
    if (this.props.enableIncrementalSearch === undefined) {
      window.location.href = to;
      return;
    }
    // @ts-ignore TS2339
    if (routeHandler.route._regex === routes.filter._regex) {
      history.replace(to);
    } else {
      history.push(to);
    }
    // @ts-ignore TS2339
    if (this.props.onSearch) this.props.onSearch();
  }

  handleClickRemove(filterKey, value) {
    const target = _.assign([], this.state[filterKey]);
    this.state[filterKey] = target.filter(n => n !== value);
    const sfList = _.map(
      // @ts-ignore TS2551
      _.filter(this.searchFilters, filter => !_.isEmpty(this.state[filter.key])),
      filter => {
        return filter.key + ':' + (filter.multi ? this.state[filter.key].join(',') : this.state[filter.key]);
      },
    );
    const { history } = this.context;
    // @ts-ignore TS2339
    const to = routes.filter.makePath({ service: this.context.service }, { sf: sfList.join('|') });
    history.replace(to);
    // @ts-ignore TS2339
    if (this.props.onSearch) this.props.onSearch();
  }

  getSearchFilter() {
    return _.map(
      // @ts-ignore TS2551
      _.filter(this.searchFilters, filter => !_.isEmpty(this.state[filter.key])),
      filter => {
        // let not;
        // if (filter.key === 'genre' && _.includes(this.state[filter.key], '11')) {
        //   const items = _.reject(this.searchFilters.find(item => item.key === filter.key).values, ['id', 11]);
        //   not = 'not;' + _.map(items, item => item.id).join(','); // その他(それ以外をnotで検索)
        // } else if (filter.key === 'country' && _.includes(this.state[filter.key], 'OTHER')) {
        //   const items = _.reject(this.searchFilters.find(item => item.key === filter.key).values, ['key', 'OTHER']);
        //   not = 'not;' + _.map(items, item => item.key).join(','); // その他(それ以外をnotで検索)
        // }
        // if (not) return `${filter.key}:${not}`;
        return filter.key + ':' + (filter.multi ? this.state[filter.key].join(',') : this.state[filter.key]);
      },
    );
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');

    // @ts-ignore TS2339
    if (this.props.mode === 'selectedValues') {
      // @ts-ignore TS2339
      const selectFilters = SearchFilter.getSelectFilters(this.props.searchFilter, this.props.filterType);
      // @ts-ignore TS2551
      const searchValues = SearchFilter.getSearchValues(selectFilters, this.searchFilters, this.context);
      const values = _.flatten(
        _.map(searchValues, searchFilter => {
          return _.map(searchFilter.values, row => {
            return {
              filterKey: searchFilter.filter.key,
              key: '' + (row.item.id || row.item.key),
              name: row.value,
            };
          });
        }),
      );
      return (
        <div className="rail show" key={`suggestion`}>
          <div className="suggestion-container filter">
            <span className="label">絞り込み条件：</span>
            <ul>
              {_.map(values, row => {
                return (
                  <li key={`search-value-${row.filterKey}-${row.key}`}>
                    <span className="values">
                      {row.name}
                      {values.length > 1 ? (
                        <i
                          className="fa fa-icon-cross_circle"
                          onClick={e => this.handleClickRemove(row.filterKey, row.key)}
                        ></i>
                      ) : null}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    // @ts-ignore TS2339
    } else if (this.props.assignSearchFilter) {
      const sfList = this.getSearchFilter();
      // @ts-ignore TS2339
      return React.cloneElement(this.props.assignSearchFilter, { searchFilter: sfList.join('|') });
    }

    const children = [];
    const node = [];
    // @ts-ignore TS2551
    const searchFilterNode = this.searchFilters.map(filter => {
      if (filter.key === 'language') {
        _.forEach(filter.values, item => {
          let value = null;
          value = item.id ? item.name : item.name;
          const key = item.id ? '' + item.id : item.key;
          node.push(
            <div className="checkbox-cont" key={`search-filter-${filter.key}-${key}`}>
              <input
                id={`${filter.key}-${key}`}
                name={filter.key}
                type="checkbox"
                value={key}
                checked={_.includes(this.state[filter.key], key)}
                className="check-box-label"
                onChange={this.handleCheckboxChange}
                onKeyDown={this.handleKeyDown.bind(this)}
                tabIndex={0}
              />
              <label className="check-box-label">{value}</label>
            </div>,
          );
        });
        return (
          <div key={`search-filter-${filter.key}`} className="search-filter-box checkbox">
            <div className={classnames('search-filter-box-inner checkbox-inner')}>
              <span className="label">{filter.label}</span>
              <div className="checkbox-box-wrapper">{node}</div>
            </div>
          </div>
        );
      } else if (filter.type === 'renge') {
        const [from, to] = _.split(this.state[filter.key], '_');
        // @ts-ignore TS2339
        if (this.state.open && this.clickKey == `${filter.key}-from`) {
          children.push(
            <FilterList
              // @ts-ignore TS2339
              key={`filter-list-${this.clickKey}`}
              // @ts-ignore TS2322
              filter={filter}
              rengeType={'from'}
              selected={from}
              handleToggleSearchFilterClose={this.handleToggleSearchFilterClose}
            />,
          );
        }
        // @ts-ignore TS2339
        if (this.state.open && this.clickKey == `${filter.key}-to`) {
          children.push(
            <FilterList
              // @ts-ignore TS2339
              key={`filter-list-${this.clickKey}`}
              // @ts-ignore TS2322
              filter={filter}
              rengeType={'to'}
              selected={to}
              handleToggleSearchFilterClose={this.handleToggleSearchFilterClose}
            />,
          );
        }
        let nameFrom = '指定なし';
        let nameTo = '指定なし';
        if (from) nameFrom = from;
        if (to) nameTo = to;
        return (
          <div key={`search-filter-${filter.key}`} className="search-filter-box">
            <div className="search-filter-box-inner">
              <span className="label">{filter.label}</span>
              <div className="search-filter-box-inner__select">
                <span>From:</span>
                <div
                  className="selectedBox"
                  onClick={this.handleToggleSearchFilter.bind(this, `${filter.key}-from`)}
                  onKeyPress={this.handleToggleSearchFilter.bind(this, `${filter.key}-from`)}
                  tabIndex={0}
                >
                  <span>{nameFrom}</span>
                </div>
                <span>To:</span>
                <div
                  className="selectedBox"
                  onClick={this.handleToggleSearchFilter.bind(this, `${filter.key}-to`)}
                  onKeyPress={this.handleToggleSearchFilter.bind(this, `${filter.key}-to`)}
                  tabIndex={0}
                >
                  <span>{nameTo}</span>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        // @ts-ignore TS2339
        if (this.state.open && this.clickKey == filter.key) {
          children.push(
            <FilterList
              // @ts-ignore TS2339
              key={`filter-list-${this.clickKey}`}
              // @ts-ignore TS2322
              filter={filter}
              selected={this.state[filter.key]}
              handleToggleSearchFilterClose={this.handleToggleSearchFilterClose}
            />,
          );
        }
        let name = '指定なし';
        _.forEach(filter.values, item => {
          let value = '';
          value = item.id ? item.name : item.name;
          const key = item.id ? item.id : item.key;
          if (key == this.state[filter.key]) name = value;
        });
        return (
          <div key={`search-filter-${filter.key}`} className="search-filter-box">
            <div className="search-filter-box-inner">
              <span className="label">{filter.label}</span>
              <div
                className="selectedBox"
                onClick={this.handleToggleSearchFilter.bind(this, filter.key)}
                onKeyPress={this.handleToggleSearchFilter.bind(this, filter.key)}
                tabIndex={0}
              >
                <span>{name}</span>
              </div>
            </div>
          </div>
        );
      }
    });

    const sfList = this.getSearchFilter();
    const sf = sfList.join('|');
    let filterList = (
      <React.Fragment key="filterList">
        {searchFilterNode}
        <div className="search-filter-result">
          <div className="search-filter-result-btn-box">
            <button className="btn btn-line btn-very-small" onClick={this.reset} name="search-clear">
              クリア
            </button>
            <button
              className={classnames('btn btn-fill btn-very-small', { disabled: !sf })}
              onClick={this.search}
              name="search-submit"
            >
              この条件で検索
            </button>
          </div>
        </div>
      </React.Fragment>
    );

    let list = [];
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      // @ts-ignore TS2339
      if (!this.state.open) {
        list.push(filterList);
      } else {
        list.push();
      }
    } else {
      list.push(filterList);
    }
    return (
      // @ts-ignore TS2551
      <div ref={this.searchFilterRef} className="search-filter-contents" key={`filter-contents`}>
        <div className="title filter-title">ジャンルで絞り込み</div>
        {list}
        {children}
      </div>
    );
  }
}
export default SearchFilter;
