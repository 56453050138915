import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import { TransitionGroup } from 'react-transition-group';
import classnames from 'classnames';

import Row from './Row';
import TitleCard from './TitleCard';
import BoxCard from './BoxCard';
import AdvertisingCard from './AdvertisingCard';
import Ranking from './Ranking';
import LoadingListTitle from '../loader/LoadingListTitle';
import LoadingGallery from '../loader/LoadingGallery';
import EpgEvent from './Epg/EpgEvent';
import CardContext, { CardContextProvider } from '../../../common/context/CardContext';
import { CONTENT_EVENTS, CLICK_AREA } from '../../../../common/GtmApp';

import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
import * as DOMUtils from '../../../../sketch-platform/utils/DOMUtils';
import EventCard from './Epg/EventCard';
import { PAGING_INITIAL_KEY, USE_LAST_EVALUATED_KEY } from '../../../../constants';

const indexes = function(models, options = {}, props) {
  return {
    from: (props && props.fromNum) || 0,
    to:
      props && props.toNum
        ? props.toNum
        : // @ts-ignore TS2339
        options && options.numTitles
        ? // @ts-ignore TS2339
          options.numTitles + (props.fromNum || 0)
        : props.perPage - 1,
  };
};
export default class GalleryContent extends React.Component {
  static getPaths = function(models, options, props) {
    const rootPath = this.getRootPath(models, options, props);
    // palette.123.objects は、Range指定があるrouteと無いrouteがある
    // そのため、Range指定無し(palette.123.objects)で取得した後に、Range指定あり(palett.123.objects{from:0, to:1})で取得しようとすると、
    // palette.123.objectsの$typeがatomになり、palette.123.objects.lengthが取得できなくなる
    // その対策として、palette.123.objectsのパスのキャッシュを解放して取り直させてやる
    const model = props.pathEvaluator || _.get(props.model, 'pathEvaluator');
    if (props.keyPrefix == 'palette' && props.modelRoot == 'objects' && model) {
      const jsongCache = model.getCache(rootPath);
      if (_.get(jsongCache, `${rootPath.join('.')}.$type`) == 'atom') {
        model.invalidate(rootPath);
      }
    }
    let Card = _.get(props, 'cardComponent') || TitleCard;
    let paths = Card.getPaths().map(function(path) {
      return rootPath.concat(indexes(models, options, props)).concat(path);
    });
    paths = paths.concat(
      // @ts-ignore TS2339
      AdvertisingCard.getPaths().map(function(path) {
        return rootPath.concat(indexes(models, options, props)).concat(path);
      }),
    );
    if (_.includes(USE_LAST_EVALUATED_KEY, props.keyPrefix)) {
      paths = paths.concat([rootPath.concat(['lastEvaluatedKey'])]);
    }
    const lengthPath = rootPath.concat(['length']);
    return ((props && props.fromNum) || 0) === 0 ? paths.concat([lengthPath]) : paths;
  };

  static getRootPath = function(models, options, props) {
    const rootPath = [];
    if (props.keyPrefix) rootPath.push(props.keyPrefix);
    if (!!props.id) rootPath.push(props.id);
    if (!!props.term) {
      rootPath.push('byTerm');
      rootPath.push(`|${props.term}`);
    }
    if (props.modelRoot) rootPath.push(props.modelRoot);
    if (props.hierarchyTypeId) rootPath.push(props.hierarchyTypeId);
    if (props.hierarchyTypeKey) rootPath.push(props.hierarchyTypeKey);
    if (props.genres != null) rootPath.push(props.genres);
    if (props.defaultContents != null) rootPath.push(props.defaultContents);
    if (props.entitled) rootPath.push(props.entitled);
    if (props.filterType) rootPath.push(props.filterType);
    if (props.sortOrder) rootPath.push(props.sortOrder);
    if (props.period) {
      rootPath.push(props.period);
      rootPath.push(Ranking.getAttributeFilter(props));
    }
    if (props.searchFilter) rootPath.push([props.searchFilter]);
    if (_.includes(USE_LAST_EVALUATED_KEY, props.keyPrefix)) {
      rootPath.push(props.lastEvaluatedKey || PAGING_INITIAL_KEY);
      const { from, to } = indexes(models, options, props);
      rootPath.push(to - from + 1);
    }
    return rootPath;
  };

  static get propTypes() {
    return {
      filter: PropTypes.func,
      header: PropTypes.element,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isPrefetchingPaths: PropTypes.bool,
      keyPrefix: PropTypes.string,
      model: PropTypes.object,
      query: PropTypes.array,
      sortOrder: PropTypes.string,
      hierarchyTypeId: PropTypes.number,
      filterType: PropTypes.string,
      uiView: PropTypes.string,
      enablePushOut: PropTypes.bool,
      classes: PropTypes.object,
      perPage: PropTypes.number,
      period: PropTypes.oneOf(['daily', 'weekly', 'monthly']),
      genreId: PropTypes.number,
      personId: PropTypes.number,
      teamId: PropTypes.number,
      leagueId: PropTypes.number,
      studioId: PropTypes.number,
      showPopCardMylistButton: PropTypes.bool,
      hideAllWhenNoResults: PropTypes.bool,
      mbListLayout: PropTypes.string,
    };
  }

  static get defaultProps() {
    return {
      perPage: 60, // paletteのオブジェクト取得APIは、from, toに対応していない為、falcor routerのlimit値に合わせる必要あり。
      cardComponent: TitleCard,
      showPopCardMylistButton: true,
      lazyload: true,
    };
  }

  static get contextTypes() {
    return {
      columnsInRow: PropTypes.number,
      isInitialRender: PropTypes.bool,
      isTallRow: PropTypes.bool,
      routeHandler: PropTypes.object,
      popType: PropTypes.string,
      getModelData: PropTypes.func,
      models: PropTypes.object,
      artType: PropTypes.string,
      gtmApp: PropTypes.object,
      titleType: PropTypes.string,
    };
  }

  static get childContextTypes() {
    return {
      deleteApp: PropTypes.object,
      columnsInRow: PropTypes.number,
    };
  }

  getChildContext() {
    return {
      // @ts-ignore TS2339
      deleteApp: this._deleteApp,
      // @ts-ignore TS2339
      columnsInRow: !!this.props.listType ? 1 : this.context.columnsInRow,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.galleryRef = React.createRef();
    this.onScroll = this.onScroll.bind(this);
    this.onScrollEnd = this.onScrollEnd.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);
    this.checkOrientation = _.throttle(this.checkOrientation.bind(this), 300, { leading: true, trailing: true });

    if (!props.children) {
      // @ts-ignore TS2339
      const rootPath = this.constructor.getRootPath(context.models, null, props);
      // @ts-ignore TS2339
      this.isFirstSetOfResults = true;
      // @ts-ignore TS2339
      this.numTitlesToFetch = props.perPage;

      // @ts-ignore TS2339
      this._deleteApp = props.deleteApp;

      // palette.123.objects は、Range指定があるrouteと無いrouteがある
      // そのため、Range指定無し(palette.123.objects)で取得した後に、Range指定あり(palett.123.objects{from:0, to:1})で取得しようとすると、
      // palette.123.objectsの$typeがatomになり、palette.123.objects.lengthが取得できなくなる
      // その対策として、palette.123.objectsのパスのキャッシュを解放して取り直させてやる
      if (props.keyPrefix == 'palette' && props.modelRoot == 'objects') {
        const jsongCache = props.model.getCache(rootPath);
        if (_.get(jsongCache, `${rootPath.join('.')}.$type`) == 'atom') {
          props.model.invalidate(rootPath);
        }
      } else {
        // @ts-ignore TS2339
        this.totalCount = props.model.getSync(rootPath.concat('length'));
      }

      this.state = {
        dispose: null,
        fetchDataError: null,
        generation: props.model.getVersion(rootPath),
        key: parseInt(props.id, 10),
        isPortrait: false,
      };
    } else {
      // @ts-ignore TS2339
      this.totalCount = props.totalItems;
      this.state = {};
    }
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      this.checkOrientation();
    }
    this.handleDeleteOnRefresh = this.handleDeleteOnRefresh.bind(this);
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    if (!this.props.children) {
      this.fetchData(this.props);
      // @ts-ignore TS2339
      if (this.props.lazyload) {
        browserEvents.addEventListener('scroll', this.onScroll);
        browserEvents.addEventListener('scrollEnd', this.onScrollEnd);
      }
    }
    // @ts-ignore TS2339
    if (this._deleteApp) {
      // @ts-ignore TS2339
      this._deleteApp.on('onRefresh', this.handleDeleteOnRefresh);
    }
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.checkOrientation);
    }
    this.checkOrientation();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.children) {
      return;
    }

    if (
      // @ts-ignore TS2339
      nextProps.sortOrder !== this.props.sortOrder ||
      // @ts-ignore TS2339
      nextProps.filterType !== this.props.filterType ||
      // @ts-ignore TS2339
      nextProps.searchFilter !== this.props.searchFilter ||
      // @ts-ignore TS2339
      nextProps.id !== this.props.id ||
      // @ts-ignore TS2339
      nextProps.term !== this.props.term ||
      // @ts-ignore TS2339
      nextProps.period !== this.props.period ||
      // @ts-ignore TS2339
      nextProps.keyPrefix !== this.props.keyPrefix ||
      // @ts-ignore TS2339
      nextProps.hierarchyTypeKey !== this.props.hierarchyTypeKey ||
      // @ts-ignore TS2339
      nextProps.entitled !== this.props.entitled
    ) {
      // @ts-ignore TS2339
      this.isFirstSetOfResults = true;
      // @ts-ignore TS2339
      this.numTitlesToFetch = this.props.perPage;
      // @ts-ignore TS2339
      this.totalCount = -1;
      // @ts-ignore TS2339
      this.items = [];
      // @ts-ignore TS2339
      delete this.lastEvaluatedKey;
      this.setState({ fetchDataError: null, generation: -1 }, () => {
        this.fetchData(nextProps);
      });
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
    // @ts-ignore TS2339
    if (!this.props.children || this.props.lazyload) {
      browserEvents.removeEventListener('scroll', this.onScroll);
      browserEvents.removeEventListener('scrollEnd', this.onScrollEnd);
    }
    // @ts-ignore TS2339
    if (this._deleteApp) {
      // @ts-ignore TS2339
      this._deleteApp.off('onRefresh', this.handleDeleteOnRefresh);
    }
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.checkOrientation);
    }
  }

  checkOrientation() {
    if (typeof window === 'undefined') return;

    const browserInfo = this.context.getModelData('browserInfo');
    if (!(browserInfo.isIOS || browserInfo.isAndroid)) {
      this.setState({ isPortrait: true });
    } else {
      let angle = screen && screen.orientation && screen.orientation.angle;
      let isPortrait = false;

      if (angle === undefined) {
        angle = window.orientation;

        if (angle === undefined) {
          if (window.innerHeight > window.innerWidth) {
            angle = 0;
          } else {
            angle = 90;
          }
        }
      }
      isPortrait = angle === 0;

      // @ts-ignore TS2339
      if (this._isMounted) {
        this.setState({ isPortrait });
      } else {
        // @ts-ignore TS2339
        this.state.isPortrait = isPortrait;
      }
    }
  }

  fetchMoreRows() {
    if (this.props.children) {
      return;
    }

    // @ts-ignore TS2339
    if (this.items && this.numTitlesToFetch > this.items.length && !this.state.fetchingMoreRows) {
      // @ts-ignore TS2339
      this.state.fetchingMoreRows = true;
      this.fetchData(this.props);
    }
  }

  isLoading() {
    // @ts-ignore TS2339
    return !!this.state.dispose || this.props.isPrefetchingPaths;
  }

  handleDeleteOnRefresh() {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    // 取り直す
    this.setState({ fetchDataError: null, generation: -1 }, () => {
      // @ts-ignore TS2339
      this.isFirstSetOfResults = true;
      // @ts-ignore TS2339
      this.numTitlesToFetch = this.props.perPage;
      // @ts-ignore TS2339
      this.totalCount = -1;
      // @ts-ignore TS2339
      this.items = [];
      // @ts-ignore TS2339
      delete this.lastEvaluatedKey;
      this.fetchData(this.props);
    });
  }

  handleItemClick(item) {
    this.sendToGtm(item);
  }

  sendToGtm(item) {
    if (!_.get(this.context, 'gtmApp')) return;
    const channel = _.get(item, 'linearChannelMeta');
    const hasRelationProgram = _.get(item, 'seriesMeta') && _.get(item, 'seasonMeta');
    const isGroupMeta = _.get(item, 'type') === 'group';
    const relationProgram = hasRelationProgram
      ? {
          refId: _.get(item, 'seriesMeta.refId'),
          name: _.get(item, 'seriesMeta.name'),
        }
      : null;
    const program = isGroupMeta
      ? {
          refId: _.get(item, 'refId'),
          name: _.get(item, 'name'),
        }
      : {
          refId: _.get(item, 'seasonMeta.refId') || _.get(item, 'seriesMeta.refId'),
          name: _.get(item, 'seasonMeta.name') || _.get(item, 'seriesMeta.name'),
        };
    const content = isGroupMeta
      ? null
      : _.isEmpty(channel)
      ? {
          refId: _.get(item, 'refId'),
          name: _.get(item, 'name'),
          rental: _.get(item, 'rental'),
          subscription: _.get(item, 'subscription'),
        }
      : {
          refId: _.get(item, 'uniqueId'),
          name: _.get(item, 'name'),
        };
    const ad = {
      id: _.get(item, 'advertisingId'),
      name: _.get(item, 'name'),
    };
    const attributes = _.get(item, 'attributes');
    const genres = _.get(item, 'genres');
    const middleGenres = _.get(item, 'middleGenres');
    const schemaId = _.get(item, 'schemaId');
    this.context.gtmApp.pushDataLayerOnContentPageClick(
      CONTENT_EVENTS.CONTENT_CLICK,
      { relationProgram, program, content, channel, attributes, genres, middleGenres, schemaId, ad },
      { clickArea: CLICK_AREA.LIST },
    );
  }

  render() {
    let spinLoader;
    // @ts-ignore TS2339
    if (this.state.fetchingMoreRows === true) {
      spinLoader = (
        <div className="gallery-spin-loader">
          <div className="loader-cont"></div>
        </div>
      );
    }

    // @ts-ignore TS2339
    let items = this.items || [];
    // @ts-ignore TS2339
    let totalCount = this.totalCount;
    // @ts-ignore TS2339
    const ignoreIds = this.props.ignoreIds ? _.compact(this.props.ignoreIds) : [];
    if (!_.isEmpty(ignoreIds)) {
      let ignoredCount = 0;
      items = _.filter(items, item => {
        if (_.isNil(item)) return false;
        let flag = !ignoreIds.includes(item.id);
        if (!flag) ignoredCount++;
        return flag;
      });
      if (ignoredCount > 0 && totalCount > 0) {
        totalCount = totalCount - ignoredCount;
      }
    }

    let cards;
    if (this.props.children) {
      // @ts-ignore TS2769
      cards = _.map(this.props.children, child => {
        // @ts-ignore TS2769
        return React.cloneElement(child);
      });
    } else {
      cards = _.compact(
        _.map(items || [], (itemData, index) => {
          if (!itemData.id && !itemData.uniqueId) return;
          // @ts-ignore TS2339
          let Card = this.props.cardComponent;
          // @ts-ignore TS2339
          if (this.props.mbListLayout == 'box') {
            Card = BoxCard;
          } else if (itemData.advertisingId) {
            Card = AdvertisingCard;
          } else if (itemData.uniqueId) {
            Card = EventCard;
            // @ts-ignore TS2554
            itemData = EpgEvent.additionalData(itemData);
          }
          return (
            <Card
              // @ts-ignore TS2339
              popType={this.props.listType ? 'none' : this.context.popType}
              // @ts-ignore TS2339
              model={this.props.model}
              key={`title_${itemData.id}_${index}`}
              rowNum={0}
              rankNum={index}
              studioId={itemData.id}
              titleId={itemData.id}
              episodeId={itemData.id}
              advertisngId={itemData.id}
              eventId={itemData.uniqueId}
              itemData={itemData}
              // @ts-ignore TS2339
              listType={this.props.listType}
              // @ts-ignore TS2339
              listCard={this.props.listCard}
              // @ts-ignore TS2339
              mbListLayout={this.props.mbListLayout}
              // @ts-ignore TS2339
              isMediaOnly={this.props.titleDisplaySetting === 'media_only' ? 'media-only' : null} // SpSliderのrenderChildrenメソッドで利用
              // @ts-ignore TS2339
              titleDisplaySetting={this.props.titleDisplaySetting || 'default'}
              // @ts-ignore TS2339
              showPopCardMylistButton={this.props.showPopCardMylistButton}
              // @ts-ignore TS2339
              deliveryStartVisible={this.props.deliveryStartVisible}
              // @ts-ignore TS2339
              showEpisodeNumber={this.props.showEpisodeNumber}
              // @ts-ignore TS2339
              showSynopsis={this.props.showSynopsis}
              // @ts-ignore TS2339
              showViewableEndAt={this.props.showViewableEndAt}
              // @ts-ignore TS2339
              keyPrefix={this.props.keyPrefix}
              // @ts-ignore TS2339
              listContext={this.props.listContext}
            />
          );
        }),
      );
    }

    const size = cards.length;
    // @ts-ignore TS2339
    const chunckSize = this.props.itemsInRow || this.context.columnsInRow; // いくつずつに分割するか
    const cardChuncks = []; // 新しく作る配列

    for (let i = 0; i < Math.ceil(size / chunckSize); i++) {
      const j = i * chunckSize;
      const chunckedTitles = cards.slice(j, j + chunckSize);
      cardChuncks.push(chunckedTitles);
    }

    const rows = _.map(cardChuncks, (chunck, index) => {
      return (
        <Row
          // @ts-ignore TS2322
          isOnAir={_.get(_.first(chunck), 'props.itemData.isOnAir')}
          key={`gallery-row-${index}`}
          totalItems={chunck.length}
          rowNum={index}
          enablePeek={true}
          enablePaginationIndicator={false}
          enablePushOut={false}
          handleItemClick={this.handleItemClick}
          // @ts-ignore TS2339
          mbListLayout={this.props.mbListLayout}
          // @ts-ignore TS2339
          liveSchedule={this.props.liveSchedule}
          // @ts-ignore TS2339
          deliveryStartVisible={this.props.deliveryStartVisible}
        >
          {chunck}
        </Row>
      );
    });

    let content;

    // @ts-ignore TS2339
    if (this.isLoading() && this.isFirstSetOfResults && cardChuncks.length === 0) {
      // @ts-ignore TS2339
      if (this.props.listType) {
        let loadingListTitle = [];
        for (let i = 0; i < 4; i++) {
          loadingListTitle.push(
            <LoadingListTitle
              pulsate={true}
              displayWhenNotPulsing={false}
              lockupClassName={`card episode-card`}
              key={`loading-row-${i}`}
            />,
          );
        }
        content = loadingListTitle;
      } else {
        content = (
          <LoadingGallery
            pulsateTitles={true}
            // @ts-ignore TS2339
            isPortrait={this.state.isPortrait}
            // @ts-ignore TS2339
            mbListLayout={this.props.mbListLayout}
          />
        );
      }
    } else if (rows.length > 0) {
      content = (
        // @ts-ignore TS2322
        <div className="pulsate-transition" timeout={{ exit: 550, enter: 250 }}>
          <div key="main-gallery">{rows}</div>
          {spinLoader}
        </div>
      );
      // @ts-ignore TS2339
    } else if ((totalCount == 0 || this.props.liveSchedule) && !this.props.hideNoResults) {
      // ライブスケジュールではメタがないのにtotalcountがあるケースがある
      // @ts-ignore TS2339
      if (this.props.hideAllWhenNoResults) return null;
      // @ts-ignore TS2339
      if (this.props.noResults) {
        content = (
          // @ts-ignore TS2322
          <div className="pulsate-transition" timeout={{ exit: 550, enter: 250 }}>
            {/*
             // @ts-ignore TS2339 */}
            {this.props.noResults}
          </div>
        );
      } else {
        content = (
          // @ts-ignore TS2322
          <div className="pulsate-transition" timeout={{ exit: 550, enter: 250 }}>
            <div>
              <div className="no_img" />
              <div className="gallery-message">登録されている作品がありません。</div>
            </div>
          </div>
        );
      }
    }

    // @ts-ignore TS2339
    let header = this.props.header;
    // @ts-ignore TS2339
    if (this.props.headerWithSwitchGallery) {
      // @ts-ignore TS2339
      header = <this.props.headerWithSwitchGallery showSwitchGallery={rows.length > 1} />;
    }
    // @ts-ignore TS2339
    let tabHeader = this.props.tabHeader;
    const browserInfo = this.context.getModelData('browserInfo');

    return (
      <div
        // @ts-ignore TS2339
        ref={this.galleryRef}
        className={classnames(
          'gallery',
          {
            // @ts-ignore TS2339
            'row-with-x-columns': !this.props.listType,
            // @ts-ignore TS2339
            small: this.props.titleSmallSize,
            titleHidden:
              this.context.titleType == 'hidden' ||
              // @ts-ignore TS2339
              this.props.titleDisplaySetting == 'media_only' ||
              // @ts-ignore TS2339
              this.props.titleDisplaySetting == 'on_card',
          },
          // @ts-ignore TS2339
          this.props.classes,
        )}
      >
        {header}
        {tabHeader}
        {content ? (
          <React.Fragment>
            <TransitionGroup className="gallery-content">
              <CardContextProvider value={this.getChildContext()}>{content}</CardContextProvider>
            </TransitionGroup>
          </React.Fragment>
        ) : null}
        {/*
         // @ts-ignore TS2339 */}
        {this.props.footer}
      </div>
    );
  }

  fetchData(props, context = this.context) {
    if (this.props.children) return;

    let beforeItemLength = props.fromNum ? props.fromNum : 0;
    // @ts-ignore TS2339
    let toNum = props.toNum ? props.toNum : this.numTitlesToFetch;
    // @ts-ignore TS2339
    if (this.items) {
      // @ts-ignore TS2339
      beforeItemLength = beforeItemLength + this.items.length;
    }
    const pathProps = Object.assign({}, props, {
      fromNum: beforeItemLength,
      toNum: toNum - 1,
      // @ts-ignore TS2339
      lastEvaluatedKey: this.lastEvaluatedKey,
    });

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(context.models, {}, pathProps);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, pathProps);
    // すでに通信している場合は実行しない
    // @ts-ignore TS2339
    if (!this.isFirstSetOfResults && this.state[JSON.stringify(paths)]) return;

    //初回以外でキーがないものは全て取得済みなので通信しない
    // if (_.includes(USE_LAST_EVALUATED_KEY, props.keyPrefix) && !this.isFirstSetOfResults && !this.lastEvaluatedKey) {
    //   return;
    // }
    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    // 事前に通信している場合、その情報は全て削除してしまったほうが良い
    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (!this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }

    evaluator
      .then(result => {
        if (result) {
          const data = _.get(result.json, rootPath);
          let items = _.clone(
            _.values(
              _.omitBy(data, (__, key) => key.indexOf('$') === 0 || key === 'length' || key === 'lastEvaluatedKey'),
            ),
          );
          let rejectCount = 0;
          items = _.reject(
            _.map(_.clone(items), item =>
              _.omitBy(
                item || [],
                (__, key) => key.indexOf('$') === 0 || key === 'length' || key === 'lastEvaluatedKey',
              ),
            ),
            item => {
              if (_.isEmpty(item || {})) {
                rejectCount++;
                return true;
              }
              return false;
            },
          );
          if (beforeItemLength > 0) {
            // @ts-ignore TS2339
            this.items = this.items.concat(items);
          } else {
            // @ts-ignore TS2339
            this.items = items;
          }
          if (data.hasOwnProperty('length')) {
            // @ts-ignore TS2339
            this.totalCount = data.length;
          }
          // @ts-ignore TS2339
          if (rejectCount && this.totalCount) {
            // @ts-ignore TS2339
            this.totalCount = this.totalCount - rejectCount;
          }
          if (_.includes(USE_LAST_EVALUATED_KEY, props.keyPrefix)) {
            // @ts-ignore TS2339
            this.lastEvaluatedKey = _.get(data, 'lastEvaluatedKey');
            // @ts-ignore TS2339
            if (!this.lastEvaluatedKey) {
              // 全件取得済み
              // @ts-ignore TS2339
              this.totalCount = this.items ? this.items.length : 0;
              // @ts-ignore TS2339
              this.numTitlesToFetch = this.totalCount;
            }
          }
        } else {
          // @ts-ignore TS2339
          this.totalCount = this.items.length;
        }
        // @ts-ignore TS2339
        if (this.props.onFetchedCount) this.props.onFetchedCount(this.totalCount);

        // @ts-ignore TS2339
        this.numTitlesToFetch += props.perPage;
        // @ts-ignore TS2339
        if (this.numTitlesToFetch > this.totalCount) this.numTitlesToFetch = this.totalCount;
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        this.isFirstSetOfResults = false;
        const newState = {
          fetchDataError: null,
          dispose: null,
          fetchingMoreRows: undefined,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
        };
        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState(newState, () => {
            // 無限ループすることがあるので、データを取得できなかった時は
            // @ts-ignore TS2339
            if (beforeItemLength >= this.items.length) {
              // @ts-ignore TS2339
              this.numTitlesToFetch = this.items.length;
            }
            // @ts-ignore TS2554
            this.onScroll();
          });
        } else {
          Object.assign(this.state, newState);
        }
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = newState;
      });
  }

  onScroll(e) {
    // @ts-ignore TS2339
    if (!this.props.lazyload) return;
    if (this.isWithinDistanceBuffer(300)) {
      // @ts-ignore TS2554
      this.fetchMoreRows(true);
    }
  }

  onScrollEnd(e) {}

  isWithinDistanceBuffer(distanceBuffer) {
    // @ts-ignore TS2339
    if (!this.galleryRef) return;
    // @ts-ignore TS2339
    const el = this.galleryRef.current;
    if (el) {
      return DOMUtils.getDistanceToBottomOfElement(el) < distanceBuffer;
    }
    return false;
  }
}
