import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import isAccountOwnerProfile from '../../../../../../utils/isAccountOwnerProfile';

const AccountOwnerOnlyView = (props, context) => {
  // if (isAccountOwnerProfile(context.models)) {
  if (true) {
    return props.children;
  } else {
    return (
      <div>
        <div>ご契約・ご購入は、アカウントオーナーのプロフィールに切り替えて行ってください。</div>
        {props.handleCancel ? (
          <div className="paymentsubmit-area">
            <button
              className={classnames('paymentsubmit-button', 'btn', 'btn-gray')}
              // @ts-ignore TS2322
              tabIndex="0"
              role="button"
              onClick={props.handleCancel}
            >
              キャンセルする
            </button>
          </div>
        ) : null}
      </div>
    );
  }
};

AccountOwnerOnlyView.contextTypes = {
  getModelData: PropTypes.func,
  models: PropTypes.object,
};

AccountOwnerOnlyView.propTypes = {
  handleCancel: PropTypes.func,
};

export default AccountOwnerOnlyView;
