import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import { Helmet } from 'react-helmet';

import Canvas from './Canvas';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import routes from '../../../common/routes';
import JsonLd from '../../../common/components/JsonLd';

class Curation extends Component {
  static getPaths = function(models, options, props) {
    return Canvas.getPaths(models, options, {
      canvasId: this.getCanvasId(models, options, props),
    });
  };

  static getCanvasId = function(models, options = {}, props = {}) {
    return _.get(models, 'config.data.canvas.curation');
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      routeHandler: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    const rootPath = Canvas.getRootPath(
      context.models,
      {},
      Object.assign({}, props, {
        // @ts-ignore TS2339
        canvasId: this.constructor.getCanvasId(context.models),
      }),
    );
    // @ts-ignore TS2339
    const canvas = this.model.getSync(rootPath);
    // @ts-ignore TS2339
    this.canvasName = _.get(canvas, 'name');
    this.sendToGtm = this.sendToGtm.bind(this);
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.sendToGtm();
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-ignore TS2339
    this.context.gtmApp.pageView(this.canvasName);
  }

  render() {
    // @ts-ignore TS2339
    const canvasId = this.constructor.getCanvasId(this.context.models, {}, this.props);
    if (!canvasId) return null;
    const links = [];
    const host = this.context.getModelData('hosts', 'host');
    // @ts-ignore TS2554
    const url = routes.curation.makePath();
    links.push({ rel: 'canonical', href: host + url });
    const jsonLdProps = {
      breadcrumbList: {
        // @ts-ignore TS2339
        itemListElement: [{ name: this.canvasName, item: host + url }],
      },
    };

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ title }) => {
            const metas = [];

            // @ts-ignore TS2339
            if (this.canvasName) {
              // @ts-ignore TS2339
              metas.push({ property: 'og:title', content: title(this.canvasName) });
            }

            // @ts-ignore TS2339
            return <Helmet title={this.canvasName} meta={metas} link={links} />;
          }}
        </HtmlContext.Consumer>
        <JsonLd {...jsonLdProps} />
        {/*
         // @ts-ignore TS2339 */}
        <Canvas {...this.props} model={this.model} canvasId={canvasId} channelNameHidden={true} />
      </React.Fragment>
    );
  }
}

export default withLayoutContext(Curation);
