import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';

import EpisodesRow from './titlemeta/EpisodesRow';
// import RowHeader from './RowHeader';
import DropDown from '../../../common/components/DropDown';
import LoadingListTitle from '../loader/LoadingListTitle';
import { CardContextProvider } from '../../../common/context/CardContext';
import { CONTENT_EVENTS } from '../../../../common/GtmApp';

export default class SeasonRow extends Component {
  static getPaths = function(models, options, props) {
    let paths = this.getRootPath(models, options, props);
    paths = [
      paths.concat([
        [
          'id',
          'refId',
          'name',
          'schemaId',
          'slug',
          'hierarchyTypes',
          'seasons',
          'languageSupportTypes',
          'attributes',
          'genres',
          'middleGenres',
          'rental',
          'subscription',
        ],
      ]),
    ];
    paths = paths.concat(EpisodesRow.getPaths(models, options, { id: props.id }));
    return paths;
  };

  static getRootPath = function(models, options, props) {
    return props.id ? ['meta', props.id] : [];
  };

  static get contextTypes() {
    return {
      seasonId: PropTypes.number,
      columnsInRow: PropTypes.number,
      routeHandler: PropTypes.object,
      getModelData: PropTypes.func,
      models: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  static OTHER = -1;

  static get childContextTypes() {
    return {
      allSeasonCount: PropTypes.number,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.othersSeasonHierarchyTypeList = [];

    this.onSeasonChange = this.onSeasonChange.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);

    this.state = {
      selectedSeasonIndex: 0,
      changeSeason: false,
      dispose: null,
      generation: -1,
      fetchDataError: null,
    };
  }

  getChildContext() {
    return {
      // @ts-ignore TS2339
      allSeasonCount: _.get(this.item, 'seasons', []).length,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }
  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  isLoading() {
    // @ts-ignore TS2339
    return !!this.state.dispose;
  }
  searchSeasonIndex(seasons, seasonId) {
    if (!Array.isArray(seasons) || !seasonId) return 0;
    const newIndex = seasons
      .filter(season => !!season.id)
      .map(season => season.id)
      .indexOf(seasonId);
    if (newIndex == -1) {
      // データ不備の可能性あり
      // 0を返却して再生はできるようにする
      return 0;
    }
    return newIndex;
  }

  onSeasonChange(e) {
    // @ts-ignore TS2339
    const newSeasonId = e == this.constructor.OTHER ? this.constructor.OTHER : e === 'all' ? null : parseInt(e, 10);

    // @ts-ignore TS2339
    if (this.seasonsWithOther) {
      // @ts-ignore TS2339
      const newIndex = this.searchSeasonIndex(this.seasonsWithOther, newSeasonId);
      // @ts-ignore TS2339
      if (this.state.selectedSeasonIndex != newIndex) {
        // @ts-ignore TS2339
        if (this.props.onChangeQuery) {
          // @ts-ignore TS2339
          this.props.onChangeQuery({ season_id: newSeasonId });
        }
        this.setState({ selectedSeasonIndex: newIndex, changeSeason: true }, async () => {
          if (newSeasonId > 0) {
            const season = await this.retrieveSeasonById(newSeasonId);
            this.sendToGtm(season);
          }
        });
      }
    }
  }

  sendToGtm(seasonMeta) {
    if (!_.get(this.context, 'gtmApp')) return;
    const program = {
      refId: _.get(seasonMeta, 'refId'),
      name: _.get(seasonMeta, 'name'),
      rental: _.get(seasonMeta, 'rental'),
      subscription: _.get(seasonMeta, 'subscription'),
    };
    const attributes = _.get(seasonMeta, 'attributes');
    const genres = _.get(seasonMeta, 'genres');
    const middleGenres = _.get(seasonMeta, 'middleGenres');
    this.context.gtmApp.pushDataLayerOnContentPageClick(CONTENT_EVENTS.SELECT_PROGRAM, {
      program,
      attributes,
      genres,
      middleGenres,
    });
  }

  fetchData(props) {
    let pathProps = props;
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    // @ts-ignore TS2339
    const evaluator = this.props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.get(res, ['json'].concat(rootPath), {});
        delete this.state[JSON.stringify(paths)];

        const othersSeasonName = 'その他';

        // @ts-ignore TS2339
        this.othersSeasonHierarchyTypeList = _.filter(
          // @ts-ignore TS2339
          this.item.hierarchyTypes,
          hierarchyType => hierarchyType.season_ids === null,
        );
        // @ts-ignore TS2339
        this.hasOtherSeasons = this.othersSeasonHierarchyTypeList.length > 0 && this.item.seasons.length > 0;
        // @ts-ignore TS2339
        this.seasonsWithOther = _.clone(this.item.seasons);
        // @ts-ignore TS2339
        if (this.hasOtherSeasons) {
          // @ts-ignore TS2339
          this.seasonsWithOther.push({ id: this.constructor.OTHER, name: othersSeasonName });
        }
        // @ts-ignore TS2339
        if (!_.isEmpty(this.seasonsWithOther)) {
          // @ts-ignore TS2339
          this.seasonsWithOther.unshift({ id: 'all', name: 'すべてのシーズン' });
        }

        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.props.model.getVersion(paths),
        };

        // @ts-ignore TS2339
        if (!this.state.changeSeason) {
          let seasonId = 0;
          if (_.get(this, ['context', 'seasonId'])) {
            // @ts-ignore TS2339
            seasonId = isNaN(this.context.seasonId) ? this.constructor.OTHER : this.context.seasonId;
          }
          if (_.has(this.context, 'routeHandler.query.season_id')) {
            seasonId = parseInt(_.get(this.context, 'routeHandler.query.season_id'), 10);
          }
          // @ts-ignore TS2339
          newState.selectedSeasonIndex = this.searchSeasonIndex(this.seasonsWithOther, seasonId);
        }

        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e);
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  retrieveSeasonById(id) {
    return new Promise((resolve, reject) => {
      // @ts-ignore TS2339
      const paths = this.constructor.getPaths(this.context.models, {}, { id });
      // @ts-ignore TS2339
      const rootPath = this.constructor.getRootPath(this.context.models, {}, { id });

      // すでに通信している場合は実行しない
      if (this.state[JSON.stringify(paths)]) return;

      // @ts-ignore TS2339
      if (this.state.dispose) {
        // 過去のObservableを削除する、これによって通信が止まるわけではなく
        // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
        // @ts-ignore TS2339
        this.state.dispose();
      }

      this.state[JSON.stringify(paths)] = paths;

      // @ts-ignore TS2339
      const evaluator = this.props.model.fetch(paths);
      const dispose = evaluator.dispose;
      // @ts-ignore TS2339
      if (this._isUpdated === false || !this._isMounted) {
        Object.assign(this.state, { dispose });
      } else {
        this.setState({ dispose });
      }
      evaluator
        .then(res => {
          const season = _.get(res, ['json'].concat(rootPath), {});
          delete this.state[JSON.stringify(paths)];
          const newState = {
            fetchDataError: null,
            dispose: null,
            // @ts-ignore TS2339
            generation: this.props.model.getVersion(paths),
          };
          // @ts-ignore TS2339
          if (this._isMounted) this.setState(newState);
          else Object.assign(this.state, newState);
          resolve(season);
        })
        .catch(e => {
          console.error(e);
          const newState = {
            fetchDataError: e,
            fetchingMoreRows: undefined,
            dispose: null,
          };
          delete this.state[JSON.stringify(paths)];
          // @ts-ignore TS2339
          if (this._isMounted) this.setState(newState);
          else Object.assign(this.state, newState);
          reject(e);
        });
    });
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');
    let episodes = null;
    if ((browserInfo.isIOS || browserInfo.isAndroid) && this.isLoading()) {
      let loadingListTitle = [];
      for (let i = 0; i < 4; i++) {
        loadingListTitle.push(
          <LoadingListTitle
            pulsate={true}
            displayWhenNotPulsing={false}
            lockupClassName={`card episode-card`}
            key={`loading-row-${i}`}
          />,
        );
      }
      episodes = loadingListTitle;
      return (
        <div className="series-area">
          <div className="episodes-container">
            <div className="episodes-wrapper">
              <div className="gallery">
                <div className="gallery-content">{episodes}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // @ts-ignore TS2339
    if (!this.item) return null;

    // @ts-ignore TS2339
    const { selectedSeasonIndex } = this.state;
    // @ts-ignore TS2339
    const { hierarchyTypes, id, slug } = this.item;
    // @ts-ignore TS2339
    const seasons = this.seasonsWithOther;
    const hasSeason = seasons && seasons.length > 0;

    if (!hierarchyTypes) return null;

    if (_.isEmpty(hierarchyTypes)) {
      hierarchyTypes.push({ key: 'df', name: 'エピソード' });
    }

    let seasonSelector = [];
    if (hasSeason) {
      const dropDownProps = {
        cols: 1,
        defaultValue: seasons[selectedSeasonIndex].name,
        options: seasons.map((season, i) => {
          return (
            <span
              className={classnames({ selected: season.name == seasons[selectedSeasonIndex].name })}
              key={`season_id_list_${season.id}`}
              onClick={e => this.onSeasonChange(season.id)}
            >
              {season.name}
            </span>
          );
        }),
        widthRestricted: false,
      };
      // @ts-ignore TS2740
      seasonSelector = <DropDown {...dropDownProps} />;
    }

    const episodesProps = {
      // @ts-ignore TS2339
      refId: this.item.refId,
      // @ts-ignore TS2339
      name: this.item.name,
      itemsInRow: this.context.columnsInRow,
      slug: slug,
      // @ts-ignore TS2339
      model: this.props.pathEvaluator, // Maximum number of paths exceeded. が発生する為バッチ処理させない
      showEpisodeSummary: true,
      enablePushOut: false,
      // @ts-ignore TS2339
      type: this.item.schemaId === 1 ? 'seriesEpisodes' : 'episodes',
      selectedEpisodeId: _.get(this.props, 'metadata.id'),
      // @ts-ignore TS2339
      onChangeQuery: this.props.onChangeQuery,
      // @ts-ignore TS2339
      onChangeEpisode: this.props.onChangeEpisode,
      keyPrefix: 'episodesList',
    };
    if (hasSeason) {
      if (seasons[selectedSeasonIndex].id && seasons[selectedSeasonIndex].id === 'all') {
        // @ts-ignore TS2339
        episodesProps.selectedSeasonId = seasons[selectedSeasonIndex].id;
        episodes = (
          <div className="episodes-container">
            {hierarchyTypes.map(hierarchyType => {
              return (
                <EpisodesRow
                  {...episodesProps}
                  // @ts-ignore TS2339
                  id={this.item.id}
                  hierarchyType={hierarchyType}
                  // @ts-ignore TS2339
                  key={`episodes_${this.item.id}_${hierarchyType.key}`}
                />
              );
            })}
          </div>
        );
        // @ts-ignore TS2339
      } else if (seasons[selectedSeasonIndex].id && seasons[selectedSeasonIndex].id != this.constructor.OTHER) {
        const seasonId = seasons[selectedSeasonIndex].id;
        let _hierarchyTypes = hierarchyTypes.filter(hierarchyType => {
          return [].concat(hierarchyType.season_ids).includes(seasonId);
        });
        if (_.isEmpty(_hierarchyTypes)) _hierarchyTypes.push({ key: 'df', name: 'エピソード' });
        episodes = (
          <div className="episodes-container">
            {_hierarchyTypes.map(hierarchyType => {
              const props = _.clone(episodesProps);
              delete props.type;
              return (
                <EpisodesRow
                  {...props}
                  id={seasonId}
                  hierarchyType={hierarchyType}
                  key={`episodes_${seasonId}_${hierarchyType.key}`}
                />
              );
            })}
          </div>
        );
      } else {
        episodes = (
          <div className="episodes-container">
            {/*
             // @ts-ignore TS2339 */}
            {this.othersSeasonHierarchyTypeList.map(hierarchyType => {
              return (
                <EpisodesRow
                  {...episodesProps}
                  id={id}
                  hierarchyType={hierarchyType}
                  key={`episodes_${id}_${hierarchyType.key}`}
                />
              );
            })}
          </div>
        );
      }
    } else {
      episodes = (
        <div className="episodes-container">
          {hierarchyTypes.map(hierarchyType => {
            return (
              <EpisodesRow
                {...episodesProps}
                id={id}
                hierarchyType={hierarchyType}
                key={`episodes_${id}_${hierarchyType.key}`}
              />
            );
          })}
        </div>
      );
    }
    return (
      <div className="series-area">
        {!_.isEmpty(seasonSelector) ? <div className="series-area-header">{seasonSelector}</div> : null}
        <CardContextProvider value={this.getChildContext()}>{episodes}</CardContextProvider>
      </div>
    );
  }
}
