import { useCallback, useEffect, useState } from 'react';
import _ from 'src/libs/util';

import usePrevious from './usePrevious';

const usePaletteObjects = ({ model, paletteId, from = 0, to = 11 }) => {
  const [paletteObjects, setPaletteObjects] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const prevPaletteId = usePrevious(paletteId);
  const prevFrom = usePrevious(from);
  const prevTo = usePrevious(to);

  const getPalette = useCallback(async () => {
    if (!paletteId) return;

    setLoading(true);
    if (error) setError(null);
    const rootPath = ['palette', paletteId, 'objects'];
    const paths = [rootPath.concat([{ from: from, to: to }])];
    try {
      const res = await model.fetch(paths);
      const obj = _.values(_.omit(_.get(res.json, rootPath), ['$__path', 'length']));
      setPaletteObjects(obj);
    } catch (e) {
      setError(e);
      console.error(e);
    }
    setLoading(false);
  }, [error, from, model, paletteId, to]);

  useEffect(() => {
    const shouldGetPalette =
      (paletteId && paletteId !== prevPaletteId) || (from && from !== prevFrom) || (to && to !== prevTo);
    if (shouldGetPalette) {
      if (paletteId !== prevPaletteId) {
        setPaletteObjects(null);
      }
      getPalette();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paletteId, from, to]);

  return { paletteObjects, loading, error };
};

export default usePaletteObjects;
