import { useMemo } from 'react';
import _ from 'src/libs/util';

const useOapLoopConfig = (config, meta) => {
  const oapLoopConfig = useMemo(() => {
    return _.find(_.get(config, 'oap_loop_metas'), oap => oap.cid == _.get(meta, 'refId') && oap.palette_id);
  }, [config, meta]);

  return oapLoopConfig;
};

export default useOapLoopConfig;
