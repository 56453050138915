import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';

export default class RoomClosed extends Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }

  static get propTypes() {
    return {
      closeModal: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);

    this._handleClose = this._handleClose.bind(this);
  }

  _handleClose() {
    this.context.watchPartyApp.dispose();
    // @ts-ignore TS2339
    this.props.closeModal();
  }

  render() {
    return (
      <React.Fragment>
        <div className="modal-title">このルームはすでに終了しています</div>
        <div className="text-left">
          <div className="modal-block">
            <p>ルームが終了しているため、入室できません</p>
          </div>
          <div className="form-btn-block text-center">
            <a className="btn btn-gray btn-small" href="javascript:void(0);" onClick={this._handleClose}>
              閉じる
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
