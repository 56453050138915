import React, { useState } from 'react';
import _ from 'src/libs/util';

import Watch from './Watch';
import { NotfoundError } from '../../../../constants/error';
import { NotFoundError as NFErr } from '../../../common/components/ErrorBoundary';

const PV = props => {
  const model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
  const rootPath = PV.getRootPath(null, null, props);
  const _item = model.getSync(rootPath) || {};
  const [item, setItem] = useState(_item);
  if (!item || _.isEmpty(item)) {
    // @ts-ignore TS2554
    throw new NFErr();
  }

  // const newProps = Object.assign({}, props, {id: item.id, model});
  const newProps = Object.assign({}, props, { id: item.id, isPV: true });
  return <Watch {...newProps} />;
};
PV.getRootPath = function(models, options, props) {
  if (props.id) {
    return ['content', props.id];
  }
  return [];
};
PV.getPrefetchPaths = function(models, options, props) {
  return [['content', props.id, ['id', 'name', 'leadSeasonId', 'titleMetaId']]];
};
PV.getPrefetchedPaths = function(models, options, props) {
  return data => {
    const item = _.omit(_.get(data.json, this.getRootPath(models, options, props)), ['$__path']);
    props = Object.assign({}, props, {
      id: item.id,
      titleMetaId: _.get(item, 'titleMetaId'),
      leadSeasonId: _.get(item, 'leadSeasonId'),
    });
    // @ts-ignore TS2339
    return Watch.getPaths(models, options, props);
  };
};
PV.afterPrefetch = function(models, options, props) {
  return data => {
    const item = _.omit(_.get(data.json, this.getRootPath(models, options, props)), ['$__path']);
    if (_.isEmpty(item)) {
      // @ts-ignore TS2554
      return { error: new NotfoundError() };
    }
  };
};
export default PV;
