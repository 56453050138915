import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import activeProfile from '../../../../utils/activeProfile';

class StreaksPlayer extends Component {
  static get contextTypes() {
    return {
      models: PropTypes.object,
      playerApp: PropTypes.object,
      playerSession: PropTypes.object,
      staticConfiguration: PropTypes.object,
      getModelData: PropTypes.func,
    };
  }

  static get propTypes() {
    return {
      autoplayNextTitle: PropTypes.func,
      forcePlaybackEngine: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.handlePlayerReady = this.handlePlayerReady.bind(this);
    this.loadImaSdk = this.loadImaSdk.bind(this);
    this.loadStreaksPlayer = this.loadStreaksPlayer.bind(this);
    // @ts-ignore TS2339
    this.coreRef = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    this.play();
  }

  componentWillUnmount() {
    // @ts-ignore TS2551
    if (this.player) {
      // @ts-ignore TS2551
      this.player.dispose();
    }
  }

  loadImaSdk() {
    const imasdk = document.createElement('script');
    imasdk.src = '//imasdk.googleapis.com/js/sdkloader/ima3.js';

    // @ts-ignore TS2339
    this.coreRef.current.appendChild(imasdk);
    return new Promise((resolve, reject) => {
      imasdk.onload = resolve;
    });
  }

  loadStreaksPlayer(video, metricsOptions) {
    const script = document.createElement('script');
    // script.src = 'https://logiclogic.jp/inhouse/streaksplayer/streaksplayer.novtt.js';
    // script.src = '/js/streaksplayer.novtt.min.js';
    // script.src = '/js/streaksplayer.min.js';
    // script.src = 'https://players.streaks.jp/sdk/latest/streaksplayer.min.js';
    script.src = 'https://players.streaks.jp/sdk/v2.5.4/streaksplayer.min.js';
    // script.src = 'https://logiclogic.jp/inhouse/streaksplayer-sandbox/dist/streaksplayer.min.js';
    script.onload = () => {
      // console.log(this.props.minBitrate, this.props.maxBitrate)
      // console.log('autoplay', !this.props.playerPaused && this.props.autoplay != false)
      let options = {
        chromeless: true,
        // @ts-ignore TS2339
        autoplay: !this.props.playerPaused && this.props.autoplay != false,
        // @ts-ignore TS2339
        muted: this.props.muted,
        timeShift: true,
        // @ts-ignore TS2339
        startTime: this.props.startTime < 0 ? 0 : this.props.startTime,
        // @ts-ignore TS2339
        pictureInPicture: this.props.canPip,
        metrics: metricsOptions,
        abr: {
          // @ts-ignore TS2339
          preferredMaximumBitrate: this.props.maxBitrate,
          // @ts-ignore TS2339
          preferredMinimumBitrate: this.props.minBitrate,
        },
        advertising: {
          requestMode: 'ondemand',
          repeat: true,
          // @ts-ignore TS2339
          skipPreroll: !this.props.shouldPlayAd,
        },
        silverlight: {
          xap: '/SmoothStreamingPlayer.xap',
        },
      };

      // @ts-ignore TS2551
      this.player = window.streaksplayer(video, options, () => {});
      // @ts-ignore TS2551
      this.player.ready(this.handlePlayerReady);
    };
    // @ts-ignore TS2339
    this.coreRef.current.appendChild(script);
  }

  async play() {
    // @ts-ignore TS2339
    if (this.coreRef.current) {
      // @ts-ignore TS2339
      while (this.coreRef.current.firstChild) {
        // @ts-ignore TS2339
        if (window.playerContainer) window.playerContainer.removeChild(this.coreRef.current.firstChild);
      }
    }

    const video = document.createElement('video');
    // @ts-ignore TS2339
    video.setAttribute('data-merchant', this.props.merchantId);
    // @ts-ignore TS2339
    video.setAttribute('data-player', this.props.playerId);
    video.setAttribute('data-embed', 'default');
    video.setAttribute('data-application-id', '');
    video.setAttribute('controls', '');

    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS && !browserInfo.isIPad) {
      video.setAttribute('webkit-playsinline', '');
      video.setAttribute('playsinline', '');
    }

    const metricsOptions = { 'app.name': 'WOWOW' };
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    if (profile) {
      metricsOptions['user.id'] = profile.uuid;
      if (profile.isPerson) {
        metricsOptions['user.type'] = 'owner';
      } else if (profile.isFamily) {
        metricsOptions['user.type'] = 'family';
      }
    }
    // @ts-ignore TS2339
    this.coreRef.current.appendChild(video);

    // @ts-ignore TS2339
    if (this.props.shouldPlayAd) {
      await this.loadImaSdk();
    }
    this.loadStreaksPlayer(video, metricsOptions);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  handlePlayerReady() {
    // console.log('handlePlayerReady')
    // @ts-ignore TS2551
    const player = this.player;

    // @ts-ignore TS2339
    this.props.loadVideo((err, data) => {
      if (err) {
        // @ts-ignore TS2339
        if (this.props.onError) this.props.onError(err);
        return;
      }

      if (typeof player.qualityLevels === 'function') {
        const qualityLevels = player.qualityLevels();
        qualityLevels.on('addqualitylevel', event => {
          let qualityLevel = event.qualityLevel;

          if (qualityLevel.height > 720) {
            qualityLevel.enabled = false;
          } else {
            qualityLevel.enabled = true;
          }
        });
      }

      if (typeof data === 'string') {
        data = JSON.parse(data);
      }
      // if (this.props.setRenditions) this.props.setRenditions(data);
      // @ts-ignore TS2339
      if (this.props.onReady) this.props.onReady(player);
      player.on('error', () => {
        // console.log(player.error())
        // @ts-ignore TS2339
        if (this.props.onError) {
          // Streaksのエラーは接頭辞S-をつける
          const error = _.clone(player.error());
          const code = _.get(error, 'code');
          if (code) {
            _.set(error, 'dcode', `S-${code}`);
          }
          // @ts-ignore TS2339
          this.props.onError(error);
        }
      });

      // debug: metrics
      // if (_.has(data, 'metrics.host')) data.metrics.host = 'localhost';

      // @ts-ignore TS2551
      if (typeof this.player.loadMedia === 'function') {
        // @ts-ignore TS2551
        this.player.loadMedia(data);
        // @ts-ignore TS2339
        if (!this.props.playerPaused && this.props.autoplay != false) this.player.play();
      } else {
        // @ts-ignore TS2551
        this.player.loadVideo(data);
      }
    });

    // TODO:
    /*
    player.on('fullscreenchange', () => {
      if (player.isFullscreen()) {
        // fullscreen
      } else {
        // exitFullscreen
      }
    });
    */
  }

  render() {
    // @ts-ignore TS2339
    return <div className="video-core-container" ref={this.coreRef}></div>;
  }
}

export default StreaksPlayer;
