import _ from 'src/libs/util';
import url from 'url';

import DeleteApp from './DeleteApp';
import { CONTENT_EVENTS } from './GtmApp';

class ViewingBookmarkDeleteApp extends DeleteApp {
  constructor(service, gtmApp) {
    super();
    service = Object.assign({}, service);
    service.pathname = _.join(_.concat(service.path, 'users/viewing_bookmarks/delete'), '/');
    // @ts-ignore TS2339
    this._url = url.format(service);
    // @ts-ignore TS2339
    this.gtmApp = gtmApp;
  }

  one(id, authContext, userId) {
    const params = {
      meta_id: id,
      user_id: userId || authContext.id,
    };
    return super.send(params, authContext);
  }

  send(authContext, userId) {
    this.sendToGtm();
    const params = {
      meta_id: this.deleteIds().join(','),
      user_id: userId || authContext.id,
    };
    return super.send(params, authContext);
  }

  sendToGtm() {
    // @ts-ignore TS2339
    if (!this.gtmApp) return;
    // @ts-ignore TS2551
    const deletingTargets = _.filter(this._targets, target => _.includes(this.deleteIds(), target.id));
    deletingTargets.forEach(deletingTarget => {
      const hasRelationProgram = _.get(deletingTarget, 'seriesMeta') && _.get(deletingTarget, 'seasonMeta');
      const isMediaMeta = _.get(deletingTarget, 'type') === 'media';
      const relationProgram = hasRelationProgram
        ? {
            refId: _.get(deletingTarget, 'seriesMeta.refId'),
            name: _.get(deletingTarget, 'seriesMeta.name'),
          }
        : null;
      const program = isMediaMeta
        ? {
            refId: _.get(deletingTarget, 'seasonMeta.refId') || _.get(deletingTarget, 'seriesMeta.refId'),
            name: _.get(deletingTarget, 'seasonMeta.name') || _.get(deletingTarget, 'seriesMeta.name'),
          }
        : {
            refId: _.get(deletingTarget, 'refId'),
            name: _.get(deletingTarget, 'name'),
          };
      const content = isMediaMeta
        ? {
            refId: _.get(deletingTarget, 'refId'),
            name: _.get(deletingTarget, 'name') || _.get(deletingTarget, 'shortName'),
            rental: _.get(deletingTarget, 'rental'),
            subscription: _.get(deletingTarget, 'subscription'),
          }
        : {
            rental: _.get(deletingTarget, 'rental'),
            subscription: _.get(deletingTarget, 'subscription'),
          };
      const attributes = _.get(deletingTarget, 'attributes');
      const genres = _.get(deletingTarget, 'genres');
      const middleGenres = _.get(deletingTarget, 'middleGenres');
      const schemaId = _.get(deletingTarget, 'schemaId');
      // @ts-ignore TS2339
      this.gtmApp.pushDataLayerOnContentPageClick(CONTENT_EVENTS.HISTORY_DELETE, {
        relationProgram,
        program,
        content,
        attributes,
        genres,
        middleGenres,
        schemaId,
      });
    });
  }
}

export default ViewingBookmarkDeleteApp;
