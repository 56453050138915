import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import { withModalContext } from '../../../../common/context/ModalContext';
import classnames from 'classnames';

import RoomCreate from './RoomCreate';

export default class RoomCreated extends Component {
  static get propTypes() {
    return {
      handleWatchPartyModeChange: PropTypes.func,
      closeModal: PropTypes.func,
      titleModel: PropTypes.object,
      roomCreatedBtn: PropTypes.func,
      seriesModel: PropTypes.object,
      userState: PropTypes.string,
    };
  }
  constructor(props, context) {
    super(props, context);

    this._handleClose = this._handleClose.bind(this);
  }

  _handleClose() {
    // @ts-ignore TS2339
    this.props.closeModal();
  }

  render() {
    return (
      <React.Fragment>
        <div className="modal-title">
          {/*
           // @ts-ignore TS2339 */}
          {this.props.userState == 'master' ? 'ウォッチパーティ開始の確認' : 'ウォッチパーティ参加の確認'}
        </div>
        <div className="text-left">
          <div className="modal-block">
            {/*
             // @ts-ignore TS2339 */}
            {this.props.userState == 'master' ? (
              <React.Fragment>
                <p>
                  {/*
                   // @ts-ignore TS2322 */}
                  すでに<span class="text-accent">{this.props.room.name}</span>を主催しています。
                  <br />
                  ルームは1つしか作成できないため、新たにウォッチパーティを開始するには、
                  {/*
                   // @ts-ignore TS2322 */}
                  <span class="text-accent">{this.props.room.name}</span>を終了する必要があります。
                  <br />
                  終了してもよろしいですか？
                </p>
                <p>（ルーム主催者が終了すると、他の参加者もルームから退室されます。）</p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p>
                  {/*
                   // @ts-ignore TS2322 */}
                  すでに<span class="text-accent">{this.props.room.name}</span>に入室しています。
                  <br />
                  ルームは1つしか入室できないため、新たなルームに入室するには、
                  {/*
                   // @ts-ignore TS2322 */}
                  <span class="text-accent">{this.props.room.name}</span>を退室する必要があります。
                  <br />
                  退室してもよろしいですか？
                </p>
              </React.Fragment>
            )}
          </div>
          <div className="form-btn-block text-center">
            <a className="btn btn-gray btn-small" href="javascript:void(0);" onClick={this._handleClose}>
              キャンセル
            </a>
            {/*
             // @ts-ignore TS2339 */}
            <a className="btn btn-fill btn-small" href="javascript:void(0);" onClick={this.props.roomCreatedBtn}>
              {/*
               // @ts-ignore TS2339 */}
              {this.props.userState == 'master' ? '終了する' : '退室する'}
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

withModalContext(RoomCreate);
