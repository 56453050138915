import _ from 'src/libs/util';
import { PAY_METHOD_TO_LABEL } from '../models/paymentMethod';
/* eslint-disable import/prefer-default-export */

class GenericError {
  constructor(err) {
    if (err) {
      if (typeof err === 'string' || Array.isArray(err)) {
        // @ts-ignore TS2339
        this.message = err;
      } else if (err instanceof Error) {
        // @ts-ignore TS2339
        this.message = err.message;
        // @ts-ignore TS2339
        this.stack = err.stack;
        // @ts-ignore TS2339
        this.originalError = err;
      }
    } else {
      // @ts-ignore TS2339
      this.title = '予期せぬエラーが発生しました';
    }
  }
}

const FORBIDDEN = 'ERROR_FORBIDDEN';
class ForbiddenError extends GenericError {
  constructor(err) {
    super(err);
    // @ts-ignore TS2339
    this.status = 403;
    // @ts-ignore TS2339
    this.message = FORBIDDEN;
  }
}

const NOTFOUND = 'ERROR_NOTFOUND';
class NotfoundError extends GenericError {
  constructor(err) {
    super(err);
    // @ts-ignore TS2339
    this.status = 404;
    // @ts-ignore TS2339
    this.message = NOTFOUND;
  }
}

const BADREQUEST = 'ERROR_BADREQUEST';
class BadRequestError extends GenericError {
  constructor(err) {
    super(err);
    // @ts-ignore TS2339
    this.message = '';
  }
}

const BROWSER_NOT_SUPPORT = 'ERROR_BROWSER_NOT_SUPPORT';
class BrowserNotSupportError extends GenericError {
  constructor(err) {
    super(err);
    // @ts-ignore TS2339
    this.status = 200;
    // @ts-ignore TS2339
    this.message = BROWSER_NOT_SUPPORT;
  }
}

const TIMEOUT = 'ERROR_TIMEOUT';
class TimeoutError extends GenericError {
  constructor(err) {
    super(err);
    // @ts-ignore TS2339
    this.title = 'アクセスを処理できませんでした';
    // @ts-ignore TS2339
    this.messages = [
      '現在アクセスが集中している為、繋がりにくくなっています。',
      'しばらくたってから再度アクセスをお願いします。',
    ];
  }
}

const UNAUTHORIZED = 'ERROR_UNAUTHORIZED';
class UnauthorizedError extends GenericError {
  constructor(err) {
    super(err);
    // @ts-ignore TS2339
    this.status = 401;
    // @ts-ignore TS2339
    this.message = UNAUTHORIZED;
    // @ts-ignore TS2339
    this.originalError = err;
  }
}

const RATING_UNMATCH = 'RATING_UNMATCH';
const EVENT_SUSPEND = 'EVENT_SUSPEND';
const LICENSE_INVALID = 'LICENSE_INVALID';
const PLAYBACK_SESSION_ID_INVALID = 'PLAYBACK_SESSION_ID_INVALID';
const LOCAL_TIME_INVALID = 'LOCAL_TIME_INVALID';
const NOT_ACCOUNT_OWNER = 'NOT_ACCOUNT_OWNER';
const META_NOT_VIEWABLE = 'META_NOT_VIEWABLE';
const AVAILABLE_ENTITLEMENT = 'AVAILABLE_ENTITLEMENT';
const CONTENTS_PROTECTION = 'CONTENTS_PROTECTION';
const MATCHING_INFO_LACK = 'MATCHING_INFO_LACK';

const MAILADDRESS_REGISTERED = 'MAILADDRESS_REGISTERED';
class MailaddressRegisteredError extends GenericError {
  constructor(err) {
    super(err);
    // @ts-ignore TS2339
    this.status = 200;
    // @ts-ignore TS2339
    this.message = 'すでに使用されているメールアドレスです。';
  }
}

// eSuiteサーバでエラーが発生した時
const PAYMENT_SERVER_ERROR = 'PAYMENT_SERVER_ERROR';
class PaymentServerError extends GenericError {
  constructor(err) {
    super(err);
    // @ts-ignore TS2339
    this.status = 200;
    // @ts-ignore TS2339
    this.message = PAYMENT_SERVER_ERROR;
  }
}

const AGREEMENT_REQUIRED = 'AGREEMENT_REQUIRED';
const ALREADY_AGREED = 'ALREADY_AGREED';
const REAUTH_SERVER_ERROR = 'REAUTH_SERVER_ERROR';
const VIEWING_DEVICE_LIMIT = 'VIEWING_DEVICE_LIMIT';
const META_NOT_DELIVERY_STARTED = 'META_NOT_DELIVERY_STARTED';
const EIRIN = 'EIRIN';
const START_OVER = 'START_OVER';
const NOT_PLAYABLE = 'NOT_PLAYABLE';
const EXERCISE_ALERT = 'EXERCISE_ALERT';
const SESSION_OPEN = 'SESSION_OPEN';
const NOT_STARTED_ENTITLEMENT = 'NOT_STARTED_ENTITLEMENT';
const PURCHASE_LIMIT = 'PURCHASE_LIMIT';
const CSRF = 'EBADCSRFTOKEN';

const VOUCHER_CAMPAIGN_ERROR = 'VoucherCampaignError';
class VoucherCampaignError extends GenericError {
  constructor(err) {
    super(err);
    // @ts-ignore TS2339
    this.status = 200;
    // @ts-ignore TS2339
    this.message = VOUCHER_CAMPAIGN_ERROR;
    // @ts-ignore TS2339
    this.errorInfo = {
      data: err,
    };
  }
}

/**トライアル契約不可エラー */
const DISABLE_TRIAL_ERROR = 'DISABLE_TRIAL_ERROR';
class DisableTrialError extends GenericError {
  constructor(err) {
    super(err);
    // @ts-ignore TS2339
    this.status = 200;
    // @ts-ignore TS2339
    this.message = err.message;
    // @ts-ignore TS2339
    this.type = DISABLE_TRIAL_ERROR;
    // @ts-ignore TS2339
    this.errorInfo = {
      data: err,
    };
  }
}
/**放送契約前の即時契約時のエラー */
const SUBSCRIBE_CONTRACTED_ERROR = 'SUBSCRIBE_CONTRACTED_ERROR';
class SubscribeContractedError extends GenericError {
  constructor(err) {
    super(err);
    // @ts-ignore TS2339
    this.status = 200;
    // @ts-ignore TS2339
    this.message = SUBSCRIBE_CONTRACTED_ERROR;
    // @ts-ignore TS2339
    this.errorInfo = {
      data: err,
    };
  }
}

// バウチャーコード //
const VOUCHER_CODE_ERROR_TYPE = {
  /**解約申込中のため、バウチャー適用ができないエラー */
  SUBSCRIPTION_APPLIED_CANCEL: 'SUBSCRIPTION_APPLIED_CANCEL',
  /**前回の決済がまだ行われていないエラー */
  SUBSCRIPTION_NOT_PAYMENT_COMPLETED: 'SUBSCRIPTION_NOT_PAYMENT_COMPLETED',
  /**使用条件エラー */
  CONDITION_FOR_USE: 'CONDITION_FOR_USE',
  /** 既に割引適用中エラー*/
  CURRENT_APPLIED: 'CURRENT_APPLIED',
  /** 加入時のみエラー*/
  ONLY_SIGNUP: 'ONLY_SIGNUP',
  /** 契約者本人のみ*/
  ONLY_OWENER: 'ONLY_OWENER',
  /** 該当バウチャーコードなし*/
  NOT_FOUND: 'NOT_FOUND',
  /** 新規利用停止*/
  SUSPENSION_OF_NEW_USE: 'SUSPENSION_OF_NEW_USE',
  /** 登録時に有効期限終了*/
  EXPIRED_AT_REGISTER: 'EXPIRED_AT_REGISTER',
  /** 支払い方法エラー*/
  INVALID_PAYMENT_METHOD: 'INVALID_PAYMENT_METHOD',
  /** 対象コース違い*/
  NOT_TARGET_COURSE: 'NOT_TARGET_COURSE',
  /**汎用エラー */
  GENERAL: 'GENERAL',
};
const VOUCHER_CODE_ERROR_MESSAGE = {
  SUBSCRIPTION_APPLIED_CANCEL: [
    '解約申し込みをしているためクーポンをご利用できません。',
    '解約申し込みをキャンセルの上、再度「利用する」ボタンを押してください。',
  ],
  SUBSCRIPTION_NOT_PAYMENT_COMPLETED: [
    '現在、ご利用料金のお支払いが確認できていないためクーポンを利用することができません。',
    '【ご契約状況】ページよりお支払い手続きを完了のうえ、再度お試しください。',
  ],
  CONDITION_FOR_USE: ['クーポンを使用できません。使用条件をご確認ください。'],
  CURRENT_APPLIED: ['現在ご利用中のクーポンがあります。', 'ご利用期間終了後に改めてお試しください。'],
  ONLY_SIGNUP: ['ご加入時にのみご利用いただけるクーポンです。'],
  SUBSCRIPTION_STATUS_INAPPROPRIATE: ['現在の契約状態では本クーポンは使用できません。'],
  NOT_FOUND: ['クーポンコードが見つかりませんでした。'],
  NOT_AVAILABLE: ['本クーポンは現在ご利用いただけません。'],
  INVALID_PAYMENT_METHOD: [
    '本クーポンは%paymentMethodName%でのお支払いにて使用できます。',
    '<a href="/account/payments">こちら</a>よりお支払方法の登録状況をご確認ください。',
  ],
  GENERAL: ['クーポンコードが不正です。'],
};
class VoucherCodeError extends GenericError {
  constructor(err, voucherCode = {}) {
    super(err);
    // @ts-ignore TS2339
    this.status = _.get(err, 'status') || 200;
    // @ts-ignore TS2339
    this.apiError = _.get(err, 'apiError');
    // @ts-ignore TS2339
    this.type = _.get(err, 'type') || VOUCHER_CODE_ERROR_TYPE.GENERAL;
    // @ts-ignore TS2339
    switch (this.type) {
      case VOUCHER_CODE_ERROR_TYPE.SUBSCRIPTION_APPLIED_CANCEL:
        // @ts-ignore TS2339
        this.message = VOUCHER_CODE_ERROR_MESSAGE.SUBSCRIPTION_APPLIED_CANCEL;
        break;
      case VOUCHER_CODE_ERROR_TYPE.SUBSCRIPTION_NOT_PAYMENT_COMPLETED:
        // @ts-ignore TS2339
        this.message = VOUCHER_CODE_ERROR_MESSAGE.SUBSCRIPTION_NOT_PAYMENT_COMPLETED;
        break;
      case VOUCHER_CODE_ERROR_TYPE.CONDITION_FOR_USE:
        // @ts-ignore TS2339
        this.message = VOUCHER_CODE_ERROR_MESSAGE.CONDITION_FOR_USE;
        break;
      case VOUCHER_CODE_ERROR_TYPE.CURRENT_APPLIED:
        // @ts-ignore TS2339
        this.message = VOUCHER_CODE_ERROR_MESSAGE.CURRENT_APPLIED;
        break;
      case VOUCHER_CODE_ERROR_TYPE.ONLY_SIGNUP:
        // @ts-ignore TS2339
        this.message = VOUCHER_CODE_ERROR_MESSAGE.ONLY_SIGNUP;
        break;
      case VOUCHER_CODE_ERROR_TYPE.ONLY_OWENER:
        // @ts-ignore TS2339
        this.message = VOUCHER_CODE_ERROR_MESSAGE.SUBSCRIPTION_STATUS_INAPPROPRIATE;
        break;
      case VOUCHER_CODE_ERROR_TYPE.NOT_TARGET_COURSE:
        // @ts-ignore TS2339
        this.message = VOUCHER_CODE_ERROR_MESSAGE.SUBSCRIPTION_STATUS_INAPPROPRIATE;
        break;
      case VOUCHER_CODE_ERROR_TYPE.NOT_FOUND:
        // @ts-ignore TS2339
        this.message = VOUCHER_CODE_ERROR_MESSAGE.NOT_FOUND;
        break;
      case VOUCHER_CODE_ERROR_TYPE.EXPIRED_AT_REGISTER:
        // @ts-ignore TS2339
        this.message = VOUCHER_CODE_ERROR_MESSAGE.NOT_FOUND;
        break;
      case VOUCHER_CODE_ERROR_TYPE.SUSPENSION_OF_NEW_USE:
        // @ts-ignore TS2339
        this.message = VOUCHER_CODE_ERROR_MESSAGE.NOT_AVAILABLE;
        break;
      case VOUCHER_CODE_ERROR_TYPE.INVALID_PAYMENT_METHOD:
        // @ts-ignore TS2339
        this.message = VOUCHER_CODE_ERROR_MESSAGE.INVALID_PAYMENT_METHOD;
        break;
      default:
        // @ts-ignore TS2339
        this.message = VOUCHER_CODE_ERROR_MESSAGE.GENERAL;
    }
    const paymentMethods = _.get(voucherCode, 'voucher.paymentMethods') || [];
    const payMethodName = _.map(paymentMethods, method => PAY_METHOD_TO_LABEL[method]).join(',');
    // @ts-ignore TS2339
    this.message = _.map(this.message, msg => msg.replace('%paymentMethodName%', payMethodName));
    // @ts-ignore TS2339
    this.errorInfo = {
      data: err,
    };
  }
}

// バウチャーコード //
const RECHAPCHA_ERROR_TYPE = {
  /** 任意のスコア以下*/
  BELOW_PASSING_SCORE: 'BELOW_PASSING_SCORE',
  /** 認証済rechapchaトークンとparamの突合エラー*/
  VERIFIED_TOKEN_NOT_MATCH: 'VERIFIED_TOKEN_NOT_MATCH',
};
class ReChapchaError extends GenericError {
  constructor(err) {
    super(err);
    // @ts-ignore TS2339
    this.message = ['reCHAPCHA認証でエラーが発生しました。'];
    // @ts-ignore TS2339
    this.version = _.get(err, 'version');
    // @ts-ignore TS2339
    this.score = _.get(err, 'score');
    // @ts-ignore TS2339
    this.errorCodes = _.get(err, 'errorCodes');

    // const ERROR_CODE = {
    //   // The secret parameter is missing.
    //   'missing-input-secret':
    //   // The secret parameter is invalid or malformed.
    //   'invalid-input-secret':
    //   // The response parameter is missing.
    //   'missing-input-response':
    //   // The response parameter is invalid or malformed.
    //   'invalid-input-response':
    //   // The request is invalid or malformed.
    //   'bad-request':
    //   // The response is no longer valid: either is too old or has been used previously.
    //   'timeout-or-duplicate':
    // }
  }
}
const LOGIN_ERROR_TYPE = {
  /** 不正なstate(csrfエラー)*/
  INVALID_STATE: 'INVALID_STATE',
};
const LOGIN_ERROR_MESSAGE = {
  /** 任意のスコア以下*/
  INVALID_STATE: ['不正な遷移を検出しました。'],
};

class LoginError extends GenericError {
  constructor(err) {
    super(err);
    // @ts-ignore TS2339
    this.type = err.type;
    // @ts-ignore TS2339
    if (this.type === LOGIN_ERROR_TYPE.INVALID_STATE) {
      // @ts-ignore TS2339
      this.message = LOGIN_ERROR_MESSAGE.INVALID_STATE;
    }
  }
}

export {
  GenericError,
  FORBIDDEN,
  ForbiddenError,
  NOTFOUND,
  NotfoundError,
  UNAUTHORIZED,
  UnauthorizedError,
  BADREQUEST,
  BadRequestError,
  BROWSER_NOT_SUPPORT,
  BrowserNotSupportError,
  TIMEOUT,
  TimeoutError,
  RATING_UNMATCH,
  EVENT_SUSPEND,
  LICENSE_INVALID,
  PLAYBACK_SESSION_ID_INVALID,
  LOCAL_TIME_INVALID,
  PAYMENT_SERVER_ERROR,
  PaymentServerError,
  META_NOT_VIEWABLE,
  AVAILABLE_ENTITLEMENT,
  CONTENTS_PROTECTION,
  MATCHING_INFO_LACK,
  MAILADDRESS_REGISTERED,
  MailaddressRegisteredError,
  AGREEMENT_REQUIRED,
  REAUTH_SERVER_ERROR,
  VIEWING_DEVICE_LIMIT,
  META_NOT_DELIVERY_STARTED,
  EIRIN,
  START_OVER,
  NOT_PLAYABLE,
  EXERCISE_ALERT,
  VOUCHER_CAMPAIGN_ERROR,
  VoucherCampaignError,
  SESSION_OPEN,
  NOT_STARTED_ENTITLEMENT,
  PURCHASE_LIMIT,
  CSRF,
  SUBSCRIBE_CONTRACTED_ERROR,
  SubscribeContractedError,
  VOUCHER_CODE_ERROR_TYPE,
  VoucherCodeError,
  DISABLE_TRIAL_ERROR,
  DisableTrialError,
  ReChapchaError,
  RECHAPCHA_ERROR_TYPE,
  LoginError,
  LOGIN_ERROR_TYPE,
};
