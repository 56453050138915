import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import window from 'global/window';
import classnames from 'classnames';
import url from 'url';
import { Helmet } from 'react-helmet';
import Falcor from 'falcor';

import Visibly from '../../../../common/visibly';

import { withLayoutContext } from '../../../common/context/LayoutContext';
import { withModalContext } from '../../../common/context/ModalContext';
import { withDrawerContext } from '../../../common/context/DrawerContext';
import HtmlContext from '../../../common/context/HtmlContext';
import { NotFoundError } from '../../../common/components/ErrorBoundary';

import GCastPlayerApp from '../../../../common/GCastPlayerApp';

import MainViewLink from '../../../common/components/MainViewLink';
import WodPlayer from '../../../common/components/player/WodPlayer';
import generateUuid from '../../../../common/generateUuid';
import ErrorBoundary from '../../../common/components/ErrorBoundary';
import UpSellFlow from '../../../common/components/UpSellFlow';
import FallbackModal from '../../../common/components/modal/FallbackModal';
import ExerciseModal from '../../../common/components/modal/ExerciseModal';
import CheckModal from '../../../common/components/modal/CheckModal';

import ErrorView from '../../../common/components/player/ErrorView';
import NextEpisode from './NextEpisode';
import UpnextView from '../player/UpnextView';
import WatchCard from './WatchCard';
import NextWatchCard from './NextWatchCard';
import PriceList from './PriceList';
// import Details from '../browse/titlemeta/Details';
import MultiView from '../player/MultiView';

import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
import * as DOMUtils from '../../../../sketch-platform/utils/DOMUtils';
import URLGenerator from '../../../../sketch-platform/ui/routing/URLGenerator';
import MetaTags from '../player/MetaTags';
import Tags from './Epg/Tags';
import Meta from './Meta';
import EventInfo from './Epg/EventInfo';
import EpgEvent from './Epg/EpgEvent';
import MyListButton from './MyListButton';
import ShareButton from './ShareButton';
import vuidDataStore from '../../../../utils/vuidDataStore';
import psidDataStore from '../../../../utils/psidDataStore';
import activeProfile from '../../../../utils/activeProfile';
import Axios from '../../../../common/Axios';
import routes, { getHomeRoute } from '../../../common/routes';
import playbackRule from '../../../../models/playbackRule';
import Comment from './WatchParty/Comment';
import RoomNotfound from './WatchParty/RoomNotfound';
import RoomClosed from './WatchParty/RoomClosed';
import RoomEnd from './WatchParty/RoomEnd';
import WatchPartyModal from './WatchParty/WatchPartyModal';
import WatchPartyButton from './WatchParty/WatchPartyButton';

import * as ERROR from '../../../../constants/error';
import { LINKS } from '../../../../constants/links';
import { DSID_KEY, FALLBACK_MODAL_ALERT, RELOAD_NEED_DATE, RESUME_POINT_KEY } from '../../../../constants/cookie';
import TitleWatchContents from './TitleWatchContents';
import BackgroundImage from '../../../common/components/BackgroundImage';
import EventRow from './Epg/EventRow';
import ScheduleButton from './ScheduleButton';
import { CONTENT_EVENTS } from '../../../../common/GtmApp';
import SetDate from './SetDate';
import JsonLd from '../../../common/components/JsonLd';
import Simulcast from './Simulcast';
import Details from './titlemeta/Details';
import lastChannelDataStore from '../../../../utils/lastChannelDataStore';
import HtmlSnippet from '../../../common/components/HtmlSnippet';
import WodIframe from '../../../common/components/WodIframe';
import { getIsDelivery, getIsDeliveryEnded, getIsDeliveryStarted } from '../../../common/utils/metaUtil';
import datetime from 'src/libs/datetime';
import StartOverModal from '../../../common/components/modal/StartOverModal';
import { TokenApp } from '../../../../common/TokenApp';
import Sidelist from '../../../common/components/player/sidelist/Sidelist';
import WatchPartyApp from 'src/common/WatchPartyApp';
import LicenseInvalidView from '../../../common/components/howto_play/ErrorView/LicenseInvalidView';
import MainvisualSideDescription from '../watch/elements/MainvisualSideDescription';
import CloedCampaignText from '../watch/elements/ClosedCampaignText';
import PlanInfo from '../watch/elements/PlanInfo';
import { getCircularReplacer } from 'src/utils/getCircularReplacer';
import { BffPlaybackApi } from 'src/api/BffPlaybackApi';
import { PlaybackUsecase } from 'src/usecase/apps/PlaybackUsecase';

let VUID;
let DSID;
let MODE;

const PLAYER_TYPE = {
  GENERAL_AUDIENCE: 'ga',
  KIDS: 'kids',
  WOD_PLAYER: 'wodplayer',
};

export const QUERY = {
  MATCH_START_TIME: 'mst', //試合開始時間
  TIME: 't', // 時間指定で再生
  LIVE_EDGE: 'le', // live位置から再生
};

const initialState = {
  fetchDataError: null,
  playbackError: null,
  prepareToPlayError: null,
  generation: -1,
  auoplayCount: 0,
  initParams: {},
  playContext: null,
  playbackEnded: false,
  stoppedPostPlay: false,
  mode: 'theater',
  enableAutoplay: true,
  showNextEpisode: false,
  videoSkip: null,
  showPlayerNextEpisode: false,
  upnextView: false,
  autoplayOffsetSeconds: 0,
  playerComponent: PLAYER_TYPE.WOD_PLAYER,
  columnsInRow: 4,
  suspend: false,
  watchPartyMode: false,
  commentActive: false,
  watchPartyType: 'none', // closeType openType
  showCatchupMsg: false,
  fetching: undefined,
  playerModal: null,
  maybeEnableStartOver: false,
  infoContent: null,
  playerSideListMode: false,
  watchInfoTabKey: 'plan',
};

/**
 * 権利が一時停止状態であるかを判定します。
 * @param code {number} エラーコード
 * @return {boolean} true 一時停止状態である, false 一時停止状態ではない
 */
const isEntitlementSuspend = code => {
  // 対象のエラーコードであれば一時停止状態と判定する
  const TARGET_ERROR_CODES = [2065, 2066];
  return _.includes(TARGET_ERROR_CODES, code);
};

const playError = function(error) {
  if (typeof error === 'object') {
    if (typeof error.error === 'object') error = error.error;
    if (error.code === 2041 || error.code === 2040) {
      error = ERROR.UNAUTHORIZED;
    } else if (error.code === 2062) {
      return {
        type: ERROR.AGREEMENT_REQUIRED,
        code: error.dcode,
        display: {
          title: '規約が更新されました。',
        },
        metaId: error.metaId,
      };
    } else if (error.code === 2070) {
      return {
        type: ERROR.NOT_STARTED_ENTITLEMENT,
        code: error.dcode,
        objects: error.objects,
        display: {
          title: '',
          text: error.message,
        },
      };
    } else if (error.code === 2069) {
      return {
        type: ERROR.EXERCISE_ALERT,
        code: error.dcode,
        display: {
          title: '',
          text: error.message,
        },
      };
    } else if (error.code == 2057) {
      return {
        type: ERROR.VIEWING_DEVICE_LIMIT,
        code: error.dcode,
        display: {
          title: 'ストリーミングエラー',
          text: error.message,
        },
      };
    } else if (error.code == 2058) {
      return {
        type: ERROR.META_NOT_DELIVERY_STARTED,
        code: error.dcode,
        display: {
          title: 'ストリーミングエラー',
          text: error.message,
        },
      };
    } else if (error.code == 6) {
      return {
        code: error.dcode,
        display: {
          title: 'ストリーミングエラー',
          text: '申し訳ございません。WOWOWシステムに接続できませんでした。お時間をおいて再度お試しください。',
        },
      };
    } else if (error.code === ERROR.LICENSE_INVALID) {
      return {
        type: ERROR.LICENSE_INVALID,
        code: error.dcode,
        display: {
          title: '認証エラー',
          text: 'このアカウントではこの動画を視聴することはできません。',
        },
        howToPlayInfo: error.object,
      };
    } else if (isEntitlementSuspend(error.code)) {
      // 権利が一時停止状態である場合
      return {
        code: error.dcode,
        display: {
          title: '認証エラー',
          text: error.message,
        },
      };
    } else if (String(error.code).match(/^w-.*/)) {
      return {
        code: error.code,
        display: {
          title: 'WOWOW 再生エラー',
          text: error.message,
        },
      };
    } else if (error.dcode) {
      return {
        code: error.dcode,
        display: {
          title: 'ストリーミングエラー',
          text: error.message,
        },
      };
    } else if (typeof error.code === 'number') {
      return {
        code: error.code,
        display: {
          title: 'ストリーミングエラー',
          text: error.message,
        },
      };
    }
  }
  if (
    error === ERROR.UNAUTHORIZED ||
    error === ERROR.CONTENTS_PROTECTION ||
    error === ERROR.EIRIN ||
    error === ERROR.START_OVER ||
    error === ERROR.NOT_PLAYABLE
  ) {
    return {
      type: error,
    };
  } else if (error === ERROR.META_NOT_VIEWABLE) {
    return {
      type: ERROR.META_NOT_VIEWABLE,
      display: {
        title: 'エラー',
        text: '現在この作品は視聴することができません。',
      },
    };
  } else if (error === ERROR.RATING_UNMATCH) {
    return {
      code: error,
      display: {
        title: '認証エラー',
        text:
          'ペアレンタルコントロールで設定している年齢制限を超えているため、この作品を再生することができません。ペアレンタルコントロールの詳細はヘルプセンターをご覧ください。',
      },
      type: error,
    };
  } else if (error === ERROR.LOCAL_TIME_INVALID) {
    return {
      code: error,
      display: {
        title: '再生エラー',
        text: '端末時刻がずれています。端末時刻を直したうえで再度お試しください。',
      },
      type: error,
    };
  } else if (error === ERROR.BROWSER_NOT_SUPPORT) {
    return {
      code: error,
      display: {},
      type: error,
    };
  } else if (error === ERROR.EVENT_SUSPEND) {
    return {
      code: error,
      display: {
        title: 'エラー',
        text: '現在放送を休止しております。放送の再開をお待ちください。',
      },
      type: error,
    };
  } else {
    return {
      code: error.code || 'Unknown',
      display: {
        title: 'エラー',
        text: '予期せぬエラーが発生しました、しばらくたってからご利用ください。',
      },
      type: 'ERROR',
    };
  }
};

const playContext = function(context) {
  let data = _.clone(context);
  return {
    set: function(key, val) {
      data[key] = val;
      return this;
    },
    get: function(key) {
      return data[key];
    },
    has: function(key) {
      return data[key] !== undefined;
    },
  };
};

class Watch extends React.Component {
  shouldPlayAd: boolean = false;
  static getPaths = function(models, options, props = {}) {
    let rootPath = [];
    // @ts-ignore TS2339
    if (props.id) {
      rootPath = rootPath.concat(this.getRootPath(models, options, props));
    }
    let paths = [
      rootPath.concat([
        [
          'id',
          'name',
          'shortName',
          'thumbnailUrl',
          'header',
          'free',
          'memberOnly',
          'entitledOnly',
          'titleMetaId',
          'resumePoint',
          'schemaId',
          'type',
          'leadSeasonId',
          'audioOnly',
          'liveStartMessage',
          'subscription',
          'rental',
          'keywords',
          'mylisted',
          'canMyList',
          'genres',
          'middleGenres',
          'attributes',
          'systemGenres',
          'ogImage',
          'showDeliveryEndAt',
          'recommend',
          'contentsProviderId',
          'cardInfo',
          'isMulti',
          'mentions',
          'playbackRestrictionType',
          'refId',
          'seriesMeta',
          'seasonMeta',
          'service',
          'links',
          'nextContentText',
          'wpEnableFlag',
          'wpOcId',
          'wpDoFlag',
          'languageCodes',
          'chasingPlayback',
          'svSwitch',
          'publishEndAt',
          'publishStartAt',
          'liveOlImage',
          'liveOlPosition',
          'tvodBadge',
          'communicationArea',
          'event',
          'infoVisibility',
          'infoType',
          'infoSrc',
        ],
      ]),
    ];
    paths = paths.concat(Details.getPaths(models, options, props));
    // @ts-ignore TS2339
    if (props.titleMetaId) {
      paths = paths.concat(this.getTitleMetaPaths(models, options, props));
    }
    // @ts-ignore TS2339
    if (props.leadSeasonId) {
      paths = paths.concat(this.getSeasonMetaPaths(models, options, props));
    }
    // @ts-ignore TS2339
    if (props.nextEpisodeLeadSeasonId) {
      paths = paths.concat(this.getNextEpisodeSeasonMetaPaths(models, options, props));
    }
    // @ts-ignore TS2339
    if (props.type === 'linear_channel') {
      paths = paths.concat(Simulcast.getPaths(models, options, props));
    }
    return paths
      .concat(NextEpisode.getPaths(models, options, props))
      .concat(EventInfo.getPaths(models, options, props));
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    return ['meta', props.id];
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static getPrefetchedPaths = function(models, options, props) {
    return data => {
      const meta = _.get(data.json, this.getRootPath(models, options, props));
      const nextEpisode = _.get(data.json, NextEpisode.getRootPath(models, options, props));
      props = Object.assign({}, props, {
        titleMetaId: _.get(meta, 'titleMetaId'),
        leadSeasonId: _.get(meta, 'leadSeasonId'),
        nextEpisodeLeadSeasonId: _.get(nextEpisode, 'leadSeasonId'),
        type: _.get(meta, 'type'),
      });
      return this.getPaths(models, options, props);
    };
  };

  static afterPrefetch = function(models, options, props) {
    return data => {
      // console.log(data)
      return null;
    };
  };

  static getTitleMetaRootPath = function(models, options, props) {
    return ['meta', props.titleMetaId];
  };
  static getTitleMetaPaths = function(models, options, props) {
    let titleMetaRootPath = [];
    titleMetaRootPath = titleMetaRootPath.concat(this.getTitleMetaRootPath(models, options, props));
    let titleMetaPaths = [
      titleMetaRootPath.concat([['id', 'refId', 'name', 'schemaId', 'defaultSort', 'leadEpisodeId', 'ogImage']]),
    ];
    return titleMetaPaths;
  };

  static getSeasonMetaRootPath = function(models, options, props) {
    return ['meta', props.leadSeasonId];
  };
  static getSeasonMetaPaths = function(models, options, props) {
    let seasonMetaRootPath = [];
    seasonMetaRootPath = seasonMetaRootPath.concat(this.getSeasonMetaRootPath(models, options, props));
    let seasonMetaPaths = [
      seasonMetaRootPath.concat([
        [
          'id',
          'refId',
          'name',
          'shortName',
          'schemaId',
          'defaultSort',
          'genres',
          'middleGenres',
          'titleMetaId',
          'leadEpisodeId',
        ],
      ]),
    ];
    return seasonMetaPaths;
  };

  static getNextEpisodeSeasonMetaRootPath = function(models, options, props) {
    return ['meta', props.nextEpisodeLeadSeasonId];
  };
  static getNextEpisodeSeasonMetaPaths = function(models, options, props) {
    let nextEpisodeSeasonMetaRootPath = [];
    nextEpisodeSeasonMetaRootPath = nextEpisodeSeasonMetaRootPath.concat(
      this.getNextEpisodeSeasonMetaRootPath(models, options, props),
    );
    let nextEpisodeSeasonMetaPaths = [nextEpisodeSeasonMetaRootPath.concat([['id', 'refId', 'name', 'shortName']])];
    return nextEpisodeSeasonMetaPaths;
  };

  get meta() {
    // @ts-ignore TS2551
    return this._meta;
  }

  set meta(meta) {
    // @ts-ignore TS2551
    const isMetaChanged = !_.isEmpty(this._meta) && !_.isEqual(this._meta.id, meta.id);
    // @ts-ignore TS2551
    this._meta = meta;

    // developmentモードではcostructor生成時にStrictModeによって2回実行される
    // STG環境でも動かしたいため制御する
    // @ts-ignore TS2339
    if ((!this.gtmInfo.pageview.isTriggered && this._isMounted) || isMetaChanged) {
      // @ts-ignore TS2339
      this.gtmInfo.pageview.isTriggered = true;
      this.sendToGtm('pageview');
    }
  }

  static get propTypes() {
    return {
      isBuffering: PropTypes.bool,
      isHDRSupported: PropTypes.bool,
      isVideoReadyForSeamlessTransition: PropTypes.bool,
      isVideoTransitioningSeamlessly: PropTypes.bool,
      model: PropTypes.object,
      pathEvaluator: PropTypes.object,
      playerApp: PropTypes.object,
      playerAppSubscription: PropTypes.func,
      playerSession: PropTypes.object,
      shouldFadePlayerControlsBar: PropTypes.bool,
      showInterstitialPostPlay: PropTypes.bool,
      showSeamlessControls: PropTypes.bool,
      showSeamlessExperience: PropTypes.bool,
      showEpisodeNumber: PropTypes.bool,
    };
  }

  static get defaultProps() {
    return {
      isKidsPage: false,
      showEpisodeNumber: true,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      history: PropTypes.object,
      isKidsPage: PropTypes.bool,
      models: PropTypes.object,
      playerApp: PropTypes.object,
      postPlayerApp: PropTypes.object,
      routeHandler: PropTypes.object.isRequired,
      cookies: PropTypes.object,
      columnsInRow: PropTypes.number,
      isIframe: PropTypes.bool,
      authApp: PropTypes.object,
      gtmApp: PropTypes.object,
      watchPartyApp: PropTypes.object,
      fsApp: PropTypes.object,
    };
  }

  static get childContextTypes() {
    return {
      node: PropTypes.object,
      isKidsPage: PropTypes.bool.isRequired,
    };
  }

  getChildContext() {
    return {
      // @ts-ignore TS2339
      node: this.props.model.node,
      // @ts-ignore TS2339
      isKidsPage: this.props.isKidsPage,
    };
  }

  private model: Falcor.Model;
  private axios: Axios;
  playbackUsecase: PlaybackUsecase;
  tokenApp: TokenApp;

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.playerRef = React.createRef();
    // @ts-ignore TS2339
    this.containerRef = React.createRef();
    // @ts-ignore TS2339
    this.belowPlayerRef = React.createRef();

    this.onPlayerReady = this.onPlayerReady.bind(this);
    this.onPlayerSeeking = this.onPlayerSeeking.bind(this);
    this.onPlayerDurationChange = this.onPlayerDurationChange.bind(this);
    this.onPlayerFirstplay = this.onPlayerFirstplay.bind(this);
    this.onPlayerTimeUpdate = this.onPlayerTimeUpdate.bind(this);
    this.onPlayerTimeout = this.onPlayerTimeout.bind(this);
    this.onPlayerEnded = this.onPlayerEnded.bind(this);
    this.onPlayerLoaded = this.onPlayerLoaded.bind(this);
    this.onPlayerPause = this.onPlayerPause.bind(this);
    this.onPlayerPlay = this.onPlayerPlay.bind(this);
    this.onPostplay = this.onPostplay.bind(this);
    this.onPlayerPlaying = this.onPlayerPlaying.bind(this);
    this.setFatalError = this.setFatalError.bind(this);
    this.exitPlayer = this.exitPlayer.bind(this);
    this.closeOverlay = this.closeOverlay.bind(this);
    // this.showInfo = this.showInfo.bind(this);
    this.onUserActivityChanged = this.onUserActivityChanged.bind(this);
    this.onClickReload = this.onClickReload.bind(this);
    this.handleWindowUnload = this.handleWindowUnload.bind(this);
    this.doErrorAction = this.doErrorAction.bind(this);
    this.playNextEpisode = this.playNextEpisode.bind(this);
    this.showUpnextView = this.showUpnextView.bind(this);
    this.hideUpnextView = this.hideUpnextView.bind(this);
    this.hidePlayerNextEpisode = this.hidePlayerNextEpisode.bind(this);
    this.toggleAutoplay = this.toggleAutoplay.bind(this);
    this.exitFullScreen = this.exitFullScreen.bind(this);
    this.changeEventData = this.changeEventData.bind(this);
    this.onResume = this.onResume.bind(this);
    this.onSuspend = this.onSuspend.bind(this);
    this.handleMounted = this.handleMounted.bind(this);
    this.handleFallbackModal = this.handleFallbackModal.bind(this);
    this.handleMediaChange = this.handleMediaChange.bind(this);
    this.handleGCastEnd = this.handleGCastEnd.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.windowFocus = this.windowFocus.bind(this);
    this.windowBlur = this.windowBlur.bind(this);
    this.showCatchupModal = this.showCatchupModal.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);

    this.onChangeWatchInfoTab = this.onChangeWatchInfoTab.bind(this);
    this.onClickMoreInfo = this.onClickMoreInfo.bind(this);

    this.handleCommentButtonClick = this.handleCommentButtonClick.bind(this);
    this.handleWatchPartyModeChange = this.handleWatchPartyModeChange.bind(this);
    this.wpJoinAnnouncement = this.wpJoinAnnouncement.bind(this);
    this.wpLeaveAnnouncement = this.wpLeaveAnnouncement.bind(this);
    this.wpForceClosed = this.wpForceClosed.bind(this);
    this.wpLinkConfirm = this.wpLinkConfirm.bind(this);
    this.wpCommand = this.wpCommand.bind(this);
    this.wpSystem = this.wpSystem.bind(this);
    this.setPsidTimer = this.setPsidTimer.bind(this);
    this.clearPsidTimer = this.clearPsidTimer.bind(this);
    this.tokenCheck = _.throttle(this.tokenCheck.bind(this), 1000, { leading: true, trailing: true });

    this.handlePlayerSideListModeChange = this.handlePlayerSideListModeChange.bind(this);

    this.checkModal = this.checkModal.bind(this);

    this.closeCommentDrawer = this.closeCommentDrawer.bind(this);
    this.openCommentDrawer = this.openCommentDrawer.bind(this);
    this.handleCloseSideInfo = this.handleCloseSideInfo.bind(this);
    this.scrollTop = this.scrollTop.bind(this);

    this.axios = new Axios({ xhr: true });
    this.playbackUsecase = new PlaybackUsecase(new BffPlaybackApi({ xhr: true }));
    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    // this.model = props.model ? props.model : (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.checkMediaQuery = _.throttle(this.checkMediaQuery.bind(this), 300, { leading: true, trailing: true });
    // @ts-ignore TS2339
    this.reauthRetryCount = 0;
    // @ts-ignore TS2339
    this.__sessionOpenErrorRetryCount = 0;
    // @ts-ignore TS2551
    this.closePlayerNextEpisode = false;

    this.state = _.clone(initialState);
    // @ts-ignore TS2339
    this.state.watchSpMode = false;
    try {
      // @ts-ignore TS2339
      this.state.mode = JSON.parse(context.cookies.get('ui')).playerMode || this.state.mode;
      // @ts-ignore TS2339
      if (this.state.mode === 'expand') {
        // @ts-ignore TS2339
        this.state.mode = 'zapping';
      }
    } catch (e) {
      // @ts-ignore TS2339
      this.state.mode = 'zapping';
    }
    // @ts-ignore TS2339
    if (this.state.mode == 'zapping') {
      // @ts-ignore TS2339
      this.state.mode = 'theater';
    }
    // @ts-ignore TS2339
    MODE = this.state.mode;

    let enableAutoplay = true;
    try {
      enableAutoplay = JSON.parse(context.cookies.get('ui')).enableAutoplay;
      if (typeof enableAutoplay !== 'boolean') {
        enableAutoplay = true;
      }
    } catch (e) {}
    // @ts-ignore TS2339
    this.state.enableAutoplay = enableAutoplay;
    // iframeは連続再生させない
    // @ts-ignore TS2339
    if (context.isIframe) this.state.enableAutoplay = false;

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);

    // @ts-ignore TS2339
    this.gtmInfo = {
      pageview: {
        isTriggered: false,
      },
    };
    // @ts-ignore TS2339
    this.meta = this.model.getSync(rootPath);
    const userInfo = this.context.getModelData('userInfo');
    // console.log(this.meta)

    // @ts-ignore TS2339
    this.state.generation = this.model.getVersion(rootPath);
    // @ts-ignore TS2339
    this.state.eventData = null;
    const eirinConfirmed = _.get(context.routeHandler, 'query.ec');
    // @ts-ignore TS2339
    if (eirinConfirmed !== undefined) this.state.eirinConfirmed = eirinConfirmed;
    this.initSyncData(props, context);
    // @ts-ignore TS2339
    this.useStartOverError = false;
  }

  initSyncData(props, context = this.context) {
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    this.meta = this.model.getSync(rootPath);
    const nextEpisodeRootPath = NextEpisode.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    let nextEpisodeData = this.model.getSync(nextEpisodeRootPath);
    if (
      _.get(nextEpisodeData, 'cardInfo.deliveryStartAt') &&
      getIsDelivery(nextEpisodeData.cardInfo.deliveryStartAt, nextEpisodeData.cardInfo.deliveryEndAt)
    ) {
      // @ts-ignore TS2339
      this.nextEpisode = nextEpisodeData;
    } else {
      // @ts-ignore TS2339
      delete this.nextEpisode;
    }
    if (this.meta) {
      // if (this.meta.schemaId === 10){
      //   throw new NotFoundError({
      //     redirect_link: routes.featureDetail.makePath({ id: props.id }),
      //   });
      // }
      if (this.meta.type === 'group') {
        throw new NotFoundError({
          // @ts-ignore TS2554
          redirect_link: routes.title.makePath({ id: props.id }),
        });
      }
      if (this.meta.titleMetaId) {
        // @ts-ignore TS2339
        const titleMetaRootPath = this.constructor.getTitleMetaRootPath(
          context.models,
          {},
          { titleMetaId: this.meta.titleMetaId },
        );
        // @ts-ignore TS2339
        this.titleMeta = this.model.getSync(titleMetaRootPath);
        // @ts-ignore TS2339
      } else if (this.titleMeta) {
        // @ts-ignore TS2339
        delete this.titleMeta;
      }
      if (this.meta.leadSeasonId) {
        // @ts-ignore TS2339
        const seasonMetaRootPath = this.constructor.getSeasonMetaRootPath(
          context.models,
          {},
          { leadSeasonId: this.meta.leadSeasonId },
        );
        // @ts-ignore TS2339
        this.seasonMeta = this.model.getSync(seasonMetaRootPath);
        // @ts-ignore TS2339
      } else if (this.seasonMeta) {
        // @ts-ignore TS2339
        delete this.seasonMeta;
      }
      // 次のエピソードのシーズンと現在のメタのシーズンが違う場合の時のため
      // @ts-ignore TS2339
      if (_.get(this.nextEpisode, 'leadSeasonId') && this.nextEpisode.leadSeasonId != this.meta.leadSeasonId) {
        // @ts-ignore TS2339
        const seasonMetaRootPath = this.constructor.getNextEpisodeSeasonMetaRootPath(
          context.models,
          {},
          // @ts-ignore TS2339
          { nextEpisodeLeadSeasonId: this.nextEpisode.leadSeasonId },
        );
        // @ts-ignore TS2339
        this.nextEpisodeSeasonMeta = this.model.getSync(seasonMetaRootPath);
        // @ts-ignore TS2339
      } else if (this.nextEpisodeSeasonMeta) {
        // @ts-ignore TS2339
        delete this.nextEpisodeSeasonMeta;
      }
      if (this.meta.type === 'linear_channel') {
        const linearChannelMetasRootPath = Simulcast.getRootPath(context.models, {}, {});
        // @ts-ignore TS2339
        const linearChannelMetas = _.values(this.model.getSync(linearChannelMetasRootPath));
        // @ts-ignore TS2339
        this.linearChannelMetaIds = _.map(linearChannelMetas, channel => channel.id);
      }
    }
    const newState = _.pick(initialState, ['watchPartyMode', 'watchPartyType', 'commentActive']);
    const userInfo = context.getModelData('userInfo');
    const browserInfo = context.getModelData('browserInfo');
    if (this.meta && !!this.meta.wpEnableFlag && userInfo.status !== 'NON_REGISTERED_MEMBER') {
      // 未ログイン時は使わない
      // エピソード
      if (this.meta.schemaId === 3) {
        newState.watchPartyType = 'closeType';
        // エピソード以外
      } else {
        // ルームIDがあればOK
        newState.watchPartyType = !!this.meta.wpOcId ? 'openType' : 'none';
      }
      if (newState.watchPartyType === 'openType') {
        newState.watchPartyMode = true;
      }
      if (!browserInfo.isIOS && !browserInfo.isAndroid) {
        newState.commentActive = !!this.meta.wpDoFlag;
      } else {
        // this.openCommentDrawer();
      }
      // chat を優先する
      const chatType = _.get(context.routeHandler, 'query.chat');
      if (chatType === 'on') {
        if (!browserInfo.isIOS && !browserInfo.isAndroid) {
          newState.commentActive = true;
        } else {
          // this.openCommentDrawer();
        }
      } else if (chatType === 'off') {
        if (!browserInfo.isIOS && !browserInfo.isAndroid) {
          newState.commentActive = false;
        }
      }
    }
    // @ts-ignore TS2339
    if (this._isMounted) {
      if (newState.watchPartyMode && newState.watchPartyType === 'openType') {
        this.context.watchPartyApp.roomId = this.meta.wpOcId;
      }
      this.setState(newState);
    } else {
      Object.assign(this.state, newState);
    }
  }

  setPsidTimer(psid) {
    this.clearPsidTimer();
    // @ts-ignore TS2551
    this.psidTimer = setTimeout(() => {
      // タブにフォーカス時のみセットする
      // blur -> focusの順番は担保されていないので1000msはセットしない
      if (document.hasFocus()) psidDataStore.set(psid);
    }, 1000);
  }

  clearPsidTimer() {
    // @ts-ignore TS2551
    if (this.psidTimer) {
      // @ts-ignore TS2551
      clearTimeout(this.psidTimer);
      // @ts-ignore TS2551
      delete this.psidTimer;
    }
  }

  windowFocus() {
    // @ts-ignore TS2339
    if (this.playbackSessionId) {
      // @ts-ignore TS2339
      this.setPsidTimer(this.playbackSessionId);
    }
    // @ts-ignore TS2551
    if (this.windowBlurDate) {
      // 戻ってきた時に保持した日付とcookieの日付を比較
      const reloadNeedDate = this.context.cookies.get(RELOAD_NEED_DATE);
      // 未来の時にリフレッシュ、過去の場合は先方から戻ってきた後に離脱した場合
      // @ts-ignore TS2551
      if (reloadNeedDate && this.windowBlurDate < reloadNeedDate) {
        // TODO sync してlocalのデータを更新
        this.reflash();
      }
    }
  }

  windowBlur() {
    // 離脱する時の時間を保持
    // @ts-ignore TS2551
    this.windowBlurDate = new Date().getTime();
    this.clearPsidTimer();
    // @ts-ignore TS2339
    if (this.playbackSessionId) {
      psidDataStore.remove();
    }
  }

  componentDidMount() {
    if (!this.meta || Object.keys(this.meta).length === 0) {
      // componentWillMountでスローしたいが、SSRのタイミングでも呼ばれるので不可
      // https://reactjs.org/docs/error-boundaries.html
      // @ts-ignore TS2554
      throw new NotFoundError();
    }

    // @ts-ignore TS2339
    if (this.isSpBrowser && this.state.enableAutoplay) {
      this.setState({ enableAutoplay: false });
    }
    // const { routeHandler, history } = this.context;
    // let playbackRate = _.get(routeHandler, 'query.r', null);
    // if (playbackRate !== null) {
    //   delete routeHandler.query.r;
    //   const to = URLGenerator.createRelative({
    //     path: routeHandler.path,
    //     query: routeHandler.query,
    //   });
    //   history.replace(to, { norender: true });
    //   this.setState({playbackRate});
    // }

    window.addEventListener('unload', this.handleWindowUnload, false);
    if (this.isSpBrowser) {
      browserEvents.addEventListener('orientationchange', this.checkMediaQuery);
      Visibly.onVisible(this.onResume);
      Visibly.onHidden(this.onSuspend);
    } else {
      browserEvents.addEventListener('resize', this.checkMediaQuery);
    }
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props, this.context);
    this.checkMediaQuery();

    // @ts-ignore TS2339
    if (this.state.watchPartyMode && this.state.watchPartyType === 'openType') {
      this.context.watchPartyApp.roomId = _.get(this.meta, 'wpOcId');
      this.context.watchPartyApp.init();
    }
    this.context.watchPartyApp.on('joinAnnouncement', this.wpJoinAnnouncement);
    this.context.watchPartyApp.on('leaveAnnouncement', this.wpLeaveAnnouncement);
    this.context.watchPartyApp.on('forceClosed', this.wpForceClosed);
    this.context.watchPartyApp.on('linkConfirm', this.wpLinkConfirm);
    this.context.watchPartyApp.on('command', this.wpCommand);
    this.context.watchPartyApp.on('system', this.wpSystem);

    window.addEventListener('focus', this.windowFocus, false);
    window.addEventListener('blur', this.windowBlur, false);
  }

  wpJoinAnnouncement(data) {
    // console.log('Watch: wpJoinAnnouncement', data);

    // open型はスルー
    // @ts-ignore TS2339
    if (this.state.watchPartyType == 'openType') return;

    // マスターはplayerの状態を通知する
    if (this.context.watchPartyApp.isMaster()) {
      this.context.watchPartyApp.postSystem(
        'initPlayer',
        {
          userId: data.sender.id,
          // @ts-ignore TS2339
          currentTime: this.__player.currentTime(),
          // @ts-ignore TS2339
          paused: this.__player.paused(),
        },
        (error, body) => {
          if (error) {
            console.log(error, body);
          }
        },
      );
    }
  }

  wpLeaveAnnouncement(data) {
    // console.log('Watch: wpLeaveAnnouncement', data);

    // 自分自身が退室
    const activeProfile = this.context.authApp.activeProfile();
    if (activeProfile && activeProfile.id == data.sender.refId) {
      this.context.watchPartyApp.leave((error, body) => {
        if (error) {
          console.log(error, body);
        }
      });
      return;
    }

    // open型はスルー
    // @ts-ignore TS2339
    if (this.state.watchPartyType == 'openType') return;

    // masterの通知は不要
    if (WatchPartyApp.isSenderMaster(data)) {
      // masterが退室した時は強制終了
      this.context.watchPartyApp.forceClose();
    }
  }

  wpLinkConfirm(linkProps) {
    // console.log('Watch: wpLinkConfirm');
    // @ts-ignore TS2339
    const props = { closeModal: this.props.closeModal };
    // MainViewLinkを利用しない場合はコールバックで処理する
    if (typeof linkProps === 'function') {
      // @ts-ignore TS2339
      props.linkCallback = linkProps;
    } else {
      // @ts-ignore TS2339
      props.linkProps = linkProps;
    }
    // @ts-ignore TS2339
    this.props.showModal(<RoomEnd {...props} />);
  }

  wpForceClosed() {
    // console.log('Watch: wpForceClosed');
    // @ts-ignore TS2554
    this.handleWatchPartyModeChange();
    // @ts-ignore TS2339
    this.props.showModal(<RoomClosed closeModal={this.props.closeModal} />, { classes: 'watchParty-modal' });
  }

  wpCommand(data) {
    // console.log('Watch: wpCommand', data);

    // 自分のコマンドはスルー
    const activeProfile = this.context.authApp.activeProfile();
    if (activeProfile && activeProfile.id == data.sender.refId) return;

    if (data.event === 'reload') {
      this.reflash();
    } else if (data.event === 'play') {
      // @ts-ignore TS2339
      this.__player.play();
    } else if (data.event === 'pause') {
      // @ts-ignore TS2339
      this.__player.pause();
    } else if (data.event === 'seek') {
      // @ts-ignore TS2339
      this.__player.currentTime(data.customData.currentTime);
    }
  }

  wpSystem(data) {
    // console.log('Watch: wpSystem', data);

    if ('initPlayer' === data.event) {
      // @ts-ignore TS2339
      this.__player.currentTime(data.customData.currentTime);
      if (data.customData.paused) {
        // @ts-ignore TS2339
        this.__player.pause();
      } else {
        // @ts-ignore TS2339
        this.__player.play();
      }
    } else if ('kicked' === data.event) {
      // masterから追い出された時は強制終了
      this.context.watchPartyApp.forceClose();
    } else if ('resumed' === data.event) {
      // resumedというイベントはスマホから発火される
      // マスターはplayerの状態を通知する
      this.context.watchPartyApp.postSystem(
        'initPlayer',
        {
          userId: data.sender.id,
          // @ts-ignore TS2339
          currentTime: this.__player.currentTime(),
          // @ts-ignore TS2339
          paused: this.__player.paused(),
        },
        (error, body) => {
          if (error) {
            console.log(error, body);
          }
        },
      );
    }
  }

  checkModal() {
    // @ts-ignore TS2339
    this.props.showModal(<CheckModal closeModal={this.props.closeModal} />);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // @ts-ignore TS2339
    if (nextProps.id !== this.props.id) {
      // @ts-ignore TS2339
      this.__sessionOpenErrorRetryCount = 0;
      // @ts-ignore TS2554
      this.bookmark();
      this.shouldPlayAd = false;
      // @ts-ignore TS2339
      if (this.state.dispose) {
        // @ts-ignore TS2339
        this.state.dispose();
      }
      // @ts-ignore TS2339
      if (this.__$promise && this.__$promise.dispose) {
        // @ts-ignore TS2339
        this.__$promise.dispose();
      }
      // @ts-ignore TS2339
      if (this.__player) {
        // @ts-ignore TS2339
        if (this.reauthTimeoutID) {
          // @ts-ignore TS2339
          window.clearTimeout(this.reauthTimeoutID);
          // @ts-ignore TS2339
          this.reauthTimeoutID = undefined;
        }
      }
      this.context.watchPartyApp.dispose();
      const initialWpState = _.pick(initialState, ['watchPartyMode', 'watchPartyType', 'commentActive']);
      this.setState(
        {
          ...initialWpState,
          maybeEnableStartOver: null,
        },
        () => {
          this.initSyncData(nextProps);
          this.reflash(nextProps);
        },
      );
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    this.updateHeader(nextState);
  }

  componentDidUpdate() {
    // @ts-ignore TS2339
    if (this.state.fetchDataError instanceof NotFoundError) {
      // @ts-ignore TS2339
      throw this.state.fetchDataError;
    }

    let uiConfig = {};
    try {
      uiConfig = JSON.parse(this.context.cookies.get('ui'));
    } catch (e) {}
    // @ts-ignore TS2339
    uiConfig.playerMode = MODE;
    this.context.cookies.set('ui', JSON.stringify(uiConfig), { maxAge: 60 * 60 * 24 * 365, path: '/' });
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (typeof this.props.closeModal === 'function') this.props.closeModal();
    const browserInfo = this.context.getModelData('browserInfo');
    if (this.isSpBrowser) {
      browserEvents.removeEventListener('orientationchange', this.checkMediaQuery);
      Visibly.offVisible(this.onResume);
      Visibly.offHidden(this.onSuspend);
    } else {
      browserEvents.removeEventListener('resize', this.checkMediaQuery);
    }
    window.removeEventListener('unload', this.handleWindowUnload, false);
    // 自動で連続再生する場合、endedで処理済みなので呼ばない
    // @ts-ignore TS2339
    if (!this.autoPlayNext) this.bookmark();
    // @ts-ignore TS2339
    if (this.state.dispose) {
      // @ts-ignore TS2339
      this.state.dispose();
    }
    // @ts-ignore TS2339
    if (this.__$promise && this.__$promise.dispose) {
      // @ts-ignore TS2339
      this.__$promise.dispose();
    }

    // @ts-ignore TS2339
    if (this.reauthTimeoutID) {
      // @ts-ignore TS2339
      window.clearTimeout(this.reauthTimeoutID);
      // @ts-ignore TS2339
      this.reauthTimeoutID = undefined;
    }

    this.clearPsidTimer();
    // @ts-ignore TS2339
    this.props.closeDrawer();

    document.body.classList.remove('header-invisible');

    this.context.watchPartyApp.off('joinAnnouncement', this.wpJoinAnnouncement);
    this.context.watchPartyApp.off('leaveAnnouncement', this.wpLeaveAnnouncement);
    this.context.watchPartyApp.off('forceClosed', this.wpForceClosed);
    this.context.watchPartyApp.off('linkConfirm', this.wpLinkConfirm);
    this.context.watchPartyApp.off('command', this.wpCommand);
    this.context.watchPartyApp.off('system', this.wpSystem);
    this.context.watchPartyApp.dispose();

    window.removeEventListener('focus', this.windowFocus, false);
    window.removeEventListener('blur', this.windowBlur, false);
  }

  redirect() {
    const query = this.context.routeHandler.query;
  }

  dispose() {
    // console.log('Watch.dispose');

    // playback_session_idを保持
    // const playbackSessionId = this.state.playContext ? this.state.playContext.get('session').id : null;
    // if (window.navigator.cookieEnabled && playbackSessionId) {
    //   this.context.cookies.set(PSID_KEY, playbackSessionId, {path: '/'});
    // }

    // リロード時に視聴位置が0になるケースがあるのでcookieに保存する
    // 一旦追加条件を視聴履歴追加と合わせる
    if (window.navigator.cookieEnabled && this.canAddResume()) {
      // @ts-ignore TS2339
      let resumePoint = this.__currentTime;
      // @ts-ignore TS2339
      if (this.state.playContext && _.get(this.state.playContext.get('playbackRule'), 'enableSeek') === false) {
        // ライブではないかつシークできないものもここに入るが、運用上ないと思われるので無視
        resumePoint = 0;
      }
      // @ts-ignore TS2554
      const profile = activeProfile(this.context.models);
      const cookieResumePointKey = profile
        ? `${RESUME_POINT_KEY}-${profile.id}-${this.meta.id}`
        : `${RESUME_POINT_KEY}-anon-${this.meta.id}`;
      this.context.cookies.set(cookieResumePointKey, resumePoint, {
        expires: datetime()
          .add(10, 'minutes')
          .toDate(),
      });
    }

    // @ts-ignore TS2554
    this.bookmark();
  }

  autoplayNextTitle() {}

  setFatalError(error) {
    // session/open でエラーした時はauthからやり直す
    // @ts-ignore TS2339
    if (error === ERROR.SESSION_OPEN && this.__sessionOpenErrorRetryCount < 3) {
      // @ts-ignore TS2339
      this.__sessionOpenErrorRetryCount++;
      this.reflash();
      return;
    }

    // playback_session_idを保持
    // const playbackSessionId = this.state.playContext ? this.state.playContext.get('session').id : null;
    // if (window.navigator.cookieEnabled && playbackSessionId) {
    //   this.context.cookies.set(PSID_KEY, playbackSessionId, {path: '/'});
    // }

    // @ts-ignore TS2339
    delete this.__player;
    this.exitFullScreen();
    if (error.responseURL && error.status == 401) {
      this.setState({ error: playError(ERROR.UNAUTHORIZED) });
      // スマホで再生できない場合はアプリ導線を表示する
    } else if (this.isSpBrowser) {
      this.setState({ error: playError(ERROR.CONTENTS_PROTECTION) });
    } else {
      this.setState({ error: playError(error) });
    }
  }

  updateHeader(nextState = this.state) {
    // @ts-ignore TS2339
    if (nextState.showActionOverlay) {
      document.body.classList.add('header-invisible');
      // @ts-ignore TS2339
    } else if (nextState.playing && nextState.mode === 'expand') {
      document.body.classList.add('header-invisible');
      // @ts-ignore TS2339
    } else if (nextState.userActive) {
      document.body.classList.remove('header-invisible');
      // @ts-ignore TS2339
    } else if (nextState.userActive === false && nextState.mode === 'expand') {
      document.body.classList.add('header-invisible');
    } else {
      document.body.classList.remove('header-invisible');
    }
  }

  // 視聴履歴
  // meta.typeがlinearまたはお試しの場合は視聴履歴を残さない
  canAddResume() {
    // @ts-ignore TS2339
    if (this.state.maybeEnableStartOver) return false;
    if (!this.context.getModelData('authContext')) return false;
    // @ts-ignore TS2339
    if (!this.__player) return false;

    // リニアチャンネルメタまたはキャスト中の場合
    if ((this.meta && this.meta.type === 'linear_channel') || this.context.playerApp.isGCasted()) return false;
    // お試しの場合
    if (this.meta && this.meta.schemaId === 6) return false;

    return true;
  }

  // 視聴中
  // meta.typeがlinearまたはお試しの場合は視聴中を残さない
  canBookmark() {
    // @ts-ignore TS2339
    if (this.state.maybeEnableStartOver) return false;
    if (!this.context.getModelData('authContext')) return false;
    // @ts-ignore TS2339
    if (!this.__player) return false;

    // リニアチャンネルメタまたはキャスト中の場合
    if ((this.meta && this.meta.type === 'linear_channel') || this.context.playerApp.isGCasted()) return false;
    // お試しの場合
    if (this.meta && this.meta.schemaId === 6) return false;

    return true;
  }

  bookmark(meta_id, bookmark_meta_id, time) {
    // console.log('bookmark:', meta_id, bookmark_meta_id, time)

    if (!this.canBookmark()) return;

    const authContext = this.context.getModelData('authContext');

    // console.log('bookmark', arguments, this.__currentTime, this.__player);
    // @ts-ignore TS2339
    if (this.meta && this.__currentTime && this.__player) {
      if (meta_id === undefined) {
        meta_id = _.compact([this.meta.titleMetaId, this.meta.leadSeasonId]);
        // @ts-ignore TS2339
        bookmark_meta_id = this.props.id;
        // @ts-ignore TS2339
        time = this.__currentTime;

        // 5秒未満は追加しない
        if (time < 5) return;

        const cacheBookmark = (args = {}) => {
          // 検索用タイトルの視聴エピソードを修正
          if (this.meta.titleMetaId) {
            model
              .set({
                path: ['meta', this.meta.titleMetaId, 'viewingEpisode'],
                // @ts-ignore TS2339
                value: { $type: 'ref', value: ['meta', args.bookmarkMetaId] },
              })
              .then(done => {});
          }

          // 1つの上の階層にも追加して置く
          if (this.meta.leadSeasonId) {
            model
              .set({
                path: ['meta', this.meta.leadSeasonId, 'viewingEpisode'],
                // @ts-ignore TS2339
                value: { $type: 'ref', value: ['meta', args.bookmarkMetaId] },
              })
              .then(done => {});
          }

          // 視聴中のレジュームははbookmarkPositionに保存
          model
            .set({
              // @ts-ignore TS2339
              path: ['meta', args.bookmarkMetaId, 'bookmarkPosition'],
              // @ts-ignore TS2339
              value: { $type: 'atom', value: args.bookmarkPosition },
            })
            .then(done => {});
        };
        // endedと同様次のエピソードを入れる
        // console.log(`isEnding: ${this.isEnding()}`)
        // @ts-ignore TS2339
        const model = this.model.withoutDataSource();
        // @ts-ignore TS2339
        if (this.nextEpisode && this.isEnding()) {
          meta_id = _.compact([this.meta.titleMetaId, this.meta.leadSeasonId]);
          // @ts-ignore TS2339
          bookmark_meta_id = this.nextEpisode.id;
          time = 0;

          // @ts-ignore TS2339
          cacheBookmark({ bookmarkMetaId: this.nextEpisode.id, bookmarkPosition: time });
        } else if (this.isEnding()) {
          // 次のエピソードがなかったら削除する
          // @ts-ignore TS2554
          this.deleteBookmark();
          return;
        } else {
          // DVR不可ライブの場合
          if (
            // @ts-ignore TS2339
            this.__duration === Infinity &&
            // @ts-ignore TS2339
            this.state.playContext &&
            // @ts-ignore TS2339
            !_.get(this.state.playContext.get('playbackRule'), 'enableStartOver', false)
          ) {
            time = 0;
          }
          // @ts-ignore TS2339
          cacheBookmark({ bookmarkMetaId: this.props.id, bookmarkPosition: time });
        }
      }
    }

    if (!meta_id) return;

    // タイトルメタがシリーズの時は、自身のメタも登録する。
    // if (this.titleMeta.schemaId == 1) {
    //   meta_id = meta_id.concat(this.props.id);
    // }

    if (typeof meta_id === 'object' && meta_id[0]) {
      meta_id = _.uniq(meta_id).join(',');
    }
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    const params = {
      meta_id: meta_id,
      bookmark_meta_id: bookmark_meta_id,
      user_id: profile.id,
      position: parseInt(time, 10),
    };

    let cmsService = Object.assign({}, this.context.getModelData('services', 'cms'));
    cmsService.pathname = _.join(_.concat(cmsService.path, 'users/viewing_bookmarks/add'), '/');

    const query = Object.keys(params)
      .map(function(key) {
        return key + '=' + encodeURIComponent(params[key]);
      })
      .join('&');

    if (window.fetch) {
      // console.log('b add',query)
      window
        .fetch(url.format(cmsService), {
          method: 'POST',
          // mode: "cors", // no-cors, cors, *same-origin
          // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          // credentials: "same-origin", // include, same-origin, *omit
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${authContext.token}`,
            'X-User-Id': authContext.id,
            'X-Session-Token': authContext.sessionToken,
          },
          // redirect: "follow", // manual, *follow, error
          // referrer: "no-referrer", // no-referrer, *client
          body: query,
          // @ts-ignore TS2339
          keepalive: this.__unloading,
        })
        .then(response => {
          // console.log(response);
          if (response.ok) {
            // HTTP ステータスコードが 200 から 299
            return response.json();
          }
          throw new Error('Network response was not ok.');
        })
        .then(response => {
          // console.log(response);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      const xhr = new XMLHttpRequest();
      // @ts-ignore TS2339
      xhr.open('POST', url.format(cmsService), !this.__unloading);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      xhr.send(query);
    }

    // キャッシュされている視聴中一覧を削除する
    // @ts-ignore TS2339
    this.model.invalidate(['continuewatching']);
  }

  deleteBookmark(meta_id) {
    // console.log('deleteBookmark:', meta_id)

    if (!this.canBookmark()) return;
    const authContext = this.context.getModelData('authContext');

    // @ts-ignore TS2339
    if (this.meta && this.__player && meta_id === undefined) {
      meta_id = _.compact([this.meta.titleMetaId, this.meta.leadSeasonId]);
    }

    if (!meta_id) return;

    // @ts-ignore TS2339
    const model = this.model.withoutDataSource();
    if (this.meta.titleMetaId) {
      model.invalidate(['meta', this.meta.titleMetaId, 'viewingEpisode']);
    }
    // @ts-ignore TS2339
    if (this.titleMeta.leadEpisodeId) {
      // @ts-ignore TS2339
      model.invalidate(['meta', this.titleMeta.leadEpisodeId, 'bookmarkPosition']);
    }
    if (this.meta.leadSeasonId) {
      model.invalidate(['meta', this.meta.leadSeasonId, 'viewingEpisode']);
    }
    // @ts-ignore TS2339
    if (this.seasonMeta && this.seasonMeta.leadEpisodeId) {
      // @ts-ignore TS2339
      model.invalidate(['meta', this.seasonMeta.leadEpisodeId, 'bookmarkPosition']);
    }

    if (typeof meta_id === 'object' && meta_id[0]) {
      meta_id = meta_id.join(',');
    }
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    const params = {
      meta_id: meta_id,
      user_id: profile.id,
    };

    let cmsService = Object.assign({}, this.context.getModelData('services', 'cms'));
    cmsService.pathname = _.join(_.concat(cmsService.path, 'users/viewing_bookmarks/delete'), '/');

    const query = Object.keys(params)
      .map(function(key) {
        return key + '=' + encodeURIComponent(params[key]);
      })
      .join('&');

    if (window.fetch) {
      // console.log('b add',query)
      window
        .fetch(url.format(cmsService), {
          method: 'POST',
          // mode: "cors", // no-cors, cors, *same-origin
          // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          // credentials: "same-origin", // include, same-origin, *omit
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${authContext.token}`,
            'X-User-Id': authContext.id,
            'X-Session-Token': authContext.sessionToken,
          },
          // redirect: "follow", // manual, *follow, error
          // referrer: "no-referrer", // no-referrer, *client
          body: query,
          // @ts-ignore TS2339
          keepalive: this.__unloading,
        })
        .then(response => {
          // console.log(response);
          if (response.ok) {
            // HTTP ステータスコードが 200 から 299
            return response.json();
          }
          throw new Error('Network response was not ok.');
        })
        .then(response => {
          // console.log(response);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      const xhr = new XMLHttpRequest();
      // @ts-ignore TS2339
      xhr.open('POST', url.format(cmsService), !this.__unloading);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      xhr.send(query);
    }

    // キャッシュされている視聴中一覧を削除する
    // @ts-ignore TS2339
    this.model.invalidate(['continuewatching']);
  }

  handleWindowUnload() {
    // @ts-ignore TS2339
    this.__unloading = true;
    this.dispose();
  }

  onUserActivityChanged(isActive) {
    this.setState({ userActive: isActive });
  }

  onPlayerReady(player) {
    //console.log('Watch: onPlayerReady');
    // @ts-ignore TS2339
    this.__sessionOpenErrorRetryCount = 0;
    // @ts-ignore TS2339
    this.__firstplay = false;
    // @ts-ignore TS2339
    this.__seekedToBookmarkPosition = false;
    // @ts-ignore TS2339
    this.__player = player;

    const { routeHandler, history } = this.context;
    let roomId = _.get(routeHandler, 'query.wp', null);
    if (roomId !== null) {
      delete routeHandler.query.wp;
      const to = URLGenerator.createRelative({
        path: routeHandler.path,
        query: routeHandler.query,
      });
      history.replace(to, { norender: true });

      // アプリケーション設定で閉じている場合参加者の処理をしない
      const closeFlag = this.context.watchPartyApp.getCloseFlag();
      // @ts-ignore TS2339
      if (this.state.watchPartyType !== 'closeType' || !closeFlag) return;

      this.context.watchPartyApp
        .doRoomInfo(roomId, true)
        .then(room => {
          // @ts-ignore TS2339
          if (this.metadata.id == room.customData.metaId) {
            // metaが一致している場合
            if (Date.now() < room.endAt) {
              // roomが終了していない場合、モダルを表示
              // @ts-ignore TS2339
              this.props.showModal(
                <WatchPartyModal
                  // @ts-ignore TS2339
                  player={this.__player}
                  // @ts-ignore TS2339
                  showModal={this.props.showModal}
                  // @ts-ignore TS2339
                  closeModal={this.props.closeModal}
                  titleModel={this.metadata}
                  // @ts-ignore TS2339
                  seriesModel={this.series || {}}
                  // @ts-ignore TS2339
                  linearModel={this.state.eventData}
                  handleWatchPartyModeChange={this.handleWatchPartyModeChange}
                  // @ts-ignore TS2322
                  watchPartyMode={this.state.watchPartyMode}
                />,
                { classes: 'watchParty-modal' },
              );
            } else {
              // roomがすでに終了した場合
              // @ts-ignore TS2339
              this.props.showModal(<RoomNotfound closeModal={this.props.closeModal} />, {
                classes: 'watchParty-modal',
              });
            }
          } else {
            // metaIdが一致しない場合
            this.context.watchPartyApp.dispose();
          }
        })
        .catch(e => {
          console.log(e);
          // roomが存在しない場合 statusCode: 404
          // @ts-ignore TS2339
          this.props.showModal(<RoomNotfound closeModal={this.props.closeModal} />, { classes: 'watchParty-modal' });
        });
    }
  }

  onPlayerSeeking() {
    //console.log('Watch: onPlayerSeeking');
    // upnextViewのautoplayを停止
    this.setState({ cancelAutoplay: true });
  }

  onPlayerDurationChange() {
    // console.log('Watch: onPlayerDurationChange', this.__player.duration());
    // @ts-ignore TS2339
    const duration = this.__player.duration();
    if (duration > 0) {
      // @ts-ignore TS2339
      this.__duration = duration;

      // episodeRuntimeが取得できるまでの暫定対応
      // episodeRuntimeが0の場合かつdurationが存在する場合
      // episodeRuntimeを設定してシークさせる
      // 尺が短いとreflashでは不安定な為
      // if (this.__duration !== Infinity && this.meta.episodeRuntime == 0) {
      //   this.meta.episodeRuntime = this.__duration;
      //   this.model.withoutDataSource().set({
      //     path: ['meta', this.constructor.getIdInSchema(this.props), 'episodeRuntime'],
      //     value: {$type: 'atom', value: this.__duration}
      //   }).then((done) => {});
      //   const startTime = this.getStartTime();
      //   this.__player.currentTime(startTime);
      // }
    }
  }

  onPlayerFirstplay() {
    //console.log('Watch: onPlayerFirstplay');
    // @ts-ignore TS2339
    this.__firstplay = true;
  }

  onPlayerLoaded() {
    //console.log('Watch: onPlayerLoaded');
    // @ts-ignore TS2339
    delete this.__currentTime;

    // 同じメタ内で複数試合を配信する場合
    let matchStartTime;
    if (_.has(this.context.routeHandler.query, QUERY.MATCH_START_TIME)) {
      matchStartTime = parseInt(_.get(this.context.routeHandler.query, QUERY.MATCH_START_TIME), 10);
      if (_.isNaN(matchStartTime)) {
        matchStartTime = null;
      }
      delete this.context.routeHandler.query[QUERY.MATCH_START_TIME];
      // @ts-ignore TS2554
      this.handleChangeQuery();
    }

    // DVR設定あり
    // @ts-ignore TS2339
    if (this.state.maybeEnableStartOver) {
      // ライブ
      // @ts-ignore TS2339
      if (this.__player.duration() == Infinity && !this.state.startOverConfirmed) {
        // @ts-ignore TS2339
        if (this.useStartOverError) {
          const newState = { error: playError(ERROR.START_OVER) };

          // @ts-ignore TS2339
          if (this._isMounted) this.setState(newState);
          else this.state = Object.assign(newState);

          return false;
        }

        const onClick = fromStart => {
          return () => {
            // @ts-ignore TS2339
            this.__player.play();
            // 開始時間がうわがかれる場合があるのでtimeoutする
            setTimeout(() => {
              try {
                if (fromStart) {
                  // @ts-ignore TS2339
                  const seekableStart = this.__player.seekable().start(0);
                  if (matchStartTime) {
                    // @ts-ignore TS2339
                    this.__player.currentTime(seekableStart + matchStartTime);
                  } else {
                    // @ts-ignore TS2339
                    this.__player.currentTime(seekableStart);
                  }
                } else {
                  // @ts-ignore TS2339
                  this.__player.currentTime(this.__player.seekable().end(0));
                }
              } catch (e) {}
              this.setState({
                startOverConfirmed: true,
                playerModal: null,
                maybeEnableStartOver: null,
              });
            }, 500);
          };
        };

        const textFromStart = matchStartTime != null ? '試合の最初から再生' : '最初から再生';
        this.setState({
          playerModal: (
            <StartOverModal
              onClickFromStart={onClick(true)}
              onClickFromLive={onClick(false)}
              textFromStart={textFromStart}
            />
          ),
        });
        // ライブ以外
      } else {
        this.setState({ maybeEnableStartOver: null }, () => {
          // @ts-ignore TS2339
          // this.__player.play();
        });
      }
    }
    // const browserInfo = this.context.getModelData('browserInfo');
    // const { routeHandler, history } = this.context;

    // // t パラメータが存在するときは、それを指定する
    // // 但し、duration以内の場合に限る
    // let t = _.get(routeHandler, 'query.t', null);
    // // t パラメータを消す
    // if (t !== null) {
    //   delete routeHandler.query.t;
    //   const to = URLGenerator.createRelative({
    //     path: routeHandler.path,
    //     query: routeHandler.query,
    //   });
    //   history.replace(to, { norender: true });
    // }
    // let time = parseInt(t, 10);
    // if (isNaN(time)) time = -1;
    // if (this.__gCastCurrentTime) {
    //   time = this.__gCastCurrentTime;
    //   delete this.__gCastCurrentTime;
    // }
    // if (this.__player && !this.__seekedToBookmarkPosition && time >= 0 && this.__player.duration() !== Infinity && this.__player.duration() > time) {
    //   this.__seekedToBookmarkPosition = true;
    //   if (time === 0) {
    //     if (!browserInfo.isSafari || (browserInfo.isSafari && parseInt(browserInfo.major, 10) <= 10)) {
    //       // console.log('Watch: play 1')
    //       this.__player.play();
    //     }
    //   } else {
    //     this.__player.currentTime(time);
    //   }
    //   return false;
    // }

    // if (this.__player && !this.__seekedToBookmarkPosition && this.meta.bookmarkPosition) {
    //   this.__seekedToBookmarkPosition = true;
    //   const duration = this.__player.duration();
    //   if (duration === Infinity) {
    //     if (this.__player.seekable().end(0) >= this.meta.bookmarkPosition &&
    //         this.__player.seekable().start(0) <= this.meta.bookmarkPosition) {
    //       this.__player.currentTime(this.meta.bookmarkPosition);
    //       return false;
    //     }
    //   } else if (duration > 0) {
    //     // falcorのcacheを更新した後に再度再生させる時の対処
    //     let bookmarkPosition = Math.min(duration - 1, this.meta.bookmarkPosition);
    //     if (bookmarkPosition < 0) bookmarkPosition = 0;
    //     this.__player.currentTime(bookmarkPosition);
    //     return false;
    //   }
    // }

    // this.__seekedToBookmarkPosition = true;
    // if (!browserInfo.isSafari || (browserInfo.isSafari && parseInt(browserInfo.major, 10) <= 10)) {
    //   // console.log('Watch: play 2')
    //   this.__player.play();
    // }
  }

  onPlayerEnded() {
    // console.log('Watch: onPlayerEnded');

    this.setState({ playbackEnded: true, playing: false });

    // @ts-ignore TS2339
    let nextEpisodeId = this.nextEpisode ? this.nextEpisode.id : 0;

    // ローカルの視聴履歴を更新する
    if (nextEpisodeId && this.context.getModelData('authContext')) {
      // @ts-ignore TS2339
      const model = this.model.withoutDataSource();
      // 検索用タイトルの視聴エピソードを修正
      if (this.meta.titleMetaId) {
        model
          .set({
            path: ['meta', this.meta.titleMetaId, 'viewingEpisode'],
            value: { $type: 'ref', value: ['meta', nextEpisodeId] },
          })
          .then(done => {});
      }

      // 1つの上の階層にも追加して置く
      if (this.meta.leadSeasonId) {
        model
          .set({
            path: ['meta', this.meta.leadSeasonId, 'viewingEpisode'],
            value: { $type: 'ref', value: ['meta', nextEpisodeId] },
          })
          .then(done => {});
      }

      this.bookmark([this.meta.titleMetaId, this.meta.leadSeasonId], nextEpisodeId, 0);
    } else {
      // 次のエピソードがなかったら削除する
      // @ts-ignore TS2554
      this.deleteBookmark();
    }

    // 次の動画を表示する
    // @ts-ignore TS2339
    if (!this.state.showNextEpisode) {
      this.setState({ showNextEpisode: true, autoplayOffsetSeconds: 0 });
    }

    // コントローラ上の次の動画を表示する
    this.showUpnextView();

    // @ts-ignore TS2339
    this.__firstplay = false;
  }

  onPlayerPlay() {
    //console.log('Watch: onPlayerPlay');
    this.setState({ playing: true });
  }

  onPlayerPause() {
    //console.log('Watch: onPlayerPause');
    this.setState({ playing: false });
  }

  onPlayerTimeout() {}

  onPlayerPlaying() {
    //console.log('Watch: onPlayerPlaying');
    // @ts-ignore TS2339
    if (this.state.error) {
      this.setState({ error: null });
    }
  }

  onPlayerTimeUpdate(currentTime) {
    // console.log('Watch: onPlayerTimeUpdate');
    // @ts-ignore TS2339
    if (this.__unloading) return;
    // @ts-ignore TS2339
    if (!this._isMounted) return;

    // コントローラー上の次の動画を消す
    // @ts-ignore TS2339
    if (this.state.upnextView) {
      this.hideUpnextView();
    }

    // @ts-ignore TS2339
    this.__currentTime = currentTime;

    if (this.canAddResume()) {
      // @ts-ignore TS2339
      const model = this.model.withoutDataSource();

      let resumePoint = currentTime;
      if (
        // @ts-ignore TS2339
        this.__duration === Infinity &&
        // @ts-ignore TS2339
        this.state.playContext &&
        // @ts-ignore TS2339
        !_.get(this.state.playContext.get('playbackRule'), 'enableStartOver', false)
      ) {
        resumePoint = 0;
      }
      // 未ログインの場合はキャッシュに再生位置を保持しない
      model
        .set({
          // @ts-ignore TS2339
          path: ['meta', this.props.id, 'resumePoint'],
          value: { $type: 'atom', value: resumePoint },
        })
        .then(done => {});
    }

    // 視聴中のキャッシュの更新は次のepを入れる処理同様にbookmark関数で行う
    // 本来視聴中epが更新されないはずのタイミングでキャッシュだけ更新されてしまうことがあるため
    // if (this.canBookmark() && currentTime >= 5) {
    //   // ローカルの視聴中を更新する
    //   if (this.meta) {
    //     // 検索用タイトルの視聴エピソードを修正
    //     if (this.meta.titleMetaId) {
    //       model.set({
    //         path: ['meta', this.meta.titleMetaId, 'viewingEpisode'],
    //         value: {$type: 'ref', value: ['meta', this.props.id]}
    //       }).then((done) => {});
    //     }

    //     // 1つの上の階層にも追加して置く
    //     if (this.meta.leadSeasonId) {
    //       model.set({
    //         path: ['meta', this.meta.leadSeasonId, 'viewingEpisode'],
    //         value: {$type: 'ref', value: ['meta', this.props.id]}
    //       }).then((done) => {});
    //     }
    //   }
    // }

    // 次の動画を表示する
    // @ts-ignore TS2339
    if (this.__player && this.__player.duration() > 0 && this.__player.duration() !== Infinity && this.meta) {
      const newState = {};
      // ending_start_position: エンドクレジットの場所
      // @ts-ignore TS2339
      let endingStartPosition = _.get(this.state.playContext.get('video'), 'mediaValues.endingStartPosition', 0);
      // 2秒後に出す
      if (endingStartPosition > 0) endingStartPosition = endingStartPosition + 2;
      // エンドクレジットが登録されていない場合
      if (endingStartPosition == 0)
        // @ts-ignore TS2339
        endingStartPosition = _.max([this.__player.duration() * 0.95, this.__player.duration() - 60.0]); // 総尺の95%と1分の大きい方
      // @ts-ignore TS2339
      let contentEndMillisecond = endingStartPosition ? this.__player.duration() - endingStartPosition : 0;
      if (
        // @ts-ignore TS2339
        !this.state.showNextEpisode &&
        this.meta &&
        // @ts-ignore TS2339
        Math.floor(this.__player.duration() - currentTime) <= contentEndMillisecond
      ) {
        // @ts-ignore TS2339
        newState.showNextEpisode = true;
        // @ts-ignore TS2339
        newState.autoplayOffsetSeconds = contentEndMillisecond;
        // @ts-ignore TS2551
        this.closePlayerNextEpisode = false;
      }
      if (
        // @ts-ignore TS2551
        !this.closePlayerNextEpisode &&
        // @ts-ignore TS2339
        !this.state.showPlayerNextEpisode &&
        this.meta &&
        // @ts-ignore TS2339
        Math.floor(this.__player.duration() - currentTime) <= contentEndMillisecond
      ) {
        // @ts-ignore TS2339
        newState.showPlayerNextEpisode = true;
        // @ts-ignore TS2339
        newState.autoplayOffsetSeconds = contentEndMillisecond;
      } else if (
        // @ts-ignore TS2551
        (this.closePlayerNextEpisode || this.state.showPlayerNextEpisode) &&
        // @ts-ignore TS2339
        Math.floor(this.__player.duration() - currentTime) > contentEndMillisecond
      ) {
        // @ts-ignore TS2339
        newState.showPlayerNextEpisode = false;
        // @ts-ignore TS2551
        this.closePlayerNextEpisode = false;
      }
      if (!_.isEmpty(newState)) {
        this.setState(newState);
      }
    }

    // オープニングスキップの表示
    // @ts-ignore TS2339
    if (this.__player && this.__player.duration() > 0 && this.__player.duration() !== Infinity && this.meta) {
      // 現在の再生秒が　start_position秒以上、end_position秒未満　だったらボタンを表示
      // 押したらend_position秒までシーク
      // @ts-ignore TS2339
      let videoSkips = _.get(this.state.playContext.get('video'), 'mediaValues.videoSkips');

      if (!!videoSkips && videoSkips.length > 0) {
        const videoSkip = _.find(videoSkips, position => {
          return position.start <= currentTime && currentTime <= position.end;
        });
        if (videoSkip) {
          // @ts-ignore TS2339
          if (this.state.videoSkip) {
            // @ts-ignore TS2339
            if (this.state.videoSkip.idx != videoSkip.idx) {
              this.setState({ videoSkip });
            }
          } else {
            this.setState({ videoSkip });
          }
          // @ts-ignore TS2339
        } else if (this.state.videoSkip) {
          this.setState({ videoSkip: null });
        }
      }
    }
  }

  onPostplay() {}

  // showInfo() {
  //   this.setState({mode: "zapping"});
  // }

  restartPlayer() {}

  exitPlayer() {
    const { routeHandler, history } = this.context;
    if (history.length > 1 && !!window.history.state) {
      history.goBack();
    } else {
      if (this.meta && this.meta.titleMetaId) {
        // @ts-ignore TS2554
        history.replace(routes.title.makePath({ id: this.meta.titleMetaId }));
      } else {
        const homeRoute = getHomeRoute(this.context.models, this.context.isKidsPage);
        history.replace(homeRoute.makePath());
      }
    }
  }

  checkMediaQuery() {
    // if (window.matchMedia('screen and (min-width: 900px)').matches) {
    //   this.setState({mode: MODE, enableToggleMode: true});
    // } else {
    //   this.setState({mode: "zapping", enableToggleMode: false});
    // }

    let columnsInRow = 1;
    let watchSpMode = false;
    let mediaQueryCheck = 1;
    if (window.matchMedia('screen and (min-width: 2400px)').matches) {
      columnsInRow = 7;
      mediaQueryCheck = 1;
    } else if (window.matchMedia('screen and (min-width: 1800px) and (max-width: 2399px)').matches) {
      columnsInRow = 6;
      mediaQueryCheck = 1;
    } else if (window.matchMedia('screen and (min-width: 1320) and (max-width: 1799px)').matches) {
      columnsInRow = 5;
      mediaQueryCheck = 1;
    } else if (window.matchMedia('screen and (min-width: 1024px) and (max-width: 1319px)').matches) {
      columnsInRow = 4;
      mediaQueryCheck = 1;
    } else if (window.matchMedia('screen and (min-width: 768px) and (max-width: 1023px)').matches) {
      columnsInRow = 3;
      mediaQueryCheck = 2;
    } else if (window.matchMedia('screen and (max-width: 767px)').matches) {
      columnsInRow = 2;
      mediaQueryCheck = 2;
    }
    // @ts-ignore TS2339
    if (columnsInRow !== this.state.columnsInRow) {
      this.setState({ columnsInRow });
    }
    // @ts-ignore TS2339
    if (mediaQueryCheck !== this.state.mediaQueryCheck) {
      this.setState({ mediaQueryCheck });
    }

    if (window.matchMedia('screen and (max-width: 1023px)').matches) {
      watchSpMode = true;
    } else {
      watchSpMode = false;
    }
    // @ts-ignore TS2339
    if (this._isMounted) this.setState({ watchSpMode });
    // @ts-ignore TS2339
    else this.state.watchSpMode = watchSpMode;
  }

  // toggleMode() {
  //   if (this.state.mode == "zapping") {
  //     MODE = "theater";
  //   } else {
  //     MODE = "zapping";
  //   }
  //   this.setState({mode: MODE})
  // }

  closeOverlay() {
    // @ts-ignore TS2339
    if (this._isMounted) {
      this.setState({ showActionOverlay: false });
    }
  }

  isEnding() {
    // console.log('isEnding', this.__duration, _.get(this.meta, 'cardInfo.episodeRuntime'));

    // const episodeRuntime = this.__player.duration() && this.__player.duration() !== Infinity ? this.__player.duration() : _.get(this.meta, 'cardInfo.episodeRuntime');

    // ライブはfalse
    // @ts-ignore TS2339
    if (this.__duration && this.__duration === Infinity) {
      return false;
    }
    // @ts-ignore TS2339
    const episodeRuntime = this.__duration || _.get(this.meta, 'cardInfo.episodeRuntime');

    if (episodeRuntime == 0) return false;

    // 全体の95%を計算
    // 再生時間の残り5%が1分を超えていたら、（全体の動画時間 - 1分）を95%とする
    const closeToTheEndPosition = episodeRuntime * 0.05 > 60.0 ? episodeRuntime - 60.0 : episodeRuntime * 0.95;

    // 全体の95%を超えた状態、あるいは、エンドクレジットを超えた状態
    // @ts-ignore TS2339
    const endingStartPosition = this.state.playContext
      ? // @ts-ignore TS2339
        _.get(this.state.playContext.get('video'), 'mediaValues.endingStartPosition', 0)
      : 0;
    return (
      // @ts-ignore TS2339
      (!_.isNaN(closeToTheEndPosition) && this.__currentTime > closeToTheEndPosition) ||
      // @ts-ignore TS2339
      (endingStartPosition > 0 && this.__currentTime > endingStartPosition)
    );
  }

  playNextEpisode(query = {}) {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    // @ts-ignore TS2339
    if (!this.nextEpisode) return;

    // 連続再生時はPIPをやめる
    if (this.context.playerApp.isInPictureInPicture()) {
      this.context.playerApp.exitPictureInPicture();
    }

    if (this.context.playerApp.isGCasted()) {
      // 連続再生時は処理しない
      if (this.context.playerApp.castPlayer().playerState() != GCastPlayerApp.PLAYER_STATE.UPNEXT) {
        this.context.playerApp.gCastAutoplay(true);
      }
    }

    // 再生速度
    // query.r = this.context.playerApp.playbackRate();

    if (!_.has(query, 'sc')) {
      // @ts-ignore TS2339
      query.sc = 1;
    }

    // iframe
    // @ts-ignore TS2339
    if (this.context.isIframe) query.mode = 'iframe';

    let to = routes.watchNow;
    // @ts-ignore TS2339
    let id = this.nextEpisode.id;
    // @ts-ignore TS2339
    if (this.nextEpisode.refId) {
      to = routes.content;
      // @ts-ignore TS2339
      id = this.nextEpisode.refId;
    }
    // @ts-ignore TS2339
    if (this.state.eirinConfirmed) query.ec = this.state.eirinConfirmed;
    // @ts-ignore TS2339
    delete this.nextEpisode;
    // @ts-ignore TS2554
    this.context.history.replace(to.makePath({ id: id }, query));
  }

  showUpnextView() {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    // @ts-ignore TS2339
    if (!this.nextEpisode) return;

    // キャスト再生中の場合
    if (this.context.playerApp.isGCasted()) {
      // @ts-ignore TS2339
      if (this.state.enableAutoplay) {
        this.setState({ showPlayerNextEpisode: false });
      } else {
        this.setState({ upnextView: true, showPlayerNextEpisode: false, cancelAutoplay: false });
      }
      return;
    }

    // 右下の次の動画が表示されたままの場合、もしくは手で閉じられた場合
    if (
      // @ts-ignore TS2339
      !this.state.watchPartyMode &&
      // @ts-ignore TS2339
      this.state.enableAutoplay &&
      // @ts-ignore TS2339
      (this.state.showPlayerNextEpisode || this.closePlayerNextEpisode)
    ) {
      this.setState({ showPlayerNextEpisode: false }, () => {
        if (this.isSpBrowser) return; // スマホ時のブラウザ再生では強制的に連続再生させない
        // @ts-ignore TS2339
        this.autoPlayNext = true;
        // この場合は次のepを0秒から
        this.playNextEpisode({ t: 0, sc: 0 });
      });
      return;
    }

    this.setState({ upnextView: true, showPlayerNextEpisode: false, cancelAutoplay: false });
  }

  hideUpnextView() {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    // @ts-ignore TS2339
    if (!this.nextEpisode) return;

    setTimeout(() => {
      this.setState({ upnextView: false });
    }, 100);
  }

  hidePlayerNextEpisode() {
    // @ts-ignore TS2551
    this.closePlayerNextEpisode = true;
    this.setState({ showPlayerNextEpisode: false });
  }

  toggleAutoplay(autoplay) {
    let uiConfig = {};
    try {
      uiConfig = JSON.parse(this.context.cookies.get('ui'));
    } catch (e) {}
    // @ts-ignore TS2339
    uiConfig.enableAutoplay = autoplay;
    this.context.cookies.set('ui', JSON.stringify(uiConfig), { maxAge: 60 * 60 * 24 * 365, path: '/' });

    this.setState({ enableAutoplay: autoplay });
  }

  getFullScreenNode() {
    return document.getElementById('appMountPoint');
  }

  exitFullScreen() {
    this.context.playerApp.exitFullScreen();
  }

  showCatchupModal() {
    const close = () => {
      this.setState({ showCatchupMsg: true });
      // @ts-ignore TS2339
      this.props.closeModal();
    };
    const switchToCatchup = () => {
      // @ts-ignore TS2339
      this.props.closeModal();
      // @ts-ignore TS2554
      let to = routes.watchNow.makePath({ id: this.state.eventData.catchupMeta.metaId });
      // @ts-ignore TS2339
      if (this.state.eventData.catchupMeta.refId) {
        // @ts-ignore TS2554
        to = routes.content.makePath({ id: this.state.eventData.catchupMeta.refId });
      }
      this.context.history.replace(to);
    };
    // TDOO コンポーネントにする
    // @ts-ignore TS2339
    this.props.showModal(
      <React.Fragment>
        <div className="modal-text">
          {/*
           // @ts-ignore TS2322 */}
          <p class="text text-center">
            この番組は放送同時配信の対象外ですが、オンデマンド配信をしています。
            <br />
            オンデマンド配信視聴に切り替えますか？
          </p>
        </div>
        <div className="btn-block">
          <button className="btn btn-gray" name="fallbackModalCancel" onClick={close}>
            キャンセル
          </button>
          <button className="btn btn-fill" name="fallbackModalOk" onClick={switchToCatchup}>
            切り替えて視聴する
          </button>
        </div>
      </React.Fragment>,
      { outsideClose: close },
    );
  }

  changeEventData(eventData) {
    if (!eventData) {
      this.setState({ eventData });
      return;
    }

    // @ts-ignore TS2339
    if (!this.state.eventData || this.state.eventData.uniqueId === eventData.uniqueId) return;

    // 配信対象外かつキャッチアップメタが配信期間中の場合
    if (eventData.isMask && eventData.isCatchup) {
      // ダイアログを表示する
      this.showCatchupModal();
      return;
    }

    // アクティブなプロフィールに設定されている視聴対象制限とメタのratingを比較する
    // const profile = activeProfile(this.context.models);
    // if (this.state.playing && !this.state.error && !isValidRating(profile.rating, eventData.rating)) {
    //   // リロードすることで停止させる
    //   window.location.reload();
    // } else

    // TODO: 処理を共通化する
    // const profile = activeProfile(this.context.models);
    // if (profile) {
    //   if (!_.includes(eventData.permitUserStatuses, permitUserStatus(profile))) {
    //     // リフレッシュすることで停止させる
    //     this.reflash();
    //     return;
    //   }
    // }

    // 休止フラグの判定
    // if ((this.state.playing && !this.state.error && eventData.isSuspend) || (!this.state.playing && !eventData.isSuspend)) {
    //   // フラグに変更がある場合はリフレッシュさせる
    //   this.reflash();
    // } else {
    this.setState({ eventData });
    // }
  }

  onResume() {
    //console.log('Visibly: onVisible');
    // @ts-ignore TS2339
    if (this.__player) return;
    this.reflash();
  }

  onSuspend() {
    //console.log('Visibly: onHidden');
    // @ts-ignore TS2339
    if (!this.__player) return;
    // @ts-ignore TS2339
    if (this.reauthTimeoutID) {
      // @ts-ignore TS2339
      window.clearTimeout(this.reauthTimeoutID);
      // @ts-ignore TS2339
      this.reauthTimeoutID = undefined;
    }
    // @ts-ignore TS2339
    this.props.closeDrawer();
    this.setState({ suspend: true }, () => {
      // @ts-ignore TS2339
      delete this.__player;
    });
  }

  reflash(props = this.props) {
    // playback_session_idを保持
    // const playbackSessionId = this.state.playContext ? this.state.playContext.get('session').id : null;
    // if (window.navigator.cookieEnabled && playbackSessionId) {
    //   this.context.cookies.set(PSID_KEY, playbackSessionId, {path: '/'});
    // }

    this.state = _.assign(
      this.state,
      _.omit(initialState, [
        'mode',
        'enableAutoplay',
        'generation',
        'watchPartyMode',
        'watchPartyType',
        'commentActive',
      ]),
    );
    // @ts-ignore TS2551
    this.closePlayerNextEpisode = false;
    // @ts-ignore TS2339
    this.setState({ error: null, reflashing: !!this.playerRef.current });
    // if (this.props.closeModal) this.props.closeModal();
    this.fetchData(props, this.context);
  }

  handleGCastEnd(option) {
    if (option.currentTime) {
      // @ts-ignore TS2339
      this.__gCastCurrentTime = option.currentTime;
    }
    this.reflash();
  }

  handleFallbackModal() {
    const fallbackModalAlert = this.context.cookies.get(FALLBACK_MODAL_ALERT);
    if (fallbackModalAlert !== 'true') {
      // 1年後
      this.context.cookies.set(FALLBACK_MODAL_ALERT, true, {
        path: '/',
        expires: datetime()
          .add(1, 'years')
          .toDate(),
      });
      // @ts-ignore TS2339
      this.props.showModal(<FallbackModal closeModal={this.props.closeModal} />);
    }
  }

  handleMediaChange(mediaId) {
    // console.log('handleMediaChange')
    const query = this.context.routeHandler.query;
    query.m = mediaId;
    // @ts-ignore TS2554
    let to = routes.watchNow.makePath({ id: this.meta.id }, query);
    if (this.meta.refId) {
      // @ts-ignore TS2554
      to = routes.content.makePath({ id: this.meta.refId }, query);
    }
    this.context.history.replace(to, { norender: true });
    // @ts-ignore TS2339
    if (this.__player && this.__player.isGCasted()) {
      // @ts-ignore TS2339
      this.__player.gCastAutoplay(true);
      // @ts-ignore TS2339
      const video = this.state.playContext.get('video');
      video.multiView = (
        // @ts-ignore TS2322
        <MultiView id={this.meta.id} mediaId={mediaId} model={this.model} onClick={this.handleMediaChange} />
      );
      // @ts-ignore TS2339
      this.state.playContext.set('video', video);
      this.setState({
        // @ts-ignore TS2339
        playContext: _.clone(this.state.playContext),
      });
    } else {
      this.reflash();
    }
  }

  scrollTop() {
    if (window.scrollY < 0) return;
    window.scroll({ top: 0, behavior: 'smooth' });
  }
  openCommentDrawer() {
    const targetEl = document.querySelector('.below-player');
    // @ts-ignore TS2339
    this.props.openDrawer(
      <Comment
        // @ts-ignore TS2322
        player={this.__player}
        // @ts-ignore TS2339
        watchPartyType={this.state.watchPartyType}
        handleWatchPartyModeChange={this.handleWatchPartyModeChange}
        // @ts-ignore TS2339
        linearModel={this.state.eventData}
        titleModel={this.metadata}
        // @ts-ignore TS2339
        seriesModel={this.series || {}}
        // @ts-ignore TS2339
        showModal={this.props.showModal}
        // @ts-ignore TS2339
        closeModal={this.props.closeModal}
        hiddenRoomName={true}
      />,
      {
        targetElement: targetEl,
        // @ts-ignore TS2339
        behindElementRefs: [this.belowPlayerRef],
        title:
          // @ts-ignore TS2339
          this.props.watchPartyType == 'closeType' ? this.context.watchPartyApp.getRoom().name : 'オープンチャット',
      },
    );
    this.scrollTop();
  }
  closeCommentDrawer() {
    // @ts-ignore TS2339
    this.props.closeDrawer();
  }

  handleCloseSideInfo(e) {
    this.setState({ infoContent: null });
    // @ts-ignore TS2339
    this.props.closeDrawer();
  }

  handleCommentButtonClick(e) {
    // @ts-ignore TS2339
    if (this.state.commentActive) {
      this.setState({ commentActive: false });
    } else {
      this.setState({ commentActive: true, watchInfoTabKey: 'info' });
    }
  }

  handleWatchPartyModeChange(e) {
    // @ts-ignore TS2339
    if (this.state.watchPartyMode) {
      this.setState({ watchPartyMode: false, commentActive: false });
    } else {
      this.setState({ watchPartyMode: true, commentActive: true });
    }
  }

  onClickReload() {
    // 最後連続10秒seekすると参加者側にはendedにならないケース
    // マスターがリプレイした時に参加者側が最初から再生できない為
    this.context.watchPartyApp.postCommand('reload', {}, (error, body) => {
      if (error) {
        console.log(error, body);
      }
    });

    this.reflash();
  }

  changeDescription() {
    // @ts-ignore TS2339
    return (this.meta.schemaId === 3 ? `「${this.metadata.title}」` : `「${this.metadata.name}」`) + '動画配信中！';
  }

  handleMounted(mounted) {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    if (!mounted) {
      this.setState({ reflashing: false });
    }
  }

  handleChangeQuery(query) {
    const { routeHandler, history } = this.context;
    query = Object.assign({}, routeHandler.query, query);
    history.replace(routeHandler.route.makePath({ id: routeHandler.params.id }, query), { norender: true });
  }

  handlePlayerSideListModeChange() {
    // @ts-ignore TS2339
    if (this.state.playerSideListMode) {
      this.setState({ playerSideListMode: false });
    } else {
      this.setState({ playerSideListMode: true });
    }
  }

  sendToGtm(event) {
    if (!_.get(this.context, 'gtmApp')) return;
    const meta = _.get(this.meta, 'onair') || this.meta;
    const hasRelationProgram = _.get(meta, 'seasonMeta') && _.get(meta, 'seriesMeta');
    const channel = _.get(meta, 'linearChannelMeta');
    const relationProgram = hasRelationProgram
      ? {
          refId: _.get(meta, 'seriesMeta.refId'),
          name: _.get(meta, 'seriesMeta.name'),
        }
      : null;
    const program = {
      refId: _.get(meta, 'seasonMeta.refId') || _.get(meta, 'seriesMeta.refId'),
      name: _.get(meta, 'seasonMeta.name') || _.get(meta, 'seriesMeta.name'),
    };
    const content = _.isEmpty(channel)
      ? {
          refId: _.get(meta, 'refId'),
          name: _.get(meta, 'name'),
          rental: _.get(meta, 'rental'),
          subscription: _.get(meta, 'subscription'),
        }
      : {
          refId: _.get(meta, 'uniqueId'),
          name: _.get(meta, 'name'),
          rental: false,
          subscription: true, //一旦リニアはsvodとしてga連携する
        };
    const attributes = _.get(meta, 'attributes');
    const genres = _.get(meta, 'genres');
    const middleGenres = _.get(meta, 'middleGenres');
    const schemaId = _.get(meta, 'schemaId');
    if (event !== 'pageview') {
      this.context.gtmApp.pushDataLayerOnContentPageClick(event, {
        relationProgram,
        program,
        content,
        channel,
        attributes,
        genres,
        middleGenres,
        schemaId,
      });
    } else {
      this.context.gtmApp.pageView(_.get(this.metadata, 'name'), {
        relationProgram,
        program,
        content,
        channel,
        attributes,
        genres,
        middleGenres,
        schemaId,
      });
    }
  }
  onChangeWatchInfoTab(tabKey) {
    this.setState({ watchInfoTabKey: tabKey });
  }
  onClickMoreInfo() {
    this.onChangeWatchInfoTab('info');
    // @ts-ignore
    const belowPlayer = DOMUtils.getRect(this.belowPlayerRef.current);
    if (belowPlayer) {
      const targetTop = belowPlayer.top + window.pageYOffset;
      // @ts-ignore
      const headerHeight = (DOMUtils.getRect(this.props.headerRef.current) || {}).height || 0;
      const top = targetTop - headerHeight - 20;
      document.documentElement.scrollTo({ top, behavior: 'smooth' });
    }
  }
  render() {
    // @ts-ignore TS2339
    if (this.state.suspend) return null;
    if (!this.meta || Object.keys(this.meta).length === 0) return null;
    const userInfo = this.context.getModelData('userInfo');
    const browserInfo = this.context.getModelData('browserInfo');

    let content = null,
      player = null;
    const exitButtonElement = (
      <button
        className="touchable player-control-element player-control-close"
        // @ts-ignore TS2322
        tabIndex="0"
        role="button"
        onClick={this.closeOverlay}
      ></button>
    );

    //プレイヤー表示しない場合(新規会員登録・購入・ログイン)
    let errorType = false;
    if (
      // @ts-ignore TS2339
      (this.state.error &&
        // @ts-ignore TS2339
        (this.state.error.type == ERROR.UNAUTHORIZED ||
          // @ts-ignore TS2339
          this.state.error.type == ERROR.LICENSE_INVALID ||
          // @ts-ignore TS2339
          this.state.error.type == ERROR.META_NOT_DELIVERY_STARTED ||
          // @ts-ignore TS2339
          this.state.error.type == ERROR.NOT_STARTED_ENTITLEMENT ||
          // @ts-ignore TS2339
          this.state.error.type == ERROR.NOT_PLAYABLE)) ||
      // @ts-ignore TS2339
      getIsDeliveryEnded(this.metadata.deliveryEndAt)
    ) {
      // @ts-ignore TS2339
      if (!(browserInfo.isIOS || browserInfo.isAndroid) && this.state.commentActive) {
        //PC かつ コメントあり
        errorType = false;
        // @ts-ignore TS2339
      } else if ((browserInfo.isIOS || browserInfo.isAndroid) && this.state.commentActive) {
        //スマホ かつ コメントあり
        errorType = true;
      } else {
        errorType = true;
      }
      // } else if (this.meta && _.get(this.state, 'eventData.isMask')) {
      //   if (!(browserInfo.isIOS || browserInfo.isAndroid) && this.state.commentActive) {
      //     //PC かつ コメントあり
      //     errorType = false;
      //   } else if ((browserInfo.isIOS || browserInfo.isAndroid) && this.state.commentActive){
      //     //スマホ かつ コメントあり
      //     errorType = true;
      //   } else {
      //     errorType = true;
      //   }
    }

    let classes;
    // @ts-ignore TS2339
    if (this.state.mode === 'zapping' || this.state.mode === 'theater') {
      classes = 'shrink';
    }

    let thumbnailUrl;
    if (_.get(this.state, 'eventData.thumbnailUrl') && !_.get(this.state, 'eventData.isMask')) {
      thumbnailUrl = _.get(this.state, 'eventData.thumbnailUrl');
    } else {
      // @ts-ignore TS2339
      thumbnailUrl = this.metadata.thumbnailUrl;
    }

    let nextEpisodeTitle;
    // @ts-ignore TS2339
    if (this.nextEpisode) {
      // @ts-ignore TS2339
      nextEpisodeTitle = this.nextEpisode.name;
      // @ts-ignore TS2339
      if (this.nextEpisode.shortName !== this.nextEpisode.name) {
        // 後方にある文字を削除する
        // @ts-ignore TS2339
        const index = this.nextEpisode.name.lastIndexOf(this.nextEpisode.shortName);
        if (index > 0) {
          // @ts-ignore TS2339
          nextEpisodeTitle = _.trim(this.nextEpisode.name.slice(0, index));
        }
      }
      // shortNameとnameの関係が崩れているメタが存在するので、グループメタの情報で上書きする
      // @ts-ignore TS2339
      if (this.nextEpisodeSeasonMeta && this.nextEpisodeSeasonMeta.id != this.nextEpisode.titleMetaId) {
        // @ts-ignore TS2339
        nextEpisodeTitle = this.nextEpisodeSeasonMeta.shortName || this.nextEpisodeSeasonMeta.name;
        // @ts-ignore TS2339
      } else if (this.titleMeta) {
        // @ts-ignore TS2339
        nextEpisodeTitle = this.titleMeta.shortName || this.titleMeta.name;
      }
    }

    // @ts-ignore TS2339
    const { maybeEnableStartOver } = this.state;
    const autoplay = !maybeEnableStartOver;

    // @ts-ignore TS2339
    player = this.state.reflashing ? null : (
      <WodPlayer
        // @ts-ignore TS2339
        ref={this.playerRef}
        // @ts-ignore TS2322
        classes={classes}
        player={this.context.playerApp}
        config={this.context.getModelData('config')}
        // @ts-ignore TS2339
        startTime={this.state.startTime}
        autoplay={autoplay}
        shouldPlayAd={this.shouldPlayAd}
        // @ts-ignore TS2339
        linearChannelMetaIds={this.linearChannelMetaIds}
        maybeEnableStartOver={maybeEnableStartOver}
        // playbackRate={this.state.playbackRate}
        // @ts-ignore TS2339
        mode={this.state.mode}
        autoplayNextTitle={this.autoplayNextTitle}
        // @ts-ignore TS2339
        controls={this.useStartOverError ? !maybeEnableStartOver : true} // コントローラがちらつくので消しておく
        disabled={false}
        hasPreplay={false}
        // @ts-ignore TS2339
        playContext={this.state.playContext}
        // @ts-ignore TS2339
        activeVideo={this.state.playContext ? this.state.playContext.get('video') : null}
        metadata={this.metadata}
        // @ts-ignore TS2339
        eventData={this.state.eventData}
        // @ts-ignore TS2339
        fetching={this.state.fetching}
        artwork={thumbnailUrl}
        onGCastEnd={this.handleGCastEnd}
        onPlayerLoaded={this.onPlayerLoaded}
        onPlayerTimeUpdate={this.onPlayerTimeUpdate}
        onPlayerReady={this.onPlayerReady}
        onPlayerSeeking={this.onPlayerSeeking}
        onPlayerDurationChange={this.onPlayerDurationChange}
        onPlayerFirstplay={this.onPlayerFirstplay}
        onPlayerTimeout={this.onPlayerTimeout}
        onPlayerEnded={this.onPlayerEnded}
        onPlayerError={this.setFatalError}
        onPlayerPlay={this.onPlayerPlay}
        onPlayerPause={this.onPlayerPause}
        onPlayerPlaying={this.onPlayerPlaying}
        onCastToTarget={this.exitPlayer}
        onPostplay={this.onPostplay}
        onUserActivityChanged={this.onUserActivityChanged}
        // @ts-ignore TS2339
        onClickPlayNext={this.nextEpisode ? this.playNextEpisode : null}
        onClickReload={this.onClickReload}
        onClickCommentButton={this.handleCommentButtonClick}
        // @ts-ignore TS2339
        commentActive={this.state.commentActive}
        // @ts-ignore TS2339
        watchPartyMode={this.state.watchPartyMode}
        // @ts-ignore TS2339
        watchPartyType={this.state.watchPartyType}
        onSendToGtm={this.sendToGtm}
        getFullScreenNode={this.getFullScreenNode}
        restartPlayer={this.restartPlayer}
        // @ts-ignore TS2551
        playNextTitle={this.playNextTitle}
        shouldFadePlayerControlsBar={false}
        // @ts-ignore TS2339
        playbackEnded={this.state.playbackEnded}
        showEpisodeSelector={false}
        showTimedText={true}
        // @ts-ignore TS2339
        enableAutoplay={this.state.enableAutoplay}
        // iframeもしくはウォッチパーティの時は設定に連続再生の項目を表示させない為
        onToggleAutoplay={
          // @ts-ignore TS2339
          this.context.isIframe || this.state.watchPartyMode || this.isSpBrowser ? null : this.toggleAutoplay
        }
        // @ts-ignore TS2339
        watchSpMode={this.state.watchSpMode}
        onFallbackModal={this.handleFallbackModal}
        onMounted={this.handleMounted}
        // @ts-ignore TS2339
        isPV={this.props.isPV}
        // @ts-ignore TS2339
        isShowExerciseModal={this.state.isShowExerciseModal}
        // @ts-ignore TS2339
        showModal={this.props.showModal}
        // @ts-ignore TS2339
        closeModal={this.props.closeModal}
        // @ts-ignore TS2339
        mediaQueryCheck={this.state.mediaQueryCheck}
        playNextButtonLabel={_.get(this.meta, 'nextContentText')}
        nextEpisode={
          !this.isSpBrowser &&
          // @ts-ignore TS2339
          !this.state.watchPartyMode &&
          // @ts-ignore TS2339
          this.state.showPlayerNextEpisode &&
          // @ts-ignore TS2339
          this.state.enableAutoplay &&
          // @ts-ignore TS2339
          this.nextEpisode ? (
            <NextWatchCard
              // @ts-ignore TS2339
              id={this.nextEpisode.id}
              // @ts-ignore TS2339
              model={this.model}
              showEpisodeDuration={false}
              showEpisodeSummary={false}
              showEpisodeProgress={false}
              enableTitleEllipsis={false}
              enableCardClick={true}
              // @ts-ignore TS2322
              eirinConfirmed={this.state.eirinConfirmed}
              nextEpisodeText={_.get(this.meta, 'nextContentText')}
              watchType={'next-video'}
              // @ts-ignore TS2339
              player={this.__player}
              onClose={this.hidePlayerNextEpisode}
              title={nextEpisodeTitle}
            />
          ) : null
        }
        // @ts-ignore TS2339
        playerModal={this.state.playerModal}
        errorView={
          // @ts-ignore TS2339
          this.state.error ? (
            <ErrorView
              // @ts-ignore TS2339
              model={this.model}
              activeEpisode={this.meta}
              // @ts-ignore TS2339
              activeEvent={this.state.eventData}
              doAction={this.doErrorAction}
              // @ts-ignore TS2339
              error={this.state.error}
              // @ts-ignore TS2339
              showModal={this.props.showModal}
              // @ts-ignore TS2339
              closeModal={this.props.closeModal}
              // @ts-ignore TS2339
              commentActive={this.state.commentActive}
              // @ts-ignore TS2339
              watchPartyMode={this.state.watchPartyMode}
              checkModal={this.checkModal}
            />
          ) : null
        }
        upnextView={
          // @ts-ignore TS2339
          !this.state.watchPartyMode && this.nextEpisode && this.state.upnextView ? (
            <UpnextView
              player={this.context.playerApp}
              // @ts-ignore TS2339
              metadata={this.nextEpisode}
              // @ts-ignore TS2322
              title={nextEpisodeTitle}
              onPlay={this.playNextEpisode}
              onClickReload={this.onClickReload}
              nextButtonText={_.get(this.meta, 'nextContentText')}
              // @ts-ignore TS2339
              autoplay={this.state.enableAutoplay && !this.state.cancelAutoplay}
            />
          ) : null
        }
        watchCard={
          // @ts-ignore TS2339
          this.nextEpisode && this.nextEpisode.id ? (
            <WatchCard
              // @ts-ignore TS2339
              id={this.nextEpisode.id}
              // @ts-ignore TS2339
              model={this.model}
              enableCardClick={false}
              showEpisodeDuration={false}
              showEpisodeSummary={false}
              showEpisodeProgress={false}
              enableTitleEllipsis={false}
              // @ts-ignore TS2322
              eirinConfirmed={this.state.eirinConfirmed}
              nextEpisodeText={_.get(this.meta, 'nextContentText')}
              watchType={'next-episode'}
            />
          ) : null
        }
        // @ts-ignore TS2339
        videoId={this.state.playContext ? this.state.playContext.get('video').videoId : null}
        // @ts-ignore TS2339
        videoSkip={this.state.videoSkip}
        // @ts-ignore TS2339
        model={this.model}
        handlePlayerSideListModeChange={this.handlePlayerSideListModeChange} //プレイヤー横にリストカードを表示
        // @ts-ignore TS2339
        playerSideListMode={this.state.playerSideListMode} //プレイヤー横にリストカードを表示中
      />
    );

    let priceList;
    // スマホの場合、未ログイン時と権利がない場合に価格表示
    if (
      (browserInfo.isIOS || browserInfo.isAndroid) &&
      _.includes([ERROR.UNAUTHORIZED, ERROR.LICENSE_INVALID], _.get(this.state, 'error.type'))
    ) {
      priceList = (
        <PriceList
          // @ts-ignore TS2339
          model={this.model}
          // @ts-ignore TS2339
          metaId={this.props.id}
          // @ts-ignore TS2322
          isOverlay={false}
          onClick={(e, key, id) => {
            // @ts-ignore TS2339
            window.location.href = `/usf/${this.props.id}/confirm?${key}=${id}`;
          }}
        />
      );
    }

    let myListButton, scheduleButton, watchPartyButton, chatButton;

    let shareButton = (
      <ShareButton
        // @ts-ignore
        spMode={this.props.spMode}
        // @ts-ignore
        currentTime={this.__currentTime}
        // @ts-ignore
        metadata={this.metadata.type === 'linear_channel' ? this.state.eventData : this.metadata}
        position={errorType ? 'left' : 'right'}
      />
    );

    if (this.meta) {
      const myListProps = {
        // @ts-ignore TS2339
        model: this.model,
        btnType: 'short',
        btnStyle: false,
        metaDetail: true,
      };
      // if (!this.meta.leadSeasonId) {
      // @ts-ignore TS2339
      myListProps.item = this.meta;
      // @ts-ignore TS2339
      myListProps.id = this.meta.id;
      // } else {
      //   myListProps.id = this.meta.leadSeasonId;
      // }
      if (MyListButton.isUsable(this.context, this.meta)) {
        myListButton = <MyListButton {...myListProps} />;
      }
    }

    let partyComment =
      // @ts-ignore TS2339
      this.state.watchPartyType !== 'none' && !this.isCarMonitor ? (
        <Comment
          // @ts-ignore TS2322
          player={this.__player}
          // @ts-ignore TS2339
          watchPartyType={this.state.watchPartyType}
          handleWatchPartyModeChange={this.handleWatchPartyModeChange}
          // @ts-ignore TS2339
          linearModel={this.state.eventData}
          titleModel={this.metadata}
          // @ts-ignore TS2339
          seriesModel={this.series || {}}
          // @ts-ignore TS2339
          showModal={this.props.showModal}
          // @ts-ignore TS2339
          closeModal={this.props.closeModal}
        />
      ) : null;

    scheduleButton = <ScheduleButton meta={this.meta} />;

    if (
      browserInfo.isAndroid ||
      browserInfo.isIOS ||
      browserInfo.isRequestDesktopWebsite ||
      !browserInfo.features.playable
    ) {
      // 推奨環境以外ではWatchPartyなし
    } else if (userInfo.status !== 'NON_REGISTERED_MEMBER') {
      // @ts-ignore TS2339
      if (this.__player && this.state.watchPartyType === 'closeType') {
        watchPartyButton = (
          <WatchPartyButton
            key={`watchParty`}
            {...this.props}
            // @ts-ignore TS2339
            player={this.__player}
            // @ts-ignore TS2322
            playContext={this.state.playContext}
            handleWatchPartyModeChange={this.handleWatchPartyModeChange}
            // @ts-ignore TS2339
            watchPartyMode={this.state.watchPartyMode}
            // @ts-ignore TS2339
            exercise={this.exercise}
            // @ts-ignore TS2339
            watchPartyType={this.state.watchPartyType}
            titleModel={this.metadata}
            // @ts-ignore TS2339
            seriesModel={this.series}
            // @ts-ignore TS2339
            linearModel={this.props.linearModel} // リニアの時
            // @ts-ignore TS2339
            showModal={this.props.showModal}
            // @ts-ignore TS2339
            closeModal={this.props.closeModal}
          />
        );
        // @ts-ignore TS2339
      } else if (this.state.watchPartyType === 'openType' && !this.isCarMonitor) {
        chatButton = (
          <a
            href="javascript:void(0);"
            // @ts-ignore TS2339
            className={classnames('action-btn chat-btn', { active: this.state.commentActive })}
            onClick={e => {
              if (!browserInfo.isIOS && !browserInfo.isAndroid) {
                // this.props.showModal(<WatchPartyModal name={"コメント機能"} closeModal={this.props.closeModal} />);
                // @ts-ignore TS2554
                this.handleCommentButtonClick();
              } else {
                // @ts-ignore TS2339
                if (!this.state.infoState) {
                  this.openCommentDrawer();
                }
              }
              this.sendToGtm(CONTENT_EVENTS.COMMENT);
            }}
          >
            <i
              className={classnames('fa', {
                // @ts-ignore TS2339
                'fa-chat-off': !this.state.commentActive,
                // @ts-ignore TS2339
                'fa-chat': this.state.commentActive,
              })}
            ></i>
            <span>チャット</span>
          </a>
        );
      }
    }

    let channelName;
    // @ts-ignore TS2339
    if (this.metadata.type == 'linear_channel') {
      // @ts-ignore TS2339
      const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.metadata.refId);
      if (simulcast) channelName = _.lowerCase(simulcast.name);
    }
    let linkProps = {
      to: routes.title,
      // @ts-ignore TS2339
      params: { id: this.metadata.seasonId },
    };
    if (_.has(this.metadata, 'seasonMeta.refId')) {
      linkProps = {
        to: routes.program,
        // @ts-ignore TS2339
        params: { id: this.metadata.seasonMeta.refId },
      };
    }

    let catchupTo;
    // @ts-ignore TS2339
    if (this.state.eventData && this.state.eventData.isCatchup) {
      // @ts-ignore TS2339
      if (this.state.eventData.catchupMeta.refId) {
        catchupTo = {
          to: routes.content,
          // @ts-ignore TS2339
          params: { id: this.state.eventData.catchupMeta.refId },
        };
      } else {
        catchupTo = {
          to: routes.watchNow,
          // @ts-ignore TS2339
          params: { id: this.state.eventData.catchupMeta.metaId },
        };
      }
    }

    //配信時間とライブ配信時間の差分
    let broadcastStartAt =
      this.meta.cardInfo && this.meta.cardInfo.broadcastStartAt ? this.meta.cardInfo.broadcastStartAt : null;
    let deliveryStartAt =
      this.meta.cardInfo && this.meta.cardInfo.deliveryStartAt ? this.meta.cardInfo.deliveryStartAt : null;
    const now = datetime();

    let diffText;
    if (this.meta.schemaId == 4 && broadcastStartAt && deliveryStartAt) {
      // @ts-ignore TS2554
      let duration = datetime(broadcastStartAt).duration(datetime(deliveryStartAt), 'milliseconds');

      if (duration.days() > 0) {
        diffText = `※${duration.days()}日前からアクセス可能`;
      } else if (duration.minutes() > 0 && duration.hours() > 0) {
        diffText = `※${duration.hours()}時間${duration.minutes()}分前からアクセス可能`;
      } else if (duration.hours() > 0) {
        diffText = `※${duration.hours()}時間前からアクセス可能`;
      } else if (duration.minutes() > 0) {
        diffText = `※${duration.minutes()}分前からアクセス可能`;
      }
    }

    let navigationInfo;
    if (
      // @ts-ignore TS2339
      this.state.error &&
      // @ts-ignore TS2339
      this.state.error.type === ERROR.META_NOT_DELIVERY_STARTED &&
      userInfo.status !== 'NON_REGISTERED_MEMBER' &&
      this.meta.event
    ) {
      const simulcastStartAt = this.meta.event.start_at;
      const simulcastEndAt = this.meta.event.end_at;
      const deliveryStartDate = datetime(deliveryStartAt);
      const simulcastStartDate = datetime(simulcastStartAt);
      const simulcastEndDate = datetime(simulcastEndAt);

      // アーカイブ配信開始時刻より放送同時配信時間が早く、放送同時配信が終わっていない場合に導線を表示させる
      if (simulcastStartDate.isBefore(deliveryStartDate) && now.isBefore(simulcastEndDate)) {
        const {
          unique_id,
          start_at,
          end_at,
          linear_channel_meta: { ref_id },
        } = this.meta.event;
        const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == ref_id);
        const linkProps = {
          to: routes.simulcastDetail,
          params: { channelName: simulcast.name, uniqueId: unique_id },
        };
        // 開始と終了で日付が変わる場合は終了の方にも日付を出す
        const isNextDay = simulcastStartDate.format('YYYY-MM-DD') !== simulcastEndDate.format('YYYY-MM-DD');
        navigationInfo = { startAt: start_at, endAt: end_at, linkProps, isNextDay };
      }
    }

    let playerSide = null;
    if (!browserInfo.isIOS && !browserInfo.isAndroid) {
      // @ts-ignore TS2339
      if (this.state.playerSideListMode) {
        playerSide = (
          <Sidelist
            // @ts-ignore TS2322
            meta={this.meta}
            // @ts-ignore TS2339
            model={this.model}
            handlePlayerSideListModeChange={this.handlePlayerSideListModeChange}
            // @ts-ignore TS2339
            playerSideListMode={this.state.playerSideListMode}
          />
        );
        // @ts-ignore TS2339
      } else if (this.state.watchPartyMode && this.state.commentActive) {
        playerSide = partyComment;
        // @ts-ignore TS2339
      } else if (this.state.infoContent) {
        playerSide = (
          <div className="iframe-width">
            <div className="iframe-close-btn" onClick={this.handleCloseSideInfo}>
              <i className="fa-remove" />
              閉じる
            </div>
            {/*
             // @ts-ignore TS2339 */}
            {this.state.infoContent}
          </div>
        );
      }
    }

    content = this.context.isIframe ? (
      <div className="watch-content">
        {/*
         // @ts-ignore TS2339 */}
        <div className="player-container" ref={this.containerRef}>
          <div className="player-width">{player}</div>
        </div>
      </div>
    ) : (
      <div className="watch-content">
        {errorType ? (
          <div className="watch-main-visual">
            <div className="watch-main-visual__metadata">
              {/*
                 // @ts-ignore TS2339 */}
              {this.metadata.type == 'linear_channel' ? (
                <React.Fragment>
                  <div className={classnames('logo', channelName)}></div>
                  <div className="playable-title__date" key={`date`}>
                    {this.meta.onair && this.meta.onair.startAt && this.meta.onair.endAt ? (
                      <React.Fragment>
                        {/*
                           // @ts-ignore TS2322 */}
                        <SetDate date={this.meta.onair.startAt} format={'short'} /> -{' '}
                        {/*
                           // @ts-ignore TS2322 */}
                        <SetDate date={this.meta.onair.endAt} format={'time'} />
                      </React.Fragment>
                    ) : this.meta.onair && this.meta.onair.startAt && !this.meta.onair.endAt ? (
                      // @ts-ignore TS2322
                      <SetDate date={this.meta.onair.endAt} format={'short'} />
                    ) : null}
                    {this.meta.onair && this.meta.onair.isMask && (
                      <div className="tag-list" key={`isMask`}>
                        <span key="isMask" className="tag grey-tag">
                          同時配信 対象外
                        </span>
                      </div>
                    )}
                  </div>
                  {this.meta.onair ? <h1 className="playable-title">{this.meta.onair.name}</h1> : null}
                </React.Fragment>
              ) : // @ts-ignore TS2339
              this.metadata.type !== 'linear_channel' ? (
                <React.Fragment>
                  {/*
                     // @ts-ignore TS2339 */}
                  {this.metadata.seasonId && this.metadata.title !== this.metadata.playableTitle ? (
                    <p className="title">
                      {/*
                         // @ts-ignore TS2339 */}
                      <MainViewLink {...linkProps}>{this.metadata.title}</MainViewLink>
                    </p>
                  ) : null}
                  <h1 className="playable-title">
                    {/*
                       // @ts-ignore TS2339 */}
                    {this.metadata.seasonId && this.metadata.title == this.metadata.playableTitle ? (
                      // @ts-ignore TS2339
                      <MainViewLink {...linkProps}>{this.metadata.title}</MainViewLink>
                    ) : (
                      // @ts-ignore TS2339
                      this.metadata.playableTitle
                    )}
                  </h1>
                </React.Fragment>
              ) : null}
              {/*
                 // @ts-ignore TS2339 */}
              {this.metadata.type == 'linear_channel' && this.meta.onair ? <Meta metadata={this.meta.onair} /> : null}
              {/*
                 // @ts-ignore TS2339 */}
              {this.metadata.type !== 'linear_channel' ? <Meta showLiveBadge={true} metadata={this.meta} /> : null}
              {/*
                 // @ts-ignore TS2339 */}
              {this.metadata.type == 'linear_channel' ? <Tags {...this.meta.onair} /> : null}
              {/*
                 // @ts-ignore TS2339 */}
              {this.metadata.type !== 'linear_channel' ? <MetaTags metadata={this.meta} /> : null}
              {errorType ? (
                <React.Fragment>
                  <MainvisualSideDescription
                    onClickMoreInfo={this.onClickMoreInfo}
                    metadata={this.metadata}
                    // @ts-ignore
                    model={this.model}
                    meta={this.meta}
                    doAction={this.doErrorAction}
                    // @ts-ignore
                    error={this.state.error}
                    // @ts-ignore
                    event={this.state.eventData}
                    // @ts-ignore
                    spMode={this.props.spMode}
                  />
                  {/* @ts-ignore */}
                  {!this.state.watchSpMode ? (
                    <div className="action-box">
                      <ul className="action-box__inner">
                        {/* @ts-ignore */}
                        {scheduleButton && this.metadata.type == 'linear_channel' ? (
                          <li key={`scheduleButton`}>{scheduleButton}</li>
                        ) : null}
                        {/* @ts-ignore */}
                        {myListButton && this.metadata.type !== 'linear_channel' ? (
                          <li key={`myListButton`}>{myListButton}</li>
                        ) : null}
                        {shareButton ? <li key={`shareButton`}>{shareButton}</li> : null}
                        {chatButton ? <li key={`chatButton`}>{chatButton}</li> : null}
                      </ul>
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
              {/* @ts-ignore */}
              {this.metadata && this.metadata.type == 'linear_channel' && _.get(this.state, 'eventData.isMask') ? (
                <div className="watch-main-visual__metadata__message">
                  <div className="watch-main-visual__metadata__message-title">
                    この番組は放送のみでお楽しみいただけます。
                  </div>
                  <div className="watch-main-visual__metadata__message-text">
                    詳しくはお客さまサポート・よくあるご質問をご確認ください。
                  </div>
                  {browserInfo.isAndroid || browserInfo.isIOS ? (
                    <a
                      href="https://support.wowow.co.jp/s/category?prmcat=category3"
                      target="_blank"
                      className="watch-main-visual__metadata__message-notice btn"
                    >
                      詳しくはこちら
                    </a>
                  ) : (
                    <div className="watch-main-visual__metadata__message-notice">
                      詳しくは
                      <a
                        href="https://support.wowow.co.jp/s/category?prmcat=category3"
                        target="_blank"
                        className="accent-color"
                      >
                        こちら
                      </a>
                    </div>
                  )}
                  {!_.isEmpty(catchupTo) ? (
                    <MainViewLink {...catchupTo} className={'btn btn-fill btn-large'}>
                      オンデマンドで再生する
                    </MainViewLink>
                  ) : null}
                </div>
              ) : _.get(this.state, 'error.type') == ERROR.NOT_STARTED_ENTITLEMENT ? (
                <div className="watch-main-visual__metadata__message">
                  <div className="watch-main-visual__metadata__message-title">
                    <SetDate
                      // @ts-ignore
                      date={_.get(this.state.error, 'objects.entitlement.start_at')}
                      format="fullja"
                      txt="から視聴可能"
                    />
                  </div>
                </div>
              ) : // @ts-ignore
              this.meta.schemaId == 4 && !getIsDeliveryStarted(this.metadata.deliveryStartAt) ? (
                <div className="watch-main-visual__metadata__message">
                  <div className="watch-main-visual__metadata__message-title">
                    {/* @ts-ignore */}
                    <SetDate date={broadcastStartAt} format={'fullja'} txt="からライブ配信予定" />
                  </div>
                  {diffText && <div className="watch-main-visual__metadata__message-text">{diffText}</div>}
                </div>
              ) : _.get(this.state, 'error.type') == ERROR.META_NOT_DELIVERY_STARTED ? (
                // 権利ありで配信開始前の場合
                <div className="watch-main-visual__metadata__message">
                  <div className="watch-main-visual__metadata__message-title">
                    {/* @ts-ignore */}
                    <SetDate date={deliveryStartAt} format={'fullja'} txt="から配信予定" />
                  </div>
                </div>
              ) : // @ts-ignore
              getIsDeliveryEnded(this.metadata.deliveryEndAt) ? (
                <div className="watch-main-visual__metadata__message">
                  <div className="watch-main-visual__metadata__message-title">
                    {this.meta.cardInfo && this.meta.cardInfo.deliveryEndAt ? (
                      <SetDate date={this.meta.cardInfo.deliveryEndAt} format={'fullja'} txt="に配信終了しました" />
                    ) : null}
                  </div>
                </div>
              ) : // @ts-ignore
              this.state.error ? (
                <>
                  {/* @ts-ignore */}
                  <CloedCampaignText model={this.model} meta={this.meta} error={this.state.error} />
                  {/* @ts-ignore */}
                  {this.state.error.type == ERROR.LICENSE_INVALID ? (
                    <div className="watch-main-visual__metadata__message">
                      <LicenseInvalidView
                        // @ts-ignore
                        model={this.model}
                        // @ts-ignore
                        activeEpisode={this.meta}
                        // @ts-ignore
                        activeEvent={this.state.eventData}
                        doAction={this.doErrorAction}
                        // @ts-ignore
                        error={this.state.error}
                        checkModal={this.checkModal}
                        // @ts-ignore
                        watchSpMode={this.state.watchSpMode}
                      />
                    </div>
                  ) : // @ts-ignore
                  this.state.error.type == ERROR.NOT_PLAYABLE ? (
                    <div className="watch-main-visual__metadata__message">
                      <div className="watch-main-visual__metadata__message-title">
                        ご利用いただいている環境は推奨環境ではないため、動画の再生をすることはできません。推奨環境は
                        <a href={LINKS.SUPPORT.ENABLE_DEVICES} target="_blank" className="accent-color">
                          こちら
                        </a>
                        をご確認ください。
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
              {navigationInfo && (
                <div className="communication-area">
                  <span>※本コンテンツは、放送同時配信でもご視聴いただけます。</span>
                  <span>
                    {/*
                       // @ts-ignore TS2322 */}
                    <SetDate date={navigationInfo.startAt} format={'short'} />
                    {' - '}
                    {/*
                       // @ts-ignore TS2322 */}
                    <SetDate date={navigationInfo.endAt} format={navigationInfo.isNextDay ? 'short' : 'time'} />
                  </span>
                  <MainViewLink {...navigationInfo.linkProps} className="btn btn-fill btn-wide">
                    放送同時配信はこちら
                  </MainViewLink>
                </div>
              )}
            </div>
            <div className="main-visual">
              <div className="video-preload-title">
                {/*
                   // @ts-ignore TS2339 */}
                <div className="video-preload-title-label">{this.metadata.name}</div>
              </div>
              {/*
                 // @ts-ignore TS2339 */}
              {this.metadata.type == 'linear_channel' && this.meta.onair ? (
                <BackgroundImage
                  // @ts-ignore TS2322
                  className={classnames('artwork', {
                    noImage: !this.meta.onair.thumbnailUrl && !this.meta.thumbnailUrl,
                  })}
                  url={this.meta.onair.thumbnailUrl || this.meta.thumbnailUrl}
                />
              ) : (
                <BackgroundImage
                  // @ts-ignore TS2322
                  className={classnames('artwork', { noImage: !this.metadata.thumbnailUrl })}
                  // @ts-ignore TS2339
                  url={this.metadata.thumbnailUrl}
                />
              )}
            </div>
          </div>
        ) : (
          // @ts-ignore TS2339
          <div className="player-container" ref={this.containerRef}>
            <div className="player-width">
              {player}
              {/*
               // @ts-ignore TS2339 */}
              {this.state.showCatchupMsg && (
                <div className="catchup-wrapper">
                  この番組は放送同時配信の対象外ですが、オンデマンド配信をしています。オンデマンド配信は
                  <MainViewLink {...catchupTo} className="accent-color">
                    こちら
                  </MainViewLink>
                </div>
              )}
            </div>
            {playerSide}
          </div>
        )}
        {/* @ts-ignore TS2339 */}
        <div className="below-player" ref={this.belowPlayerRef}>
          <PlanInfo
            metadata={this.metadata}
            meta={this.meta}
            // @ts-ignore
            error={this.state.error}
            {...this.props}
            // @ts-ignore
            itemsInRow={this.state.columnsInRow}
            // @ts-ignore
            mediaQueryCheck={this.state.mediaQueryCheck}
            // @ts-ignore
            model={this.model}
            priceList={priceList}
            myListButton={myListButton}
            shareButton={shareButton}
            watchPartyButton={watchPartyButton}
            chatButton={chatButton}
            titleHidden={errorType}
            handleWatchPartyModeChange={this.handleWatchPartyModeChange}
            // @ts-ignore
            watchPartyMode={this.state.watchPartyMode}
            // @ts-ignore
            exercise={this.exercise}
            hiddenTips={true}
            // @ts-ignore
            watchPartyType={this.state.watchPartyType}
            onEventChange={this.changeEventData}
            scheduleButton={scheduleButton}
            // @ts-ignore
            watchSpMode={this.state.watchSpMode}
            activeEvent={_.get(this.state, 'eventData')}
            // @ts-ignore
            tabKey={this.state.watchInfoTabKey}
            onChangeTab={this.onChangeWatchInfoTab}
            checkModal={this.checkModal}
            doAction={this.doErrorAction}
          />
          {/* @ts-ignore */}
          {errorType && this.state.watchSpMode ? (
            <div className="action-box">
              <ul className="action-box__inner">
                {/* @ts-ignore */}
                {scheduleButton && this.metadata.type == 'linear_channel' ? (
                  <li key={`scheduleButton`}>{scheduleButton}</li>
                ) : null}
                {/* @ts-ignore */}
                {myListButton && this.metadata.type !== 'linear_channel' ? (
                  <li key={`myListButton`}>{myListButton}</li>
                ) : null}
                {shareButton ? <li key={`shareButton`}>{shareButton}</li> : null}
                {chatButton ? <li key={`chatButton`}>{chatButton}</li> : null}
              </ul>
            </div>
          ) : null}
          {/* @ts-ignore */}
          {this.metadata.type === 'linear_channel' ? (
            <div className={'series-area event-timeline'}>
              {/* @ts-ignore */}
              <EventRow listContext={'fromnow'} model={this.model} id={this.metadata.id} tagHidden={true} />
            </div>
          ) : null}
          {/* @ts-ignore */}
          {this.titleMeta || this.seasonMeta ? (
            <TitleWatchContents
              {...this.props}
              // @ts-ignore TS2339
              id={this.metadata.titleId}
              // @ts-ignore TS2322
              relatedMetaId={this.metadata.id}
              ignoreIds={_.uniq(
                // @ts-ignore TS2339
                _.compact([this.metadata.id, _.get(this.seasonMeta, 'id'), _.get(this.titleMeta, 'id')]),
              )}
              metadata={this.metadata}
              // @ts-ignore TS2339
              model={this.model}
              // @ts-ignore TS2339
              onChangeEpisode={this.props.onChangeEpisode}
              onChangeQuery={this.handleChangeQuery}
            />
          ) : null}
        </div>
      </div>
    );
    // 構造化タグ
    const jsonLdProps = {};
    const host = this.context.getModelData('hosts', 'host');
    const itemListElement = [];
    const videoObject = {};

    if (this.meta.type !== 'linear_channel') {
      // ジャンル
      const genre = _.first(this.meta.genres);
      // @ts-ignore TS2339
      if (genre && genre.id && genre.name) {
        // @ts-ignore TS2554
        let url = routes.genre.makePath({ id: genre.id }, { type: 'od' });
        // @ts-ignore TS2339
        if (genre.refId) url = routes.genreRef.makePath({ id: genre.refId }, { type: 'od' });
        // @ts-ignore TS2339
        itemListElement.push({ name: genre.name, item: host + url });
      }

      // 親シリーズがあれば親シリーズ
      // @ts-ignore TS2339
      if (this.titleMeta && this.titleMeta.id && this.titleMeta.name && this.titleMeta.schemaId == 1) {
        // @ts-ignore TS2554
        let url = routes.title.makePath({ id: this.titleMeta.id });
        // @ts-ignore TS2339
        if (this.titleMeta.refId) url = routes.program.makePath({ id: this.titleMeta.refId });
        // @ts-ignore TS2339
        const name = _.get(this.titleMeta, 'shortName', _.get(this.titleMeta, 'name'));
        itemListElement.push({ name: name, item: host + url });
      }

      // 親シーズンがあれば親シーズン
      // @ts-ignore TS2339
      if (this.seasonMeta && this.seasonMeta.id && this.seasonMeta.name && this.seasonMeta.schemaId == 2) {
        // @ts-ignore TS2554
        let url = routes.title.makePath({ id: this.seasonMeta.id });
        // @ts-ignore TS2339
        if (this.seasonMeta.refId) url = routes.program.makePath({ id: this.seasonMeta.refId });
        // @ts-ignore TS2339
        const name = _.get(this.seasonMeta, 'shortName', _.get(this.seasonMeta, 'name'));
        itemListElement.push({ name: name, item: host + url });
      }

      const formatVideoObjectName = (titleName, episodeNum, episodeName) => {
        // 出力例：「ねこが笑えばSP #1 ～猫の日に帰ってきたニャ～」
        return _.compact([
          titleName,
          !!titleName ? episodeNum : null,
          !!titleName && !episodeNum && titleName === episodeName ? null : episodeName,
        ]).join(' ');
      };

      // @ts-ignore TS2554
      let url = routes.watchNow.makePath({ id: this.meta.id });
      // @ts-ignore TS2554
      if (this.meta.refId) url = routes.content.makePath({ id: this.meta.refId });
      // @ts-ignore TS2339
      videoObject.url = host + url;
      // @ts-ignore TS2339
      videoObject.name = formatVideoObjectName(
        // @ts-ignore TS2339
        _.get(this.seasonMeta, 'name') || _.get(this.titleMeta, 'name'),
        _.get(this.meta, 'cardInfo.episodeNumberTitle'),
        _.get(this.meta, 'shortName'),
      );
      // @ts-ignore TS2339
      videoObject.description = this.changeDescription() + _.get(this.meta, 'cardInfo.description', '');
      // @ts-ignore TS2339
      videoObject.thumbnailUrl = _.get(this.meta, 'ogImage', '');
      // @ts-ignore TS2339
      videoObject.uploadDate = _.get(this.meta, 'publishStartAt');
      // @ts-ignore TS2339
      videoObject.expires = _.get(this.meta, 'publishEndAt');
      // @ts-ignore TS2339
      videoObject.duration = _.get(this.meta, 'cardInfo.episodeRuntime');
      if (this.meta.schemaId == 4) {
        // @ts-ignore TS2339
        videoObject.publication = {
          startDate: _.get(this.meta, 'cardInfo.broadcastStartAt'),
          endDate: _.get(this.meta, 'cardInfo.broadcastEndAt'),
        };
      }
    }

    // 自分
    if (this.meta && this.meta.id && this.meta.name) {
      const data = { name: _.get(this.meta, 'shortName', _.get(this.meta, 'name')) };
      if (this.meta.type === 'linear_channel') {
        const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.meta.refId);
        // @ts-ignore TS2339
        data.item = host + routes.simulcast.makePath({ channelName: simulcast.name });
      }
      // パンくずリストの最後のitemはurlを指定しない場合は現在ページのURLが使用されるため指定しない
      // /watchと/contentがインデックスに混在しているため
      itemListElement.push(data);
    }

    if (!_.isEmpty(itemListElement)) {
      // @ts-ignore TS2339
      jsonLdProps.breadcrumbList = { itemListElement };
    }

    if (!_.isEmpty(videoObject)) {
      // @ts-ignore TS2339
      jsonLdProps.videoObject = videoObject;
    }

    const metadata = this.metadata;
    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ title, keywords }) => {
            const links = [];
            const metas = [];
            // @ts-ignore TS2339
            const metaTitle = videoObject.name || metadata.name;
            if (metaTitle) {
              metas.push({ name: 'keywords', content: keywords((this.meta.keywords || []).join(',')) });
              metas.push({ property: 'og:title', content: title(metaTitle) });
              metas.push({
                name: 'description',
                content:
                  // @ts-ignore TS2339
                  this.changeDescription() + (metadata.description ? metadata.description : '').replace(/\r?\n/g, ''),
              });
              metas.push({
                property: 'og:description',
                // @ts-ignore TS2339
                content: (metadata.description ? metadata.description : '').replace(/\r?\n/g, ''),
              });
            }
            if (this.meta && this.meta.ogImage) {
              metas.push({ name: 'thumbnail', content: this.meta.ogImage });
              metas.push({ property: 'og:image', content: this.meta.ogImage });
            }
            // @ts-ignore TS2339
            if (metadata.id) {
              let url;
              // @ts-ignore TS2339
              if (metadata.type === 'linear_channel') {
                // @ts-ignore TS2339
                const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == metadata.refId);
                // @ts-ignore TS2554
                url = routes.simulcast.makePath({ channelName: simulcast.name });
                // /contentでは動画インデックスがされないため、/watchを指定する
                // } else if (metadata.refId) {
                //   url = routes.content.makePath({id: metadata.refId});
              } else {
                // @ts-ignore TS2554
                url = routes.watchNow.makePath({ id: metadata.id });
              }
              links.push({ rel: 'canonical', href: host + url });
            }
            return <Helmet title={metaTitle} link={links} meta={metas} />;
          }}
        </HtmlContext.Consumer>
        <JsonLd {...jsonLdProps} />
        <div
          className={classnames('watch', {
            // @ts-ignore TS2339
            'br-player-playing': this.state.playing,
            // @ts-ignore TS2339
            'br-player-error': this.state.error,
            // @ts-ignore TS2339
            'watch-party-on': this.state.watchPartyMode,
            // @ts-ignore TS2339
            'comment-on': this.state.commentActive && this.state.watchPartyMode,
            // @ts-ignore TS2339
            unauthrized: this.state.error && this.state.error.type == ERROR.UNAUTHORIZED,
            // @ts-ignore TS2339
            'iframe-on': !!this.state.infoContent,
            // @ts-ignore TS2339
            'player-side-list-on': !!this.state.playerSideListMode,
          })}
          // @ts-ignore TS2339
          data-mode={this.state.mode}
        >
          {content}
        </div>
      </React.Fragment>
    );
  }

  get metadata() {
    if (!this.meta)
      return {
        isDeliveryStarted: function() {
          return false;
        },
      };
    let title = this.meta.name;
    if (this.meta.shortName !== this.meta.name) {
      // 後方にある文字を削除する
      const index = this.meta.name.lastIndexOf(this.meta.shortName);
      if (index > 0) {
        title = _.trim(this.meta.name.slice(0, index));
      }
    }
    // shortNameとnameの関係が崩れているメタが存在するので、グループメタの情報で上書きする
    // @ts-ignore TS2339
    if (this.seasonMeta && this.meta.titleMetaId != this.meta.leadSeasonId) {
      // @ts-ignore TS2339
      title = this.seasonMeta.shortName || this.seasonMeta.name;
      // @ts-ignore TS2339
    } else if (this.titleMeta) {
      // @ts-ignore TS2339
      title = this.titleMeta.shortName || this.titleMeta.name;
    }
    if (!this.meta.cardInfo) this.meta.cardInfo = {};
    const metadata = {
      id: this.meta.id,
      refId: this.meta.refId,
      schemaId: this.meta.schemaId,
      groupedId: this.meta.titleMetaId || this.meta.id,
      titleId: this.meta.titleMetaId,
      seasonId: this.meta.leadSeasonId,
      // @ts-ignore TS2339
      seasonMeta: this.seasonMeta,
      // @ts-ignore TS2339
      seriesMeta: this.titleMeta,
      episodeId: this.meta.id,
      contentsProviderId: this.meta.contentsProviderId,
      type: this.meta.type,
      name: this.meta.name,
      title: title,
      attributes: this.meta.attributes,
      genres: this.meta.genres,
      middleGenres: this.meta.middleGenres,
      playableTitle: this.meta.shortName || this.meta.name,
      tags: this.meta.cardInfo.tags,
      rating: this.meta.cardInfo.rating,
      synopsis: this.meta.cardInfo.synopsis,
      productionYear: this.meta.cardInfo.productionYear,
      thumbnailUrl: this.meta.thumbnailUrl,
      header: this.meta.header,
      description: this.meta.cardInfo.description,
      liveStartMessage: this.meta.liveStartMessage,
      audioOnly: this.meta.audioOnly,
      showDeliveryEndAt: this.meta.showDeliveryEndAt,
      deliveryStartAt: this.meta.cardInfo.deliveryStartAt,
      deliveryEndAt: this.meta.cardInfo.deliveryEndAt,
      isSubscription: this.meta.subscription,
      isRental: this.meta.rental,
      isMulti: this.meta.isMulti,
      recommend: this.meta.recommend,
      episodeNumberTitle: this.meta.cardInfo.episodeNumberTitle,
      episodeRuntime: this.meta.cardInfo.episodeRuntime,
      mentions: this.meta.mentions,
      links: this.meta.links,
      wpEnableFlag: this.meta.wpEnableFlag,
      svSwitch: this.meta.svSwitch,
      liveOlImage: this.meta.liveOlImage,
      liveOlPosition: this.meta.liveOlPosition,
      communicationArea: this.meta.communicationArea,
      tvodBadge: this.meta.tvodBadge,
      cardInfo: this.meta.cardInfo,
      isDeliveryStarted: () => {
        return getIsDeliveryStarted(this.meta.cardInfo.deliveryStartAt);
      },
    };
    return metadata;
  }

  get isSpBrowser() {
    const { isAndroid, isIOS, isRequestDesktopWebsite } = this.context.getModelData('browserInfo') || {};
    return isAndroid || isIOS || isRequestDesktopWebsite;
  }

  get isCarMonitor() {
    const { isCarMonitor } = this.context.getModelData('browserInfo') || {};
    return isCarMonitor;
  }

  getStartTime(mediaInfo, shouldDeleteQuery) {
    const { routeHandler, history } = this.context;

    if (mediaInfo) {
      // @ts-ignore TS2339
      this.cacheMediaInfo = {
        resumePoint: _.get(mediaInfo, 'resume_point'),
        endingStartPosition: _.get(mediaInfo, 'media.values.ending_start_position'),
        openingEndPosition: _.get(mediaInfo, 'media.values.opening_end_position', 0),
        enableStartOver: _.get(mediaInfo, 'playback_rule.enable_start_over_flag'),
      };
    }

    // WPの場合は指定しない
    let roomId = _.get(routeHandler, 'query.wp', null);
    if (roomId) return;

    // console.log('episodeRuntime', _.get(this.meta, 'cardInfo.episodeRuntime'));
    // console.log('resumePoint', _.get(this.meta, 'resumePoint'));
    // console.log('query', routeHandler.query);

    const deleteQueries = [];
    const _getStartTime = () => {
      let startTime = undefined;
      const tQuery = _.get(routeHandler.query, QUERY.TIME, null);
      // DVR
      // @ts-ignore TS2339
      if (this.state.startOverConfirmed) {
        // @ts-ignore TS2339
        if (this.state.beginning) startTime = 0;
        // @ts-ignore TS2339
      } else if (_.get(this.cacheMediaInfo, 'enableStartOver', false)) {
        let fromLiveEdge = _.get(routeHandler.query, QUERY.LIVE_EDGE);
        if (fromLiveEdge) {
          fromLiveEdge = fromLiveEdge === 'true';
        }
        if (tQuery !== null) {
          let time = parseInt(tQuery, 10);
          if (isNaN(time)) time = undefined;
          startTime = time;
        } else if (fromLiveEdge) {
          startTime = undefined;
          return startTime;
        } else {
          let time, resumePoint;
          // @ts-ignore TS2339
          if (_.get(this.cacheMediaInfo, 'resumePoint')) {
            // @ts-ignore TS2339
            resumePoint = this.cacheMediaInfo.resumePoint;
          } else {
            resumePoint = _.get(this.meta, 'resumePoint');
          }
          time = parseInt(resumePoint, 10);
          if (isNaN(time)) time = undefined;
          startTime = time;
        }
      }
      if (_.has(this.meta, 'cardInfo.episodeRuntime') && this.meta.cardInfo.episodeRuntime > 0) {
        startTime = 0;

        // t パラメータが存在するときは、それを指定する
        // 但し、duration以内の場合に限る
        let time = parseInt(tQuery, 10);
        if (isNaN(time)) time = -1;
        if (time >= 0 && this.meta.cardInfo.episodeRuntime > time) {
          startTime = time;
          // console.log(`from query.t: ${startTime}`);
          // @ts-ignore TS2339
        } else if (this.__gCastCurrentTime) {
          // @ts-ignore TS2339
          startTime = this.__gCastCurrentTime;
          // @ts-ignore TS2339
          delete this.__gCastCurrentTime;
          // console.log(`from cast currentTime: ${startTime}`);
          // @ts-ignore TS2339
        } else if (this.cacheMediaInfo.resumePoint) {
          // @ts-ignore TS2339
          startTime = isNaN(parseInt(this.cacheMediaInfo.resumePoint, 10))
            ? undefined
            : // @ts-ignore TS2339
              parseInt(this.cacheMediaInfo.resumePoint, 10);
        } else if (this.meta.resumePoint && this.meta.cardInfo.episodeRuntime > this.meta.resumePoint) {
          startTime = this.meta.resumePoint;
          // console.log(`from resumePoint: ${startTime}`);
        }

        // エンドクレジットがあるときはエンドクレジット以上再生されている時
        // 残りの再生時間が全体の95%以上の時
        // 残りの再生時間が1分未満の時
        // 末尾まで再生したフラグが立っている時
        // 上記の場合は0秒から開始する
        if (startTime > 0) {
          // @ts-ignore TS2339
          const endingStartPosition = this.cacheMediaInfo.endingStartPosition;
          if (endingStartPosition > 0 && endingStartPosition <= startTime) {
            // console.log(`resumePoint over endingStartPosition ${startTime} ${endingStartPosition}`);
            startTime = 0;
          }
        }
        if (startTime > 0 && this.meta.cardInfo.episodeRuntime > 0) {
          const resumePercent = ((startTime * 1000) / (this.meta.cardInfo.episodeRuntime * 1000)) * 100;
          if (resumePercent >= 95) {
            // console.log(`resumePoint over 95% ${startTime} ${this.meta.cardInfo.episodeRuntime} ${resumePercent}`);
            startTime = 0;
          }
        }
        if (startTime > 0 && this.meta.cardInfo.episodeRuntime > 0) {
          const diff = this.meta.cardInfo.episodeRuntime - startTime;
          if (diff < 60) {
            // console.log(`resumePoint less than 60s ${startTime} ${this.meta.cardInfo.episodeRuntime} ${diff}`);
            startTime = 0;
          }
        }
        if (startTime > 0 && this.meta.reachToEndFlag) {
          // 視聴履歴やエピソード一覧での視聴位置の表示に使うので、falcorで0に上書きするのはNG
          // console.log(`resumePoint reatch_to_end_flag = true ${startTime}`);
          startTime = 0;
        }

        // スタートクレジットがあり、以下の条件を満たす時
        //   連続再生ON
        //   エンドクレジットで次のエピソードに飛ばした場合、あるいは、最後まで視聴した場合
        if (_.has(routeHandler, 'query.sc')) {
          if (routeHandler.query.sc == 1) {
            // @ts-ignore TS2339
            const openingEndPosition = this.cacheMediaInfo.openingEndPosition;
            if (openingEndPosition >= 0) {
              // console.log(`Start credit: openingEndPosition: ${startTime} ${openingEndPosition}`);
              // 0.3秒前から再生を開始する
              startTime = Math.max(openingEndPosition - 0.3, 0);
            }
          }
          deleteQueries.push('sc');
        }
      }
      return startTime;
    };
    let startTime: number | undefined = _getStartTime();

    // DVR不可のライブのピックアップ経由でもクエリがつくので、ここでまとめて入れておく
    if (_.get(routeHandler.query, QUERY.TIME, null) != null) {
      deleteQueries.push(QUERY.TIME);
    }
    if (_.get(routeHandler.query, QUERY.LIVE_EDGE, null) != null) {
      deleteQueries.push(QUERY.LIVE_EDGE);
    }
    if (shouldDeleteQuery && !_.isEmpty(deleteQueries)) {
      _.forEach(deleteQueries, query => {
        delete routeHandler.query[query];
      });
      const to = URLGenerator.createRelative({
        path: routeHandler.path,
        query: routeHandler.query,
      });
      history.replace(to, { norender: true });
    }
    // console.log('startTime:' + startTime);
    // メタの情報も更新しておく
    // this.meta.bookmarkPosition = startTime;
    this.meta.resumePoint = startTime;
    return startTime;
  }

  fetchData(_props, context) {
    const props = _.clone(_props);
    props.type = _.get(this.meta, 'type');
    if (_.get(this.meta, 'id') == props.id && this.meta.titleMetaId) {
      props.titleMetaId = this.meta.titleMetaId;
    }
    if (_.get(this.meta, 'id') == props.id && this.meta.leadSeasonId) {
      props.leadSeasonId = this.meta.leadSeasonId;
    }
    // @ts-ignore TS2339
    if (_.get(this.nextEpisode, 'leadSeasonId')) {
      // @ts-ignore TS2339
      props.nextEpisodeLeadSeasonId = this.nextEpisode.leadSeasonId;
    }

    // 1. メタの情報を取得する(falcor)
    // 2. メタに紐づくrightsを取得し、そのrightsとuserが紐づくか(再生可能かどうか)検証するAPIを叩く
    // 3-A. 再生可能なものがない場合は再生するために必要な条件を取得する(api)
    // 3-B. 再生可能なメディアがある場合はプレイヤーを起動する(今のところref:media_idの形で渡しておく)
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(context.models, {}, props);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    const nextEpisodeRootPath = NextEpisode.getRootPath(context.models, {}, props);
    const eventInfoRootPath = EventInfo.getRootPath(context.models, {}, props);
    const linearChannelMetasRootPath = Simulcast.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    const titleMetaRootPath = this.constructor.getTitleMetaRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    const seasonMetaRootPath = this.constructor.getSeasonMetaRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    const nextEpisodeSeasonMetaRootPath = this.constructor.getNextEpisodeSeasonMetaRootPath(context.models, {}, props);
    const evaluator = props.pathEvaluator.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(async res => {
        this.meta = _.get(res.json, rootPath);
        if (!this.meta) {
          this.exitPlayer();
          return;
        }
        // 視聴ログにまれにrefIdが連携されない場合があるため、refIdが取れなければキャッシュを消して取り直す
        if (!this.meta.refId) {
          const sampleMovieMetaId = context.getModelData('sampleMovieMetaId');
          // 視聴確認動画には参照idはないので無視する
          if (this.meta.id != sampleMovieMetaId) {
            this.model.invalidate(rootPath);
            const metaRes = await this.model.fetch(paths);
            this.meta = _.get(metaRes.json, rootPath);
            // それでもrefIdがない場合はlog用に通信を飛ばす
            if (!this.meta.refId) {
              // 非同期
              try {
                const data = {
                  meta: JSON.stringify(this.meta, getCircularReplacer()),
                  path: context.routeHandler.url,
                };
                this.axios.post('/api/logger/no_ref_id', data);
              } catch (e) {}
            }
          }
        }
        if (this.meta) {
          if (this.meta.type !== 'media' && this.meta.type !== 'linear_channel') {
            // @ts-ignore TS2554
            throw new NotFoundError();
          }
          // this.meta.isMulti = true;
        }

        // iframeの場合は次の動画のボタンを非表示にするので取得しない
        if (!context.isIframe && nextEpisodeRootPath) {
          let nextEpisodeData = _.get(res.json, nextEpisodeRootPath);
          if (
            _.get(nextEpisodeData, 'cardInfo.deliveryStartAt') &&
            // @ts-ignore TS2554
            getIsDeliveryStarted(nextEpisodeData.cardInfo.deliveryStartAt, nextEpisodeData.cardInfo.deliveryEndAt)
          ) {
            // @ts-ignore TS2339
            this.nextEpisode = nextEpisodeData;
          } else {
            // @ts-ignore TS2339
            delete this.nextEpisode;
          }
        }

        // @ts-ignore TS2554
        const onairEvent = EpgEvent.additionalData(_.get(res.json, eventInfoRootPath));

        if (this.meta.type === 'linear_channel') {
          const linearChannelMetas = _.get(res.json, linearChannelMetasRootPath);
          // @ts-ignore TS2339
          this.linearChannelMetaIds = _.map(linearChannelMetas, channel => channel.id);
        }
        // @ts-ignore TS2339
        if (props.titleMetaId) this.titleMeta = _.get(res.json, titleMetaRootPath);
        // @ts-ignore TS2339
        if (props.leadSeasonId) this.seasonMeta = _.get(res.json, seasonMetaRootPath);
        // @ts-ignore TS2339
        if (props.nextEpisodeLeadSeasonId) this.nextEpisodeSeasonMeta = _.get(res.json, nextEpisodeSeasonMetaRootPath);

        // 見た目に与える影響があるのでこの段階でまずはrenderを読んでおく
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.pathEvaluator.getVersion(rootPath),
          eventData: onairEvent,
          fetching: true,
        };

        // WatchParty
        const userInfo = context.getModelData('userInfo');
        const browserInfo = context.getModelData('browserInfo');
        if (
          // @ts-ignore TS2339
          this.state.watchPartyType == 'none' &&
          !!this.meta.wpEnableFlag &&
          userInfo.status !== 'NON_REGISTERED_MEMBER'
        ) {
          if (this.meta.schemaId === 3) {
            // @ts-ignore TS2339
            newState.watchPartyType = 'closeType';
          } else {
            // @ts-ignore TS2339
            newState.watchPartyType = !!this.meta.wpOcId ? 'openType' : 'none';
          }
          // @ts-ignore TS2339
          if (newState.watchPartyType === 'openType') {
            // @ts-ignore TS2339
            newState.watchPartyMode = true;
          }
          if (!browserInfo.isIOS && !browserInfo.isAndroid) {
            // @ts-ignore TS2339
            newState.commentActive = !!this.meta.wpDoFlag;
          } else {
            // this.openCommentDrawer();
          }
          // chat を優先する
          const chatType = _.get(context.routeHandler, 'query.chat');
          if (chatType === 'on') {
            if (!browserInfo.isIOS && !browserInfo.isAndroid) {
              // @ts-ignore TS2339
              newState.commentActive = true;
            } else {
              // this.openCommentDrawer();
            }
          } else if (chatType === 'off') {
            if (!browserInfo.isIOS && !browserInfo.isAndroid) {
              // @ts-ignore TS2339
              newState.commentActive = false;
            }
          }
        }
        // @ts-ignore TS2339
        if (newState.watchPartyMode && newState.watchPartyType === 'openType') {
          context.watchPartyApp.roomId = this.meta.wpOcId;
        }
        if (!browserInfo.features.playable) {
          // 推奨環境以外の場合はチャットを表示しない
          // @ts-ignore TS2339
          newState.commentActive = false;
        }

        // @ts-ignore TS2554
        const profile = activeProfile(context.models);
        if (!profile) {
          if (this.meta.memberOnly === true) {
            this.exitFullScreen();
            // もしこのメタがmemberOnlyである場合はログインの必要性がある
            // @ts-ignore TS2339
            newState.error = playError(ERROR.UNAUTHORIZED);
          }
        }

        return newState;
      })
      .then(newState => {
        // ここにおいては特に処理を行う必要性はない
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);

        if (!newState.error) {
          this.checkLocalTime()
            .then(() => {
              // 初期化
              // @ts-ignore TS2339
              this.userForceSynced = false;
              this.prepareToPlay(props, context);
            })
            .catch(e => {
              const error = playError(e);
              const newState = { error, fetching: false };
              // @ts-ignore TS2339
              if (this._isMounted) this.setState(newState);
              else this.state = Object.assign(newState);
            });
        }
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          dispose: null,
          fetching: false,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  prepareToPlay(props, context, retryCount = 0) {
    // この処理はスタブ処理、これはBCプレイヤーが対応されたら変更する
    // 一旦PlaybackAPIたたいておく
    // chat 消す
    if (_.has(context.routeHandler, 'query.chat')) {
      delete context.routeHandler.query.chat;
      const to = URLGenerator.createRelative({
        path: context.routeHandler.path,
        query: context.routeHandler.query,
      });
      context.history.replace(to, { norender: true });
    }

    // playback session id があれば指定してreplace
    // 複数タブで視聴しようとした場合psidのreplaceを防ぐため、フォーカスが当たっていなければ取り出さない
    const playbackSessionId = document.hasFocus() ? psidDataStore.get() : null;

    // media
    const mediaId = _.get(context.routeHandler, 'query.m', null);

    // @ts-ignore TS2554
    const profile = activeProfile(context.models);
    const cookieResumePointKey = profile
      ? `${RESUME_POINT_KEY}-${profile.id}-${this.meta.id}`
      : `${RESUME_POINT_KEY}-anon-${this.meta.id}`;

    let canceled = false;
    // @ts-ignore TS2339
    this.__$promise = new Promise((resolve, reject) => {
      const authContext = context.getModelData('authContext');

      const browserInfo = this.context.getModelData('browserInfo');
      let useCheckAvailability = false;

      // スマホの場合
      if (browserInfo.isAndroid || browserInfo.isIOS || browserInfo.isRequestDesktopWebsite) {
        // 無料以外
        // pvページ以外
        // 視聴確認動画（SPブラウザで流してはいけない）
        // 上記の場合は権利判定だけして視聴させない
        if (this.meta.memberOnly || !props.isPV || this.meta.schemaId === 6) {
          useCheckAvailability = true;
          this.exitFullScreen();
        }
      }

      // キャスト再生中かつ自身の場合は権利チェックを行う
      if (context.playerApp.isSelfGCasted(_.get(authContext, 'id'))) {
        useCheckAvailability = true;
      }

      // playable
      if (!browserInfo.features.playable) {
        useCheckAvailability = true;
      }

      if (useCheckAvailability) {
        const q = {
          meta_id: this.meta.id,
          error_flag: true,
          _strict: 1,
        };
        const headers = {};

        this.playbackUsecase
          .checkAvailability(q, headers)
          .then(() => {
            // スマホは全てアプリ誘導
            if (browserInfo.isAndroid || browserInfo.isIOS || browserInfo.isRequestDesktopWebsite) {
              const newState = {
                playContext: playContext(context)
                  .set('video', {})
                  .set('playbackRule', {})
                  .set('session', {}),
                fetching: false,
              };
              // @ts-ignore TS2339
              if (this._isMounted) this.setState(newState);
              else this.state = Object.assign(newState);
              return;
            }

            const newState = {
              playContext: playContext(context)
                .set('video', {})
                .set('playbackRule', {})
                .set('session', {}),
              fetching: false,
            };
            if (!browserInfo.features.playable) {
              // @ts-ignore TS2339
              newState.error = playError(ERROR.NOT_PLAYABLE);
            }
            // @ts-ignore TS2339
            if (this._isMounted) this.setState(newState);
            else this.state = Object.assign(newState);
          })
          .catch(e => reject(e));
        return;
      }

      let vuid = VUID;

      // vuidキャッシュがあるときはキャッシュを返す
      if (!vuid) {
        vuid = vuidDataStore.get();
      }

      // vuidないときはキャッシュする
      if (!vuid) {
        vuid = vuidDataStore.create();
        vuidDataStore.set(vuid);
      }

      if (vuid) {
        VUID = vuid;
      }

      if (!DSID) {
        DSID = this.context.cookies.get(DSID_KEY);
        if (!DSID) {
          DSID = generateUuid(false);
        }
        if (window.navigator.cookieEnabled) {
          this.context.cookies.set(DSID_KEY, DSID, { path: '/' });
        }
      }

      const params = {
        meta_id: this.meta.id,
        vuid,
        device_code: context.getModelData('browserInfo', 'deviceCode'),
        app_id: 1,
        ua: window.navigator.userAgent,
      };

      // @ts-ignore TS2339
      if (this.meta.service) params.service = this.meta.service;

      // @ts-ignore TS2551
      if (mediaId) params.media_id = mediaId;

      const cookieResumePoint = this.context.cookies.get(cookieResumePointKey);

      // @ts-ignore TS2339
      if (profile && profile.id) params.user_id = profile.id;

      // @ts-ignore TS2339
      if (this.exercise) params.exercise = true;

      let headers = {};
      if (playbackSessionId) {
        headers['X-Playback-Session-Id'] = playbackSessionId;
      }

      this.playbackUsecase
        .auth(params, headers)
        .then(res => {
          // cookieに保存されている値を設定する
          if (cookieResumePoint !== undefined) {
            // @ts-ignore
            res.data.resume_point = cookieResumePoint;
          }
          return resolve(res.data);
        })
        .catch(reject);
    })
      .then(async mediaInfo => {
        if (canceled) return;

        const browserInfo = this.context.getModelData('browserInfo');

        const { routeHandler } = context;
        const eirinMention = _.find(this.meta.mentions || [], mention => mention.type === 'eirin');
        // 映倫チェック
        // 続きから再生される場合・表示内容がない場合はスルー
        // @ts-ignore TS2339
        if (!this.state.eirinConfirmed && eirinMention && eirinMention.message) {
          const startTime = this.getStartTime(mediaInfo, false);
          if (_.has(routeHandler, 'query.ec')) {
            delete routeHandler.query.ec;
            this.handleChangeQuery(routeHandler.query);
          } else if (startTime == undefined || startTime <= 0) {
            const newState = { error: playError(ERROR.EIRIN) };
            // @ts-ignore TS2339
            if (this._isMounted) this.setState(newState);
            else this.state = Object.assign(newState);
            return;
          }
        }

        // 再生開始チェック
        // 一旦ここではDVR可能設定になっていればautoplayを止める
        // @ts-ignore TS2339
        const playbackRuleData = playbackRule(mediaInfo.playback_rule);
        let queryTime = _.get(routeHandler.query, QUERY.TIME);
        queryTime = parseInt(queryTime, 10);
        let matchStartTime = _.get(routeHandler.query, QUERY.MATCH_START_TIME);
        matchStartTime = parseInt(matchStartTime, 10);
        let fromLiveEdge = _.get(routeHandler.query, QUERY.LIVE_EDGE);
        fromLiveEdge = fromLiveEdge === true;
        // @ts-ignore TS2339
        if (!this.state.startOverConfirmed && playbackRuleData.enableStartOver) {
          let maybeEnableStartOver = true;
          if (!_.isNaN(matchStartTime)) {
            // 試合開始時間の指定がある場合はダイアログを出す
          } else if (fromLiveEdge) {
            // ライブ位置からの再生指定
            maybeEnableStartOver = false;
          } else if (!_.isNaN(queryTime) && queryTime !== 0) {
            // tクエリで再生位置の指定がある場合
            maybeEnableStartOver = false;
          } else if (this.meta && this.meta.resumePoint) {
            // レジュームがある場合
            maybeEnableStartOver = false;
          }
          this.setState({ maybeEnableStartOver });
        }

        // beacon
        const broadcastType = () => {
          switch (this.meta.schemaId) {
            case 4: // ライブ
              return 1;
            case 5: // リニア
              return 3;
            default:
              return 2;
          }
        };
        const programCode = () => {
          return _.get(this.meta.seasonMeta, 'refId', _.get(this.meta.seriesMeta, 'refId'));
        };
        const relationProgramCode = () => {
          // シーズンがある場合は3階層
          if (this.meta.seasonMeta) {
            return _.get(this.meta.seriesMeta, 'refId');
          }
          return '';
        };
        const analytics = {
          vuid: VUID,
          dsid: DSID,
          t_flg: browserInfo.isBMW ? '15' : browserInfo.isAudi ? '16' : '03', // 端末種別
          os_version: _.get(browserInfo, 'os.version'), // OSバージョン
          series_meta_id: _.get(this.meta, 'seriesMeta.metaId', ''), // シリーズのメタID
          season_meta_id: _.get(this.meta, 'seasonMeta.metaId', ''), // シーズンのメタID
          schema_id: this.meta.schemaId, //スキーマID(logicaのスキーマID 視聴ログをLive or Archiveに分割するために使用)
          // ダウンロードフラグ(ダウンロードフラグ 通常: 0：ダウンロード再生時: 1)
          download_flag: 0, // WEBは常に0
          // 放送種別(ライブ（Live）：1、見逃し（Archive）：2、放送同時（Simulcast）：3)
          broadcast_type: broadcastType(),
        };
        if (this.meta.type === 'linear_channel') {
          // @ts-ignore TS2339
          analytics.meta_type = 'event'; // メタのタイプ(視聴ログをLive/Archive or Simulcastに分割するために使用 event or meta)
          // @ts-ignore TS2339
          analytics.channnel = _.replace(this.meta.refId, 'wowow_', ''); // チャンネルID(Simalcast(event)時のみ付与 191：プライム 192：ライブ 193：シネマ)
        } else {
          // @ts-ignore TS2339
          analytics.meta_type = 'meta'; // メタのタイプ(視聴ログをLive/Archive or Simulcastに分割するために使用 event or meta)
          // 番組グループID(関連番組ID)	Holmesが払い出す番組グループID
          // @ts-ignore TS2339
          analytics.relation_program_code = relationProgramCode(); // ３階層：シリーズ
          // 番組コード(Holmesが払い出す番組コード)
          // @ts-ignore TS2339
          analytics.program_code = programCode(); // ３階層：シーズン、2階層：シリーズ
          // @ts-ignore TS2339
          analytics.content_id = this.meta.refId; // コンテンツID(Holmesが払い出すコンテンツID)
        }
        if (profile) {
          // @ts-ignore TS2339
          analytics.member_id = profile.refId; // WOLメンバーID
          // @ts-ignore TS2339
          analytics.usernum = profile.customerNum || ''; // お客様番号(テレビ会員番号customer_num)
          // @ts-ignore TS2339
          analytics.k_type = profile.kType || ''; // 連携元フラグ
        }

        const hasEntitlement = !!_.get(mediaInfo, 'log_params.ent_id');
        // 無料ユーザーに対して権利がない場合（entitlementがあるかどうかAPI叩く
        if (!profile) {
          this.shouldPlayAd = true;
        }
        const shouldCheckEntitlementForAds = !!(profile && !hasEntitlement);
        const shouldCheckEntitlementForInfo = !!(
          !this.isCarMonitor &&
          this.meta.infoSrc &&
          this.meta.infoVisibility === 'non_entitled' &&
          !hasEntitlement
        );

        // 権利の確認が必要ない、もしくは権利がない場合にresolveする
        const entPromise = new Promise((resolve, reject) => {
          if (shouldCheckEntitlementForAds || shouldCheckEntitlementForInfo) {
            const authContext = context.getModelData('authContext');
            if (!authContext) {
              // @ts-ignore TS2794
              return resolve();
            }
            const q = { meta_id: this.meta.id };
            // @ts-ignore TS2339
            this.axios
              .get('/api/account/entitlements', q, {})
              .then(res => {
                if (!_.get(res, 'data.result')) {
                  return reject();
                }
                const entitlements = _.get(res, 'data.entitlements');
                // メタに紐づく権利がない場合
                if (_.isEmpty(entitlements)) {
                  this.shouldPlayAd = true;
                  // @ts-ignore TS2794
                  return resolve();
                } else {
                  return reject();
                }
              })
              .catch(e => {
                return reject();
              });
          } else {
            resolve(null);
          }
        });
        await entPromise
          .then(() => {
            // always | non_entitled | none
            if (this.meta.infoVisibility !== 'none' && this.meta.infoSrc && !this.isCarMonitor) {
              let el;
              if (this.meta.infoType === 'snippet' && this.meta.infoSrc.html_snippet_id) {
                // @ts-ignore TS2322
                el = <HtmlSnippet snippetId={this.meta.infoSrc.html_snippet_id} model={this.model} />;
              } else if (this.meta.infoType === 'iframe') {
                // @ts-ignore TS2322
                el = <WodIframe src={this.meta.infoSrc} />;
              }
              const infoState = {};
              if (this.isSpBrowser) {
                this.scrollTop();
                const targetEl = document.querySelector('.below-player');
                // @ts-ignore TS2339
                this.props.openDrawer(el, { targetElement: targetEl, behindElementRefs: [this.belowPlayerRef] });
              } else {
                // @ts-ignore TS2339
                infoState.infoContent = el;
              }
              this.setState(infoState);
            }
          })
          .catch(() => {});

        const newState = {
          playContext: playContext(context)
            .set('video', {
              seekPreview: {
                // @ts-ignore TS2339
                urls: mediaInfo.media.thumbnail_tile_urls || [],
              },
              videoId: _.get(mediaInfo, 'media.ovp_video_id'),
              ovpId: _.get(mediaInfo, 'media.ovp_id'),
              // @ts-ignore TS2339
              analytics: Object.assign({}, mediaInfo.log_params, analytics),
              session: {
                // @ts-ignore TS2339
                id: mediaInfo.playback_session_id,
              },
              logo: {},
              progressImageRoot: null,
              title: this.meta.name,
              rating: this.meta.cardInfo.rating,
              metadata: this.metadata,
              multiView: this.meta.isMulti ? (
                <MultiView
                  // @ts-ignore TS2322
                  id={this.meta.id}
                  mediaId={_.get(mediaInfo, 'media.media_id')}
                  // @ts-ignore TS2339
                  model={this.model}
                  onClick={this.handleMediaChange}
                />
              ) : null,
              requiresAdultVerification: false,
              requiresPin: false,
              requiresPreReleasePin: false,
              hd: true,
              cretisOffset: 0,
              autoplayable: true,
              audioOnly: this.meta.audioOnly,
              artwork: this.meta.thumbnailUrl ? [this.meta.thumbnailUrl] : [],
              mediaValues: {
                videoSkips: _.map(_.get(mediaInfo, 'media.values.video_skips'), (position, i) => {
                  return {
                    idx: i,
                    // @ts-ignore TS2339
                    start: position.start_position + 2, // 2秒後
                    // @ts-ignore TS2339
                    end: position.end_position - 0.3, // 0.3秒前
                  };
                }),
                openingEndPosition: _.get(mediaInfo, 'media.values.opening_end_position'),
                endingStartPosition: _.get(mediaInfo, 'media.values.ending_start_position'),
              },
            })
            .set('playbackRule', playbackRuleData)
            .set('session', {
              // @ts-ignore TS2339
              id: mediaInfo.playback_session_id,
            }),
          videoId: _.get(mediaInfo, 'media.ovp_video_id'),
          ovpId: _.get(mediaInfo, 'media.ovp_id'),
          fetching: false,
          startTime: this.getStartTime(mediaInfo, true),
        };

        // @ts-ignore TS2339
        if (mediaInfo.playback_session_id) {
          // @ts-ignore TS2339
          this.reauthRetryCount = 0;
          // reauthで使うためにインスタンス変数として持つ
          // @ts-ignore TS2339
          this.playbackSessionId = mediaInfo.playback_session_id;
          // @ts-ignore TS2339
          this.setPsidTimer(this.playbackSessionId);
          // reauthするタイミングを取得してsetTimeoutする
          this.setTimeoutReauth(_.get(mediaInfo, 'playback_validity_end_at'));
        }

        // playback/auth を通過したら消す
        if (window.navigator.cookieEnabled) {
          context.cookies.remove(cookieResumePointKey);
        }

        // 最後に視聴したリニアチャンネルを記録しておく（ヘッダーから遷移するため）
        if (this.meta.type === 'linear_channel') {
          lastChannelDataStore.set(this.meta.refId);
        }

        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      })
      .catch(e => {
        if (canceled) return;

        // 連続再生の切り替え時に値が取れない場合があるのでリトライする
        if (retryCount <= 2 && e == 'mediaSession is null') {
          retryCount = retryCount + 1;
          setTimeout(() => {
            if (canceled) return;
            this.prepareToPlay(props, context, retryCount);
          }, retryCount * 2000);
          return;
        }

        // 無効な再生セッションIDの場合は削除してリトライ
        if (retryCount <= 2 && playbackSessionId && e === ERROR.PLAYBACK_SESSION_ID_INVALID) {
          psidDataStore.remove();
          retryCount = retryCount + 1;
          this.prepareToPlay(props, context, retryCount);
          return;
        }

        // mediaId が指定されていて見つからなかった場合、または
        // codeが1かつmessageの中にmedia_idが存在する場合、指定したmediaIdに不備があると判断し、mediaIdを削除してリトライする
        const errCode = _.get(e, 'code');
        const errMessage = _.get(e, 'message', '');
        if (
          retryCount <= 2 &&
          mediaId &&
          (errCode === 2020 || (errCode === 1 && errMessage.indexOf('media_id') >= 0))
        ) {
          const query = context.routeHandler.query;
          delete query.m;
          let to = context.routeHandler.route.makePath({ id: context.routeHandler.params.id }, query);
          context.history.replace(to, { norender: true });
          retryCount = retryCount + 1;
          this.prepareToPlay(props, context, retryCount);
          return;
        }

        // InternalServerError、2秒後に1度だけリトライ
        if (retryCount < 1 && errCode === 6) {
          retryCount = retryCount + 1;
          setTimeout(() => {
            if (canceled) return;
            this.prepareToPlay(props, context, retryCount);
          }, 2000);
          return;
        }

        let promise;
        if (e === ERROR.LICENSE_INVALID) {
          promise = new Promise((resolve, reject) => {
            // TODO: ログインが必要かどうかの判定はhowto_playを叩く前に判定する
            // ログインしている場合は最初からauthを叩き視聴できない時にhowto_playを叩く順番とする
            // メタの再生に必要な権限に紐づく情報を含めて取得する（with_howto_acquisition）
            // @ts-ignore TS2339
            this.axios
              // @ts-ignore TS2339
              .get(`/api/meta/${this.props.id}/howto_play`, { with_howto_acquisition: true })
              .then(result => {
                if (canceled) return;

                const authContext = context.getModelData('authContext');
                // @ts-ignore TS2554
                const profile = activeProfile(context.models);
                const howToPlayInfo = result.data || {};

                if (!howToPlayInfo.free || howToPlayInfo.memberOnly) {
                  if (!authContext) {
                    return reject(ERROR.UNAUTHORIZED);

                    // メタに視聴権利が設定されていなかった場合はエラーを出す
                  } else if (_.get(howToPlayInfo, 'rights.length') === 0) {
                    return reject(ERROR.META_NOT_VIEWABLE);
                  }
                }
                return resolve(howToPlayInfo);
              })
              .catch(reject);
          });
        } else {
          promise = Promise.reject(e);
        }

        promise
          .then(howToPlayInfo => {
            throw { code: ERROR.LICENSE_INVALID, object: howToPlayInfo };
          })
          .catch(e => {
            if (canceled) return;

            if (typeof e === 'object') {
              _.set(e, 'metaId', props.id);
            }

            const error = playError(e);
            console.error(e);
            if (retryCount <= 1 && error.type !== ERROR.LICENSE_INVALID && error.type !== ERROR.UNAUTHORIZED) {
              if (_.get(e, 'code') === 2050) {
                // 同時視聴制限に引っかかっている場合のみリトライする
                retryCount = retryCount + 1;
                setTimeout(() => {
                  if (canceled) return;
                  this.prepareToPlay(props, context, retryCount);
                }, retryCount * 2000);
                return;
              }
            }

            if (error.type === ERROR.EXERCISE_ALERT) {
              const newState = {
                fetching: false,
                error,
                isShowExerciseModal: true,
              };
              // @ts-ignore TS2339
              if (this._isMounted) {
                // @ts-ignore TS2339
                this.props.showModal(
                  <ExerciseModal
                    // @ts-ignore TS2322
                    closeModal={this.props.closeModal}
                    metadata={this.meta}
                    error={error}
                    onCancel={() => {
                      // @ts-ignore TS2339
                      this.props.closeModal();
                      this.setState({ isShowExerciseModal: false });
                    }}
                    onClick={() => {
                      // @ts-ignore TS2339
                      this.props.closeModal();
                      this.setState({ fetching: true, error: null, isShowExerciseModal: false }, () => {
                        // @ts-ignore TS2339
                        this.exercise = true;
                        this.prepareToPlay(props, context, retryCount);
                      });
                    }}
                  />,
                  {
                    enableCloseWithOutsidePointerDown: false,
                  },
                );
                this.setState(newState);
              } else this.state = Object.assign(newState);
              return;
            }
            // 支払い方法追加して戻ってきた場合
            if (_.get(this.context, 'routeHandler.query.payment_instrument_id')) {
              const { routeHandler, history } = this.context;
              const browserInfo = this.context.getModelData('browserInfo');
              const isSP = browserInfo.isAndroid || (browserInfo.isIOS && !browserInfo.isiPad);
              if (!isSP && error.type === ERROR.LICENSE_INVALID && this.meta.rental) {
                const newState = {
                  error,
                  fetching: false,
                };
                // @ts-ignore TS2339
                if (this._isMounted) {
                  let selected = {};
                  if (routeHandler.query.product_id) {
                    selected = { type: 'product', id: parseInt(routeHandler.query.product_id, 10) };
                    delete routeHandler.query.product_id;
                  }
                  // @ts-ignore TS2339
                  this.props.showModal(
                    <ErrorBoundary>
                      <UpSellFlow
                        // @ts-ignore TS2322
                        selected={selected}
                        // @ts-ignore TS2339
                        model={this.model}
                        metaId={_.get(this, 'meta.id')}
                        metaRefId={_.get(this, 'meta.refId')}
                        defaultPaymentInstrumentId={routeHandler.query.payment_instrument_id}
                      />
                    </ErrorBoundary>,
                  );
                  this.setState(newState);
                } else {
                  this.state = Object.assign(newState);
                }
              }

              delete routeHandler.query.payment_instrument_id;
              const to = URLGenerator.createRelative({
                path: routeHandler.path,
                query: routeHandler.query,
              });
              history.replace(to, { norender: true });
              return;
            }

            const newState = {
              error,
              fetching: false,
            };
            // 配信前の場合かつコメント欄デフォルト表示ONの場合は強制的にOFFにする
            // @ts-ignore TS2339
            if (error.type == ERROR.META_NOT_DELIVERY_STARTED && this.state.commentActive) {
              // @ts-ignore TS2339
              newState.commentActive = false;
            }
            // @ts-ignore TS2339
            if (this._isMounted) this.setState(newState);
            else this.state = Object.assign(newState);
          });
      });
    // @ts-ignore TS2339
    this.__$promise.dispose = function() {
      canceled = true;
    };
  }

  doErrorAction(options = {}) {
    const browserInfo = this.context.getModelData('browserInfo');
    const isSP = browserInfo.isAndroid || (browserInfo.isIOS && !browserInfo.isiPad);

    // @ts-ignore TS2339
    switch (this.state.error.type) {
      case ERROR.UNAUTHORIZED:
        if (this.context.isIframe) {
          const host = _.get(this.context.getModelData('hosts'), 'host');
          window.parent.location.href = `${host}/login?return_to=${encodeURIComponent(window.parent.location.href)}`;
        } else {
          // @ts-ignore TS2554
          const to = routes.watchNow.makePath({ id: this.props.id });
          window.location.href = `/login?return_to=${window.encodeURIComponent(to)}`;
        }
        break;
      case ERROR.LICENSE_INVALID:
        // スマホの場合はGenericのUpSellFlowへ遷移させる
        if (isSP) {
          // @ts-ignore TS2339
          window.location.href = `/usf/${this.props.id}/confirm?${options.selected.type}_id=${options.selected.id}&return_to=${options.returnTo}`;
        } else {
          // @ts-ignore TS2339
          this.props.showModal(
            <ErrorBoundary>
              <UpSellFlow
                // @ts-ignore TS2322
                model={this.model}
                // @ts-ignore TS2339
                selected={options.selected}
                metaId={_.get(this, 'meta.id')}
                metaRefId={_.get(this, 'meta.refId')}
              />
            </ErrorBoundary>,
          );
        }
        break;
      case ERROR.EIRIN:
        // @ts-ignore TS2339
        if (options.button === 'submit') {
          this.setState({ eirinConfirmed: true }, this.reflash);
        } else {
          // シリーズに戻す
          const { history } = this.context;
          // 通常運用ではありえないがデータとしてはありえるので、この場合はTOPへ遷移させるようにする
          if (this.meta.titleMetaId == this.meta.id) {
            // @ts-ignore TS2554
            history.push(routes.home.makePath());
          } else {
            // @ts-ignore TS2554
            history.push(routes.title.makePath({ id: this.meta.titleMetaId }));
          }
        }
        break;
      case ERROR.START_OVER:
        const newState = { startOverConfirmed: true };
        // @ts-ignore TS2339
        if (options.button === 'submit') {
          // @ts-ignore TS2339
          newState.beginning = true;
        }
        newState['maybeEnableStartOver'] = null;
        this.setState(newState, this.reflash);
        break;
    }
  }

  checkLocalTime() {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          let response = xhr.response;
          if (typeof response === 'string') {
            response = JSON.parse(response);
          }
          if (response.t) {
            if (datetime(response.t).isValid()) {
              // +-5分以内ならOK
              const now = datetime();
              const from = datetime(response.t).add(-5, 'minutes');
              const to = datetime(response.t).add(5, 'minutes');
              if (now.isBetween(from, to, null, '[]')) {
                // @ts-ignore TS2794
                return resolve();
              }
            } else {
              console.error('utilsTimeCheck is invalid.');
            }
          }
          reject(ERROR.LOCAL_TIME_INVALID);
        }
      };
      let cmsService = Object.assign({}, this.context.getModelData('services', 'cms'));
      cmsService.pathname = _.join(_.concat(cmsService.path, 'utils/time_check'), '/');
      xhr.open('GET', url.format(cmsService), true);
      xhr.send();
    });
  }

  //
  // 引数で渡された秒数後にreauthを実行する
  //
  setTimeoutReauth(playbackValidityEndAt) {
    // @ts-ignore TS2339
    if (!this.playbackSessionId) {
      return;
    }
    if (!playbackValidityEndAt) {
      return;
    }
    if (!datetime(playbackValidityEndAt).isValid()) {
      console.error('playbackValidityEndAt is invalid.');
      return;
    }
    let intervalToReauth = datetime(playbackValidityEndAt).diffFromNow('second');
    if (intervalToReauth <= 0) {
      intervalToReauth = 0;
    }

    // 2147483647 (約 24.8 日) より大きな遅延時間を使用すると整数値がオーバーフローする為
    if (intervalToReauth > 2147483) {
      intervalToReauth = 2147483;
    }

    // @ts-ignore TS2339
    if (this.reauthTimeoutID) {
      // @ts-ignore TS2339
      window.clearTimeout(this.reauthTimeoutID);
    }

    // キャスト中
    if (this.context.playerApp.isGCasted()) return;

    // @ts-ignore TS2339
    this.reauthTimeoutID = window.setTimeout(this.execReauth.bind(this), intervalToReauth * 1000);
  }

  tokenCheck() {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          let response = xhr.response;
          if (typeof response === 'string') {
            response = JSON.parse(response);
          }
          if (response.result) {
            // @ts-ignore TS2794
            resolve();
          } else {
            reject();
          }
        }
      };
      let tokenManagerService = Object.assign({}, this.context.getModelData('services', 'tokenManager'));
      tokenManagerService.pathname = _.join(_.concat(tokenManagerService.path, 'token/check'), '/');
      // @ts-ignore TS2339
      xhr.open('POST', url.format(tokenManagerService), !this.__unloading);
      xhr.setRequestHeader('Content-Type', 'application/json');
      const authContext = this.context.getModelData('authContext');
      let params;
      if (authContext) {
        xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
        xhr.setRequestHeader('X-Token-Id', authContext.id);
        params = JSON.stringify({ wip_access_token: authContext.idpToken });
      }
      xhr.send(params);
    });
  }

  tokenReflesh() {
    return new Promise((resolve, reject) => {
      // @ts-ignore TS2339
      this.tokenApp
        // @ts-ignore TS2339
        .tokenRefresh(!this.__unloading)
        .then(res => {
          // @ts-ignore TS2794
          if (res.result) {
            return resolve(null);
          } else {
            return reject();
          }
        })
        .catch(e => {
          // @ts-ignore TS2794
          return resolve();
        });
    });
  }

  //
  // reauth実行
  //
  execReauth(props = this.props, context = this.context) {
    // キャスト中
    if (context.playerApp.isGCasted()) return;

    new Promise((resolve, reject) => {
      const q = {
        meta_id: this.meta.id,
        device_code: context.getModelData('browserInfo', 'deviceCode'),
        app_id: 1,
        // @ts-ignore TS2339
        playback_session_id: this.playbackSessionId,
        vuid: VUID,
      };

      this.playbackUsecase
        .reauth(q)
        .then(res => {
          return resolve(res.data);
        })
        .catch(reject);
    })
      .then(mediaInfo => {
        // @ts-ignore TS2339
        this.reauthRetryCount = 0;

        // playback_session_id の更新
        // @ts-ignore TS2339
        this.playbackSessionId = mediaInfo.playback_session_id;
        // @ts-ignore TS2339
        this.setPsidTimer(this.playbackSessionId);
        // @ts-ignore TS2339
        const video = this.state.playContext.get('video');
        // @ts-ignore TS2339
        const playbackRule = this.state.playContext.get('playbackRule');
        // @ts-ignore TS2339
        const session = this.state.playContext.get('session');
        // @ts-ignore TS2339
        video.session.id = mediaInfo.playback_session_id;
        if (_.get(video, 'analytics.playback_session_id')) {
          // @ts-ignore TS2339
          video.analytics.playback_session_id = mediaInfo.playback_session_id;
        }
        // @ts-ignore TS2339
        session.id = mediaInfo.playback_session_id;
        this.setState({
          playContext: playContext(context)
            .set('video', video)
            .set('playbackRule', playbackRule)
            .set('session', session),
        });

        // 正常時は再び指定秒数後にreauth設定
        this.setTimeoutReauth(_.get(mediaInfo, 'playback_validity_end_at'));
      })
      .catch(e => {
        // 500系エラー、408エラーの場合は1分後にリトライする
        if (e === ERROR.REAUTH_SERVER_ERROR || e === ERROR.TIMEOUT) {
          // @ts-ignore TS2339
          if (this.reauthRetryCount < 10) {
            // @ts-ignore TS2339
            this.reauthRetryCount++;
            this.setTimeoutReauth(datetime().add(1, 'minutes'));
          } else {
            // 10回以上の場合
            window.location.reload();
          }
          return;
        }

        // 定期実行されるtoken/checkがreauthのタイミングとずれる場合にエラーするので
        // tokenを確認しrefleshした後で再度reauthを実行する
        this.tokenCheck()
          .then(() => {
            // tokenによるエラーではない為
            window.location.reload();
          })
          .catch(e => {
            // リフレッシュ
            this.tokenReflesh()
              .then(() => {
                // 再度実行
                this.execReauth(props, context);
              })
              .catch(e => {
                // リフレッシュ失敗
                window.location.reload();
              });
          });
      });
  }
}

const watchHOC = function(WrappedComponent) {
  class WatchRoute extends React.Component {
    static get contextTypes() {
      return {
        routeHandler: PropTypes.object,
        history: PropTypes.object,
        playerApp: PropTypes.object,
      };
    }

    constructor(props, context) {
      super(props, context);
      this.handleChangeEpisode = this.handleChangeEpisode.bind(this);
      // @ts-ignore TS2339
      this._isMounted = false;
      this.state = {};
    }

    componentDidMount() {
      // @ts-ignore TS2339
      this._isMounted = true;
    }

    componentWillUnmount() {
      // @ts-ignore TS2339
      this._isMounted = false;
    }

    handleChangeEpisode(item) {
      const { routeHandler, history } = this.context;
      const query = {};
      // @ts-ignore TS2554
      let to = routes.watchNow.makePath({ id: item.id }, query);
      if (item.refId) {
        // @ts-ignore TS2554
        to = routes.content.makePath({ id: item.refId }, query);
      }
      if (this.context.playerApp.isGCasted()) {
        history.replace(to);
      } else {
        const props = { id: item.id };
        // @ts-ignore TS2339
        if (item.leadSeasonId) props.leadSeasonId = item.leadSeasonId;
        // @ts-ignore TS2339
        if (item.titleMetaId) props.titleMetaId = item.titleMetaId;
        const paths = Watch.getPrefetchPaths(this.context.models, {}, props);
        // @ts-ignore TS2339
        this.props.pathEvaluator
          .fetch(paths)
          .then(() => {
            // @ts-ignore TS2339
            const norender = !this.props.isPV;
            history.replace(to, { norender });
            this.setState({ id: item.id });
            window.scrollTo(0, 0);
          })
          .catch(e => {
            console.log(e);
            // 必要なデータが取れずに表示がおかしくなる可能性があるのでリセットする
            window.location.href = to;
          });
      }
    }

    render() {
      return React.createElement(
        WrappedComponent,
        Object.assign({}, this.props, this.state, { onChangeEpisode: this.handleChangeEpisode }),
      );
    }
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // @ts-ignore TS2339
  WatchRoute.displayName = `watchHOC(${wrappedComponentName})`;
  if (typeof WrappedComponent.getPaths === 'function') {
    // @ts-ignore TS2339
    WatchRoute.getPaths = WrappedComponent.getPaths.bind(WrappedComponent);
  }
  if (typeof WrappedComponent.getPrefetchPaths === 'function') {
    // @ts-ignore TS2339
    WatchRoute.getPrefetchPaths = WrappedComponent.getPrefetchPaths.bind(WrappedComponent);
  }
  if (typeof WrappedComponent.getPrefetchedPaths === 'function') {
    // @ts-ignore TS2339
    WatchRoute.getPrefetchedPaths = WrappedComponent.getPrefetchedPaths.bind(WrappedComponent);
  }

  return hoistStatics(WatchRoute, WrappedComponent);
};

export default withLayoutContext(withModalContext(withDrawerContext(watchHOC(Watch))));
// export default withLayoutContext(withModalContext(Watch));
