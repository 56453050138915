import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

const iconList = new Map([
  ['pause', 'fa-pause'],
  ['play', 'fa-play'],
  ['forward', 'fa-forward-10'],
  ['replay', 'fa-replay-10'],
  ['volume-high', 'fa-volume-high'],
  ['volume-mind', 'fa-volume-mind'],
  ['mute', 'fa-volume-mute'],
  ['unmute', 'fa-volume-max'],
  ['fullscreen', 'fa-expand'],
  ['window', 'fa-compress'],
]);

class Bezel extends React.PureComponent {
  static get propTypes() {
    return {
      ready: PropTypes.bool,
      action: PropTypes.string,
      autoPlayErrorFlg: PropTypes.bool,
      volume: PropTypes.number,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      style: { display: 'none' },
      icon: '',
    };
    // @ts-ignore TS2339
    this.animateID = null;
  }

  componentDidUpdate(prevProps) {
    // propsが変化した時のみ処理するようにしないとsetStateによりループが発生するので注意
    // @ts-ignore TS2339
    if (!this.props.autoPlayErrorFlg && !_.isEqual(this.props, prevProps) && this.props.ready && this.props.action) {
      let style;
      // @ts-ignore TS2339
      if (!this.animateID) {
        // @ts-ignore TS2339
        switch (this.props.action) {
          case 'forward':
            style = { left: '60%' };
            break;
          case 'replay':
            style = { left: '40%' };
            break;
          default:
            style = {};
        }
        // @ts-ignore TS2339
        this.setState({ style, icon: iconList.get(this.props.action) });

        // @ts-ignore TS2339
        this.animateID = setTimeout(() => {
          this.setState({ style: { display: 'none' }, icon: '' });
          // @ts-ignore TS2339
          this.animateID = null;
        }, 500);
      }
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    if (this.animateID) {
      // @ts-ignore TS2339
      clearTimeout(this.animateID);
    }
  }

  render() {
    return (
      // @ts-ignore TS2339
      <div className="bezel" style={this.state.style}>
        {/*
         // @ts-ignore TS2339 */}
        <div className={classnames('bezel-icon fa', this.state.icon)} />
      </div>
    );
  }
}

export default Bezel;
