import React from 'react';
import _ from 'src/libs/util';

const PageUpdateModal = React.memo(props => {
  const handleClick = () => {
    window.location.reload();
  };

  return (
    <div className="fallback-modal">
      <p>ページを更新してください。</p>
      <div className="btn-block">
        <button className="btn btn-fill" name="fallbackModalClose" onClick={handleClick}>
          更新
        </button>
      </div>
    </div>
  );
});

export default PageUpdateModal;
