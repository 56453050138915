import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';
import datetime from 'src/libs/datetime';

type FooterProps = {
  rootRef?: React.RefObject<HTMLElement>;
  className?: string;
  isAccountLayout?: boolean;
  isSignupLayout?: boolean;
  hideLinks?: boolean;
};

const Footer = (props: FooterProps, context) => {
  const curYear = datetime().format('YYYY');
  if (props.isAccountLayout) {
    return (
      <footer
        className={classnames('footer', { 'signup-footer': props.isSignupLayout }, props.className)}
        role="contentinfo"
        ref={props.rootRef}
      >
        {props.isSignupLayout ? (
          <p className="footer-copyright">©WOWOW INC.</p>
        ) : (
          <React.Fragment>
            <div className="footer__line">
              <span className="blue"></span>
              <span className="red"></span>
              <span className="green"></span>
            </div>
            <div className="lower-colmun">
              {props.hideLinks ? null : (
                <ul className="footer-links">
                  <li className="footer-link-wrapper">
                    <a className="footer-link" href="/my_wowow">
                      My WOWOW TOP
                    </a>
                  </li>
                  <li className="footer-link-wrapper">
                    <a className="footer-link" href="/">
                      WOWOW オンデマンドへ戻る
                    </a>
                  </li>
                  <li className="footer-link-wrapper">
                    <a className="footer-link" href="https://support.wowow.co.jp/" target="_blank">
                      ヘルプセンター
                    </a>
                  </li>
                  <li className="footer-link-wrapper end-of-line">
                    <a className="footer-link" href="https://www.wowow.co.jp/guidance/tokutei/" target="_blank">
                      特定商取引法に基づく表示
                    </a>
                  </li>
                </ul>
              )}
              <p className="footer-copyright">©1996 - {curYear} WOWOW INC.</p>
            </div>
          </React.Fragment>
        )}
      </footer>
    );
  }
  const handleClick = category => {
    if (_.get(context, 'gtmApp') && category) context.gtmApp.pushDataLayerOnFooterClick(category);
  };
  const userInfo = context.getModelData('userInfo');
  return (
    <footer className={classnames('footer', props.className)} role="contentinfo" ref={props.rootRef}>
      <div className="footer__line">
        <span className="blue"></span>
        <span className="red"></span>
        <span className="green"></span>
      </div>
      <div className="lower-colmun">
        <ul className="footer-links">
          <li className="footer-link-wrapper">
            <a className="footer-link" href="/wod/" target="_blank" onClick={e => handleClick('ご利用方法')}>
              ご利用方法
            </a>
          </li>
          {userInfo.status !== 'NON_REGISTERED_MEMBER' ? null : (
            <React.Fragment>
              <li className="footer-link-wrapper">
                <a
                  className="footer-link"
                  href="https://support.wowow.co.jp/"
                  target="_blank"
                  onClick={e => handleClick('ヘルプセンター')}
                >
                  ヘルプセンター
                </a>
              </li>
              <li className="footer-link-wrapper">
                <a className="footer-link" href="/check" onClick={e => handleClick('お試し再生')}>
                  お試し再生
                </a>
              </li>
            </React.Fragment>
          )}
          {/* <li className="footer-link-wrapper"><a className="footer-link" href="/service/chatguide/" target="_blank" onClick={(e) => handleClick('チャットサポート')}>チャットサポート</a></li> */}
          <li className="footer-link-wrapper">
            <a
              className="footer-link"
              href="https://corporate.wowow.co.jp/"
              target="_blank"
              onClick={e => handleClick('会社情報')}
            >
              会社情報
            </a>
          </li>
          <li className="footer-link-wrapper">
            <a
              className="footer-link"
              href="/guidance/attention/"
              target="_blank"
              onClick={e => handleClick('サイトポリシー')}
            >
              サイトポリシー
            </a>
          </li>
          <li className="footer-link-wrapper">
            <a
              className="footer-link"
              href="/static/privacy/"
              onClick={e => handleClick('アプリケーション・プライバシーポリシー')}
            >
              アプリケーション・プライバシーポリシー
            </a>
          </li>
          {/* <li className="footer-link-wrapper end-of-line"><a className="footer-link" href="https://support.wowow.co.jp/s/category?prmcat=category3" target="_blank" onClick={(e) => handleClick('お客様サポート・よくあるご質問')}>お客様サポート・よくあるご質問</a></li> */}
          {/* <li className="footer-link-wrapper"><a className="footer-link" href="/terms">利用規約</a></li> */}
          {/* <li className="footer-link-wrapper"><a className="footer-link" href="/privacy">プライバシーポリシー</a></li> */}
          {/* <li className="footer-link-wrapper"><a className="footer-link" href="/law">特定商取引法に基づく表示</a></li> */}
        </ul>
        <p className="footer-copyright">©1996 - {curYear} WOWOW INC.</p>
        <div className="mark">
          <div className="lmark">
            <img src="/images/footer_lmark.gif" alt="エルマーク" />
            <span>
              このエルマークは、レコード会社・映像製作会社が提供するコンテンツを示す登録商標です
              <br />
              RIAJ60028001
            </span>
          </div>
          <div className="jasrac">
            <img src="/images/footer_jasrac.jpg" alt="JASRAC" />
            <span>
              JASRAC許諾番号
              <br />
              9010055026Y45038
            </span>
          </div>
          <div className="nextone">
            <img src="/images/footer_nextone.png" alt="NexTone" />
            <span>
              NexTone許諾番号
              <br />
              ID000000659
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.contextTypes = {
  getModelData: PropTypes.func,
  cookies: PropTypes.object,
  gtmApp: PropTypes.object,
};

export default Footer;
