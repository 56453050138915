import React from 'react';
import PropTypes from 'prop-types';
import url from 'url';
import _ from 'src/libs/util';

import * as browserEvents from '../../../sketch-platform/utils/browserEvents';
import * as DOMUtils from './../../../sketch-platform/utils/DOMUtils';

class BackgroundImage extends React.Component {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  static get defaultProps() {
    return {
      useLazyload: true,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.imageRef = React.createRef();
    this.handleEvent = _.throttle(this.handleEvent.bind(this), 300, { leading: true, trailing: true });
    const browserInfo = context.getModelData('browserInfo');
    this.state = {
      show: !browserInfo.isIOS && !browserInfo.isAndroid,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      // @ts-ignore TS2339
      this.timer = window.requestAnimationFrame(this.handleEvent);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      // @ts-ignore TS2339
      if (this.timer) window.cancelAnimationFrame(this.timer);
      // @ts-ignore TS2339
      delete this.timer;
    }
  }

  handleEvent() {
    // @ts-ignore TS2339
    if (this.state.show) return;
    // @ts-ignore TS2339
    const el = this.imageRef.current;
    if (el) {
      const { width, height } = DOMUtils.getWindowRect();
      const { top, left } = DOMUtils.getRect(el);
      if (width + width / 2 > left && height + height / 2 > top) {
        // @ts-ignore TS2339
        if (this.timer) window.cancelAnimationFrame(this.timer);
        // @ts-ignore TS2339
        delete this.timer;
        this.setState({ show: true });
      }
    }
    // @ts-ignore TS2339
    this.timer = window.requestAnimationFrame(this.handleEvent);
  }

  render() {
    const newProps = _.omit(this.props, 'children', 'url', 'useLazyload');

    // @ts-ignore TS2339
    if (this.props.url && this.state.show) {
      let query = '';
      // @ts-ignore TS2339
      const urlParse = url.parse(this.props.url);
      const browserInfo = this.context.getModelData('browserInfo');
      if (browserInfo.isChrome && !browserInfo.isIOS) {
        urlParse.search = (!!urlParse.search ? urlParse.search + '&' : '?') + 'p=t';
      }
      // @ts-ignore TS2339
      if (this.props.useLazyload) {
        newProps['data-bg'] = url.format(urlParse);
        // @ts-ignore TS2339
        newProps.className += ' lazyload';
      } else {
        // @ts-ignore TS2339
        newProps.style = { backgroundImage: `url(${url.format(urlParse)})` };
      }
    }

    return (
      // @ts-ignore TS2339
      <div ref={this.imageRef} {...newProps}>
        {this.props.children}
      </div>
    );
  }
}

export default BackgroundImage;
