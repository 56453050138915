import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

import HowToPlayDescription from './HowToPlay/HowToPlayDescription';
import HowToPlayCourses from './HowToPlay/HowToPlayCourses';
import HowToPlayProducts from './HowToPlay/HowToPlayProducts';

const HowToPlay = props => {
  return (
    <div key="how-to-play">
      <div className="how-to-play-content">
        <HowToPlayDescription {...props} />
        <HowToPlayCourses {...props} />
        <HowToPlayProducts {...props} />
      </div>
    </div>
  );
};

export default HowToPlay;
