import ReactDOM from 'react-dom';
import _ from 'src/libs/util';
import url from 'url';

import safeTimeout from '../../common/safeTimeout';
import tokenDataStore from '../../utils/tokenDataStore';
import datetime from 'src/libs/datetime';

class ReactClientRenderer {
  private isMounted: boolean;
  constructor(mountNode, context, options = {}) {
    const logger = context.getLogger();
    // @ts-ignore TS2339
    this.mountNode = mountNode;
    // @ts-ignore TS2339
    this.appContext = context;
    // @ts-ignore TS2339
    this.options = options;
    // @ts-ignore TS2339
    this.cl = logger;
    // @ts-ignore TS2339
    this.isPerfAPIAvailable = typeof window !== 'undefined' && window.performance && window.performance.mark;
    this.isMounted = false;
    // @ts-ignore TS2339
    this.retrieveScrollPosition = false;

    this.handleHistoryChange = this.handleHistoryChange.bind(this);
    // @ts-ignore TS2339
    this.isListeningToHistory = false;

    // Switch off the native scroll restoration behavior and handle it manually
    // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
    // @ts-ignore TS2339
    this.scrollPositionsHistory = {};
    if (window.history && 'scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    // @ts-ignore TS2339
    this.currentLocation = context.getHistory().location;

    // inappの場合は定期実行をしない
    // @ts-ignore TS2339
    const inapp = this.appContext.getModelData('inapp', 'inapp');
    if (!inapp) {
      // @ts-ignore TS2339
      this.tokenConfig = this.appContext.getModelData('tokenConfig');
    }

    this.handleTokenCheck = this.handleTokenCheck.bind(this);
    this.handleTokenRefresh = this.handleTokenRefresh.bind(this);

    // @ts-ignore TS2339
    this.isTokenCheckInterval = false;
    // @ts-ignore TS2339
    this.isTokenRefreshInterval = false;
    // @ts-ignore TS2339
    this.isFirstTokenRefreshTime = false;

    this.handleTokenCheckStart = this.handleTokenCheckStart.bind(this);
    this.handleTokenRefreshStart = this.handleTokenRefreshStart.bind(this);

    // @ts-ignore TS2339
    this.isOnbeforeunload = false;
    window.addEventListener('beforeunload', e => {
      // @ts-ignore TS2339
      this.isOnbeforeunload = true;
    });
  }

  handleHistoryChange(location, action) {
    // @ts-ignore TS2339
    if (this.scrollTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.scrollTimeoutId);
      // @ts-ignore TS2339
      delete this.scrollTimeoutId;
    }

    // @ts-ignore TS2339
    if (this.options.handleHistoryChange && typeof this.options.handleHistoryChange === 'function') {
      // @ts-ignore TS2339
      this.options.handleHistoryChange(location, action);
    }

    // Remember the latest scroll position for the previous location
    // @ts-ignore TS2339
    this.scrollPositionsHistory[this.currentLocation.key] = {
      scrollX: window.pageXOffset,
      scrollY: window.pageYOffset,
    };

    // Delete stored scroll position for next page if any
    if (action === 'PUSH') {
      // @ts-ignore TS2339
      delete this.scrollPositionsHistory[location.key];
    }

    // @ts-ignore TS2339
    this.currentLocation = location;

    if (action !== 'POP' && _.get(location, 'state.norender')) return;

    this.render(function(err, html) {
      if (err) {
        console.error(err.stack);
      }
    });
  }

  handleTokenCheckStart() {
    // @ts-ignore TS2339
    if (this.tokenConfig && this.tokenConfig.tokenCheckInterval) {
      safeTimeout(() => {
        this.handleTokenCheck(true);
        // @ts-ignore TS2339
      }, this.tokenConfig.tokenCheckInterval);
    }
  }

  handleTokenRefreshStart() {
    // @ts-ignore TS2339
    if (this.tokenConfig && this.tokenConfig.tokenRefreshInterval) {
      safeTimeout(() => {
        this.handleTokenRefresh(true);
        // @ts-ignore TS2339
      }, this.tokenConfig.tokenRefreshInterval);
    }
  }

  handleTokenCheck(interval = false) {
    // @ts-ignore TS2339
    if (this.isOnbeforeunload) return;
    try {
      // @ts-ignore TS2339
      let tokenData = this.appContext.getTokenData();
      if (tokenData && tokenData.token) {
        // @ts-ignore TS2339
        let service = Object.assign({}, this.appContext.getModelData('services', 'tokenManager'));
        if (!service) return;

        // @ts-ignore TS2339
        if (tokenData.uuid != this.appContext.uuid || tokenData.isRefresh) {
          if (interval) this.handleTokenCheckStart();
          return;
        }

        service.pathname = _.join(_.concat(service.path, 'token/check'), '/');

        const xhr = new XMLHttpRequest();
        // @ts-ignore TS2339
        xhr.open('POST', url.format(service), !this.__unloading);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.setRequestHeader('Authorization', `Bearer ${tokenData.token}`);
        xhr.setRequestHeader('X-Token-Id', tokenData.id);
        const params = { wip_access_token: tokenData.idpToken };
        const query = Object.keys(params)
          .map(key => key + '=' + params[key])
          .join('&');
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 0) {
              if (interval) this.handleTokenCheckStart();
            }
            if (xhr.status === 200) {
              if (interval) this.handleTokenCheckStart();
            }
            if (xhr.status === 401) {
              if (interval) this.handleTokenCheckStart();
              this.handleTokenRefresh();
            }
          }
        };
        xhr.send(query);
      }
    } catch (e) {
      console.error('ReactClientRenderer::handleTokenCheckError', e);
    }
  }

  handleTokenRefresh(interval = false) {
    // @ts-ignore TS2339
    if (this.isOnbeforeunload) return;
    try {
      // @ts-ignore TS2339
      let tokenData = this.appContext.getTokenData();
      if (tokenData && tokenData.token) {
        let timecheck = false;

        if (tokenData.time) {
          timecheck = datetime().diff(datetime(tokenData.time, 'x'), 'second') < 60;
        }
        // @ts-ignore TS2339
        if (tokenData.uuid != this.appContext.uuid || tokenData.isRefresh || timecheck) {
          if (interval) this.handleTokenRefreshStart();
          return;
        }

        tokenData.isRefresh = true;
        // @ts-ignore TS2339
        this.appContext.setTokenData(tokenData);

        const xhr = new XMLHttpRequest();
        // @ts-ignore TS2339
        xhr.open('POST', '/api/user/token/refresh', !this.__unloading);
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 0) {
              tokenData.isRefresh = false;
              // @ts-ignore TS2339
              this.appContext.setTokenData(tokenData);
              if (interval) this.handleTokenRefreshStart();
            }
            if (xhr.status === 200) {
              let response = JSON.parse(xhr.responseText);
              if (response.result) {
                tokenData.isRefresh = false;
                // @ts-ignore TS2339
                this.appContext.setTokenData(tokenData);
                tokenDataStore.setAuthContextData(response.authContext);

                // @ts-ignore TS2339
                this.accessToken = response.authContext.token;
                // @ts-ignore TS2339
                this.retrieveScrollPosition = false;
                this.render(function(err, html) {
                  if (err) {
                    console.error(err.stack);
                  }
                });

                if (interval) this.handleTokenRefreshStart();
              } else if (response.result === false) {
                window.location.reload();
              }
            }
          }
        };
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.send();
      }
    } catch (e) {
      console.error('ReactClientRenderer::handleTokenRefreshError', e);
    }
  }

  async render(cb) {
    // @ts-ignore TS2339
    this.currentUrl = window.location.pathname + window.location.search + window.location.hash;
    // @ts-ignore TS2339
    const currentUrl = this.currentUrl;
    // @ts-ignore TS2339
    await this.appContext.resolveState(this.currentUrl, (state, routeHandler) => {
      // @ts-ignore TS2339
      if (this.accessToken) {
        // @ts-ignore TS2339
        _.set(state, 'model.models.authContext.data.token', this.accessToken);
        // @ts-ignore TS2339
        delete this.accessToken;
      }
      return state;
    });

    // @ts-ignore TS2339
    if (currentUrl !== this.currentUrl) return;

    // @ts-ignore TS2339
    if (!this.isListeningToHistory) {
      // @ts-ignore TS2339
      this.appContext.getHistory().listen(this.handleHistoryChange);
      // @ts-ignore TS2339
      this.isListeningToHistory = true;
    }

    // @ts-ignore TS2339
    if (!this.isTokenCheckInterval) {
      // @ts-ignore TS2339
      this.isTokenCheckInterval = true;
      this.handleTokenCheckStart();
    }

    // @ts-ignore TS2339
    if (!this.isTokenRefreshInterval) {
      // @ts-ignore TS2339
      this.isTokenRefreshInterval = true;
      this.handleTokenRefreshStart();
    }

    // @ts-ignore TS2339
    if (!this.isFirstTokenRefreshTime) {
      // @ts-ignore TS2339
      this.isFirstTokenRefreshTime = true;
      // @ts-ignore TS2339
      if (this.tokenConfig && this.tokenConfig.firstTokenRefreshTime) {
        safeTimeout(() => {
          this.handleTokenRefresh();
          // @ts-ignore TS2339
        }, this.tokenConfig.firstTokenRefreshTime);
      }
    }

    // @ts-ignore TS2339
    await this.appContext.resolveElement(async (err, elements) => {
      // @ts-ignore TS2339
      if (currentUrl !== this.currentUrl) return;
      if (err) {
        return cb(err);
      }

      let paths = [];
      let secondPaths = [];
      const getPrefetchedPaths = [];
      // @ts-ignore TS2339
      const routeHandler = this.appContext.getState().routeHandler;
      // @ts-ignore TS2339
      const models = this.appContext.getModels();
      routeHandler.components.forEach((component, i) => {
        const props = Object.assign({}, routeHandler.params, elements.props);
        if (typeof component.getPrefetchPaths === 'function') {
          paths = paths.concat(component.getPrefetchPaths(models, {}, props));
        }
        if (typeof component.getPrefetchedPaths === 'function') {
          getPrefetchedPaths.push(component.getPrefetchedPaths(models, { prefetcheSource: 'client' }, props));
        }
      });
      paths = _.compact(paths);

      if (paths.length > 0) {
        // @ts-ignore TS2339
        const pathEvaluator = this.appContext.getFalcorModel();
        try {
          let prefetchResult = await pathEvaluator.get.apply(pathEvaluator, paths);
          // 1回目のfetchデータを利用して2回目のfetch
          _.forEach(getPrefetchedPaths, func => {
            secondPaths = secondPaths.concat(func(prefetchResult));
          });
          if (secondPaths.length > 0) {
            await pathEvaluator.get.apply(pathEvaluator, secondPaths);
          }
        } catch (err) {
          console.error(err);
        }
      }

      // @ts-ignore TS2339
      if (currentUrl !== this.currentUrl) return;
      const self = this;

      const renderReactApp = !this.isMounted ? ReactDOM.hydrate : ReactDOM.render;
      // @ts-ignore TS2339
      renderReactApp(this.appContext.provideAppContextToElement(elements), this.mountNode, function() {
        // @ts-ignore TS2339
        self.isMounted = true;

        let scrollX = 0;
        let scrollY = 0;
        // @ts-ignore TS2339
        const pos = self.scrollPositionsHistory[self.currentLocation.key];
        if (pos) {
          scrollX = pos.scrollX;
          scrollY = pos.scrollY;
        } else {
          // @ts-ignore TS2339
          const targetHash = self.currentLocation.hash.substr(1);
          if (targetHash) {
            const target = document.getElementById(targetHash);
            if (target) {
              scrollY = window.pageYOffset + target.getBoundingClientRect().top;
            }
          }
        }

        // 初回SSRで描画される時は処理をさせない
        // @ts-ignore TS2339
        if (self.retrieveScrollPosition) {
          // Restore the scroll position if it was saved into the state
          // or scroll to the given #hash anchor
          // or scroll to top of the page
          if (document.documentElement.scrollHeight > scrollY) {
            if (window.scrollTo) {
              this.scrollTimeoutId = setTimeout(function() {
                delete this.scrollTimeoutId;
                window.scrollTo(scrollX, scrollY);
              }, 0);
            }
          } else {
            // スクロールをすでにしている場合はこの処理は実行しないほうがいい
            this.scrollTimeoutId = setTimeout(function() {
              delete this.scrollTimeoutId;
              if (window.scrollTo) {
                window.scrollTo(scrollX, scrollY);
              }
            }, 0);
          }
        }
        // @ts-ignore TS2339
        self.retrieveScrollPosition = true;

        try {
          // @ts-ignore TS2339
          if (window.FB && window.FB.XFBML && typeof window.FB.XFBML.parse === 'function') {
            // @ts-ignore TS2339
            window.FB.XFBML.parse();
          }
        } catch (e) {
          try {
            if (!document.getElementById('fb-root')) {
              const fbRoot = document.createElement('div');
              fbRoot.id = 'fb-root';
              document.body.appendChild(fbRoot);
              // @ts-ignore TS2339
              if (window.FB && window.FB.XFBML && typeof window.FB.XFBML.parse === 'function') {
                // @ts-ignore TS2339
                window.FB.XFBML.parse();
              }
            }
          } catch (e) {}
        }
        try {
          // @ts-ignore TS2339
          if (window.twttr && window.twttr.widgets && typeof window.twttr.widgets.load === 'function') {
            // 正常にURLが設定されないので明示的に現在のURLを指定する
            const el = document.getElementById('twitter-share');
            if (el) el.setAttribute('data-url', window.location.href);
            // @ts-ignore TS2339
            window.twttr.widgets.load();
          }
        } catch (e) {}
        cb(null, this);
      });
    });
  }

  unMount() {
    // @ts-ignore TS2339
    if (this.mountNode) {
      // TODO history remove
      // @ts-ignore TS2339
      this.isListeningToHistory = false;
      this.isMounted = false;
      // @ts-ignore TS2339
      ReactDOM.unmountComponentAtNode(this.mountNode);
    }
  }
}

export default ReactClientRenderer;
