import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import { CONTENT_EVENTS } from '../../../../common/GtmApp';

class ScheduleButton extends Component {
  static get propTypes() {
    return {
      meta: PropTypes.object.isRequired,
    };
  }
  static get contextTypes() {
    return {
      models: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this._handleClick = this._handleClick.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);
  }
  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }
  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }
  _handleClick() {
    this.sendToGtm();
  }
  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    const item = _.get(this.props, 'meta.onair') || _.get(this.props, 'meta');
    const channel = _.get(item, 'linearChannelMeta');
    const content = {
      refId: _.get(item, 'uniqueId'),
      name: _.get(item, 'name'),
    };
    const attributes = _.get(item, 'attributes');
    const genres = _.get(item, 'genres');
    const middleGenres = _.get(item, 'middleGenres');
    const schemaId = _.get(item, 'schemaId');
    this.context.gtmApp.pushDataLayerOnContentPageClick(CONTENT_EVENTS.SCHEDULE, {
      content,
      channel,
      attributes,
      genres,
      middleGenres,
      schemaId,
    });
  }
  render() {
    return (
      <a href="/schedule" target="_blank" onClick={this._handleClick} className="action-btn schedule-btn">
        <i className="fa fa-schedule"></i>
        <span>番組表</span>
      </a>
    );
  }
}

export default ScheduleButton;
