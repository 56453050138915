import React, { useCallback, useMemo, useRef, useState } from 'react';
import _ from 'src/libs/util';
import PropTypes from 'prop-types';

import SidelistCard from './SidelistCard';
import useSidelistPalette from './hooks/useSidelistPalette';
import * as DOMUtils from '../../../../../sketch-platform/utils/DOMUtils';

const PER_PAGE = 12;
const Sidelist = (props, context) => {
  const scrollAreaRef = useRef(null);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(PER_PAGE - 1);
  const config = useMemo(() => context.getModelData('config'), [context]);
  const { palette, paletteObjectsLength, paletteObjects, loading } = useSidelistPalette({
    model: props.model,
    config,
    meta: props.meta,
    propKeys: ['id', 'title'],
    withObjects: true,
    withObjectsLength: true,
    from: from,
    to: to,
  });

  const onClickCloseButton = useCallback(() => {
    if (props.handlePlayerSideListModeChange) {
      props.handlePlayerSideListModeChange();
    }
  }, [props]);

  const paging = useCallback(() => {
    if (loading) return;
    const el = scrollAreaRef.current;
    if (el && DOMUtils.getDistanceToBottomOfElement(el) < 200) {
      if (paletteObjectsLength > to + 1) {
        const _to = Math.min(paletteObjectsLength - 1, to + PER_PAGE);
        setTo(_to);
      }
    }
  }, [loading, paletteObjectsLength, to]);

  const onScroll = useCallback(() => {
    paging();
  }, [paging]);

  return palette && paletteObjectsLength ? (
    <div className="player-side-list-width">
      <div className="player-side-list-width__header">
        <div className="player-side-list-width__header__title ellipsized">{palette.title}</div>
        <div className="player-side-list-width__header__close" onClick={onClickCloseButton}>
          <i className="fa-remove" />
        </div>
      </div>
      <div className="player-side-list-width__inner" onScroll={onScroll} ref={scrollAreaRef}>
        <div className="player-side-list-width__inner__scroll">
          {_.map(paletteObjects || [], item => {
            // @ts-ignore TS2322
            return <SidelistCard item={item} palette={palette} model={props.model} />;
          })}
          {loading ? (
            <div className="gallery-spin-loader">
              <div className="loader-cont"></div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
};

Sidelist.contextTypes = {
  getModelData: PropTypes.func,
};

export default React.memo(Sidelist);
