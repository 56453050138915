import _ from 'src/libs/util';
import activeProfile from '../utils/activeProfile';
import settingsDataStore from '../utils/settingsDataStore';
import { ValueOf } from 'src/types/util';

const EVENTS = {
  SPA_PAGE_VIEW: 'SPAPageView',
  CUSTOM_CLICK: 'custom_click',
  FOOTER_CLICK: 'footer_click',
  HEADER_MYPAGE_CLICK: 'header_mypage_click',
  CONTENT_PAGE_CLICK: 'content_page_click',
  PURCHASE: 'purchase',
};

export const CUSTOM_EVENTS = {
  LOGIN_CLICK: 'login_click',
  JOIN_CLICK: 'join_click',
  NOTIFICATION_DETAIL_CLICK: 'notification_detail_click',
  SUBSCRIPTION_CLICK: 'subscription_click',
  CANCEL_CLICK: 'cancel_click',
  UNDO_CANCEL_CLICK: 'undo_cancel_click',
  PAYMENT_CHANGE_CLICK: 'payment_change_click',
  CHARGING_CLICK: 'charging_click',
  APP_DOWNLOAD_CLICK: 'app_download_click',
  APP_LAUNCH_CLICK: 'app_launch_click',
  MENU_CLICK: 'menu_click',
  MENU_GENRE_CLICK: 'menu_genre_click',
  CONTENT_PROPERTY_CLICK: 'content_property_click',
  USE_COUPON_CLICK: 'use_coupon_click',
  GET_COUPON_OFFER_CLICK: 'get_coupon_offer_click',
  DENY_COUPON_OFFER_CLICK: 'deny_coupon_offer_click',
  PALETTE_IN_PLAYER_CLICK: 'palette_in_player_click',
  TVOD_TARGET_EPISODE_CLICK: 'tvod_target_episode_click',
  TVOD_BUY_CLICK: 'tvod_buy_click',
  TVOD_LOGIN_CLICK: 'tvod_login_click',
  PALETTE_IMPRESSION: 'palette_imp',
};

export const CONTENT_EVENTS = {
  ADD_TO_WISHLIST: 'add_to_wishlist',
  COMMENT: 'comment',
  CONTENT_CLICK: 'content_click',
  FAVORITE_DELETE: 'favorite_delete',
  HISTORY_DELETE: 'history_delete',
  LOGIN_REQUEST_CLICK: 'login_request_click',
  PLAY_CLICK: 'play_click',
  PLAY_START: 'play_start',
  PLAY_1MIN: 'play_1min',
  PLAY_50PERCENT: 'play_50percent',
  PLAY_90PERCENT: 'play_90percent',
  PURCHASE: 'purchase',
  SCHEDULE: 'schedule',
  SELECT_PROGRAM: 'select_program',
  SHARE: 'share',
};

export const CLICK_AREA = {
  CONTENT_CLICK: {
    MUSTHEAD_THUMBNAIL: 'マストヘッド「サムネ」',
    MUSTHEAD_DETAIL: 'マストヘッド「詳細ボタン」',
    MUSTHEAD_OTHERS: 'マストヘッド「その他領域」',
    IN_PLAYER: 'プレイヤー内導線カード',
  },
  PLAY_CLICK: {
    MUSTHEAD: 'マストヘッド「再生」',
    META_DETAIL: 'メタ詳細「再生」',
  },
  ADD_TO_WISHLIST: {
    MUSTHEAD: 'マストヘッド「お気に入り追加」',
    META_DETAIL: 'メタ詳細「お気に入り追加」',
    POP_CARD: 'ポップカード「お気に入り追加」',
    IN_PLAYER: 'プレイヤー内導線「お気に入り追加」',
  },
  FAVORITE_DELETE: {
    MUSTHEAD: 'マストヘッド「お気に入り削除」',
    META_DETAIL: 'メタ詳細「お気に入り削除」',
    POP_CARD: 'ポップカード「お気に入り削除」',
    MY_LIST: 'マイリスト「お気に入り削除」',
    IN_PLAYER: 'プレイヤー内導線「お気に入り削除」',
  },
  BILLBOARD: '看板',
  SHELF: '棚',
  MATCH_SHELF: '対戦カード棚',
  LIST: '一覧',
  THUMBNAIL: 'メインサムネ',
  LIVESTREAMING: 'ライブ配信',
  SCHEDULE: '番組表',
  SIGNUP: {
    HEADER: 'ページ上部加入ボタン',
    FOTTER: 'フッター追従加入バナー',
    EPISODE: 'エピソード画面S導線',
  },
  APP_DOWNLOAD: {
    THUMBNAIL: 'サムネイル内表示',
    FOTTER: 'フッター追従加入表示',
  },
  APP_LAUNCH: {
    THUMBNAIL: 'サムネイル内表示',
    FOTTER: 'フッター追従加入表示',
  },
  MENU_GENRE: {
    HEADER_MENU: 'ヘッダーメニュー',
    OVER_MUST_HEAD: 'マストヘッド上部',
  },
} as const;

export const MENU_CATEGORY = {
  SEARCH: '検索',
  INFO: 'お知らせ',
  MYLIST: 'マイリスト',
  RANKING: 'ランキング',
  FEATURE: '特集',
  LIVE: 'ライブ配信',
  LINEAR: '放送同時配信',
};

const streamingType = (content, isLinearChannelMeta, schemaId, isAd) => {
  // refIdが存在しないケースがあるのでどちらも取得できない時だけ設定しない
  if (!_.get(content, 'refId') && !_.get(content, 'name')) return null;
  if (isAd) return null;

  if (isLinearChannelMeta) return '同時配信';
  switch (schemaId) {
    case 3:
      return 'アーカイブ';
    case 4:
      return 'Live';
    default:
      return null;
  }
};

const purchaseType = (content = {}) => {
  if (_.get(content, 'rental') && _.get(content, 'subscription')) {
    return '併用';
  } else if (_.get(content, 'rental')) {
    return 'TVOD';
  } else if (_.get(content, 'subscription')) {
    return 'SVOD';
  }
  return null;
};

class GtmApp {
  private options: any;
  private profile: any;
  private _routeHandler: any;
  private _initialized: boolean;

  constructor(options = {}) {
    this.options = options;
    this.profile = activeProfile(this.options.models);
    this._routeHandler = {};
    this._initialized = false;
  }

  get memberStatus() {
    switch (_.get(this.profile, 'userStatus')) {
      case 0:
      case 4:
        return '未加入';
      case 1:
        return '無料トライアル';
      case 2:
        if (this.profile.isBs) {
          return 'CASあり配信加入';
        } else {
          return '有料加入';
        }
      case 3:
        return '放送加入';
      default:
        return '未ログイン';
    }
  }

  get playbackRate() {
    const settingsData = settingsDataStore.get() || {};
    let playbackRate = settingsData.playbackRate || 1;
    if (playbackRate % 1 === 0) {
      playbackRate = playbackRate.toFixed(1);
    }
    return `${playbackRate}倍速`;
  }

  get initialized() {
    return this._initialized;
  }

  /**
   * タグマネージャーに送信
   * @param {object} params
   */
  _pushLayer(params, opt = {}) {
    if (this.options.browserInfo && this.options.browserInfo.isCarMonitor) return;
    if (typeof window !== 'undefined') {
      // @ts-ignore TS2339
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore TS2339
      const _dataLayer = window.dataLayer;
      const _gtm = _.get(this.options, 'config.analytics.gtm.id');
      if (_gtm) {
        // @ts-ignore TS2339
        if (opt.reset && _.get(window, `google_tag_manager[${_gtm}].dataLayer`)) {
          // @ts-ignore TS2339
          window.google_tag_manager[_gtm].dataLayer.reset();
        }
        _dataLayer.push(Object.assign({}, params));
      }
    }
  }

  pageView(pageTitle = null, params = {}) {
    if (this._initialized) {
      this._csrPageView(pageTitle, params);
    } else {
      this._init(params);
    }
  }

  _init(params = {}) {
    if (!_.get(this.options, 'config.analytics.gtm.id') || this._initialized) return;
    if (this.options.browserInfo && this.options.browserInfo.isCarMonitor) return;

    this._initialized = true;
    // 0番目に出す
    this._pushLayer({
      member_id: _.get(this.profile, 'refId', null),
      member_status: this.memberStatus,
      relation_program_code: _.get(params, 'relationProgram.refId', null),
      relation_program_name: _.get(params, 'relationProgram.name', null),
      program_code: _.get(params, 'program.refId', null),
      program_name: _.get(params, 'program.name', null),
      content_id: _.get(params, 'content.refId', null),
      content_name: _.get(params, 'content.name', null),
      channel_id: _.get(params, 'channel.refId') ? _.replace(_.get(params, 'channel.refId'), 'wowow_', '') : null, // チャンネルID(191：プライム 192：ライブ 193：シネマ)
      channel_name: _.get(params, 'channel.name', null),
      genre_01: _.get(params, 'genres[0].name', null), // 一つだけでOK
      genre_02: _.get(params, 'middleGenres[0].name', null), // 一つだけでOK
      // @ts-ignore TS2339
      genre_detail: !_.isEmpty(params.attributes)
        ? _.reduce(
            // @ts-ignore TS2339
            _.map(params.attributes, attribute => attribute.name || attribute),
            (str, name) => str + ',' + name,
          )
        : null,
      // @ts-ignore TS2554
      streaming_type: streamingType(_.get(params, 'content'), !_.isEmpty(params.channel), _.get(params, 'schemaId')),
      shelf_id: _.get(params, 'shelf.id', null),
      shelf_name: _.get(params, 'shelf.name', null),
      purchase_type: purchaseType(_.get(params, 'content')),
    });
    // 1番目に出す
    this._pushLayer({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

    const firstScriptTag = document.getElementsByTagName('script')[0];
    const newTag = document.createElement('script');
    newTag.async = true;
    newTag.src = `https://www.googletagmanager.com/gtm.js?id=${this.options.config.analytics.gtm.id}`;
    firstScriptTag.parentNode.insertBefore(newTag, firstScriptTag);
  }

  _csrPageView(pageTitle = null, params = {}) {
    this._pushLayer(
      Object.assign(
        {},
        {
          event: EVENTS.SPA_PAGE_VIEW,
          member_id: _.get(this.profile, 'refId', null),
          member_status: this.memberStatus,
          relation_program_code: _.get(params, 'relationProgram.refId', null),
          relation_program_name: _.get(params, 'relationProgram.name', null),
          program_code: _.get(params, 'program.refId', null),
          program_name: _.get(params, 'program.name', null),
          content_id: _.get(params, 'content.refId', null),
          content_name: _.get(params, 'content.name', null),
          channel_id: _.get(params, 'channel.refId') ? _.replace(_.get(params, 'channel.refId'), 'wowow_', '') : null, // チャンネルID(191：プライム 192：ライブ 193：シネマ)
          channel_name: _.get(params, 'channel.name', null),
          genre_01: _.get(params, 'genres[0].name', null), // 一つだけでOK
          genre_02: _.get(params, 'middleGenres[0].name', null), // 一つだけでOK
          // @ts-ignore TS2339
          genre_detail: !_.isEmpty(params.attributes)
            ? _.reduce(
                // @ts-ignore TS2339
                _.map(params.attributes, attribute => attribute.name || attribute),
                (str, name) => str + ',' + name,
              )
            : null,
          // @ts-ignore TS2554
          streaming_type: streamingType(
            _.get(params, 'content'),
            // @ts-ignore TS2339
            !_.isEmpty(params.channel),
            _.get(params, 'schemaId'),
          ),
          shelf_id: _.get(params, 'shelf.id', null),
          shelf_name: _.get(params, 'shelf.name', null),
          virtualPageUrl: _.get(this._routeHandler, 'path', null),
          virtualPageTitle: pageTitle,
          purchase_type: purchaseType(_.get(params, 'content')),
        },
      ),
    );
  }

  /**
   * クリック計測
   * @param {string} eventName
   * @param {object} params
   */
  _pushLayerWithClickEvent(eventName, params) {
    this._pushLayer(
      Object.assign(
        {
          event: eventName,
        },
        params,
      ),
    );
  }

  pushDataLayerOnCustomClick(eventName = null, params = {}) {
    if (!_.includes(CUSTOM_EVENTS, eventName)) return;
    this._pushLayerWithClickEvent(EVENTS.CUSTOM_CLICK, {
      custom_event: eventName,
      ...params,
    });
  }

  pushDataLayerOnSubscriptionClick(clickArea: ValueOf<typeof CLICK_AREA.SIGNUP>) {
    this.pushDataLayerOnCustomClick(CUSTOM_EVENTS.SUBSCRIPTION_CLICK, { click_area: clickArea });
  }

  pushDataLayerOnAppDownloadClick(clickArea) {
    this.pushDataLayerOnCustomClick(CUSTOM_EVENTS.APP_DOWNLOAD_CLICK, { click_area: clickArea });
  }

  pushDataLayerOnAppLaunchClick(clickArea) {
    this.pushDataLayerOnCustomClick(CUSTOM_EVENTS.APP_LAUNCH_CLICK, { click_area: clickArea });
  }

  pushDataLayerOnMenuClick(menuCategory) {
    this.pushDataLayerOnCustomClick(CUSTOM_EVENTS.MENU_CLICK, { menu_category: menuCategory });
  }

  pushDataLayerOnMenuGenreClick(clickArea, menuGenre) {
    this.pushDataLayerOnCustomClick(CUSTOM_EVENTS.MENU_GENRE_CLICK, { click_area: clickArea, menu_genre: menuGenre });
  }

  pushDataLayerOnContentProperyClick(propertyType, propertyName) {
    this.pushDataLayerOnCustomClick(CUSTOM_EVENTS.CONTENT_PROPERTY_CLICK, {
      property_type: propertyType,
      property_name: propertyName,
    });
  }
  pushDataLayerOnUseCouponClick(voucherCode) {
    this.pushDataLayerOnCustomClick(CUSTOM_EVENTS.USE_COUPON_CLICK, {
      voucher_id: _.get(voucherCode, 'voucher.id') || null,
      voucher_code: _.get(voucherCode, 'code') || null,
      voucher_name: _.get(voucherCode, 'voucher.displayName') || null,
    });
  }
  pushDataLayerOnGetCouponOfferClick(voucherCode) {
    this.pushDataLayerOnCustomClick(CUSTOM_EVENTS.GET_COUPON_OFFER_CLICK, {
      voucher_id: _.get(voucherCode, 'voucher.id') || null,
      voucher_code: _.get(voucherCode, 'code') || null,
      voucher_name: _.get(voucherCode, 'voucher.displayName') || null,
    });
  }

  pushDataLayerOnDenyCouponOfferClick(voucherCode) {
    this.pushDataLayerOnCustomClick(CUSTOM_EVENTS.DENY_COUPON_OFFER_CLICK, {
      voucher_id: _.get(voucherCode, 'voucher.id') || null,
      voucher_code: _.get(voucherCode, 'code') || null,
      voucher_name: _.get(voucherCode, 'voucher.displayName') || null,
    });
  }

  pushDataLayerOnPaltteInPlayerClick(palette) {
    this.pushDataLayerOnCustomClick(CUSTOM_EVENTS.PALETTE_IN_PLAYER_CLICK, {
      palette_id: _.get(palette, 'id') || null,
      palette_name: _.get(palette, 'title') || null,
    });
  }

  pushDataLayerOnTvodTargetEpisodeClick(meta) {
    this.pushDataLayerOnCustomClick(CUSTOM_EVENTS.TVOD_TARGET_EPISODE_CLICK, {
      click_area: 'エピソード',
      genre_01: _.get(meta, 'genres[0].name', null),
      genre_02: _.get(meta, 'middleGenres[0].name', null),
    });
  }

  pushDataLayerOnTvodBuyClick(isProductPage: boolean, meta) {
    const clickArea = isProductPage ? '商品ページ_ログイン済み' : 'エピソード_ログイン済み';
    this.pushDataLayerOnCustomClick(CUSTOM_EVENTS.TVOD_BUY_CLICK, {
      click_area: clickArea,
      genre_01: _.get(meta, 'genres[0].name', null),
      genre_02: _.get(meta, 'middleGenres[0].name', null),
    });
  }

  pushDataLayerOnTvodLoginClick(isProductPage: boolean, meta) {
    const clickArea = isProductPage ? '商品ページ_未ログイン' : 'エピソード_未ログイン';
    this.pushDataLayerOnCustomClick(CUSTOM_EVENTS.TVOD_LOGIN_CLICK, {
      click_area: clickArea,
      genre_01: _.get(meta, 'genres[0].name', null),
      genre_02: _.get(meta, 'middleGenres[0].name', null),
    });
  }

  // Paletteのインプレッション数計測
  pushDataLayerOnPaletteImp(paletteId, paletteName, orderNum = null) {
    this.pushDataLayerOnCustomClick(CUSTOM_EVENTS.PALETTE_IMPRESSION, {
      shelf_id: paletteId,
      shelf_name: paletteName,
      shelf_order: orderNum,
    });
  }

  pushDataLayerOnFooterClick(categoryName = null) {
    if (!categoryName) return;
    this._pushLayerWithClickEvent(EVENTS.FOOTER_CLICK, {
      footer_category: categoryName,
    });
  }

  pushDataLayerOnHeaderMyPageClick(categoryName = null) {
    if (!categoryName) return;
    this._pushLayerWithClickEvent(EVENTS.HEADER_MYPAGE_CLICK, {
      mypage_category: categoryName,
    });
  }

  _pushDataLayerOnContentPageClick(eventName = null, params = {}, options = {}) {
    if (!_.includes(CONTENT_EVENTS, eventName)) return;
    const p = Object.assign(
      {},
      {
        click_area: _.get(options, 'clickArea', null),
        shelf_id: _.get(params, 'shelf.id', null),
        shelf_name: _.get(params, 'shelf.name', null),
        shelf_order: _.get(params, 'shelf.order', null),
        relation_program_code: _.get(params, 'relationProgram.refId', null),
        relation_program_name: _.get(params, 'relationProgram.name', null),
        program_code: _.get(params, 'program.refId', null),
        program_name: _.get(params, 'program.name', null),
        content_id: _.get(params, 'content.refId', null),
        content_name: _.get(params, 'content.name', null),
        channel_id: _.get(params, 'channel.refId') ? _.replace(_.get(params, 'channel.refId'), 'wowow_', '') : null, // チャンネルID(191：プライム 192：ライブ 193：シネマ)
        channel_name: _.get(params, 'channel.name', null),
        genre_01: _.get(params, 'genres[0].name', null), // 一つだけでOK
        genre_02: _.get(params, 'middleGenres[0].name', null), // 一つだけでOK
        // @ts-ignore TS2339
        genre_detail: !_.isEmpty(params.attributes)
          ? _.reduce(
              // @ts-ignore TS2339
              _.map(params.attributes, attribute => attribute.name || attribute),
              (str, name) => str + ',' + name,
            )
          : null,
        ad_id: _.get(params, 'ad.id', null),
        ad_name: _.get(params, 'ad.name', null),
        streaming_type: streamingType(
          _.get(params, 'content'),
          // @ts-ignore TS2339
          !_.isEmpty(params.channel),
          _.get(params, 'schemaId'),
          !!_.get(params, 'ad.id'),
        ),
        member_id: _.get(this.profile, 'refId', null),
        member_status: this.memberStatus,
        play_spped: this.playbackRate,
        purchase_type: purchaseType(_.get(params, 'content')),
        quantity: _.get(params, 'quantity', null),
      },
    );
    this._pushLayerWithClickEvent(
      EVENTS.CONTENT_PAGE_CLICK,
      Object.assign({}, p, {
        custom_event: eventName,
      }),
    );
  }
  pushDataLayerOnContentPageClick(eventName = null, params = {}, options = {}) {
    this._pushDataLayerOnContentPageClick(eventName, params, options);
  }

  // eコマース private
  _pushDataLayerOnEcommerce(params = {}, options = {}) {
    // 初期化する
    this._pushLayer({ ecommerce: null });
    const p = Object.assign({ event: CONTENT_EVENTS.PURCHASE }, params);
    this._pushLayer(p);
  }

  // tvod購入計測
  pushDataLayerOnPurchase(params = {}) {
    // @ts-ignore TS2339
    const productOrder = params.productOrder || null;
    const product = _.get(productOrder, 'product');
    const voucher = _.get(productOrder, 'userVouchers.0.voucher');
    const item = {
      // Logicaの商品ID
      item_id: _.get(product, 'id', null),
      // Logicaの商品名
      item_name: _.get(product, 'name', null),
      // 明細単位の割引額
      discount: _.get(voucher, 'discount', 0),
      // tvod固定
      affiliation: 'tvod',
      // 明細単位の割引の名称。キャンペーン適用時はバウチャー名称
      coupon: _.get(voucher, 'name', null),
      // 商品の単価
      price: _.get(product, 'activePricing.paymentAmount', null),
      // 通貨
      // @ts-ignore TS2339
      currency: params.currency || 'JPY',
      // 商品の数
      quantity: 1,
    };
    const p = {
      ecommerce: {
        // LogicaのオーダーID
        transaction_id: _.get(productOrder, 'id'),
        // tvod固定
        affiliation: 'tvod',
        // itemsの金額の合計
        value: _.get(product, 'activePricing.paymentAmount', null),
        //現地通貨
        // @ts-ignore TS2339
        currency: params.currency || 'JPY',
        // バウチャー名称
        coupon: _.get(voucher, 'name', null),
        items: [item],
      },
    };
    this._pushDataLayerOnEcommerce(p);
  }

  // svod契約計測
  pushDataLayerOnSubscribe(params = {}) {
    // @ts-ignore TS2339
    const subscription = params.subscription;
    // @ts-ignore TS2339
    const order = params.order;
    const course = _.get(subscription, 'course');
    const voucher = _.get(subscription, 'userVouchers.0.voucher');
    const item = {
      // LogicaのコースID※無料トライアルでもセットされる
      item_id: _.get(course, 'id', null),
      // Logicaのコース名※無料トライアルでもセットされる
      item_name: _.get(course, 'name', null),
      // 明細単位の割引額
      discount: _.get(voucher, 'discountPrice', 0),
      // svod固定
      affiliation: 'svod',
      // 明細単位の割引の名称。キャンペーン適用時はバウチャー名称
      coupon: _.get(voucher, 'name', null),
      //明細単位の割引後価格
      // 無料トライアル: 0、有料契約: 定価または割引後価格
      price: _.get(order, 'paymentAmount', 0),
      // 通貨
      // @ts-ignore TS2339
      currency: params.currency || 'JPY',
      // 商品の数
      quantity: 1,
    };
    const p = {
      ecommerce: {
        // subscriptionのid
        transaction_id: _.get(subscription, 'id', null),
        // svod固定
        affiliation: 'svod',
        // itemsの金額の合計
        value: _.get(order, 'paymentAmount', 0),
        //現地通貨
        // @ts-ignore TS2339
        currency: params.currency || 'JPY',
        // バウチャー名称
        coupon: _.get(voucher, 'name', null),
        items: [item],
      },
    };
    this._pushDataLayerOnEcommerce(p);
  }

  setRouteHandler(routeHandler) {
    this._routeHandler = routeHandler;
  }
}

export default GtmApp;
