import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';
import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';

import Slider from '@logiclogic/react-slider';
import PresTrackedContainer from '../../../common/components/PresTrackedContainer';
import SpSlider from './SpSlider';

const getWidth = function(elem) {
  if (!elem) {
    return 0;
  }
  return elem.getBoundingClientRect().width || elem.offsetWidth || 0;
};

const SliderItemRows = props => {
  return (
    <div className={`slider-item-rows size-${props.size}`} key={props.key}>
      {React.Children.map(props.children, (child, index) => {
        return React.cloneElement(child, {
          sliderItemId: props.sliderItemId,
          itemTabbable: props.itemTabbable,
        });
      })}
    </div>
  );
};

type RowState = {
  isPopOpen: boolean;
  lowestVisibleItemIndex: number;
  isPortrait: boolean;
  keyboardFocus?: boolean;
};

export default class Row extends Component<any, RowState> {
  static get propTypes() {
    return {
      containsDoubleWides: PropTypes.bool,
      enablePushOut: PropTypes.bool,
      enablePeek: PropTypes.bool,
      enablePaginationIndicator: PropTypes.bool,
      enableLooping: PropTypes.bool,
      initialSlide: PropTypes.number,
      handleSliderMove: PropTypes.func,
      isMyListRow: PropTypes.bool,
      orderedItemList: PropTypes.array,
      numOfItemRows: PropTypes.number.isRequired,
      rowNum: PropTypes.number,
      totalItems: PropTypes.number,
      loadingComponent: PropTypes.object,
      loading: PropTypes.bool,
    };
  }

  static get defaultProps() {
    return {
      enablePaginationIndicator: true,
      enableLooping: false,
      initialSlide: 0,
      containsDoubleWides: false,
      handleSliderMove: null,
      isMyListRow: false,
      numOfItemRows: 1,
      orderedItemList: null,
      rowNum: null,
      totalItems: null,
      loadingComponent: null,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      columnsInRow: PropTypes.number,
      history: PropTypes.object,
      isInitialRender: PropTypes.bool,
      isOverlayPage: PropTypes.bool,
      isTallRow: PropTypes.bool,
      listContext: PropTypes.string,
      listType: PropTypes.string,
      routeHandler: PropTypes.object,
      viewType: PropTypes.string,
      artType: PropTypes.string,
      cardSize: PropTypes.string,
      orientation: PropTypes.string,
      models: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  private rowRef: React.RefObject<HTMLDivElement>;
  private sliderRef: React.RefObject<Slider>;
  private _isMounted: boolean;
  private popAnimationId: NodeJS.Timeout;
  private isMouse: boolean;
  private activeCard: any;

  constructor(props, context) {
    super(props, context);
    this.handleSliderMove = this.handleSliderMove.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleRowFocus = this.handleRowFocus.bind(this);
    this.onPopOpen = this.onPopOpen.bind(this);
    this.onPopLeave = this.onPopLeave.bind(this);
    this.onPopClose = this.onPopClose.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.getRowHasPopOpen = this.getRowHasPopOpen.bind(this);
    this.checkOrientation = _.throttle(this.checkOrientation.bind(this), 300, { leading: true, trailing: true });
    this.handleWheel = this.handleWheel.bind(this);

    this.sliderRef = React.createRef();
    this.rowRef = React.createRef();

    const browserInfo = context.getModelData('browserInfo');
    this.state = {
      isPopOpen: false,
      lowestVisibleItemIndex: 0,
      isPortrait: browserInfo.isIOS || browserInfo.isAndroid || browserInfo.isRequestDesktopWebsite,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isTouchDevice || browserInfo.isRequestDesktopWebsite) {
      browserEvents.addEventListener('orientationchange', this.checkOrientation);
    } else {
      this.rowRef.current?.addEventListener('wheel', this.handleWheel);
    }
    this.checkOrientation();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.popAnimationId) {
      clearTimeout(this.popAnimationId);
      delete this.popAnimationId;
    }
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isTouchDevice || browserInfo.isRequestDesktopWebsite) {
      browserEvents.removeEventListener('orientationchange', this.checkOrientation);
    } else {
      this.rowRef.current?.removeEventListener('wheel', this.handleWheel);
    }
  }

  checkOrientation() {
    if (typeof window === 'undefined') return;

    const browserInfo = this.context.getModelData('browserInfo');
    if (!(browserInfo.isIOS || browserInfo.isAndroid || browserInfo.isRequestDesktopWebsite)) {
      this.setState({ isPortrait: true });
    } else {
      let angle = screen && screen.orientation && screen.orientation.angle;
      let isPortrait = false;

      if (angle === undefined) {
        angle = window.orientation;

        if (angle === undefined) {
          if (window.innerHeight > window.innerWidth) {
            angle = 0;
          } else {
            angle = 90;
          }
        }
      }
      isPortrait = angle === 0;

      if (this._isMounted) {
        this.setState({ isPortrait });
      } else {
        // @ts-ignore TS2540
        this.state.isPortrait = isPortrait;
      }
    }
  }

  handleMouseEnter(e) {
    this.isMouse = true;
  }

  handleMouseLeave(e) {
    this.isMouse = false;
  }

  handleMouseMove(e) {
    this.isMouse = true;
    this.setState({ keyboardFocus: false });
  }

  handleRowFocus(event) {
    if (this.isMouse) return;
    if (event.target.classList.contains('sliderRefocus')) {
      this.setState({ keyboardFocus: true });
      // 広告はDOM構造が異なる為
    } else if (event.target.classList.contains('title-card') || event.target.classList.contains('episode-card')) {
      if (event.target.querySelector('.sliderRefocus')) {
        this.setState({ keyboardFocus: true });
      }
    }
  }

  handleKeyDown(event) {
    // Tab
    if (event.which === 9 && !event.shiftKey) {
      if (event.target.classList.contains('handleNext')) {
        event.target.tabIndex = -1;
        this.setState({ keyboardFocus: false });
      } else {
        const links = event.currentTarget.querySelectorAll('div[tabindex="0"]');
        const index = _.findIndex(links, link => {
          // @ts-ignore TS2339
          return link.id === event.target.id;
        });
        if (links.length == index + 1) {
          const arrow = event.currentTarget.querySelector('.handleNext');
          if (arrow) {
            arrow.tabIndex = 0;
          } else {
            this.setState({ keyboardFocus: false });
          }
        }
      }

      // Tab with Shift
    } else if (event.which === 9 && event.shiftKey) {
      if (event.target.classList.contains('handlePrev')) {
        event.target.tabIndex = -1;
        this.setState({ keyboardFocus: false });
      } else {
        const links = event.currentTarget.querySelectorAll('div[tabindex="0"]');
        const index = _.findIndex(links, link => {
          // @ts-ignore TS2339
          return link.id === event.target.id;
        });
        if (index === 0) {
          const arrow = event.currentTarget.querySelector('.handlePrev');
          if (arrow) {
            arrow.tabIndex = 0;
          } else {
            this.setState({ keyboardFocus: false });
          }
        }
      }
    }
  }

  handleWheel(e: WheelEvent) {
    if (e.deltaX < -50 && this.sliderRef.current.hasPrev()) {
      e.preventDefault();
      this.sliderRef.current.advancePrev();
    } else if (e.deltaX > 50 && this.sliderRef.current.hasNext()) {
      e.preventDefault();
      this.sliderRef.current.advanceNext();
    }
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');

    const settings = {
      initialSlide: this.props.initialSlide,
      swipe: false,
      enableLooping: this.props.enableLooping,
      enablePeek: this.props.enablePeek,
      enablePaginationIndicator: this.props.enablePaginationIndicator,
      enableSwipe: browserInfo.isIOS || browserInfo.isAndroid,
      autoAdjustWidth: false,
    };

    // 1行以外はループさせない
    if (this.props.numOfItemRows !== 1) {
      settings.enableLooping = false;
    }

    const cardSize = this.context.cardSize || 'medium';
    let artType = this.context.artType;
    if (artType === 'key_visual_b' || artType === 'key_visual_c') {
      artType = 'default';
    }

    let itemsInRow = this.context.columnsInRow;
    let slideRatio = 1;
    if (artType === 'keyart') {
      //キーアート

      //リスト
      if (this.context.viewType === 'list') {
        itemsInRow = this.context.columnsInRow / 2;
        if (cardSize === 'large') {
          itemsInRow -= 0.5;
        } else if (cardSize === 'small') {
          itemsInRow += 0.5;
        }
        itemsInRow = _.max([1.5, itemsInRow]);
        slideRatio = ((100 / itemsInRow) * Math.floor(itemsInRow)) / 100;
      } else {
        if (cardSize === 'large') {
          itemsInRow = Math.floor(this.context.columnsInRow / 1.5);
        } else if (cardSize === 'medium') {
          itemsInRow = this.context.columnsInRow - 1;
        } else if (cardSize === 'small') {
        }
      }
    } else if (artType === 'poster') {
      //ポスター

      //リスト
      if (this.context.viewType === 'list') {
        if (cardSize === 'large') {
          itemsInRow = Math.floor(this.context.columnsInRow / 1.5);
        } else if (cardSize === 'medium') {
          itemsInRow = this.context.columnsInRow - 1;
        } else if (cardSize === 'small') {
        }
      } else if (
        this.context.viewType === 'grid' ||
        this.props.mbListLayout == 'grid' ||
        this.props.mbListLayout == 'default'
      ) {
        if (cardSize === 'large') {
          itemsInRow = this.context.columnsInRow;
        } else if (cardSize === 'medium') {
          itemsInRow = this.context.columnsInRow + 1;
        } else if (cardSize === 'small') {
          itemsInRow = this.context.columnsInRow + 2;
        }
        slideRatio = ((100 / itemsInRow) * Math.floor(itemsInRow)) / 100;
      } else {
        if (cardSize === 'large') {
          itemsInRow = this.context.columnsInRow + 0.5;
        } else if (cardSize === 'medium') {
          itemsInRow = this.context.columnsInRow + 1.6;
        } else if (cardSize === 'small') {
          itemsInRow = this.context.columnsInRow + 2.5;
        }
        slideRatio = ((100 / itemsInRow) * Math.floor(itemsInRow)) / 100;
      }
    } else if (artType === 'square') {
      //スクエア
      if (this.context.viewType === 'slider') {
        if (cardSize === 'large') {
          itemsInRow = this.context.columnsInRow - 0.5;
          slideRatio = ((100 / itemsInRow) * Math.floor(itemsInRow)) / 100;
        } else if (cardSize === 'medium') {
          itemsInRow = this.context.columnsInRow + 1;
        } else if (cardSize === 'small') {
          itemsInRow = this.context.columnsInRow + 3;
        }
      } else if (this.context.viewType === 'list') {
        if (this.context.cardSize === 'large') {
          itemsInRow = Math.floor(this.context.columnsInRow / 1.5);
        } else if (this.context.cardSize === 'medium') {
          itemsInRow = Math.floor(this.context.columnsInRow - 1);
        } else if (this.context.cardSize === 'small') {
        }
      } else {
        if (cardSize === 'large') {
          itemsInRow = this.context.columnsInRow;
        } else if (cardSize === 'medium') {
          itemsInRow = this.context.columnsInRow + 1;
        } else if (cardSize === 'small') {
          itemsInRow = this.context.columnsInRow + 3;
        }
        if (this.context.columnsInRow == 2) {
          itemsInRow = this.context.columnsInRow + 1;
        }
      }
    } else if (artType === 'tennisCard') {
      itemsInRow = Math.max(this.context.columnsInRow - 1, 1);
    } else if (artType === 'soccerCard') {
      if (this.context.columnsInRow > 3) {
        itemsInRow = Math.max(this.context.columnsInRow - 1.5, 1);
      } else {
        itemsInRow = Math.max(this.context.columnsInRow - 1, 1);
      }
    } else {
      //デフォルト or サムネイル

      //リスト
      if (this.context.viewType === 'list') {
        if (this.context.cardSize === 'large') {
          itemsInRow = Math.floor(this.context.columnsInRow / 1.5);
        } else if (this.context.cardSize === 'medium') {
          itemsInRow = Math.floor(this.context.columnsInRow - 1);
        }
      } else {
        if (cardSize === 'large') {
          itemsInRow = this.context.columnsInRow - 1;
        } else if (cardSize === 'small') {
          itemsInRow = this.context.columnsInRow + 1;
        }
      }
    }

    if (this.context.columnsInRow == 2) {
      let itemWidth = 100;
      if (artType === 'keyart') {
        if (cardSize === 'large') {
          itemWidth = 85.714;

          if (!this.state.isPortrait) {
            itemWidth = 65.714;
          }
        } else if (cardSize === 'medium') {
          itemWidth = 57.147;

          if (!this.state.isPortrait) {
            itemWidth = 37.147;
          }
        } else if (cardSize === 'small') {
          itemWidth = 42.859;

          if (!this.state.isPortrait) {
            itemWidth = 22.859;
          }
        }

        if (this.context.viewType === 'list') {
          if (cardSize === 'large') {
            itemWidth = 90.9090909091;

            if (!this.state.isPortrait) {
              itemWidth = 70.9090909091;
            }
          } else if (cardSize === 'medium') {
            itemWidth = 86.9565217391;

            if (!this.state.isPortrait) {
              itemWidth = 66.9565217391;
            }
          } else if (cardSize === 'small') {
            itemWidth = 90.9090909091;

            if (!this.state.isPortrait) {
              itemWidth = 70.9090909091;
            }
          }
        }
        if (this.context.viewType === 'grid') {
          if (cardSize === 'large') {
            itemWidth = 100;
            if (!this.state.isPortrait) {
              itemWidth = 50;
            }
          } else if (cardSize === 'medium') {
            itemWidth = 100;
            if (!this.state.isPortrait) {
              itemWidth = 50;
            }
          } else if (cardSize === 'small') {
            itemWidth = 50;
            if (!this.state.isPortrait) {
              itemWidth = 33.3333333333;
            }
          }
        }
      } else if (artType === 'poster') {
        if (cardSize === 'large') {
          itemWidth = 45;

          if (!this.state.isPortrait) {
            itemWidth = 30.303030303;
          }
        } else if (cardSize === 'medium') {
          itemWidth = 33.3333333333;

          if (!this.state.isPortrait) {
            itemWidth = 25;
          }
        } else if (cardSize === 'small') {
          itemWidth = 26;

          if (!this.state.isPortrait) {
            itemWidth = 20.8333333333;
          }
        }
        if (this.context.viewType === 'list') {
          if (cardSize === 'large') {
            itemWidth = 90.9090909091;

            if (!this.state.isPortrait) {
              itemWidth = 70.9090909091;
            }
          } else if (cardSize === 'medium') {
            itemWidth = 80;

            if (!this.state.isPortrait) {
              itemWidth = 60;
            }
          } else if (cardSize === 'small') {
            itemWidth = 80;

            if (!this.state.isPortrait) {
              itemWidth = 60;
            }
          }
        } else if (
          this.context.viewType === 'grid' ||
          this.props.mbListLayout == 'grid' ||
          this.props.mbListLayout == 'default'
        ) {
          if (cardSize === 'large') {
            itemWidth = 50;

            if (!this.state.isPortrait) {
              itemWidth = 33.3333333333;
            }
          } else if (cardSize === 'small') {
            itemWidth = 33.3333333333;

            if (!this.state.isPortrait) {
              itemWidth = 25;
            }
          }
        }
      } else if (artType === 'square') {
        if (cardSize === 'large') {
          itemWidth = 71.4285714286;

          if (!this.state.isPortrait) {
            itemWidth = 41.4285714286;
          }
        } else if (cardSize === 'medium') {
          itemWidth = 33.3333333333;

          if (!this.state.isPortrait) {
            itemWidth = 25;
          }
        } else if (cardSize === 'small') {
          itemWidth = 26.6666666667; //3.75

          if (!this.state.isPortrait) {
            itemWidth = 21.0526315789;
          }
        }

        if (this.context.viewType === 'list') {
          if (cardSize === 'large') {
            itemWidth = 90.9090909091;

            if (!this.state.isPortrait) {
              itemWidth = 70.9090909091;
            }
          } else if (cardSize === 'medium') {
            itemWidth = 86.9565217391;

            if (!this.state.isPortrait) {
              itemWidth = 66.9565217391;
            }
          } else if (cardSize === 'small') {
            itemWidth = 90.9090909091;

            if (!this.state.isPortrait) {
              itemWidth = 70.9090909091;
            }
          }
        } else if (this.context.viewType === 'grid') {
          if (cardSize === 'large') {
            itemWidth = 100;

            if (!this.state.isPortrait) {
              itemWidth = 50;
            }
          } else if (cardSize === 'medium') {
            itemWidth = 50;

            if (!this.state.isPortrait) {
              itemWidth = 33.3333333333;
            }
          } else if (cardSize === 'small') {
            itemWidth = 33.3333333333;

            if (!this.state.isPortrait) {
              itemWidth = 25;
            }
          }
          if (!this.context.cardSize) {
            itemWidth = 33.3333333333;

            if (!this.state.isPortrait) {
              itemWidth = 25;
            }
          }
        }
      } else if (artType === 'tennisCard') {
        itemWidth = 80;
      } else if (artType === 'soccerCard') {
        itemWidth = 95;

        if (!this.state.isPortrait) {
          itemWidth = 50;
        }
      } else {
        if (cardSize === 'large') {
          itemWidth = 71.4285714286;

          if (!this.state.isPortrait) {
            itemWidth = 41.4285714286;
          }
        } else if (cardSize === 'medium') {
          itemWidth = 47.619047619;

          if (!this.state.isPortrait) {
            itemWidth = 33.3333333333;
          }
        } else if (cardSize === 'small') {
          itemWidth = 40;

          if (!this.state.isPortrait) {
            itemWidth = 30;
          }
        }

        if (this.context.viewType === 'list') {
          if (cardSize === 'large') {
            itemWidth = 90.9090909091;

            if (!this.state.isPortrait) {
              itemWidth = 70.9090909091;
            }
          } else if (cardSize === 'medium') {
            itemWidth = 86.9565217391;

            if (!this.state.isPortrait) {
              itemWidth = 66.9565217391;
            }
          } else if (cardSize === 'small') {
            itemWidth = 90.9090909091;

            if (!this.state.isPortrait) {
              itemWidth = 70.9090909091;
            }
          }
        } else if (this.context.viewType === 'grid') {
          if (cardSize === 'large') {
            itemWidth = 100;
          } else if (cardSize == 'medium') {
            itemWidth = 50;
          } else if (cardSize == 'small') {
            itemWidth = 50;
          }
        }
      }
      if (!!this.props.episodeCardSize) itemWidth = 50;
      if (this.props.mbListLayout == 'table' || this.props.mbListLayout == 'box') {
        itemWidth = 100;
      }
      itemsInRow = Math.floor(100 / itemWidth);
      slideRatio = (itemWidth * itemsInRow) / 100;
    }

    if (this.props.isOnPlayer) {
      itemsInRow = this.props.columnsInRow;
    }

    if (this.props.liveSchedule && (browserInfo.isIOS || browserInfo.isAndroid)) {
      itemsInRow = 1;
    }
    const sliderItemsInRow = Math.floor(itemsInRow);
    const sliderTotalItems = Math.ceil(this.props.totalItems / this.props.numOfItemRows);

    // スライドできる画面サイズ(columnsInRow < totalItems)でスライドしてから
    // スライドできない大きさ(columnsInRow >= totalItems)までリサイズする。
    // 上記の場合、Sliderのoffsetが更新されないままレンダリングされスライダーがずれるので0に戻す
    if (
      this.sliderRef.current &&
      this.sliderRef.current.currentSliderContentOffset != 0 &&
      sliderItemsInRow >= sliderTotalItems
    ) {
      this.sliderRef.current.currentSliderContentOffset = 0;
    }
    return (
      <div
        className="row"
        data-is-angle={this.state.isPortrait ? 'portrait' : 'landscape'}
        data-column-items={itemsInRow}
        data-total-count={this.props.totalItems <= this.props.numOfItemRows ? true : false}
        data-card-size={this.context.cardSize}
        data-art-type={artType}
        data-view-type={this.context.viewType}
        data-mobile-landscape-mode={this.context.orientation === 'horizontal'}
        data-is-onair={itemsInRow == 1 && this.props.isOnAir}
        data-mb-list-layout={this.props.mbListLayout}
        ref={this.rowRef}
      >
        <PresTrackedContainer>
          {browserInfo.isTouchDevice ? (
            // @ts-ignore TS2322
            <SpSlider
              onNextClick={this.handleSliderMove}
              loading={this.props.loading}
              totalItems={Math.ceil(this.props.totalItems / this.props.numOfItemRows)}
            >
              {this.renderChildren()}
            </SpSlider>
          ) : this.context.columnsInRow !== 1 ? (
            <div
              className={classnames('row-content', {
                'slider-hover-trigger-layer': this.context.columnsInRow !== 1,
                'keyboard-focus': this.state.keyboardFocus,
              })}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onMouseMove={this.handleMouseMove}
              onFocus={this.handleRowFocus}
              onKeyDown={this.handleKeyDown}
            >
              <Slider
                ref={this.sliderRef}
                itemsInRow={sliderItemsInRow}
                slideRatio={slideRatio}
                totalItems={sliderTotalItems}
                onSliderMove={this.handleSliderMove}
                loadingComponent={this.props.loadingComponent}
                arrowTabbable={false}
                {...settings}
              >
                {this.renderChildren()}
              </Slider>
            </div>
          ) : (
            this.renderChildren()
          )}
        </PresTrackedContainer>
      </div>
    );
  }
  getRowHasPopOpen() {
    return this.state.isPopOpen;
  }

  renderChildren() {
    const children = React.Children.map(this.props.children, (child, index) => {
      // @ts-ignore TS2339
      const rankNum = this.context.listContext === 'ranking' ? child.props.rankNum || index : index;
      // @ts-ignore TS2769
      return React.cloneElement(child, {
        getRowHasPopOpen: this.getRowHasPopOpen,
        isTallPanel: this.context.isTallRow === true,
        onPopClose: this.onPopClose,
        onPopLeave: this.onPopLeave,
        onPopOpen: this.onPopOpen,
        onCardClick: this.onCardClick,
        rankNum: rankNum,
      });
    });
    if (this.props.numOfItemRows === 1) {
      return children;
    }

    const browserInfo = this.context.getModelData('browserInfo');

    return _.map(_.chunk(children, this.props.numOfItemRows), (chunkedChildren, index) => {
      return (
        <SliderItemRows key={`slider_item_row_${this.props.rowNum}-${index}`} size={`1x${this.props.numOfItemRows}`}>
          {chunkedChildren}
        </SliderItemRows>
      );
    });
  }

  onPopOpen(targetCard) {
    if (this.popAnimationId && this.activeCard === targetCard) {
      clearTimeout(this.popAnimationId);
      delete this.popAnimationId;
    }

    this.activeCard = targetCard;

    this.setState({ isPopOpen: true });

    if (!this.sliderRef.current) {
      return;
    }

    const targetSliderItem = this.sliderRef.current.itemRefs[targetCard.props.sliderItemId];
    const targetViewportPosition = targetSliderItem.props.viewportPosition;

    const zoomSize = targetCard.getZoomScale();
    const width = Math.ceil((getWidth(targetCard.titleCardRef.current) * (zoomSize - 1.0)) / 2.0);
    const slide = ReactDOM.findDOMNode(targetSliderItem);
    if (!slide) return;

    const defaultStyle =
      'transition-duration: 400ms; transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1); transition-delay: 0ms;';

    let offset = 0;
    if (targetViewportPosition === 'leftEdge') {
      offset = width;
    } else if (targetViewportPosition === 'rightEdge') {
      offset = -width;
    }
    if (this.props.enablePushOut !== true) {
      // @ts-ignore TS2339
      ReactDOM.findDOMNode(targetCard).setAttribute(
        'style',
        `z-index: 4; transform: translate3d(${offset}px, 0px, 0px); ${defaultStyle}`,
      );
      // @ts-ignore TS2339
      slide.style.zIndex = 4;
    } else {
      // @ts-ignore TS2339
      slide.setAttribute('style', `z-index: 4; transform: translate3d(${offset}px, 0px, 0px); ${defaultStyle}`);
    }

    if (this.props.enablePushOut !== true) {
      return;
    }

    // 両サイドのSliderItemの位置を重ならないように移動する

    let elem = slide;
    // 左側のSliderItemの処理
    offset = -1 * width;
    if (targetViewportPosition === 'rightEdge') {
      offset = offset * 2;
    } else if (targetViewportPosition === 'leftEdge') {
      offset = 0;
    }
    while ((elem = elem.previousElementSibling)) {
      if (elem.classList.contains('slider-item') && !elem.classList.contains('slider-item-')) {
        elem.setAttribute('style', `transform: translate3d(${offset}px, 0px, 0px); ${defaultStyle}`);
      }
    }

    // 右側のSliderItemの処理
    elem = slide;
    offset = width;
    if (targetViewportPosition === 'leftEdge') {
      offset = offset * 2;
    } else if (targetViewportPosition === 'rightEdge') {
      offset = 0;
    }
    while ((elem = elem.nextElementSibling)) {
      if (elem.classList.contains('slider-item') && !elem.classList.contains('slider-item-')) {
        elem.setAttribute('style', `transform: translate3d(${offset}px, 0px, 0px); ${defaultStyle}`);
      }
    }
  }

  onPopLeave(targetCard, delay = 0) {
    this.popAnimationId = setTimeout(() => {
      delete this.popAnimationId;
      if (this.activeCard !== targetCard && this.activeCard !== undefined) {
        if (this.props.enablePushOut !== true) {
          if (this.sliderRef.current) {
            const targetSliderItem = this.sliderRef.current.itemRefs[targetCard.props.sliderItemId];
            // 同じスライドItemの場合は処理を行わない
            if (targetCard.props.sliderItemId !== this.activeCard.props.sliderItemId) {
              const slide = ReactDOM.findDOMNode(targetSliderItem);
              if (slide) {
                // @ts-ignore TS2339
                slide.style.zIndex = 2;
                // @ts-ignore TS2339
                slide.style.transform = 'translate3d(0px, 0px, 0px)';
              }
            }
          }

          const container = ReactDOM.findDOMNode(targetCard);
          if (container) {
            // @ts-ignore TS2339
            container.style.zIndex = 2;
            // @ts-ignore TS2339
            container.style.transform = 'translate3d(0px, 0px, 0px)';
          }
        }
        return;
      }

      const container = ReactDOM.findDOMNode(targetCard);
      if (container) {
        // @ts-ignore TS2339
        if (parseInt(container.style.zIndex, 10) === 4) {
          // @ts-ignore TS2339
          container.style.zIndex = 2;
        }
        // @ts-ignore TS2339
        container.style.transform = 'translate3d(0px, 0px, 0px)';
      }

      const sliderDOM = ReactDOM.findDOMNode(this.sliderRef.current);
      if (sliderDOM) {
        // @ts-ignore TS2339
        Array.prototype.forEach.call(sliderDOM.querySelectorAll('.slider-item:not(.slider-item-)'), slide => {
          if (parseInt(slide.style.zIndex, 10) === 4) {
            slide.style.zIndex = 2;
          }
          if (this.props.enablePushOut === true) {
            slide.style.transform = 'translate3d(0px, 0px, 0px)';
          }
        });
      }
    }, delay);
  }

  onPopClose(targetCard) {
    if (this.activeCard !== targetCard) {
      if (this.props.enablePushOut !== true) {
        if (this.sliderRef.current) {
          const targetSliderItem = this.sliderRef.current.itemRefs[targetCard.props.sliderItemId];
          const slide = ReactDOM.findDOMNode(targetSliderItem);
          if (slide) {
            // @ts-ignore TS2339
            slide.removeAttribute('style');
          }
        }
        const card = ReactDOM.findDOMNode(targetCard);
        if (card) {
          // @ts-ignore TS2339
          card.removeAttribute('style');
        }
      }
      return;
    }

    delete this.activeCard;
    this.setState({ isPopOpen: false });
    const sliderDOM = ReactDOM.findDOMNode(this.sliderRef.current);
    if (sliderDOM) {
      // @ts-ignore TS2339
      Array.prototype.forEach.call(sliderDOM.querySelectorAll('.slider-item:not(.slider-item-)'), slide => {
        slide.removeAttribute('style');
      });
      Array.prototype.forEach.call(
        // @ts-ignore TS2339
        sliderDOM.querySelectorAll('.slider-item:not(.slider-item-) .title-card'),
        container => {
          container.removeAttribute('style');
        },
      );
    }
  }

  onCardClick(item) {
    if (this.props.handleItemClick) {
      this.props.handleItemClick(item);
    }
  }

  handleSliderMove(nextState, actionType) {
    this.setState({ lowestVisibleItemIndex: nextState.lowestVisibleItemIndex });
    if (this.props.handleSliderMove) {
      this.props.handleSliderMove(nextState, actionType);
    }
  }
}
