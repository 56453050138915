import React from 'react';
import classnames from 'classnames';
import _ from 'src/libs/util';
import PropTypes from 'prop-types';
import Button from './Button';

class FullScreenButton extends React.PureComponent {
  static get propTypes() {
    return {
      isHidden: PropTypes.bool,
      isFullScreen: PropTypes.bool,
      startHover: PropTypes.func,
      stopHover: PropTypes.func,
    };
  }

  static get defaultProps() {
    return {
      isFullScreen: false,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.onClick = this.onClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onClick(e) {
    // @ts-ignore TS2339
    if (this.props.isHidden) {
      return;
    }

    // @ts-ignore TS2339
    if (this.props.onClick) {
      // @ts-ignore TS2339
      this.props.onClick(e);
    }
    // @ts-ignore TS2339
    if (this.props.releaseFocus) {
      // @ts-ignore TS2339
      this.props.releaseFocus(e);
    }
  }

  onMouseEnter(e) {
    // @ts-ignore TS2339
    if (this.props.startHover) {
      // @ts-ignore TS2339
      this.props.startHover('fullscreen');
    }
  }

  onMouseLeave(e) {
    // @ts-ignore TS2339
    if (this.props.stopHover) {
      // @ts-ignore TS2339
      this.props.stopHover(this, e);
    }
  }

  render() {
    const props = _.omit(this.props, 'isFullScreen', 'isHidden', 'onClick', 'stopHover', 'startHover');
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isIOS && !browserInfo.isAndroid) {
      // @ts-ignore TS2339
      props.onMouseEnter = this.onMouseEnter;
      // @ts-ignore TS2339
      props.onMouseLeave = this.onMouseLeave;
      // @ts-ignore TS2339
      props.onFocus = this.onMouseEnter;
      // @ts-ignore TS2339
      props.onBlur = this.onMouseLeave;
    }
    return (
      <Button
        {...props}
        type="button"
        className={classnames('touchable', 'player-control-element', 'player-button-control', 'fa', {
          // @ts-ignore TS2339
          'fa-expand': !this.props.isFullScreen,
          // @ts-ignore TS2339
          'fa-compress': this.props.isFullScreen,
        })}
        tabIndex="0"
        onClick={this.onClick}
        // @ts-ignore TS2339
        aria-label={this.props.isFullScreen ? '全画面表示の終了' : '全画面表示'}
      />
    );
  }
}

// export default FullScreenButton;
export default React.forwardRef(function fullScreenButton(props, ref) {
  // @ts-ignore TS2322
  return <FullScreenButton {...props} forwardedRef={ref} />;
});
