import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import StreaksPlayer from './StreaksPlayer';
import PlayerSurface from './PlayerSurface';

class OAPPlayerCore extends React.PureComponent {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  static get defaultProps() {
    return {
      muted: true,
    };
  }

  constructor(props, context) {
    super(props, context);

    this.handleWindowUnload = this.handleWindowUnload.bind(this);
    this.onReady = this.onReady.bind(this);
    this.onError = this.onError.bind(this);
    this.loadVideo = this.loadVideo.bind(this);
    this.dispose = this.dispose.bind(this);
    // @ts-ignore TS2339
    this.playerRef = React.createRef();

    this.state = {
      audioTracks: [],
      textTracks: [],
      buffered: 0,
      currentPlayerTime: 0,
      destroyPlayer: false,
      duration: 0,
      enableFocusStyle: false,
      ended: false,
      error: null,
      fallbackMode: false,
      fastForward: false,
      loaded: false,
      loading: false,
      metadataloaded: false,
      milestone: 0,
      firstplaying: false,
      playStartCompleted: false,
      playbackTimedOut: false,
      player: null,
      postPlayTriggered: false,
      ready: false,
      rebuffering: false,
      rewoundOnEnd: false,
      seeking: false,
      selectedAudioTrack: null,
      selectedTextTrack: null,
      stalled: false,
      timedTextBounds: {},
      timedTracksLoaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('unload', this.handleWindowUnload, false);
  }

  componentWillUnmount() {
    window.removeEventListener('unload', this.handleWindowUnload, false);
    try {
      // @ts-ignore TS2339
      if (ReactDOM.findDOMNode(this).classList) {
        // @ts-ignore TS2339
        ReactDOM.findDOMNode(this).classList.add('prp-closing');
      }
    } catch (e) {
      console.error(e);
    }
    this.dispose();
  }

  handleWindowUnload() {
    // @ts-ignore TS2339
    this.__unloading = true;
    this.dispose();
  }

  onReady(player) {
    // @ts-ignore TS2339
    player = player || _.get(this.playerRef, 'current.player');
    this.setState({ ready: true, player });

    // @ts-ignore TS2339
    if (this.props.onReady) {
      // @ts-ignore TS2339
      this.props.onReady(player);
    }

    let __loaded = false,
      __started = false;
    const triggerLoadEvent = () => {
      if (!__loaded) {
        // @ts-ignore TS2339
        if (this.props.onLoaded) {
          // @ts-ignore TS2339
          let ret = this.props.onLoaded();
          if (ret !== false) {
            __loaded = true;
          }
        } else {
          __loaded = true;
        }
        this.setState({ loaded: __loaded });
        // @ts-ignore TS2339
        if (__loaded && !this.props.paused && this.props.autoplay != false) {
          player.play();
        }
      }
    };

    player.on('loadstart', () => {
      // console.log('OAPPlayerCore::loadstart')
      // @ts-ignore TS2339
      if (this.props.onLoadStart) {
        // @ts-ignore TS2339
        this.props.onLoadStart();
      }
    });
    player.on('pause', () => {
      // console.log('OAPPlayerCore::pause')
      // @ts-ignore TS2339
      if (this.playReload) return;
      const newState = {
        paused: true,
      };
      this.setState(newState);
      // @ts-ignore TS2339
      if (this.props.onPause) {
        // @ts-ignore TS2339
        this.props.onPause();
      }
    });
    player.on('play', () => {
      // console.log('OAPPlayerCore::play')
      // @ts-ignore TS2339
      if (this.playReload) return;
      if (!__started) {
        __started = true;
        this.setState({ playStartCompleted: true });
      }
      this.setState({ paused: false, ended: false }, () => {});
      // @ts-ignore TS2339
      if (this.props.onPlay) {
        // @ts-ignore TS2339
        this.props.onPlay();
      }
    });
    player.on('firstplay', () => {
      // console.log('OAPPlayerCore::firstplay')
    });
    player.on('playing', () => {
      // console.log('OAPPlayerCore::playing')
      // @ts-ignore TS2339
      this.seekToEnded = false;
      // @ts-ignore TS2339
      if (this.props.onPlaying) {
        // @ts-ignore TS2339
        this.props.onPlaying();
      }
      this.setState({ firstplaying: true });
    });
    player.on('loadedmetadata', () => {
      // console.log('OAPPlayerCore::loadedmetadata')
      this.setState({ metadataloaded: true });
    });
    player.on('loadeddata', () => {
      // console.log('OAPPlayerCore::loadeddata')
      this.setState({ loaded: true });
      triggerLoadEvent();
    });
    player.on('ended', () => {
      // console.log('OAPPlayerCore::ended')
      // @ts-ignore TS2339
      if (this.playReload) {
        player.currentTime(0);
        return;
      }
      const newState = { ended: true };
      // @ts-ignore TS2339
      this.endedTimmer = setTimeout(() => {
        // @ts-ignore TS2339
        if (this.endedTimmer) clearTimeout(this.endedTimmer);
        // @ts-ignore TS2339
        delete this.endedTimmer;
      }, 100);
      this.setState(newState);
      // @ts-ignore TS2339
      if (this.props.onEnded) {
        // @ts-ignore TS2339
        this.props.onEnded();
      }
    });
    player.on('error', () => {
      // console.log('OAPPlayerCore::error')
    });
    player.one('durationchange', () => {
      // console.log('OAPPlayerCore::durationchange')
      this.setState({ duration: player.duration() });
      if (player.duration() === Infinity) {
        triggerLoadEvent();
      }
      // @ts-ignore TS2339
      if (this.props.onDurationChange) {
        // @ts-ignore TS2339
        this.props.onDurationChange(player.duration());
      }
    });
    player.on('ratechange', () => {
      // console.log('OAPPlayerCore::ratechange')
      const playbackRate = player.playbackRate();
      if (playbackRate === 0) return;
      // @ts-ignore TS2339
      if (this.props.onPlaybackRateChange) {
        // @ts-ignore TS2339
        this.props.onPlaybackRateChange(playbackRate);
      }
    });
    player.on('volumechange', () => {
      // console.log('OAPPlayerCore::volumechange')
      const volume = player.volume();
      const muted = player.muted();
      this.setState({ volume, muted });
      // @ts-ignore TS2339
      if (this.props.onVolumeChange) {
        // @ts-ignore TS2339
        this.props.onVolumeChange(volume, muted);
      }
    });
    player.on('stalled', () => {
      // console.log('OAPPlayerCore::stalled')
      this.setState({ stalled: true, canplaythrough: false });
    });
    player.on('emptied', () => {
      // console.log('OAPPlayerCore::emptied')
      this.setState({ emptied: true, canplaythrough: false });
    });
    player.on('suspend', () => {
      // console.log('OAPPlayerCore::suspend')
      this.setState({ emptied: true, canplaythrough: false }, () => {
        // @ts-ignore TS2339
        if (this.props.onSuspend) {
          // @ts-ignore TS2339
          this.props.onSuspend();
        }
      });
    });
    player.on('waiting', () => {
      // console.log('OAPPlayerCore::waiting')
      this.setState({ rebuffering: true, canplaythrough: false });
      // @ts-ignore TS2339
      if (this.props.onRebuffering) {
        // @ts-ignore TS2339
        this.props.onRebuffering();
      }
    });
    player.on('playing', () => {
      // console.log('OAPPlayerCore::playing')
      const newState = {
        loading: false,
        rebuffering: false,
        stalled: false,
        emptied: false,
      };
      // @ts-ignore TS2339
      if (!this.props.paused) {
        // @ts-ignore TS2339
        newState.paused = false;
      }
      // @ts-ignore TS2554
      this.setState();
    });
    player.on('canplay', () => {
      // console.log('OAPPlayerCore::canplay')
      this.setState({ rebuffering: false });
      triggerLoadEvent();
    });
    player.on('seeked', () => {
      // console.log('OAPPlayerCore::seeked')
      // @ts-ignore TS2339
      this.seekToEnded = true;
      this.setState({ seeking: false, rebuffering: false }, () => {
        // @ts-ignore TS2339
        if (this.playReload) {
          if (player.currentTime() == 0) {
            // @ts-ignore TS2339
            this.playReload = false;
          }
          setTimeout(() => {
            player.play();
          }, 100);
        // @ts-ignore TS2339
        } else if (!this.state.playStartCompleted) {
          player.play();
        }
      });
    });
    player.on('seeking', () => {
      // console.log('OAPPlayerCore::seeking')
      this.setState({ seeking: true, ended: false });
    });
    player.on('canplaythrough', () => {
      // console.log('OAPPlayerCore::canplaythrough')
      this.setState({ canplaythrough: true });
    });
    player.on('firstplay', () => {
      // console.log('OAPPlayerCore::firstplay')
      this.setState({ playStartCompleted: true });
      // @ts-ignore TS2339
      if (this.props.onFirstplay) {
        // @ts-ignore TS2339
        this.props.onFirstplay();
      }
    });
    player.on('timeupdate', () => {
      // console.log('OAPPlayerCore::timeupdate')
      // @ts-ignore TS2339
      if (this.endedTimmer) return;
      this.setState({ duration: player.duration(), currentPlayerTime: player.currentTime() }, () => {
        // @ts-ignore TS2339
        if (this.props.onTimeUpdate) {
          // @ts-ignore TS2339
          this.props.onTimeUpdate(this.state.currentPlayerTime);
        }
      });
    });
  }

  onError(error) {
    // console.log('OAPPlayerCore.onError')
    const newState = { paused: true, error };
    this.setState(newState, () => {
      // @ts-ignore TS2339
      if (this.props.onError) {
        // @ts-ignore TS2339
        this.props.onError(error);
      }
    });
  }

  dispose() {}

  play() {
    // @ts-ignore TS2339
    if (this.state.player) {
      // @ts-ignore TS2339
      this.state.player.play();
    }
  }

  pause() {
    // @ts-ignore TS2339
    if (this.state.player) {
      // @ts-ignore TS2339
      this.state.player.pause();
    }
  }

  reload() {
    // @ts-ignore TS2339
    this.playReload = true;
    // @ts-ignore TS2339
    if (this.seekToEnded) {
      // @ts-ignore TS2339
      this.state.player.currentTime(this.state.player.currentTime() - 1);
    } else {
      // @ts-ignore TS2339
      this.state.player.currentTime(0);
    }
  }

  loadVideo(handler) {
    // errorViewがあるときはloadしない
    // @ts-ignore TS2339
    if (this.props.errorView) {
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (!handler) return;
        if (xhr.status === 200 && xhr.response !== null) {
          let data = xhr.response;
          if (typeof data === 'string') {
            data = JSON.parse(data);
          }

          handler(null, data);
        } else {
          handler(xhr, null);
        }
      }
    };

    const streaks = this.context.getModelData('ovps', 'streaks');
    // @ts-ignore TS2339
    let path = `${streaks.endpoint}/projects/${streaks[this.props.ovpId].merchantId}/medias/${this.props.ovpVideoId}`;
    xhr.open('GET', path, true);
    // @ts-ignore TS2339
    xhr.setRequestHeader('X-Streaks-Api-Key', streaks[this.props.ovpId].apiKey);
    xhr.send();
  }

  render() {
    const streaks = this.context.getModelData('ovps', 'streaks', 1);
    return (
      <PlayerSurface
        aria-label="WodPlayer"
        className={classnames('wod-player-container', 'video-container', 'notranslate', {
          // @ts-ignore TS2339
          'prp-fullscreen': this.props.isFullScreen,
          // @ts-ignore TS2339
          'prp-loaded': this.state.loaded,
          // @ts-ignore TS2339
          'prp-started': this.state.firstplaying, // this.state.playStartCompleted,
          // @ts-ignore TS2339
          'prp-ended': this.state.ended,
          // @ts-ignore TS2339
          'prp-closing': this.state.ended,
          // @ts-ignore TS2339
          'prp-error': !!this.props.errorView,
          // @ts-ignore TS2339
          'prp-before-delivery': this.props.isBeforeDelivery,
        })}
        // @ts-ignore TS2339
        onMouseEnter={this.props.onMouseEnter}
        // @ts-ignore TS2339
        onMouseLeave={this.props.onMouseLeave}
      >
        <StreaksPlayer
          // @ts-ignore TS2322
          autoplay={this.props.autoplay}
          mediaId={null}
          merchantId={streaks.merchantId}
          // playerId={streaks.playerId}
          // activeVideo={this.props.activeVideo}
          // isFullScreen={this.props.isFullScreen}
          // @ts-ignore TS2339
          loaded={this.state.loaded}
          // mueted={false}
          // @ts-ignore TS2339
          muted={this.props.muted}
          // minBitrate={this.props.minBitrate || -1}
          // maxBitrate={this.props.maxBitrate || -1}
          loadVideo={this.loadVideo}
          // playerPaused={this.state.paused}
          // playContext={this.props.playContext}
          // setRenditions={this.props.setRenditions}
          onReady={this.onReady}
          onError={this.onError}
          // @ts-ignore TS2339
          onTimeout={this.onTimeout}
          // @ts-ignore TS2339
          ref={this.playerRef}
        />
      </PlayerSurface>
    );
  }
}

export default OAPPlayerCore;
