import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import FullHitZone from './FullHitZone';
import DoubleTapArea from './DoubleTapArea';
import StepButton from './StepButton';
import PictureInPictureButton from './PictureInPictureButton';
import FullScreenButton from './FullScreenButton';
import ProgressBar from './ProgressBar';
import TimeRemaining from './TimeRemaining';
import Timelimit from './Timelimit';
import AudienceButton from './AudienceButton';

import PlayButton from './PlayButton';
import PlayNextButton from './PlayNextButton';
import ReloadButton from './ReloadButton';
import VolumeButton from './VolumeButton';
import SettingsButton from './SettingsButton';
import Settings from './Settings';
import CastButton from './CastButton';
import CommentButton from './CommentButton';
import SetDate from '../../../bluerose/components/browse/SetDate';
import AudioTextSettingsButton from './AudioTextSettingsButton';
import AudioTextSettings from './AudioTextSettings';

import * as DOMUtils from '../../../../sketch-platform/utils/DOMUtils';
import BackgroundImage from '../BackgroundImage';

import EpgEvent from '../../../bluerose/components/browse/Epg/EpgEvent';
import MainViewLink from '../MainViewLink';
import routes from '../../routes';
import { LIVE_RANGE } from '../../../../constants';
import getRatingText from 'src/utils/getRatingText';

class LinearChannelListOnPlayer extends React.PureComponent {
  static getPaths = function(models, options, props = {}) {
    let paths = [];
    // @ts-ignore TS2339
    paths = paths.concat([['meta', props.linearChannelMetaIds, ['id', 'name', 'refId', 'thumbnailUrl']]]);
    EpgEvent.getPaths(models, options, props).forEach(_path => {
      // @ts-ignore TS2339
      paths = paths.concat([['meta', props.linearChannelMetaIds, 'onair'].concat(_path)]);
    });
    return paths;
  };

  static getRootPath = function(models, options, props = {}) {
    return ['meta'];
  };

  static get contextTypes() {
    return {
      routeHandler: PropTypes.object,
      history: PropTypes.object,
      models: PropTypes.object,
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    this.items = props.model.getSync(rootPath);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
      leavedChannelName: null,
    };
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.mouseOverTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOverTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOverTimeoutId;
    }
  }

  componentWillReceiveProps(nextProps) {
    // @ts-ignore TS2339
    if (this.props.isHidden && !nextProps.isHidden) {
      // オンエアが変わっている場合があるので
      this.fetchData(this.props);
    }
  }

  handleKeyDown(channelName) {
    return e => {
      if (e.which === 13 || e.keyCode === 13) {
        const { history } = this.context;
        // @ts-ignore TS2554
        history.replace(routes.simulcast.makePath({ channelName: channelName }));
      }
    };
  }

  onMouseEnter(e) {
    // @ts-ignore TS2339
    if (this.props.startHover) {
      // @ts-ignore TS2339
      this.props.startHover();
    }
    // @ts-ignore TS2339
    if (this.mouseOverTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOverTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOverTimeoutId;
    }
  }

  onMouseLeave(channelName) {
    // @ts-ignore TS2339
    if (this.props.stopHover) {
      // @ts-ignore TS2339
      this.props.stopHover();
    }
    this.setState({ leavedChannelName: channelName });
    // @ts-ignore TS2339
    this.mouseOverTimeoutId = setTimeout(() => {
      // @ts-ignore TS2339
      delete this.mouseOverTimeoutId;
      this.setState({ leavedChannelName: null });
    }, 300);
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        const items = _.get(res.json, rootPath, {});
        // @ts-ignore TS2339
        this.items = _.toArray(_.omit(items, '$__path'));
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }

  render() {
    const getImage = (item = {}) => {
      if (_.get(item, 'onair.thumbnailUrl')) {
        // @ts-ignore TS2339
        return item.onair.thumbnailUrl;
      }
      // @ts-ignore TS2339
      return item.thumbnailUrl;
    };
    const items = [];
    // @ts-ignore TS2339
    const idx = _.findIndex(this.items, item => item.id == this.props.metadata.id);
    if (idx !== -1) {
      // @ts-ignore TS2339
      items.push(this.items[idx - 1] ? this.items[idx - 1] : this.items[this.items.length - 1]);
      // @ts-ignore TS2339
      items.push(this.items[idx + 1] ? this.items[idx + 1] : this.items[0]);
    }
    return (
      <div className="linear-ch-switch">
        {_.map(items, item => {
          let lowerChannelName;
          const simulcast = _.find(this.context.getModelData('simulcast'), _item => _item.refId == item.refId);
          if (simulcast) lowerChannelName = _.lowerCase(simulcast.name);
          return (
            <div
              className={classnames('linear-ch-switch_item', {
                // @ts-ignore TS2339
                isLeave: this.state.leavedChannelName === simulcast.name,
              })}
              onKeyDown={this.handleKeyDown(simulcast.name)}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={() => this.onMouseLeave(simulcast.name)}
            >
              <MainViewLink
                // @ts-ignore TS2322
                to={routes.simulcast}
                params={{ channelName: simulcast.name }}
                key={`channel_link_${lowerChannelName}`}
              >
                <div className="linear-ch-switch_item-inner">
                  {/*
                   // @ts-ignore TS2322 */}
                  <BackgroundImage className="artwork" url={getImage(item)} />
                  <img
                    src={`../images/logo_${lowerChannelName}_white.svg`}
                    className="linear-ch-switch_item-inner_logo"
                  />
                </div>
                <div className="linear-ch-switch_item-text">
                  {item.onair && (
                    <div className="linear-ch-switch_item-text-time">
                      {/*
                       // @ts-ignore TS2322 */}
                      <SetDate date={item.onair.startAt} format={'short'} /> -{' '}
                      {/*
                       // @ts-ignore TS2322 */}
                      <SetDate date={item.onair.endAt} format={'time'} />
                    </div>
                  )}
                  <div className="linear-ch-switch_item-text-title">{_.get(item, 'onair.name', '休止中')}</div>
                </div>
              </MainViewLink>
            </div>
          );
        })}
      </div>
    );
  }
}

type ButtonKey =
  | 'play'
  | 'reload'
  | 'step-back-10'
  | 'step-forward-10'
  | 'volume'
  | 'live-skip'
  | 'play-next'
  | 'audioTextSettings'
  | 'settings'
  | 'cast'
  | 'pip'
  | 'fullscreen'
  | 'comment';

class WodPlayerControls extends React.PureComponent {
  static get defaultProps() {
    return {
      enablePause: true,
      enableFullScreen: true,
      enableSeek: true,
    };
  }

  static get propTypes() {
    return {
      audioTracks: PropTypes.array,
      textTracks: PropTypes.array,
      controls: PropTypes.bool,
      currentScrubTime: PropTypes.number,
      exitPlayer: PropTypes.func,
      getEvidence: PropTypes.func,
      getPlayerNode: PropTypes.func,
      mode: PropTypes.string,
      hasPreplay: PropTypes.bool,
      isActive: PropTypes.bool,
      isFullScreen: PropTypes.bool,
      metadata: PropTypes.object,
      muted: PropTypes.bool,
      onCastToTarget: PropTypes.func,
      onClickPlay: PropTypes.func,
      onClickPlayNext: PropTypes.func,
      onClickReload: PropTypes.func,
      onClickSeekBack: PropTypes.func,
      onClickSeekForward: PropTypes.func,
      onFullScreenChange: PropTypes.func,
      onMouseMove: PropTypes.func,
      onUserActivityChanged: PropTypes.func,
      onClickVolume: PropTypes.func,
      onClickCastButton: PropTypes.func,
      onClickCommenntButton: PropTypes.func,
      commentActive: PropTypes.bool,
      watchPartyMode: PropTypes.bool,
      watchPartyType: PropTypes.string,
      ready: PropTypes.bool,
      paused: PropTypes.bool,
      playStartCompleted: PropTypes.bool,
      playNextTitle: PropTypes.func,
      playbackEnded: PropTypes.bool,
      playbackTimeOut: PropTypes.bool,
      player: PropTypes.object,
      playerDimensions: PropTypes.object,
      scrubbing: PropTypes.bool,
      selectedAudioTrack: PropTypes.object,
      selectedTextTrack: PropTypes.object,
      setAudioTrack: PropTypes.func,
      setKeepActive: PropTypes.func,
      setTimedTextTrack: PropTypes.func,
      shouldFadePlayerControlsBar: PropTypes.bool,
      skipCreditsEnabled: PropTypes.bool,
      timedTracksLoaded: PropTypes.bool,
      uiState: PropTypes.string,
      updateCurrentScrubTime: PropTypes.func,
      volume: PropTypes.number,
      playbackRate: PropTypes.number,
      enablePlaybackRateChange: PropTypes.bool,
      quality: PropTypes.number,
      playToggleButton: PropTypes.string,
      enableAutoplay: PropTypes.bool,
      onToggleAutoplay: PropTypes.func,
      onChangeQuality: PropTypes.func,
      watchSpMode: PropTypes.bool,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      isIframe: PropTypes.bool,
      watchPartyApp: PropTypes.object,
      models: PropTypes.object,
    };
  }
  buttonRefs: {
    [K in ButtonKey]?: React.RefObject<any>;
  };

  constructor(props, context) {
    super(props, context);

    this.buttonRefs = {};
    // @ts-ignore TS2339
    this.buttonRefs['play'] = React.createRef();
    // @ts-ignore TS2339
    this.buttonRefs['reload'] = React.createRef();
    // @ts-ignore TS2339
    this.buttonRefs['step-back-10'] = React.createRef();
    // @ts-ignore TS2339
    this.buttonRefs['step-forward-10'] = React.createRef();
    // @ts-ignore TS2339
    this.buttonRefs['volume'] = React.createRef();
    // @ts-ignore TS2339
    this.buttonRefs['live-skip'] = React.createRef();
    // @ts-ignore TS2339
    this.buttonRefs['play-next'] = React.createRef();
    // @ts-ignore TS2339
    this.buttonRefs['audioTextSettings'] = React.createRef();
    // @ts-ignore TS2339
    this.buttonRefs['settings'] = React.createRef();
    // @ts-ignore TS2339
    this.buttonRefs['cast'] = React.createRef();
    // @ts-ignore TS2339
    this.buttonRefs['pip'] = React.createRef();
    // @ts-ignore TS2339
    this.buttonRefs['fullscreen'] = React.createRef();
    // @ts-ignore TS2339
    this.buttonRefs['comment'] = React.createRef();
    // @ts-ignore TS2339
    this.doubleTapBackRef = React.createRef();
    // @ts-ignore TS2339
    this.doubleTapForwardRef = React.createRef();
    // @ts-ignore TS2339
    this.progressBarRef = React.createRef();
    // @ts-ignore TS2339
    this.tooltipRef = React.createRef();
    // @ts-ignore TS2339
    this.controlButtonsElement = null;

    this.drowTooltip = this.drowTooltip.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClickControls = this.onClickControls.bind(this);
    this.onPointerEnter = this.onPointerEnter.bind(this);
    this.onPointerLeave = this.onPointerLeave.bind(this);
    this.releaseFocus = this.releaseFocus.bind(this);
    this.startHover = this.startHover.bind(this);
    this.stopHover = this.stopHover.bind(this);
    this.startSettingsHover = this.startSettingsHover.bind(this);
    this.stopSettingsHover = this.stopSettingsHover.bind(this);
    this.startLinearBtnHover = this.startLinearBtnHover.bind(this);
    this.stopLinearBtnHover = this.stopLinearBtnHover.bind(this);
    this.onScrubStart = this.onScrubStart.bind(this);
    this.onScrubEnd = this.onScrubEnd.bind(this);
    this.onClickSettings = this.onClickSettings.bind(this);
    this.onClickAudioTextSettings = this.onClickAudioTextSettings.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {
      focused: '',
      holdingFocus: false,
      hovered: '',
      showSettings: false,
      showAudioTextSettings: false,
      keyboardFocus: false,
    };
  }

  componentDidMount() {
    if (!Element.prototype.matches) {
      // @ts-ignore TS2339
      Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
      Element.prototype.closest = function(s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
          if (el.matches(s)) return el;
          el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
      };
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      // @ts-ignore TS2339
      !this.state.hoveredSettings &&
      // @ts-ignore TS2339
      (this.props.isActive || this.props.paused) &&
      !(nextProps.isActive || nextProps.paused)
    ) {
      this.setState({ hovered: '', showSettings: false, showAudioTextSettings: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.drowTooltip();

    // @ts-ignore TS2339
    if (this.props.onHover && prevState.hovered != this.state.hovered) {
      // @ts-ignore TS2339
      this.props.onHover(!!this.state.hovered);
    }
    // @ts-ignore TS2339
    if (prevState.keyboardFocus != this.state.keyboardFocus) {
      // @ts-ignore TS2339
      this.props.onKeyboardFocus(this.state.keyboardFocus);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    if (this.settingsCloseTimmer) {
      // @ts-ignore TS2339
      clearTimeout(this.settingsCloseTimmer);
      // @ts-ignore TS2339
      delete this.settingsCloseTimmer;
    }
  }

  drowTooltip() {
    // @ts-ignore TS2339
    if (this.buttonRefs[this.state.hovered]) {
      // const el = ReactDOM.findDOMNode(this.buttonRefs[this.state.hovered]);
      // @ts-ignore TS2339
      const el = this.buttonRefs[this.state.hovered].current;
      // @ts-ignore TS2339
      if (el && this.tooltipRef.current) {
        // @ts-ignore TS2339
        this.tooltipRef.current.classList.add('tooltip-visible');
        const position = DOMUtils.findPosition(el);
        const rect = DOMUtils.getRect(el);
        const parentPosition = DOMUtils.findPosition(el.closest('.button-controls-row'));
        // const tooltipRect = DOMUtils.getRect(ReactDOM.findDOMNode(this.tooltipRef.current));
        // @ts-ignore TS2339
        const tooltipRect = DOMUtils.getRect(this.tooltipRef.current);
        // @ts-ignore TS2339
        if (this.state.hovered == 'play-next') {
          // @ts-ignore TS2339
          this.tooltipRef.current.setAttribute('style', `right: 0px;`);
        } else if (
          position.left - parentPosition.left + rect.width / 2 + tooltipRect.width >
          DOMUtils.getRect(el.closest('.button-controls-row')).width + tooltipRect.width / 2
        ) {
          // @ts-ignore TS2339
          this.tooltipRef.current.setAttribute('style', `right: ${-tooltipRect.width / 2}px;`);
        } else {
          // @ts-ignore TS2339
          this.tooltipRef.current.setAttribute(
            'style',
            `left: ${Math.max(
              // @ts-ignore TS2345
              parseInt(tooltipRect.width / 2, 10),
              // @ts-ignore TS2345
              parseInt(position.left - parentPosition.left + rect.width / 2),
            )}px;`,
          );
        }
      }
    } else {
      // @ts-ignore TS2339
      if (this.tooltipRef.current) {
        // @ts-ignore TS2339
        this.tooltipRef.current.classList.remove('tooltip-visible');
        // @ts-ignore TS2339
        this.tooltipRef.current.setAttribute('style', ``);
      }
    }
  }

  onPointerEnter() {}

  onPointerLeave() {}

  releaseFocus(e) {}

  isTriggeredByMouseClick(clientX, clientY) {
    // NOTE Enter/Spaceキーによるクリック判定の場合は座標が０以下になる
    return clientX > 0 && clientY > 0;
  }

  startHover(hovered) {
    // @ts-ignore TS2339
    if (this.state.showSettings || this.state.showAudioTextSettings) return;
    this.setState({ hovered });
  }

  stopHover(e) {
    // @ts-ignore TS2339
    if (this.state.showSettings || this.state.showAudioTextSettings) return;
    this.setState({ hovered: '' });
  }

  startSettingsHover() {
    this.setState({ hoveredSettings: true });
    // @ts-ignore TS2339
    if (this.props.setKeepActive) {
      // @ts-ignore TS2339
      this.props.setKeepActive(true);
    }
  }

  stopSettingsHover() {
    this.setState({ hoveredSettings: false });
    // @ts-ignore TS2339
    if (this.props.setKeepActive) {
      // @ts-ignore TS2339
      this.props.setKeepActive(false);
    }
  }
  startLinearBtnHover() {
    this.setState({ hoveredLinearBtn: true });
    // @ts-ignore TS2339
    if (this.props.setKeepActive) {
      // @ts-ignore TS2339
      this.props.setKeepActive(true);
    }
  }

  stopLinearBtnHover() {
    this.setState({ hoveredLinearBtn: false });
    // @ts-ignore TS2339
    if (this.props.setKeepActive) {
      // @ts-ignore TS2339
      this.props.setKeepActive(false);
    }
  }

  onMouseMove(e) {
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isCarMonitor) {
      return;
    }
    this.setState({ keyboardFocus: false });
    // @ts-ignore TS2339
    if (this.props.onMouseMove) {
      // @ts-ignore TS2339
      this.props.onMouseMove(e);
    }
  }

  onMouseLeave(e) {
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isCarMonitor) {
      return;
    }
    const newState = { keyboardFocus: false, hovered: '' };

    if (!e.target.closest('.tooltip')) {
      if (!e.target.classList.contains('fa-cog')) {
        newState['showSettings'] = false;
      }
      if (!e.target.classList.contains('fa-sub-dub')) {
        newState['showAudioTextSettings'] = false;
      }
    }
    // @ts-ignore TS2339
    if (this.props.isAdMode && this.props.onMouseLeave) {
      // @ts-ignore TS2339
      if (this.props.onMouseLeave) {
        // @ts-ignore TS2339
        this.props.onMouseLeave(e);
      }
    }
    this.setState(newState);
  }

  onClickControls(e) {
    const newState = { hovered: '' };

    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2339
    if (browserInfo.isCarMonitor && this.props.onClickTouchDeviceControl) {
      // タッチデバイス全般これにしたいが、一旦車載モニターの場合のみ
      // @ts-ignore TS2339
      this.props.onClickTouchDeviceControl(e);
    }
    if (!e.target.closest('.tooltip')) {
      if (!e.target.classList.contains('fa-cog')) {
        newState['showSettings'] = false;
      }
      if (!e.target.classList.contains('fa-sub-dub')) {
        newState['showAudioTextSettings'] = false;
      }
    }
    // スマホで広告再生の場合は、本編再生時のclick動作と違う挙動：controlsを表示するようにする
    // @ts-ignore TS2339
    if (this.props.isAdMode && (browserInfo.isIOS || browserInfo.isAndroid || browserInfo.isRequestDesktopWebsite)) {
      // @ts-ignore TS2339
      if (this.props.showControlsOnMobileDevices) {
        // @ts-ignore TS2339
        this.props.showControlsOnMobileDevices(e);
      }
    }

    this.setState(newState);
  }

  onScrubStart() {
    // @ts-ignore TS2339
    this.settingsCloseTimmer = setTimeout(() => {
      const newState = { hovered: '' };
      // @ts-ignore TS2339
      if (this.state.showSettings) newState['showSettings'] = false;
      // @ts-ignore TS2339
      if (this.state.showAudioTextSettings) newState['showAudioTextSettings'] = false;

      this.setState(newState);
    }, 300);

    // @ts-ignore TS2339
    if (this.state.showSettings || this.state.showAudioTextSettings) return;
    this.setState({ hovered: 'progressbar' });
    // @ts-ignore TS2339
    if (this.props.setKeepActive) {
      // @ts-ignore TS2339
      this.props.setKeepActive(true);
    }
  }

  onScrubEnd() {
    // @ts-ignore TS2339
    if (this.settingsCloseTimmer) {
      // @ts-ignore TS2339
      clearTimeout(this.settingsCloseTimmer);
      // @ts-ignore TS2339
      delete this.settingsCloseTimmer;
    }
    // @ts-ignore TS2339
    if (this.state.showSettings || this.state.showAudioTextSettings) return;
    this.setState({ hovered: '' });
    // @ts-ignore TS2339
    if (this.props.setKeepActive) {
      // @ts-ignore TS2339
      this.props.setKeepActive(false);
    }
  }

  onClickSettings(e) {
    // 非同期処理内で同期イベントを呼ぶとpropertyが全てnullになっているから必要なものだけローカル変数に入れて渡す
    const { clientX, clientY } = e;
    this.setState(state => {
      return {
        // @ts-ignore TS2339
        showSettings: !state.showSettings,
        shouldFocus: !this.isTriggeredByMouseClick(clientX, clientY),
      };
    });
  }

  onClickAudioTextSettings(e) {
    const { clientX, clientY } = e;
    this.setState(state => {
      return {
        // @ts-ignore TS2339
        showAudioTextSettings: !state.showAudioTextSettings,
        shouldFocus: !this.isTriggeredByMouseClick(clientX, clientY),
      };
    });
  }

  handleKeyDown(e) {
    // Tab
    if (e.which === 9) {
      this.setState({ keyboardFocus: true });
      // @ts-ignore TS2339
      if (this.props.onMouseMove) {
        // @ts-ignore TS2339
        this.props.onMouseMove(e);
      }
    }
  }

  render() {
    // @ts-ignore TS2339
    const isHidden = !(this.props.uiState === 'active' || this.props.paused) && this.props.isActive !== true;
    // @ts-ignore TS2339
    const { isAdMode, adDuration, currentAdTime } = this.props;
    let tooltip;
    // @ts-ignore TS2339
    if (this.state.hovered === 'play') {
      // @ts-ignore TS2339
      if (this.props.playToggleButton == 'play') {
        tooltip = '一時停止';
      } else {
        tooltip = '再生';
      }
      // @ts-ignore TS2339
    } else if (this.state.hovered === 'reload') {
      tooltip = 'もう一度再生';
      // @ts-ignore TS2339
    } else if (this.state.hovered === 'play-next') {
      // @ts-ignore TS2339
      tooltip = this.props.watchCard;
      // @ts-ignore TS2339
    } else if (this.state.hovered === 'volume') {
      // @ts-ignore TS2339
      if (this.props.volumeMute) {
        tooltip = 'ミュート解除';
      } else {
        tooltip = 'ミュート（消音）';
      }
      // @ts-ignore TS2339
    } else if (this.state.hovered === 'live-skip') {
      tooltip = 'ライブに戻る';
      // @ts-ignore TS2339
    } else if (this.state.hovered === 'step-back-10') {
      tooltip = '10秒戻る';
      // @ts-ignore TS2339
    } else if (this.state.hovered === 'step-forward-10') {
      tooltip = '10秒進む';
      // @ts-ignore TS2339
    } else if (this.state.hovered === 'audioTextSettings') {
      // @ts-ignore TS2339
      if (!this.state.showAudioTextSettings) {
        tooltip = '音声字幕設定';
      }
      // @ts-ignore TS2339
    } else if (this.state.hovered === 'settings') {
      // @ts-ignore TS2339
      if (!this.state.showSettings) {
        tooltip = '設定';
      }
      // @ts-ignore TS2339
    } else if (this.state.hovered === 'mode') {
      tooltip = 'モード切替';
      // @ts-ignore TS2339
    } else if (this.state.hovered === 'cast') {
      tooltip = 'テレビで再生';
      // @ts-ignore TS2339
    } else if (this.state.hovered === 'pip') {
      // @ts-ignore TS2339
      if (this.props.isInPictureInPicture) {
        tooltip = 'ピクチャー イン ピクチャー';
      } else {
        tooltip = 'ピクチャー イン ピクチャー';
      }
      // @ts-ignore TS2339
    } else if (this.state.hovered === 'fullscreen') {
      // @ts-ignore TS2339
      if (this.props.isFullScreen) {
        tooltip = '全画面表示を終了';
      } else {
        tooltip = '全画面表示';
      }
      // @ts-ignore TS2339
    } else if (this.state.hovered === 'comment') {
      // @ts-ignore TS2339
      if (this.props.commentActive) {
        tooltip = 'チャットを非表示';
      } else {
        tooltip = 'チャットを表示';
      }
    }

    // @ts-ignore TS2339
    if (this.state.showSettings) {
      // @ts-ignore TS2339
      this.state.hovered = 'settings';
      tooltip = (
        <Settings
          // @ts-ignore TS2322
          config={this.props.config}
          // @ts-ignore TS2339
          renditions={this.props.renditions}
          // @ts-ignore TS2339
          player={this.props.player}
          drowTooltip={this.drowTooltip}
          startHover={this.startSettingsHover}
          stopHover={this.stopSettingsHover}
          // @ts-ignore TS2339
          showModal={this.props.showModal}
          // @ts-ignore TS2339
          closeModal={this.props.closeModal}
          // @ts-ignore TS2339
          enableAutoplay={this.props.enableAutoplay}
          // @ts-ignore TS2339
          onToggleAutoplay={this.props.onToggleAutoplay}
          // @ts-ignore TS2339
          playbackRate={this.props.playbackRate}
          // @ts-ignore TS2339
          enablePlaybackRateChange={this.props.enablePlaybackRateChange}
          // @ts-ignore TS2339
          showPlaybackRateChange={this.props.showPlaybackRateChange}
          // @ts-ignore TS2339
          quality={this.props.quality}
          // @ts-ignore TS2339
          enableQualityChange={this.props.enableQualityChange}
          // @ts-ignore TS2339
          onChangeQuality={this.props.onChangeQuality}
          // @ts-ignore TS2339
          onMouseMove={this.props.onMouseMove}
          // @ts-ignore TS2339
          shouldFocus={this.state.shouldFocus}
          onEscape={() => {
            // @ts-ignore TS2339
            this.buttonRefs['settings'].current.focus();
            this.setState({ showSettings: false });
          }}
        />
      );
    }

    // @ts-ignore TS2339
    if (this.state.showAudioTextSettings) {
      // @ts-ignore TS2339
      this.state.hovered = 'audioTextSettings';
      tooltip = (
        <AudioTextSettings
          // @ts-ignore TS2322
          config={this.props.config}
          // @ts-ignore TS2339
          player={this.props.player}
          drowTooltip={this.drowTooltip}
          startHover={this.startSettingsHover}
          stopHover={this.stopSettingsHover}
          // @ts-ignore TS2339
          showModal={this.props.showModal}
          // @ts-ignore TS2339
          closeModal={this.props.closeModal}
          // @ts-ignore TS2339
          enableTextTracks={this.props.enableTextTracks}
          // @ts-ignore TS2339
          enableAudioTracks={this.props.enableAudioTracks}
          // @ts-ignore TS2339
          textTrack={this.props.textTrack}
          // @ts-ignore TS2339
          audioTrack={this.props.audioTrack}
          // @ts-ignore TS2339
          onMouseMove={this.props.onMouseMove}
          // @ts-ignore TS2339
          shouldFocus={this.state.shouldFocus}
          onEscape={() => {
            // @ts-ignore TS2339
            this.buttonRefs['audioTextSettings'].current.focus();
            this.setState({ showAudioTextSettings: false });
          }}
        />
      );
    }

    // @ts-ignore TS2339
    const textTracks = _.filter(this.props.enableTextTracks, track => !track.disable);
    const enableShowTextTracks = (textTracks || []).length > 1;

    // @ts-ignore TS2339
    const audioTracks = _.filter(this.props.enableAudioTracks, track => !track.disable);
    const enableShowAudioTracks = (audioTracks || []).length > 1;
    const shouldHideAudioTextSettingBtn = !enableShowTextTracks && !enableShowAudioTracks;
    // 設定で出すメニューがない場合
    const shouldHideSettingBtn =
      // @ts-ignore TS2339
      !this.props.enableQualityChange && !this.props.enablePlaybackRateChange && !this.props.onToggleAutoplay;

    const browserInfo = this.context.getModelData('browserInfo');
    const isWpUser = this.context.watchPartyApp.isUser();
    // @ts-ignore TS2339
    const ratingText = getRatingText(this.context.models, this.props.metadata.rating);

    let seekableEnd = 0;
    let isLiveEdge = false;
    // @ts-ignore TS2339
    if (this.props.player.duration() === Infinity) {
      try {
        // @ts-ignore TS2339
        seekableEnd = this.props.player.seekable().end(0);
        // @ts-ignore TS2339
        if (seekableEnd - this.props.player.currentTime() <= LIVE_RANGE) {
          isLiveEdge = true;
        }
      } catch (e) {}
    }

    return (
      <div
        className={classnames('controls', {
          // @ts-ignore TS2339
          active: this.props.isActive,
          // @ts-ignore TS2339
          inactive: !this.props.isActive,
          // @ts-ignore TS2339
          'keyboard-focus': this.state.keyboardFocus,
        })}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onClickControls}
        onKeyDown={this.handleKeyDown}
      >
        <div className="controls-container">
          {/*
           // @ts-ignore TS2339 */}
          {isWpUser || isAdMode ? null : <FullHitZone onClickPlay={this.props.onClickPlay} />}
          {browserInfo.isCarMonitor &&
          // @ts-ignore TS2339
          this.props.player &&
          // @ts-ignore TS2339
          this.props.enableSeek ? (
            <React.Fragment>
              <DoubleTapArea
                // @ts-ignore TS2339
                ref={this.doubleTapBackRef}
                // @ts-ignore TS2332
                classes={{ 'step-backward': true }}
                step={-10}
                // @ts-ignore TS2339
                onClick={this.props.onClickSeekBack}
                // @ts-ignore TS2339
                player={this.props.player}
              />

              <div className="car-monitor-center-controls">
                <StepButton
                  // @ts-ignore TS2339
                  ref={this.buttonRefs['step-back-10']}
                  // @ts-ignore TS2322

                  isHidden={isHidden}
                  classes={{ fa: true, 'fa-replay-10': true, 'car-monitor-control-element': true }}
                  // @ts-ignore TS2339
                  onClick={this.props.onClickSeekBack}
                  step={-10}
                  label={'10秒戻る'}
                  // @ts-ignore TS2339
                  player={this.props.player}
                />
                {// @ts-ignore TS2339
                this.props.enablePause ? (
                  <PlayButton
                    // @ts-ignore TS2339
                    ref={this.buttonRefs['play']}
                    // @ts-ignore TS2322
                    player={this.props.player}
                    onPointerEnter={this.onPointerEnter}
                    onPointerLeave={this.onPointerLeave}
                    isHidden={isHidden}
                    // @ts-ignore TS2339
                    paused={this.props.paused}
                    // @ts-ignore TS2339
                    playToggleButton={this.props.playToggleButton}
                    // @ts-ignore TS2339
                    onClick={this.props.onClickPlay}
                    classes={{ fa: true, 'fa-car-monitor-play': true, 'car-monitor-control-element': true }}
                  />
                ) : null}
                <StepButton
                  // @ts-ignore TS2339
                  ref={this.buttonRefs['step-forward-10']}
                  // @ts-ignore TS2322

                  isHidden={isHidden || isLiveEdge}
                  classes={{ fa: true, 'fa-forward-10': true, 'car-monitor-control-element': true }}
                  label={'10秒進む'}
                  step={10}
                  // @ts-ignore TS2339
                  onClick={this.props.onClickSeekForward}
                  // @ts-ignore TS2339
                  player={this.props.player}
                />
              </div>

              <DoubleTapArea
                // @ts-ignore TS2339
                ref={this.doubleTapForwardRef}
                // @ts-ignore TS2332
                classes={{ 'step-forward': true }}
                step={10}
                // @ts-ignore TS2339
                onClick={this.props.onClickSeekForward}
                // @ts-ignore TS2339
                player={this.props.player}
              />
            </React.Fragment>
          ) : null}
          {/*
           // @ts-ignore TS2339 */}
          {this.props.upnextView}
          {/* // 広告inactiveの場合のprogress bar表示 */}
          <div className="main-controls is-ad-mode">
            <div className="player-controls-row bottom-controls is-ad-mode">
              <ProgressBar
                // @ts-ignore TS2339
                ref={this.progressBarRef}
                onScrubStart={this.onScrubStart}
                onScrubEnd={this.onScrubEnd}
                // @ts-ignore TS2339
                onMouseMove={this.props.onMouseMove}
                // @ts-ignore TS2339
                player={this.props.player}
                isAdMode={isAdMode}
                adDuration={adDuration}
                currentAdTime={currentAdTime}
                step={10}
                // @ts-ignore TS2339
                currentScrubTime={-1}
                // @ts-ignore TS2339
                updateCurrentScrubTime={this.props.updateCurrentScrubTime}
                isHidden={isHidden}
                // @ts-ignore TS2339
                mediaQueryCheck={this.props.mediaQueryCheck}
                // @ts-ignore TS2339
                watchSpMode={this.props.watchSpMode}
              />
            </div>
          </div>

          <div className="main-controls">
            {/*
             // @ts-ignore TS2339 */}
            {this.props.metadata && this.props.metadata.type == 'linear_channel' ? (
              <LinearChannelListOnPlayer
                // @ts-ignore TS2322
                metadata={this.props.metadata}
                tooltip={tooltip}
                // @ts-ignore TS2339
                hovered={this.state.hovered}
                // @ts-ignore TS2339
                model={this.props.model}
                isHidden={isHidden}
                startHover={this.startLinearBtnHover}
                stopHover={this.stopLinearBtnHover}
                // @ts-ignore TS2339
                linearChannelMetaIds={this.props.linearChannelMetaIds}
              />
            ) : null}
            {/*
             // @ts-ignore TS2339 */}
            {this.props.exitPlayer ? (
              <div className="player-controls-row top-right-controls">
                <button
                  className="touchable player-control-element player-control-close"
                  // @ts-ignore TS2322
                  tabIndex="0"
                  role="button"
                  // @ts-ignore TS2339
                  onClick={this.props.exitPlayer}
                ></button>
              </div>
            ) : null}
            {/*
             // @ts-ignore TS2339 */}
            {this.props.multiView && !this.state.showSettings ? (
              <div
                className={classnames('player-controls-row multiview', {
                  // @ts-ignore TS2339
                  progressHover: this.state.hovered === 'progressbar',
                })}
              >
                {/*
                 // @ts-ignore TS2339 */}
                {React.cloneElement(this.props.multiView, { commentActive: this.props.commentActive })}
              </div>
            ) : null}
            {/*
             // @ts-ignore TS2339 */}
            {this.props.watchPartyMode && this.props.watchPartyType == 'closeType' ? (
              <div className="player-controls-row top-controls">
                <Timelimit />
                <AudienceButton />
              </div>
            ) : null}
            <div
              className={classnames('player-controls-row bottom-controls', {
                // @ts-ignore TS2339
                hoverProgress: this.state.hovered === 'progressbar',
                // @ts-ignore TS2339
                hoverButtons: !!tooltip || this.state.hovered == 'volume-bar',
              })}
              // @ts-ignore TS2339
              ref={elem => (this.controlButtonsElement = elem)}
            >
              {/*
               // @ts-ignore TS2339 */}
              {this.props.metadata &&
              // @ts-ignore TS2339
              this.props.metadata.id &&
              // @ts-ignore TS2339
              !this.props.metadata.isDeliveryStarted() &&
              !browserInfo.isSmartPhone &&
              // @ts-ignore TS2339
              !this.props.playStartCompleted ? (
                // @ts-ignore TS2339
                this.props.metadata.liveStartMessage ? (
                  <div className="announce-row">
                    <div className="announce">
                      {/*
                       // @ts-ignore TS2339 */}
                      <div className="announce-message">{this.props.metadata.liveStartMessage}</div>
                    </div>
                  </div>
                ) : (
                  <div className="announce-row">
                    <div className="announce">
                      {/*
                       // @ts-ignore TS2339 */}
                      <div className="announce-message">{this.props.metadata.deliveryStartAt}から配信予定</div>
                    </div>
                  </div>
                )
              ) : null}
              {/*
               // @ts-ignore TS2339 */}
              {this.props.metadata && !this.props.upnextView ? (
                <div className="metadata-info-row">
                  <div id="" className="ellipsized">
                    {/*
                     // @ts-ignore TS2339 */}
                    <h4 className="ellipsized">{this.props.metadata.title}</h4>
                    {/*
                     // @ts-ignore TS2339 */}
                    {this.props.metadata.playableTitle &&
                    // @ts-ignore TS2339
                    this.props.metadata.title !== this.props.metadata.playableTitle ? (
                      // @ts-ignore TS2339
                      <span className="ellipsized">{this.props.metadata.playableTitle}</span>
                    ) : null}
                    {/*
                     // @ts-ignore TS2339 */}
                    {this.props.metadata.synopsis ? (
                      // @ts-ignore TS2339
                      <span className="synopsis ellipsized">{this.props.metadata.synopsis}</span>
                    ) : null}
                    {/*
                     // @ts-ignore TS2339 */}
                    {this.props.metadata.productionYear ||
                    // @ts-ignore TS2339
                    this.props.metadata.rating ||
                    // @ts-ignore TS2339
                    this.props.showMetadataDetail ? (
                      <div className="meta">
                        {/*
                         // @ts-ignore TS2339 */}
                        {!!this.props.metadata.productionYear ? (
                          // @ts-ignore TS2339
                          <span className="year">{this.props.metadata.productionYear}</span>
                        ) : null}
                        {/*
                         // @ts-ignore TS2339 */}
                        {ratingText ? (
                          <span className="maturity-rating">
                            <span className="maturity-number">{ratingText}</span>
                          </span>
                        ) : null}
                        {/*
                         // @ts-ignore TS2339 */}
                        {this.props.showMetadataDetail ? (
                          // @ts-ignore TS2339
                          <a className="link" onClick={this.props.showMetadataDetail}>
                            詳細
                          </a>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {/*
               // @ts-ignore TS2339 */}
              {isWpUser ? null : this.props.player &&
                // @ts-ignore TS2339
                (this.props.player.localPlayer() || (this.props.isSelfGCasted && this.props.isGCastMediaSessoin)) &&
                // @ts-ignore TS2339
                this.props.enableSeek ? (
                <div className="progress-controls-row">
                  <ProgressBar
                    // @ts-ignore TS2339
                    ref={this.progressBarRef}
                    onScrubStart={this.onScrubStart}
                    onScrubEnd={this.onScrubEnd}
                    // @ts-ignore TS2339
                    onMouseMove={this.props.onMouseMove}
                    // @ts-ignore TS2339
                    player={this.props.player}
                    isAdMode={isAdMode}
                    adDuration={adDuration}
                    currentAdTime={currentAdTime}
                    step={10}
                    // @ts-ignore TS2339
                    currentScrubTime={this.props.currentScrubTime}
                    // @ts-ignore TS2339
                    updateCurrentScrubTime={this.props.updateCurrentScrubTime}
                    isHidden={isHidden}
                    // @ts-ignore TS2339
                    mediaQueryCheck={this.props.mediaQueryCheck}
                    // @ts-ignore TS2339
                    watchSpMode={this.props.watchSpMode}
                  />
                </div>
              ) : null}
              <div className="button-controls-row">
                <div className="left-btn">
                  {isWpUser ? null : !browserInfo.isCarMonitor &&
                    // @ts-ignore TS2339
                    this.props.player &&
                    // @ts-ignore TS2339
                    (this.props.player.localPlayer() || this.props.player.isActiveGCasted()) &&
                    // @ts-ignore TS2339
                    this.props.playToggleButton !== 'ended' &&
                    // @ts-ignore TS2339
                    (this.props.enablePause || this.props.paused) ? (
                    <PlayButton
                      // @ts-ignore TS2339
                      ref={this.buttonRefs['play']}
                      // @ts-ignore TS2322
                      player={this.props.player}
                      onPointerEnter={this.onPointerEnter}
                      onPointerLeave={this.onPointerLeave}
                      startHover={this.startHover}
                      stopHover={this.stopHover}
                      releaseFocus={this.releaseFocus}
                      isHidden={isHidden}
                      // @ts-ignore TS2339
                      paused={this.props.paused}
                      // @ts-ignore TS2339
                      playToggleButton={this.props.playToggleButton}
                      // @ts-ignore TS2339
                      onClick={this.props.onClickPlay}
                    />
                  ) : null}
                  {/*
                   // @ts-ignore TS2339 */}
                  {isWpUser ? null : this.props.player &&
                    // @ts-ignore TS2339
                    (this.props.player.localPlayer() || this.props.isSelfGCasted) &&
                    // @ts-ignore TS2339
                    this.props.playToggleButton === 'ended' &&
                    // @ts-ignore TS2339
                    this.props.enablePause ? (
                    <ReloadButton
                      // @ts-ignore TS2339
                      ref={this.buttonRefs['reload']}
                      // @ts-ignore TS2322
                      player={this.props.player}
                      startHover={this.startHover}
                      stopHover={this.stopHover}
                      releaseFocus={this.releaseFocus}
                      isHidden={isHidden}
                      // @ts-ignore TS2339
                      onClick={this.props.onClickReload}
                    />
                  ) : null}
                  {isWpUser || isAdMode ? null : !browserInfo.isCarMonitor &&
                    // @ts-ignore TS2339
                    this.props.player &&
                    // @ts-ignore TS2339
                    (this.props.player.localPlayer() ||
                      // @ts-ignore TS2339
                      (this.props.isSelfGCasted && this.props.isGCastMediaSessoin)) &&
                    // @ts-ignore TS2339
                    this.props.enableSeek ? (
                    <StepButton
                      // @ts-ignore TS2339
                      ref={this.buttonRefs['step-back-10']}
                      // @ts-ignore TS2322
                      startHover={this.startHover}
                      stopHover={this.stopHover}
                      releaseFocus={this.releaseFocus}
                      isHidden={isHidden}
                      classes={{ fa: true, 'fa-replay-10': true }}
                      // @ts-ignore TS2339
                      onClick={this.props.onClickSeekBack}
                      step={-10}
                      label={'10秒戻る'}
                      // @ts-ignore TS2339
                      player={this.props.player}
                    />
                  ) : null}
                  {isWpUser || isAdMode ? null : !browserInfo.isCarMonitor &&
                    // @ts-ignore TS2339
                    this.props.player &&
                    // @ts-ignore TS2339
                    (this.props.player.localPlayer() ||
                      // @ts-ignore TS2339
                      (this.props.isSelfGCasted && this.props.isGCastMediaSessoin)) &&
                    // @ts-ignore TS2339
                    this.props.enableSeek ? (
                    <StepButton
                      // @ts-ignore TS2339
                      ref={this.buttonRefs['step-forward-10']}
                      // @ts-ignore TS2322
                      startHover={this.startHover}
                      stopHover={this.stopHover}
                      releaseFocus={this.releaseFocus}
                      isHidden={isHidden || isLiveEdge}
                      classes={{ fa: true, 'fa-forward-10': true }}
                      label={'10秒進む'}
                      step={10}
                      // @ts-ignore TS2339
                      onClick={this.props.onClickSeekForward}
                      // @ts-ignore TS2339
                      player={this.props.player}
                    />
                  ) : null}
                  {!(browserInfo.isIOS || browserInfo.isAndroid || browserInfo.isRequestDesktopWebsite) &&
                  // @ts-ignore TS2339
                  this.props.player &&
                  // @ts-ignore TS2339
                  (this.props.player.localPlayer() ||
                    // @ts-ignore TS2339
                    (this.props.isSelfGCasted && this.props.isGCastMediaSessoin)) ? (
                    <VolumeButton
                      // @ts-ignore TS2339
                      ref={this.buttonRefs['volume']}
                      // @ts-ignore TS2322
                      onClick={this.props.onClickVolume}
                      releaseFocus={this.releaseFocus}
                      // @ts-ignore TS2339
                      onMouseMove={this.props.onMouseMove}
                      // @ts-ignore TS2339
                      muted={this.props.muted}
                      // @ts-ignore TS2339
                      volume={this.props.volume}
                      step={0.05}
                      // @ts-ignore TS2339
                      isShowContorls={this.props.isActive}
                      startHover={this.startHover}
                      stopHover={this.stopHover}
                      // @ts-ignore TS2339
                      player={this.props.player}
                    />
                  ) : null}
                  {/*
                   // @ts-ignore TS2339 */}
                  {this.props.player &&
                  // @ts-ignore TS2339
                  (this.props.player.localPlayer() ||
                    // @ts-ignore TS2339
                    (this.props.isSelfGCasted && this.props.isGCastMediaSessoin)) &&
                  // @ts-ignore TS2339
                  this.props.enableSeek ? (
                    <TimeRemaining
                      // @ts-ignore TS2339
                      ref={this.buttonRefs['live-skip']}
                      // @ts-ignore TS2322
                      startHover={this.startHover}
                      stopHover={this.stopHover}
                      // @ts-ignore TS2339
                      player={this.props.player}
                      isHidden={isHidden}
                      isAdMode={isAdMode}
                      adDuration={adDuration}
                      currentAdTime={currentAdTime}
                    />
                  ) : null}
                </div>
                <div className="right-btn">
                  {/*
                   // @ts-ignore TS2339 */}
                  {isWpUser ? null : !this.props.nextEpisode &&
                    // @ts-ignore TS2339
                    !this.props.watchSpMode &&
                    // @ts-ignore TS2339
                    this.props.player &&
                    // @ts-ignore TS2339
                    (this.props.player.localPlayer() ||
                      // @ts-ignore TS2339
                      (this.props.isSelfGCasted && this.props.isGCastMediaSessoin)) &&
                    // @ts-ignore TS2339
                    this.props.onClickPlayNext ? (
                    <PlayNextButton
                      // @ts-ignore TS2339
                      ref={this.buttonRefs['play-next']}
                      // @ts-ignore TS2322
                      player={this.props.player}
                      startHover={this.startHover}
                      stopHover={this.stopHover}
                      releaseFocus={this.releaseFocus}
                      isHidden={isHidden}
                      // @ts-ignore TS2339
                      paused={this.props.paused}
                      // @ts-ignore TS2339
                      label={this.props.playNextButtonLabel}
                      // @ts-ignore TS2339
                      onClick={this.props.onClickPlayNext}
                    />
                  ) : null}
                  {/*
                   // @ts-ignore TS2339 */}
                  {this.props.player &&
                  // @ts-ignore TS2339
                  this.props.player.localPlayer() &&
                  // @ts-ignore TS2339
                  this.props.watchPartyMode &&
                  // @ts-ignore TS2339
                  (this.props.watchPartyType === 'closeType' ||
                    // @ts-ignore TS2339
                    (this.props.watchPartyType === 'openType' && this.props.isFullScreen)) ? (
                    <CommentButton
                      // @ts-ignore TS2339
                      ref={this.buttonRefs['comment']}
                      // @ts-ignore TS2322
                      onClick={this.props.onClickCommentButton}
                      startHover={this.startHover}
                      stopHover={this.stopHover}
                      // @ts-ignore TS2339
                      player={this.props.player}
                      // @ts-ignore TS2339
                      commentActive={this.props.commentActive}
                    />
                  ) : null}
                  {/*
                   // @ts-ignore TS2339 */}
                  {this.props.player &&
                  // @ts-ignore TS2339
                  (this.props.player.localPlayer() ||
                    // @ts-ignore TS2339
                    (this.props.isSelfGCasted && this.props.isGCastMediaSessoin)) ? (
                    <React.Fragment>
                      {!shouldHideAudioTextSettingBtn && (
                        <AudioTextSettingsButton
                          // @ts-ignore TS2339
                          ref={this.buttonRefs['audioTextSettings']}
                          // @ts-ignore TS2322
                          onClick={this.onClickAudioTextSettings}
                          releaseFocus={this.releaseFocus}
                          // @ts-ignore TS2339
                          showSettings={this.state.showAudioTextSettings}
                          startHover={this.startHover}
                          stopHover={this.stopHover}
                          // @ts-ignore TS2339
                          player={this.props.player}
                        />
                      )}
                      {/*  @ts-ignore TS2339 */}
                      {!shouldHideSettingBtn && !isAdMode && (
                        <SettingsButton
                          // @ts-ignore TS2339
                          ref={this.buttonRefs['settings']}
                          // @ts-ignore TS2322
                          onClick={this.onClickSettings}
                          releaseFocus={this.releaseFocus}
                          // @ts-ignore TS2339
                          showSettings={this.state.showSettings}
                          startHover={this.startHover}
                          stopHover={this.stopHover}
                          // @ts-ignore TS2339
                          player={this.props.player}
                        />
                      )}
                    </React.Fragment>
                  ) : null}
                  {/*
                   // @ts-ignore TS2339 */}
                  {((this.props.player &&
                    // @ts-ignore TS2339
                    this.props.player.localPlayer() &&
                    // @ts-ignore TS2339
                    this.props.player.isGCastAvailable()) ||
                    // @ts-ignore TS2339
                    this.props.player.isGCasted()) &&
                  // @ts-ignore TS2339
                  !(this.props.watchPartyMode && this.props.watchPartyType == 'closeType') ? (
                    <CastButton
                      // @ts-ignore TS2339
                      ref={this.buttonRefs['cast']}
                      // @ts-ignore TS2322
                      player={this.props.player}
                      // @ts-ignore TS2339
                      onClick={this.props.onClickCastButton}
                      releaseFocus={this.releaseFocus}
                      startHover={this.startHover}
                      stopHover={this.stopHover}
                      isHidden={isHidden}
                    />
                  ) : null}
                  {!(
                    browserInfo.isIOS ||
                    browserInfo.isAndroid ||
                    browserInfo.isRequestDesktopWebsite ||
                    browserInfo.isCarMonitor
                  ) &&
                    // @ts-ignore TS2339
                    this.props.player &&
                    // @ts-ignore TS2339
                    this.props.player.localPlayer() &&
                    // @ts-ignore TS2339
                    this.props.canPip &&
                    // @ts-ignore TS2339
                    !isAdMode && (
                      <PictureInPictureButton
                        // @ts-ignore TS2339
                        ref={this.buttonRefs['pip']}
                        // @ts-ignore TS2322
                        isInPictureInPicture={this.props.isInPictureInPicture}
                        releaseFocus={this.releaseFocus}
                        startHover={this.startHover}
                        stopHover={this.stopHover}
                        // @ts-ignore TS2339
                        onClick={this.props.togglePictureInPicture}
                      />
                    )}
                  {/*
                   // @ts-ignore TS2339 */}
                  {//　スマホ広告再生の場合にfullscreen表示させない
                  !(isAdMode && (browserInfo.isIOS || browserInfo.isAndroid || browserInfo.isRequestDesktopWebsite)) &&
                  // @ts-ignore TS2339
                  this.props.player &&
                  // @ts-ignore TS2339
                  this.props.player.localPlayer() &&
                  // @ts-ignore TS2339
                  this.props.enableFullScreen ? (
                    <FullScreenButton
                      // @ts-ignore TS2339
                      ref={this.buttonRefs['fullscreen']}
                      // @ts-ignore TS2322
                      isFullScreen={this.props.isFullScreen}
                      releaseFocus={this.releaseFocus}
                      startHover={this.startHover}
                      stopHover={this.stopHover}
                      // @ts-ignore TS2339
                      onClick={this.props.toggleFullScreen}
                    />
                  ) : null}
                </div>
                {/*
                <div className="touchable player-control-element">
                  <button type="button" className="touchable player-control-element player-button-control default-control-button player-control-element-blurred" tabIndex="0" role="button" aria-label="次のエピソード">
                  </button>
                  <div className="touchable popup-content-wrapper keep-right"></div>
                </div>
*/}
                <div className="player-control-spacer-element"></div>
                {/*
                <div className="touchable player-control-element">
                  <button type="button" className="touchable player-control-element player-button-control default-control-button player-control-element-blurred" tabIndex="0" role="button" aria-label="他のエピソード">
                  </button>
                  <div className="touchable popup-content-wrapper keep-right"></div>
                </div>
*/}

                {tooltip && typeof tooltip === 'string' ? (
                  // @ts-ignore TS2339
                  <div className="tooltip tooltip-text-only" ref={this.tooltipRef}>
                    <div className="tooltip-text">{tooltip}</div>
                  </div>
                ) : tooltip ? (
                  // @ts-ignore TS2339
                  <div className={classnames('tooltip', this.state.hovered)} ref={this.tooltipRef}>
                    {tooltip}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WodPlayerControls;
