'use strict';

import _ from 'src/libs/util';
import cookieDough from 'cookie-dough';
import namespace from './namespace';

const context = namespace.appContext;
export default {
  getModelData: function(key, subkey, subkeyData) {
    if (context) {
      return context.getModelData.apply(context, arguments);
    }
    var model = _.get(namespace, ['reactContext', 'models', key, 'data']);
    return subkey ? _.get(model, subkey, subkeyData) : model;
  },
  getModels: function() {
    return context ? context.getModels() : _.get(namespace, ['reactContext', 'models']);
  },
  getAB: function() {
    return context ? context.getAB() : this.getModelData('ab') || {};
  },
  getCookieDough: function() {
    // @ts-ignore TS2554
    return context ? context.getCookieDough() : new cookieDough();
  },
  shiftFlashMessage: function() {
    if (context) {
      return context.shiftFlashMessage.apply(context);
    }
    return _.get(namespace, ['reactContext', 'models', 'flashMessages', 'data']);
  },
};
