import datetime from 'src/libs/datetime';

/**追っかけ再生を表示するかどうか */
export const getShowChasingPlayBack = function(meta = {}) {
  //　ライブ以外
  // @ts-ignore TS2339
  if (meta.schemaId != 4) return false;

  // 追っかけ再生（chasingPlayback）ではない
  // @ts-ignore TS2339
  if (!meta.chasingPlayback) return false;
  // @ts-ignore TS2339
  const cardInfo = meta.cardInfo || {};

  // 追っかけ再生あり→ライブ終了後取る
  const now = datetime();
  const broadcastStartAt = cardInfo.broadcastStartAt ? datetime(cardInfo.broadcastStartAt, 'YYYY/MM/DD HH:mm:ss') : null;
  const broadcastEndAt = cardInfo.broadcastEndAt ? datetime(cardInfo.broadcastEndAt, 'YYYY/MM/DD HH:mm:ss') : null;

  let playbackBadgeCheck = false;
  // 追っかけ再生あり表示ルール
  //// ライブ開始時刻なし && あり/なし = しない
  if (broadcastStartAt && broadcastEndAt) {
    if (now.isBefore(broadcastEndAt)) {
      // ライブ開始時刻あり && 終了時刻（現在<ライブ終了時刻） = する
      playbackBadgeCheck = true;
    }
  } else if (broadcastStartAt) {
    // ライブ開始時刻あり && 終了時刻なし = する
    playbackBadgeCheck = true;
  }
  return playbackBadgeCheck;
};

export const getIsLiveOnAir = function(_broadcastStartAt, _broadcastEndAt) {
  let isOnAir = false;
  const broadcastStartAt = _broadcastStartAt ? datetime(_broadcastStartAt) : null;
  const broadcastEndAt = _broadcastEndAt ? datetime(_broadcastEndAt) : null;
  const now = datetime();

  if (broadcastStartAt && broadcastEndAt) {
    isOnAir = broadcastStartAt.isBefore(now) && broadcastEndAt.isSameOrAfter(now);
  } else if (broadcastStartAt) {
    isOnAir = broadcastStartAt.isBefore(now);
  } else if (broadcastEndAt) {
    isOnAir = broadcastEndAt.isSameOrAfter(now);
  } else {
    isOnAir = true;
  }
  return isOnAir;
};