import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import layoutContext from '../../common/hocs/layoutContext';
import inNode from '../../../sketch-platform/ui/utils/inNode';

import routes from '../../common/routes';
import { provideRoutingContext as routing } from '../../../sketch-platform/ui/routing';

import ErrorBoundary from '../../common/components/ErrorBoundary';

import isKidsProfile from '../../../utils/isKidsProfile';
import connectToApps from '../components/connectToApps';

class BaseRouteHandler extends React.PureComponent {
  static getPathOptions = function(models, props) {
    return {
      isKidsPage: this.isKidsPage(props.routeHandler),
    };
  };

  static get contextTypes() {
    return {
      getModelData: PropTypes.func.isRequired,
      history: PropTypes.object,
      models: PropTypes.object,
      routeHandler: PropTypes.object.isRequired,
    };
  }

  static get childContextTypes() {
    return {
      isRtl: PropTypes.bool,
      isKidsProfile: PropTypes.bool,
      isKidsPage: PropTypes.bool,
      playerApp: PropTypes.object,
      authApp: PropTypes.object,
      gtmApp: PropTypes.object,
      fsApp: PropTypes.object,
      watchPartyApp: PropTypes.object,
      discoveryApp: PropTypes.object,
      isIframe: PropTypes.bool,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.mainRef = React.createRef();
    this._handleTouchStart = this._handleTouchStart.bind(this);

    this.state = {
      profileGateAppState: {
        activeView: _.get(this.context.models, 'profileGateState.data', ''),
      },
      isAllowedToRender: true,
      isInitialRender: false,
      initializedCL: false,
      is4KSupported: false,
      isHDRSupported: false,
      inLowPowerMode: false,
    };
  }

  /**
   * サーバサイドで取得できない情報を設定
   */
  setBrowserInfo() {
    if (_.get(this.context.models, 'browserInfo.data')) {
      // iPhone iPad にて デスクトップ用Webサイトを表示 を利用した場合
      this.context.models.browserInfo.data.isRequestDesktopWebsite =
        navigator.userAgent.includes('Macintosh') && 'ontouchend' in document;
    }
  }

  getChildContext() {
    return {
      isRtl: false,
      isKidsProfile: isKidsProfile(this.context.models),
      isKidsPage: this.isKidsPage(),
      // @ts-ignore TS2339
      playerApp: this.props.playerApp,
      // @ts-ignore TS2339
      authApp: this.props.authApp,
      // @ts-ignore TS2339
      gtmApp: this.props.gtmApp,
      // @ts-ignore TS2339
      fsApp: this.props.fsApp,
      // @ts-ignore TS2339
      watchPartyApp: this.props.watchPartyApp,
      isIframe: _.get(this.props, 'routeHandler.query.mode') === 'iframe',
    };
  }

  componentDidMount() {
    this.setBrowserInfo();
    // @ts-ignore TS2339
    if (this.props.fsApp) {
      // @ts-ignore TS2339
      this.props.fsApp.init();
    }
    setTimeout(() => {
      // 本来ここではなく各コンポーネントで初期化されるべきだが漏れがあると嫌なので
      // 初期化されていない場合はここでおこなう
      // didmountでやらない場合もあるので、web apiに投げることで回避する。
      // @ts-ignore TS2339
      if (!this.props.gtmApp.initialized) {
        // @ts-ignore TS2339
        this.props.gtmApp.pageView();
      }
    }, 0);
  }

  componentWillReceiveProps(nextProps) {
    const userInfo = _.get(this.context.models, 'userInfo.data');
    const isKidsPage = this.isKidsPage();
    const isSharedPage = this.isSharedPage();
    // @ts-ignore TS2339
    if (this.state.isInitialRender) {
      this.setState({ isInitialRender: false });
    }
    if (userInfo && userInfo.isKids && !isKidsPage && !isSharedPage) {
      this.setState({ isAllowedToRender: false });
      // @ts-ignore TS2339
      this.context.history.push(routes.kidsHome.makePath());
    } else if (userInfo && !userInfo.isKids && isKidsPage) {
      this.setState({ isAllowedToRender: false });
      // @ts-ignore TS2554
      this.context.history.push(routes.browse.makePath());
    } else {
      this.setState({ isAllowedToRender: true });
    }
  }

  componentWillUnmount() {
    // eventBus.removeListener("enter-lp", this.enterLowPowerMode),
    // eventBus.removeListener("exit-lp", this.exitLowPowerMode),
    if (!inNode && window.PointerEvent) {
      // @ts-ignore TS2339
      var main = this.mainRef.current;
      if (main) {
        main.removeEventListener('pointerenter', this._handleTouchStart);
      }
    }
    // @ts-ignore TS2339
    if (this.props.fsApp) {
      // @ts-ignore TS2339
      this.props.fsApp.reset();
    }
  }

  isSharedPage() {
    return false;
  }

  static isKidsPage = function(routeHandler) {
    return (
      [
        // @ts-ignore TS2339
        routes.kidsHome,
        // @ts-ignore TS2339
        routes.kidsCategory,
        // @ts-ignore TS2339
        routes.kidsTitle,
        // @ts-ignore TS2339
        routes.kidsSearch,
        // @ts-ignore TS2339
        routes.kidsSearchTerm,
        // @ts-ignore TS2339
        routes.kidsSimilars,
        // @ts-ignore TS2339
        routes.kidsSearchSuggestion,
        // @ts-ignore TS2339
        routes.kidsOriginals,
      ].indexOf(routeHandler.route) > -1
    );
  };

  isKidsPage() {
    // @ts-ignore TS2339
    return this.constructor.isKidsPage(this.context.routeHandler);
  }

  static isIncrementalSearchPage = function(routeHandler) {
    return [routes.watchNow, routes.content, routes.simulcast].indexOf(routeHandler.route) === -1;
  };

  isIncrementalSearchPage() {
    // @ts-ignore TS2339
    return this.constructor.isIncrementalSearchPage(this.context.routeHandler);
  }

  isOverlayPage() {
    return false;
  }

  getCurrentUIView() {
    return '';
  }

  _handleTouchStart() {}

  render() {
    // @ts-ignore TS2339
    if (!this.state.isAllowedToRender) {
      return null;
    }

    return (
      // @ts-ignore TS2339
      <div ref={this.mainRef} lang="ja-JP" dir="ltr" onTouchStart={this._handleTouchStart}>
        <ErrorBoundary>
          {/*
           // @ts-ignore TS2769 */}
          {React.cloneElement(this.props.children, {
            // @ts-ignore TS2339
            isRefreshingApp: this.props.isRefreshingApp,
            // @ts-ignore TS2339
            isInitialRender: this.state.isInitialRender,
            // @ts-ignore TS2339
            is4KSupported: this.state.is4KSupported,
            // @ts-ignore TS2339
            isHDRSupported: this.state.isHDRSupported,
            isKidsPage: this.isKidsPage(),
            isIncrementalSearchPage: this.isIncrementalSearchPage(),
            isOverlayPage: this.isOverlayPage(),
            // @ts-ignore TS2339
            model: this.props.model,
            // @ts-ignore TS2339
            profileGateAppState: this.state.profileGateAppState,
            uiView: this.getCurrentUIView(),
          })}
        </ErrorBoundary>
      </div>
    );
  }
}

// @ts-ignore TS2554
let layout = connectToApps()(layoutContext(BaseRouteHandler));
if (process.env.BROWSER) {
  layout = routing()(layout);
}

export default layout;
