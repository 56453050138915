import _ from 'src/libs/util';
import cookieDough from 'cookie-dough';
import { KEYWORD_KEY } from '../constants/cookie';

const cookie = cookieDough();

export default {
  set: function(profileId, history = []) {
    if (typeof window !== 'undefined') {
      let histories = this.getAll();
      if (!histories) histories = {};
      if (!profileId) profileId = 'anonymous';
      histories[profileId] = _.take(_.uniq(history), 5);
      if (window.localStorage) {
        window.localStorage.setItem(KEYWORD_KEY, JSON.stringify(histories));
      } else if (window.navigator.cookieEnabled) {
        cookie.set(KEYWORD_KEY, JSON.stringify(histories), { path: '/' });
      }
    }
  },
  getAll: function() {
    try {
      if (typeof window !== 'undefined') {
        if (window.localStorage) {
          return JSON.parse(window.localStorage.getItem(KEYWORD_KEY));
        } else if (window.navigator.cookieEnabled) {
          return JSON.parse(cookie.get(KEYWORD_KEY));
        }
      }
    } catch (e) {
      return null;
    }
  },
  get: function(profileId) {
    let histories = this.getAll();
    if (!histories) return null;
    if (!profileId) profileId = 'anonymous';
    return histories[profileId];
  },
  add: function(profileId, keyword) {
    let history = this.get(profileId);
    if (!history) history = [];
    history.unshift(keyword);
    this.set(profileId, history);
  },
  has: function(profileId, keyword) {
    let history = this.get(profileId);
    if (!history) return false;
    return _.includes(history, keyword);
  },
  move: function(profileId) {
    let anonymousHistory = this.get();
    if (!anonymousHistory) return;
    let history = this.get(profileId);
    if (!history) history = [];
    anonymousHistory = anonymousHistory.concat(history);
    this.set(profileId, anonymousHistory);
    this.set();
  },
};
