import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import StreaksPlayer from './StreaksPlayer';
import PlayerSurface from './PlayerSurface';
import * as ERROR from '../../../../constants/error';
import activeProfile from '../../../../utils/activeProfile';

const BEACON_SPAN = 30;
const send = function(host, params, async = true) {
  const xhr = new XMLHttpRequest();
  const query = Object.keys(params)
    .map(function(key) {
      return key + '=' + encodeURIComponent(params[key]);
    })
    .join('&');
  xhr.open('GET', `${host}?${query}`, async);
  xhr.onreadystatechange = function() {
    if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
    }
  };
  xhr.send(null);
};

class WodPlayerCore extends React.PureComponent {
  static get contextTypes() {
    return {
      models: PropTypes.object,
      getModelData: PropTypes.func,
      playerApp: PropTypes.object,
      playerSession: PropTypes.object,
      staticConfiguration: PropTypes.object,
      toggleMode: PropTypes.func,
      mode: PropTypes.string,
    };
  }

  static get defaultProps() {
    return {
      minBitrate: 0,
    };
  }

  static get propTypes() {
    return {
      onLoadStart: PropTypes.func,
      onLoaded: PropTypes.func,
      onInterruptStart: PropTypes.func,
      onInterruptEnd: PropTypes.func,
      onSuspend: PropTypes.func,
      onReady: PropTypes.func,
      onError: PropTypes.func,
      onPlay: PropTypes.func,
      onPause: PropTypes.func,
      onPlaying: PropTypes.func,
      onFirstplay: PropTypes.func,
      onRebuffering: PropTypes.func,
      onTimeUpdate: PropTypes.func,
      onStateChange: PropTypes.func,
      onDurationChange: PropTypes.func,
      onPlaybackRateChange: PropTypes.func,
      onEnded: PropTypes.func,
      autoplayNextTitle: PropTypes.func,
      forcePlaybackEngine: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);

    this.handleWindowUnload = this.handleWindowUnload.bind(this);
    this.onReady = this.onReady.bind(this);
    this.onError = this.onError.bind(this);
    this.loadVideo = this.loadVideo.bind(this);
    this.dispose = this.dispose.bind(this);
    // @ts-ignore TS2339
    this.__pt = 0;
    // @ts-ignore TS2339
    this.__pFrom;
    // @ts-ignore TS2339
    this.__beaconIntervalId;
    // @ts-ignore TS2339
    this.playerRef = React.createRef();

    this.state = {
      audioTracks: [],
      textTracks: [],
      buffered: 0,
      currentPlayerTime: 0,
      destroyPlayer: false,
      duration: 0,
      enableFocusStyle: false,
      ended: false,
      error: null,
      fallbackMode: false,
      fastForward: false,
      loaded: false,
      loading: false,
      metadataloaded: false,
      milestone: 0,
      playStartCompleted: false,
      playbackTimedOut: false,
      player: null,
      postPlayTriggered: false,
      ready: false,
      rebuffering: false,
      rewoundOnEnd: false,
      seeking: false,
      selectedAudioTrack: null,
      selectedTextTrack: null,
      stalled: false,
      timedTextBounds: {},
      timedTracksLoaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('unload', this.handleWindowUnload, false);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // @ts-ignore TS2339
    if (nextProps.paused !== this.props.paused) {
      // @ts-ignore TS2339
      if (this.state.player) {
        if (!nextProps.paused) {
          // console.log('WodPlayerCore: play 1')
          try {
            // @ts-ignore TS2339
            this.state.player.play();
          } catch (e) {
            console.log(e);
          }
        } else {
          // console.log('WodPlayerCore: pause 1')
          try {
            // @ts-ignore TS2339
            this.state.player.pause();
          } catch (e) {
            console.log(e);
          }
        }
      }
    }

    // @ts-ignore TS2339
    if (nextProps.playbackRate != this.props.playbackRate) {
      // @ts-ignore TS2339
      if (this.state.player) {
        // @ts-ignore TS2339
        this.state.player.playbackRate(nextProps.playbackRate);
      }
    }

    // @ts-ignore TS2339
    if (nextProps.volume != this.props.volume || nextProps.muted != this.props.muted) {
      if (nextProps.muted === true) {
        // @ts-ignore TS2339
        if (this.state.player) this.state.player.volume(0);
      } else {
        // @ts-ignore TS2339
        if (this.state.player) this.state.player.volume(nextProps.volume);
      }
    }

    const newState = {};
    // @ts-ignore TS2339
    if (nextProps.loaded != this.state.loaded) {
      // @ts-ignore TS2339
      newState.loaded = nextProps.loaded;
    }
    // @ts-ignore TS2339
    if (nextProps.playStartCompleted != this.state.playStartCompleted) {
      // @ts-ignore TS2339
      newState.playStartCompleted = nextProps.playStartCompleted;
    }
    // @ts-ignore TS2339
    if (nextProps.ended != this.state.ended) {
      // @ts-ignore TS2339
      newState.ended = nextProps.ended;
    }
    this.setState(newState);
  }

  componentWillUpdate(props, state) {
    // @ts-ignore TS2339
    if (this.state.playStartCompleted != state.playStartCompleted) {
      if (state.playStartCompleted) {
        this.trackEvent('firstplay');
      }
    }
  }

  componentDidUpdate(beforeProps, beforeState) {
    // @ts-ignore TS2339
    if (this.state.playStartCompleted != beforeState.playStartCompleted) {
      // @ts-ignore TS2339
      if (this.state.player && this.state.playStartCompleted) {
        // 再生速度の指定
        // @ts-ignore TS2339
        if (this.state.player.playbackRate() != this.props.playbackRate) {
          // @ts-ignore TS2339
          this.state.player.playbackRate(this.props.playbackRate);
        }
      }
    }
    // @ts-ignore TS2339
    if (beforeProps.minBitrate !== this.props.minBitrate || beforeProps.maxBitrate !== this.props.maxBitrate) {
      // @ts-ignore TS2339
      if (this.state.player) {
        // @ts-ignore TS2339
        if (typeof this.state.player.qualityLevels === 'function') {
          // @ts-ignore TS2339
          const qualityLevels = this.state.player.qualityLevels();
          _.forEach(qualityLevels, (qualityLevel, index) => {
            // @ts-ignore TS2339
            if (this.props.minBitrate <= qualityLevel.bitrate) {
              // @ts-ignore TS2339
              if (this.props.maxBitrate === undefined || qualityLevel.bitrate <= this.props.maxBitrate) {
                if (typeof qualityLevel.height === 'undefined' || qualityLevel.height <= 720) {
                  qualityLevel.enabled = true;
                } else {
                  qualityLevel.enabled = false;
                }
              } else {
                qualityLevel.enabled = false;
              }
            } else {
              qualityLevel.enabled = false;
            }
          });
          // @ts-ignore TS2339
        } else if (typeof this.state.player.options === 'function') {
          // console.log(this.props.minBitrate, this.props.maxBitrate)
          // @ts-ignore TS2339
          this.state.player.options({
            abr: {
              // @ts-ignore TS2339
              preferredMaximumBitrate: this.props.maxBitrate,
              // @ts-ignore TS2339
              preferredMinimumBitrate: this.props.minBitrate,
            },
          });
        }
      }
    }

    // @ts-ignore TS2339
    if (beforeState.currentPlayerTime !== this.state.currentPlayerTime) {
      // milestoneの計算を行う
      // @ts-ignore TS2339
      if (this.state.duration && this.state.duration !== Infinity) {
        // @ts-ignore TS2339
        const milestone = this.state.currentPlayerTime / this.state.duration;
        // @ts-ignore TS2339
        if (this.state.milestone < milestone) {
          // @ts-ignore TS2339
          if (milestone >= 0.25 && this.state.milestone < 0.25) {
            // @ts-ignore TS2339
            this.state.milestone = 0.25;
            this.trackEvent('milestone', { percentage: '25' });
          }
          // @ts-ignore TS2339
          if (milestone >= 0.5 && this.state.milestone < 0.5) {
            // @ts-ignore TS2339
            this.state.milestone = 0.5;
            this.trackEvent('milestone', { percentage: '50' });
          }
          // @ts-ignore TS2339
          if (milestone >= 0.75 && this.state.milestone < 0.75) {
            // @ts-ignore TS2339
            this.state.milestone = 0.75;
            this.trackEvent('milestone', { percentage: '75' });
          }
          // @ts-ignore TS2339
          if (milestone >= 0.9 && this.state.milestone < 0.9) {
            // @ts-ignore TS2339
            this.state.milestone = 0.9;
            this.trackEvent('milestone', { percentage: '90' });
          }
        }
      }
    }

    // @ts-ignore TS2339
    if (!this.context.playerApp && beforeProps.isFullScreen !== this.props.isFullScreen) {
      // @ts-ignore TS2339
      if (this.props.isFullScreen) {
        this.trackEvent('fullscreen');
      } else {
        this.trackEvent('exitFullscreen');
      }
    }

    // @ts-ignore TS2339
    if (beforeState.seeking !== this.state.seeking) {
      // @ts-ignore TS2339
      if (this.state.seeking) {
        this.trackEvent('seekStart');
      } else {
        this.trackEvent('seekEnd');
      }
    }

    // @ts-ignore TS2339
    if (!this.context.playerApp && this.props.onStateChange) {
      const newState = _.pick(
        this.state,
        'audioTracks',
        'textTracks',
        'paused',
        'buffered',
        'ended',
        'loaded',
        'loading',
        'metadataloaded',
        'playStartCompleted',
        'ready',
        'stalled',
        'rebuffering',
        'seeking',
      );
      // @ts-ignore TS2339
      if (this.props.paused) {
        // @ts-ignore TS2339
        newState.paused = this.props.paused;
      }
      // @ts-ignore TS2339
      this.props.onStateChange(newState);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('unload', this.handleWindowUnload, false);
    try {
      // @ts-ignore TS2339
      if (ReactDOM.findDOMNode(this).classList) {
        // @ts-ignore TS2339
        ReactDOM.findDOMNode(this).classList.add('prp-closing');
      }
    } catch (e) {
      console.error(e);
    }
    this.dispose();
  }

  handleWindowUnload() {
    // @ts-ignore TS2339
    this.__unloading = true;
    this.dispose();
  }

  onReady(player) {
    if (typeof player.qualityLevels === 'function') {
      const qualityLevels = player.qualityLevels();
      qualityLevels.on('addqualitylevel', e => {
        // @ts-ignore TS2339
        if (this.props.minBitrate <= e.qualityLevel.bitrate) {
          // @ts-ignore TS2339
          if (this.props.maxBitrate === undefined || e.qualityLevel.bitrate <= this.props.maxBitrate) {
            if (typeof e.qualityLevel.height === 'undefined' || e.qualityLevel.height <= 720) {
              e.qualityLevel.enabled = true;
            } else {
              e.qualityLevel.enabled = false;
            }
          } else {
            e.qualityLevel.enabled = false;
          }
        } else {
          e.qualityLevel.enabled = false;
        }
      });
    }

    // @ts-ignore TS2339
    player = player || _.get(this.playerRef, 'current.player');
    this.setState({ ready: true, player });

    // ボリュームの指定
    // @ts-ignore TS2339
    player.volume(this.props.muted ? 0 : this.props.volume);

    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2339
    if (browserInfo.isSafari && parseInt(browserInfo.major, 10) <= 10 && !this.props.paused) {
      // console.log('WodPlayerCore: play 2')
      player.play();
    }

    if (this.context.playerApp) {
      // @ts-ignore TS2339
      this.context.playerApp.setLocalPlayer(player, { paused: this.props.paused });
      // @ts-ignore TS2339
      if (this.props.onReady) {
        // @ts-ignore TS2339
        this.props.onReady(player);
      }
      return;
      // @ts-ignore TS2339
    } else if (this.props.onReady) {
      // @ts-ignore TS2339
      this.props.onReady(player);
    }

    let __loaded = false,
      __started = false;
    const triggerLoadEvent = () => {
      if (!__loaded) {
        // @ts-ignore TS2339
        if (this.props.onLoaded) {
          // @ts-ignore TS2339
          let ret = this.props.onLoaded();
          if (ret !== false) {
            __loaded = true;
          }
        } else {
          __loaded = true;
        }
        this.setState({ loaded: __loaded });
        // @ts-ignore TS2339
        if (__loaded && !this.props.paused) {
          // console.log('WodPlayerCore: play 3')
          player.play();
        }
      }
    };

    player.on('loadstart', () => {
      // @ts-ignore TS2339
      if (this.props.onLoadStart) {
        // @ts-ignore TS2339
        this.props.onLoadStart();
      }
    });

    player.on('pause', () => {
      // @ts-ignore TS2339
      if (this.playReload) {
        return;
      }
      this.stopBeacon();
      const newState = {
        paused: true,
      };
      // @ts-ignore TS2339
      if (typeof this.__pFrom !== 'undefined') {
        // @ts-ignore TS2345
        let addPt = parseInt((new Date().getTime() - this.__pFrom) / 1000, 10);
        if (!isNaN(addPt)) {
          // @ts-ignore TS2339
          this.__pt += addPt;
        }
        // @ts-ignore TS2339
        newState.pt = this.__pt;
        // @ts-ignore TS2339
        this.trackEvent('pause', { pt: parseInt(this.__pt, 10) });
      }
      this.setState(newState);
      // @ts-ignore TS2339
      this.__pFrom = undefined;

      // @ts-ignore TS2339
      if (this.props.onPause) {
        // @ts-ignore TS2339
        this.props.onPause();
      }
    });
    player.on('play', () => {
      // @ts-ignore TS2339
      if (this.playReload) {
        return;
      }
      if (!__started) {
        __started = true;
        this.setState({ playStartCompleted: true });
      }
      // @ts-ignore TS2339
      this.__pFrom = new Date().getTime();
      this.setState({ paused: false, ended: false }, () => {
        this.checkVideo(res => {
          if (res.result === true) return;
          if (res.error === 'invalid_grant') {
            window.location.reload();
          }
        });
      });
      // @ts-ignore TS2339
      this.trackEvent('play', { pt: parseInt(this.__pt, 10) });
      this.startBeacon();
      // @ts-ignore TS2339
      if (this.props.onPlay) {
        // @ts-ignore TS2339
        this.props.onPlay();
      }
    });
    player.on('firstplay', () => {
      // console.log('firstplay');
    });
    player.on('playing', () => {
      // console.log('playing');
      // @ts-ignore TS2339
      this.seekToEnded = false;
      // @ts-ignore TS2339
      if (!this.__pFrom) {
        // @ts-ignore TS2339
        this.__pFrom = new Date().getTime();
      }
      // @ts-ignore TS2339
      if (this.props.onPlaying) {
        // @ts-ignore TS2339
        this.props.onPlaying();
      }
    });
    player.on('loadedmetadata', () => {
      this.setState({ metadataloaded: true });
    });
    player.on('loadeddata', () => {
      this.setState({ loaded: true });
    });
    player.on('ended', () => {
      // console.log('ended');
      // @ts-ignore TS2339
      if (this.playReload) {
        player.currentTime(0);
        return;
      }
      this.stopBeacon();
      const newState = { ended: true };
      // @ts-ignore TS2339
      if (typeof this.__pFrom !== 'undefined') {
        // @ts-ignore TS2345
        let addPt = parseInt((new Date().getTime() - this.__pFrom) / 1000, 10);
        if (!isNaN(addPt)) {
          // @ts-ignore TS2339
          this.__pt += addPt;
        }
        // @ts-ignore TS2339
        newState.pt = this.__pt;
        // @ts-ignore TS2339
        this.trackEvent('ended', { pt: parseInt(this.__pt, 10) });
      } else {
        this.trackEvent('ended');
      }
      // @ts-ignore TS2339
      this.endedTimmer = setTimeout(() => {
        // @ts-ignore TS2339
        if (this.endedTimmer) clearTimeout(this.endedTimmer);
        // @ts-ignore TS2339
        delete this.endedTimmer;
      }, 100);
      this.setState(newState);
      // @ts-ignore TS2339
      this.__pFrom = undefined;
      // @ts-ignore TS2339
      if (this.props.onEnded) {
        // @ts-ignore TS2339
        this.props.onEnded();
      }
    });
    player.on('error', () => {
      // console.log('error');
    });
    player.one('durationchange', () => {
      this.setState({ duration: player.duration() });
      if (player.duration() === Infinity) {
        triggerLoadEvent();
      }
      // @ts-ignore TS2339
      if (this.props.onDurationChange) {
        // @ts-ignore TS2339
        this.props.onDurationChange(player.duration());
      }
    });
    player.on('ratechange', () => {
      const playbackRate = player.playbackRate();
      if (playbackRate === 0) return;
      // @ts-ignore TS2339
      if (this.props.onPlaybackRateChange) {
        // @ts-ignore TS2339
        this.props.onPlaybackRateChange(playbackRate);
      }
    });
    player.on('volumechange', () => {
      const volume = player.volume();
      this.setState({ volume });
      // @ts-ignore TS2339
      if (this.props.onVolumeChange) {
        // @ts-ignore TS2339
        this.props.onVolumeChange(volume);
      }
    });
    player.on('stalled', () => {
      this.setState({ stalled: true, canplaythrough: false });
    });
    player.on('emptied', () => {
      this.setState({ emptied: true, canplaythrough: false });
    });
    player.on('suspend', () => {
      this.setState({ emptied: true, canplaythrough: false }, () => {
        // @ts-ignore TS2339
        if (this.props.onSuspend) {
          // @ts-ignore TS2339
          this.props.onSuspend();
        }
      });
    });
    player.on('waiting', () => {
      this.setState({ rebuffering: true, canplaythrough: false });
      // @ts-ignore TS2339
      if (this.props.onRebuffering) {
        // @ts-ignore TS2339
        this.props.onRebuffering();
      }
    });
    player.on('playing', () => {
      const newState = {
        loading: false,
        rebuffering: false,
        stalled: false,
        emptied: false,
      };
      // @ts-ignore TS2339
      if (!this.props.paused) {
        // @ts-ignore TS2339
        newState.paused = false;
      }
      // @ts-ignore TS2554
      this.setState();
    });
    player.on('canplay', () => {
      this.setState({ rebuffering: false });
      triggerLoadEvent();
    });
    player.on('seeked', () => {
      // @ts-ignore TS2339
      this.seekToEnded = true;
      this.setState({ seeking: false, rebuffering: false }, () => {
        // @ts-ignore TS2339
        if (this.playReload) {
          if (player.currentTime() == 0) {
            // @ts-ignore TS2339
            this.playReload = false;
          }
          setTimeout(() => {
            // console.log('WodPlayerCore: play 4')
            player.play();
          }, 100);
          // @ts-ignore TS2339
        } else if (!this.state.playStartCompleted) {
          if (!browserInfo.isSafari || parseInt(browserInfo.major, 10) <= 10) {
            // console.log('WodPlayerCore: play 5')
            player.play();
          }
        }
      });
    });
    player.on('seeking', () => {
      this.setState({ seeking: true, ended: false });
    });
    player.on('canplaythrough', () => {
      this.setState({ canplaythrough: true });
    });
    player.on('firstplay', () => {
      this.setState({ playStartCompleted: true });
      // @ts-ignore TS2339
      if (this.props.onFirstplay) {
        // @ts-ignore TS2339
        this.props.onFirstplay();
      }
    });
    player.on('loadeddata', () => {
      triggerLoadEvent();
    });
    player.on('timeupdate', () => {
      // @ts-ignore TS2339
      if (this.endedTimmer) return;
      this.setState({ duration: player.duration(), currentPlayerTime: player.currentTime() }, () => {
        // @ts-ignore TS2339
        if (this.props.onTimeUpdate) {
          // @ts-ignore TS2339
          this.props.onTimeUpdate(this.state.currentPlayerTime);
        }
      });
    });
  }

  onError(error) {
    this.stopBeacon();
    const newState = { paused: true, error };
    // @ts-ignore TS2339
    if (typeof this.__pFrom !== 'undefined') {
      // @ts-ignore TS2345
      let addPt = parseInt((new Date().getTime() - this.__pFrom) / 1000, 10);
      if (!isNaN(addPt)) {
        // @ts-ignore TS2339
        this.__pt += addPt;
      }
      // @ts-ignore TS2339
      newState.pt = this.__pt;
      // @ts-ignore TS2339
      this.trackEvent('error', { pt: parseInt(this.__pt, 10) });
    }
    this.setState(newState, () => {
      // @ts-ignore TS2339
      if (this.props.onError) {
        // @ts-ignore TS2339
        this.props.onError(error);
      }
    });
    // @ts-ignore TS2339
    this.__pFrom = undefined;
  }

  trackEvent(event, params = {}) {
    //console.log('Core: trackEvent', event, params);
    if (!event) return;

    // TODO: この値を毎回そもそも送る必要性があるのか疑問ではある
    params = Object.assign(
      {
        event,
      },
      params,
    );
    this.send('/track', params);
  }

  beacon(params = {}) {
    //console.log('Core: beacon', params);
    const analytics = _.get(this.props, 'activeVideo.analytics', {});
    // @ts-ignore TS2339
    if (analytics.psid) {
      // @ts-ignore TS2339
      params.psid = analytics.psid;
    }
    this.send('/beacon', params);
  }

  send(path, params = {}) {
    //console.log('Core: send', path, params);
    /**
     * device_type
     * 001=PC（Windows）
     * 002=PC（Mac）
     * 003=PC（その他）
     * 004=iOS
     * 005=Android
     * 006=Fire TVstick
     */
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    const metadata = _.get(this.props, 'activeVideo.metadata', {});
    // let device_type = '001';
    // if (browserInfo.isMac) {
    //   device_type == '002';
    // } else if (browserInfo.isWindows7 || browserInfo.isWindows8 || browserInfo.isWindows8_1 || browserInfo.isWindows10) {
    //   device_type == '001';
    // } else if (browserInfo.isIOS) {
    //   device_type == '004';
    // } else if (browserInfo.isAndroid) {
    //   device_type == '005';
    // } else if (!browserInfo.isMobile) {
    //   device_type == '001';
    // }

    params = Object.assign(
      {
        // resolution: window.screen.availWidth + 'x' + window.screen.availHeight,
        // screen_mode: (this.props.isFullScreen) ? '002' : '001',
        // video_type: '001', // video_type: isAdMode ? '002' : '001',
        // kids_flag: (profile && profile.isKids) ? '1' : '0',
        // device_type,
        // delivery_type: metadata.type === 'linear_channel' ? '004' : '001', // 001=vod,002=dic,003=live,004=linear
        ts: new Date().getTime(),
        // player_ver: '0.0.0',
        token: 'web_player',
        // playback_rate: this.props.playbackRate,
      },
      _.get(this.props, 'activeVideo.analytics', {}),
      params,
    );

    let currentTime = -1;
    // @ts-ignore TS2339
    const player = this.state.player;
    if (player) {
      const playback_rate = player.playbackRate();
      // if (params.delivery_type !== '004' && player.duration() === Infinity) {
      //   params.delivery_type = '003';
      // }
      // iPhoneの場合はplayerのfullscreenを参照
      // if (browserInfo.isIOS && !browserInfo.isIPad) {
      //   params.screen_mode = player.isFullscreen() ? '002' : '001';
      // }

      // TODO: エラーしたらcurrentTimeを取得することはできないため、この値は外部から取得すべきなきがする
      //
      if (typeof playback_rate === 'undefined') {
        currentTime = parseInt(player.currentTime(), 10);
        if (isNaN(currentTime)) {
          currentTime = -1;
        }
      }
    }
    if (currentTime === -1) {
      // @ts-ignore TS2339
      currentTime = this.state.currentPlayerTime;
    }
    // @ts-ignore TS2339
    params.s = parseInt(currentTime, 10);
    let trackService = Object.assign({}, this.context.getModelData('services', 'playbacktracking'));
    // @ts-ignore TS2339
    send(`${trackService.protocol}://${trackService.hostname}${path}`, params, !this.__unloading);
  }

  startBeacon() {
    // console.log('beacon::start');
    // @ts-ignore TS2339
    if (this.__beaconIntervalId) return;
    // @ts-ignore TS2339
    this.__beaconIntervalId = setInterval(() => {
      this.beacon();
    }, BEACON_SPAN * 1000);
  }

  stopBeacon() {
    // console.log('beacon::stop');
    // @ts-ignore TS2339
    if (this.__beaconIntervalId) {
      // @ts-ignore TS2339
      clearInterval(this.__beaconIntervalId);
      // @ts-ignore TS2339
      this.__beaconIntervalId = undefined;
    }
  }

  dispose() {
    //console.log('Core: dispose');
    const playbackService = Object.assign({}, this.context.getModelData('services', 'playback'));
    const authContext = this.context.getModelData('authContext');
    const analytics = _.get(this.props, 'activeVideo.analytics', {});
    const sessionId = _.get(this.props, 'activeVideo.session.id');

    if (this.context.playerApp) {
      const playbackSessionId = this.context.playerApp.getPlaybackSessionId(
        sessionId,
        analytics,
        // @ts-ignore TS2339
        this.props.playContext,
      );
      // @ts-ignore TS2339
      this.context.playerApp.dispose(playbackService, authContext, playbackSessionId, !this.__unloading);
      return;
    }

    this.stopBeacon();
    // @ts-ignore TS2339
    if (typeof this.__pFrom !== 'undefined') {
      // @ts-ignore TS2345
      let addPt = parseInt((new Date().getTime() - this.__pFrom) / 1000, 10);
      if (!isNaN(addPt)) {
        // @ts-ignore TS2339
        this.__pt += addPt;
      }
    }
    // @ts-ignore TS2339
    this.trackEvent('dispose', { pt: parseInt(this.__pt, 10) });
    // @ts-ignore TS2339
    this.__pt = 0;

    const xhr = new XMLHttpRequest();
    // @ts-ignore TS2339
    xhr.open('POST', `${playbackService.protocol}://${playbackService.hostname}/session/close`, !this.__unloading);
    if (authContext) {
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
    }
    if (sessionId) {
      xhr.setRequestHeader('X-Playback-Session-Id', sessionId);
      // @ts-ignore TS2339
    } else if (analytics.psid) {
      // @ts-ignore TS2339
      xhr.setRequestHeader('X-Playback-Session-Id', analytics.psid);
      // @ts-ignore TS2339
    } else if (this.props.playContext) {
      // @ts-ignore TS2339
      xhr.setRequestHeader('X-Playback-Session-Id', this.props.playContext.get('session').id);
    } else {
      return;
    }
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.withCredentials = true;
    xhr.send();
  }

  play() {
    // @ts-ignore TS2339
    if (this.state.player) {
      // console.log('WodPlayerCore: play 6')
      // @ts-ignore TS2339
      this.state.player.play();
    }
  }

  pause() {
    // @ts-ignore TS2339
    if (this.state.player) {
      // console.log('WodPlayerCore: pause 2')
      // @ts-ignore TS2339
      this.state.player.pause();
    }
  }

  reload() {
    // @ts-ignore TS2339
    this.playReload = true;
    // @ts-ignore TS2339
    if (this.seekToEnded) {
      // @ts-ignore TS2339
      this.state.player.currentTime(this.state.player.currentTime() - 1);
    } else {
      // @ts-ignore TS2339
      this.state.player.currentTime(0);
    }
  }

  loadVideo(handler) {
    // errorViewがあるときはloadしない
    // @ts-ignore TS2339
    if (this.props.errorView) {
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (!handler) return;
        if (xhr.status === 200 && xhr.response !== null) {
          let data = xhr.response;
          if (typeof data === 'string') {
            data = JSON.parse(data);
          }

          if (this.context.playerApp) {
            const track = _.find(data.tracks, track => track.kind === 'thumbnails');
            this.context.playerApp.setSeekThumbnailTrack(track);
          }
          // @ts-ignore TS2339
          if (this.props.setWillPlayAd) {
            // @ts-ignore TS2339
            this.props.setWillPlayAd(_.get(data, 'ads', []).length > 0);
          }
          handler(null, data);
        } else {
          // session/openのエラーはauthからやり直す
          // @ts-ignore TS2339
          if (this.props.onError) {
            // @ts-ignore TS2339
            this.props.onError(ERROR.SESSION_OPEN);
          } else {
            handler(xhr, null);
          }
        }
      }
    };

    if (this.context.playerApp) {
      this.context.playerApp.setSeekThumbnailTrack();
    }

    // @ts-ignore TS2339
    const authContext = this.props.mediaOnly ? null : this.context.getModelData('authContext');
    const analytics = _.get(this.props, 'activeVideo.analytics', {});
    const session_id = _.get(this.props, 'activeVideo.session.id');

    const playbackService = Object.assign({}, this.context.getModelData('services', 'playback'));
    // const videocloud = Object.assign({}, this.context.getModelData('ovps', 'videocloud', this.props.playContext.get('video').ovpId));
    const streaks = Object.assign(
      {},
      // @ts-ignore TS2339
      this.context.getModelData('ovps', 'streaks', this.props.playContext.get('video').ovpId),
    );
    let path;
    // if (videocloud.accountId !== undefined) {
    //   path = `${playbackService.protocol}://${playbackService.hostname}/session/open/playback/v1/accounts/${videocloud.accountId}/videos/${this.props.playContext.get('video').videoId}`;
    // } else {
    const params = {};
    if (authContext) {
      // @ts-ignore TS2339
      params.user_id = authContext.id;
    } else if (analytics) {
      // @ts-ignore TS2339
      params.user_id = analytics.vuid;
    }
    const query = Object.keys(params)
      .map(function(key) {
        return key + '=' + encodeURIComponent(params[key]);
      })
      .join('&');
    path = `${playbackService.protocol}://${playbackService.hostname}/session/open/v1/merchants/${
      streaks.merchantId
      // @ts-ignore TS2339
    }/medias/${this.props.playContext.get('video').videoId}?${query}`;
    // }
    xhr.open('GET', path, true);
    xhr.responseType = 'json';
    if (authContext) {
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
    }
    if (session_id) {
      xhr.setRequestHeader('X-Playback-Session-Id', session_id);
      // @ts-ignore TS2339
    } else if (analytics.psid) {
      // @ts-ignore TS2339
      xhr.setRequestHeader('X-Playback-Session-Id', analytics.psid);
      // @ts-ignore TS2339
    } else if (this.props.playContext) {
      // @ts-ignore TS2339
      xhr.setRequestHeader('X-Playback-Session-Id', this.props.playContext.get('session').id);
    }
    xhr.withCredentials = true;
    xhr.send();
  }

  checkVideo(handler) {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        // @ts-ignore TS2367
        if (xhr.status === 401 || xhr.status === 403) {
          window.location.reload();
          return;
        }
        if (handler) handler(xhr.response);
      }
    };

    const playbackService = Object.assign({}, this.context.getModelData('services', 'playback'));
    xhr.open('POST', `${playbackService.protocol}://${playbackService.hostname}/session/check`, true);
    xhr.responseType = 'json';
    const authContext = this.context.getModelData('authContext');
    if (authContext) {
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
    }
    const analytics = _.get(this.props, 'activeVideo.analytics', {});
    const session_id = _.get(this.props, 'activeVideo.session.id');
    if (session_id) {
      xhr.setRequestHeader('X-Playback-Session-Id', session_id);
      // @ts-ignore TS2339
    } else if (analytics.psid) {
      // @ts-ignore TS2339
      xhr.setRequestHeader('X-Playback-Session-Id', analytics.psid);
      // @ts-ignore TS2339
    } else if (this.props.playContext) {
      // @ts-ignore TS2339
      xhr.setRequestHeader('X-Playback-Session-Id', this.props.playContext.get('session').id);
    }
    xhr.withCredentials = true;
    xhr.send();
  }

  render() {
    // let videocloud = {};
    let streaks = {};
    if (
      _.get(this.props, 'errorView.props.error.type') !== ERROR.CONTENTS_PROTECTION &&
      // @ts-ignore TS2339
      this.props.playContext &&
      // @ts-ignore TS2339
      this.props.playContext.get('video').ovpId
    ) {
      // videocloud = Object.assign({}, this.context.getModelData('ovps', 'videocloud', this.props.playContext.get('video').ovpId));
      streaks = Object.assign(
        {},
        // @ts-ignore TS2339
        this.context.getModelData('ovps', 'streaks', this.props.playContext.get('video').ovpId),
      );
    }

    // DVRの選択制御
    // @ts-ignore TS2339
    const started = !this.props.maybeEnableStartOver && this.state.playStartCompleted;

    return (
      <PlayerSurface
        aria-label="WodPlayer"
        className={classnames('wod-player-container', 'video-container', 'notranslate', {
          // @ts-ignore TS2339
          'prp-fullscreen': this.props.isFullScreen,
          // @ts-ignore TS2339
          'prp-loaded': this.state.loaded,
          'prp-started': started,
          // @ts-ignore TS2339
          'prp-ended': this.state.ended,
          // @ts-ignore TS2339
          'prp-error': !!this.props.errorView,
          // @ts-ignore TS2339
          'prp-before-delivery': this.props.isBeforeDelivery,
        })}
        // @ts-ignore TS2339
        onMouseEnter={this.props.onMouseEnter}
        // @ts-ignore TS2339
        onMouseLeave={this.props.onMouseLeave}
      >
        {!_.isEmpty(streaks) && (
          <StreaksPlayer
            // @ts-ignore TS2322
            mediaId={this.props.playContext.get('video').videoId}
            // @ts-ignore TS2339
            merchantId={streaks.merchantId}
            // playerId={streaks.playerId}
            // @ts-ignore TS2339
            activeVideo={this.props.activeVideo}
            // @ts-ignore TS2339
            isFullScreen={this.props.isFullScreen}
            // @ts-ignore TS2339
            startTime={this.props.startTime}
            // @ts-ignore TS2339
            shouldPlayAd={this.props.shouldPlayAd}
            // @ts-ignore TS2339
            loaded={this.state.loaded}
            mueted={false}
            // @ts-ignore TS2339
            canPip={this.props.canPip}
            // @ts-ignore TS2339
            minBitrate={this.props.minBitrate || -1}
            // @ts-ignore TS2339
            maxBitrate={this.props.maxBitrate || -1}
            loadVideo={this.loadVideo}
            // @ts-ignore TS2339
            playerPaused={this.state.paused}
            // @ts-ignore TS2339
            playContext={this.props.playContext}
            // @ts-ignore TS2339
            setRenditions={this.props.setRenditions}
            onReady={this.onReady}
            onError={this.onError}
            // @ts-ignore TS2339
            onTimeout={this.onTimeout}
            // @ts-ignore TS2339
            ref={this.playerRef}
          />
        )}
      </PlayerSurface>
    );
  }
}

export default WodPlayerCore;
