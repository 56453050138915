import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import { Helmet } from 'react-helmet';

import LiveScheduleCanvas from './LiveScheduleCanvas';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import JsonLd from '../../../common/components/JsonLd';
import routes from '../../../common/routes';

class LiveSchedule extends Component {
  static getPaths = function(models, options, props) {
    return LiveScheduleCanvas.getPaths(models, options, {
      canvasId: this.getCanvasId(models, options, props),
    });
  };

  static getCanvasId = function(models, options = {}, props = {}) {
    return _.get(models, 'config.data.canvas.live_schedule');
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      routeHandler: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.sendToGtm = this.sendToGtm.bind(this);
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.sendToGtm();
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    this.context.gtmApp.pageView('ライブ配信');
  }

  render() {
    // @ts-ignore TS2339
    const canvasId = this.constructor.getCanvasId(this.context.models, {}, this.props);
    if (!canvasId) return null;

    const host = this.context.getModelData('hosts', 'host');
    // @ts-ignore TS2554
    const url = routes.liveSchedule.makePath();
    const jsonLdProps = {
      breadcrumbList: {
        itemListElement: [{ name: 'ライブ配信', item: host + url }],
      },
    };
    return (
      <React.Fragment>
        <HtmlContext.Consumer>{({ keywords }) => <Helmet title={'ライブ配信'} />}</HtmlContext.Consumer>
        <JsonLd {...jsonLdProps} />
        {/*
         // @ts-ignore TS2339 */}
        <LiveScheduleCanvas {...this.props} model={this.model} canvasId={canvasId} />
      </React.Fragment>
    );
  }
}

export default withLayoutContext(LiveSchedule);
