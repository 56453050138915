import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

// @ts-ignore TS1192
import reactNl2br from '../../../../../common/reactNl2br';
// @ts-ignore TS2306
import sepDigit from '../../../../../common/sepDigit';
import CheckboxDiv from '../../../../common/components/CheckboxDiv';
import validations from '../../../../../utils/validations';
import RightsPanel from '../common/RightsPanel';

import { withModalContext } from '../../../context/ModalContext';
import UserVoucherConfirm from './PurchaseConfirm/UserVoucherConfirm';
import SubmitErrorView from './common/SubmitErrorView';
import SubmitButtonArea from './common/SubmitButtonArea';
import PaymentInfoForm from './common/PaymentInfoForm';
import AccountOwnerOnlyView from './common/AccountOwnerOnlyView';
import ViewingConfirmation from '../../howto_play/ViewingConfirmation';
import { getPurchaseLabel } from '../../../../../utils/getPurchaseLabel';
import MainViewLink from '../../MainViewLink';

class PurchaseConfirm extends React.PureComponent {
  static get contextTypes() {
    return {
      models: PropTypes.object,
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      history: PropTypes.object,
    };
  }

  static get propTypes() {
    return {
      product: PropTypes.object,
      meta: PropTypes.object,
      accountInfo: PropTypes.object,
      paymentMethods: PropTypes.array,
      paymentInstruments: PropTypes.array,
    };
  }

  static get defaultProps() {
    return {
      meta: {},
    };
  }

  constructor(props, context) {
    super(props, context);

    this._handleAddPayment = this._handleAddPayment.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._applyVoucherData = this._applyVoucherData.bind(this);
    this.setNextStep = this.setNextStep.bind(this);
    this._handleClickAgreeFlag = this._handleClickAgreeFlag.bind(this);

    // @ts-ignore TS2339
    this.paymentInfoFormRef = React.createRef();
    // @ts-ignore TS2339
    this.inputAgreeFlagRef = React.createRef();

    const paymentMethodRule = _.get(props.product, 'paymentMethodRule');
    this.state = {
      voucherData: null,
      nextStep: _.isEmpty(
        _.filter(props.paymentInstruments, paymentInstrument => {
          return (
            paymentInstrument.isAvailable &&
            (_.isEmpty(paymentMethodRule) || paymentMethodRule.includes(_.get(paymentInstrument, 'paymentMethod.id')))
          );
        }),
      ),
      messages: {},
      disabled: true,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillReceiveProps(newProps) {
    if (newProps.submitError) {
      // @ts-ignore TS2339
      if (this._isMounted) {
        this.setState({ submitting: false });
      }
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  //
  // 支払い方法追加
  //
  _handleAddPayment(e) {
    // @ts-ignore TS2339
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true, nextStep: true });

    let args = {
      // @ts-ignore TS2339
      metaId: this.props.meta.id,
      type: 'purchase',
      // @ts-ignore TS2339
      productId: this.props.product.id,
      nextStep: true,
    };
    // @ts-ignore TS2339
    this.props.nextAction(args);
  }

  //
  // submitしたとき
  //
  _handleSubmit(e) {
    // @ts-ignore TS2339
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    let args = {
      // @ts-ignore TS2339
      metaId: this.props.meta.id,
      type: 'purchase',
      // @ts-ignore TS2339
      productId: this.props.product.id,
      // @ts-ignore TS2339
      nextStep: this.state.nextStep,
      userVoucherId: _.get(this.state, 'voucherData.userVoucher.id'),
    };
    try {
      // @ts-ignore TS2339
      if (this.paymentInfoFormRef.current) {
        // @ts-ignore TS2339
        if (this.state.nextStep) {
          // @ts-ignore TS2339
          args['paymentMethodInfo'] = this.paymentInfoFormRef.current.getSelectedPaymentMethodInfo();
        // @ts-ignore TS2339
        } else if (this.props.paymentInstruments.length > 0) {
          // @ts-ignore TS2339
          args['paymentInstrumentInfo'] = this.paymentInfoFormRef.current.getSelectedPaymentInstrumentInfo();
        }
      }
    } catch (e) {}
    // @ts-ignore TS2339
    this.props.nextAction(args);
  }

  //
  // キャンセルボタンを押したとき
  //
  _handleCancel(e) {
    // @ts-ignore TS2339
    this.props.prevAction(e);
  }

  _applyVoucherData(data) {
    let nextStep = _.isEmpty(
      // @ts-ignore TS2339
      _.filter(this.props.paymentInstruments, paymentInstrument => {
        return paymentInstrument.isAvailable;
      }),
    );
    if (data) {
      nextStep = false;
    }
    this.setState({ voucherData: data, nextStep: nextStep });
  }

  //
  // 現在の支払い方法で購入するか
  // 支払い情報を登録して購入するか
  // をセット
  //
  setNextStep(bool) {
    // @ts-ignore TS2339
    if (this._isMounted) {
      this.setState({ nextStep: bool });
    }
  }

  _handleClickAgreeFlag(e) {
    // @ts-ignore TS2339
    if (this.state.submitting) {
      e.preventDefault();
      return false;
    }
    this._handleFormChange();
    this.setState(prevState => {
      // @ts-ignore TS2339
      let newMessages = _.omit(_.assign({}, prevState.messages), 'agreed_contract_version');
      // @ts-ignore TS2339
      validations.agreeFlag(newMessages, this.inputAgreeFlagRef.current.getChecked());
      return {
        messages: newMessages,
      };
    });
  }

  _handleFormChange() {
    //バリデーションがあるかチェック
    const messages = {};
    // @ts-ignore TS2339
    validations.agreeFlag(messages, this.inputAgreeFlagRef.current.getChecked());
    let isValid = _.isEmpty(messages);
    // @ts-ignore TS2339
    if (isValid && this.state.messages) {
      // @ts-ignore TS2339
      if (this.state.disabled) this.setState({ disabled: false });
    } else {
      // @ts-ignore TS2339
      if (!this.state.disabled) this.setState({ disabled: true });
    }
  }

  render() {
    // @ts-ignore TS2339
    const product = this.props.product;
    // @ts-ignore TS2339
    const label = getPurchaseLabel(product, this.props.meta);
    const browserInfo = this.context.getModelData('browserInfo');
    return (
      <React.Fragment>
        <div className="upsell-confirm purchase-confirm">
          <p className="message">この作品を{label}しますか？</p>
          {/*
           // @ts-ignore TS2322 */}
          <AccountOwnerOnlyView handleCancel={this._handleCancel}>
            {/* <ViewingConfirmation actionType="purchase" /> */}
            <div className="upsell-confirm-table">
              <div className="upsell-confirm-table__inner">
                <div className="product-name">{product.name}</div>
                {_.isEmpty(product.productDescription) ? null : (
                  <div className="product-description">{reactNl2br(product.productDescription)}</div>
                )}
                {this._renderProductPrice()}
                {this._renderProductPeriod()}
              </div>
              <div className="upsell-confirm-table__inner">
                <UserVoucherConfirm
                  // @ts-ignore TS2322
                  product={this.props.product}
                  applyVoucherData={this._applyVoucherData}
                  // @ts-ignore TS2339
                  userVoucherInfo={this.props.userVoucherInfo}
                />
                <PaymentInfoForm
                  // @ts-ignore TS2322
                  model={this.props.model}
                  // @ts-ignore TS2339
                  ref={this.paymentInfoFormRef}
                  // @ts-ignore TS2339
                  paymentInstruments={this.props.paymentInstruments}
                  // @ts-ignore TS2339
                  paymentMethods={this.props.paymentMethods}
                  setNextStep={this.setNextStep}
                  // @ts-ignore TS2339
                  nextStep={this.state.nextStep}
                  hidden={_.get(this.state, 'voucherData.paymentPricing.price') == 0}
                  handleAddPayment={this._handleAddPayment}
                  // @ts-ignore TS2339
                  defaultPaymentInstrumentId={this.props.defaultPaymentInstrumentId}
                  // @ts-ignore TS2339
                  product={this.props.product}
                />
              </div>
              {_.isEmpty(product.notesOnPurchase) ? null : (
                <div className="upsell-confirm-table__inner">
                  <div className="recipe-table-row">
                    <div className="title">購入時の注意事項</div>
                    <div className="item">
                      <p>{reactNl2br(product.notesOnPurchase)}</p>
                    </div>
                  </div>
                </div>
              )}
              <div className="upsell-confirm-table__inner">
                <div className="input-block">
                  <fieldset>
                    <CheckboxDiv
                      // @ts-ignore TS2339
                      ref={this.inputAgreeFlagRef}
                      name="agreed_contract_version"
                      defaultChecked={false}
                      onClick={this._handleClickAgreeFlag}
                      required={true}
                    >
                      <a
                        href="https://www.wowow.co.jp/guidance/haishin_tvod/"
                        target="_blank"
                        className="external-link"
                      >
                        都度課金型動画配信サービス規約
                      </a>
                      に同意
                    </CheckboxDiv>
                  </fieldset>
                </div>
              </div>
              <div className="upsell-confirm-table__inner text-center">
                {browserInfo.isAndroid || browserInfo.isIOS ? (
                  // @ts-ignore TS2322
                  <MainViewLink href="/check" target="_blank" className="btn btn-line white-color external-link">
                    ※購入前に、お試し再生を必ず行ってください。
                  </MainViewLink>
                ) : (
                  <React.Fragment>
                    ※購入前に、
                    {/*
                     // @ts-ignore TS2322 */}
                    <MainViewLink href="/check" target="_blank" className="external-link">
                      お試し再生
                    </MainViewLink>
                    を必ず行ってください。
                  </React.Fragment>
                )}
              </div>
            </div>
            <SubmitErrorView {...this.props} />
            <SubmitButtonArea
              actionName={label}
              // @ts-ignore TS2322
              disabled={this.state.disabled}
              // @ts-ignore TS2339
              nextStep={this.state.nextStep}
              // @ts-ignore TS2339
              submitting={this.state.submitting}
              handleSubmit={this._handleSubmit}
              handleCancel={this._handleCancel}
            />
          </AccountOwnerOnlyView>
        </div>
      </React.Fragment>
    );
  }

  //
  // 価格表示
  //
  _renderProductPrice() {
    // @ts-ignore TS2339
    const product = this.props.product;

    const discountPricing = _.get(this.state, 'voucherData.paymentPricing');
    // @ts-ignore TS2339
    if (discountPricing && discountPricing.price && discountPricing.displayUnit) {
      return (
        <div className="price">
          <p className="strikeout">
            <span className="text">{sepDigit(product.activePricing.paymentAmount)}</span>
            {product.activePricing.displayUnit}（税込）
          </p>
          <p>
            {/*
             // @ts-ignore TS2339 */}
            <span className="text">{sepDigit(discountPricing.paymentAmount)}</span>
            {/*
             // @ts-ignore TS2339 */}
            {discountPricing.displayUnit}（税込）
          </p>
        </div>
      );
    }

    return (
      <div className="price">
        <span className="text">{sepDigit(product.activePricing.paymentAmount)}</span>
        {product.activePricing.displayUnit}（税込）
      </div>
    );
  }

  //
  // 期間表示
  //
  _renderProductPeriod() {
    // @ts-ignore TS2339
    const product = this.props.product;

    if (product.productType != 'product_type_rental') {
      return null;
    }

    const rightLength = _.get(product, 'rights.length', 0);

    if (rightLength > 1) {
      return (
        <div className="recipe-table-row clearfix">
          <div className="title">視聴期間</div>
          <div className="item">
            <p>
              <a
                href="javascript:void(0)"
                onClick={e => {
                  // @ts-ignore TS2339
                  this.props.showModal(<RightsPanel rights={product.rights} />);
                }}
              >
                内容の確認
              </a>
            </p>
          </div>
        </div>
      );
    } else if (rightLength == 1) {
      const activeRight = product.rights[0] || {};
      return (
        <React.Fragment>
          <div className="period">
            <span>{activeRight.displayLimitType}</span>
            {activeRight.displayLimitValue}
            {activeRight.displayLimitUnit}
          </div>
          {!!activeRight.exerciseTerm && (
            <div className="period">
              <span>{activeRight.exerciseTermType}</span>
              {activeRight.exerciseTermFrom}
              {activeRight.exerciseTerm}
              {activeRight.exerciseTermUnit}
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export default withModalContext(PurchaseConfirm);
