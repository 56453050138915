import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';
import ImageRotator from './ImageRotator';

export default class HeroImages extends PureComponent {
  static get propTypes() {
    return {
      auto: PropTypes.bool,
      classes: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
      duration: PropTypes.number,
      firstDelay: PropTypes.number,
      preloadImage: PropTypes.string,
      stop: PropTypes.bool,
      images: PropTypes.array,
      model: PropTypes.object.isRequired,
    };
  }

  static get contextTypes() {
    return {
      auto: PropTypes.bool,
      duration: PropTypes.number,
      firstDelay: PropTypes.number,
      height: PropTypes.number,
      isStandAlone: PropTypes.bool,
      models: PropTypes.object,
      preloadImage: PropTypes.string,
      size: PropTypes.string,
      stop: PropTypes.bool,
      watched: PropTypes.bool,
      images: PropTypes.array,
      classes: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.state = { isLoaded: false };
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  render() {
    const childProps = _.omit(this.props, ['model']);
    if (this.props.children) {
      return (
        // @ts-ignore TS2339
        <div className={classnames(this.props.classes)}>
          <ImageRotator {...childProps} />
          {this.props.children}
        </div>
      );
    } else {
      return <ImageRotator {...childProps} />;
    }
  }
}
