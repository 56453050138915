import React, { useContext } from 'react';
import _ from 'src/libs/util';

// @ts-ignore TS2554
const CardContext = React.createContext();
export default CardContext;

export const withCardContext = Component => {
  const withCardContext = React.forwardRef((props, ref) => {
    return (
      <CardContext.Consumer>
        {/*
         // @ts-ignore TS2698 */}
        {context => React.createElement(Component, { ref, ...props, ...context })}
      </CardContext.Consumer>
    );
  });
  return withCardContext;
};

export const CardContextProvider = props => {
  const context = useContext(CardContext);
  return (
    // @ts-ignore TS2698
    <CardContext.Provider value={{ ...context, ...props.value }}>
      {React.Children.map(props.children, (child, index) => {
        if (!React.isValidElement(child)) return child;
        return React.cloneElement(child, { ..._.omit(props, ['value', 'children']) });
      })}
    </CardContext.Provider>
  );
};
