import { EventEmitter } from 'fbemitter';
import url from 'url';
import _ from 'src/libs/util';
import cookieDough from 'cookie-dough';

import datetime from 'src/libs/datetime';
import Axios from './Axios';
import { Organization } from 'src/types/context/Organization';

const WatchPartyDataStore = function(cookie) {
  return {
    get: function(profile) {
      const data = this.getAll();
      if (_.has(data, profile.id)) {
        return data[profile.id];
      }
      return null;
    },
    getAll: function() {
      if (window.localStorage) {
        return JSON.parse(window.localStorage.getItem('watchparty'));
      } else if (window.navigator.cookieEnabled) {
        return cookie.get('watchparty');
      }
    },
    set: function(wpProfile, profile) {
      let data = this.getAll();
      if (!data) data = {};
      data[profile.id] = wpProfile;
      if (window.localStorage) {
        window.localStorage.setItem('watchparty', JSON.stringify(data));
      } else if (window.navigator.cookieEnabled) {
        cookie.set('watchparty', JSON.stringify(data), { path: '/' });
      }
    },
    delAll: function() {
      if (window.localStorage) {
        window.localStorage.setItem('watchparty', null);
      } else if (window.navigator.cookieEnabled) {
        cookie.remove('watchparty', { path: '/' });
      }
    },
  };
};

/*
リニア open型 番組跨ぐ時は考慮しない
ライブ open型 ライブ間もない見逃しについては考慮しない
-> ライブ終了と同時にフラグを落とす
-> フラグを落とすとclose型になるがライブスキーマなのでclose型はできない想定
エピソード close型
 */
class WatchPartyApp {
  static USER_ACCESS_LIMIT = 30; // アクセス人数制限（USER_ATTEND_LIMITより大きな数字に設定）
  static USER_ATTEND_LIMIT = 8; // 最大出席人数（画面上表示用の数字）
  static ROOM_LIMIT = 3 * 3600 * 1000;

  // N分前 N時間前 M月D日
  static getAgo = function(sentAt, context) {
    const diff = Date.now() - sentAt;
    // 24時間前か
    if (diff > 60 * 60 * 24 * 1000) {
      return datetime(sentAt).format('M月D日');

      // 1時間前か
    } else if (diff > 60 * 60 * 1000) {
      // 1時間であれば hour、2時間以降であればhours
      const hour = Math.round(diff / (60 * 60 * 1000));
      return `${hour}時間前`;
    }

    // 1分であれば minute、2分以降であればminutes
    const minute = Math.round(diff / (60 * 1000));
    return `${minute}分前`;
  };

  static isSenderMaster(data) {
    // 文字列の場合でも判定できるようにしておく
    return _.get(data, 'sender.customData.master') == 1;
  }

  static isSenderOfficialAccount(data) {
    // 文字列の場合でも判定できるようにしておく
    return _.get(data, 'sender.customData.officialAccount') == 1;
  }

  private initing?: boolean;
  private organizations?: Organization[];
  private axios: Axios;

  constructor(authApp, models, options = {}, req) {
    // @ts-ignore TS2339
    this._authApp = authApp;
    // @ts-ignore TS2339
    this._options = options;
    // @ts-ignore TS2339
    this._emitter = new EventEmitter();
    // @ts-ignore TS2339
    this._listeners = [];
    // @ts-ignore TS2339
    this._cookies = cookieDough(req);
    this.axios = new Axios({ xhr: true });

    this.on = this.on.bind(this);
    this.off = this.off.bind(this);
    // @ts-ignore TS2339
    this._models = _.pick(models, ['config', 'services', 'hosts']);
    // @ts-ignore TS2339
    this._currentModels = {};

    // @ts-ignore TS2339
    this.comments = [];

    this.handleError = this.handleError.bind(this);
    this.handleJoinAnnouncement = this.handleJoinAnnouncement.bind(this);
    this.handleLeaveAnnouncement = this.handleLeaveAnnouncement.bind(this);
    this.handleGift = this.handleGift.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleMessageUpdated = this.handleMessageUpdated.bind(this);
    this.handleUserUpdated = this.handleUserUpdated.bind(this);
    this.handleRoomUpdated = this.handleRoomUpdated.bind(this);
    this.handleCommand = this.handleCommand.bind(this);
    this.handleSystem = this.handleSystem.bind(this);

    if (typeof window !== 'undefined') {
      this.setup();
    }

    // @ts-ignore TS2339
    this._DataStore = WatchPartyDataStore(this._cookies);
  }

  setup() {
    return new Promise((resolve, reject) => {
      // @ts-ignore TS2794
      return resolve();
      const xhr = new XMLHttpRequest();
      xhr.open('GET', '/api/utils/profile_icons', true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          try {
            if (xhr.status != 200) {
              // @ts-ignore TS2448
              return reject(response);
            }

            let response = xhr.response;
            if (typeof response === 'string') {
              response = JSON.parse(response);
            }

            return resolve(response);
          } catch (e) {
            return reject(e);
          }
        }
      };
      xhr.send();
    })
      .then(response => {
        // console.log(response);
        // @ts-ignore TS2339
        this.icons = [];
        // this.icons = _.map(_.get(response, 'profileIcons'), profileIcon => {
        //   let src = _.get(profileIcon, 'images.profileIcon.src');
        //   if (src) src += '?size=300x300&w=300&h=300';
        //   return src;
        // });

        // プロフィール
        // @ts-ignore TS2339
        const activeProfile = this._authApp.activeProfile();
        if (activeProfile) {
          // @ts-ignore TS2551
          this.__profile = this._DataStore.get(activeProfile);
        }
        // @ts-ignore TS2551
        if (!this.__profile) {
          let nickname = 'ニックネーム';
          this.profile = {
            icon: this.getSampleIcon(),
            name: nickname,
          };
        }

        // @ts-ignore TS2551
        this.setuped = true;
      })
      .catch(e => {
        console.log(e);
      });
  }

  // setRouteHandler(routeHandler) {
  //   this._routeHandler = routeHandler;
  //   if (typeof window !== 'undefined') {
  //     this.syncUser();
  //   }
  // }

  getModelData(name) {
    // @ts-ignore TS2339
    return _.get(this._currentModels, `${name}.data`, _.get(this._models, `${name}.data`));
  }

  getIcons() {
    // @ts-ignore TS2339
    return this.icons;
  }

  getSampleIcon() {
    return null;
    // @ts-ignore TS2339
    return _.sample(this.icons);
  }

  getProfile() {
    return _.clone(this.profile);
  }

  getIsOfficialAccount() {
    return this.organizations && _.some(this.organizations, org => org.isWowowOrg);
  }

  setProfile(profile, cb) {
    // join済みだったらcustom_apiを叩いてuserを更新する
    this.doUserJoinCheck()
      .then(joined => {
        if (joined) return this.doUpdateUser(profile);
        return Promise.resolve();
      })
      .then(() => {
        this.profile = profile;
        if (cb) cb(null, true);
      })
      .catch(e => {
        // TODO: error
        if (cb) cb(e, null);
        else console.log(e);
      });
  }

  get roomId() {
    if (this.room) return this.room.id;
    // @ts-ignore TS2551
    return this.__roomId;
  }

  set roomId(roomId) {
    // @ts-ignore TS2551
    this.__roomId = roomId;
    this.setRoomApiPath();
    this.init();
  }

  get room() {
    // @ts-ignore TS2339
    if (this.wpc && this.wpc.room) return this.wpc.room;
    // @ts-ignore TS2551
    return this.__room;
  }

  set room(room) {
    // @ts-ignore TS2551
    this.__room = room;
  }

  get profile() {
    // @ts-ignore TS2551
    return this.__profile;
  }

  set profile(profile) {
    // @ts-ignore TS2339
    const activeProfile = this._authApp.activeProfile();
    if (activeProfile) {
      // @ts-ignore TS2339
      this._DataStore.set(profile, activeProfile);
    }
    // @ts-ignore TS2551
    this.__profile = profile;
    // @ts-ignore TS2339
    this._emitter.emit('updateProfile');
  }

  addComment(comment) {
    // @ts-ignore TS2339
    this.comments.push(comment);
  }

  getComments() {
    // @ts-ignore TS2339
    return _.takeRight(this.comments, 100);
  }

  scriptLoad() {
    // console.log(`${this.constructor.name}:scriptLoad`, this.scriptLoading);
    // @ts-ignore TS2551
    if (this.scriptLoading) return;
    // @ts-ignore TS2551
    this.scriptLoading = true;
    const script = document.createElement('script');
    // script.src = 'http://www.dev.watchparty.play.jp/dist/watchparty.js';
    // script.src = '/js/watchparty.js';
    script.src = '/js/watchparty.min.js';
    script.onload = () => {
      // @ts-ignore TS2551
      this.scriptLoading = false;
      // @ts-ignore TS2551
      this.scriptLoaded = true;
      this.init();
    };
    document.head.appendChild(script);
  }

  init() {
    // console.log(`${this.constructor.name}:init`, this.scriptLoaded, this.roomId, !!this.wpc);
    // @ts-ignore TS2551
    if (!this.scriptLoaded) return this.scriptLoad();
    if (!this.roomId) return;
    // @ts-ignore TS2339
    if (this.wpc) return;
    // @ts-ignore TS2339
    if (this.initing) return;
    // @ts-ignore TS2339
    this.initing = true;

    const promise = new Promise((resolve, reject) => {
      // 組織情報がまだ取得できていない場合だけ
      if (this.organizations) {
        resolve(null);
      } else {
        this.axios
          .get('/api/account/organizations')
          .then(result => {
            if (!_.get(result, 'data.result')) {
              throw new Error(_.get(result, 'data.message'));
            }
            this.organizations = result.data.organizations;
            resolve(null);
          })
          .catch(e => {
            reject(e);
          });
      }
    });
    promise
      .then(() => {
        this.initClient().then(res => {
          // @ts-ignore TS2339
          if (this.initStart) this.start();
          this.initing = false;
        });
      })
      .catch(e => {
        this.handleError(e);
        this.initing = false;
      });
  }

  initClient() {
    // TODO 受け取るメッセージの設定
    // roomUpdated wpc.room で最新が取れるはず
    // room.config.userUpdateAnnounce
    return this.doRoomToken().then(res => {
      const options = _.get(this.getModelData('config'), 'watch_party.options', {});
      // publicの場合に指定する
      // @ts-ignore TS2551
      if (this.__roomId) {
        options.types = ['message', 'messageUpdated']; // messageUpdated は指定しなくてもよくなる
      }
      // @ts-ignore TS2339
      this.__projectId = this.getProjectId();
      // @ts-ignore TS2339
      this.wpc = new window.WatchPartyClient(res.token, this.__projectId, this.roomId, options);
      // @ts-ignore TS2339
      this.wpc.on('error', this.handleError);
      // @ts-ignore TS2339
      this.wpc.on('joinAnnouncement', this.handleJoinAnnouncement);
      // @ts-ignore TS2339
      this.wpc.on('leaveAnnouncement', this.handleLeaveAnnouncement);
      // @ts-ignore TS2339
      this.wpc.on('gift', this.handleGift);
      // @ts-ignore TS2339
      this.wpc.on('message', this.handleMessage);
      // @ts-ignore TS2339
      this.wpc.on('messageUpdated', this.handleMessageUpdated);
      // @ts-ignore TS2339
      this.wpc.on('userUpdated', this.handleUserUpdated);
      // @ts-ignore TS2339
      this.wpc.on('roomUpdated', this.handleRoomUpdated);
      // @ts-ignore TS2339
      this.wpc.on('command', this.handleCommand);
      // @ts-ignore TS2339
      this.wpc.on('system', this.handleSystem);
      // @ts-ignore TS2339
      this.wpc.dispose = () => {
        // @ts-ignore TS2339
        this.wpc.off('error', this.handleError);
        // @ts-ignore TS2339
        this.wpc.off('joinAnnouncement', this.handleJoinAnnouncement);
        // @ts-ignore TS2339
        this.wpc.off('leaveAnnouncement', this.handleLeaveAnnouncement);
        // @ts-ignore TS2339
        this.wpc.off('gift', this.handleGift);
        // @ts-ignore TS2339
        this.wpc.off('message', this.handleMessage);
        // @ts-ignore TS2339
        this.wpc.off('messageUpdated', this.handleMessageUpdated);
        // @ts-ignore TS2339
        this.wpc.off('userUpdated', this.handleUserUpdated);
        // @ts-ignore TS2339
        this.wpc.off('roomUpdated', this.handleRoomUpdated);
        // @ts-ignore TS2339
        this.wpc.off('command', this.handleCommand);
        // @ts-ignore TS2339
        this.wpc.off('system', this.handleSystem);
        // @ts-ignore TS2339
        delete this.wpc;
      };
    });
  }

  handleError(error) {
    // @ts-ignore TS2339
    this._emitter.emit('error', error);
  }

  handleJoinAnnouncement(data) {
    // console.log('handleJoinAnnouncement', data)
    if (this.isMaster()) this.updateUserCountRoom();
    // @ts-ignore TS2339
    this._emitter.emit('joinAnnouncement', this.adjustComment(data));
  }

  handleLeaveAnnouncement(data) {
    // console.log('handleLeaveAnnouncement', data)

    const done = () => {
      if (this.isMaster()) this.updateUserCountRoom();
      // リロードとかで取得できない場合があるので補完する
      if (!_.get(data, 'sender.name')) {
      }
    };

    if (data.sender.id) {
      // 退室したユーザーをroomから消す
      this.doDeleteUser(data.sender.id).then(() => {
        done();
      });
    } else {
      done();
    }

    // @ts-ignore TS2339
    this._emitter.emit('leaveAnnouncement', this.adjustComment(data));
  }

  handleGift(data) {
    // @ts-ignore TS2339
    this._emitter.emit('gift', this.adjustComment(data));
  }

  handleMessage(data) {
    // console.log('handleMessage', data)
    // @ts-ignore TS2339
    this._emitter.emit('message', this.adjustComment(data));
  }

  handleMessageUpdated(data) {
    // console.log("handleMessageUpdated", data);
    if (_.get(data, 'message.data.isNGMessage')) {
      // @ts-ignore TS2339
      this.comments = _.reject(this.comments, comment => comment.key === data.message.data.id);
      // @ts-ignore TS2339
      this._emitter.emit('messageUpdated', this.adjustComment(data.message.data));
    }
  }

  handleUserUpdated(data) {
    // console.log('handleUserUpdated', data)
    // @ts-ignore TS2339
    this._emitter.emit('userUpdated', this.adjustComment(data));
  }

  handleRoomUpdated(data) {
    // console.log('handleRoomUpdated', data)
    // @ts-ignore TS2339
    this._emitter.emit('roomUpdated', this.adjustComment(data));
  }

  handleCommand(data) {
    // console.log('handleCommand', data)
    // @ts-ignore TS2339
    this._emitter.emit('command', this.adjustComment(data));
  }

  handleSystem(data) {
    // console.log('handleSystem', data);
    // @ts-ignore TS2554
    if (data.event === 'initPlayer' && this.isUser()) {
      if (_.find(window.app.appContext.state.watchPartyApp._listeners, l => l.eventName == 'system')) {
        // @ts-ignore TS2339
        this._emitter.emit('system', this.adjustComment(data));
      } else {
        // @ts-ignore TS2339
        this.initPlayer = data;
      }
      // @ts-ignore TS2339
    } else if (data.event === 'kicked' && data.customData.userId === this.user.id) {
      // @ts-ignore TS2339
      this._emitter.emit('system', this.adjustComment(data));
    } else if (data.event === 'resumed' && this.isMaster()) {
      // @ts-ignore TS2339
      this._emitter.emit('system', this.adjustComment(data));
    }
  }

  adjustComment(data) {
    // console.log(data)
    if (!_.has(data, 'customData')) {
      _.set(data, 'customData', {});
    }
    let name = _.get(data, 'sender.name');
    if (!name) name = '----';
    _.set(data, 'sender.name', name);
    let customData = {};
    if (_.has(data, 'sender.customData')) {
      customData = data.sender.customData;
      if (typeof customData === 'string') {
        try {
          customData = JSON.parse(customData);
        } catch (e) {
          console.log(e);
          customData = {};
        }
      }
    }
    // if (!customData.icon) customData.icon = this.getSampleIcon();
    _.set(data, 'sender.customData', customData);
    return data;
  }

  dispose() {
    // console.log(`${this.constructor.name}:dispose`);
    // @ts-ignore TS2554
    this.pause();
    // @ts-ignore TS2554
    this.leave();
    this.pauseAgoTimeUpdate();
    // @ts-ignore TS2339
    this.comments = [];
    // masterの場合のみ削除する
    if (this.isMaster() && this.room) {
      this.doDeleteRoom();
    }
    // @ts-ignore TS2339
    delete this.user;
    // @ts-ignore TS2339
    if (this.wpc) this.wpc.dispose();
    this.roomId = null;
    this.room = null;
    // @ts-ignore TS2339
    this.initing = false;
    // @ts-ignore TS2339
    this.initStart = false;
  }

  // @ts-ignore TS2339
  isMaster(user = this.user) {
    return _.get(user, 'customData.master') == 1;
  }

  isUser(user) {
    return !this.isMaster(user) && this.isPrivate();
  }

  isPrivate() {
    return _.get(this.room, 'type') === 'private';
  }

  // @ts-ignore TS2339
  isJoin(user = this.user) {
    // 入室日時があり、退室日時がないのでtrue
    if (user.lastJoinAt && !user.lastLeaveAt) {
      return true;
      // 入室、退室日時がどちらもある
    } else if (user.lastJoinAt && user.lastLeaveAt) {
      // 退室後に再入室した場合はtrue
      if (user.lastJoinAt > user.lastLeaveAt) {
        return true;
      }
    }
    return false;
  }

  getUsers() {
    return this.doRoomUsers(this.roomId)
      .then(response => {
        // @ts-ignore TS2339
        const users = _.filter(response.users, user => {
          // join済み
          if (this.isJoin(user)) return true;
          return false;
        });

        // roomの値と不一致
        if (this.isMaster()) {
          if (this.room.customData.userCount != users.length) {
            const customData = this.room.customData;
            customData.userCount = users.length;
            // @ts-ignore TS2554
            this.doUpdateRoom(customData);
          }
        }

        return users;
      })
      .catch(e => {
        console.log(e);
      });
  }

  getRoom() {
    return this.room;
  }

  getRoomUrl(context, meta) {
    const host = _.get(this.getModelData('hosts'), 'host', {});
    const metaId = this.room.customData.metaId;
    const refId = this.room.customData.refId;

    let path = 'watch';
    if (refId) {
      path = 'content';
      return `${host}/${path}/${refId}` + '?wp=' + this.room.id;
    }
    // const schemaKey = meta.schemaKey;
    // if (schemaKey == 'realtime') {
    //   path = 'realtimes';
    // } else if (schemaKey === 'viewingConfirmation') {
    //   path = 'media';
    // }
    // if (context.isStore) path = 'store/' + path;

    return `${host}/${path}/${metaId}` + '?wp=' + this.room.id;
  }

  getUser() {
    // @ts-ignore TS2339
    return this.user;
  }

  getProjectId() {
    if (_.has(this.getModelData('config'), 'watch_party.project')) {
      const setting = _.get(this.getModelData('config'), 'watch_party.project', {});
      // @ts-ignore TS2551
      if (this.__roomId && _.has(setting, 'open.id')) {
        // Open型WP
        return _.get(setting, 'open.id');
        // @ts-ignore TS2551
      } else if (!this.__roomId && _.has(setting, 'close.id')) {
        // Close型WP
        return _.get(setting, 'close.id');
      }
    }

    return 'wod';
  }

  setRoomApiPath() {
    if (_.has(this.getModelData('config'), 'watch_party.project')) {
      // @ts-ignore TS2551
      if (this.__roomId) {
        // Open型WP
        // @ts-ignore TS2551
        this.__roomApiPath = `watch_party/open/rooms`;
      } else {
        // Close型WP
        // @ts-ignore TS2551
        this.__roomApiPath = `watch_party/close/rooms`;
      }
    } else {
      // @ts-ignore TS2551
      this.__roomApiPath = `watch_party/rooms`;
    }
  }

  getRoomApiPath() {
    // @ts-ignore TS2551
    return this.__roomApiPath;
  }

  getCloseFlag() {
    return _.get(this.getModelData('config'), 'watch_party.close_flag', true);
  }

  getUserAccessLimit() {
    return _.get(this.getModelData('config'), 'watch_party.user_access_limit', WatchPartyApp.USER_ACCESS_LIMIT);
  }

  getUserAttendLimit() {
    return _.get(this.getModelData('config'), 'watch_party.user_attend_limit', WatchPartyApp.USER_ATTEND_LIMIT);
  }

  getRoomLimit() {
    return _.get(this.getModelData('config'), 'watch_party.room_limit', WatchPartyApp.ROOM_LIMIT);
  }

  getTimeLimit() {
    let limit = this.getRoomLimit();
    if (_.has(this.room, 'customData.startAt')) {
      return limit - (Date.now() - this.room.customData.startAt);
    }
    return limit;
  }

  ngMessage(messageId) {
    return this.doNgMessage(messageId)
      .then(response => {
        return response;
      })
      .catch(e => {
        console.log(e);
      });
  }

  forceClose() {
    this.dispose();
    // @ts-ignore TS2339
    this._emitter.emit('forceClosed');
  }

  isLinkConfirm() {
    // Close型でuserが存在する場合は遷移確認モーダルを表示する
    // @ts-ignore TS2339
    return this.isPrivate() && this.user;
  }

  linkConfirm(e, linkProps) {
    // MainViewLinkを利用しない場合はコールバックで処理する
    if (typeof e === 'function') {
      // @ts-ignore TS2339
      this._emitter.emit('linkConfirm', e);
    } else {
      e.preventDefault();
      e.stopPropagation();
      // @ts-ignore TS2339
      this._emitter.emit('linkConfirm', linkProps);
    }
  }

  startAgoTimeUpdate() {
    // console.log(`${this.constructor.name}:startAgoTimeUpdate`);
    // @ts-ignore TS2339
    this.interval = setInterval(() => {
      // @ts-ignore TS2339
      this._emitter.emit('agoTimeUpdate');
    }, 60 * 1000);
  }

  pauseAgoTimeUpdate() {
    // console.log(`${this.constructor.name}:pauseAgoTimeUpdate`);
    // @ts-ignore TS2339
    if (this.interval) clearInterval(this.interval);
    // @ts-ignore TS2339
    delete this.interval;
  }

  start(cb) {
    // @ts-ignore TS2339
    if (!this.wpc) {
      // @ts-ignore TS2339
      this.initStart = true;
      return;
    }
    // @ts-ignore TS2339
    this.initStart = false;
    // console.log(`${this.constructor.name}:start`);
    // @ts-ignore TS2339
    this.wpc.start((error, body) => {
      // console.log(error, body);
      this.startAgoTimeUpdate();
      if (cb) cb(error, body);
    });
  }

  pause(cb) {
    // @ts-ignore TS2339
    if (!this.wpc) {
      // @ts-ignore TS2339
      this.initStart = false;
      return;
    }
    // console.log(`${this.constructor.name}:pause`);
    // @ts-ignore TS2339
    this.wpc.pause((error, body) => {
      // console.log(error, body);
      this.pauseAgoTimeUpdate();
      if (cb) cb(error, body);
    });
  }

  join(cb) {
    // @ts-ignore TS2339
    if (!this.wpc) return;
    // console.log(`${this.constructor.name}:join`);
    this.doUserToken()
      .then(res => {
        // @ts-ignore TS2339
        this.wpc.join(this.user.id, res.token, (error, body) => {
          // console.log(error, body);
          // roomが404
          if (error && _.get(body, 'statusCode') === 404) {
            // 裏ではjoinできているので不整合が発生する為消しておく
            // @ts-ignore TS2339
            this.doDeleteUser(this.user.id);
            // @ts-ignore TS2339
            if (this.wpc) this.wpc.dispose();
          }
          if (cb) cb(error, body);
        });
      })
      .catch(e => {
        if (cb) cb(e, null);
        else console.log(e);
      });
  }

  joinWithName(name, opt = {}, cb) {
    const profile = this.getProfile();
    const isOfficialAccount = this.getIsOfficialAccount();
    const params = { ...opt, officialAccount: isOfficialAccount ? 1 : 0 };
    profile.name = name;
    let _joined = false;
    this.doUserJoinCheck(params)
      .then(joined => {
        // @ts-ignore TS2322
        _joined = joined;
        // joinしてる場合userを更新する
        if (_joined) return this.doUpdateUser(profile, params);

        // joinしてない場合は出席人数を確認する
        if (_.has(this.room, 'customData.userCount') && this.room.customData.userCount >= this.getUserAttendLimit()) {
          // console.log("current user count: ", this.room.customData.userCount);
          throw new Error('room user limit');
        }

        // この場合はuserを作成する
        return this.doCreateUser(profile, params);
      })
      .then(() => {
        this.profile = profile;
        // joinしてない場合はjoinする
        // if (!_joined) this.join(cb);
        this.join(cb);
      })
      .catch(e => {
        // ニックネームNGワード
        if (_.has(e, 'message') && -1 !== e.message.indexOf('includes ng word')) {
          // マスターの場合に作られたルームを削除する
          // @ts-ignore TS2367
          if (_.get(opt, 'master', 0) === 1) {
            this.doDeleteRoom();
          }
        }

        if (cb) cb(e, null);
        else console.log(e);
      });
  }

  kickUser(userId) {
    return this.doDeleteUser(userId)
      .then(response => {
        // 退室通知
        // @ts-ignore TS2554
        this.postSystem('kicked', {
          userId: userId,
        });
        return response;
      })
      .catch(e => {
        console.log(e);
      });
  }

  leave(cb) {
    // @ts-ignore TS2339
    if (!this.wpc) return;
    // console.log(`${this.constructor.name}:leave`);
    // @ts-ignore TS2339
    this.wpc.leave(function(error, body) {
      if (_.has(error, 'message')) {
        // 下記のエラーはスルー
        if (error.message != 'not joined room' && error.message != 'sender information is not loaded') {
          console.log(error);
        }
      }
      if (cb) cb(error, body);
    });
  }

  postMessage(value, opt = {}, cb) {
    // @ts-ignore TS2339
    if (!this.wpc) return;
    // @ts-ignore TS2339
    this.wpc.postMessage(
      'message',
      {
        message: value,
        customData: opt,
      },
      (error, body) => {
        if (_.has(error, 'message')) {
          // 内部的にjoinしてないとエラーする
          if (error.message == 'not joined room') {
            // joinさせる
            this.join((error, body) => {
              if (error) {
                if (cb) cb(error, body);
                else console.log(error);
              } else {
                // 再度処理する
                // @ts-ignore TS2554
                this.postMessage(value, opt, cb);
              }
            });
            return;
          }
        }
        // console.log(error, body);
        if (cb) cb(error, body);
        else console.log(error);
      },
    );
  }

  postSystem(event, opt = {}, cb) {
    // @ts-ignore TS2339
    if (!this.wpc) return;
    if (!this.isMaster()) return;
    // @ts-ignore TS2339
    this.wpc.postMessage(
      'system',
      {
        event: event,
        customData: opt,
      },
      (error, body) => {
        // console.log(error, body);
        if (cb) cb(error, body);
        else console.log(error);
      },
    );
  }

  postCommand(event, opt = {}, cb) {
    // @ts-ignore TS2339
    if (!this.wpc) return;
    if (!this.isMaster()) return;
    // @ts-ignore TS2339
    this.wpc.postMessage(
      'command',
      {
        event: event,
        customData: opt,
      },
      (error, body) => {
        // console.log(error, body);
        if (cb) cb(error, body);
        else console.log(error);
      },
    );
  }

  checkPass(pass) {
    return this.room.customData.pass == pass;
  }

  userCount() {
    return this.doRoomUsers(this.roomId)
      .then(response => {
        // @ts-ignore TS2339
        return response.totalCount;
      })
      .catch(e => {
        console.log(e);
      });
  }

  startRoom() {
    // 開始するタイミングでroomを延長
    const customData = this.room.customData;
    customData.startAt = Date.now();
    const endAt = customData.startAt + this.getRoomLimit();
    return this.doUpdateRoom(customData, endAt);
  }

  updateUserCountRoom() {
    return this.getUsers()
      .then(users => {
        const customData = this.room.customData;
        // @ts-ignore TS2339
        customData.userCount = users.length;
        // @ts-ignore TS2554
        return this.doUpdateRoom(customData);
      })
      .catch(e => {
        console.log(e);
      });
  }

  createRoom(name, metaId, refId) {
    this.setRoomApiPath();
    // パスワード生成 6桁 乱数
    const password = _.join(
      _.times(6, function() {
        return _.random(1, 9);
      }),
      '',
    );
    return this.doCreateRoom(name, password, metaId, refId);
  }

  doRoomToken() {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          try {
            let response = xhr.response;
            if (typeof response === 'string' && response.length > 0) {
              response = JSON.parse(response);
            }
            if (xhr.status != 200) {
              return reject(response);
            }
            return resolve(response);
          } catch (e) {
            return reject(e);
          }
        }
      };
      let cmsService = Object.assign({}, _.get(this.getModelData('services'), 'cms'));
      cmsService.pathname = _.join(_.concat(cmsService.path, this.getRoomApiPath(), `${this.roomId}/token`), '/');
      xhr.open('GET', url.format(cmsService), false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      // @ts-ignore TS2339
      const authContext = this._authApp.getModelData('authContext');
      if (authContext) {
        xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
        xhr.setRequestHeader('X-User-Id', authContext.id);
        xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      }
      xhr.send();
    });
  }

  doUserToken() {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          try {
            let response = xhr.response;
            if (typeof response === 'string' && response.length > 0) {
              response = JSON.parse(response);
            }
            if (xhr.status != 200) {
              return reject(response);
            }
            return resolve(response);
          } catch (e) {
            return reject(e);
          }
        }
      };
      let cmsService = Object.assign({}, _.get(this.getModelData('services'), 'cms'));
      cmsService.pathname = _.join(
        // @ts-ignore TS2339
        _.concat(cmsService.path, this.getRoomApiPath(), `${this.roomId}/users/${this.user.id}/token`),
        '/',
      );
      xhr.open('GET', url.format(cmsService), false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      // @ts-ignore TS2339
      const authContext = this._authApp.getModelData('authContext');
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      xhr.send();
    });
  }

  doUserJoinCheck(opt = {}) {
    // console.log(`doUserJoinCheck`);
    return new Promise((resolve, reject) => {
      // @ts-ignore TS2339
      const activeProfile = this._authApp.activeProfile();
      if (!activeProfile) {
        return resolve(false);
      }

      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          try {
            let response = xhr.response;
            if (typeof response === 'string' && response.length > 0) {
              response = JSON.parse(response);
            }
            // createされてないuserの場合
            // {"statusCode":404,"message":"user ref:22790909 is not found in room d01e7e4d-f65b-4552-a5fe-5f1204a8845b"}
            if (xhr.status == 404) {
              return resolve(false);
            }
            if (xhr.status != 200) {
              return reject(response);
            }
            if (response.result) {
              if (_.has(opt, 'joinRoom')) {
                // すでに入室した
                throw new Error('user is joined');
              }
              // @ts-ignore TS2339
              this.user = response.user;
            }
            return resolve(response.result);
          } catch (e) {
            return reject(e);
          }
        }
      };
      let cmsService = Object.assign({}, _.get(this.getModelData('services'), 'cms'));
      cmsService.pathname = _.join(
        _.concat(cmsService.path, this.getRoomApiPath(), `${this.roomId}/users/ref:${activeProfile.id}/join`),
        '/',
      );
      xhr.open('GET', url.format(cmsService), false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      // @ts-ignore TS2339
      const authContext = this._authApp.getModelData('authContext');
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      xhr.send();
    });
  }

  doCreateRoom(name, password, metaId, refId) {
    return new Promise((resolve, reject) => {
      // create済み
      // @ts-ignore TS2551
      if (this.__room) return resolve(this.__room);

      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          try {
            let response = xhr.response;
            if (typeof response === 'string' && response.length > 0) {
              response = JSON.parse(response);
            }
            if (xhr.status != 201) {
              return reject(response);
            }
            this.room = response.room;
            return resolve(this.room);
          } catch (e) {
            return reject(e);
          }
        }
      };
      let cmsService = Object.assign({}, _.get(this.getModelData('services'), 'cms'));
      cmsService.pathname = _.join(_.concat(cmsService.path, this.getRoomApiPath()), '/');
      // @ts-ignore TS2339
      const authContext = this._authApp.getModelData('authContext');
      // @ts-ignore TS2339
      const activeProfile = this._authApp.activeProfile();
      const params = {};
      // @ts-ignore TS2339
      params.name = name;
      // @ts-ignore TS2339
      params.type = 'private';
      // @ts-ignore TS2339
      params.customData = {
        aid: authContext.id,
        pid: activeProfile.id,
        pass: password,
        metaId: metaId,
        refId: refId,
      };
      // startAtは指定しない
      // 開始されなければ1時間で終了
      // @ts-ignore TS2339
      params.endAt = Date.now() + 3600 * 1000;
      // @ts-ignore TS2339
      params.config = {
        userLimit: this.getUserAccessLimit(), // プライベートルームは上限20人なのでデフォルト20
        userUpdateAnnounce: true,
      };
      xhr.open('POST', url.format(cmsService), false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      xhr.send(JSON.stringify(params));
    });
  }

  doUpdateRoom(customData, endAt) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          try {
            let response = xhr.response;
            if (typeof response === 'string' && response.length > 0) {
              response = JSON.parse(response);
            }
            if (xhr.status != 200) {
              return reject(response);
            }
            this.room = response.room;
            return resolve(this.room);
          } catch (e) {
            return reject(e);
          }
        }
      };
      let cmsService = Object.assign({}, _.get(this.getModelData('services'), 'cms'));
      cmsService.pathname = _.join(_.concat(cmsService.path, this.getRoomApiPath(), `${this.room.id}`), '/');
      // @ts-ignore TS2339
      const authContext = this._authApp.getModelData('authContext');
      // @ts-ignore TS2339
      const activeProfile = this._authApp.activeProfile();
      const params = {};
      // @ts-ignore TS2339
      params.customData = customData;
      if (endAt) {
        // @ts-ignore TS2339
        params.endAt = endAt;
      }
      xhr.open('PUT', url.format(cmsService), false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      xhr.send(JSON.stringify(params));
    });
  }

  doRoomInfo(roomId, hold = false) {
    this.setRoomApiPath();
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          try {
            // 削除済みは404
            // {"statusCode":404,"message":"room 1b35083d-90d8-4ee3-bf8a-ea82b2e45a72 is not found"}
            let response = xhr.response;
            if (typeof response === 'string' && response.length > 0) {
              response = JSON.parse(response);
            }
            if (xhr.status != 200) {
              return reject(response);
            }
            if (hold) {
              this.room = response.room;
            }
            return resolve(response.room);
          } catch (e) {
            return reject(e);
          }
        }
      };
      let cmsService = Object.assign({}, _.get(this.getModelData('services'), 'cms'));
      // cmsService.pathname = _.join(_.concat(cmsService.path, this.getRoomApiPath(), `1b35083d-90d8-4ee3-bf8a-ea82b2e45a72`), '/');
      cmsService.pathname = _.join(_.concat(cmsService.path, this.getRoomApiPath(), `${roomId}`), '/');
      // @ts-ignore TS2339
      const authContext = this._authApp.getModelData('authContext');
      xhr.open('GET', url.format(cmsService), false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      xhr.send();
    });
  }

  doRoomUsers(roomId) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          try {
            // {"totalCount":1,"users":[{"roomId":"c8239de5-ee50-4dcf-8dde-7b80e3096479","name":"ニックネーム01","createdAt":1604035968435,"updatedAt":1604035969455,"lastJoinAt":1604035969386,"refId":"23923732","id":"69c24b3d-dd9a-4604-bf47-a8899442c69e","customData":{"icon":"https://img.happyon.jp/j31p7JnZ/common/icon-face_png/icon-face03.png?size=300x300","pid":23923732,"gpid":"bce74abb-e39b-4a06-95bd-3bc169c2ca2f","gaid":"46437719-d798-4e4d-a018-13e5b3e53611","aid":23923730,"master":1}}]}
            let response = xhr.response;
            if (typeof response === 'string' && response.length > 0) {
              response = JSON.parse(response);
            }
            if (xhr.status != 200) {
              return reject(response);
            }
            return resolve(response);
          } catch (e) {
            return reject(e);
          }
        }
      };
      const params = { limit: 100 };
      const query = Object.keys(params)
        .map(function(key) {
          return key + '=' + encodeURIComponent(params[key]);
        })
        .join('&');
      let cmsService = Object.assign({}, _.get(this.getModelData('services'), 'cms'));
      // cmsService.pathname = _.join(_.concat(cmsService.path, this.getRoomApiPath(), `1b35083d-90d8-4ee3-bf8a-ea82b2e45a72`), '/');
      cmsService.pathname = _.join(_.concat(cmsService.path, this.getRoomApiPath(), `${roomId}/users`), '/');
      // @ts-ignore TS2339
      const authContext = this._authApp.getModelData('authContext');
      xhr.open('GET', url.format(cmsService) + `?${query}`, false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      xhr.send();
    });
  }

  doDeleteRoom() {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          try {
            //　{"result":true}
            // 削除済み
            // {"result":false}
            let response = xhr.response;
            if (typeof response === 'string' && response.length > 0) {
              response = JSON.parse(response);
            }
            if (xhr.status != 200) {
              return reject(response);
            }
            this.room = null;
            // @ts-ignore TS2339
            if (this.wpc) this.wpc.dispose();
            return resolve(response);
          } catch (e) {
            return reject(e);
          }
        }
      };
      let cmsService = Object.assign({}, _.get(this.getModelData('services'), 'cms'));
      // cmsService.pathname = _.join(_.concat(cmsService.path, this.getRoomApiPath(), `e3414d61-8dac-45a9-8d9a-46e0ae75e22f`), '/');
      cmsService.pathname = _.join(_.concat(cmsService.path, this.getRoomApiPath(), `${this.room.id}`), '/');
      // @ts-ignore TS2339
      const authContext = this._authApp.getModelData('authContext');
      xhr.open('DELETE', url.format(cmsService), false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      xhr.send();
    });
  }

  doCreateUser(profile, opt = {}) {
    // console.log(`doCreateUser`)
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          try {
            let response = xhr.response;
            if (typeof response === 'string' && response.length > 0) {
              response = JSON.parse(response);
            }
            if (xhr.status != 201) {
              return reject(response);
            }
            // @ts-ignore TS2339
            this.user = response.user;
            return resolve(response);
          } catch (e) {
            return reject(e);
          }
        }
      };
      let cmsService = Object.assign({}, _.get(this.getModelData('services'), 'cms'));
      cmsService.pathname = _.join(_.concat(cmsService.path, this.getRoomApiPath(), `${this.roomId}/users`), '/');
      // @ts-ignore TS2339
      const authContext = this._authApp.getModelData('authContext');
      // @ts-ignore TS2339
      const activeProfile = this._authApp.activeProfile();
      const params = {};
      // @ts-ignore TS2339
      params.name = profile.name;
      // @ts-ignore TS2339
      params.refId = '' + activeProfile.id;
      // @ts-ignore TS2339
      params.customData = {
        aid: authContext.id,
        pid: activeProfile.id,
        // icon: profile.icon,
        master: _.get(opt, 'master', 0),
        officialAccount: _.get(opt, 'officialAccount', 0),
      };
      xhr.open('POST', url.format(cmsService), false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      xhr.send(JSON.stringify(params));
    });
  }

  doUpdateUser(profile, opt = {}) {
    // console.log(`doUpdateUser`)
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          try {
            let response = xhr.response;
            if (typeof response === 'string' && response.length > 0) {
              response = JSON.parse(response);
            }
            if (xhr.status != 200) {
              return reject(response);
            }

            // SDKの情報も更新
            // @ts-ignore TS2339
            this.wpc.reloadUser((error, body) => {
              if (_.has(error, 'message')) {
                // 下記のエラーはスルー
                // 内部的にjoinしてないとエラーする
                if (error.message == 'not joined room') {
                  // joinしてないのでここでreturn
                  return resolve(response);
                } else {
                  console.log(error);
                  // TODO エラー処理
                  return reject(error);
                }
              }

              return resolve(response);
            });
          } catch (e) {
            return reject(e);
          }
        }
      };
      let cmsService = Object.assign({}, _.get(this.getModelData('services'), 'cms'));
      cmsService.pathname = _.join(
        // @ts-ignore TS2339
        _.concat(cmsService.path, this.getRoomApiPath(), `${this.roomId}/users/${this.user.id}`),
        '/',
      );
      // @ts-ignore TS2339
      const authContext = this._authApp.getModelData('authContext');
      // @ts-ignore TS2339
      const activeProfile = this._authApp.activeProfile();
      const params = {};
      // @ts-ignore TS2339
      params.name = profile.name;
      // @ts-ignore TS2339
      params.refId = '' + activeProfile.id;
      // @ts-ignore TS2339
      params.customData = {
        aid: authContext.id,
        pid: activeProfile.id,
        // icon: profile.icon,
        // @ts-ignore TS2339
        master: _.get(this.user, 'customData.master', 0),
      };
      xhr.open('PUT', url.format(cmsService), false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      xhr.send(JSON.stringify(params));
    });
  }

  doDeleteUser(userId) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          try {
            let response = xhr.response;
            if (typeof response === 'string' && response.length > 0) {
              response = JSON.parse(response);
            }
            if (xhr.status != 200) {
              return reject(response);
            }
            return resolve(response);
          } catch (e) {
            return reject(e);
          }
        }
      };
      let cmsService = Object.assign({}, _.get(this.getModelData('services'), 'cms'));
      cmsService.pathname = _.join(
        _.concat(cmsService.path, this.getRoomApiPath(), `${this.roomId}/users/${userId}`),
        '/',
      );
      // @ts-ignore TS2339
      const authContext = this._authApp.getModelData('authContext');
      // @ts-ignore TS2339
      const activeProfile = this._authApp.activeProfile();
      xhr.open('DELETE', url.format(cmsService), false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      xhr.send();
    });
  }

  doNgMessage(messageId) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          try {
            let response = xhr.response;
            if (typeof response === 'string' && response.length > 0) {
              response = JSON.parse(response);
            }
            if (xhr.status != 200) {
              return reject(response);
            }
            // @ts-ignore TS2794
            return resolve();
          } catch (e) {
            return reject(e);
          }
        }
      };
      let cmsService = Object.assign({}, _.get(this.getModelData('services'), 'cms'));
      cmsService.pathname = _.join(
        _.concat(cmsService.path, this.getRoomApiPath(), `${this.room.id}/messages/${messageId}/isNGMessage`),
        '/',
      );
      // @ts-ignore TS2339
      const authContext = this._authApp.getModelData('authContext');
      const params = { isNgMessage: true };
      xhr.open('PUT', url.format(cmsService), false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      xhr.send(JSON.stringify(params));
    });
  }

  addEmitterListener(eventName, fn) {
    // @ts-ignore TS2339
    this._listeners.push({
      // @ts-ignore TS2339
      subscription: this._emitter.addListener(eventName, fn),
      eventName: eventName,
      fn: fn,
    });
  }

  removeEmitterListener(eventName, fn) {
    // @ts-ignore TS2339
    let index = _.findIndex(this._listeners, listener => {
      if (fn) {
        // @ts-ignore TS2339
        return listener.eventName == eventName && listener.fn == fn;
      } else {
        // @ts-ignore TS2339
        return listener.eventName == eventName;
      }
    });
    if (index !== -1) {
      // @ts-ignore TS2339
      this._listeners[index].subscription.remove();
      // @ts-ignore TS2339
      this._listeners.splice(index, 1);
    }
  }

  on(eventName, fn) {
    this.addEmitterListener(eventName, fn);
    // @ts-ignore TS2339
    if ('initPlayer' === eventName && this.initPlayer) {
      // @ts-ignore TS2339
      this._emitter.emit('initPlayer', this.adjustComment(this.initPlayer));
      // @ts-ignore TS2339
      delete this.initPlayer;
    }
  }

  off(eventName, fn) {
    this.removeEmitterListener(eventName, fn);
  }
}

export default WatchPartyApp;
