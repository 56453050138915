import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

import MetaTags from '../../../player/MetaTags';
import DetailText from '../../../player/DetailText';
import Meta from '../../../browse/Meta';
import MainViewLink from '../../../../../common/components/MainViewLink';
import routes from '../../../../../common/routes';
import PPVLimit from '../../../meta/elements/PPVLimit';
import useDateBox from '../../hooks/useDateBox';

const WatchInfo = props => {
  let linkProps = {
    to: routes.title,
    params: { id: props.metadata.seasonId },
  };
  if (_.has(props.metadata, 'seasonMeta.refId')) {
    linkProps = {
      to: routes.program,
      params: { id: props.metadata.seasonMeta.refId },
    };
  }

  const dateBox = useDateBox({ meta: props.meta, error: props.error });

  return (
    <div className="watch-info">
      <div className="watch-info__colum_left" id="columLeft">
        {props.titleHidden == false ? (
          <div className="watch-info-title" id="infoTitle">
            {props.metadata.seasonId && props.metadata.title !== props.metadata.playableTitle ? (
              <p className="title">
                <MainViewLink {...linkProps}>{props.metadata.title}</MainViewLink>
              </p>
            ) : null}
            <h1 className="playable-title">
              {props.metadata.seasonId && props.metadata.title == props.metadata.playableTitle ? (
                <MainViewLink {...linkProps}>{props.metadata.title}</MainViewLink>
              ) : (
                props.metadata.playableTitle
              )}
            </h1>
            {/*
             // @ts-ignore TS2322 */}
            <Meta showLiveBadge={true} metadata={props.meta} />
          </div>
        ) : null}
        {/* @ts-ignore TS2322 */}
        {props.titleHidden == false ? <MetaTags metadata={props.meta} hiddenTips={props.hiddenTips} /> : null}
        <DetailText
          // @ts-ignore TS2322
          planInfoRenderCheck={props.planInfoRenderCheck}
          titleHidden={props.titleHidden}
          metadata={props.metadata}
          model={props.model}
          linearFlag={false}
          mediaQueryCheck={props.mediaQueryCheck}
        />
      </div>
      <div className="watch-info__colum_right" id="columRight">
        {props.titleHidden == false ? (
          <div className="action-box">
            <ul className="action-box__inner">
              {props.scheduleButton ? <li key={`scheduleButton`}>{props.scheduleButton}</li> : null}
              {props.myListButton ? <li key={`myListButton`}>{props.myListButton}</li> : null}
              {props.chatButton ? (
                <li key={`chatButton`}>{props.chatButton}</li>
              ) : props.watchPartyButton ? (
                <li key={`watchPartyButton`}>{props.watchPartyButton}</li>
              ) : null}
              {props.shareButton ? <li key={`shareButton`}>{props.shareButton}</li> : null}
            </ul>
          </div>
        ) : null}
        {/*  planinfo経由でrenderされている=PPV期限はない*/}
        {!props.hidePPVLimit ? <PPVLimit metaId={props.meta.id} isPPV={props.meta.rental} model={props.model} /> : null}
        {!_.isEmpty(dateBox) ? (
          <div className="date-box">
            <dl>
              {dateBox.map(date => (
                <>
                  <dt>{date.title}</dt>
                  <dd>{date.text}</dd>
                </>
              ))}
            </dl>
          </div>
        ) : null}
        {_.map(props.metadata.links, (link, i) => {
          return (
            // @ts-ignore TS2322
            <MainViewLink key={`link-${i}`} href={link.url} target={'_blank'} className="watch-info__link">
              {link.name}
            </MainViewLink>
          );
        })}
      </div>
    </div>
  );
};

WatchInfo.contextTypes = {
  getModelData: PropTypes.func,
};

export default WatchInfo;
