import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import url from 'url';
import classnames from 'classnames';
import window from 'global/window';

import Axios from '../../../../common/Axios';
import activeProfile from '../../../../utils/activeProfile';
import { CLICK_AREA } from '../../../../common/GtmApp';
import DeeplinkApp from '../../../bluerose/components/deepLink/DeepLinkApp';

const AppView = (props, context) => {
  const onClickAppDownload = () => {
    if (!context.gtmApp) return;
    context.gtmApp.pushDataLayerOnAppDownloadClick(CLICK_AREA.APP_DOWNLOAD.THUMBNAIL);
  };

  // アプリ起動ボタンタップ
  const _handleAppRun = e => {
    if (context.gtmApp) {
      context.gtmApp.pushDataLayerOnAppLaunchClick(CLICK_AREA.APP_LAUNCH.THUMBNAIL);
    }

    const authContext = context.getModelData('authContext');
    const deeplinkApp = new DeeplinkApp({ routeHandler: context.routeHandler, authContext });
    deeplinkApp.launch(props);
  };

  const castButtonRef = React.createRef();
  const _handleCastButton = e => {
    // @ts-ignore TS2339
    castButtonRef.current.click();
    if (props.onClickCastButton) props.onClickCastButton(e);
  };

  const _renderAppLink = () => {
    const config = context.getModelData('config');
    const browserInfo = context.getModelData('browserInfo');
    const isAndroid = _.get(browserInfo, 'isAndroid');
    const isIOS = _.get(browserInfo, 'isIOS');
    const isRequestDesktopWebsite = _.get(browserInfo, 'isRequestDesktopWebsite');
    const isKindleFire = _.get(browserInfo, 'isKindleFire');

    // AndroidでもiOSでも無かった場合は何も表示しない
    if (!isAndroid && !isIOS && !isRequestDesktopWebsite) {
      return null;
    }

    return (
      <div className="app-view-actions">
        <a
          href="javascript:void(0)"
          className={classnames('btn', {
            'ios-app-launch-btn': isIOS || isRequestDesktopWebsite,
            'android-app-launch-btn': isAndroid && !isKindleFire,
            'amazon-app-launch-btn': isKindleFire,
          })}
          onClick={_handleAppRun}
        >
          アプリで視聴
        </a>
        {isIOS || isRequestDesktopWebsite ? (
          <a
            href={_.get(config, 'store_urls.ios')}
            className="btn ios-app-banner-btn"
            target="_blank"
            onClick={onClickAppDownload}
          >
            <img src="/images/app_apple.svg" />
          </a>
        ) : isKindleFire ? (
          <a
            href={_.get(config, 'store_urls.amazon')}
            className="btn amazon-app-banner-btn"
            target="_blank"
            onClick={onClickAppDownload}
          >
            {' '}
            <img src="/images/amazon-appstore-black.svg" />{' '}
          </a>
        ) : isAndroid ? (
          <a href={_.get(config, 'store_urls.android')} className="btn android-app-banner-btn" target="_blank">
            <img src="/images/google-play-badge.svg" onClick={onClickAppDownload} />
          </a>
        ) : null}
      </div>
    );
  };

  const message = props.message || 'ご視聴にはアプリインストールが必要です。';
  return (
    <div className="app-view">
      <p className="whoops">{message}</p>
      {_renderAppLink()}
    </div>
  );
};

AppView.contextTypes = {
  models: PropTypes.object,
  getModelData: PropTypes.func,
  gtmApp: PropTypes.object,
  routeHandler: PropTypes.object,
};

AppView.propTypes = {
  metaId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onClickCastButton: PropTypes.func,
};

export default AppView;
