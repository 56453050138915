import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import window from 'global/window';
import classnames from 'classnames';

import UseComment from './UseComment';
import RoomShare from './RoomShare';
import KickUser from './KickUser';
import NicknameEdit from './NicknameEdit';
import WatchPartyApp from '../../../../../common/WatchPartyApp';
import * as browserEvents from '../../../../../sketch-platform/utils/browserEvents';

import { Picker } from 'emoji-mart';
import Emoji from 'react-emoji-render';

class CommentAgo extends Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }
  constructor(props, context) {
    super(props, context);
    this.agoTimeUpdate = this.agoTimeUpdate.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }
  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.context.watchPartyApp.on('agoTimeUpdate', this.agoTimeUpdate);
  }
  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    this.context.watchPartyApp.off('agoTimeUpdate', this.agoTimeUpdate);
  }
  agoTimeUpdate() {
    // @ts-ignore TS2339
    if (this._isMounted) this.forceUpdate();
  }
  render() {
    // @ts-ignore TS2339
    return WatchPartyApp.getAgo(this.props.sentAt, this.context);
  }
}

class UserName extends Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }
  constructor(props, context) {
    super(props, context);
    this.updateProfile = this.updateProfile.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }
  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.context.watchPartyApp.on('updateProfile', this.updateProfile);
  }
  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    this.context.watchPartyApp.off('updateProfile', this.updateProfile);
  }
  updateProfile() {
    // @ts-ignore TS2339
    if (this._isMounted) this.forceUpdate();
  }
  render() {
    // 最小限の描画で済ませたい為、取得できる前提で処理
    const profile = this.context.watchPartyApp.getProfile();
    return (
      <div className="user-name">
        <div className="name">
          <p>{profile.name}</p>
        </div>
        {/*
         // @ts-ignore TS2339 */}
        <button className="fa-ellipsis-v name-edit" onClick={this.props.onClick}></button>
      </div>
    );
  }
}

class Comment extends Component {
  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.commentListRef = React.createRef();
    // @ts-ignore TS2339
    this.commentInnerRef = React.createRef();
    this.state = {
      agreement: false,
      messages: [],
      newComment: false,
    };
    this._handleUserCommentClick = this._handleUserCommentClick.bind(this);
    this.termsChange = this.termsChange.bind(this);
    this._handleRoomShareModalClick = this._handleRoomShareModalClick.bind(this);
    this._handleNicknameEditModalClick = this._handleNicknameEditModalClick.bind(this);
    this._jamp = this._jamp.bind(this);
    this._kickUser = this._kickUser.bind(this);
    this._hideMessage = this._hideMessage.bind(this);
    this._postMessage = this._postMessage.bind(this);
    this._masterMenu = this._masterMenu.bind(this);

    this.wpError = this.wpError.bind(this);
    this.wpJoinAnnouncement = this.wpJoinAnnouncement.bind(this);
    this.wpLeaveAnnouncement = this.wpLeaveAnnouncement.bind(this);
    this.wpGift = this.wpGift.bind(this);
    this.wpMessage = this.wpMessage.bind(this);
    this.wpMessageUpdated = this.wpMessageUpdated.bind(this);
    this.wpUserUpdated = this.wpUserUpdated.bind(this);
    this.wpCommand = this.wpCommand.bind(this);
    this.hiddenNewCommentMsg = this.hiddenNewCommentMsg.bind(this);
    this.showNewestComment = this.showNewestComment.bind(this);
    this.commentAutoScroll = this.commentAutoScroll.bind(this);
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      isIframe: PropTypes.bool,
      authApp: PropTypes.object,
      watchPartyApp: PropTypes.object,
    };
  }
  static get propTypes() {
    return {
      watchPartyType: PropTypes.string,
    };
  }

  wpError(error) {
    console.log(error);
  }

  wpJoinAnnouncement(data) {
    // console.log('Comment: joinAnnouncement', data);

    // open型は表示しない
    // masterの通知は不要
    // @ts-ignore TS2339
    if (this.props.watchPartyType == 'openType' || WatchPartyApp.isSenderMaster(data)) return;

    this.commentPush(
      data,
      <div key={data.id} className="comment-notice">
        <Emoji text={`${data.sender.name} が入室しました`} />
        <span className="ago">
          {/*
           // @ts-ignore TS2322 */}
          <CommentAgo sentAt={data.sentAt} />
        </span>
      </div>,
    );
  }

  wpLeaveAnnouncement(data) {
    // console.log('Comment: leaveAnnouncement', data);

    // 自分自身が退室
    const activeProfile = this.context.authApp.activeProfile();
    if (activeProfile && activeProfile.id == data.sender.refId) {
      this.setState({ agreement: false });
      return;
    }

    // open型は表示しない
    // masterの通知は不要
    // @ts-ignore TS2339
    if (this.props.watchPartyType == 'openType' || WatchPartyApp.isSenderMaster(data)) return;

    this.commentPush(
      data,
      <div key={data.id} className="comment-notice">
        <Emoji text={`${data.sender.name} が退室しました`} />
        <span className="ago">
          {/*
           // @ts-ignore TS2322 */}
          <CommentAgo sentAt={data.sentAt} />
        </span>
      </div>,
    );
  }

  wpGift(data) {
    // console.log('Comment: gift', data);
  }

  wpMessage(data) {
    // console.log('Comment: message', data);
    this.commentPush(
      data,
      <div
        key={data.id}
        className={classnames('comment-item', WatchPartyApp.isSenderOfficialAccount(data) ? 'official' : null)}
      >
        <div className="text-box">
          <div className="nickname">
            {data.sender.name}
            {WatchPartyApp.isSenderMaster(data) ? <span className="master">- Admin</span> : null}
            {WatchPartyApp.isSenderOfficialAccount(data) ? (
              <span className="official-icon">
                <img src="../../images/wp/admin-icon.svg" />
              </span>
            ) : null}
            <span className="ago">
              {/* @ts-ignore */}
              <CommentAgo sentAt={data.sentAt} />
            </span>
            {data.customData.currentTime ? (
              <div className="time">{this._getFormatedTimeOfComment(data.customData.currentTime)}</div>
            ) : null}
          </div>
          <Emoji text={data.message} />
        </div>
        {this.context.watchPartyApp.isMaster() ? (
          <div className="masterMenuBtn" onMouseEnter={this._masterMenu} onMouseLeave={this._masterMenu}>
            <i className="fa-ellipsis-menu"></i>
            <div className="master-menu">
              <ul>
                {_.get(data, 'customData.currentTime') ? (
                  <li onClick={e => this._jamp(data.customData.currentTime)}>ジャンプする</li>
                ) : null}
                {!WatchPartyApp.isSenderMaster(data) ? (
                  <li onClick={e => this._kickUser(data.sender)}>このユーザーを退室させる</li>
                ) : null}
                <li onClick={e => this._hideMessage(data.id)}>このコメントを非表示にする</li>
              </ul>
            </div>
          </div>
        ) : null}
      </div>,
    );
  }

  wpMessageUpdated(data) {
    // console.log('Comment: messageUpdated', data);
    this.setState({ messages: this.context.watchPartyApp.getComments() }, this.commentAutoScroll);
  }

  wpUserUpdated(data) {
    // console.log('Comment: wpUserUpdated', data);

    // open型は表示しない
    // @ts-ignore TS2339
    if (this.props.watchPartyType == 'openType') return;

    this.commentPush(
      data,
      <div key={data.id} className="comment-notice">
        <span>{data.user.name} ニックネームを編集しました</span>
        <span className="ago">
          {/*
           // @ts-ignore TS2322 */}
          <CommentAgo sentAt={data.sentAt} />
        </span>
      </div>,
    );
  }

  wpCommand(data) {
    // console.log('Comment: wpCommand', data);

    if (data.event === 'play') {
      let currentTime = this._getFormatedCurrentTime(data.customData.currentTime);
      this.commentPush(
        data,
        <div key={data.id} className="comment-notice">
          <span>
            {data.sender.name} {currentTime}で再生開始しました
          </span>
          <span className="ago">
            {/*
             // @ts-ignore TS2322 */}
            <CommentAgo sentAt={data.sentAt} />
          </span>
        </div>,
      );
    } else if (data.event === 'pause') {
      let currentTime = this._getFormatedCurrentTime(data.customData.currentTime);
      this.commentPush(
        data,
        <div key={data.id} className="comment-notice">
          <span>
            {data.sender.name} {currentTime}で一時停止しました
          </span>
          <span className="ago">
            {/*
             // @ts-ignore TS2322 */}
            <CommentAgo sentAt={data.sentAt} />
          </span>
        </div>,
      );
    } else if (data.event === 'seek') {
      let currentTime = this._getFormatedCurrentTime(data.customData.currentTime);
      this.commentPush(
        data,
        <div key={data.id} className="comment-notice">
          <span>
            {data.sender.name} 再生位置を{currentTime}に移動しました
          </span>
          <span className="ago">
            {/*
             // @ts-ignore TS2322 */}
            <CommentAgo sentAt={data.sentAt} />
          </span>
        </div>,
      );
    }
  }

  commentPush(data, comment) {
    // @ts-ignore TS2339
    const el = this.commentListRef.current;
    // @ts-ignore TS2339
    const inner = this.commentInnerRef.current;
    const activeProfile = this.context.authApp.activeProfile();

    // 自動スクロール判定
    let autoScroll = true;
    if (
      el &&
      inner &&
      el.clientHeight + el.scrollTop + 5 < inner.clientHeight &&
      activeProfile &&
      activeProfile.id != data.sender.refId
    ) {
      // スクロール状態（スクロールバーが表示されて、ユーザが最後のコメントを見てない）場合
      // かつ、自分が送ったコメントではない場合
      autoScroll = false;
    }

    this.context.watchPartyApp.addComment(comment);
    // 既に表示されている時はそのまま、メッセージ以外のコメントは表示しない
    // @ts-ignore TS2339
    const newComment = this.state.newComment ? true : _.has(data, 'message') && !autoScroll;
    this.setState({ messages: this.context.watchPartyApp.getComments(), newComment }, () => {
      if (autoScroll) this.showNewestComment();
    });
  }

  hiddenNewCommentMsg() {
    // @ts-ignore TS2339
    const el = this.commentListRef.current;
    // @ts-ignore TS2339
    const inner = this.commentInnerRef.current;
    if (el && inner && el.clientHeight + el.scrollTop > inner.clientHeight - 30) {
      // [新しいコメントがあります]を非表示
      this.setState({ newComment: false });
    }
  }

  showNewestComment() {
    // [新しいコメントがあります]を非表示、またコメントを下に表示させる
    this.setState({ newComment: false }, this.commentAutoScroll);
  }

  commentAutoScroll() {
    // console.log('Comment: commentAutoScroll');

    // @ts-ignore TS2339
    if (this.state.newComment) return;

    // @ts-ignore TS2339
    const el = this.commentListRef.current;
    // @ts-ignore TS2339
    const inner = this.commentInnerRef.current;
    if (el && inner) {
      // コメントを下に表示させる為の調整
      let spacer = el.clientHeight - inner.clientHeight;
      if (spacer < 0) spacer = 0;
      inner.style.marginTop = spacer + 'px';
      el.scrollTop = el.scrollHeight;
    }
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;

    const done = () => {
      // リスナー登録
      this.context.watchPartyApp.on('error', this.wpError);
      this.context.watchPartyApp.on('joinAnnouncement', this.wpJoinAnnouncement);
      this.context.watchPartyApp.on('leaveAnnouncement', this.wpLeaveAnnouncement);
      this.context.watchPartyApp.on('gift', this.wpGift);
      this.context.watchPartyApp.on('message', this.wpMessage);
      this.context.watchPartyApp.on('messageUpdated', this.wpMessageUpdated);
      this.context.watchPartyApp.on('userUpdated', this.wpUserUpdated);
      this.context.watchPartyApp.on('command', this.wpCommand);
      // コメント復元
      this.setState({ messages: this.context.watchPartyApp.getComments() }, this.commentAutoScroll);
      // 再開
      this.context.watchPartyApp.start((error, body) => {
        // console.log(error, body);
        if (error) {
          console.log(error);
          // TODO: エラー処理
        }
      });
    };
    this.context.watchPartyApp
      .doUserJoinCheck()
      .then(joined => {
        // roomにjoin済みの場合は規約同意済み
        if (joined) {
          this.setState({ agreement: true }, done);

          // joinしていない場合
        } else {
          done();
        }
      })
      .catch(e => {
        // TODO: エラー処理
        console.log(e);
      });

    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.commentAutoScroll);
    } else {
      browserEvents.addEventListener('resize', this.commentAutoScroll);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;

    // 一時停止
    this.context.watchPartyApp.pause((error, body) => {
      // console.log(error, body);
      if (error) {
        console.log(error);
        // TODO: エラー処理
      }
    });
    // リスナー削除
    this.context.watchPartyApp.off('error', this.wpError);
    this.context.watchPartyApp.off('joinAnnouncement', this.wpJoinAnnouncement);
    this.context.watchPartyApp.off('leaveAnnouncement', this.wpLeaveAnnouncement);
    this.context.watchPartyApp.off('gift', this.wpGift);
    this.context.watchPartyApp.off('message', this.wpMessage);
    this.context.watchPartyApp.off('messageUpdated', this.wpMessageUpdated);
    this.context.watchPartyApp.off('userUpdated', this.wpUserUpdated);
    this.context.watchPartyApp.off('command', this.wpCommand);
  }

  _handleUserCommentClick() {
    // @ts-ignore TS2339
    this.props.showModal(
      <UseComment
        // @ts-ignore TS2339
        closeModal={this.props.closeModal}
        // @ts-ignore TS2339
        titleModel={this.props.titleModel}
        // @ts-ignore TS2339
        seriesModel={this.props.seriesModel}
        // @ts-ignore TS2322
        linearModel={this.props.linearModel}
        // @ts-ignore TS2339
        handleWatchPartyModeChange={this.props.handleWatchPartyModeChange}
        termsChange={this.termsChange}
      />,
      { classes: 'watchParty-modal' },
    );
  }

  termsChange() {
    // @ts-ignore TS2339
    this.props.closeModal();
    this.setState({ agreement: true });
  }

  _handleRoomShareModalClick() {
    // @ts-ignore TS2339
    this.props.showModal(<RoomShareModal closeModal={this.props.closeModal} titleModel={this.props.titleModel} />, {
      classes: 'watchParty-modal',
    });
  }

  _handleNicknameEditModalClick() {
    // @ts-ignore TS2339
    this.props.showModal(<NicknameEdit closeModal={this.props.closeModal} titleModel={this.props.titleModel} />, {
      classes: 'watchParty-modal nickname-edit-modal',
    });
  }

  _jamp(currentTime) {
    // @ts-ignore TS2339
    this.props.player.currentTime(currentTime);
  }

  _kickUser(user) {
    // @ts-ignore TS2339
    this.props.showModal(<KickUser closeModal={this.props.closeModal} user={user} />, { classes: 'watchParty-modal' });
  }

  _hideMessage(messageId) {
    this.context.watchPartyApp.ngMessage(messageId);
  }

  _postMessage(value) {
    const opt: { currentTime?: number } = {};
    // close型のみ
    // @ts-ignore TS2339
    if (this.props.watchPartyType == 'closeType') {
      // @ts-ignore TS2339
      opt.currentTime = this.props.player.currentTime();
    }
    this.context.watchPartyApp.postMessage(value, opt, (error, body) => {
      if (error) {
        console.log(error);
      }
    });
  }

  _masterMenu(e) {
    if (e.currentTarget.classList.contains('open')) {
      e.currentTarget.classList.remove('open');
    } else {
      e.currentTarget.classList.add('open');
    }
  }

  // @ts-ignore TS2339
  _getFormatedCurrentTime(currentTime = this.props.player.currentTime()) {
    let h = Math.floor(currentTime / 3600); //時間
    let m = Math.floor((currentTime - h * 3600) / 60); //分
    let s = Math.round(currentTime - h * 3600 - m * 60); //秒
    let ct = ('000' + h).slice(-2) + ':' + ('000' + m).slice(-2) + ':' + ('000' + s).slice(-2);
    return ct;
  }

  _getFormatedTimeOfComment(currentTime) {
    let h = Math.floor(currentTime / 3600); //時間
    let m = Math.floor((currentTime - h * 3600) / 60); //分
    let s = Math.round(currentTime - h * 3600 - m * 60); //秒
    let ct = '';
    if (h > 0) {
      // 10時間2分3秒 -> 10:02:03
      // 1時間2分3秒 -> 1:02:03
      ct += h + ':' + ('000' + m).slice(-2) + ':' + ('000' + s).slice(-2);
    } else {
      // 2分3秒 -> 2:03
      // 3秒 -> 0:03
      ct += m + ':' + ('000' + s).slice(-2);
    }
    return ct;
  }

  render() {
    const profile = this.context.watchPartyApp.getProfile();
    if (!profile) return null;

    const handleSignup = () => {
      if (this.context.isIframe) {
        const host = _.get(this.context.getModelData('hosts'), 'host');
        window.parent.location.href = `${host}/signup`;
      } else {
        window.location.href = '/signup';
      }
    };

    const handleLogin = () => {
      if (this.context.isIframe) {
        const host = _.get(this.context.getModelData('hosts'), 'host');
        window.parent.location.href = `${host}/login`;
      } else {
        window.location.href = '/login';
      }
    };

    const userInfo = this.context.getModelData('userInfo');
    let commentBottom = null;
    // Close型はそのままコメント入力欄を表示
    // Open型ログイン済みかどうかを判定
    // その後必ず規約に同意して開始させる

    // @ts-ignore TS2339
    if (this.props.watchPartyType == 'closeType') {
      // @ts-ignore TS2322
      commentBottom = <CommentInput postMessage={this._postMessage} />;
      // @ts-ignore TS2339
    } else if (this.props.watchPartyType == 'openType') {
      // @ts-ignore TS2339
      if (userInfo.status !== 'NON_REGISTERED_MEMBER' && this.state.agreement) {
        // ログイン済みで規約同意済み
        // @ts-ignore TS2322
        commentBottom = <CommentInput postMessage={this._postMessage} />;
        // @ts-ignore TS2339
      } else if (userInfo.status !== 'NON_REGISTERED_MEMBER' && !this.state.agreement) {
        // ログイン済みで規約未同意
        commentBottom = (
          <button type="button" className="btn btn-fill btn-wide" onClick={this._handleUserCommentClick}>
            チャットに参加する
          </button>
        );
        // } else {
        //   // 未ログイン
        //   commentBottom = (
        //     <React.Fragment>
        //       <button type="button" className="btn btn-fill btn-wide btn-very-small" onClick={handleSignup}>新規会員登録してチャットに参加する</button>
        //       <button type="button" className="btn btn-line btn-wide btn-very-small" onClick={handleLogin}>ログイン</button>
        //     </React.Fragment>
        //   );
      }
    }

    const isChannel =
      // @ts-ignore TS2339
      this.props.titleModel.type == 'linear_channel_meta' &&
      // @ts-ignore TS2339
      !this.props.titleModel.isMulti &&
      // @ts-ignore TS2339
      !this.props.titleModel.isLiveEvent;
    const isLiveEvent =
      // @ts-ignore TS2339
      this.props.titleModel.type == 'linear_channel_meta' &&
      // @ts-ignore TS2339
      (this.props.titleModel.isMulti || this.props.titleModel.isLiveEvent);

    // 主催者の場合は.WatchPartyCommentに.masterをつけてください
    return (
      <div
        // @ts-ignore TS2339
        className={classnames('WatchPartyComment', this.props.watchPartyType, {
          master: this.context.watchPartyApp.isMaster(),
          // @ts-ignore TS2339
          commentOpen: this.state.agreement,
          // @ts-ignore TS2339
          nonRegistered: this.props.watchPartyType == 'openType' && userInfo.status == 'NON_REGISTERED_MEMBER',
        })}
      >
        <div className="WatchPartyComment-head">
          <div className="room-detail">
            {/*
             // @ts-ignore TS2339 */}
            {this.props.watchPartyType == 'closeType' ? (
              <p className="room-name">{this.context.watchPartyApp.getRoom().name}</p>
            ) : (
              <p className="room-name">
                {/*
                 // @ts-ignore TS2339 */}
                オープンチャット{userInfo.status !== 'NON_REGISTERED_MEMBER' && this.state.agreement ? ' / ' : null}
              </p>
            )}
            {userInfo.status !== 'NON_REGISTERED_MEMBER' &&
            // @ts-ignore TS2339
            this.props.watchPartyType !== 'closeType' &&
            // @ts-ignore TS2339
            this.state.agreement ? (
              // @ts-ignore TS2322
              <UserName onClick={this._handleNicknameEditModalClick} />
            ) : null}
            {/*
             // @ts-ignore TS2339 */}
            {this.props.watchPartyType == 'closeType' && this.context.watchPartyApp.isMaster() ? (
              <button className="fa-share room-share" onClick={this._handleRoomShareModalClick}></button>
            ) : null}
          </div>
          {/*
           // @ts-ignore TS2339 */}
          {userInfo.status !== 'NON_REGISTERED_MEMBER' && this.props.watchPartyType == 'closeType' ? (
            // @ts-ignore TS2322
            <UserName onClick={this._handleNicknameEditModalClick} />
          ) : null}
        </div>
        {/*
         // @ts-ignore TS2339 */}
        <div ref={this.commentListRef} className="WatchPartyComment-comment" onScroll={this.hiddenNewCommentMsg}>
          {/*
           // @ts-ignore TS2339 */}
          <div ref={this.commentInnerRef} className="comment-inner">
            {/*
             // @ts-ignore TS2339 */}
            {this.state.messages}
          </div>
        </div>
        {/*
         // @ts-ignore TS2339 */}
        <div className={classnames('comment-new', { show: this.state.newComment })} onClick={this.showNewestComment}>
          新着コメント<i className="fa-caret_down"></i>
        </div>
        {commentBottom ? <div className="WatchPartyComment-bottom">{commentBottom}</div> : null}
      </div>
    );
  }
}

export default Comment;

class RoomShareModal extends Component {
  static get propTypes() {
    return {
      closeModal: PropTypes.func,
      titleModel: PropTypes.object,
      seriesModel: PropTypes.object,
      linearModel: PropTypes.object,
    };
  }

  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <div className="text-left">
        {/*
         // @ts-ignore TS2339 */}
        <RoomShare titleModel={this.props.titleModel} room={this.context.watchPartyApp.getRoom()} />
        <div className="form-btn-block text-center">
          {/*
           // @ts-ignore TS2339 */}
          <a className="btn btn-gray btn-small" href="javascript:void(0);" onClick={this.props.closeModal}>
            閉じる
          </a>
        </div>
      </div>
    );
  }
}

class CommentInput extends Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      emoji: false,
    };
    // @ts-ignore TS2339
    this.inputValue = null;
    // @ts-ignore TS2339
    this.inputRef = React.createRef();
    // @ts-ignore TS2339
    this.countRef = React.createRef();

    this.textCount = this.textCount.bind(this);
    this.onClickEmojiButton = this.onClickEmojiButton.bind(this);
    this.emoji = this.emoji.bind(this);

    this._handleEnter = this._handleEnter.bind(this);
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    const el = this.inputRef.current;
    if (el) el.focus();
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  textCount() {
    // @ts-ignore TS2339
    let value = this.inputRef.current.value;
    // @ts-ignore TS2339
    this.countRef.current.innerText = value.length;

    if (value.length >= 100) {
      // @ts-ignore TS2339
      this.inputRef.current.style.color = '#2CB5FF';
      // @ts-ignore TS2339
      this.countRef.current.style.color = '#2CB5FF';
    } else {
      // @ts-ignore TS2339
      this.inputRef.current.style = '';
      // @ts-ignore TS2339
      this.countRef.current.style = '';
    }
  }

  onClickEmojiButton() {
    // @ts-ignore TS2339
    if (this.state.emoji) {
      this.setState({ emoji: false });
    } else {
      this.setState({ emoji: true });
    }
  }

  emoji(e) {
    let emoji = e;
    // @ts-ignore TS2339
    let value = this.inputRef.current.value;
    // @ts-ignore TS2339
    let pos = this.inputRef.current.selectionStart;

    if (value.length < 100) {
      value = value.split('');
      value.splice(pos, 0, emoji.native);
      value = value.join('');
      // @ts-ignore TS2339
      this.inputRef.current.value = value;
      this.textCount();
    }
    // @ts-ignore TS2339
    this.inputRef.current.setSelectionRange(pos + emoji.native.length, pos + emoji.native.length);
    // @ts-ignore TS2339
    this.inputRef.current.focus();
  }

  _handleEnter(e) {
    if (e.keyCode == 13) {
      // @ts-ignore TS2339
      const value = this.inputRef.current.value;
      // @ts-ignore TS2339
      this.inputRef.current.value = '';
      this.textCount();
      // @ts-ignore TS2339
      if (this.state.emoji) this.onClickEmojiButton();
      // @ts-ignore TS2339
      this.props.postMessage(value);
    }
  }

  render() {
    return (
      <React.Fragment>
        {/*
         // @ts-ignore TS2339 */}
        {this.state.emoji ? (
          <Picker
            title={'emoji'}
            style={{
              width: '100%',
              border: 'none',
            }}
            i18n={{
              search: '検索',
              categories: {
                search: '検索結果',
                recent: 'よく使う絵文字',
                people: '顔 & 人',
                nature: '動物 & 自然',
                foods: '食べ物 & 飲み物',
                activity: 'アクティビティ',
                places: '旅行 & 場所',
                objects: 'オブジェクト',
                symbols: '記号',
                flags: '旗',
                custom: 'カスタム',
              },
            }}
            onSelect={emoji => this.emoji(emoji)}
            theme={'dark'}
            showPreview={false}
            showSkinTones={false}
            perLine={7}
            native={true}
          />
        ) : null}
        <div className="input-inner">
          <input
            // @ts-ignore TS2339
            ref={this.inputRef}
            type="text"
            // @ts-ignore TS2322
            maxLength="100"
            placeholder={'チャットに投稿しよう'}
            onChange={this.textCount}
            onKeyDown={this._handleEnter}
          />
          <div className="text-count">
            {/*
             // @ts-ignore TS2339 */}
            <span ref={this.countRef}>0</span>/100
          </div>
          <button
            // @ts-ignore TS2339
            className={classnames('fa fa-stamp stamp-btn', { open: this.state.emoji })}
            aria-label={'絵文字'}
            onClick={this.onClickEmojiButton}
          ></button>
        </div>
      </React.Fragment>
    );
  }
}
