import _ from 'src/libs/util';
import * as ERROR from 'src/constants/error';
// TODO: injectionするようにする
import tokenDataStore from 'src/utils/tokenDataStore';
import { PlaybackApiInterface } from 'src/interface/api/PlaybackApiInterface';
import { PlaybackUsecaseInterface } from 'src/interface/usecase/apps/PlaybackUsecaseInterface';

export class PlaybackUsecase implements PlaybackUsecaseInterface {
  readonly playbackApi: PlaybackApiInterface;
  readonly tokenRepository: typeof tokenDataStore;
  constructor(playbackApi: PlaybackApiInterface) {
    this.playbackApi = playbackApi;
    this.tokenRepository = tokenDataStore;
  }
  // TODO:そのほかのエラーもここに集約したい
  private convertError(error) {
    const code = _.get(error, 'data.code');
    // 権利なし
    // 92056001 : BS未登録者NG
    // 92056002 : 配信契約無料トライアル中NG
    if (code === 2056 || code === 92056001 || code === 92056002) {
      return ERROR.LICENSE_INVALID;
    }
    // 無効な再生セッションID
    else if (code === 2061) {
      return ERROR.PLAYBACK_SESSION_ID_INVALID;
    }
    if (code) {
      // Logica系のエラーは接頭辞L-を付与する
      if (code) {
        _.set(error, 'data.dcode', `L-${code}`);
      }
    }

    return error.data;
  }

  async auth(params, headers?) {
    try {
      const res = await this.playbackApi.auth(params, { headers });
      this.tokenRepository.setAuthContextData(res.data.authContext);
      if (res.data.result) {
        return res.data;
      } else {
        throw this.convertError(res.data.error);
      }
    } catch (e) {
      throw e;
    }
  }

  async reauth(params, headers?) {
    try {
      const res = await this.playbackApi.reauth(params, { headers });
      this.tokenRepository.setAuthContextData(res.data.authContext);
      if (res.data.result) {
        return res.data;
      } else {
        const error = res.data.error;
        if (_.startsWith(error.status.toString(), '5')) {
          throw ERROR.REAUTH_SERVER_ERROR;
          // timeout系
        } else if (error.status == 408 || _.get(error, 'code') == 'ECONNABORTED') {
          throw ERROR.TIMEOUT;
        }
        throw this.convertError(error);
      }
    } catch (e) {
      if (_.get(e, 'response.status')) {
        if (_.startsWith(_.get(e, 'response.status').toString(), '5')) {
          throw ERROR.REAUTH_SERVER_ERROR;
          // timeout系
        } else if (_.get(e, 'response.status') == 408 || _.get(e, 'code') == 'ECONNABORTED') {
          throw ERROR.TIMEOUT;
        }
      }
      throw e;
    }
  }

  // 再生可否の確認
  async checkAvailability(params, headers?) {
    try {
      const res = await this.playbackApi.checkAvailability(params, { headers });
      this.tokenRepository.setAuthContextData(res.data.authContext);
      if (res.data.result) {
        return res.data;
      } else {
        throw this.convertError(res.data.error);
      }
    } catch (e) {
      throw e;
    }
  }
}
