import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

import InterestApp from '../../../../common/InterestApp';
import PaletteContent from './PaletteContent';
import activeProfile from '../../../../utils/activeProfile';

export default class Interest extends React.Component {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      models: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // DeleteAppを流用する
    // @ts-ignore TS2339
    this._deleteApp = new InterestApp(context.getModelData('services', 'cms'));
    // @ts-ignore TS2339
    this._deleteApp.toggleMode();
    this.handleSend = this.handleSend.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {};
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;

    // 一旦表示したタイミング
    const authContext = this.context.getModelData('authContext');
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    // @ts-ignore TS2339
    this._deleteApp.registered(authContext, profile.id);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  handleSend() {
    const authContext = this.context.getModelData('authContext');
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    // @ts-ignore TS2339
    this._deleteApp
      .send(authContext, profile.id)
      .then(response => {
        // @ts-ignore TS2339
        if (!this._isMounted) return;
        // @ts-ignore TS2339
        this.props.closeModal();
      })
      .catch(e => {
        // @ts-ignore TS2339
        if (!this._isMounted) return;
        // @ts-ignore TS2339
        this.props.closeModal();
      });
  }
  handleClose() {
    // @ts-ignore TS2339
    this.props.closeModal();
  }

  render() {
    const idKey = _.get(this.context.models, 'config.data.palette.interests', 'interests');
    return (
      <div className="interest-wrapper">
        <p>好みを選んでWOWOWを自分用にカスタマイズしましょう</p>
        <PaletteContent
          {...this.props}
          id={idKey}
          showSpaOnly={true}
          shouldSkipGtm={true}
          headerVisuable={false}
          useHelmet={false}
          // @ts-ignore TS2322
          deleteApp={this._deleteApp}
        />
        <div className="btn-wrapper">
          <a href="javascript:void(0);" className="btn btn-fill" onClick={this.handleSend}>
            マイリスト登録
          </a>
          <a href="javascript:void(0);" className="skip-btn" onClick={this.handleClose}>
            スキップ<i className="fa-angle_right"></i>
          </a>
        </div>
      </div>
    );
  }
}
