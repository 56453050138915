import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropDown from '../../../common/components/DropDown';

import URLGenerator from '../../../../sketch-platform/ui/routing/URLGenerator';
import classnames from 'classnames';

const SortGallery = (props, context) => {
  if (props.sortOrders.length < 2) return null;

  const renderOptions = () => {
    const { history, routeHandler } = context;
    return props.sortOrders.map((option, key) => {
      const execSort = () => {
        if (routeHandler.query.so == option.so) {
          return null;
        }
        delete routeHandler.query.p;
        const options = {
          path: routeHandler.path,
          query: Object.assign({}, routeHandler.query, {
            so: option.so,
          }),
        };
        const to = URLGenerator.createRelative(options);
        if (props.onChangeQuery) {
          history.push(to, { norender: true });
          routeHandler.query = options.query;
          props.onChangeQuery(options.query);
        } else {
          history.push(to);
        }
      };

      return (
        <a
          className="sub-menu-link"
          role="link"
          // @ts-ignore TS2322
          tabIndex="0"
          onClick={e => {
            e.preventDefault();
            execSort();
          }}
          onKeyDown={e => {
            if (e.which === 13 || e.keyCode === 13) {
              e.preventDefault();
              execSort();
            }
          }}
        >
          {option.name}
        </a>
      );
    });
  };
  const getOptionName = so => {
    let name = null;
    props.sortOrders.forEach(option => {
      if (option.so == so) {
        name = option.name;
      }
    });
    return name;
  };
  const dropDownProps = {
    cols: 1,
    defaultValue: getOptionName(props.sortOrder),
    options: renderOptions(),
    widthRestricted: true,
  };

  return (
    <div className={classnames('sort-gallery', { right: props.positionRight, 'sort-icon': !props.hideSortLabel })}>
      <DropDown {...dropDownProps} />
    </div>
  );
};

SortGallery.getPaths = function() {
  return [];
};

SortGallery.propTypes = {
  sortOrders: PropTypes.array.isRequired,
};

SortGallery.defaultProps = {
  sortOrders: [
    { so: 'po', name: '人気順' },
    { so: 'ps', name: '新着順' },
    // {so: 'yr', name: '新しい作品順'},
    // {so: 'lg', name: '古い作品順'},
    { so: 'az', name: '昇順（作品名）' },
    { so: 'za', name: '降順（作品名）' },
  ],
};

SortGallery.contextTypes = {
  history: PropTypes.object,
  routeHandler: PropTypes.object,
};

export default SortGallery;
