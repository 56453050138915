import _ from 'src/libs/util';
import url from 'url';

import Axios from '../../../common/Axios';

/*
 * セッション引き継ぐためにワンタイムトークンを取得する
 * req;
 *   method;
 *     POST
 *   url;
 *     https://stg-br-token.logica.io/onetime-token/publish
 *   header:
 *     X-Token-Id: id
 *     Authorization: Bearer accexxToken
 * res:
 *   {
 *       "expires_in": 300,
 *       "onetime_token": "7fd3e0bb-5c1b-4559-b3e1-707d859ca2cd",
 *       "status": "success"
 *   }
 *
 * サーバサイドで使うとき
 * fetchOnetimeToken(_.assign({}, config.services.tokenManager), req.authContext);
 *
 * クライアントで使うとき
 * let tokenService = _.assign({}, context.getModelData('services', 'tokenManager'));
 * const authContext = context.getModelData('authContext');
 * fetchOnetimeToken(_.assign({}, tokenService), req.authContext);
 *
 */
const fetchOnetimeToken = function(tokenService, authContext) {
  // @ts-ignore TS2448
  return axios.get('/api/user/onetime-token', {}, {headers}).then((result) => {
    return _.get(result, 'data.onetime_token');
  });
  let headers = {};
  if (_.get(authContext, 'id') && _.get(authContext, 'token')) {
    headers['Authorization'] = `Bearer ${authContext.token}`;
    headers['X-Token-Id'] = authContext.id;
  } else {
    return Promise.resolve();
  }

  tokenService.pathname = _.join(_.concat(tokenService.path, 'onetime-token/publish'), '/');

  const axios = new Axios;
  return axios.formPost(url.format(tokenService), {}, {headers}).then((result) => {
    return _.get(result, 'data.onetime_token');
  });
}

//
// urlスキームにトークンパラメータを付与して返す
//
const addOnetimeTokenToQueryString = function(tokenService, authContext, urlString) {
  return fetchOnetimeToken(tokenService, authContext).then((result) => {
    if (!result) { urlString };

    const urlParts = urlString.split('?');
    if (urlParts[1]) {
      urlParts[1] += `&token=${result}`;
    } else {
      urlParts[1] = `token=${result}`;
    }
    return urlParts.join('?');

  }).catch((e) => {
    console.error(e);
    return urlString;
  });
}

export default fetchOnetimeToken;
export { addOnetimeTokenToQueryString };
