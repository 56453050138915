import { useCallback, useEffect, useMemo, useState } from 'react';

import datetime from 'src/libs/datetime';
import useOapLoopConfig from './useOapLoopConfig';

const useShowSidelistButton = (config, meta, isActiveControls) => {
  const [nowMs, setNowMs] = useState(datetime().valueOf());
  const updateNow = useCallback(() => {
    setNowMs(datetime().valueOf());
  }, []);

  useEffect(() => {
    const id = setInterval(updateNow, 5000);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const oapLoopConfig = useOapLoopConfig(config, meta);
  const cardInfo = useMemo(() => {
    return meta.cardInfo || {};
  }, [meta.cardInfo]);

  const displayStartAt = useMemo(() => {
    if (!oapLoopConfig) {
      return null;
    }
    let _displayStartAt;
    // 表示開始
    // delivery_start_at
    // broadcast_start_at
    // broadcast_end_at
    if (oapLoopConfig.display_start_at === 'delivery_start_at') {
      _displayStartAt = cardInfo.deliveryStartAt;
    } else if (oapLoopConfig.display_start_at === 'broadcast_start_at') {
      _displayStartAt = cardInfo.broadcastStartAt;
    } else if (oapLoopConfig.display_start_at === 'broadcast_end_at') {
      _displayStartAt = cardInfo.broadcastEndAt;
    }
    return _displayStartAt;
  }, [cardInfo.broadcastEndAt, cardInfo.broadcastStartAt, cardInfo.deliveryStartAt, oapLoopConfig]);

  const displayEndAt = useMemo(() => {
    if (!oapLoopConfig) {
      return null;
    }
    let _displayEndAt;
    // 表示終了
    // delivery_end_at
    // broadcast_start_at
    // broadcast_end_at
    if (oapLoopConfig.display_end_at === 'delivery_end_at') {
      _displayEndAt = cardInfo.deliveryEndAt;
    } else if (oapLoopConfig.display_end_at === 'broadcast_start_at') {
      _displayEndAt = cardInfo.broadcastStartAt;
    } else if (oapLoopConfig.display_end_at === 'broadcast_end_at') {
      _displayEndAt = cardInfo.broadcastEndAt;
    }

    return _displayEndAt;
  }, [cardInfo.broadcastEndAt, cardInfo.broadcastStartAt, cardInfo.deliveryEndAt, oapLoopConfig]);

  const displayTrigger = useMemo(() => {
    if (!oapLoopConfig) {
      return null;
    }
    let _displayTrigger = false;
    // 表示トリガー
    // show_control
    // always
    if (oapLoopConfig.display_trigger === 'show_control') {
      _displayTrigger = !!isActiveControls;
    } else if (oapLoopConfig.display_trigger === 'always') {
      _displayTrigger = true;
    }
    return _displayTrigger;
  }, [isActiveControls, oapLoopConfig]);

  const showButton = useMemo(() => {
    if (!oapLoopConfig) {
      return false;
    }

    const nowDatetime = datetime(nowMs);
    if (!displayStartAt || nowDatetime.isBefore(datetime(displayStartAt))) {
      return false;
    }

    if (displayEndAt && nowDatetime.isAfter(datetime(displayEndAt))) {
      return false;
    }

    return displayTrigger;
  }, [oapLoopConfig, nowMs, displayStartAt, displayEndAt, displayTrigger]);

  return showButton;
};

export default useShowSidelistButton;
