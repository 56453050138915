import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

import ProductOrderInfo from './common/ProductOrderInfo';
import SubscriptionInfo from './common/SubscriptionInfo';

const Duplicate = props => {
  const DuplicatedSubscriptionsInfo = props => {
    if (_.isEmpty(props.subscriptions)) {
      return null;
    }

    const nodes = props.subscriptions.map((subscription, i) => {
      const subscriptionProps = {
        course: subscription.course,
      };
      return <SubscriptionInfo key={`subscriptions-${i}`} {...subscriptionProps} />;
    });

    return (
      <React.Fragment>
        <h3>すでにご契約いただいている内容</h3>
        {nodes}
      </React.Fragment>
    );
  };

  const DuplicatedProductOrdersInfo = props => {
    if (_.isEmpty(props.productOrders)) {
      return null;
    }

    const nodes = props.productOrders.map((productOrder, i) => {
      const productOrderProps = {
        product: productOrder.product,
        productOrder: productOrder,
        entitlements: productOrder.entitlements,
      };
      return <ProductOrderInfo key={`product-order-${i}`} {...productOrderProps} />;
    });

    return (
      <React.Fragment>
        <h3>すでにご購入いただいている内容</h3>
        {nodes}
      </React.Fragment>
    );
  };

  return (
    <div>
      <div className="introduction-text">この作品はすでに視聴可能です。</div>
      <DuplicatedSubscriptionsInfo {...props} />
      <DuplicatedProductOrdersInfo {...props} />
      <div className="action-buttons">
        {props.redirectTo ? (
          <a className="btn btn-fill" href="javascript:void(0)" onClick={props.redirectTo}>
            視聴開始する
          </a>
        ) : (
          <a className="btn btn-fill" href={props.returnTo}>
            視聴開始する
          </a>
        )}
      </div>
    </div>
  );
};

Duplicate.propTypes = {
  meta: PropTypes.object,
  accountInfo: PropTypes.object,
  subscriptions: PropTypes.array,
  productOrders: PropTypes.array,
  returnTo: PropTypes.string.isRequired,
  redirectTo: PropTypes.func,
};

export default Duplicate;
