import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';
import LoadingSlider from './LoadingSlider';
import LoadingSpinner from './LoadingSpinner';

const LoadingRow = props => {
  const loadingSliders = _.times(props.numOfRows || 1, i => {
    // @ts-ignore TS2322
    return <LoadingSlider isPortrait={props.isPortrait} key={`loading_slider_${i}`} pulsate={props.pulsate} />;
  });
  return (
    <div className="canvas-row canvasPreview">
      {!props.hideRowHeader && !props.rowHeader ? (
        <div className="row-header">
          <span className={classnames('row-title', { pulsate: props.pulsateHeader })}> </span>
        </div>
      ) : (
        props.rowHeader
      )}
      <div className="row-content">{loadingSliders}</div>
      {props.showSpinner ? (
        <div className="canvasSpinLoader">
          <LoadingSpinner />
        </div>
      ) : null}
    </div>
  );
};

LoadingRow.propTypes = {
  pulsateHeader: PropTypes.bool,
  pulsateTitles: PropTypes.bool,
  showSpinner: PropTypes.bool,
};

LoadingRow.defaultProps = {
  pulsateHeader: false,
  pulsateTitles: false,
  pulsate: true,
  showSpinner: false,
  rowHeader: null,
};

export default LoadingRow;
