import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';

import datetime from 'src/libs/datetime';
import GtmApp from 'src/common/GtmApp';
import { withModalContext } from 'src/apps/common/context/ModalContext';
import MainViewLink from 'src/apps/common/components/MainViewLink';
import { getPurchaseLabel } from 'src/utils/getPurchaseLabel';
import SetDate from 'src/apps/bluerose/components/browse/SetDate';
import routes from 'src/apps/common/routes';
import ProductDetailModal from 'src/apps/common/components/modal/ProductDetailModal';

import AppContext from 'src/types/context/App';
import { RouteHandler } from 'src/types/app/Route';

type ProductCardContext = {
  gtmApp: GtmApp;
  getModelData: AppContext.GetModelData;
  routeHandler: RouteHandler;
};

const ProductCard = (props, context: ProductCardContext) => {
  const authContext = context.getModelData('authContext');
  const onClickPurchase = useCallback(() => {
    if (context.gtmApp) {
      context.gtmApp.pushDataLayerOnTvodBuyClick(props.isProductPage, props.meta);
    }
    if (props.product.onSale) {
      const returnTo = encodeURIComponent(context.routeHandler.url);
      props.doAction({ selected: { type: 'product', id: props.product.id }, returnTo });
    }
  }, [context.gtmApp, context.routeHandler, props]);

  const onClickLoginPurchase = useCallback(() => {
    if (context.gtmApp) {
      context.gtmApp.pushDataLayerOnTvodLoginClick(props.isProductPage, props.meta);
    }
  }, [context.gtmApp, props.isProductPage, props.meta]);

  const onClickTargetEpisode = useCallback(() => {
    if (context.gtmApp) {
      context.gtmApp.pushDataLayerOnTvodTargetEpisodeClick(props.meta);
    }
  }, [context.gtmApp, props.meta]);

  const onClickProductDetail = useCallback(
    product => {
      return () => {
        props.showModal(<ProductDetailModal onClickClose={props.closeModal} product={product} />);
      };
    },
    [props],
  );

  const label = getPurchaseLabel(props.product, props.meta);
  // 販売開始前
  const beforeSalesStartAt = useMemo(
    () => props.product.salesStartAt && datetime() < datetime(props.product.salesStartAt),
    [props.product.salesStartAt],
  );
  // 販売終了
  const beforeSalesEndAt = useMemo(() => props.product.salesEndAt && datetime() >= datetime(props.product.salesEndAt), [
    props.product.salesEndAt,
  ]);

  const salesPeriod = useMemo(() => {
    let _salePeriod = null;
    if (beforeSalesStartAt) {
      return <SetDate format="fullja" date={props.product.salesStartAt} txt=" から販売開始" />;
    } else if (beforeSalesEndAt) {
      _salePeriod = <SetDate format="fullja" date={props.product.salesEndAt} txt=" に販売終了" />;
    } else if (!!props.product.salesEndAt) {
      _salePeriod = <SetDate format="fullja" date={props.product.salesEndAt} txt=" まで" />;
    }
    return _salePeriod;
  }, [beforeSalesEndAt, beforeSalesStartAt, props.product.salesEndAt, props.product.salesStartAt]);

  const price = (
    <span className="price">
      <span>{props.product.activePricing.displayPaymentAmountWithoutUnit}</span>
      {props.product.activePricing.displayUnit}（税込み）
    </span>
  );
  return (
    <div className="plan-info__item ppv">
      <div className="plan-info__item__li product-btn">
        <div className="plan-info__item__li__name">
          {props.product.name}
          {(props.isDisabled || props.product.salesLimitExceeded) && (
            <div className="plan-info__item__li__sub">※販売上限数に達しましたため、ご購入いただけません。</div>
          )}
        </div>
        <div className="plan-info__item__li__price">
          <div className="price">
            <span>{props.product.activePricing.displayPaymentAmountWithoutUnit}</span>
            {props.product.activePricing.displayUnit}（税込み）
          </div>
          <div className="plan-info__item__li__price__btn-box">
            {!props.isProductPage ? (
              <MainViewLink
                to={routes.product}
                params={{ id: props.product.id }}
                className={'btn btn-fill grey-color program-btn'}
                onClick={onClickTargetEpisode}
              >
                商品詳細
              </MainViewLink>
            ) : null}
            {props.isDisabled ||
            props.product.salesLimitExceeded ||
            (props.product.salesEndAt && datetime() >= datetime(props.product.salesEndAt)) ? (
              <button type="button" className="btn btn-fill grey-color purchase-btn disabled">
                販売終了しました
              </button>
            ) : beforeSalesStartAt ? (
              <button type="button" className="btn btn-fill purchase-btn disabled">
                販売開始前です
              </button>
            ) : !authContext ? (
              <MainViewLink href={'/login'} className="btn btn-fill purchase-btn" onClick={onClickLoginPurchase}>
                {`ログインして${label}`}
              </MainViewLink>
            ) : (
              <button
                type="button"
                onClick={onClickPurchase}
                className={classnames('btn btn-fill purchase-btn', {
                  disabled: !props.product.onSale,
                })}
              >
                {`${label}する`}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="plan-info__item__li date">
        {salesPeriod ? (
          <div className="plan-info__item__li__date">
            <span>販売期間</span>
            {salesPeriod}
          </div>
        ) : null}
        {props.product.rights && (
          <div className="plan-info__item__li__date">
            <span>{props.product.rights[0].displayLimitType}</span>
            {props.product.rights[0].activeLimitType == 'viewTerm' ? <React.Fragment>{label}後</React.Fragment> : null}
            {props.product.rights[0].displayLimitValue}
            {props.product.rights[0].displayLimitUnit}
          </div>
        )}
        {props.product.productType == 'product_type_rental' && _.get(props.product, 'rights.0.exerciseTerm') ? (
          <div className="plan-info__item__li__date">
            <span>{props.product.rights[0].exerciseTermType}</span>
            {props.product.rights[0].exerciseTermFrom}
            {props.product.rights[0].exerciseTerm}
            {props.product.rights[0].exerciseTermUnit}
          </div>
        ) : null}
      </div>
      {!props.spMode && props.isProductPage && props.product.productDescription ? (
        <div className="plan-info__item__li">
          <div className="btn-container text-right">
            <button type="button" className="plan-description-btn" onClick={onClickProductDetail(props.product)}>
              <i className="fa-info-circle"></i>商品詳細
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

ProductCard.contextTypes = {
  getModelData: PropTypes.func,
  routeHandler: PropTypes.object,
  models: PropTypes.object,
  gtmApp: PropTypes.object,
};

export default withModalContext(ProductCard);
