import routes from '../common/routes';

import BaseRouteHandler from './layouts/BaseRouteHandler';
import BlueroseLayout from './layouts/BlueroseLayout';

import HomeContent from './components/browse/HomeContent';
import GenreContent from './components/browse/GenreContent';
import GenreRef from './components/browse/GenreRef';
// import GenreNewReleaseContent from './components/browse/GenreContent';
import PaletteContent from './components/browse/PaletteContent';
// import NewRelease from './components/browse/NewRelease';
// import NewArrivals from './components/browse/NewArrivals';
import MyList from './components/browse/MyList';
import ContinueWatching from './components/browse/ContinueWatching';
import OnRental from './components/browse/OnRental';
import RecentlyAdded from './components/browse/RecentlyAdded';
import TrendingNow from './components/browse/TrendingNow';
import PopularTitles from './components/browse/PopularTitles';
import NewEpisodeAdded from './components/browse/NewEpisodeAdded';
// import ContentRefresh from './components/browse/ContentRefresh';
// import Similars from './components/browse/Similars';
import Search from './components/browse/Search';
// import Filter from './components/browse/Filter';
import Title from './components/browse/Title';
import Program from './components/browse/Program';
import TitleEpisodes from './components/browse/TitleAssets';
import Attr from './components/browse/Attr';
import Person from './components/browse/Person';
import Team from './components/browse/Team';
import League from './components/browse/League';
// import Studio from './components/browse/Studio';
// import StudioList from './components/browse/StudioList';
import Watch from './components/browse/Watch';
import Content from './components/browse/Content';
import Simulcast from './components/browse/Simulcast';
import SimulcastDetail from './components/browse/SimulcastDetail';
// import { EpgByDay, EpgByChannel, EpgByEvents } from './components/browse/Epg';
import CanvasDisplay from './components/browse/CanvasDisplay';
import FeatureHome from './components/browse/FeatureHome';
// @ts-ignore TS2306
import FeatureDetail from './components/browse/FeatureDetail';
import Ranking from './components/browse/Ranking';
import Info from './components/browse/Info';
import InfoDetail from './components/browse/InfoDetail';
import LiveSchedule from './components/browse/LiveSchedule';
import Curation from './components/browse/Curation';
import PV from './components/browse/PV';
import Product from './components/browse/Product';
import ExclusiveUiContent from './components/browse/ExclusiveUiContent';

const routeHandlers = [{
  route: routes.home,
  components: [BaseRouteHandler, BlueroseLayout, HomeContent],
  uiView: "homeScreen",
  view: "browseTitles"
}, {
  route: routes.browse,
  components: [BaseRouteHandler, BlueroseLayout, HomeContent],
  uiView: "homeScreen",
  view: "browseTitles"
}, {
  route: routes.mylist,
  components: [BaseRouteHandler, BlueroseLayout, MyList],
  uiView: "MyListAsGallery",
  view: "browseTitlesGallery"
}, {
//   route: routes.similars,
//   components: [BaseRouteHandler, BlueroseLayout, Similars],
// }, {
  route: routes.genre,
  components: [BaseRouteHandler, BlueroseLayout, GenreContent],
}, {
  route: routes.genreRef,
  components: [BaseRouteHandler, BlueroseLayout, GenreRef],
}, {
//   route: routes.genreNewRelease,
//   components: [BaseRouteHandler, BlueroseLayout, GenreNewReleaseContent],
// }, {
  route: routes.editorial,
  components: [BaseRouteHandler, BlueroseLayout, PaletteContent],
}, {
  route: routes.exclusiveUi,
  components: [BaseRouteHandler, BlueroseLayout, ExclusiveUiContent],
}, {
  route: routes.attr,
  components: [BaseRouteHandler, BlueroseLayout, Attr],
}, {
  route: routes.person,
  components: [BaseRouteHandler, BlueroseLayout, Person],
}, {
  route: routes.team,
  components: [BaseRouteHandler, BlueroseLayout, Team],
}, {
  route: routes.league,
  components: [BaseRouteHandler, BlueroseLayout, League],
}, {
//   route: routes.studio,
//   components: [BaseRouteHandler, BlueroseLayout, Studio],
// }, {
//   route: routes.studioList,
//   components: [BaseRouteHandler, BlueroseLayout, StudioList],
// }, {
//   route: routes.newRelease,
//   components: [BaseRouteHandler, BlueroseLayout, NewRelease],
// }, {
//   route: routes.newArrivals,
//   components: [BaseRouteHandler, BlueroseLayout, NewArrivals],
// }, {
  route: routes.recentlyAdded,
  components: [BaseRouteHandler, BlueroseLayout, RecentlyAdded]
}, {
  route: routes.trendingNow,
  components: [BaseRouteHandler, BlueroseLayout, TrendingNow],
}, {
  route: routes.popularTitles,
  components: [BaseRouteHandler, BlueroseLayout, PopularTitles]
}, {
  route: routes.newEpisodeAdded,
  components: [BaseRouteHandler, BlueroseLayout, NewEpisodeAdded]
}, {
  route: routes.continueWatching,
  components: [BaseRouteHandler, BlueroseLayout, ContinueWatching]
}, {
  route: routes.onRental,
  components: [BaseRouteHandler, BlueroseLayout, OnRental]
}, {
//   route: routes.justAdded,
//   components: [BaseRouteHandler, BlueroseLayout, ContentRefresh]
// }, {
  route: routes.search,
  components: [BaseRouteHandler, BlueroseLayout, Search]
}, {
//   route: routes.filter,
//   components: [BaseRouteHandler, BlueroseLayout, Filter]
// }, {
  route: routes.titleEpisodes,
  components: [BaseRouteHandler, BlueroseLayout, TitleEpisodes]
}, {
  route: routes.title,
  components: [BaseRouteHandler, BlueroseLayout, Title]
}, {
  route: routes.program,
  components: [BaseRouteHandler, BlueroseLayout, Program]
}, {
  route: routes.product,
  components: [BaseRouteHandler, BlueroseLayout, Product]
// }, {
//   route: routes.kidsHome,
//   components: [BaseRouteHandler, BlueroseLayout, HomeContent]
// }, {
//   route: routes.kidsSearch,
//   components: [BaseRouteHandler, BlueroseLayout, Search]
// }, {
//   route: routes.kidsGenre,
//   components: [BaseRouteHandler, BlueroseLayout, GenreContent]
// }, {
//   route: routes.kidsTitle,
//   components: [BaseRouteHandler, BlueroseLayout, Title]
}, {
  route: routes.simulcastDetail,
  components: [BaseRouteHandler, BlueroseLayout, SimulcastDetail],
}, {
  route: routes.simulcast,
  components: [BaseRouteHandler, BlueroseLayout, Simulcast],
}, {
  route: routes.watchNow,
  components: [BaseRouteHandler, BlueroseLayout, Watch]
}, {
  route: routes.content,
  components: [BaseRouteHandler, BlueroseLayout, Content]
}, {
  route: routes.pv,
  components: [BaseRouteHandler, BlueroseLayout, PV]
}, {
//   route: routes.epg,
//   components: [BaseRouteHandler, BlueroseLayout, EpgByDay]
// }, {
//   route: routes.channelEpg,
//   components: [BaseRouteHandler, BlueroseLayout, EpgByChannel],
// }, {
//   route: routes.events,
//   components: [BaseRouteHandler, BlueroseLayout, EpgByEvents],
// }, {
  route: routes.display,
  components: [BaseRouteHandler, BlueroseLayout, CanvasDisplay],
}, {
  route: routes.featureHome,
  components: [BaseRouteHandler, BlueroseLayout, FeatureHome],
}, {
  route: routes.featureDetail,
  components: [BaseRouteHandler, BlueroseLayout, FeatureDetail],
},{
  route: routes.ranking,
  components: [BaseRouteHandler, BlueroseLayout, Ranking],
},{
  route: routes.liveSchedule,
  components: [BaseRouteHandler, BlueroseLayout, LiveSchedule],
},{
  route: routes.curation,
  components: [BaseRouteHandler, BlueroseLayout, Curation],
}, {
  route: routes.recommended,
  components: [BaseRouteHandler, BlueroseLayout, PaletteContent],
},{
  route: routes.info,
  components: [BaseRouteHandler, BlueroseLayout, Info],
},{
  route: routes.infoDetail,
  components: [BaseRouteHandler, BlueroseLayout, InfoDetail],
}];

export default routeHandlers;
