'use strict';

module.exports = function(str, isXhtml) {
  if (!str) {
    str = '';
  }
  if (isXhtml === undefined) {
    isXhtml = false;
  }
  const breakTag = isXhtml ? '<br />' : '<br>';
  return String(str).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
};
