import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import _ from 'src/libs/util';
import classnames from 'classnames';

import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
import routes from '../../../common/routes';
import TitleOverview from './TitleOverview';
import SeriesCanvas from './SeriesCanvas';
import Details from './titlemeta/Details';
import MyListButton from './MyListButton';
import ShareButton from './ShareButton';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import { NotFoundError } from '../../../common/components/ErrorBoundary';
import HtmlContext from '../../../common/context/HtmlContext';
import canAccessBySlug from '../../utils/slug';
import MainViewLink from '../../../common/components/MainViewLink';
import TitleWatchContents from './TitleWatchContents';
import MetaTags from '../player/MetaTags';
import Meta from './Meta';
import DetailText from '../player/DetailText';
import BackgroundImage from '../../../common/components/BackgroundImage';
import WatchCard from './WatchCard';
import OAPPlayer from '../../../common/components/player/OAPPlayer';
import { CLICK_AREA, CONTENT_EVENTS } from '../../../../common/GtmApp';
import JsonLd from '../../../common/components/JsonLd';

class Title extends Component {
  static getPaths = function(models, options, props) {
    let paths = [];
    const model = props.pathEvaluator || props.model.pathEvaluator;
    const rootPath = this.getRootPath(models, options, props);
    if (props.id && model) {
      paths = paths.concat(Details.getPaths(models, options, { id: props.id }));
    }
    if (props.id) {
      if (props.schemaId == 1) {
        const mylistPath = MyListButton.getPaths(models, options, { id: props.id });
        paths = paths.concat(mylistPath);
        // レコメンドパレット表示の為
        paths = paths.concat(SeriesCanvas.getPaths(models, options, { id: props.id }));
        paths = paths.concat([
          rootPath.concat([
            'viewingEpisode',
            ['id', 'refId', 'name', 'shortName', 'casts', 'thumbnailUrl', 'bookmarkPosition', 'cardInfo'],
          ]),
        ]);
      } else {
        const overviewPaths = TitleOverview.getPaths(models, options, Object.assign({}, props, { id: props.id }));
        paths = paths.concat(overviewPaths);
      }
      paths = paths.concat([['meta', props.id, ['communicationArea']]]);
    }
    if (props.titleMetaId) {
      paths = paths.concat(this.getTitleMetaPaths(models, options, props));
    }
    if (props.id) {
      paths = paths.concat([
        [
          'meta',
          props.id,
          [
            'schemaId',
            'refId',
            'type',
            'seriesId',
            'seriesMeta',
            'thumbnailUrl',
            'links',
            'subscription',
            'rental',
            'ogImage',
            'keywords',
            'copyrights',
            'genres',
            'middleGenres',
            'systemGenres',
            'attributes',
            'header',
            'leadEpisodeId',
            'leadEpisode',
            'defaultSort',
            'hierarchyTypes',
            'recommend',
            'shortName',
            'directorsCut',
            'cardInfo',
            'oap',
            'languageCodes',
            'tvodBadge',
          ],
        ],
      ]);
    }
    return paths;
  };

  static getRootPath = function(models, options, props) {
    if (props.id) {
      return ['meta', props.id];
    }
    return [];
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static getPrefetchedPaths = function(models, options, props) {
    return data => {
      const item = _.omit(_.get(data.json, this.getRootPath(models, options, props)), ['$__path']);
      let titleMetaId;
      if (_.get(item, 'seriesMeta.metaId')) titleMetaId = item.seriesMeta.metaId;
      props = Object.assign({}, props, { id: item.id, titleMetaId: titleMetaId });
      return this.getPaths(models, options, props);
    };
  };

  // @ts-ignore TS2300
  static getRootPath = function(models, options, props) {
    if (props.id) {
      return ['meta', props.id];
    }
    return [];
  };

  static getTitleMetaRootPath = function(models, options, props) {
    if (props.titleMetaId) {
      return ['meta', props.titleMetaId];
    }
    return [];
  };

  static getTitleMetaPaths = function(models, options, props) {
    let paths = [];
    const rootPath = this.getTitleMetaRootPath(models, options, props);
    return paths.concat([rootPath.concat([['id', 'refId', 'name', 'shortName', 'thumbnailUrl']])]);
  };

  static get propTypes() {
    return {
      history: PropTypes.object,
      id: PropTypes.string,
      // isKidsPage: PropTypes.bool,
      isPrefetchingPaths: PropTypes.bool,
    };
  }

  static get contextTypes() {
    return {
      models: PropTypes.object,
      history: PropTypes.object,
      routeHandler: PropTypes.object,
      getModelData: PropTypes.func,
      gtmApp: PropTypes.object,
    };
  }

  get item() {
    // @ts-ignore TS2551
    return this._item;
  }

  set item(item) {
    // @ts-ignore TS2551
    this._item = item;
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, null, props);
    // @ts-ignore TS2339
    this.item = this.model.getSync(rootPath) || {};
    const titleMetaId = _.get(this.item, 'seriesMeta.metaId');
    if (titleMetaId) {
      // @ts-ignore TS2339
      const titleMetaRootPath = this.constructor.getTitleMetaRootPath(context.models, null, {
        titleMetaId: titleMetaId,
      });
      // @ts-ignore TS2339
      this.titleMeta = this.model.getSync(titleMetaRootPath) || {};
    } else {
      // @ts-ignore TS2339
      this.titleMeta = this.item;
    }

    // if (this.item && this.item && this.item.schemaId === 10){
    //   throw new NotFoundError({
    //     redirect_link: routes.featureDetail.makePath({ id: props.id }),
    //   })
    // }
    if (this.item && (this.item.type === 'media' || this.item.type === 'linear_channel')) {
      let to = routes.watchNow;
      let params = { id: props.id };
      if (this.item.refId) {
        to = routes.content;
        params.id = this.item.refId;
        if (this.item.type === 'linear_channel') {
          const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.item.refId);
          to = routes.simulcast;
          // @ts-ignore TS2322
          params = { channelName: simulcast.name };
        }
      }
      throw new NotFoundError({
        // @ts-ignore TS2554
        redirect_link: to.makePath(params),
      });
    }

    this.state = {
      dispose: null,
      // @ts-ignore TS2339
      generation: this.model.getVersion(rootPath),
      fetchDataError: null,
      titleSpMode: null,
    };

    this.handleWatchCardClick = this.handleWatchCardClick.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);
    this.checkMediaQuery = _.throttle(this.checkMediaQuery.bind(this), 300, { leading: true, trailing: true });
    this.checkMediaQuery();
  }

  componentDidMount() {
    if (!this.item || Object.keys(this.item).length === 0) {
      // componentWillMountでスローしたいが、SSRのタイミングでも呼ばれるので不可
      // https://reactjs.org/docs/error-boundaries.html
      // @ts-ignore TS2554
      throw new NotFoundError();
    }

    // @ts-ignore TS2339
    this._isMounted = true;

    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.checkMediaQuery);
    } else {
      browserEvents.addEventListener('resize', this.checkMediaQuery);
    }

    this.fetchData(this.props);
    this.checkMediaQuery();
    // @ts-ignore TS2554
    this.sendToGtm('pageview')(this.item);
  }

  componentWillReceiveProps(nextProps) {
    // 対象のメタがMediaMetaの場合はリダイレクトする
    // SSRの場合のリダイレクトはconstructor側でやる?
    // componentWillReceiveProps
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    // if (this.item && this.item.schemaId === 10){
    //   nextContext.history.replace(routes.featureDetail.makePath({ id: this.props.id }));
    // }
    if (this.item.type === 'media' || this.item.type === 'linear_channel') {
      let to = routes.watchNow;
      // @ts-ignore TS2339
      let params = { id: this.props.id };
      if (this.item.refId) {
        to = routes.content;
        params.id = this.item.refId;
        if (this.item.type === 'linear_channel') {
          const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.item.refId);
          // @ts-ignore TS2339
          to = this.simulcast;
          // @ts-ignore TS2322
          params = { channelName: simulcast.name };
        }
      }
      // @ts-ignore TS2554
      nextContext.history.replace(to.makePath(params));
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();

    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('orientationchange', this.checkMediaQuery);
    } else {
      browserEvents.removeEventListener('resize', this.checkMediaQuery);
    }
  }

  checkMediaQuery() {
    if (typeof window === 'undefined') return;

    let titleSpMode = null;
    if (window.matchMedia('screen and (min-width: 1024px)').matches) {
      titleSpMode = 1;
    } else if (window.matchMedia('screen and (min-width: 768px) and (max-width: 1023px)').matches) {
      titleSpMode = 2;
    } else {
      titleSpMode = 3;
    }
    // @ts-ignore TS2339
    if (titleSpMode !== this.state.titleSpMode) this.setState({ titleSpMode });
  }

  fetchData(props) {
    let pathProps = _.clone(props);
    if (this.item && canAccessBySlug(this.item)) {
      // slugでアクセスされた場合はprops.idにslugが入るので、取得したIDで上書き
      pathProps.id = this.item.id;
    }
    if (this.item) {
      pathProps.schemaId = this.item.schemaId;
    }
    if (_.get(this.item, 'seriesMeta.metaId')) {
      pathProps.titleMetaId = this.item.seriesMeta.metaId;
    } else {
      pathProps.titleMetaId = this.item.id;
    }
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    // @ts-ignore TS2339
    const evaluator = this.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, pathProps);
    // @ts-ignore TS2339
    const titleMetaRootPath = this.constructor.getTitleMetaRootPath(this.context.models, {}, pathProps);
    evaluator
      .then(res => {
        this.item = _.get(res, ['json'].concat(rootPath), {});
        if (this.item && _.get(this.item, 'children.df')) {
          // @ts-ignore TS2339
          this.firstSeason = _.first(
            _.compact(
              _.dropRightWhile(_.values(_.omit(this.item.children.df, ['$__path'])), function(v) {
                return _.isNumber(v);
              }),
            ),
          );
        }
        // @ts-ignore TS2339
        this.titleMeta = _.get(res, ['json'].concat(titleMetaRootPath), {});

        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.model.getVersion(rootPath),
        };

        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  handleChangeQuery(query) {
    const { routeHandler, history } = this.context;
    query = Object.assign({}, routeHandler.query, query);
    if (_.has(query, 'season_id') && query.season_id == null) delete query.season_id;
    history.replace(routeHandler.route.makePath({ id: routeHandler.params.id }, query), { norender: true });
  }

  handleWatchCardClick(episode) {
    this.sendToGtm(CONTENT_EVENTS.PLAY_CLICK, CLICK_AREA.PLAY_CLICK.META_DETAIL)(episode);
  }

  sendToGtm(event, clickArea) {
    return episode => {
      if (!_.get(this.context, 'gtmApp')) return;
      const meta = _.isEmpty(episode) ? this.item : episode;
      const isMediaMeta = _.get(meta, 'type') === 'media';
      const hasRelationProgram = _.get(meta, 'seasonMeta') && _.get(meta, 'seriesMeta');
      const relationProgram = hasRelationProgram
        ? {
            refId: _.get(meta, 'seriesMeta.refId'),
            name: _.get(meta, 'seriesMeta.name'),
          }
        : null;
      const program = isMediaMeta
        ? {
            refId: _.get(meta, 'seasonMeta.refId') || _.get(meta, 'seriesMeta.refId'),
            name: _.get(meta, 'seasonMeta.name') || _.get(meta, 'seriesMeta.name'),
          }
        : {
            refId: _.get(meta, 'refId'),
            name: _.get(meta, 'name'),
          };
      const content = isMediaMeta
        ? {
            refId: _.get(meta, 'refId'),
            name: _.get(meta, 'name') || _.get(meta, 'shortName'),
            rental: _.get(meta, 'rental'),
            subscription: _.get(meta, 'subscription'),
          }
        : {
            refId: _.get(meta, 'viewingEpisode.refId') || _.get(meta, 'leadEpisode.refId'),
            name: _.get(meta, 'viewingEpisode.name') || _.get(meta, 'leadEpisode.name'),
            rental: _.get(meta, 'rental'),
            subscription: _.get(meta, 'subscription'),
          };
      const attributes = _.get(meta, 'attributes');
      const genres = _.get(meta, 'genres');
      const middleGenres = _.get(meta, 'middleGenres');
      const schemaId =
        !isMediaMeta && event !== 'pageview' ? _.get(meta, 'leadEpisode.schemaId') : _.get(meta, 'schemaId');
      if (event !== 'pageview') {
        this.context.gtmApp.pushDataLayerOnContentPageClick(
          event,
          { relationProgram, program, content, attributes, genres, middleGenres, schemaId },
          { clickArea },
        );
      } else {
        this.context.gtmApp.pageView(_.get(this.item, 'shortName'), {
          relationProgram,
          program,
          attributes,
          genres,
          middleGenres,
          schemaId,
        });
      }
    };
  }

  render() {
    if (!this.item) return null;
    if (!this.item || Object.keys(this.item).length === 0) return null;

    const browserInfo = this.context.getModelData('browserInfo');

    const schemaContextValue = {
      pageSchemaId: this.item.schemaId,
    };

    let content;
    // slugでアクセスすることが可能な場合はprops.idにslugが入る可能性があるので、取得したIDで上書き
    const props = canAccessBySlug(this.item) ? Object.assign({}, this.props, { id: this.item.id }) : this.props;

    const watchCardProps = {
      // @ts-ignore TS2339
      model: this.model,
      enableCardClick: true,
      id: _.get(this.item, 'viewingEpisode.id', _.get(this.item, 'leadEpisode.id', _.get(this.item, 'leadEpisodeId'))),
      noTitle: false,
      onCardClick: this.handleWatchCardClick,
    };

    // if (this.item.viewingEpisode) {
    //   watchCardProps.itemData = this.item.viewingEpisode;
    // }

    let communicationArea = null;
    if (this.item.communicationArea) {
      communicationArea = (
        <div className="communication-area">
          <span>{this.item.communicationArea}</span>
        </div>
      );
    }

    let linkProps = {};
    if (this.item.shortName && this.item.schemaId == 2 && this.item.seriesMeta) {
      if (this.item.seriesMeta.metaId) {
        linkProps = {
          to: routes.title,
          params: { id: this.item.seriesMeta.metaId },
        };
      }
      if (this.item.seriesMeta.refId) {
        linkProps = {
          to: routes.program,
          params: { id: this.item.seriesMeta.refId },
        };
      }
    }
    const wapperLayout = attr => {
      return props => <div className={attr.classes}>{props.children}</div>;
    };
    let seriesName;
    // @ts-ignore TS2339
    if (this.item.schemaId == 2 && this.titleMeta) {
      // @ts-ignore TS2339
      seriesName = this.titleMeta.shortName || this.titleMeta.name;
    }
    let myListButton;
    const myListProps = {
      // @ts-ignore TS2339
      model: this.model,
      btnType: 'short',
      btnStyle: false,
      metaDetail: true,
    };
    // @ts-ignore TS2339
    myListProps.item = this.item;
    // @ts-ignore TS2339
    myListProps.id = this.item.id;
    if (MyListButton.isUsable(this.context, this.item)) {
      myListButton = <MyListButton {...myListProps} />;
    }

    const titleView = (
      <React.Fragment>
        <div className="title-main-visual">
          <div className="title-main-visual__metadata">
            {!!seriesName && (
              <div className="sub-title">
                <MainViewLink {...linkProps}>{seriesName}</MainViewLink>
              </div>
            )}
            {this.item.shortName ? <h1 className="playable-title">{this.item.shortName}</h1> : null}
            <Meta metadata={this.item} />
            {/*
             // @ts-ignore TS2322 */}
            <MetaTags metadata={this.item} />
            <WatchCard
              {...watchCardProps}
              // @ts-ignore TS2322
              btnVisible={true}
              wapperLayout={wapperLayout({ classes: 'watch-card-box' })}
            />
            {communicationArea}
          </div>
          <div className="main-visual">
            <div className="video-preload-title">
              <div className="video-preload-title-label">{this.item.name}</div>
            </div>
            <BackgroundImage
              // @ts-ignore TS2322
              className={classnames('artwork', { noImage: !this.item.thumbnailUrl })}
              url={this.item.thumbnailUrl}
            />
            {this.item.oap && (
              <div className="main-visual__oap-player-wrapper">
                {/*
                 // @ts-ignore TS2322 */}
                <OAPPlayer oap={this.item.oap} />
              </div>
            )}
          </div>
        </div>
        <div className="title-info">
          <div className="title-info__colum_left" id="columLeft">
            <DetailText
              metadata={this.item}
              // @ts-ignore TS2322
              model={this.model}
              titlePage={true}
              linearFlag={false}
              // @ts-ignore TS2339
              mediaQueryCheck={this.state.titleSpMode}
            />
          </div>
          <div className="title-info__colum_right" id="columRight">
            <div className="action-box">
              <ul className="action-box__inner">
                {myListButton ? <li>{myListButton}</li> : null}
                <li>
                  {/*
                   // @ts-ignore TS2322 */}
                  <ShareButton metadata={this.item} position="right" spMode={this.props.spMode} />
                </li>
              </ul>
            </div>
            {_.map(this.item.links, (link, i) => {
              return (
                // @ts-ignore TS2322
                <MainViewLink key={`link-${i}`} href={link.url} target={'_blank'} className="title-info__link">
                  {link.name}
                </MainViewLink>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );

    // シリーズページの場合の表示
    if (this.item.schemaId == 1) {
      // シリーズページに表示するレコメンドパレット
      // １．表示順先頭のシーズンのレコメンド
      // ２．(１．が存在しなければ)シリーズ自身のレコメンド
      // ３．(２．が存在しなければ)こちらもおすすめ
      // let recommend = null;
      // const title = 'この作品をご覧になったあなたへ'
      // if (_.get(this.firstSeason, 'recommend')) {
      //   recommend = <Palette key={`recommend-${this.firstSeason.recommend}`} listContext={"editorial"} paletteId={this.firstSeason.recommend} model={this.model} title={title} isGrid={true} useRowHeader={false} />;
      // } else if (this.item.recommend) {
      //   recommend = <Palette key={`recommend-${this.item.recommend}`} listContext={"editorial"} paletteId={this.item.recommend} model={this.model} title={title} isGrid={true} useRowHeader={false} />;
      // }

      content = (
        <React.Fragment>
          {titleView}
          {/* <CardContextProvider value={schemaContextValue}>
            <SeriesCanvas
              id={props.id}
              rental={this.item.rental}
              model={this.model} />
          </CardContextProvider> */}
          <TitleWatchContents
            {...this.props}
            // @ts-ignore TS2339
            id={this.props.id}
            // @ts-ignore TS2322
            relatedMetaId={this.props.id}
            // @ts-ignore TS2339
            model={this.model}
            onChangeQuery={this.handleChangeQuery}
            titleSmallSize={true}
            // @ts-ignore TS2339
            ignoreIds={[this.props.id]}
          />
          {/* {recommend ? recommend : (
            <SimilarsRow id={props.id} model={this.model} listContext={"more_like_this"} title={title} titleSmallSize={true} isGrid={true} />
          )} */}
        </React.Fragment>
      );
    } else {
      let directorsCut = null;
      if (this.item.directorsCut) {
        directorsCut = (
          <div className="pickup-wrapper">
            WOWOWオンデマンドではディレクターズカット版を配信中。ディレクターズカット版は
            {/*
             // @ts-ignore TS2322 */}
            <MainViewLink to={routes.title} params={{ id: this.item.directorsCut.meta_id }}>
              こちら
            </MainViewLink>
          </div>
        );
      }
      // @ts-ignore TS2339
      const ignoreIds = [this.props.id];
      // シーズンの場合はシリーズも消し込む
      if (this.item.schemaId === 2 && _.get(this.item.seriesMeta, 'metaId')) {
        ignoreIds.push(this.item.seriesMeta.metaId);
      }
      content = (
        <React.Fragment>
          {titleView}
          <TitleWatchContents
            {...this.props}
            // @ts-ignore TS2339
            id={this.props.id}
            // @ts-ignore TS2322
            model={this.model}
            onChangeQuery={this.handleChangeQuery}
            titleSmallSize={true}
            // @ts-ignore TS2339
            relatedMetaId={this.props.id}
            ignoreIds={ignoreIds}
          />
        </React.Fragment>
      );
    }

    // 構造化タグ
    const jsonLdProps = {};
    const itemListElement = [];
    const host = this.context.getModelData('hosts', 'host');

    // ジャンル
    const genre = _.first(this.item.genres);
    // @ts-ignore TS2339
    if (genre && genre.id && genre.name) {
      // @ts-ignore TS2554
      let url = routes.genre.makePath({ id: genre.id }, { type: 'od' });
      // @ts-ignore TS2339
      if (genre.refId) url = routes.genreRef.makePath({ id: genre.refId }, { type: 'od' });
      // @ts-ignore TS2339
      itemListElement.push({ name: genre.name, item: host + url });
    }

    // 親
    // @ts-ignore TS2339
    if (this.titleMeta && this.titleMeta.id && this.item.id !== this.titleMeta.id && this.titleMeta.name) {
      // @ts-ignore TS2554
      let url = routes.title.makePath({ id: this.titleMeta.id });
      // @ts-ignore TS2339
      if (this.titleMeta.refId) url = routes.program.makePath({ id: this.titleMeta.refId });
      // @ts-ignore TS2339
      const name = _.get(this.titleMeta, 'shortName', _.get(this.titleMeta, 'name'));
      itemListElement.push({ name: name, item: host + url });
    }

    // 自分
    if (this.item.id && this.item.name) {
      // @ts-ignore TS2554
      let url = routes.title.makePath({ id: this.item.id });
      // @ts-ignore TS2554
      if (this.item.refId) url = routes.program.makePath({ id: this.item.refId });
      const name = _.get(this.item, 'shortName', _.get(this.item, 'name'));
      itemListElement.push({ name: name, item: host + url });
    }

    if (!_.isEmpty(itemListElement)) {
      // @ts-ignore TS2339
      jsonLdProps.breadcrumbList = { itemListElement };
    }

    const description = {
      name: 'description',
      content:
        `「${this.item.shortName}」動画配信中！` +
        (this.item.cardInfo && this.item.cardInfo.description ? this.item.cardInfo.description : ''),
    };
    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ title, keywords }) => {
            const links = [];
            const metas = [];
            if (this.item.name) {
              metas.push({ name: 'keywords', content: keywords((this.item.keywords || []).join(',')) });
              metas.push(description);
              metas.push({ property: 'og:title', content: title(this.item.shortName) });
              metas.push({
                property: 'og:description',
                content: this.item.cardInfo && this.item.cardInfo.description ? this.item.cardInfo.description : '',
              });
            }
            if (this.item && this.item.ogImage) {
              metas.push({ name: 'thumbnail', content: this.item.ogImage });
              metas.push({ property: 'og:image', content: this.item.ogImage });
            }
            if (this.item.refId) {
              // @ts-ignore TS2554
              const url = routes.program.makePath({ id: this.item.refId });
              links.push({ rel: 'canonical', href: host + url });
              metas.push({ property: 'og:url', content: host + url });
            } else if (this.item.id) {
              // @ts-ignore TS2554
              const url = routes.title.makePath({ id: this.item.id });
              links.push({ rel: 'canonical', href: host + url });
              metas.push({ property: 'og:url', content: host + url });
            }
            return <Helmet title={this.item.shortName} link={links} meta={metas} />;
          }}
        </HtmlContext.Consumer>
        <JsonLd {...jsonLdProps} />
        {content}
      </React.Fragment>
    );
  }
}

export default withLayoutContext(Title);
