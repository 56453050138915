import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';
import * as browserEvents from '../../../sketch-platform/utils/browserEvents';

export default class TabDropDown extends Component {
  static get propTypes() {
    return {
      model: PropTypes.object,
    };
  }
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandlers: PropTypes.array,
      history: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      tabDropdownMenuOpen: false,
      bottom: null,
      left: null,
      clickEl: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.dropdownMenuClose = this.dropdownMenuClose.bind(this);
    this.handleScrollStart = this.handleScrollStart.bind(this);
    // @ts-ignore TS2339
    this.sportsTabDropdownRef = React.createRef();
  }
  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;

    const browserInfo = this.context.getModelData('browserInfo');
    if (!(browserInfo.isIOS && browserInfo.isAndroid)) {
      browserEvents.addEventListener('scrollStart', this.handleScrollStart);
    }
  }
  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    const browserInfo = this.context.getModelData('browserInfo');
    if (!(browserInfo.isIOS && browserInfo.isAndroid)) {
      browserEvents.removeEventListener('scrollStart', this.handleScrollStart);
    }
  }

  handleChange(event) {
    // @ts-ignore TS2339
    const index = _.findIndex(this.props.options, item => {
      // @ts-ignore TS2339
      return item.props.children == event.target.value;
    });
    if (index === -1) return;

    // @ts-ignore TS2339
    if (this.props.options[index].props.onClick) {
      // @ts-ignore TS2339
      this.props.options[index].props.onClick(event);
    } else {
      // @ts-ignore TS2339
      const linkProps = this.props.options[index].props;
      const { routeHandlers, history } = this.context;
      let routesMatchFlag = false;
      let href = '';
      if (linkProps.to) {
        let params = linkProps.params || {};
        let query = linkProps.query;
        href = linkProps.to.makePath(params, query);
        routesMatchFlag =
          routeHandlers &&
          _.find(routeHandlers, function(routeHandler) {
            return linkProps.to._regex === routeHandler.route._regex;
          });
      } else if (linkProps.href) {
        href = linkProps.href;
        routesMatchFlag =
          routeHandlers &&
          _.find(routeHandlers, function(routeHandler) {
            return !!routeHandler.route.match(href);
          });
      }

      if (routesMatchFlag) {
        history.push(href);
      } else {
        window.location.href = href;
      }
    }
  }

  handleMenu(e) {
    // @ts-ignore TS2339
    const button = this.sportsTabDropdownRef.current;
    const buttonClient = button.getBoundingClientRect();

    // @ts-ignore TS2339
    if (this.state.tabDropdownMenuOpen) {
      this.setState({ tabDropdownMenuOpen: false, bottom: null, left: null, clickEl: null });
    } else {
      this.setState({
        tabDropdownMenuOpen: true,
        bottom: buttonClient.bottom + 'px',
        left: buttonClient.left + 'px',
        // @ts-ignore TS2339
        clickEl: this.sportsTabDropdownRef,
      });
    }
  }
  dropdownMenuClose(e) {
    this.setState({ tabDropdownMenuOpen: false, bottom: null, left: null, clickEl: null });
  }
  handleScrollStart() {
    // @ts-ignore TS2339
    if (this.state.tabDropdownMenuOpen) {
      // @ts-ignore TS2554
      this.dropdownMenuClose();
    }
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');
    const options = [];
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      // @ts-ignore TS2339
      if (this.props.blankValue !== undefined)
        options.push(
          // @ts-ignore TS2339
          <option key={`item-blankValue`} value={this.props.blankValue}>
            {/*
             // @ts-ignore TS2339 */}
            {this.props.blankValue}
          </option>,
        );
      // @ts-ignore TS2339
      _.forEach(this.props.options, (item, key) => {
        options.push(
          <option key={`item-${key}`} value={item.props.children}>
            {item.props.children}
          </option>,
        );
      });

      return (
        <div
          // @ts-ignore TS2339
          className={classnames('dropDown', { widthRestricted: this.props.widthRestricted === true }, this.props.theme)}
        >
          <label className="select-group">
            {/*
             // @ts-ignore TS2339 */}
            <select value={this.props.defaultValue}>{options}</select>
          </label>
        </div>
      );
    } else {
      // @ts-ignore TS2339
      _.forEach(this.props.options, (item, key) => {
        options.push(
          <li className="sub-menu-item" key={`item-${key}`}>
            {item}
          </li>,
        );
      });

      return (
        // @ts-ignore TS2339
        <div className="dropDown sportsTab" ref={this.sportsTabDropdownRef}>
          {/*
           // @ts-ignore TS2322 */}
          <div className="label" tabIndex="0" role="button" onClick={() => this.handleMenu(this)}>
            {/*
             // @ts-ignore TS2339 */}
            {this.props.defaultValue}
            <span className="arrow"></span>
          </div>
          {/*
           // @ts-ignore TS2339 */}
          {this.state.tabDropdownMenuOpen ? (
            // @ts-ignore TS2339
            <div className="sub-menu" style={{ top: this.state.bottom, left: this.state.left }}>
              <ul className="sub-menu-list">{options}</ul>
            </div>
          ) : null}
        </div>
      );
    }
  }
}
