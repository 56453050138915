import hoistStatics from 'hoist-non-react-statics';
import invariant from 'invariant';
import React, { Component } from 'react';
import _ from 'src/libs/util';

import PropTypes from 'prop-types';
import window from 'global/window';

export default function layoutContext(WrappedComponent) {
  invariant(
    typeof WrappedComponent === 'function',
    `You must pass a component to the function returned by ` +
      `layoutContext. Instead received ${JSON.stringify(WrappedComponent)}`,
  );

  class LayoutContext extends Component {

    static get childContextTypes() {
      return {
        offline: PropTypes.bool,
        popType: PropTypes.string,
        models: PropTypes.object,
        node: PropTypes.object
      };
    }

    static get contextTypes() {
      return {
        getCookieDough: PropTypes.func,
        getModelData: PropTypes.func,
        store: PropTypes.object,
      };
    }

    constructor(props, context) {
      super(props, context);

      this.state = {
        offline:
          typeof window === 'undefined' ||
          typeof window.navigator === 'undefined'
            ? false
            : !window.navigator.onLine,
      };
      this._handleUpdateOnlineStatus = this._handleUpdateOnlineStatus.bind(this);

      const { store: propsStore } = props;
      // @ts-ignore TS2339
      this.store = propsStore || context.store;
    }

    getChildContext() {
      // @ts-ignore TS2339
      const model = this.props.model;
      return {
        models: model.models || this.context.models || {},
        node: model.node,
        // @ts-ignore TS2339
        offline: this.state.offline,
        popType: 'bounce'
      };
    }

    componentDidMount() {
      // @ts-ignore TS2339
      this._isMounted = true;
      window.addEventListener('online', this._handleUpdateOnlineStatus);
      window.addEventListener('offline', this._handleUpdateOnlineStatus);
    }

    componentWillReceiveProps(nextProps) {}

    componentDidUpdate(beforeProps) {}

    componentWillUnmount() {
      // @ts-ignore TS2339
      this._isMounted = false;

      window.removeEventListener('online', this._handleUpdateOnlineStatus);
      window.removeEventListener('offline', this._handleUpdateOnlineStatus);
    }

    _handleUpdateOnlineStatus() {
      // @ts-ignore TS2339
      if (this._isMounted) {
        if (window.navigator.onLine) {
        }
        this.setState({ offline: !window.navigator.onLine });
      }
    }

    render() {
      // @ts-ignore TS2339
      const props = Object.assign({model: _.omit(this.props.model, 'models')}, _.omit(this.props, 'model'));
      return React.createElement(WrappedComponent, props);
    }
  }

  // @ts-ignore TS2339
  LayoutContext.displayName = 'LayoutContext';

  return hoistStatics(LayoutContext, WrappedComponent);
};
