import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';

import activeTags from '../../utils/activeTags';
import { getMetaData, BaseMetaTags } from '../player/MetaTags';
import { getEndDayBadge } from './CardBadge';
import datetime from 'src/libs/datetime';

// 優先順位順 高↑ 低↓
// * Box
//  * バッジテキスト
//  * バッジステータスが「もうすぐ終了」以外表示
//  * メディアメタかつ配信開始している
//	  * ライブスキーマ以外で配信終了日時から90日以内で終了日時表示
//    * ライブスキーマでライブが終わっていたら、配信終了日時から90日以内で終了日時表示
//	* 「もうすぐ終了」は表示させない
//  + 日付ラベルも表示させる

const CardBadgeNode = props => {
  return <span className="tag grey-tag">{props.tag}</span>;
};

const EndDayBadgeNode = props => {
  return (
    <span className="tag grey-tag" key="endday-tag">
      <span>{datetime(props.datetime).formatMD()}</span>まで
    </span>
  );
};

const tagText = (props, context) => {
  const itemData = props.item;
  const cardInfo = itemData.cardInfo || {};
  const options = {
    isOnArt: props.isOnArt,
    tvodTagText: true,
  };
  let tags = activeTags(itemData, context, options);

  // 配信終了日バッジ
  //Boxの時は配信終了日時から90日以内で終了日時表示
  // @ts-ignore TS2554
  const EndDayBadge = getEndDayBadge(itemData, cardInfo, EndDayBadgeNode);

  const badges = [];
  if (EndDayBadge) {
    tags = tags.filter(tag => tag !== 'もうすぐ終了');
    tags.forEach((tag, index) => badges.push(<CardBadgeNode key={index} tag={tag} />));
    badges.push(EndDayBadge);
  } else {
    tags.forEach((tag, index) => badges.push(<CardBadgeNode key={index} tag={tag} />));
  }

  let content;
  // アートの上のバッジは一つ(エピソードリストは除く)
  if (props.isOnArt && props.keyPrefix !== 'episodesList') {
    content = _.first(badges);
  } else {
    content = badges;
  }

  if (!_.isEmpty(tags) || EndDayBadge) {
    return <React.Fragment>{content}</React.Fragment>;
  }
  return null;
};

const BoxCardBadge = (props, context) => {
  if (!props.item) return null;
  if (context.showBadge === false) return null;

  const tag = tagText(props, context);
  const metaData = getMetaData(props.item, props.hiddenGenre, tag, context);
  if (!metaData) return null;
  let tvodTag = null;

  // PPV: 1（ライブのみ/ライブエピソードが混在するシリーズ）
  // レンタル：2（エピソードのみ）
  // 購入商品：3（エピソード・ライブ）
  if (props.item.tvodBadge) {
    let tagText;
    if (props.item.tvodBadge == 1) {
      tagText = 'PPV';
    } else if (props.item.tvodBadge == 2) {
      tagText = 'レンタル';
    } else if (props.item.tvodBadge == 3) {
      tagText = '購入商品';
    }
    tvodTag = (
      <span className="tag tvod" key="ppv-tag">
        {tagText}
      </span>
    );
  }
  return (
    <div className="tag-list">
      {tvodTag}
      {tag && tag}
      <BaseMetaTags {...metaData} />
    </div>
  );
};
BoxCardBadge.contextTypes = {
  getModelData: PropTypes.func,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  showBadge: PropTypes.bool,
};
BoxCardBadge.defaultProps = {
  isOnArt: true,
};
export default BoxCardBadge;
