import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import _ from 'src/libs/util';

import ImageRotatorCard from './ImageRotatorCard';

export default class ImageRotator extends Component {
  static get propTypes() {
    return {
      auto: PropTypes.bool,
      classes: PropTypes.object,
      duration: PropTypes.number,
      firstDelay: PropTypes.number,
      images: PropTypes.array,
      preloadImage: PropTypes.string,
      stop: PropTypes.bool,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.timerID = null;
    // @ts-ignore TS2339
    this.intervalID = null;
    // @ts-ignore TS2339
    this.numRotations = 0;

    this.state = {
      generation: 0,
      imageIndex: 0,
      numImagesLoaded: props.images ? props.images.length : 0,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    if (this.state.numImagesLoaded > 1) {
      // @ts-ignore TS2339
      this.timerID = setTimeout(() => {
        this.next();
        this.startRotate();
      // @ts-ignore TS2339
      }, this.props.firstDelay);
    }
  }

  componentWillReceiveProps(nextProps) {
    const nextState = { numImagesLoaded: nextProps.images ? nextProps.images.length : 0 };
    if (
      // @ts-ignore TS2339
      _.difference(nextProps.images, this.props.images).length > 0 ||
      // @ts-ignore TS2339
      _.difference(this.props.images, nextProps.images).length > 0
    ) {
      // @ts-ignore TS2339
      nextState.generation = this.state.generation + 1;
      // @ts-ignore TS2339
      nextState.imageIndex = 0;
      // @ts-ignore TS2339
      if (this.timerID) {
        // @ts-ignore TS2339
        clearTimeout(this.timerID);
        // @ts-ignore TS2339
        delete this.timerID;
      }
    }
    if (nextState.numImagesLoaded < 2) {
      // @ts-ignore TS2339
      nextState.imageIndex = 0;
    }
    this.setState(nextState);
  }

  componentDidUpdate() {
    // @ts-ignore TS2339
    if (this.state.numImagesLoaded > 1) {
      // @ts-ignore TS2339
      if (this.timerID) {
        // @ts-ignore TS2339
        clearTimeout(this.timerID);
      }
      // @ts-ignore TS2339
      if (this.intervalID) {
        // @ts-ignore TS2339
        clearInterval(this.intervalID);
        // @ts-ignore TS2339
        delete this.intervalID;
      }
      // @ts-ignore TS2339
      this.timerID = setTimeout(() => {
        this.next();
        this.startRotate();
      // @ts-ignore TS2339
      }, this.props.firstDelay);
    } else {
      // @ts-ignore TS2339
      if (this.timerID) {
        // @ts-ignore TS2339
        clearTimeout(this.timerID);
        // @ts-ignore TS2339
        delete this.timerID;
      }
      // @ts-ignore TS2339
      if (this.intervalID) {
        // @ts-ignore TS2339
        clearInterval(this.intervalID);
        // @ts-ignore TS2339
        delete this.intervalID;
      }
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    if (this.intervalID) {
      // @ts-ignore TS2339
      clearInterval(this.intervalID);
    }

    // @ts-ignore TS2339
    if (this.timerID) {
      // @ts-ignore TS2339
      clearTimeout(this.timerID);
    }
  }

  render() {
    const mouseEnterHandler = () => {
      // TODO
    };
    const mouseLeaveHandler = () => {
      // TODO
    };
    const childFactory = child => {
      return React.cloneElement(child, {
        // key: `.$image-rotator-image-${this.state.imageIndex}${this.state.stop ? '-stopped' : ''}`,
        // duration: this.props.duration,
        // index: this.state.imageIndex,
        // numRotations: this.numRotations,
        // url: this.props.images[this.state.imageIndex],
      });
    };

    return (
      <div
        // @ts-ignore TS2339
        className={classnames('image-rotator', this.props.classes)}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
      >
        <TransitionGroup component="span" childFactory={childFactory}>
          {this.renderChildren()}
        </TransitionGroup>
      </div>
    );
  }

  renderChildren() {
    // @ts-ignore TS2339
    let images = this.props.images;
    if (!_.isEmpty(images)) {
      return images.map((url, i) => {
        // @ts-ignore TS2339
        if (this.state.imageIndex != i) {
          return false;
        }

        return (
          <CSSTransition
            classNames="fade"
            timeout={{ enter: 750, exit: 850 }}
            // @ts-ignore TS2339
            key={`image-rotator-image-${this.state.generation}-${i}${this.props.stop ? '-stopped' : ''}`}
          >
            <ImageRotatorCard
              // @ts-ignore TS2339
              duration={this.props.duration}
              index={i}
              // @ts-ignore TS2339
              numRotations={this.numRotations}
              // @ts-ignore TS2322
              preloadImage={this.props.preloadImage}
              url={url}
            />
          </CSSTransition>
        );
      });
    } else {
      return (
        <CSSTransition classNames="fade" timeout={{ enter: 750, exit: 850 }} key={`image-rotator-image-preload`}>
          <ImageRotatorCard
            // @ts-ignore TS2339
            duration={this.props.duration}
            // @ts-ignore TS2322
            isPreload={true}
            index={0}
            numRotations={0}
            // @ts-ignore TS2339
            url={this.props.preloadImage}
          />
        </CSSTransition>
      );
    }
  }

  startRotate() {
    // @ts-ignore TS2339
    if (this.intervalID) {
      return;
    }

    // @ts-ignore TS2339
    this.intervalID = setInterval(() => {
      this.next();
    // @ts-ignore TS2339
    }, this.props.duration);
  }

  next() {
    // @ts-ignore TS2339
    if (!this.props.stop) {
      // @ts-ignore TS2339
      this.numRotations++;
      // @ts-ignore TS2339
      if (this.state.imageIndex + 1 >= this.props.images.length) {
        this.setState({ imageIndex: 0 });
      } else {
        // @ts-ignore TS2339
        this.setState({ imageIndex: this.state.imageIndex + 1 });
      }
    }
  }
}
