import _ from 'src/libs/util';

import useMeta from '../../../../common/hooks/useMeta';

const useHowtoPlay = ({ model, metaId }) => {
  const metaData = useMeta({ model, metaId, propKeys: ['products', 'courses', 'rights', 'free', 'memberOnly'] });

  return {
    products: _.get(metaData, 'meta.products'),
    courses: _.get(metaData, 'meta.courses'),
    rights: _.get(metaData, 'meta.rights'),
    loading: metaData.loading,
    error: metaData.error,
  };
};

export default useHowtoPlay;
