import React from 'react';
import _ from 'src/libs/util';
import ViewingConfirmation from '../../howto_play/ViewingConfirmation';

export default props => {
  const courseCount = _.get(props, 'courses.length', 0);
  const productCount = _.get(props, 'products.length', 0);

  let target = '';
  let actionType;
  if (courseCount + productCount > 1) {
    target += 'いずれかの';
  }
  if (courseCount == 0 && productCount > 0) {
    target += '商品';
    actionType = 'purchase';
  } else if (courseCount > 0 && productCount == 0) {
    target += 'プラン';
    actionType = 'subscribe';
  } else if (courseCount > 0 && productCount > 0) {
    target += 'プランもしくは商品';
  }

  let description;
  if (target) {
    description = `${props.meta.name}を視聴するには、下記の${target}をご購入いただく必要があります。`;
  }

  return (
    <div className="howto-play-description">
      {description}
      <ViewingConfirmation actionType={actionType} />
    </div>
  );
};
