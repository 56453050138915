import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

// @ts-ignore TS2306
import sepDigit from '../../../../../common/sepDigit';
import { withModalContext } from '../../../context/ModalContext';
import RightsPanel from '../common/RightsPanel';
import datetime from 'src/libs/datetime';

const HowToPlayProducts = props => {
  const _renderProductPeriod = product => {
    if (product.productType != 'product_type_rental' || _.get(product, 'rights.length', 0) == 0) {
      return (
        <div className="view-period">
          <p></p>
        </div>
      );
    }

    if (_.get(product, 'rights.length') != 1) {
      return (
        <div className="view-period">
          <p>
            <a
              href="javascript:void(0)"
              onClick={e => {
                props.showModal(<RightsPanel rights={product.rights} />);
              }}
            >
              内容の確認
            </a>
          </p>
        </div>
      );
    }

    const activeRight = product.rights[0] || {};
    return (
      <div className="view-period">
        <span className="sub">{activeRight.displayLimitType}</span>
        <p>
          {activeRight.displayLimitValue} {activeRight.displayLimitUnit}
        </p>
      </div>
    );
  };

  //
  // 販売中のみボタンを表示
  //
  const _renderPurchaseButton = product => {
    if (product.salesStartAt) {
      if (datetime(product.salesStartAt, 'YYYY/MM/DD HH:mm:ss').diffFromNow('second') > 0) {
        return <div>販売開始前</div>;
      }
    }

    if (product.salesEndAt) {
      if (datetime(product.salesEndAt, 'YYYY/MM/DD HH:mm:ss').diffFromNow('second') < 0) {
        return <div>販売終了</div>;
      }
    }

    return (
      <div
        className="submit-button btn btn-fill"
        onClick={e => {
          props.nextAction(product, 'product');
        }}
      >
        購入へ進む
      </div>
    );
  };

  if (!props.products) {
    return null;
  }

  return props.products.map((product, i) => {
    if (_.isEmpty(product.activePricing)) {
      return null;
    }

    return (
      <div className="product-content-row" key={`product-${i}`}>
        <div className="product-info">
          <p className="product-name">
            {product.productType == 'product_type_rental' ? null : (
              <span className="product-type">{product.displayProductType}</span>
            )}
            {product.name}
          </p>
          {_.isEmpty(product.productDescription) ? null : (
            <p className="product-description">{product.productDescription}</p>
          )}
          {product.salesStartAt && datetime(product.salesStartAt, 'YYYY/MM/DD HH:mm:ss').diffFromNow('second') > 0 ? (
            <div className="product-description">{product.salesStartAt}より販売開始</div>
          ) : null}

          <div className="product-condition">
            {_renderProductPeriod(product)}
            <div className="price">
              <span className="sub">価格</span>
              <p>
                {sepDigit(product.activePricing.paymentAmount)}
                <span>{product.activePricing.displayUnit}（税込）</span>
              </p>
            </div>
            <div className="button">{_renderPurchaseButton(product)}</div>
          </div>
        </div>
      </div>
    );
  });
};

export default withModalContext(HowToPlayProducts);
