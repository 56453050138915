import 'core-js/shim';
import 'regenerator-runtime/runtime';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

import _ from 'src/libs/util';

import FalcorDataSource from 'falcor-http-datasource';

import PathEvaluator from 'src/sketch-platform/utils/PathEvaluator';
import initAppContext from 'src/apps/bluerose/initAppContext';
import * as COOKIE from 'src/constants/cookie';
import { ReactClientRenderer } from 'src/sketch-platform/renderers';
import history from 'src/apps/history';

window.app = window.app || {};
window.app.reactContext = window.app.reactContext || {};

window.onpageshow = function(event) {
  // console.log(event);
  const cookieUid = window.app.appContext.cookieDough.get(COOKIE.PROFILE_UID);
  const activeUid = _.get(
    window.app.appContext.getModelData('memberContext'),
    'profileData.active.' + COOKIE.PROFILE_UID,
  );
  // console.log(cookieUid, activeUid);
  if (event.persisted || (window.performance && window.performance.navigation.type == 2)) {
    if (cookieUid != activeUid) {
      window.location.reload();
    }
  }
};

let endpoint = '/pathEvaluator';
if (_.get(window, 'app.reactContext.models.userInfo.data.status') === 'NON_REGISTERED_MEMBER') {
  const deviceCode = _.get(window, 'app.reactContext.models.browserInfo.data.deviceCode');
  endpoint = `/anon/${deviceCode}/path`;
}

const falcorModel = new PathEvaluator({
  collectRatio: 0.75,
  source: new FalcorDataSource(endpoint, {
    headers: {
      'If-Modified-Since': 'Thu, 01 Jan 1970 00:00:00 GMT',
    },
    timeout: 30 * 1000, // デフォルト15秒
  }),
  errorSelector(error) {
    console.error('errorSelector', error);
    error.$expires = -1000 * 60 * 2;
  },
});
falcorModel.setCache(window.app.falcorCache);

const model = _.cloneDeep(window.app.reactContext);
const container = document.getElementById('appMountPoint');

// @ts-ignore TS2554
initAppContext(falcorModel, history, function(appContext) {
  // @ts-ignore TS7006
  new ReactClientRenderer(container, appContext).render((err, a) => {
    if (err) {
      console.error(err);
    }
  });
});
