import { EventEmitter } from 'fbemitter';
import _ from 'src/libs/util';
import cookieDough from 'cookie-dough';

import tokenDataStore from '../utils/tokenDataStore';

class AuthApp {
  private _cookies: cookieDough.CookieDough;
  constructor(models, options = {}, req) {
    // @ts-ignore TS2339
    this._options = options;
    // @ts-ignore TS2339
    this._emitter = new EventEmitter();
    // @ts-ignore TS2339
    this._listeners = [];
    // @ts-ignore TS2339
    this._cookies = cookieDough(req);

    this.on = this.on.bind(this);
    this.off = this.off.bind(this);
    // @ts-ignore TS2339
    this._models = _.pick(models, [
      'config',
      'browserInfo',
      'services',
      'authContext',
      'memberContext',
      'userInfo',
      'wodConfig',
      'onetimeTokenData',
    ]);
    // @ts-ignore TS2551
    this._currentModels = {};

    // this.handleHistoryChange = this.handleHistoryChange.bind(this);
    // if (typeof window !== 'undefined') {
    //   history.listen(this.handleHistoryChange);
    // }
  }

  // handleHistoryChange(location, action) {
  // }

  setRouteHandler(routeHandler) {
    // @ts-ignore TS2551
    this._routeHandler = routeHandler;
    if (typeof window !== 'undefined') {
      // this.syncUser();
    }
  }

  _getModelData(name) {
    // @ts-ignore TS2551
    return _.get(this._currentModels, `${name}.data`, _.get(this._models, `${name}.data`));
  }

  getModelData(name) {
    return this._getModelData(name);
  }

  _setModelData(name, value) {
    // @ts-ignore TS2339
    _.set(this._models, `${name}.data`, value);
    // @ts-ignore TS2551
    _.set(this._currentModels, `${name}.data`, value);
    // あまり良くない
    _.set(window, `app.appContext.state.models.${name}.data`, value);
  }

  setModelData(name, value) {
    this._setModelData(name, value);
  }

  getAuthContext() {
    return this._getModelData('authContext');
  }

  setAuthContext(authContext) {
    this._setModelData('authContext', authContext);
  }

  setCurrentModels(response) {
    return new Promise((resolve, reject) => {
      if (_.get(response, 'models.accountInfo')) {
        // @ts-ignore TS2551
        this._currentModels.accountInfo = response.models.accountInfo;
      }
      if (_.get(response, 'models.authContext')) {
        // @ts-ignore TS2551
        this._currentModels.authContext = response.models.authContext;
        // @ts-ignore TS2551
        tokenDataStore.setAuthContextData(this._currentModels.authContext.data);
      }
      if (_.get(response, 'models.memberContext')) {
        // @ts-ignore TS2551
        this._currentModels.memberContext = response.models.memberContext;
        // @ts-ignore TS2551
        this._currentModels.userInfo = { data: this._currentModels.memberContext.data.userInfo, type: 'model' };
      }
      return resolve(response);
    });
  }

  profiles(withAccountOwner = false) {
    const profiles = _.get(this.getModelData('memberContext'), 'profileData.profiles', []);
    if (withAccountOwner) return profiles;
    return _.filter(profiles, profile => _.get(profile, 'type', null) == 'profile');
  }

  activeProfile() {
    return _.get(this.getModelData('memberContext'), 'profileData.active');
  }

  profile(id) {
    return _.find(this.profiles(), profile => profile.id == id);
  }

  syncUser() {
    const promise = new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          try {
            let response = xhr.response;
            if (typeof response === 'string') {
              response = JSON.parse(response);
            }

            if (xhr.status != 200) {
              return reject(response);
            }

            return resolve(response);
          } catch (e) {
            return reject(e);
          }
        }
      };
      xhr.open('GET', `/api/user/sync`, true);
      xhr.send();
    });

    const beforeProfile = this.activeProfile();

    return promise
      .then(response => {
        if (_.get(response, 'result') === false) {
          // 500エラー等で同期ができない状態なので、更新しない
          return Promise.resolve(response);
        }
        return this.setCurrentModels({
          models: {
            accountInfo: { data: _.get(response, 'accountInfo'), type: 'api' },
            authContext: { data: _.get(response, 'authContext'), type: 'api' },
            memberContext: _.get(response, 'memberContext', { data: {}, type: 'api' }),
          },
        });
      })
      .then(response => {
        const profile = this.activeProfile();
        const beforeId = _.get(beforeProfile, 'id');
        const afterId = _.get(profile, 'id');
        // プロフィールの選択状況が変わった時
        if (beforeId != afterId) {
          // console.log('valid profile: ' + beforeId + ' -> ' + afterId);
          window.location.reload();
        }
      })
      .catch(e => {
        console.log(e);
        throw e;
      });
  }

  on(eventName, fn) {
    // @ts-ignore TS2339
    this.addEmitterListener(eventName, fn);
  }

  off(eventName, fn) {
    // @ts-ignore TS2339
    this.removeEmitterListener(eventName, fn);
  }
}

export default AuthApp;
