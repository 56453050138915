import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';
import MainViewLink from '../../../../common/components/MainViewLink';

export default class RoomEnd extends Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }

  static get propTypes() {
    return {
      handleWatchPartyModeChange: PropTypes.func,
      closeModal: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);

    this._handleClose = this._handleClose.bind(this);
    this._handleWatchPartyEnd = this._handleWatchPartyEnd.bind(this);
  }

  _handleClose() {
    // @ts-ignore TS2339
    this.props.closeModal();
  }

  _handleWatchPartyEnd() {
    // コールバックが指定されている場合
    // @ts-ignore TS2339
    if (this.props.linkCallback) {
      // @ts-ignore TS2339
      this.props.linkCallback();
      return;
    }
    this.context.watchPartyApp.dispose();
    // @ts-ignore TS2339
    this.props.closeModal();
    // @ts-ignore TS2339
    this.props.handleWatchPartyModeChange();
  }

  render() {
    const room = this.context.watchPartyApp.room;
    return (
      <React.Fragment>
        <div className="modal-title">
          {this.context.watchPartyApp.isMaster() ? 'ウォッチパーティを終了' : 'ウォッチパーティから退室'}
        </div>
        <div className="text-left">
          <div className="modal-block">
            {this.context.watchPartyApp.isMaster() ? (
              <p>
                {/*
                 // @ts-ignore TS2322 */}
                <span class="text-accent">{room.name}</span>のウォッチパーティを終了します。
                <br />
                ルーム主催者が終了すると、他の参加者もルームから退室されます。
                <br />
                それでも終了してよろしいですか？
              </p>
            ) : (
              <p>
                {/*
                 // @ts-ignore TS2322 */}
                <span class="text-accent">{room.name}</span>から退室します。
                <br />
                退室してよろしいですか？
              </p>
            )}
          </div>
          <div className="form-btn-block text-center">
            <a className="btn btn-gray btn-small" href="javascript:void(0);" onClick={this._handleClose}>
              キャンセル
            </a>
            {/*
             // @ts-ignore TS2339 */}
            {this.props.linkProps ? (
              // @ts-ignore TS2339
              <MainViewLink {...this.props.linkProps} className="btn btn-fill btn-small" watchPartyNoLinkConfirm={true}>
                {this.context.watchPartyApp.isMaster() ? '終了する' : '退室する'}
              </MainViewLink>
            ) : (
              <a className="btn btn-fill btn-small" href="javascript:void(0);" onClick={this._handleWatchPartyEnd}>
                {this.context.watchPartyApp.isMaster() ? '終了する' : '退室する'}
              </a>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
