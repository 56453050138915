import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';
import formatTime from '../../../../common/formatTime';
import { LIVE_RANGE } from '../../../../constants';
import context from 'src/common/globalContext';

type TimeRemainingProps = {
  isBlurred?: boolean;
  isHidden?: boolean;
  player: any;
  startHover?: (controlName: string) => void;
  stopHover?: (controlName: string) => void;
  isAdMode: boolean;
  adDuration: number;
  currentAdTime: number;
  ended: boolean;
};

type PropsForLiveSpan = {
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
};

const TimeRemaining = (props: TimeRemainingProps, ref) => {
  const { isAdMode, adDuration, currentAdTime, player, isHidden, startHover, stopHover, ended } = props;

  const [currentPlayerTime, setCurrentPlayerTime] = useState<number>(0);
  const [currentScrubTime, setCurrentScrubTime] = useState<number>(-1);
  const [isScrubbing, setIsScubbing] = useState<boolean>(false);
  const [firstDurationChange, setFirstDurationChange] = useState(false);

  const update = () => {
    setCurrentPlayerTime(player.currentTime());
  };
  const throttleUpdateRef = useRef(_.throttle(update, 200));
  const throttleUpdate = throttleUpdateRef.current;

  useEffect(() => {
    if (!isHidden) {
      player.on('timeupdate', throttleUpdate);
    }

    const handleDurationChange = () => {
      player.off('durationchange', handleDurationChange);
      setFirstDurationChange(true);
    };

    player.on('durationchange', handleDurationChange);

    return () => {
      if (!firstDurationChange) {
        player.off('durationchange', handleDurationChange);
      }
      player.off('timeupdate', throttleUpdate);
    };
  }, [throttleUpdate, player, isHidden, firstDurationChange]);

  useEffect(() => {
    if (player && !isHidden) {
      player.on('timeupdate', throttleUpdate);
      return () => {
        player.off('timeupdate', throttleUpdate);
      };
    }
  }, [throttleUpdate, player, isHidden]);

  const handleLiveClick = (e, disabled, seekableEnd) => {
    if (!disabled) return;
    player.currentTime(seekableEnd);
  };

  const onMouseEnter = () => {
    if (startHover) {
      startHover('live-skip');
    }
  };

  const onMouseLeave = () => {
    if (stopHover) {
      stopHover('');
    }
  };

  // @ts-ignore TS2339
  const browserInfo = context.getModelData('browserInfo');

  let duration = player.duration();
  let currentTime = currentPlayerTime;

  if (isAdMode) {
    duration = adDuration;
    currentTime = currentAdTime;
  }

  let disabled = false;
  let seekableEnd = 0;
  if (duration === Infinity) {
    try {
      seekableEnd = player.seekable().end(0);
      if (seekableEnd - currentTime > LIVE_RANGE) {
        disabled = true;
      }
    } catch (e) {}
  } else if (ended) {
    currentTime = duration;
  } else {
    currentTime = _.min([currentTime, duration]);
  }

  let currentTimeF = formatTime(currentTime);
  let durationF = formatTime(duration);

  if (durationF.length > 5 && currentTimeF.length < 7) {
    currentTimeF = (currentTimeF.length === 4 ? '0:0' : '0:') + currentTimeF;
  }

  const propsForLiveSpan: PropsForLiveSpan = {};
  if (!browserInfo.isIOS && !browserInfo.isAndroid && disabled) {
    propsForLiveSpan.onMouseEnter = onMouseEnter;
    propsForLiveSpan.onMouseLeave = onMouseLeave;
    propsForLiveSpan.onFocus = onMouseEnter;
    propsForLiveSpan.onBlur = onMouseLeave;
  }

  return (
    <div className="player-control-element time-remaining text-control">
      {firstDurationChange && duration === Infinity ? (
        <span
          ref={ref}
          {...propsForLiveSpan}
          className={classnames('live', { disabled })}
          onClick={e => handleLiveClick(e, disabled, seekableEnd)}
        >
          LIVE
        </span>
      ) : (
        <time className="time">
          {currentTimeF}
          <span> / {formatTime(duration)}</span>
        </time>
      )}
    </div>
  );
};

TimeRemaining.propTypes = {
  isBlurred: PropTypes.bool,
  isHidden: PropTypes.bool,
  player: PropTypes.object.isRequired,
  startHover: PropTypes.func,
  stopHover: PropTypes.func,
  isAdMode: PropTypes.bool.isRequired,
  adDuration: PropTypes.number.isRequired,
  currentAdTime: PropTypes.number.isRequired,
  ended: PropTypes.bool.isRequired,
};

TimeRemaining.contextTypes = {
  getModelData: PropTypes.func,
};

// export default TimeRemaining;
export default React.forwardRef(function timeRemaining(props, ref) {
  // @ts-ignore TS2322
  return <TimeRemaining {...props} forwardedRef={ref} />;
});
