import React from 'react';
import _ from 'src/libs/util';

const Button = props => {
  const { forwardedRef, ...rest } = props;
  return (
    <div className="player-control-element">
      <button role="button" {...rest} ref={forwardedRef}></button>
    </div>
  );
};

export default Button;
