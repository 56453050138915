import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

import { withLayoutContext } from '../../common/context/LayoutContext';
import LoadingBar from './LoadingBar';

class PageTransitionProgressBar extends React.PureComponent {
  static get propTypes() {
    return {
      path: PropTypes.string,
      history: PropTypes.object,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      prevPath: props.routeHandler.path,
      progress: 0,
    };
    this.handleHistoryChange = this.handleHistoryChange.bind(this);
    // @ts-ignore TS2339
    this.loadingTimeoutId = null;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    this.unlisten = this.props.history.listen(this.handleHistoryChange);
  }

  componentWillReceiveProps(nextProps) {
    // @ts-ignore TS2339
    if (this.props.routeHandler.url != nextProps.routeHandler.url) {
      // @ts-ignore TS2339
      clearTimeout(this.loadingTimeoutId);
      // @ts-ignore TS2339
      if (this._isMounted) {
        this.setState({ progress: 100 });
      }
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.unlisten) this.unlisten();
    // @ts-ignore TS2339
    clearTimeout(this.loadingTimeoutId);
  }

  handleHistoryChange(location, action) {
    // @ts-ignore TS2339
    const isCancellingPageTransition = _.isEqual(window.location.pathname, this.props.routeHandler.path);
    if (isCancellingPageTransition) {
      // @ts-ignore TS2339
      if (this.state.progress !== 0) {
        this.setState({ progress: 0 });
      }
      return;
    }
    if (_.get(location, 'state.norender')) return;
    // @ts-ignore TS2339
    if (this._isMounted && this.state.prevPath != location.pathname) {
      // @ts-ignore TS2339
      if (this.props.layoutHeader) {
        // @ts-ignore TS2339
        this.props.layoutHeader({});
      }
      this.setState({ prevPath: location.pathname }, this.loadingProgress);
    }
  }

  loadingProgress() {
    const updateProgress = progress => {
      // @ts-ignore TS2339
      if (this._isMounted) {
        this.setState({ progress: progress }, () => {});
      }
    };
    updateProgress(90);
  }

  render() {
    // @ts-ignore TS2322
    return <LoadingBar progress={this.state.progress} />;
  }
}
export default withLayoutContext(PageTransitionProgressBar);
