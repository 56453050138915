import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import { Helmet } from 'react-helmet';

import Canvas from './Canvas';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import { NotfoundError } from '../../../../constants/error';
import { NotFoundError as NFErr } from '../../../common/components/ErrorBoundary';

import Watch from './Watch';
// import RealtimeDetail from './RealtimeDetail';

class Simulcast extends React.Component {
  static getPaths = function(models, options, props = {}) {
    let paths = [['linearChannel', { from: 0, to: 5 }, ['id', 'name', 'refId']]];
    return paths;
  };

  static getRootPath = function(models, options, props = {}) {
    return ['linearChannel'];
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static getPrefetchedPaths = function(models, options, props) {
    return data => {
      const items = _.omit(_.get(data.json, this.getRootPath(models, options, props)), ['$__path']);
      const simulcast = _.find(models.simulcast.data, item => item.name == props.channelName) || {};
      const linearChannel = _.find(items, item => item.refId == simulcast.refId) || {};
      props = Object.assign({}, props, { id: linearChannel.id });
      // @ts-ignore TS2339
      return Watch.getPrefetchPaths(models, options, props);
    };
  };

  static afterPrefetch = function(models, options, props) {
    return data => {
      const items = _.omit(_.get(data.json, this.getRootPath(models, options, props)), ['$__path']);
      const simulcast = _.find(models.simulcast.data, item => item.name == props.channelName) || {};
      const linearChannel = _.find(items, item => item.refId == simulcast.refId);
      if (_.isEmpty(linearChannel)) {
        // @ts-ignore TS2554
        return { error: new NotfoundError() };
      }
    };
  };

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    const items = this.model.getSync(rootPath);
    const simulcast = _.find(context.getModelData('simulcast'), item => item.name == props.channelName) || {};
    // @ts-ignore TS2339
    this.linearChannel = _.find(items, item => item.refId == simulcast.refId);
    // @ts-ignore TS2339
    if (_.isEmpty(this.linearChannel)) {
      // @ts-ignore TS2554
      throw new NFErr();
    }
    // console.log(items)
    // console.log(simulcast, this.linearChannel);
  }

  render() {
    // @ts-ignore TS2322
    return <Watch {...this.props} id={this.linearChannel.id} />;
  }
}

export default Simulcast;
