import React from 'react';
import _ from 'src/libs/util';

class DisableRenditionModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {};
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  handleClick() {
    // @ts-ignore TS2339
    if (this.props.closeModal) this.props.closeModal();
  }

  render() {
    return (
      <div className="fallback-modal">
        <div className="modal-text">
          <p className="text text-center">
            <span className="block">Macをご利用の場合、画質選択機能に制限がございます。</span>
            <span className="block">なお、Safariの最新バージョンでは、より高画質でお楽しみいただけます。</span>
          </p>
        </div>
        <div className="btn-block">
          <button className="btn btn-fill" name="fallbackModalClose" onClick={this.handleClick}>
            閉じる
          </button>
        </div>
      </div>
    );
  }
}

export default DisableRenditionModal;
