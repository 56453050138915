import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

import Meta from '../Meta';
import Tags from './Tags';
import EpgEvent from './EpgEvent';
import DetailText from '../../player/DetailText';
import MainViewLink from '../../../../common/components/MainViewLink';

import classnames from 'classnames';
import SetDate from '../SetDate';

export default class SimulcastEventInfo extends Component {
  static getPaths = function(models, options, props = {}) {
    let path = [];
    // @ts-ignore TS2339
    if (props.id) {
      path = path.concat(this.getRootPath(models, options, props));
    }
    EpgEvent.getPaths(models, options, {}).map(_path => {
      path = path.concat(_path);
    });
    return [path];
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    return ['meta', props.id, 'onair'];
  };

  static get propTypes() {
    return {
      onSliderMove: PropTypes.func,
      model: PropTypes.object.isRequired,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      myListButton: PropTypes.object,
    };
  }
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      spMode: PropTypes.bool,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    this.state = {
      fetchDataError: null,
      dispose: null,
      generation: props.model.getVersion(rootPath),
    };
    // @ts-ignore TS2339
    this.item = props.model.getSync(rootPath);
    // @ts-ignore TS2339
    if (this.item) {
      // @ts-ignore TS2339
      this.item = EpgEvent.additionalData(this.item);
      // @ts-ignore TS2339
      if (props.onEventChange) props.onEventChange(this.item);
    }
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);

    // @ts-ignore TS2551
    this.refreshId = setInterval(this.refresh, 1000 * 60);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // @ts-ignore TS2339
    if (nextProps.id !== this.props.id) {
      // @ts-ignore TS2339
      const rootPath = this.constructor.getRootPath(nextContext.models, {}, nextProps);
      // @ts-ignore TS2339
      this.item = nextProps.model.getSync(rootPath);
      this.setState({ generation: nextProps.model.getVersion(rootPath), fetchDataError: null }, () => {
        this.fetchData(nextProps);
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // @ts-ignore TS2339
    if (prevState.generation !== this.state.generation) {
      // @ts-ignore TS2339
      if (this.props.onEventChange) this.props.onEventChange(this.item);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2551
    if (this.refreshId) {
      // @ts-ignore TS2551
      clearInterval(this.refreshId);
      // @ts-ignore TS2551
      delete this.refreshId;
    }
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  refresh() {
    this.fetchData(this.props);
  }

  fetchData(props) {
    if (!props.id) return;

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // すでに通信している場合は実行しない
    //if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    //this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.get(res, ['json'].concat(rootPath));
        // @ts-ignore TS2339
        this.item = EpgEvent.additionalData(this.item);
        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');
    let channelName;
    const simulcast = _.find(
      this.context.getModelData('simulcast'),
      // @ts-ignore TS2339
      item => item.refId == this.props.metadata.linearChannelMeta.refId,
    );
    if (simulcast) channelName = _.lowerCase(simulcast.name);

    const imulti = () => {
      // @ts-ignore TS2339
      if (_.get(this.props.metadata, 'imultiflag.value') > 0) {
        // @ts-ignore TS2339
        return <span>※{this.props.metadata.imultiflag.label}</span>;
      }
      return '';
    };

    return (
      <div className="watch-info with-event">
        <div className="watch-info__colum_left" id="columLeft">
          {/*
           // @ts-ignore TS2339 */}
          {this.props.mediaQueryCheck !== 1 && this.props.titleHidden == false && this.props.metadata.name ? (
            <p className={classnames('playable-title', channelName)} id="infoTitle">
              <img src={`../../images/logo_${channelName}_white.svg`} />
              {/*
               // @ts-ignore TS2339 */}
              {this.props.metadata.name}
            </p>
          ) : null}
          {/*
           // @ts-ignore TS2339 */}
          {this.props.mediaQueryCheck !== 1 && this.props.titleHidden == false ? (
            <React.Fragment>
              {/*
               // @ts-ignore TS2339 */}
              <Meta metadata={this.props.metadata} />
              {/*
               // @ts-ignore TS2339 */}
              <Tags {...this.props.metadata} />
            </React.Fragment>
          ) : null}
          {/*
           // @ts-ignore TS2339 */}
          {this.props.metadata && (
            <DetailText
              // @ts-ignore TS2322
              titleHidden={this.props.titleHidden}
              // @ts-ignore TS2339
              metadata={this.props.metadata}
              // @ts-ignore TS2339
              model={this.props.model}
              linearFlag={true}
              // @ts-ignore TS2339
              mediaQueryCheck={this.props.mediaQueryCheck}
            />
          )}
        </div>
        {/* @ts-ignore TS2339 */}
        {this.props.metadata.links && this.props.metadata.links.length > 0 ? (
          <div className="watch-info__colum_right" id="columRight">
            {/*
            // @ts-ignore TS2339 */}
            {_.map(this.props.metadata.links, (link, i) => {
              return (
                // @ts-ignore TS2322
                <MainViewLink key={`link-${i}`} href={link.url} target={'_blank'} className="watch-info__link">
                  {link.name}
                </MainViewLink>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}
