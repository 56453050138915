import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import classnames from 'classnames';

import tokenDataStore from '../../../utils/tokenDataStore';
import Axios from '../../../common/Axios';
import * as ERROR from '../../../constants/error';
import panelHOC from '../hocs/panelHOC';
import { withModalContext } from '../context/ModalContext';
import { addOnetimeTokenToQueryString } from '../utils/fetchOnetimeToken';

import ErrorView from './UpSellFlow/ErrorView';
import HowToPlay from './UpSellFlow/HowToPlay';
import Confirm from './UpSellFlow/Confirm';
import Finish from './UpSellFlow/Finish';
import Duplicate from './UpSellFlow/Duplicate';
import RegistForm from './UpSellFlow/RegistForm';
import routes from '../routes';

const commitError = function(resData) {
  let err = new Error(_.get(resData, 'message'));
  if (_.get(resData, 'code')) {
    // @ts-ignore TS2339
    err.code = resData.code;
  }
  if (_.get(resData, 'dcode')) {
    // @ts-ignore TS2339
    err.dcode = resData.dcode;
  }
  return err;
};

//
// 契約or購入フローを管理するComponent
//
class UpSellFlow extends React.PureComponent {
  static get propTypes() {
    return {
      model: PropTypes.object,
      metaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      routeHandler: PropTypes.object,
      history: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.state = { loading: true, step: 'started' };
    // @ts-ignore TS2339
    this.axios = new Axios({ xhr: true });

    this.doSelect = this.doSelect.bind(this);
    this.cancelSelect = this.cancelSelect.bind(this);
    this.doCommit = this.doCommit.bind(this);
    this.redirectToWatch = this.redirectToWatch.bind(this);

    // @ts-ignore TS2339
    this.registFormRef = React.createRef();

    // howToPlayInfoが渡されたときはHowToPlayから表示する
    if (props.howToPlayInfo) {
      this.setHowToPlayInfo(props.howToPlayInfo);

      // confirmInfoが渡されたときはConfirmから表示する
    } else if (props.confirmInfo) {
      this.setConfirmInfo(props.confirmInfo);

      // finishInfoが渡されたときはFinish表示する
    } else if (props.finishInfo) {
      this.setFinishInfo(props.finishInfo);

      // duplicateInfoが渡されたときはduplicate表示する
    } else if (props.duplicateInfo) {
      this.setDuplicateInfo(props.duplicateInfo);
    }
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchHowToPlayInfo();
  }

  componentWillReceiveProps(nextProps) {
    // @ts-ignore TS2339
    if (this.props.metaId != nextProps.metaId) {
      this.fetchHowToPlayInfo(nextProps);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;

    // 購入フローがモーダルの時に
    // 購入後にモーダルを消してしまうと再度購入フローに入ってしまうため
    // モーダルの時かつ購入後の場合はwindowをリロードする
    // @ts-ignore TS2339
    if (typeof this.props.closeModal === 'function' && this.state.step === 'finish') {
      window.location.reload();
    }
  }

  render() {
    let loadingView;

    // @ts-ignore TS2339
    if (this.state.loading) {
      loadingView = (
        <div className="loading-spinner-container">
          <div className="loading-spinner"></div>
        </div>
      );
    }

    let content;
    // @ts-ignore TS2339
    switch (this.state.step) {
      case 'started':
        break;
      case 'howto_play':
        content = (
          <HowToPlay
            // @ts-ignore TS2339
            model={this.model}
            // @ts-ignore TS2339
            meta={this.meta}
            // @ts-ignore TS2339
            courses={this.courses}
            // @ts-ignore TS2339
            products={this.products}
            nextAction={this.doSelect}
          />
        );
        break;
      case 'confirm':
        content = (
          <Confirm
            // @ts-ignore TS2339
            model={this.props.model}
            // @ts-ignore TS2339
            meta={this.meta}
            // @ts-ignore TS2339
            item={this.activeItem}
            // @ts-ignore TS2339
            itemType={this.activeItemType}
            // @ts-ignore TS2339
            paymentMethods={this.paymentMethods}
            // @ts-ignore TS2339
            paymentInstruments={this.paymentInstruments}
            // @ts-ignore TS2551
            userVoucherInfo={this.userVoucherInfo}
            nextAction={this.doCommit}
            prevAction={this.cancelSelect}
            // @ts-ignore TS2339
            estimateInfo={this.estimateInfo}
            submitError={_.get(this, 'error.commitError')}
            // @ts-ignore TS2339
            defaultPaymentInstrumentId={this.props.defaultPaymentInstrumentId}
          />
        );
        break;
      case 'finish':
        content = (
          <Finish
            // @ts-ignore TS2339
            model={this.model}
            // @ts-ignore TS2339
            meta={this.meta}
            // @ts-ignore TS2339
            item={this.activeItem}
            // @ts-ignore TS2339
            itemType={this.activeItemType}
            // @ts-ignore TS2339
            accountInfo={this.accountInfo}
            // @ts-ignore TS2339
            subscription={this.subscription}
            // @ts-ignore TS2339
            productOrder={this.productOrder}
            // @ts-ignore TS2339
            paymentInstrument={this.selectedPaymentInstrument}
            // @ts-ignore TS2339
            entitlements={this.grantedEntitlements}
            // @ts-ignore TS2339
            userVouchers={this.usedUserVouchers}
            // @ts-ignore TS2339
            question={this.finishQuestion}
            returnTo={this.watchUrl()}
            redirectTo={this.redirectToWatch}
          />
        );
        break;
      case 'duplicate':
        content = (
          <Duplicate
            // @ts-ignore TS2322
            model={this.model}
            // @ts-ignore TS2339
            meta={this.meta}
            // @ts-ignore TS2339
            subscriptions={this.duplicatedSubscriptions}
            // @ts-ignore TS2339
            productOrders={this.duplicatedProductOrders}
            returnTo={this.watchUrl()}
            redirectTo={this.redirectToWatch}
          />
        );
        break;
      // @ts-ignore TS7029
      case 'error':
        content = this.renderError();
      default:
    }

    return (
      // @ts-ignore TS2339
      <div className={classnames('up-sell-flow', { 'error-flow': this.state.step === 'error' })}>
        <div className="flow-header"></div>
        <div className="flow-content">{content}</div>
        {/*
         // @ts-ignore TS2339 */}
        <RegistForm ref={this.registFormRef} metaId={this.props.metaId} />
        {loadingView}
      </div>
    );
  }

  renderError() {
    // @ts-ignore TS2339
    return <ErrorView error={this.error} onClickCancel={this.cancelSelect} />;
  }

  //
  // propsでhowToPlayInfoが渡されたときは
  // データやメソッドを上書きしてHowToPlay表示させる
  //
  setHowToPlayInfo(howToPlayInfo) {
    if (typeof howToPlayInfo !== 'object') {
      return;
    }

    // @ts-ignore TS2339
    this.state.step = howToPlayInfo.step;
    // @ts-ignore TS2339
    this.state.loading = false;

    if (howToPlayInfo.errorMessage) {
      // @ts-ignore TS2339
      this.error = new Error(howToPlayInfo.errorMessage);
      // @ts-ignore TS2339
      this.state.step = 'error';
    }

    const fields = ['meta', 'courses', 'products', 'paymentMethods', 'paymentInstruments'];
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (howToPlayInfo[field]) {
        this[field] = howToPlayInfo[field];
      }
    }

    this.componentDidMount = () => {
      // @ts-ignore TS2339
      this._isMounted = true;
      // propsで表示に必要なデータを渡すのでfetchはしない
    };

    // 選択操作を上書き
    if (typeof howToPlayInfo.handleSelect === 'function') {
      this.doSelect = (item, type) => {
        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState({ loading: true });
        }
        howToPlayInfo.handleSelect(item, type);
      };
    }
  }

  //
  // propsでconfirmInfoが渡されたときは
  // データやメソッドを上書きしてConfirm表示させる
  //
  setConfirmInfo(confirmInfo) {
    if (typeof confirmInfo !== 'object') {
      return;
    }

    // @ts-ignore TS2339
    this.state.step = confirmInfo.step;
    // @ts-ignore TS2339
    this.state.loading = false;

    if (confirmInfo.errorMessage) {
      // @ts-ignore TS2339
      this.error = new Error(confirmInfo.errorMessage);
      // @ts-ignore TS2339
      this.state.step = 'error';
    }

    const fields = [
      'meta',
      'courses',
      'products',
      'paymentMethods',
      'paymentInstruments',
      'activeItem',
      'activeItemType',
      'userVoucherInfo',
      'estimateInfo',
      'error',
    ];
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (confirmInfo[field]) {
        this[field] = confirmInfo[field];
      }
    }

    this.componentDidMount = () => {
      // @ts-ignore TS2339
      this._isMounted = true;
      // propsで表示に必要なデータを渡すのでfetchはしない
    };
    this.componentWillReceiveProps = nextProps => {
      // @ts-ignore TS2339
      if (this.props.metaId != nextProps.metaId) {
        this.fetchConfirmInfo(nextProps);
      }
    };

    // Confirmからのキャンセル操作を上書き
    this.cancelSelect = () => {
      confirmInfo.handleCancel();
    };

    // 契約/購入後の後処理
    if (typeof confirmInfo.handleAfterCommit === 'function') {
      // @ts-ignore TS2339
      this.handleAfterCommit = id => {
        confirmInfo.handleAfterCommit(id);
      };
    }
  }

  //
  // propsでfinishInfoが渡されたときは
  // データやメソッドを上書きしてfinish表示させる
  //
  setFinishInfo(finishInfo) {
    if (typeof finishInfo !== 'object') {
      return;
    }

    // @ts-ignore TS2339
    this.state.step = finishInfo.step;
    // @ts-ignore TS2339
    this.state.loading = false;

    if (finishInfo.errorMessage) {
      // @ts-ignore TS2339
      this.error = new Error(finishInfo.errorMessage);
      // @ts-ignore TS2339
      this.state.step = 'error';
    }

    const fields = [
      'meta',
      'activeItem',
      'activeItemType',
      'accountInfo',
      'subscription',
      'productOrder',
      'selectedPaymentInstrument',
      'grantedEntitlements',
      'usedUserVouchers',
      'finishQuestion',
      'returnTo',
    ];
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (finishInfo[field]) {
        this[field] = finishInfo[field];
      }
    }

    this.componentDidMount = () => {
      // @ts-ignore TS2339
      this._isMounted = true;
      // propsで表示に必要なデータを渡すのでfetchはしない
    };
  }

  //
  // propsでduplicateInfoが渡されたときは
  // データやメソッドを上書きしてduplicate表示させる
  //
  setDuplicateInfo(duplicateInfo) {
    if (typeof duplicateInfo !== 'object') {
      return;
    }

    // @ts-ignore TS2339
    this.state.step = duplicateInfo.step;
    // @ts-ignore TS2339
    this.state.loading = false;

    const fields = ['meta', 'duplicatedSubscriptions', 'duplicatedProductOrders'];
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (duplicateInfo[field]) {
        this[field] = duplicateInfo[field];
      }
    }

    this.componentDidMount = () => {
      // @ts-ignore TS2339
      this._isMounted = true;
      // propsで表示に必要なデータを渡すのでfetchはしない
    };
  }

  //
  // コース/商品一覧での情報取得
  //
  fetchHowToPlayInfo(props = this.props) {
    // @ts-ignore TS2339
    if (!this._isMounted) {
      return;
    }
    this.setState({ loading: true });

    // @ts-ignore TS2339
    return this.axios
      .get('/api/account/me', { with_matching_info_lack_flag: true })
      .then(result => {
        if (!_.get(result, 'data.result')) {
          throw new Error(_.get(result, 'data.message'));
        }
        tokenDataStore.setAuthContextData(_.get(result, 'data.authContext'));
        // 名寄せされていない
        if (_.get(result, 'data.account.matchingInfoLackFlag')) {
          throw new Error(ERROR.MATCHING_INFO_LACK);
        }

        // @ts-ignore TS2339
        return this.axios
          .multi([
            // @ts-ignore TS2339
            this.axios.post('/api/account/check_availability', { meta_id: props.metaId }),
            // @ts-ignore TS2339
            this.axios.get(`/api/meta/${props.metaId}`),
            // @ts-ignore TS2339
            this.axios.get(`/api/meta/${this.props.metaId}/howto_play`, {
              with_howto_acquisition: true,
              with_terms: true,
            }),
            // @ts-ignore TS2339
            this.axios.get('/api/account/trial_used_course_ids'),
          ])
          .then(results => {
            this.checkAvailability(results[0]);
            this.setMeta(results[1]);
            this.setCoursesAndProducts(results[2], results[3]);
          })
          .catch(e => {
            throw e;
          });
      })
      .then(() => {
        // 選べる対象が複数ある場合は選択肢を表示
        if (!this.existOnlyOneItem()) {
          // @ts-ignore TS2339
          if (!this._isMounted) {
            return;
          }
          this.setState({ loading: false, step: 'howto_play' });
          return;
        }

        // 選べる対象が一つだけの場合は
        // 必要な情報を取得してConfirmを表示
        this.setOnlyOneItem();
        const q = {
          // 最後に登録された支払い方法をデフォルトで出す
          sort: 'created_at',
          order: 'desc',
        };
        // @ts-ignore TS2339
        if (this.activeItemType == 'product') {
          // @ts-ignore TS2339
          q.product_id = this.activeItem.id;
        } else {
          // @ts-ignore TS2339
          q.course_id = this.activeItem.id;
        }
        // @ts-ignore TS2339
        return this.axios
          .multi([
            // @ts-ignore TS2339
            this.axios.get('/api/user/payment_methods', { service_type: this.activeItemType }),
            // @ts-ignore TS2339
            this.axios.get('/api/account/payment_instruments', q),
          ])
          .then(results => {
            // @ts-ignore TS2339
            this.paymentMethods = _.get(results, '[0].data.paymentMethods');
            // @ts-ignore TS2339
            this.paymentInstruments = _.get(results, '[1].data.paymentInstruments');

            // productの場合はWOWOWオンデマンドチケットの情報を取得
            const toConfirm = () => {
              // @ts-ignore TS2339
              if (!this._isMounted) {
                return;
              }
              this.setState({ loading: false, step: 'confirm' });
            };
            // @ts-ignore TS2339
            if (this.activeItemType == 'product') {
              // @ts-ignore TS2339
              this.fetchUserVoucherInfo(this.activeItem.id, toConfirm);
            } else {
              // @ts-ignore TS2339
              this.fetchEstimateInfo({ course_id: this.activeItem.id }, toConfirm);
            }
          })
          .catch(e => {
            throw e;
          });
      })
      .catch(e => {
        this.handleError(e);
      });
  }

  //
  // コース/商品の確認ページでの情報取得
  //
  fetchConfirmInfo(props = this.props) {
    // @ts-ignore TS2339
    if (!this._isMounted) {
      return;
    }
    this.setState({ loading: true });

    // @ts-ignore TS2339
    return this.axios
      .get('/api/account/me')
      .then(result => {
        tokenDataStore.setAuthContextData(_.get(result, 'data.authContext'));
        // if (!_.get(result, 'data.account')) {
        //   throw new Error(ERROR.UNAUTHORIZED);
        // }
        const q = {
          // 最後に登録された支払い方法をデフォルトで出す
          sort: 'created_at',
          order: 'desc',
        };
        // @ts-ignore TS2339
        if (this.activeItemType == 'product') {
          // @ts-ignore TS2339
          q.product_id = this.activeItem.id;
        } else {
          // @ts-ignore TS2339
          q.course_id = this.activeItem.id;
        }
        // @ts-ignore TS2339
        return this.axios
          .multi([
            // @ts-ignore TS2339
            this.axios.post('/api/account/check_availability', { meta_id: props.metaId }),
            // @ts-ignore TS2339
            this.axios.get(`/api/meta/${props.metaId}`),
            // @ts-ignore TS2339
            this.axios.get(`/api/meta/${this.props.metaId}/howto_play`, {
              with_howto_acquisition: true,
              with_terms: true,
            }),
            // @ts-ignore TS2339
            this.axios.get('/api/account/trial_used_course_ids'),
            // @ts-ignore TS2339
            this.axios.get('/api/user/payment_methods', { service_type: this.activeItemType }),
            // @ts-ignore TS2339
            this.axios.get('/api/account/payment_instruments', q),
          ])
          .then(results => {
            this.checkAvailability(results[0]);
            this.setMeta(results[1]);
            this.setCoursesAndProducts(results[2], results[3]);
            // @ts-ignore TS2339
            this.paymentMethods = _.get(results, '[4].data.paymentMethods');
            // @ts-ignore TS2339
            this.paymentInstruments = _.get(results, '[5].data.paymentInstruments');

            this.activeItemValidation();
            // @ts-ignore TS2339
            this.error = null;

            // productの場合はWOWOWオンデマンドチケットの情報を取得
            const toConfirm = () => {
              // @ts-ignore TS2339
              if (!this._isMounted) {
                return;
              }
              this.setState({ loading: false, step: 'confirm' });
            };
            // @ts-ignore TS2339
            if (this.activeItemType == 'product') {
              // @ts-ignore TS2339
              this.fetchUserVoucherInfo(this.activeItem.id, toConfirm);
            } else {
              // @ts-ignore TS2339
              this.fetchEstimateInfo({ course_id: this.activeItem.id }, toConfirm);
            }
          })
          .catch(e => {
            this.handleError(e);
          });
      })
      .catch(e => {
        this.handleError(e);
      });
  }

  //
  // WOWOWオンデマンドチケットの情報取得
  //
  fetchUserVoucherInfo(productId, cb) {
    // @ts-ignore TS2551
    this.userVoucherInfo = null;

    const q = {
      product_id: productId,
      sort: 'expire_at',
      order: 'asc',
      status: 'unused',
      expired: 'without',
      page: 1,
      limit: 1,
      type: 'WodTicket',
      with_total_count: true,
    };

    // ユーザのWOWOWオンデマンドチケットを引く
    // @ts-ignore TS2339
    this.axios
      .get('/api/account/vouchers', q)
      .then(result => {
        tokenDataStore.setAuthContextData(_.get(result, 'data.authContext'));
        if (_.get(result, 'data.message')) {
          // 存在しない場合
          if (result.data.code == 4) return cb();
          throw new Error(result.data.message);
        } else {
          return result.data;
        }

        // 使用できるバウチャーが存在すればそれを適用した結果の値を得る
      })
      .then(userVouchersData => {
        const userVoucher = _.get(userVouchersData, 'data.userVouchers[0]');
        if (_.isEmpty(userVoucher)) {
          return cb();
        }

        // @ts-ignore TS2339
        this.axios
          .post('/api/user/estimate', {
            user_voucher_id: userVoucher.id,
            product_id: productId,
          })
          .then(result => {
            tokenDataStore.setAuthContextData(_.get(result, 'data.authContext'));
            if (_.get(result, 'data.message')) {
              throw new Error(result.data.message);
            } else {
              // バウチャーの情報をセット
              const appliedData = _.get(result, 'data.data');
              // @ts-ignore TS2551
              this.userVoucherInfo = {
                appliedData: appliedData,
                totalCount: _.get(userVouchersData, 'data.totalCount'),
              };
              cb();
            }
          })
          .catch(e => {
            throw e;
          });
      })
      .catch(e => {
        throw e;
      });
  }

  //
  // 契約したときにどんなサブスクリプションができるか
  // q = {
  //   course_id: #{コースID}
  // }
  //
  fetchEstimateInfo(q, cb) {
    // @ts-ignore TS2339
    this.axios
      .post('/api/subscription/estimate', q)
      .then(result => {
        tokenDataStore.setAuthContextData(_.get(result, 'data.authContext'));

        if (_.get(result, 'data.message')) {
          // @ts-ignore TS2339
          this.estimateInfo = null;
          throw new Error(result.data.message);
        } else {
          // @ts-ignore TS2339
          this.estimateInfo = _.get(result, 'data');
          cb();
        }
      })
      .catch(e => {
        throw e;
      });
  }

  //
  // 視聴可能であった場合に表示するデータを取得する
  //
  fetchDuplicateInfo(props) {
    const next = () => {
      // @ts-ignore TS2339
      if (this._isMounted) {
        this.setState({ loading: false, step: 'duplicate' });
      }
    };

    // @ts-ignore TS2339
    return this.axios
      .multi([
        // @ts-ignore TS2339
        this.axios.get(`/api/meta/${props.metaId}`),
        // @ts-ignore TS2339
        this.axios.post('/api/account/check_availability', { meta_id: props.metaId }),
      ])
      .then(results => {
        this.setMeta(results[0]);

        const entitlements = _.get(results[1], 'data.data.entitlements');
        if (_.isEmpty(entitlements)) {
          return next();
        }

        let entitlementIds = entitlements.map(entitlement => {
          return entitlement.id;
        });
        entitlementIds = _.compact(_.uniq(entitlementIds));
        if (_.isEmpty(entitlementIds)) {
          return next();
        }

        // @ts-ignore TS2339
        return this.axios
          .multi([
            // @ts-ignore TS2339
            this.axios.get('/api/account/subscriptions', {
              with_course: true,
              with_payment_instrument: true,
              with_user_vouchers: true,
              with_entitlements: true,
              entitlement_id: entitlementIds.join(','),
            }),
            // @ts-ignore TS2339
            this.axios.get('/api/account/product_orders', {
              with_order: true,
              with_product: true,
              with_payment_instrument: true,
              with_user_vouchers: true,
              with_entitlements: true,
              entitlement_id: entitlementIds.join(','),
            }),
          ])
          .then(results => {
            // @ts-ignore TS2339
            this.duplicatedSubscriptions = _.get(results[0], 'data.subscriptions', []);
            // @ts-ignore TS2339
            this.duplicatedProductOrders = _.get(results[1], 'data.productOrders', []);
            next();
          })
          .catch(e => {
            console.error(e);
            next();
          });
      })
      .catch(e => {
        console.error(e);
        next();
      });
  }

  // 視聴可能な権利が付与されているか確認する
  checkAvailability(result) {
    if (_.get(result, 'data.data.isAvailable')) {
      throw new Error(ERROR.AVAILABLE_ENTITLEMENT);
    }
  }

  setMeta(result) {
    const meta = _.get(result, 'data.meta');
    if (!meta) {
      throw new Error('meta not found.');
    } else {
      // @ts-ignore TS2339
      this.meta = meta;
    }
  }

  //
  // コースと商品リストをセット
  //
  setCoursesAndProducts(result, trialUsedResult = null) {
    const courses = _.get(result, 'data.courses', []);
    const products = _.get(result, 'data.products', []);
    if (courses.length == 0 && products.length == 0) {
      throw new Error(ERROR.META_NOT_VIEWABLE);
    } else {
      // @ts-ignore TS2339
      if (_.isEmpty(this.props.selected)) {
        // @ts-ignore TS2339
        this.courses = courses;
        // @ts-ignore TS2339
        this.products = products;
      } else {
        // @ts-ignore TS2339
        if (this.props.selected.type === 'product') {
          // @ts-ignore TS2339
          this.courses = [];
          // @ts-ignore TS2339
          this.products = _.filter(products, product => product.id === this.props.selected.id);
        } else {
          // @ts-ignore TS2339
          this.courses = _.filter(courses, course => course.id === this.props.selected.id);
          // @ts-ignore TS2339
          this.products = [];
        }
      }
    }

    if (trialUsedResult) {
      // コース情報にトライアル享受済フラグを追加しておく
      const trialUsedCourseIds = _.get(trialUsedResult, 'data.trial_used_course_ids', []);
      // @ts-ignore TS2339
      this.courses = this.courses.map(course => {
        if (trialUsedCourseIds.indexOf(course.id) != -1) {
          course.isTrialUsed = true;
        }
        return course;
      });
    }
  }

  //
  // activeItemがcoursesとproductsに
  // 含まれているかをチェック
  //
  activeItemValidation() {
    // @ts-ignore TS2339
    if (!this.activeItem) {
      throw new TypeError('this.activeItem is required.');
    // @ts-ignore TS2339
    } else if (this.activeItemType == 'course') {
      if (
        _.isEmpty(
          // @ts-ignore TS2339
          _.filter(this.courses, course => {
            // @ts-ignore TS2339
            return _.get(course, 'id') == this.activeItem.id;
          }),
        )
      ) {
        throw new Error('this.activeItem is invalid.');
      }
    // @ts-ignore TS2339
    } else if (this.activeItemType == 'product') {
      if (
        _.isEmpty(
          // @ts-ignore TS2339
          _.filter(this.products, product => {
            // @ts-ignore TS2339
            return _.get(product, 'id') == this.activeItem.id;
          }),
        )
      ) {
        throw new Error('this.activeItem is invalid.');
      }
    } else {
      throw new TypeError('this.activeitemtypetype must be "product" or "course".');
    }
  }

  existOnlyOneItem() {
    const productsLen = _.get(this, 'products.length', 0);
    const coursesLen = _.get(this, 'courses.length', 0);
    // @ts-ignore TS2365
    return productsLen + coursesLen == 1;
  }

  setOnlyOneItem() {
    // @ts-ignore TS2339
    const productsLen = _.get(this.products, 'length');
    // @ts-ignore TS2339
    const coursesLen = _.get(this.courses, 'length');
    if (coursesLen === 1 && productsLen === 0) {
      // @ts-ignore TS2339
      this.activeItem = this.courses[0];
      // @ts-ignore TS2339
      this.activeItemType = 'course';
      return true;
    } else if (coursesLen === 0 && productsLen === 1) {
      // @ts-ignore TS2339
      this.activeItem = this.products[0];
      // @ts-ignore TS2339
      this.activeItemType = 'product';
      return true;
    } else {
      return false;
    }
  }

  //
  // 契約/商品を選択
  //
  doSelect(item, type) {
    if (!item) {
      this.handleError(new TypeError('Argument Error: model is required.'));
    } else if (!(type === 'course' || type === 'product')) {
      this.handleError(new TypeError('Argument Error: type must be "product" or "course".'));
    } else {
      // @ts-ignore TS2339
      this.activeItem = item;
      // @ts-ignore TS2339
      this.activeItemType = type;
      this.fetchConfirmInfo();
    }
  }

  //
  // キャンセル = モーダル閉じる
  //
  cancelSelect() {
    // @ts-ignore TS2339
    if (typeof this.props.closeModal === 'function') {
      // @ts-ignore TS2339
      this.props.closeModal();
    }
  }

  //
  // 購入/契約処理
  //
  doCommit(args = {}) {
    // @ts-ignore TS2339
    if (!this._isMounted) {
      return;
    }
    this.setState({ loading: true });

    // 直前にアカウント情報取得と
    // 視聴可能な権利が付与されているか確認する
    // @ts-ignore TS2339
    return this.axios
      .multi([
        // @ts-ignore TS2339
        this.axios.get('/api/account/me'),
        // @ts-ignore TS2339
        this.axios.post('/api/account/check_availability', { meta_id: args.metaId }),
      ])
      .then(results => {
        // if (!_.get(results[0], 'data.account')) {
        //   throw new Error(ERROR.UNAUTHORIZED);
        // }
        if (_.get(results[1], 'data.data.isAvailable')) {
          throw new Error(ERROR.AVAILABLE_ENTITLEMENT);
        }
        // @ts-ignore TS2339
        this.accountInfo = _.get(results[0], 'data.account');
        tokenDataStore.setAuthContextData(_.get(results[1], 'data.authContext'));

        // @ts-ignore TS2339
        if (args.type === 'subscribe') {
          // @ts-ignore TS2339
          if (args.nextStep) {
            this.registAndSubscribe(args);
          } else {
            this.doSubscribe(args);
          }
        // @ts-ignore TS2339
        } else if (args.type === 'purchase') {
          // @ts-ignore TS2339
          if (args.nextStep) {
            this.addPayment(args);
            // this.registAndPurchase(args);
          } else {
            this.doPurchase(args);
          }
        } else {
          throw new TypeError('type must be "subscribe" or "purchase".');
        }
      })
      .catch(e => {
        this.handleError(e);
      });
  }

  //
  // 支払い方法登録＆契約処理
  // 支払情報の登録へ遷移
  //
  registAndSubscribe(args) {
    const courseId = args.courseId;
    const voucherCode = args.voucherCode;
    const paymentMethodInfo = args.paymentMethodInfo;

    // POSTする値を組み立ててsubmit
    const values = {
      courseId: courseId,
      voucherCode: voucherCode,
      cancelTo: window.location.href,
      returnTo: this.watchUrl(),
    };
    // if (paymentMethodInfo.isSbpsService) {
    // @ts-ignore TS2339
    values.paymentMethodId = paymentMethodInfo.id;
    // @ts-ignore TS2339
    values.formAction = '/account/subscribe_gmo_post';
    // } else {
    //   values.formAction = '/account/subscribe_token';
    // }
    // @ts-ignore TS2339
    if (this.registFormRef.current) {
      // @ts-ignore TS2339
      this.registFormRef.current.formSubmit(values);
    }

    // @ts-ignore TS2339
    if (!this._isMounted) {
      return;
    }
    this.setState({ loading: true });
  }

  //
  // 契約処理
  //
  doSubscribe(args) {
    const metaId = args.metaId;
    const courseId = args.courseId;
    const voucherCode = args.voucherCode;

    // 登録済の支払い方法で一撃決済
    const q = {
      meta_id: metaId,
      course_id: courseId,
      voucher_code: voucherCode,
      with_payment_instrument: true,
      with_user_vouchers: true,
      with_entitlements: true,
    };
    const subscribeUrl = '/api/account/subscribe';

    // @ts-ignore TS2339
    this.axios
      .post(subscribeUrl, q, { timeout: 60000, retry: 0 })
      .then(result => {
        tokenDataStore.setAuthContextData(_.get(result, 'data.authContext'));
        if (_.get(result, 'data.message')) {
          if (_.get(result.data.message, '[0]') === ERROR.AVAILABLE_ENTITLEMENT) {
            throw ERROR.AVAILABLE_ENTITLEMENT;
          } else {
            const error = new Error('subscribe error');
            // @ts-ignore TS2339
            error.commitError = commitError(result.data);
            throw error;
          }
        } else {
          // @ts-ignore TS2339
          this.subscription = _.get(result, 'data.subscription');
          // @ts-ignore TS2339
          this.selectedPaymentInstrument = _.get(this.subscription, 'paymentInstrument');
          // @ts-ignore TS2339
          this.grantedEntitlements = _.get(this.subscription, 'entitlements');
          // @ts-ignore TS2339
          this.usedUserVouchers = _.get(this.subscription, 'userVouchers');
        }
      })
      .then(() => {
        // @ts-ignore TS2339
        if (typeof this.handleAfterCommit === 'function') {
          // @ts-ignore TS2339
          return this.handleAfterCommit(this.subscription.id);
        }

        const toFinish = () => {
          // @ts-ignore TS2339
          if (this._isMounted) {
            this.setState({ loading: false, step: 'finish' });
          }
        };

        // 契約時アンケート情報の取得
        // @ts-ignore TS2339
        const questionId = _.get(this.activeItem, 'question_at_subscribing');
        if (!questionId) {
          return toFinish();
        }
        // @ts-ignore TS2339
        this.axios
          .get(`/api/form/questions/${questionId}`)
          .then(result => {
            // @ts-ignore TS2339
            this.finishQuestion = _.get(result, 'data.data');
            toFinish();
          })
          .catch(e => {
            toFinish();
          });
        // this.redirectToWatch();
      })
      .catch(e => {
        this.handleError(e);
      });
  }

  //
  // 支払い方法追加
  //
  addPayment(args) {
    const browserInfo = this.context.getModelData('browserInfo');
    const isSP = browserInfo.isAndroid || (browserInfo.isIOS && !browserInfo.isiPad);
    const productId = args.productId;
    // POSTする値を組み立ててsubmit
    const values = {
      productId: productId,
      cancelTo: window.location.href,
      returnTo: isSP
        // @ts-ignore TS2339
        ? `/usf/${this.props.metaId}/confirm?product_id=${productId}`
        : this.watchUrl({ product_id: productId }),
    };
    // if (paymentMethodInfo.isSbpsService) {
    // @ts-ignore TS2339
    values.formAction = '/account/purchase';
    // } else {
    //   values.formAction = '/account/purchase_token';
    // }
    // @ts-ignore TS2339
    if (this.registFormRef.current) {
      // @ts-ignore TS2339
      this.registFormRef.current.formSubmit(values);
    }
  }

  //
  // 支払い情報を登録して購入する
  //
  registAndPurchase(args) {
    const productId = args.productId;
    const userVoucherId = args.userVoucherId;
    const paymentMethodInfo = args.paymentMethodInfo;

    // POSTする値を組み立ててsubmit
    const values = {
      productId: productId,
      userVoucherId: userVoucherId,
      cancelTo: window.location.href,
      returnTo: this.watchUrl(),
    };
    // if (paymentMethodInfo.isSbpsService) {
    // @ts-ignore TS2339
    values.paymentMethodId = paymentMethodInfo.id;
    // @ts-ignore TS2339
    values.formAction = '/account/purchase';
    // } else {
    //   values.formAction = '/account/purchase_token';
    // }
    // @ts-ignore TS2339
    if (this.registFormRef.current) {
      // @ts-ignore TS2339
      this.registFormRef.current.formSubmit(values);
    }
  }

  //
  // 購入処理
  //
  doPurchase(args) {
    const metaId = args.metaId;
    const productId = args.productId;
    const userVoucherId = args.userVoucherId;
    const paymentInstrumentInfo = args.paymentInstrumentInfo;
    let paymentInstrumentInfoId = null;

    // 支払い方法未設定かつバウチャー使用のケースが出てきたので
    // ここで先にPaymentInstrument.idを取得するようにする
    if (paymentInstrumentInfo) {
      paymentInstrumentInfoId = paymentInstrumentInfo.id;
    }
    // 登録済の支払い方法で一撃決済
    const q = {
      meta_id: metaId,
      product_id: productId,
      payment_instrument_id: paymentInstrumentInfoId,
      user_voucher_id: userVoucherId,
      with_order: true,
      with_payment_instrument: true,
      with_user_vouchers: true,
      with_entitlements: true,
    };
    const purchaseUrl = '/api/account/purchase';

    // @ts-ignore TS2339
    this.axios
      .post(purchaseUrl, q, { timeout: 60000, retry: 0 })
      .then(result => {
        tokenDataStore.setAuthContextData(_.get(result, 'data.authContext'));
        if (_.get(result, 'data.message')) {
          if (_.get(result.data.message, '[0]') === ERROR.AVAILABLE_ENTITLEMENT) {
            throw new Error(ERROR.AVAILABLE_ENTITLEMENT);
          } else {
            const error = new Error('purchase error');
            // @ts-ignore TS2339
            error.commitError = commitError(result.data);
            throw error;
          }
        } else {
          // @ts-ignore TS2339
          this.productOrder = _.get(result, 'data.productOrder');
          // @ts-ignore TS2339
          this.selectedPaymentInstrument = _.get(this.productOrder, 'paymentInstrument');
          // @ts-ignore TS2339
          this.grantedEntitlements = _.get(this.productOrder, 'entitlements');
          // @ts-ignore TS2339
          this.usedUserVouchers = _.get(this.productOrder, 'userVouchers');

          // @ts-ignore TS2339
          if (typeof this.handleAfterCommit === 'function') {
            // @ts-ignore TS2339
            this.handleAfterCommit(this.productOrder.id);
          // @ts-ignore TS2339
          } else if (this._isMounted) {
            this.setState({ loading: false, step: 'finish' });
          }
          // this.redirectToWatch();
        }
      })
      .catch(e => {
        this.handleError(e);
      });
  }

  handleError(error) {
    console.error(error);
    const basicErrors = [ERROR.UNAUTHORIZED, ERROR.META_NOT_VIEWABLE, ERROR.AVAILABLE_ENTITLEMENT];

    // APIエラーまるめる
    if (_.includes(basicErrors, _.get(error, 'commitError.message'))) {
      // @ts-ignore TS2339
      this.error = new Error(error.commitError.message);
    } else {
      // @ts-ignore TS2339
      this.error = error;
    }

    // @ts-ignore TS2339
    if (_.get(this.error, 'message') === ERROR.MATCHING_INFO_LACK) {
      window.location.href = `/signup/info?return_to=${encodeURIComponent(this.watchUrl())}`;
      return;
    }

    // 視聴可能だった場合はリロード(watchページへリダイレクト)する
    // @ts-ignore TS2339
    if (_.get(this.error, 'message') === ERROR.AVAILABLE_ENTITLEMENT) {
      this.redirectToWatch();
      return;
    }

    // basicErrorの場合はerror表示
    // @ts-ignore TS2339
    if (_.includes(basicErrors, _.get(this.error, 'message'))) {
      this.setState({ loading: false, step: 'error' });
      return;
    }

    // 契約/購入系のエラーが生じた場合はConfirmでエラー表示する
    // @ts-ignore TS2339
    if (_.get(this.error, 'commitError') && this.state.step == 'confirm') {
      this.setState({ loading: false, step: 'confirm' });
      return;
    }

    // それ以外の例外はErrorBoundaryで拾う
    // @ts-ignore TS2339
    throw this.error;
  }

  //
  // 契約・購入処理完了後に戻ってくる
  // 再生ページのURLを返す
  //
  watchUrl(q = {}) {
    // ページ遷移でreturn_toを渡されたときはそちらを優先
    let returnTo = _.get(this.context.getModelData('howtoPlayInfo'), 'returnTo');
    if (returnTo) {
      return returnTo;
    }
    // @ts-ignore TS2339
    if (this.props.metaRefId) {
      // @ts-ignore TS2554
      return routes.content.makePath({ id: this.props.metaRefId }, q);
    }
    // @ts-ignore TS2554
    return routes.watchNow.makePath({ id: this.props.metaId }, q);
  }

  //
  // 視聴ページのURLへ遷移
  // URLスキームが渡された場合はログイン状態を引き継ぐために
  // ワンタイムトークンを付与して遷移。
  //
  redirectToWatch() {
    let watchUrl = this.watchUrl();
    if (_.get(this.context.getModelData('howtoPlayInfo'), 'isReturnedToApp')) {
      let tokenService = _.assign({}, this.context.getModelData('services', 'tokenManager'));
      const authContext = this.context.getModelData('authContext');
      addOnetimeTokenToQueryString(tokenService, authContext, watchUrl)
        .then(result => {
          window.location.href = result;
        })
        .catch(e => {
          throw e;
        });
    } else {
      window.location.href = watchUrl;
    }
  }

  isFirstSubscribe() {
    // @ts-ignore TS2339
    if (this.activeItemType === 'course' && !_.get(this.activeItem, 'isTrialUsed')) {
      return true;
    } else {
      return false;
    }
  }
}

export default withModalContext(panelHOC(UpSellFlow));
