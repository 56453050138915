import _ from 'src/libs/util';
import datetime from 'src/libs/datetime';

const PAYMENT_METHOD_LABEL = {
  CARD: 'クレジットカード',
  DOCOMO: 'd払い',
  AU: 'auかんたん決済',
  SOFTBANK: 'ソフトバンクまとめて支払い',
  AMAZON: 'Amazonアプリ内決済',
  APPLE: 'Apple App内課金',
};

const PAY_METHOD = {
  CARD: 'card',
  DOCOMO: 'docomo',
  AU: 'au',
  SOFTBANK: 'softbank',
  AMAZON: 'amazon',
  APPLE: 'apple',
};

export const PAY_METHOD_TO_LABEL = {
  [PAY_METHOD.CARD]: PAYMENT_METHOD_LABEL.CARD,
  [PAY_METHOD.DOCOMO]: PAYMENT_METHOD_LABEL.DOCOMO,
  [PAY_METHOD.AU]: PAYMENT_METHOD_LABEL.AU,
  [PAY_METHOD.SOFTBANK]: PAYMENT_METHOD_LABEL.SOFTBANK,
  [PAY_METHOD.AMAZON]: PAYMENT_METHOD_LABEL.AMAZON,
  [PAY_METHOD.APPLE]: PAYMENT_METHOD_LABEL.APPLE,
};

/**
 * 支払い方法一覧をフィルタリングします。
 * - 支払い種別単位で優先度が高いデータのみを抽出します。
 * @param paymentMethods {array<object>} 支払い方法一覧
 * @return {array<object>} フィルタリング後の支払い方法一覧
 */
const filterByPriority = paymentMethods => {
  if (!_.isArray(paymentMethods)) return paymentMethods;
  if (_.isEmpty(paymentMethods)) return paymentMethods;
  // 優先度の高い順にソートする
  const orderList = _.orderBy(paymentMethods, ['id', 'priority'], ['asc', 'asc']);
  // 支払い種別単位で優先度が高いデータを抽出する
  const filterMap = {}; // 抽出データ
  _.each(orderList, model => {
    if (_.has(filterMap, model.payMethod)) {
      if (filterMap[model.payMethod].priority > model.priority) {
        filterMap[model.payMethod] = model;
      }
    } else {
      filterMap[model.payMethod] = model;
    }
  });
  // 優先度が高いデータのみを抽出する
  return paymentMethods.filter(model => model.id === filterMap[model.payMethod].id);
};

const filterByDisplayPaymentMethod = (paymentMethod, appConfig) => {
  const displayPaymentMethodIds: number[] = appConfig.display_payment_method_ids || [];
  return displayPaymentMethodIds.some(id => id === paymentMethod.id);
};

const isRegistable = data => {
  const now = datetime();
  if (!data.register_acceptable_start_at && !data.register_acceptable_end_at) {
    return true;
  } else if (!data.register_acceptable_start_at) {
    return now < datetime(data.register_acceptable_end_at, 'YYYY/MM/DD HH:mm:ss');
  } else if (!data.register_acceptable_end_at) {
    return datetime(data.register_acceptable_start_at, 'YYYY/MM/DD HH:mm:ss') < now;
  } else {
    return (
      datetime(data.register_acceptable_start_at, 'YYYY/MM/DD HH:mm:ss') < now &&
      now < datetime(data.register_acceptable_end_at, 'YYYY/MM/DD HH:mm:ss')
    );
  }
};

export default function paymentMethod(data) {
  if (data == null) return null;
  const isAppleIAP = data.payment_method_id == 6 || data.pay_method == 'apple';
  if (isAppleIAP) {
    data.name = PAYMENT_METHOD_LABEL.APPLE;
    data.display_name = PAYMENT_METHOD_LABEL.APPLE;
  }

  return {
    id: data.payment_method_id,
    isAppleIAP: isAppleIAP,
    isAmazonIAP: data.payment_method_id == 5,
    name: data.name,
    service: data.service,
    displayName: data.display_name ? data.display_name : data.name,
    paymentMethodTypeName: data.payment_method_type_name,
    paymentMethodType: data.payment_method_type,
    payMethod: data.pay_method,
    mode: data.mode,
    priority: data.priority, // 優先度
    limit: data.limit, // 同時登録許可数
    registable: isRegistable(data),
  };
}

/**
 * 支払い方法に関する処理を行います。
 */
const PaymentMethodUtils = {
  filterByPriority: filterByPriority,
  filterByDisplayPaymentMethod: filterByDisplayPaymentMethod,
};
export { PaymentMethodUtils };
