import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

import Title from './Title';

import { NotfoundError } from '../../../../constants/error';
import { NotFoundError as NFErr } from '../../../common/components/ErrorBoundary';
const Program = props => {
  const model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
  const rootPath = Program.getRootPath(null, null, props);
  const _item = model.getSync(rootPath) || {};
  const [item, setItem] = useState(_item);
  if (!item || _.isEmpty(item)) {
    // @ts-ignore TS2554
    throw new NFErr();
  }
  // const newProps = Object.assign({}, props, {id: item.id, model});
  const newProps = Object.assign({}, props, { id: item.id });
  return <Title {...newProps} />;
};
Program.getRootPath = function(models, options, props) {
  if (props.id) {
    return ['program', props.id];
  }
  return [];
};
Program.getPrefetchPaths = function(models, options, props) {
  return [['program', props.id, ['id', 'name', 'seriesMeta']]];
};
Program.getPrefetchedPaths = function(models, options, props) {
  return data => {
    const item = _.omit(_.get(data.json, this.getRootPath(models, options, props)), ['$__path']);
    let titleMetaId;
    if (_.get(item, 'seriesMeta.metaId')) titleMetaId = item.seriesMeta.metaId;
    props = Object.assign({}, props, { id: item.id, titleMetaId: titleMetaId });
    // @ts-ignore TS2339
    return Title.getPaths(models, options, props);
  };
};
Program.afterPrefetch = function(models, options, props) {
  return data => {
    const item = _.omit(_.get(data.json, this.getRootPath(models, options, props)), ['$__path']);
    if (_.isEmpty(item)) {
      // @ts-ignore TS2554
      return { error: new NotfoundError() };
    }
  };
};
export default Program;
