import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';
import ReactDropDown from '@logiclogic/react-dropdown';

const DropDown = (props, context) => {
  const handleChange = event => {
    const index = _.findIndex(props.options, item => {
      // @ts-ignore TS2339
      return item.props.children == event.target.value;
    });
    if (index === -1) return;

    if (props.options[index].props.onClick) {
      props.options[index].props.onClick(event);
    } else {
      const linkProps = props.options[index].props;
      const { routeHandlers, history } = context;
      let routesMatchFlag = false;
      let href = '';
      if (linkProps.to) {
        let params = linkProps.params || {};
        let query = linkProps.query;
        href = linkProps.to.makePath(params, query);
        routesMatchFlag =
          routeHandlers &&
          _.find(routeHandlers, function(routeHandler) {
            return linkProps.to._regex === routeHandler.route._regex;
          });
      } else if (linkProps.href) {
        href = linkProps.href;
        routesMatchFlag =
          routeHandlers &&
          _.find(routeHandlers, function(routeHandler) {
            return !!routeHandler.route.match(href);
          });
      }

      if (routesMatchFlag) {
        history.push(href);
      } else {
        window.location.href = href;
      }
    }
  };

  const browserInfo = context.getModelData('browserInfo');
  if (browserInfo.isIOS || browserInfo.isAndroid) {
    const options = [];
    if (props.blankValue !== undefined)
      options.push(
        <option key={`item-blankValue`} value={props.blankValue}>
          {props.blankValue}
        </option>,
      );
    _.forEach(props.options, (item, key) => {
      options.push(
        <option key={`item-${key}`} value={item.props.children}>
          {item.props.children}
        </option>,
      );
    });

    return (
      <div className={classnames('dropDown', { widthRestricted: props.widthRestricted === true }, props.theme)}>
        <label className="select-group">
          <select value={props.defaultValue} onChange={handleChange}>
            {options}
          </select>
        </label>
      </div>
    );
  }

  return <ReactDropDown {...props} />;
};

DropDown.contextTypes = {
  getModelData: PropTypes.func,
  routeHandlers: PropTypes.array,
  history: PropTypes.object,
};

export default DropDown;
