import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';

const PlayerSurface = props => {
  const onMouseEnter = e => {
    if (props.onMouseEnter) {
      props.onMouseEnter(e);
    }
  };

  const onMouseLeave = e => {
    if (props.onMouseLeave) {
      props.onMouseLeave(e);
    }
  };

  const onMouseMove = e => {
    if (props.onMouseMove) {
      props.onMouseMove(e);
    }
  };

  const newProps = _.omit(props, 'children');
  return (
    <div {...newProps} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onMouseMove={onMouseMove}>
      {props.children}
    </div>
  );
};

PlayerSurface.contextTypes = {
  staticConfiguration: PropTypes.object,
};

export default PlayerSurface;
